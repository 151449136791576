const didRequiredFieldsChange = (requiredFields, prevState, state) => !requiredFields.every((field) => (prevState[field] === state[field]));

const allOrNothingValidator = (formFields, requiredFields) => {
  const data = requiredFields.filter((field) => formFields[field]);
  return !((data.length === 0) || (data.length === requiredFields.length))
    ? 'Must fill in all related fields or leave empty'
    : '';
};

const REQUIRED_FIELD = 'Required Field';

module.exports = {
  allOrNothingValidator,
  didRequiredFieldsChange,
  REQUIRED_FIELD,
};
