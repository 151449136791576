import PropTypes from 'prop-types';
import React from 'react';

const PostSuccess = ({ requestId }) => (requestId
  && (
    <section className="text-color-success ta-sm-l body-3">
      Successfully submitted Tax Identifiers to Data Ingest Service.
      Request Id {requestId}
    </section>
  ));

PostSuccess.propTypes = {
  requestId: PropTypes.string.isRequired,
};

export default PostSuccess;
