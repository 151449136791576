{
    "lastA11yAudit": "07/08/22",
    "name": "Toggle Button",
    "description": "ToggleButton is a binary button indicating an active or inactive state.",
    "docs": "#/components/buttons/ToggleButton",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=594%3A152406",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/ToggleButton",
    "tags": [
        "togglebutton",
        "togglebuttongroup",
        "toggle button group",
        "segmented",
        "control",
        "cta",
        "call to action",
        "toolbar",
        "accessibility",
        "a11y"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
