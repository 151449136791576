import { Fn, MapLike, MapLikeKey } from '../index.js';

const reducer = (keyExtractor: Function, valueExtractor: Function) => (acc: MapLike<any>, value: any) => {
  const key = keyExtractor(value);
  const newValues = [...(acc[key] || []), valueExtractor(value)];
  return { ...acc, [key]: newValues };
};

const finish = <V, U>(grouping: MapLike<V[]>, finisher: Fn<V[], U>) => Object.entries(grouping).reduce((acc, [key, group]) => ({ ...acc, [key]: finisher(group) }), {});

export const group = <T, K extends MapLikeKey, V, U>(
  items: T[],
  keyExtractor: Fn<T, K> = (t: T) => t as unknown as K,
  valueExtractor: Fn<T, V> = (t: T) => t as unknown as V,
  finisher?: Fn<V[], U>,
): MapLike<V[]> | MapLike<U> => {
  const grouping = items.reduce(reducer(keyExtractor, valueExtractor), {});
  return finisher === null || finisher === undefined
    ? grouping
    : finish(grouping, finisher);
};
