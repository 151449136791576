import _ from 'lodash';

import {
  ERR_CODE_CONFLICT_EXCEPTION, ERR_CODE_DEVICE_ALREADY_ASSIGNED, ERR_MESSAGE_CONFLICT_EXCEPTION, ERR_MESSAGE_DEVICE_ALREADY_ASSIGNED, REGISTERS_TABLE_COLUMNS, WORKSTATION_DEVICE_TYPES,
} from '../constants/registerleveltracking';

import { getUserPermissions, registerLevelTrackingPermissions, registerLevelTrackingPermissionsReadOnly } from './tab-permissions';

export const reorderWorkstationRecords = (records) => records
  .sort((a, b) => (a.workstationNumber < b.workstationNumber ? -1 : 1));

export const groupWorkstationRecords = (records) => {
  const reorderedRecords = [];
  if (!records) return [];
  WORKSTATION_DEVICE_TYPES
    .forEach((deviceType) => {
      reorderedRecords.push(...reorderWorkstationRecords(
        records.filter((register) => register.type === deviceType.value),
      ));
    });
  return reorderedRecords.map((item, idx) => ({ ...item, rowNo: idx + 1 }));
};

export const getDeviceTypeLabel = (value) => WORKSTATION_DEVICE_TYPES.find((item) => item.value === value).label;

export const isHighestWorkstationNumber = (registers, record) => !registers.find(
  (item) => item.type === record.type
      && item.workstationNumber > record.workstationNumber,
);

export const getPlaceholder = ({ Header, isMandatory }) => `${Header}${isMandatory ? ' *' : ''}`;

export const isInvalidSubmit = (record) => REGISTERS_TABLE_COLUMNS.some((colConfig) => _.has(record, colConfig.accessor) && colConfig.isMandatory && !(record[colConfig.accessor]));

export const isRecordUpdated = (updatedrecord, originalRecord) => Object.keys(updatedrecord).some((key) => updatedrecord[key] !== originalRecord[key]);

export const getErrorMessage = (err) => {
  switch (err?.error?.code) {
    case ERR_CODE_CONFLICT_EXCEPTION: return ERR_MESSAGE_CONFLICT_EXCEPTION;
    case ERR_CODE_DEVICE_ALREADY_ASSIGNED: return ERR_MESSAGE_DEVICE_ALREADY_ASSIGNED;
    default: return err?.error?.message || err?.message || 'Failed to load';
  }
};

/* Users should be able to access RLT either with App.RetailOperations.Users.RLT or  App.RetailOperations.Users.RLT.ReadOnly */
export const getRTLPermissionName = (userInfo) => {
  const userPermissions = userInfo.groups ? getUserPermissions(userInfo.groups) : [];
  if (userPermissions.includes('RLT') || !userPermissions.includes('RLT.ReadOnly')) { return registerLevelTrackingPermissions; }

  return registerLevelTrackingPermissionsReadOnly;
};
