// eslint-disable-next-line import/no-extraneous-dependencies
import { List } from 'immutable';
import { Comparator, Fn } from '../index.js';

/**
 * Sorts an array leaving the original array unchanged.
 */
export const sort = <T>(
  arr: T[],
  comparator?: Comparator<T>,
): T[] => List(arr).sort(comparator).toJS() as unknown as T[];

/**
 * Sorts an array by value from valueExtractor leaving the original array unchanged.
 */
export const sortBy = <T, U>(
  arr: T[],
  comparatorValueMapper: Fn<T, U>,
  comparator?: Comparator<U>,
): T[] => List(arr).sortBy(comparatorValueMapper, comparator).toJS() as unknown as T[];
