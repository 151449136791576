import { Checkbox } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { storeStatusValues } from '../../../../utils/static/sls-property-values';
import { DateInput, Select } from '../../../reusable';

import RegularHours from './RegularHours';
import SpecialHours from './SpecialHours';

const OperationalDetails = (props) => (
  <>
    <DateInput
      className="ncss-col-sm-6 va-sm-t"
      errorMessage={!props.disableCmpFields && props.operationalDetailsErrors.openingDate}
      id="openingDate"
      isDisabled={props.userIsReadOnly || props.disableCmpFields}
      label="Opening Date"
      value={props.operationalDetails.openingDate || ''}
      onChange={(openingDate) => props.updateOperationalDetails('openingDate', openingDate)}
    />
    <DateInput
      className="ncss-col-sm-6 va-sm-t"
      errorMessage={!props.disableCmpFields && props.operationalDetailsErrors.closingDate}
      id="closingDate"
      isDisabled={props.userIsReadOnly || props.disableCmpFields}
      label="Closing Date"
      value={props.operationalDetails.closingDate || ''}
      onChange={(closingDate) => props.updateOperationalDetails('closingDate', closingDate)}
    />
    <Select
      className="ncss-col-sm-6 va-sm-t"
      errorMessage={props.disableCmpFields ? '' : props.formErrors.storeStatus}
      id="storeStatus"
      isDisabled={props.userIsReadOnly || props.disableCmpFields}
      label="Store Status"
      options={storeStatusValues}
      value={props.formData.storeStatus || ''}
      onChange={(value) => props.updateFormData('storeStatus', value)}
    />
    <article className="ncss-col-sm-6 va-sm-t">
      <Checkbox
        id="testStore"
        isChecked={props.formData.testStore}
        isDisabled={props.userIsReadOnly}
        label="Test Store"
        onChange={({ target: { checked } }) => props.updateFormData('testStore', checked)}
      />
    </article>
    <RegularHours
      disableCmpFields={props.disableCmpFields}
      errors={props.operationalDetailsErrors.hoursOfOperation.regularHours}
      hours={props.operationalDetails.hoursOfOperation?.regularHours || {}}
      updateHours={(day, value) => {
        const hoursOfOperation = {
          ...props.operationalDetails.hoursOfOperation,
          regularHours: { ...(props.operationalDetails.hoursOfOperation?.regularHours || {}), [day]: value },
        };
        props.updateOperationalDetails('hoursOfOperation', hoursOfOperation);
      }}
      userIsReadOnly={props.userIsReadOnly}
    />
    <SpecialHours
      errors={props.operationalDetailsErrors.hoursOfOperation.specialHours}
      popSpecialHour={(indexToPop) => {
        const { specialHours } = props.operationalDetails.hoursOfOperation;
        specialHours.splice(indexToPop, 1);
        props.updateOperationalDetails('hoursOfOperation', { ...props.operationalDetails.hoursOfOperation, specialHours });
      }}
      pushSpecialHour={() => {
        const specialHours = props.operationalDetails.hoursOfOperation?.specialHours || [];
        const newHour = { date: '', hours: [] };
        specialHours.push(newHour);
        props.updateOperationalDetails('hoursOfOperation', { ...props.operationalDetails.hoursOfOperation, specialHours });
      }}
      specialHours={props.operationalDetails.hoursOfOperation?.specialHours || []}
      updateDate={(i, date) => props.updateSpecialHours(i, { ...props.operationalDetails.hoursOfOperation.specialHours[i], date })}
      updateHours={(i, hours) => props.updateSpecialHours(i, { ...props.operationalDetails.hoursOfOperation.specialHours[i], hours })}
      userIsReadOnly={props.userIsReadOnly}
    />
  </>
);

OperationalDetails.propTypes = {
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  operationalDetails: PropTypes.shape().isRequired,
  operationalDetailsErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  updateOperationalDetails: PropTypes.func.isRequired,
  updateSpecialHours: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default OperationalDetails;
