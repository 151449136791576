import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { fromISODateToInput } from '../../utils/formatting';

const DateInput = (props) => (
  <article className={`${props.className} ${props.errorMessage !== '' ? 'error' : ''}`}>
    <Input
      className="ncss-input pt2-sm pl4-sm pb2-sm pr2-sm"
      errorMessage={props.errorMessage}
      id={props.id}
      isDisabled={props.isDisabled}
      label={props.label}
      type="date"
      value={fromISODateToInput(props.value)}
      onChange={({ target: { value } }) => props.onChange(value)}
    />
  </article>
);

DateInput.defaultProps = {
  className: '',
  errorMessage: '',
  isDisabled: false,
  value: '',
};

DateInput.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default DateInput;
