{
    "lastA11yAudit": "07/08/22",
    "name": "Select",
    "docs": "#/components/Select",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=560%3A148523",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Select",
    "tags": [
        "select",
        "inputs",
        "form elements",
        "forms",
        "dropdown",
        "menu",
        "accessibility",
        "a11y"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
