import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { o2oOrderLookup } from '../../../utils/service-calls/o2o-capabilities';
import { ButtonSubmit } from '../../reusable';

import OrderInfo from './OrderInfo';

import './OrderLookup.css';

const OrderLookup = (props) => {
  const accessToken = useSelector((state) => state.authorizationReducer.auth.accessToken);
  const [doms, setDoms] = useState(undefined);
  const [fulfillmentAttempts, setFulfillmentAttempts] = useState([]);
  const [lookupInProgress, setLookupInProgress] = useState(false);
  const [lookupCompleted, setLookupCompleted] = useState(false);
  const [orderIdentifier, setOrderIdentifier] = useState(props.pathIdentifier || '');

  useEffect(() => { // componentDidMount
    const getFulfillmentAttempts = async () => {
      const defaultResponse = { doms: {}, fulfillmentAttempts: [] };
      try {
        if (!accessToken) return defaultResponse;
        const fulfillmentAttemptsRecord = await o2oOrderLookup(accessToken, orderIdentifier);
        return fulfillmentAttemptsRecord.body;
      } catch (err) {
        console.error('Lookup error', err);
      }
      return defaultResponse;
    };

    const submitOrderLookup = async () => {
      if (!orderIdentifier || lookupInProgress || !!lookupCompleted) return;

      setLookupInProgress(true);
      setLookupCompleted(false);
      setFulfillmentAttempts([]);

      try {
        props.history.push(`/onlinetooffline/orderlookup/${orderIdentifier}`);
        const { doms: domsRecord, fulfillmentAttempts: fulfillmentAttemptsRecord } = await getFulfillmentAttempts(orderIdentifier);
        setDoms(domsRecord);
        setFulfillmentAttempts(fulfillmentAttemptsRecord);
      } catch (err) {
        console.error('Order API error:', err);
      }

      setLookupCompleted(true);
      setLookupInProgress(false);
    };
    if (orderIdentifier) submitOrderLookup();
  }, [orderIdentifier, lookupInProgress, lookupCompleted, fulfillmentAttempts, props.history, accessToken]);

  const emojis = ['🧞', '🥸', '😨', '👻', '🙅', '🧙', '🧌', '🕺', '🏄', '🏌', '🦧', '🦬', '🦣', '🦫', '🦥', '🦕', '🦖', '👟'];
  return (
    <main className="ncss-col-sm-10">
      <div className="order-lookup-search">
        <Input
          defaultValue={orderIdentifier}
          placeholder="Order Number/Fulfillment Reference Number"
          style={{ zIndex: 1 }}
          type="text"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setLookupCompleted(false);
              setOrderIdentifier(e.currentTarget.value);
            }
          }}
        />
        <ButtonSubmit
          isLoading={lookupInProgress}
          label="Search"
          onClick={() => {
            setLookupCompleted(false);
            const identifierValue = document.querySelector('.ncss-input').value;
            setOrderIdentifier(identifierValue);
          }}
        />
      </div>
      <div className="order-lookup-results">
        {fulfillmentAttempts?.map((attempt) => (
          <OrderInfo
            key={attempt.frn}
            doms={doms}
            fmg={attempt.fmg}
            fulfillmentReferenceNumber={attempt.frn}
            ofr={attempt.ofr}
            orderNumber={attempt.orderNumber}
            task={attempt.tasks[attempt.tasks.length - 1]}
            tasks={attempt.tasks}
          />
        ))}
        {(lookupCompleted && fulfillmentAttempts.length === 0) && <div className="no-results">{emojis[Math.floor(Math.random() * emojis.length)]} No results found for {orderIdentifier}</div>}
      </div>
    </main>
  );
};

OrderLookup.defaultProps = {
  history: { push: () => {} },
  pathIdentifier: undefined,
};

OrderLookup.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
  pathIdentifier: PropTypes.string,
};

export default OrderLookup;
