import { requestWrapper } from './reusable';

const config = require('../../config')();

/* DEVICES */

export const devicesGet = (token, storeId) => requestWrapper(
  token,
  config.dwDevicesResource,
  'get',
  { storeId },
);

export const devicePut = (token, device) => requestWrapper(
  token,
  config.dwDevicesResource,
  'put',
  undefined,
  device,
);

export const devicePutToWaitlines = (token, device, waitline) => requestWrapper(
  token,
  config.dwWaitlinesResource,
  'put',
  undefined,
  { ...waitline, name: device.description },
);

/* TICKETS */

export const ticketDelete = (token, ticketId) => requestWrapper(
  token,
  config.dwTicketsResource,
  'delete',
  { ticketId },
);

export const ticketsGet = (token, waitlineId) => requestWrapper(
  token,
  config.dwTicketsResource,
  'get',
  { waitlineId },
);

/* WAITLINES */

export const waitlineDelete = (token, waitlineId) => requestWrapper(
  token,
  config.dwWaitlinesResource,
  'delete',
  { waitlineId },
);

export const waitlinesGet = (token, storeId) => requestWrapper(
  token,
  config.dwWaitlinesResource,
  'get',
  { storeId },
);

export const waitlinePost = (token, storeId, waitline) => requestWrapper(
  token,
  config.dwWaitlinesResource,
  'post',
  undefined,
  { ...waitline, storeId },
);

export const waitlinePut = (token, waitline) => requestWrapper(
  token,
  config.dwWaitlinesResource,
  'put',
  undefined,
  waitline,
);

export const waitlinePutToDevices = (token, waitline, device) => requestWrapper(
  token,
  config.dwDevicesResource,
  'put',
  undefined,
  { ...device, description: waitline.name },
);
