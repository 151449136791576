import {
  addressValidator,
  altPhoneNumberValidator,
  altPhoneRequiredValidator,
  altPhoneTypeValidator,
  businessConceptValidator,
  companyValidator,
  costCenterValidator,
  countryCodeValidator,
  currenciesValidator,
  dateClosedValidator,
  dateISOValidator,
  facilityTypeValidator,
  JECodeDescriptionValidator,
  JECountryValidator,
  JEIdCodeValidator,
  JEOrderCategoryValidator,
  IPChannelValidator,
  IPDistributionCenterValidator,
  IPShipToValidator,
  IPTemporaryClosureValidator,
  latitudeValidator,
  localeValidator,
  localizationValidator,
  loginValidator,
  longitudeValidator,
  managerNameValidator,
  partnerNameValidator,
  emailValidator,
  phoneNumberValidator,
  positiveIntegerValidator,
  positiveNumberValidator,
  regionValidator,
  regularHourValidator,
  requiredValidator,
  specialHourValidator,
  offeringsValidator,
  stateValidator,
  stateRequiredValidator,
  storeNumberValidator,
  timezoneValidator,
} from './sls-validation';

export const fieldsToValidate = [
  { prop: 'address.address1', validators: [requiredValidator, addressValidator] },
  { prop: 'address.address2', validators: [addressValidator] },
  { prop: 'address.address3', validators: [addressValidator] },
  { prop: 'address.city', validators: [requiredValidator] },
  { prop: 'address.country', validators: [requiredValidator, countryCodeValidator] },
  { multipleFieldValidators: [stateValidator, stateRequiredValidator], prop: 'address.state', validators: [] },
  { prop: 'address.postalCode', validators: [requiredValidator] },
  { multipleFieldValidators: [altPhoneNumberValidator, altPhoneRequiredValidator, altPhoneTypeValidator], prop: 'altPhones', validators: [] },
  { prop: 'brand', validators: [requiredValidator] },
  { prop: 'businessConcept', validators: [requiredValidator, businessConceptValidator] },
  { prop: 'company', validators: [requiredValidator, companyValidator] },
  { prop: 'coordinates.latitude', validators: [requiredValidator, latitudeValidator] },
  { prop: 'coordinates.longitude', validators: [requiredValidator, longitudeValidator] },
  { prop: 'costCenter', validators: [costCenterValidator] },
  { prop: 'currencies', validators: [currenciesValidator] },
  { prop: 'description', validators: [requiredValidator] },
  { prop: 'email', validators: [requiredValidator, emailValidator] },
  { prop: 'facilityType', validators: [requiredValidator, facilityTypeValidator] },
  { prop: 'geoFence.entranceRadius', validators: [positiveIntegerValidator] },
  { prop: 'geoFence.exitRadius', validators: [positiveIntegerValidator] },
  { prop: 'imageUrl', validators: [requiredValidator] },
  { prop: 'locale', validators: [requiredValidator, localeValidator] },
  { multipleFieldValidators: [localizationValidator], prop: 'localizations', validators: [] },
  { prop: 'loginClassification', validators: [requiredValidator, loginValidator] },
  { prop: 'name', validators: [requiredValidator] },
  { prop: 'nikeApp.notificationRadius', validators: [positiveNumberValidator] },
  { multipleFieldValidators: [dateClosedValidator], prop: 'operationalDetails.closingDate', validators: [] },
  { multipleFieldValidators: [offeringsValidator], prop: 'offerings', validators: [] },
  { prop: 'operationalDetails.openingDate', validators: [requiredValidator, dateISOValidator] },
  { multipleFieldValidators: [regularHourValidator], prop: 'operationalDetails.hoursOfOperation.regularHours.monday', validators: [] },
  { multipleFieldValidators: [regularHourValidator], prop: 'operationalDetails.hoursOfOperation.regularHours.tuesday', validators: [] },
  { multipleFieldValidators: [regularHourValidator], prop: 'operationalDetails.hoursOfOperation.regularHours.wednesday', validators: [] },
  { multipleFieldValidators: [regularHourValidator], prop: 'operationalDetails.hoursOfOperation.regularHours.thursday', validators: [] },
  { multipleFieldValidators: [regularHourValidator], prop: 'operationalDetails.hoursOfOperation.regularHours.friday', validators: [] },
  { multipleFieldValidators: [regularHourValidator], prop: 'operationalDetails.hoursOfOperation.regularHours.saturday', validators: [] },
  { multipleFieldValidators: [regularHourValidator], prop: 'operationalDetails.hoursOfOperation.regularHours.sunday', validators: [] },
  { multipleFieldValidators: [specialHourValidator], prop: 'operationalDetails.hoursOfOperation.specialHours', validators: [] },
  { multipleFieldValidators: [partnerNameValidator], prop: 'partnerName', validators: [] },
  { prop: 'phone', validators: [requiredValidator, phoneNumberValidator] },
  { prop: 'region', validators: [requiredValidator, regionValidator] },
  { prop: 'sellingArea.grossSize', validators: [positiveNumberValidator] },
  { prop: 'sellingArea.netSize', validators: [positiveNumberValidator] },
  { prop: 'slug', validators: [requiredValidator] },
  { prop: 'storeManager', validators: [managerNameValidator] },
  { prop: 'storeNumber', validators: [requiredValidator, storeNumberValidator] },
  { prop: 'storeStatus', validators: [requiredValidator] },
  { prop: 'timezone', validators: [requiredValidator, timezoneValidator] },
  { prop: 'islandPacific.sapShipTo', validators: [requiredValidator, IPShipToValidator] },
  { prop: 'islandPacific.ipChannel', validators: [requiredValidator, IPChannelValidator] },
  { prop: 'islandPacific.sellingArea.gross', validators: [positiveNumberValidator] },
  { prop: 'islandPacific.sellingArea.net', validators: [positiveNumberValidator] },
  { prop: 'islandPacific.temporaryClosure', validators: [IPTemporaryClosureValidator] },
  { prop: 'islandPacific.distributionCenter', validators: [IPDistributionCenterValidator] },
  { prop: 'justEnough.justEnoughRegion', validators: [requiredValidator, JECodeDescriptionValidator] },
  { prop: 'justEnough.justEnoughTimezone', validators: [requiredValidator] },
  { prop: 'justEnough.countryDesc', validators: [requiredValidator, JECountryValidator] },
  { prop: 'justEnough.storeSubConcept', validators: [requiredValidator, JEIdCodeValidator] },
  { prop: 'justEnough.leadTime', validators: [requiredValidator, positiveNumberValidator] },
  { prop: 'justEnough.orderCategory', validators: [requiredValidator, JEOrderCategoryValidator] },
  { prop: 'justEnough.territory', validators: [requiredValidator, JECodeDescriptionValidator] },
  { prop: 'justEnough.dimension', validators: [requiredValidator, JECodeDescriptionValidator] },
  { prop: 'justEnough.concept', validators: [requiredValidator, JECodeDescriptionValidator] },
  { prop: 'justEnough.salesArea', validators: [requiredValidator, JECodeDescriptionValidator] },
  { prop: 'justEnough.locationType', validators: [requiredValidator, JECodeDescriptionValidator] },
];

export default fieldsToValidate;
