{
    "lastA11yAudit": "07/08/22",
    "name": "Button Group",
    "description": "The ButtonGroup component wraps buttons to provide row alignment and spacing.",
    "docs": "#/components/buttons/ButtonGroup",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=6410%3A294206",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/ButtonGroup",
    "tags": ["buttongroup", "CTA", "Call to Action", "link group", "button set"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
