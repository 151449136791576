import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { createTopic, updateTopic } from '../../utils/service-calls/store-services';
import applications from '../../utils/static/topic-application-values';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import { ButtonSubmit, Select } from '../reusable';

const topicTypes = ['BOPIS', 'PICKUP_REQUEST', 'PRODUCT_REQUEST', 'RESERVE', 'SHIP_FROM_STORE', 'TRY_ON'];

class TopicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: props.topic.applicationId,
      description: props.topic.description,
      errorMessage: '',
      formErrors: {
        appId: props.topic.applicationId ? '' : REQUIRED_FIELD,
        description: props.topic.description ? '' : REQUIRED_FIELD,
        name: props.topic.topicName ? '' : REQUIRED_FIELD,
        topicType: props.topic.topicType ? '' : REQUIRED_FIELD,
      },
      loading: false,
      name: props.topic.topicName,
      topicType: props.topic.topicType,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  isDisabled = () => !(this.state.appId && this.state.description && this.state.name && this.state.topicType);

  onSubmit = () => this.setState({ errorMessage: '', loading: true, successMessage: '' },
    () => {
      const toSend = {
        applicationId: this.state.appId,
        description: this.state.description,
        storeId: this.props.storeId,
        topicName: this.state.name,
        topicType: this.state.topicType,
      };
      if (this.props.adding) {
        return createTopic(this.props.accessToken, toSend)
          .then(() => this.setState({ errorMessage: '', successMessage: `Topic "${toSend.topicName}" was successfully created.` }))
          .catch((error) => this.setState({ errorMessage: `Store Services Error: ${error.message}`, successMessage: '' }))
          .finally(() => this.setState({ loading: false }));
      } else {
        delete toSend.applicationId;
        delete toSend.storeId;
        toSend.topicId = this.props.topic.id;
        return updateTopic(this.props.accessToken, toSend)
          .then(() => this.setState({ errorMessage: '', successMessage: `Topic "${toSend.topicName}" was successfully updated.` }))
          .catch((error) => this.setState({ errorMessage: `Store Services Error: ${error.message}`, successMessage: '' }))
          .finally(() => this.setState({ loading: false }));
      }
    });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState({ formErrors });
  };

  render = () => (
    <article className="ncss-col-sm-8 ta-sm-c mb4-sm">
      <section className="ncss-col-sm-12 border pb2-sm">
        <Select
          errorMessage={this.state.formErrors.appId}
          id="applicationId"
          isDisabled={!this.props.adding}
          label="Application ID"
          options={applications.map((application) => ({ label: application.applicationName, value: application.id }))}
          value={this.state.appId}
          zIndex={90}
          onChange={(value) => this.setState({ appId: value })}
        />
        <Input
          errorMessage={this.state.formErrors.name}
          label="Topic Name"
          placeholder="Enter the Topic Name"
          value={this.state.name || ''}
          onChange={({ target: { value } }) => this.setState({ name: value })}
        />
        <Input
          errorMessage={this.state.formErrors.description}
          label="Topic Description"
          placeholder="Enter the Topic Description"
          value={this.state.description || ''}
          onChange={({ target: { value } }) => this.setState({ description: value })}
        />
        <Select
          errorMessage={this.state.formErrors.topicType}
          id="topicType"
          isDisabled={!this.isDisabled()}
          label="Topic Type"
          options={topicTypes.map((value) => ({ label: value, value }))}
          value={this.state.topicType}
          zIndex={80}
          onChange={(value) => this.setState({ topicType: value })}
        />
      </section>
      <ButtonSubmit
        isDisabled={this.isDisabled()}
        isLoading={this.state.loading}
        submitError={this.state.errorMessage}
        onClick={this.onSubmit}
      />
      <aside className="ncss-col-sm-12 body-2 text-color-success">{this.state.successMessage}</aside>
    </article>
  );
}

TopicForm.propTypes = {
  accessToken: PropTypes.string.isRequired,
  adding: PropTypes.bool.isRequired,
  storeId: PropTypes.string.isRequired,
  topic: PropTypes.shape({
    applicationId: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    topicName: PropTypes.string,
    topicType: PropTypes.string,
  }).isRequired,
};

export default TopicForm;
