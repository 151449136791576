//
// Set useMockHierarchyService to true to use the mock service instead of the live one.
//
const useMockHierarchyService = true;

//
// Set useMockStoreService to true to use the mock service instead of the live one.
//
const useMockStoresService = true;

export default () => ({
    useMockHierarchyService,
    useMockStoresService,
});
