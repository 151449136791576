import {
  compose, group, isSome,
} from '@nike/rcf-fp';
import { format, parse } from 'date-fns';
import { get, set } from 'lodash';
import moment from 'moment';

import { validateLatitude, validateLongitude } from '../../components/sls/stores/service/store-contexts';
import * as staticOptions from '../static/sls-property-values';

const INVALID_FIELD = 'Value has invalid format';
const IP_SHIP_REQUIRED_LENGTH = 6;
const MAX_ADDRESS_CHARS = 40;
const MAX_COST_CENTER_LENGTH = 30;
const MAX_LATITUDE = 90;
const MIN_LATITUDE = -90;
const REQUIRED_FIELD = 'Required Field';
const DEFAULT_ERROR = '';

/* regular expressions */
const regExCountryCode = /^[A-Z]{3}$/;
const regExIsoDate = /^[1-2]\d{3}-(1[0-2]|0\d|\d)-(\d|0\d|[1-2]\d|3[0-1])$/;
const regExEmail = /\S+@\S+\.\S+/;
const regExPhoneNumber = /(\+)?[0-9() -/]+$/;
const regExPositiveInteger = /^\d*$/;
const regExPositiveNumber = /^(\d*)([.](\d+))?$/;
const regExStoreNumber = /^[A-Z]*[0-9]+$/;

/* TYPES of validators */

export const allOrNothingValidator = (storeData, requiredProps, justAll = false) => {
  let data = [];
  requiredProps.forEach((accessor) => {
    if (get(storeData, accessor) !== undefined && get(storeData, accessor) !== '') {
      data = [...data, get(storeData, accessor)];
    }
  });
  if ((!justAll && data.length === 0) || (data.length === requiredProps.length)) {
    return '';
  }
  return 'Must fill in all related fields or leave empty';
};

const lengthValidator = (value, requiredLength) => (!value || value.length === requiredLength
  ? ''
  : `Must be of length ${requiredLength}`);

export const maxLengthValidator = (value, maxLength) => (!value || value.length <= maxLength
  ? ''
  : `Must be of length ${maxLength} or fewer`);

const multipleOptionsValidator = (values = [], options, isCmpStore) => {
  if (!isCmpStore) {
    for (let i = 0; i < values.length; i++) {
      if (!(options.includes(values[i]))) {
        return INVALID_FIELD;
      }
    }
  }
  return '';
};

const numberRangeValidator = (value, min, max) => ((value >= min && value <= max) ? '' : `Must be between ${min} and ${max}`);

const optionsValidator = (value, options) => (!value || options.includes(value)
  ? ''
  : `${INVALID_FIELD}. Current value is '${value}' but must be one of the following options: ${options.join(', ')}`);

const regExValidator = (value, regEx, required = false) => ((!required && !value) || regEx.test(value) ? '' : INVALID_FIELD);

export const requiredValidator = (value) => (!value ? REQUIRED_FIELD : '');

/* Validators */

export const addressValidator = (value) => maxLengthValidator(value, MAX_ADDRESS_CHARS);

export const altPhoneNumberValidator = (storeData) => {
  const { altPhones } = storeData;
  const phoneValidationErrors = altPhones && altPhones.map((altPhone) => regExValidator(altPhone.number, regExPhoneNumber)).filter((s) => s !== '');
  return phoneValidationErrors && phoneValidationErrors.length > 0 ? 'Invalid alternate phone number' : '';
};

export const altPhoneRequiredValidator = (storeData) => {
  const { altPhones } = storeData;
  const valueMissingErrors = altPhones && altPhones.map((altPhone) => requiredValidator(altPhone.type) + requiredValidator(altPhone.number)).filter((s) => s !== '');
  return valueMissingErrors && valueMissingErrors.length > 0 ? 'Alternate phone type and number are required' : '';
};

export const altPhoneTypeValidator = (storeData) => {
  const { altPhones } = storeData;
  const altPhoneTypes = altPhones && altPhones.map((altPhone) => altPhone.type);
  const altPhoneTypeSet = new Set(altPhoneTypes);
  return altPhones && altPhones.length !== altPhoneTypeSet.size ? 'Duplicate alternate phone types' : '';
};

export const announcementMessageTypeValidator = (value) => optionsValidator(value, staticOptions.announcementMessageTypes);

export const businessConceptValidator = (value) => optionsValidator(value, staticOptions.businessConceptValues);

export const companyValidator = (value) => optionsValidator(value, staticOptions.companyValues);

export const costCenterValidator = (value) => maxLengthValidator(value, MAX_COST_CENTER_LENGTH);

export const countryCodeValidator = (value) => regExValidator(value, regExCountryCode);

export const countryValidator = (value) => optionsValidator(value, Object.keys(staticOptions.countryValues));

export const currenciesValidator = (value, isCmpStore) => multipleOptionsValidator(value, staticOptions.currencyCodeValues, isCmpStore);

export const currencyCodeValidator = (value) => optionsValidator(value, staticOptions.currencyCodeValues);

export const dateClosedValidator = (storeData) => regExValidator(
  storeData.operationalDetails?.closingDate || '',
  regExIsoDate,
  (storeData !== undefined && storeData.storeStatus !== undefined && storeData.storeStatus.toUpperCase() === 'CLOSED'),
);

export const dateISOValidator = (value) => regExValidator(value, regExIsoDate);

export const facilityTypeValidator = (value) => optionsValidator(value, staticOptions.facilityTypeValues);

export const IPChannelValidator = (value) => optionsValidator(value, staticOptions.IPChannelValues);

export const IPDistributionCenterValidator = (value) => allOrNothingValidator(value, ['code', 'description']);

export const IPKeyCityValidator = (value) => optionsValidator(value, staticOptions.keyCityValues);

export const IPShipToValidator = (values) => ((!values || values.length === 0)
  || (values.every((value) => (lengthValidator(value, IP_SHIP_REQUIRED_LENGTH) === '') && (regExValidator(value, regExPositiveNumber) === '')))
  ? ''
  : 'Must be 6-digit number(s)');

export const IPSubTerritoryValidator = (value) => optionsValidator(value, staticOptions.IPSubTerritory);

export const loginValidator = (value) => optionsValidator(value, staticOptions.loginClassificationValues);

export const IPTemporaryClosureValidator = (value) => allOrNothingValidator(value, ['reason', 'fromDate', 'toDate']);

export const IPTerritoryValidator = (value) => optionsValidator(value, staticOptions.IPTerritory);

export const IPTimezoneValidator = (value) => optionsValidator(value, staticOptions.IPTimezones);

export const JECodeDescriptionValidator = (value) => ((value && (!value.code || !value.description)) ? REQUIRED_FIELD : '');

export const JEIdCodeValidator = (value) => ((value && (!value.code || !value.id)) ? REQUIRED_FIELD : '');

export const JECountryValidator = (value) => optionsValidator(value, staticOptions.jeCountries);

export const JEOrderCategoryValidator = (value) => optionsValidator(value, staticOptions.orderCategories);

export const languageCodeValidator = (value) => optionsValidator(value, Object.keys(staticOptions.isoCountryObjectArray));

export const latitudeValidator = (value) => numberRangeValidator(value, MIN_LATITUDE, MAX_LATITUDE);

export const storeStatusValidator = (value) => optionsValidator(value.toUpperCase(), staticOptions.storeStatusValues.map((status) => status.value));

export const localeValidator = (value) => ((!value || staticOptions.localeValues.includes(value)) ? '' : `Current value '${value}' is invalid. Please select a locale.`);

export const localizationAnnouncementsValidator = (localization, timezone) => {
  const errorMessage = 'Announcement(s) must have a Message and an Expiry Date in the future';
  const currentTime = moment.tz(new Date(), timezone).format('YYYY-MM-DD');
  if (localization.announcements?.length && Array.isArray(localization.announcements)) {
    const errors = localization.announcements.map((announcement) => {
      if (!announcement.content || !announcement.expiryDate || (announcement.newEdit && announcement.expiryDate < currentTime)) {
        return errorMessage;
      }
      return '';
    });
    if (!errors.every((announcementError) => announcementError === '')) {
      return errorMessage;
    }
    return '';
  }
  return '';
};

export const localizationValidator = (storeData) => {
  const requiredErrorMessage = REQUIRED_FIELD;
  if (storeData?.localizations && Array.isArray(storeData.localizations)) {
    const localizationErrors = storeData.localizations.map((loc) => {
      const announcements = localizationAnnouncementsValidator(loc, storeData.timezone);
      const locErrors = {
        address1: '', address2: '', address3: '', announcements, city: '', state: '',
      };

      const primaryAddressFieldsPresent = loc?.address?.address1 && loc?.address?.city;
      const primaryAddressFieldsMissing = !loc?.address?.address1 && !loc?.address?.city;

      locErrors.address1 = addressValidator(loc?.address?.address1);
      locErrors.address2 = addressValidator(loc?.address?.address2);
      locErrors.address3 = addressValidator(loc?.address?.address3);

      /*
         If the three primary fields are all empty or
         all present, no errors. Otherwise, require all 3 fields
      */
      if (!(primaryAddressFieldsPresent || primaryAddressFieldsMissing)) {
        locErrors.address1 = requiredErrorMessage;
        locErrors.city = requiredErrorMessage;
      }

      return locErrors;
    });

    return localizationErrors;
  }
  return [{
    address1: '', address2: '', address3: '', announcements: '', city: '', state: '',
  }];
};

const MIN_LONGITUDE = -180;
const MAX_LONGITUDE = 180;

export const longitudeValidator = (value) => numberRangeValidator(value, MIN_LONGITUDE, MAX_LONGITUDE);

export const managerNameValidator = (value) => ((!value || Object.entries(value).length === 0) ? '' : allOrNothingValidator(value, ['firstName', 'lastName'], true));

export const partnerNameValidator = (storeData, property, bulkEdit = false) => (
  // if we are not bulk editing, make partnerName required if the facilityType corresponds to a partner facility,
  // eslint-disable-next-line no-nested-ternary
  (!bulkEdit && ['FRANCHISEE_PARTNER_STORE', 'MONO_BRAND_NON_FRANCHISEE_PARTNER_STORE', 'MULTI_BRAND_NON_FRANCHISEE_PARTNER_STORE'].includes(storeData.facilityType)
    ? requiredValidator(storeData.partnerName)
    : '')
  // and always validate against the allowed options
  || ((!storeData.partnerName || staticOptions.partnerNameValues.includes(storeData.partnerName))
    ? ''
    : `Current value '${storeData.partnerName}' is invalid. Please select a partner name.`));

export const emailValidator = (value) => (regExEmail.test(String(value).toLowerCase()) ? '' : 'Please enter a valid email address');

export const phoneNumberValidator = (value) => regExValidator(value, regExPhoneNumber);

export const positiveIntegerValidator = (value) => (!value || regExPositiveInteger.test(value) ? '' : 'Must be a positive integer');

export const positiveNumberValidator = (value) => (!value || regExPositiveNumber.test(value) ? '' : 'Must be a positive number');

export const regularHourValidator = (storeData, property) => {
  const errorMessage = 'Store needs to be open or closed on this day. If store should be open for 24 hours, then set the open time to "12:00 AM"';
  const day = property.split('.').pop();
  if (storeData.operationalDetails?.hoursOfOperation?.regularHours && storeData.operationalDetails.hoursOfOperation.regularHours[day]) {
    const dayHourObj = storeData.operationalDetails.hoursOfOperation.regularHours[day];
    if (dayHourObj.length !== 0 && !dayHourObj[0].localOpenTime) {
      return errorMessage;
    }
    return '';
  }
  return errorMessage;
};

export const regionValidator = (value) => optionsValidator(value, staticOptions.regionValues);

const specialHourFormatValidator = (storeData) => {
  const errorMessage = 'Special hours need a date and need to be open or closed on this day. If store should be open for 24 hours, then set the open time to "12:00 AM"';
  if (storeData.operationalDetails?.hoursOfOperation && Array.isArray(storeData.operationalDetails.hoursOfOperation.specialHours)) {
    const errors = storeData.operationalDetails.hoursOfOperation.specialHours.map((specialDay) => {
      if (!specialDay.date) {
        return errorMessage;
      } else if (Array.isArray(specialDay.hours) && specialDay.hours.length === 0) {
        return '';
      } else if (!Array.isArray(specialDay.hours) || (!specialDay.hours[0].localOpenTime)) {
        return errorMessage;
      }
      return '';
    });
    if (errors.filter((s) => s !== '').length > 0) {
      return errorMessage;
    }
    return '';
  }
  return '';
};

const formatSpecialHourDateForDisplay = compose(
  (dt) => format(dt, 'M/d/yyyy'),
  (s) => parse(s, 'yyyy-MM-dd', new Date()),
);

const specialHourDuplicateValidator = (storeData) => {
  const specialHours = storeData?.operationalDetails?.hoursOfOperation?.specialHours ?? [];
  const dates = specialHours.map(({ date }) => date)
    .filter((date) => isSome(date) && date !== '');
  const dateCounts = group(dates, (k) => k, (v) => v, (vs) => vs.length);
  const duplicates = Object.entries(dateCounts)
    .filter(([, v]) => v > 1)
    .map(([k]) => k)
    .map(formatSpecialHourDateForDisplay);
  return duplicates.length === 0
    ? DEFAULT_ERROR
    : `There are duplicate special hours for date(s): [ ${duplicates.join(', ')} ]`;
};

export const specialHourValidator = (storeData) => {
  const validations = [specialHourFormatValidator, specialHourDuplicateValidator]
    .map((f) => f(storeData))
    .filter((result) => result !== DEFAULT_ERROR);
  return validations.length === 0
    ? DEFAULT_ERROR
    : validations.join(' | ');
};

export const offeringsValidator = ({ offerings = [] }) => {
  const getOfferingErrors = (offering) => [validateLatitude, validateLongitude]
    .map((f) => f(offering))
    .filter((validation) => validation !== DEFAULT_ERROR);
  const offeringErrors = offerings.flatMap(getOfferingErrors);
  return offeringErrors.length > 0
    ? 'There are one or more errors in the Services section'
    : DEFAULT_ERROR;
};

export const stateValidator = (storeData) => (
  (!storeData.address || storeData.address.country !== 'USA')
    || (staticOptions.stateValues.map((option) => option.value).includes(storeData.address.state))
    ? ''
    : `Current value '${storeData.address.state}' is invalid. Please select a state.`);

export const stateRequiredValidator = (storeData) => (
  (storeData.address && (storeData.region === 'NORTH_AMERICA' || storeData.region === 'GREATER_CHINA'))
    ? requiredValidator(storeData.address.state)
    : '');

export const storeChannelValidator = (value) => optionsValidator(value, staticOptions.storeChannelValues);

export const storeNumberValidator = (value) => regExValidator(value, regExStoreNumber);

export const timeFormatValidator = (value) => (moment(value, 'HH:mm').isValid() ? moment(value, 'HH:mm').format('HH:mm') : REQUIRED_FIELD);

export const timezoneValidator = (value) => optionsValidator(value, staticOptions.timezoneValues);

export const warehouseChannelsValidator = (value) => multipleOptionsValidator(value, staticOptions.warehouseChannelValues);

export const warehouseCompanyValidator = (value) => optionsValidator(value, staticOptions.warehouseCompanyValues);

export const warehouseIPTypeValidator = (value) => optionsValidator(value, staticOptions.warehouseIPTypeValues);

export const warehouseRegionValidator = (value) => optionsValidator(value, staticOptions.warehouseRegionValues);

export const warehouseTypeValidator = (value) => optionsValidator(value, staticOptions.warehouseTypeValues);

/* Time to validate the fields */

export const validateFields = (data, fields, showIP, showJE, adding, isCmpStore) => fields.reduce((accumulator, obj) => {
  if ((adding && (obj.prop.includes('islandPacific'))) || (!showIP && (obj.prop.includes('islandPacific')))) {
    return accumulator;
  }
  if ((adding && obj.prop.includes('justEnough')) || (!showJE && obj.prop.includes('justEnough'))) {
    return accumulator;
  }
  const newAccumulator = accumulator;
  const value = get(data, obj.prop);
  let errors = obj.validators.map((validatorFunc) => validatorFunc(value, isCmpStore));
  // this is for validators that needs to take in the whole storeData object
  if (Array.isArray(obj.multipleFieldValidators)) {
    errors = errors.concat(obj.multipleFieldValidators.map((validatorFunc) => validatorFunc(data, obj.prop)));
    // Special case for the localizationsValidator that returns an array
    if (obj.prop === 'localizations') {
      errors = errors.flat();
    }
  }
  // Special case for the localizationsValidator
  if (obj.prop === 'localizations') {
    if (!((errors.map((loc) => Object.values(loc))).flat()).every((v) => v === '')) {
      newAccumulator.errors = true;
    }
  } else if (!errors.every((v) => v === '')) {
    newAccumulator.errors = true;
  }

  const firstError = errors.filter((e) => e !== '').length > 0 ? errors.filter((e) => e !== '')[0] : '';

  // Special case for localizations
  if (obj.prop === 'localizations') {
    return set(newAccumulator, obj.prop, errors);
  } else {
    return set(newAccumulator, obj.prop, firstError);
  }
}, { errors: false });
