export default {
    objects: [
      {
        address: {
          country: 'CHN'
        },
        id: '001fe8b3-5889-4628-af94-977cfcd44182',
        name: 'TOPSPORTS河南安阳峰中路与三道街丹尼斯NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '292348'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0025a219-eeec-4b33-9db4-4389dce2d857',
        name: '上海宝山真华路巴黎春天NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271309'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '00426d72-62b0-4999-91da-1bca65571044',
        name: 'TOPSPORTS福建福州坂中路五四北泰禾广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '292591'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '007db2f5-ba0c-4c8c-8328-c600513209c9',
        name: '青岛合肥路NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '524530'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '008ba1f3-61cc-46b3-88e3-3f362c075658',
        name: 'POUSHENG江西景德镇宝龙城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282195'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: '00940CFB-1282-4114-8808-C78BB84F7000',
        name: 'Nike Clearance Store - Orlando Marketplace',
        region: 'NORTH_AMERICA',
        storeNumber: '204'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '00E02648-B54C-4FE2-BF8B-97ABDB1FF3B6',
        name: 'Nike Factory Store - Myrtle Beach',
        region: 'NORTH_AMERICA',
        storeNumber: '78'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '00a3173e-2371-4fc8-8e87-9fafdd3f2e0d',
        name: 'NIKE青岛三城优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4068'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '00af1f23-a8ca-4dd3-8d4e-55fc713202ee',
        name: '나이키 현대 무역',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '25355'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '00dd34dd-3db9-4d55-8592-01dcf13f3cdf',
        name: 'Nike Chadstone',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1465'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '0108eee7-1778-4b6b-a605-6e8220c25396',
        name: 'Nike Factory Store - Farmington',
        region: 'NORTH_AMERICA',
        storeNumber: '287'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '010df7bb-191e-4f7b-b93e-379acd39cf4b',
        name: 'Nike Robson',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD3'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '010eb0a6-a521-47a6-b0ad-693af025272a',
        name: 'Nike Factory Store - Premium Itupeva',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6312'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '01128f3b-db5c-4f0c-b45a-ff20479e0e6b',
        name: 'NIKE換季優惠店 新竹復興',
        region: 'GREATER_CHINA',
        storeNumber: '3310'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '011bc426-f9c4-489a-b290-1c8678c47a45',
        name: '安徽省合肥市宝胜合肥庐阳区欢乐颂购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241182'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '013278a8-f689-4269-bda3-5cd5f0eafc08',
        name: '山东省临沂市临沂宝胜运动城KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '17049'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '014761f4-8082-4369-b6aa-5e2481263b26',
        name: 'Nike Store MarkAntalya (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2676'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '014b80c0-4948-4dd0-b655-211132d77f0f',
        name: '耐克西安赛格购物中心体验店',
        region: 'GREATER_CHINA',
        storeNumber: '157845'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '015a4342-6673-4f53-be9d-ea11037a881f',
        name: 'NIKE青岛火炬优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4035'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '016eb99a-26b0-432c-b03d-23fa53386824',
        name: '上海市锐力宝安公路嘉定大融城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272091'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0180c280-a0f9-427a-85ce-9d119150b5b9',
        name: '陕西省安康市高新区万达广场NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '270691'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0193e4d4-0f81-4e46-b872-4edf7c27fe20',
        name: 'POUSHENG广西阳朔西街叠翠路NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288481'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: '01C1430D-9444-45F3-9BE1-52078DC6582E',
        name: 'Nike Factory Store - Lubbock',
        region: 'NORTH_AMERICA',
        storeNumber: '206'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: '01D7E317-DB61-4F28-B496-E4FCF34A7A47',
        name: 'Nike Factory Store - Oshkosh',
        region: 'NORTH_AMERICA',
        storeNumber: '70'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '01addd77-db04-4961-be29-9f780914b6b4',
        name: '山东省济南市历下区融创茂NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270944'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '01c92191-d1c1-47e2-bce7-5a987a3a8840',
        name: 'NIKE苏州开平换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4003'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '01d612bf-d994-4da1-976b-66b2ddcdcc22',
        name: 'Nike Factory Store - Zona Norte',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6311'
      },
      {
        address: {
          country: 'BEL'
        },
        districtId: '031af3aa-9b11-4b5d-b05f-80d63e997ea7',
        id: '01db7b41-13ea-4932-be93-7bdfb013f52e',
        name: 'Nike Unite Liege',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '961'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '01de1421-e0bd-4384-8d27-8ac252368d5d',
        name: '临沂兰陵万达广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '287277'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '01e2ae99-c735-4c1f-a402-b22a3221298c',
        name: 'POUSHENG山西晋城城区西街凤展新时代NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288405'
      },
      {
        address: {
          country: 'QAT'
        },
        id: '02183274-fda5-4814-9556-c8fbea44f92b',
        name: 'Nike Doha City Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'QAT006'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '021d3081-afbb-4922-b1cb-f1ea1c2d02fd',
        name: '江苏省南京市宝胜江苏南京宝原南京商厦KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '481513'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '0223eefc-68f6-40f0-8a37-84e4d5a69105',
        name: 'Nike Factory Store - Drive and Dine',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5967'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '02284e91-7a8f-4f24-91cc-600ab5257bba',
        name: '江苏省海安市锐力海安宁海南路文峰大世界NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270917'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '022f1141-9c52-4d6d-9129-bd0f89162c01',
        name: '吉林省吉林市昌邑区解放东路欧亚商都Nike Sport S',
        region: 'GREATER_CHINA',
        storeNumber: '273138'
      },
      {
        address: {
          country: 'THA'
        },
        id: '0232435d-352a-4b7c-9bef-4941449dee24',
        name: 'Nike Icon Siam',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1367'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '023f896f-ba31-49b5-9f3f-0d31e90988ef',
        name: '江苏省张家港市宝胜张家港万达广场KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '528279'
      },
      {
        address: {
          country: 'IND'
        },
        id: '0245c7db-5d4c-4321-bee5-1c215bdeb4b4',
        name: 'Nike Banjara',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1917'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '0252e6d4-3c86-43f4-bb2a-dac280f41861',
        name: 'Nike Well Collective Shibuya',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3194'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '02549dfd-ba65-4eed-8a5b-96baed9dd3ea',
        name: '北京市华联丽泽天地购物中心 Nike Sport-M',
        region: 'GREATER_CHINA',
        storeNumber: '274449'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0263e446-9981-49d0-befb-467bc9bbbf0a',
        name: '广东省深圳市宝安区壹方城 BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '269308'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '028d3c11-96ed-4292-860f-6b8277ab206d',
        name: '浙江省杭州市杭州西溪阿里巴巴广场KL',
        region: 'GREATER_CHINA',
        storeNumber: '241179'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '02EFEFE0-B4A4-4404-A0B4-D47A7B8946C5',
        name: 'Nike Factory Store - Tejon Ranch',
        region: 'NORTH_AMERICA',
        storeNumber: '209'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '02d02586-28a2-4b3c-8dd2-94051e2f2892',
        name: 'Converse Factory Store Tulare - Tulare Outlet Center',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3129'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '02e7a362-a6ad-4e63-aa97-99962d54feb4',
        name: 'Nike Abreeza',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8436'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '02fead9e-3b3e-499b-ab1d-503449c6e979',
        name: 'Nike Mandurriao',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090057'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '0324386e-a2fe-4625-b526-993e32309c83',
        name: 'Converse Factory Store - Tanger Outlet Columbus',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3110'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '03319c26-7076-455c-8adf-42973c4b2e9b',
        name: 'Qingdao RIO FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6013'
      },
      {
        address: {
          country: 'GRC'
        },
        id: '03355631-bf79-4124-8349-40ab8e9b45a6',
        name: 'NIKE STORE WEST ATHENS',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2391'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0337c21f-79ec-4f33-a467-25c60de7d2e8',
        name: '滔搏湖北武汉江岸建设大道与台北路交汇处华润万象城4+5F NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274040'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '03472b5e-b3fa-4068-9b48-2d188e2049f4',
        name: '湖北省武汉市武昌区武珞路武商梦时代广场（武商亚贸二期）B1 NIKE RISE',
        region: 'GREATER_CHINA',
        storeNumber: '274353'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '0350ee76-e131-4b06-96ee-89b86902cbc4',
        name: 'Nike Robina',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8834'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '036bc4eb-532a-446c-8fd3-d6de1ed41f3d',
        name: 'Converse Factory Store Lehi - Outlets at Traverse Mountain',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3126'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '036c4ed3-263e-4334-9a51-d44676ddbb8f',
        name: 'Changsha TIMES Outlets',
        region: 'GREATER_CHINA',
        storeNumber: 'C6026'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '03A63E7D-EF29-4CFA-B475-F6048F13521E',
        name: 'Nike Unite - South Chicago',
        region: 'NORTH_AMERICA',
        storeNumber: '160'
      },
      {
        address: {
          country: 'BGR'
        },
        id: '03aa29e1-7a8f-4731-bd6d-fbe04936441a',
        name: 'Nike Serdika Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BUL019'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '03c6ea24-acfa-480a-bf57-7f26ff2909b3',
        name: 'Nike Sarona (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2344'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '03c75741-7a67-40e6-aa65-e739679622d4',
        name: 'TOPSPORTS辽宁省朝阳市朝阳大街商业城 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287480'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '03c81cd3-e83f-445e-b1a7-93460989ac50',
        name: '阿克苏阿克苏市新华东路太百SP',
        region: 'GREATER_CHINA',
        storeNumber: '288435'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '03cd2945-ec70-45be-8dc4-b92fa28c1845',
        name: 'NIKE武汉佛祖岭换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4000'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '03d3209f-083b-4804-9229-292d10103fc0',
        name: '重庆市北碚区万达广场劲浪运动城SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '288466'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66772996-562e-4025-a37f-33b8bab87475',
        id: '03e8e170-ce06-4f68-bf21-8b361b1441b6',
        name: 'Converse Americana',
        region: 'NORTH_AMERICA',
        storeNumber: 'C5403'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '03fb08aa-4f10-470d-9a64-cf100b400ec2',
        name: '甘肃兰州七里河区西太华商场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287509'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '040af88c-5d99-43ec-9b61-7a00ac6e12ef',
        name: '湖北省武汉市锐力武汉珞瑜路群光广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '3367'
      },
      {
        address: {
          country: 'KWT'
        },
        id: '0432b8d3-6bc0-4406-9aeb-5425948e11a6',
        name: 'Nike Discovery Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KUW104'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0436a596-b49a-42cb-b75a-0b619ebfe55c',
        name: 'POUSHENG安徽合肥蜀山区繁华大道中环广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '268875'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '044c9d3f-aee4-4118-a3dc-bb57faeba3b7',
        name: 'TOPSPORTS河南平顶山开源路开源路丹尼斯NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '260214'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0450bfed-b84f-4478-bbcc-19ebe370518b',
        name: '辽宁省大连市开发区金马路安盛购物广场 SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272166'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '045966d9-dd72-4560-a9f3-6871843a45cd',
        name: '安徽省蚌埠市宝胜蚌埠合百商厦BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '274244'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0465bec1-9d48-4b61-bd9d-6487b607cbb9',
        name: '山东省青岛市青岛城阳高新区金茂览秀城NIKE SPOTR-M',
        region: 'GREATER_CHINA',
        storeNumber: '274457'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '04680388-381e-48d1-b96d-088eaaded4a5',
        name: '安徽省合肥市宝胜合肥淮河路百大休闲广场KL1',
        region: 'GREATER_CHINA',
        storeNumber: '243767'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '04790c84-a1b8-4750-901e-44a2537d6b42',
        name: '四川省泸州市泸州摩尔国际购物广场 Beacon-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '262402'
      },
      {
        address: {
          country: 'GBR'
        },
        id: '047a54ab-b57e-4be9-8b40-7e7fbf94d7dd',
        name: 'Converse Brand Outlet Store Cheshire Oaks',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8127'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '04834c81-48fa-4493-855d-b0e97316741f',
        name: 'Nike Well Collective - 건대',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '96692'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '0483d942-53e8-455a-b0e3-700d9b326a3b',
        name: '나이키 덕천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '49115'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '04C42E59-C21A-437D-BFD9-6483D6C6BCA3',
        name: 'Nike Factory Store - Nashville',
        region: 'NORTH_AMERICA',
        storeNumber: '172'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '04aacfcd-f4cd-437c-8ace-876ca565657a',
        name: 'Nike Glorietta',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5033917'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '04ad42d6-de1d-4d62-978e-77ac55518412',
        name: '江苏省如皋市锐力如皋惠政路吾悦广场DSP',
        region: 'GREATER_CHINA',
        storeNumber: '272636'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '04b659c5-1934-4d23-b893-856a00628a6a',
        name: '河南省郑州市金水区花园路正弘城JORDAN L1',
        region: 'GREATER_CHINA',
        storeNumber: '288060'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '04b73b49-ea95-4162-b3e3-bd4c942f2f74',
        name: 'Nike Metrotown',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD1'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '04b7f341-c7f0-4078-93fc-f2bd61dee776',
        name: '福建省福安市福建省宁德市宝胜宁德福安东百购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '531650'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '04ca1d01-281f-4897-97f8-2d07898af6f6',
        name: 'Converse Factory Store - Outlets at San Clemente',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3106'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '04dbe647-7589-463f-908b-98836ab08c5e',
        name: 'POUSHENG安徽合肥长江西路大洋百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '135899'
      },
      {
        address: {
          country: 'POL'
        },
        id: '04dc4148-e56e-431c-a33a-3271862e81ca',
        name: 'Nike Store Bydgoszcz (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2404'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '04f63f9a-8c27-47ca-86ee-9cd224f77aac',
        name: '北京市西单商场BEACON-L2-L',
        region: 'GREATER_CHINA',
        storeNumber: '270474'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '04fb7060-6b3e-4ac8-bebc-cfb6dca5408d',
        name: '나이키 신세계 강남 스포츠',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '84701'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: '053c7328-d2c5-4fc2-8ee8-95522697310c',
        name: 'Nike Factory Store - Norfolk',
        region: 'NORTH_AMERICA',
        storeNumber: '224'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '054e34aa-864c-4a94-94e0-98ef8a3c3f56',
        name: '贵州省毕节七星关区杜鹃大道招商花园BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '240547'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '056d869d-ab44-4d73-9c24-875513419cf8',
        name: 'NIKE南宁沙井换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3705'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0572557e-2981-4dd6-ad67-051a72e781a6',
        name: '上海市滔博上海嘉定区南翔印象城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '243801'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '05b26b04-80e9-4a3e-999c-6706cab7c1eb',
        name: 'Nike Store Marmara Forum (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2618'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '05da8050-ca53-4844-8c9d-902dda0bb163',
        name: 'Nike Factory Store - Birkenhead Point',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6174'
      },
      {
        address: {
          country: 'BIH'
        },
        id: '06043611-1d76-4306-bfb9-716efa634d19',
        name: 'Nike Store Banja Luka Delta Planet Shopping Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BOS101'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '060de390-e769-4bda-a11d-3194547f41d2',
        name: 'NIKE深圳高尔夫换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3806'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0618e161-3f76-4925-9a2e-67d782e1a45e',
        name: '湖北省黄石市滔搏湖北黄石下陆区广州路30号万达广场1F NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272756'
      },
      {
        address: {
          country: 'ZAF'
        },
        districtId: '43bbce93-a2ad-4606-a7df-205395646fdf',
        id: '06196f56-7908-4c52-829c-5974722587d4',
        name: 'Nike Factory Store Cape Town',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '951'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '0634df89-2f9b-4a1b-aef3-6284acaaa7d7',
        name: '나이키 롯데 군산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '83316'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '0638c03e-5b8d-43f5-b015-84dea9462b55',
        name: 'NIKE佛山魁奇换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3811'
      },
      {
        address: {
          country: 'IND'
        },
        id: '066857fb-8908-4828-978b-bfdcca641f3f',
        name: 'Nike MGF Mall Jaipur',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5046463'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '066c9afa-e11e-4205-a04d-e508a0968df6',
        name: '晋大同南环东路万达广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '274219'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '069650f1-ed83-41da-af76-5c9b8298342b',
        name: '나이키 엔터식스 강변',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '83873'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '06d09b2e-5d44-4a65-baa5-c412c1716993',
        name: '四川省南充市五国风情街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270491'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '06d65036-f7bb-4b50-a498-01146e6cff14',
        name: '耐克南京德基广场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '438741'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '06ebebd3-fd60-4f0c-a8a8-44f8aff5beab',
        name: '内蒙古自治区包头市青山万达广场BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '271215'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '06f43115-13f9-4e68-89ad-89fac3321f2d',
        name: '浙江省湖州市锐力湖州市红旗路南街口浙北大厦KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '258585'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '070ffc5c-ca03-4620-ae78-9c8b09b1a072',
        name: '四川省绵阳市红星街王府井购物中心KICDS LOUNGE L2',
        region: 'GREATER_CHINA',
        storeNumber: '274287'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '0710d4a0-80e8-4138-87c3-186efbebbe08',
        name: 'Converse Factory Store - Pismo Beach Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3100'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '07537188-507e-4d2c-87b2-c9e807821970',
        name: '辽宁省营口市站前区市府路万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '244475'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
        id: '0758b4a1-683f-425a-944d-cee0fd5a990f',
        name: 'Nike Factory Store Castleford',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '526'
      },
      {
        address: {
          country: 'ARE'
        },
        id: '07645169-c690-47d6-940a-f3d255916dff',
        name: 'Nike Mall of the Emirates',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UAE019'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: '076dc866-b5af-4232-b67e-e6252916ff60',
        name: 'Nike Factory Store Sanda',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3040'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '077abf48-351c-4fee-9307-d10d0c251ded',
        name: '北京市长安商场耐克运动店NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '960'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '07980682-c451-495f-ab73-903935320b6c',
        name: '나이키 신세계 김해',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '70311'
      },
      {
        address: {
          country: 'NZL'
        },
        id: '079a6917-09d2-43f3-9063-a3077a0447bc',
        name: 'Nike St Lukes',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6584'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: '07F60F63-6F2A-40A6-A695-B0C6D42D71C0',
        name: 'Nike Factory Store - Ontario',
        region: 'NORTH_AMERICA',
        storeNumber: '110'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '07a32b88-e744-4933-9281-3f7455b7261d',
        name: 'TOPSPORTS广西南宁高新大道安吉万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287299'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '07be0471-c088-4af2-bcd9-e56a4050b474',
        name: '四川省成都市成都科华路王府井Beacon L2 EXTEND',
        region: 'GREATER_CHINA',
        storeNumber: '150843'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '07e56e13-b51e-49a4-9f42-f375973936aa',
        name: 'POUSHENG江苏无锡江南大悦城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287548'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '08062f4e-724c-4caa-a6cb-8277baf6fe0e',
        name: '广东省深圳市百丽广东省深圳市光明新区振明路公明天虹NIKE SPORT -M',
        region: 'GREATER_CHINA',
        storeNumber: '11656'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '08179a24-2c99-416f-9c98-12a2d1e9e069',
        name: '江苏省张家港市张家港万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '528278'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '08619b19-dfe3-4b6c-ad01-9e4b1b223ed5',
        name: 'Nike Store TLV Port',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2727'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '086a181b-8aa2-4f60-ab7d-ca66ca40088a',
        name: '조던 월드 오브 플라이트 서울 홍대',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '77976'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '087a6072-a4db-4f56-ba44-d68503ffda6e',
        name: '浙江省杭州市滔搏杭州下城延安路武林银泰ABEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526544'
      },
      {
        address: {
          country: 'IND'
        },
        id: '0895c7e5-91e8-4df6-9427-4eee90d2cc4f',
        name: 'Nike Ranchi 2',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9186'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '089f6091-8190-4514-b309-cc42dbbfc9d3',
        name: '四川省成都市成都市成华区龙湖宾江天街BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '243000'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: '08F29AE1-5A07-4038-8D2F-940CA4F9F330',
        name: 'Nike Factory Store - Vacaville',
        region: 'NORTH_AMERICA',
        storeNumber: '72'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '08d5302d-efa6-4b9e-b76e-b3a2ebf3ee24',
        name: '黑龙江省哈尔滨市道里区中央大街赛丽斯商场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '245811'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '08ec5c07-f0a9-491f-8cb6-f120ed334374',
        name: '나이키 롯데 의왕',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '94986'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '08f26fca-014d-4e93-8c9d-160a93279638',
        name: 'POUSHENG山东济南双山大街和谐广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '526885'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '08f741a2-0273-4d4d-96f1-77a523d8d8d6',
        name: '广东省广州市盛世长运广州市北京路JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '243856'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '091960ac-9806-4e67-9ff0-d92f8692b1db',
        name: '나이키 롯데 수지',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '88985'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '092103e4-1cbf-4e87-bfa6-d7cd511656c7',
        name: 'Nike Podium',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5088972'
      },
      {
        address: {
          country: 'IND'
        },
        id: '092c25cd-de50-4021-8744-e4d5ae304fb3',
        name: 'Nike Counnaught Place',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4740'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '094e78e4-c694-4526-a0db-7227a1658d17',
        name: '广东省广州市宝胜广州黄埔南岗万达广场',
        region: 'GREATER_CHINA',
        storeNumber: '269140'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '09607d1a-3e00-4e94-86ee-7daa2e464d3b',
        name: 'Converse Factory Store - Tanger Outlet National Harbor',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3786'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '09706db0-343d-4cf0-8457-0f5e6ef9dbe4',
        name: 'REALLY浙江宁波鄞州中兴路龙湖天街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281968'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '09745c3a-5387-4b2c-8396-c03b7dd38a0f',
        name: '湖北省武汉市滔搏湖北武汉市光谷大洋百货NIKE  SPORT –M',
        region: 'GREATER_CHINA',
        storeNumber: '481613'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '40880d74-de09-4fd5-81ce-fa42cca1d744',
        id: '097732d6-36bd-4976-9b2e-2f8c682b6846',
        name: 'Nike Unite Lakeside',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2109'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '099541ff-c697-412f-ad38-79975c76b27f',
        name: '나이키 롯데 광주 수완',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87395'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '09a2ef21-6730-4b18-87d4-724b849d64a2',
        name: '广西壮族自治区南宁市百丽南宁民族大道华润万象城Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '272464'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '09b479fa-c4b4-4cf2-ae02-2c75618f82ae',
        name: 'NIKE成都郫花换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3997'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '09bbb146-ef62-4dda-b44a-9e02d26fcdb7',
        name: '辽宁省大连市中山区新玛特NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270923'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '09c6de3d-c6a2-46ab-b422-19e210fb045a',
        name: 'NIKE三亚亚龙湾换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3860'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '09c88952-2bff-48d8-a9ac-ecd3e0dc31c5',
        name: '辽宁省凤城市丹东凤城市爱琴海城市广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240389'
      },
      {
        address: {
          country: 'IND'
        },
        id: '09ed52c5-9dcf-449b-9e65-72e9a7632e6e',
        name: 'Nike Ambience Mall Gurgaon',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3972'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '09fa5a47-471f-42c8-92cf-0c67b223b818',
        name: '나이키 현대 목동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '25542'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: '09fa80fa-87fa-4ab6-a268-863bd99f0534',
        name: 'NIKE长春九台换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3871'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: '0A0D9D4A-C5A4-463B-9178-ACC1BFD108E1',
        name: 'Nike Factory Store - Allen',
        region: 'NORTH_AMERICA',
        storeNumber: '132'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: '0AC06F9E-13B5-437E-B71B-76FF391A8342',
        name: 'Nike Factory Store - Woodbury',
        region: 'NORTH_AMERICA',
        storeNumber: '59'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: '0B00B53E-2CDA-4FC1-A67D-B48CF200CEE5',
        name: 'Nike Factory Store - Leon',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6614'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '0C978A9C-4B39-4BB4-AA85-2295DD25A9C8',
        name: 'Nike Factory Store - West Jordan',
        region: 'NORTH_AMERICA',
        storeNumber: '342'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: '0FBDD238-910B-4A2F-BBDB-174F206A3EF9',
        name: 'Nike Factory Store - Livermore',
        region: 'NORTH_AMERICA',
        storeNumber: '356'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0a0bcd2f-f01c-4819-9ed0-f5f4b8ad3dfb',
        name: 'TOPSPORTS喀什市明升国际·Vpark购物公园NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293684'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '0a2196f1-b340-4f32-8a57-85ffbeaac4f0',
        name: 'NIKE南宁昆仑换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4032'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0a220a73-472b-4d45-a370-ffc1713d4db9',
        name: 'TOPSPORTS山东烟台西关南街万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288288'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '0a99938f-9736-4a51-b4ef-0235cf262512',
        name: 'Nike Well Collective - 대구 호림',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85935'
      },
      {
        address: {
          country: 'ISR'
        },
        districtId: '80d72218-6d2b-46e2-bea0-2210cb625fbb',
        id: '0ac8d83c-8127-401a-bc77-f1d1ce0e2997',
        name: 'Holon Nike Factory Store',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '791'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '0ac8e68a-30a9-4865-a3b0-3ebc3fc9bdde',
        name: 'Nike Store FAISALIAH MALL',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA001'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0ada236a-a82c-4bd4-b285-b533719fda51',
        name: '北京市昌平万科BEACON L2-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '481973'
      },
      {
        address: {
          country: 'CZE'
        },
        id: '0adcf952-6086-47e3-9695-129e88b9e7f9',
        name: 'Nike Store Prague Kuruna',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2383'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0ae0479a-f8af-47bd-8972-078619f18fdb',
        name: '广东省广州市盛世长运广州市北京路广百NIKESPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270812'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '0b0754e4-5073-468e-a4c3-c8d185ead07a',
        name: 'Memphis ES',
        region: 'NORTH_AMERICA',
        storeNumber: '269'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0b1bd0d7-9c87-4dee-a1de-7371cea4d07a',
        name: '江苏省南京市锐力南京弘阳世界Beacon ext店',
        region: 'GREATER_CHINA',
        storeNumber: '240001'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '0b5dbc2e-9c86-403d-abcf-484c91e2e2b1',
        name: '나이키 현대 대구',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '45143'
      },
      {
        address: {
          country: 'MAR'
        },
        id: '0b76584b-3d5b-4107-b79c-31c25f1919e1',
        name: 'Nike Store Morocco Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MOR001'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '0b7a91b7-bab4-4807-8807-5a922cae1992',
        name: 'Nike Unite - Desert Ridge',
        region: 'NORTH_AMERICA',
        storeNumber: '294'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0b8e26ca-0ba8-460e-b6ad-3d9ffad4abee',
        name: '河北省沧州市河北省沧州运河区永安南大道吾悦广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272158'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '0b8f3a40-de51-4176-9c92-49ead7f25a22',
        name: 'Nike Well Collective - Basaksehir (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2342'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0bbe5ef5-d839-4c63-a7a7-01f051689f02',
        name: '山东省威海市环翠区昆明路威高广场一期KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '240394'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0bcb9639-cb67-4147-b655-d113fa11a31e',
        name: '甘肃省兰州市城关区国芳广场KICKS LOUNGE- L1',
        region: 'GREATER_CHINA',
        storeNumber: '270867'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0be09ca6-6c77-4e9c-bcde-285da9a76047',
        name: '广西壮族自治区柳州市百丽柳州市城中区文昌路华润万象城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '524970'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0bee1281-9661-4fbd-90b9-343ab6dfa70e',
        name: 'POUSHENG山东济南玉函路银座NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '274298'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0c2087da-58ee-41bc-b452-a05866f7ee01',
        name: 'TOPSPORTS山西高平建设北路红旗生活广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288174'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0c5a9719-2c0d-4aa1-a753-1a311f1171d4',
        name: '山东省潍坊市奎文区泰华三期Beacon L2-750',
        region: 'GREATER_CHINA',
        storeNumber: '266807'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '40880d74-de09-4fd5-81ce-fa42cca1d744',
        id: '0c630e67-675d-439b-a888-221ffbec70ce',
        name: 'Nike Factory Store Norwich Riverside',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '822'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '0c937e16-d014-4638-b8a0-684eb1e6bf04',
        name: 'YORKDALE SHOPPING CENTRE',
        region: 'NORTH_AMERICA',
        storeNumber: '477539'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '125d973d-d862-4d06-b2b2-5d14f960d4af',
        id: '0c9c053f-b42d-405b-9dcd-51f8206a8d49',
        name: 'Nike Unite Gran Canaria',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '669'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0cd3a44f-7e75-4408-8ea7-14256abd419e',
        name: 'JORDAN 8 CENTURY AVENUE',
        region: 'GREATER_CHINA',
        storeNumber: '270805'
      },
      {
        address: {
          country: 'CHE'
        },
        districtId: 'fe144077-30cf-4be6-9316-3a64d50a3124',
        id: '0cd42013-bbcc-4ac8-b984-437ed463ece6',
        name: 'Nike Factory Store Aubonne',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '560'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '0cd4fd5c-1a1f-4a5b-852d-86e3d6cc6363',
        name: 'Nike Store Napoli (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '732'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0cd7a1e8-5229-436a-b4f4-faea9c8db9ed',
        name: '锐力上海剑川路闵行龙湖天街KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '260407'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0d08f8a7-3f64-44d2-a3ec-4048b792c1b5',
        name: '江苏省南京市锐力建邺区应天大道虹悦城Kicks Lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '271020'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0d1749e3-bac4-4595-a4e2-2b537ded4b7c',
        name: '贵州贵阳观山湖万科印象城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271197'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: '0d2a010a-c1a7-4eeb-ac2b-91bd2a03cd01',
        name: 'Nike Factory Store Sapporo Kitahiroshima',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3177'
      },
      {
        address: {
          country: 'AUT'
        },
        id: '0d2f9c9e-3a7e-4383-a9c5-8799ac8e88a6',
        name: 'Converse Brand Outlet Store Parndorf',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8124'
      },
      {
        address: {
          country: 'CZE'
        },
        id: '0d3138dc-c78d-4974-983a-3d65369ac80e',
        name: 'Nike Store Olympia Brno (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2466'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '0d333d91-b776-4726-908d-be159513d6d3',
        name: 'Nike Outlet Pristina Af Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'RKS101'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0d4fcf1b-5455-40e9-8ba7-0e2e79495688',
        name: '长沙河西通程商业广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '273018'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '0d56d5d0-42b6-4cf9-986c-007f7f354f0a',
        name: 'Nike Garden City',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9510'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0d7f3712-05e1-40a7-8458-9bc616514743',
        name: '湖北省武汉市滔搏湖北武汉江汉区解放大道688号武汉武商广场6F Kicks Lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '481520'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: '0d806da5-0b83-4b8f-aa24-5543c16895e4',
        name: 'NIKE哈尔滨利民换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3809'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '0d815e0c-119a-44c3-875d-ba3e6f916cb9',
        name: 'Nike Unite South Wharf',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6157'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0dc0e072-9b8b-4cf1-9f8c-4fba8cb61902',
        name: '重庆市重庆龙湖时代天街C馆BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '267170'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0dd64645-6df8-4b12-b579-e2bb7f87f66d',
        name: '浙江省衢州市宝原衢州万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '454172'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: '0de55106-3bff-4218-9ec0-5791773ff658',
        name: 'Nike Factory Store Wertheim',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '567'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0de8b9d0-8f37-43ec-9d91-3a06dc5d03a7',
        name: '浙江省杭州市滔搏杭州滨江江汉路龙湖天街KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '272969'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e02e55b-45bb-40c0-8b8a-27fdad246195',
        name: '辽宁省沈阳市皇姑区佳禾 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '109937'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e12a83d-d65b-4423-91eb-5812e4a487ca',
        name: 'POUSHENG河北迁安惠泉大街东安悦购城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293782'
      },
      {
        address: {
          country: 'DNK'
        },
        districtId: '0f873e25-c3dc-420c-82e5-740c579c5669',
        id: '0e28047f-cbe2-4c75-921f-cf7ffcbcc8c6',
        name: 'Nike Factory Store Copenhagen II',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '970'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e391067-6f45-4b6a-872f-4e33097bc212',
        name: '四川省成都市成都市温江区光华大道珠江国际中心SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '114752'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: '0e3c0079-3cc9-4d82-9a17-21b97f10cfcb',
        name: 'Nike Unite Bordeaux Bègles',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '840'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e406073-b67d-4ad1-8933-fde987a98768',
        name: '黑龙江省哈尔滨市南岗区西大直街学府凯德广场 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '267218'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '0e4da4b1-f994-44f9-891e-887d648e8758',
        name: 'NIKE西安世博换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3875'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '0e4e0eeb-f296-4327-9e3e-5ced550d6dd4',
        name: 'Nike Factory Store - Homebush',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6177'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e4fa31b-e6c7-4f05-acec-e150ff7900b1',
        name: 'POUSHENG河北遵化通化东街爱琴海NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288193'
      },
      {
        address: {
          country: 'MYS'
        },
        districtId: '0f249a59-1a39-4d43-815f-b9ed824f45b6',
        id: '0e65bf8d-d5f4-459a-8d56-6e105e8308f3',
        name: 'Nike Unite - Johor Premium Outlets',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6821'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '0e6cd1c0-5c5c-4a38-bb55-a34fb90602fc',
        name: 'Nike Clearance Store - Canberra',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6176'
      },
      {
        address: {
          country: 'ARE'
        },
        id: '0e6ea28f-644e-43ff-8fa9-1665e3490442',
        name: 'Nike Dalma Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UAE060'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
        id: '0e6f4d8f-cca5-417c-af1d-355f1ea5df7b',
        name: 'Nike Factory Store - Bellevue',
        region: 'NORTH_AMERICA',
        storeNumber: '266'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e82ce2a-2324-49b4-906d-5bca60a8d472',
        name: '新疆阿克苏市天百NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '269512'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '0e857752-a7ae-4c27-b39a-45aed5926ad3',
        name: 'Nike Granada Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA068'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '0e8a3415-fb78-43c0-9ee8-4cda543d30e9',
        name: 'Nike Store Monte Carlo (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '721'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e926d54-ea14-47e0-9d3b-1328d464bd94',
        name: '河北省正定市正定北国商城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272588'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0e963239-af5b-4063-af77-1381d81ba1d8',
        name: 'POUSHENG安徽蚌埠涂山东路百大宝龙城市广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '242453'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '0e97f6fb-29a3-4529-baad-97b070786e31',
        name: 'NIKE常州东城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3929'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '0eacc3b7-d4f7-461b-ab62-55c12ce17127',
        name: 'Nike Store Azrieli',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2724'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '0ec75fab-f6c4-487c-be23-2f4af4669efd',
        name: 'NIKE昆明前卫换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3788'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0f001bbb-227e-4599-9921-8e3d2b876919',
        name: '湖南省浏阳市滔搏湖南长沙浏阳庆泰南路1号天虹百货一楼NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '260392'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '0f1bac85-5fdc-4041-b10b-3a13dd9e1cc3',
        name: 'NSP Rishon West',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2790'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0f22b689-864d-4c26-b447-1449a81cfd7f',
        name: '耐克太原王府井体验店',
        region: 'GREATER_CHINA',
        storeNumber: '259079'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0f2803a0-dca9-4633-b015-b13f5ad66f50',
        name: '江苏省昆山市九方购物中心KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '272765'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '0f32571b-1d90-49ca-abfb-d568bfd7bb73',
        name: 'Nike Store City Stars (Phase 2)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY052'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0f3387c7-35b1-40eb-bded-274115e8e861',
        name: '山西省太原市山西万科印力广场KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '266810'
      },
      {
        address: {
          country: 'LBN'
        },
        id: '0f3f570c-7b86-4a42-9b1d-a537df389c60',
        name: 'Nike Beirut City Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LEB066'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '0f454e95-4683-4fca-924c-372ce92332fa',
        name: 'NIKE西安正阳换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4006'
      },
      {
        address: {
          country: 'IND'
        },
        id: '0f4a09e8-51d2-435d-9ad8-4aa4bee45bf4',
        name: 'Nike Anand',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9456'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '0f6d9e4f-626d-4b36-b0f9-aaaa605458bd',
        name: '나이키 문정',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '81379'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '0f73bfe4-c367-473e-a73f-7997a295582d',
        name: '上海九亭中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '246703'
      },
      {
        address: {
          country: 'IND'
        },
        id: '0f7ec037-7179-45fc-8d8c-e15da3ab7c57',
        name: 'Nike Sec 17',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1884'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '0f83aedc-f882-4649-b8f6-b9c337ed1496',
        name: 'Nike Liverpool Satélite',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: 'Satelite'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '0f9a8f5f-ae67-4dc0-be1f-a7e01ef29556',
        name: 'NIKE南昌九龙换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3900'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '0fd6a9f7-afb3-49ae-817e-9eb8d9272e76',
        name: 'Nike Store Shenkar',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2725'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '100c426f-7c32-4d48-8978-d5968d9655e5',
        name: 'Nike Rideau Centre',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD19'
      },
      {
        address: {
          country: 'HKG'
        },
        districtId: '4da1e8ed-2893-4ac4-a578-e03e4ac4c5aa',
        id: '101fb03f-1eda-4ae7-ad9c-401db9538785',
        name: 'NIKE香港荃灣換季優惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3267'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1026ab44-6434-468d-aa51-0745c153e66e',
        name: 'HIGHWAVE SPORTS四川成都天府大道北段招商大魔方KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287264'
      },
      {
        address: {
          country: 'EST'
        },
        id: '104b320e-d971-4884-8a5d-18099f688f3c',
        name: 'Nike Outlet Tallinn Lasnamäe Centrum (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EST004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '105c7cda-6b19-479f-8514-a176de10cef4',
        name: '北京昌平区隆德广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '135883'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1c3af8dc-d58a-4495-909d-823d7d8f09e1',
        id: '106a8027-f0d2-45aa-89fa-54d3ac5e956b',
        name: 'Nike Factory Store Noventa',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '626'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '10860208-a75a-416b-aeac-597c51a73731',
        name: '耐克杭州宝龙购物中心体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260083'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '10959ec2-26ca-4e68-94df-4e7f04a8bed9',
        name: '나이키 이태원 타운',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21220'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '10a27a88-10db-4a2f-845b-72f3226a4752',
        name: 'Nike Well Collective - Arese (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2469'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '10a4e14a-d6aa-4ac1-86b4-fcda49532115',
        name: 'POUSHENG广西柳州龙城路工贸NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '275364'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: '10b74f5c-4767-4bcf-85ad-f553612c8ac6',
        name: 'Nike Unite - Baton Rouge',
        region: 'NORTH_AMERICA',
        storeNumber: '295'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '10c1318f-d913-4021-87c6-d1543e40cd07',
        name: '河北石家庄桥西中山路万象城JORDAN-L1',
        region: 'GREATER_CHINA',
        storeNumber: '260746'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '10c98127-a6ef-4529-88ee-65b76f5a7f06',
        name: 'Nike Ayala Feliz',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5082909'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '112e31c2-11ea-4d65-bdc0-83657189c13b',
        name: 'Nike Factory Store - Maipu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6414'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '114a6f33-e631-4a09-9063-02dfb4cbfbc6',
        name: '江苏省宝应市锐力宝应吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288477'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1158ea0c-75d5-4aee-a03a-624dd7ad442c',
        name: '山东省济南市济南市历下区燕山银座购物广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240336'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '115d6238-7752-4075-9f8a-03017f7cf6b5',
        name: 'HIGHWAVE SPORTS四川成都武侯区剑南大道世豪广场世豪KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '454724'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '117b5410-7787-4184-8359-0f1f2f78488d',
        name: '上海市百丽上海浦东长泰广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '189875'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '117cac01-b666-40b6-b369-071e32cdfcb6',
        name: '耐克上海浦东正大广场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260289'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '118a5e17-5640-46f0-8918-4b687e3cc387',
        name: 'NIKE烟台长江换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3915'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9d9eff3f-247a-4ecb-92af-3ea47a3ef09f',
        id: '118e110d-93d8-499b-8b96-e88be7a0c72b',
        name: 'Nike Factory Store - Vancouver',
        region: 'NORTH_AMERICA',
        storeNumber: '411534'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '11ce6569-99c8-4685-80d5-abcc3727d5dc',
        name: '黑龙江省哈尔滨市道里区友谊路百盛购物中心NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '240326'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '11d962fb-5a65-4b4a-ae5f-8c1e5733b061',
        name: '广东省佛山市盛世长运顺德海骏达城 BEACON-L2 EXD',
        region: 'GREATER_CHINA',
        storeNumber: '241253'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
        id: '11e635f5-02a6-4d62-bb3c-4448c6b7b36d',
        name: 'Nike Factory Store Lelystad',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '545'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '11e9cb2a-63ea-4a8b-b0cf-993f68cc2b08',
        name: 'POUSHENG安徽合肥金隅南七里NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293493'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '120b81c4-a910-4b8f-bc11-8ad14f9f768f',
        name: 'NIKE无锡观顺换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3842'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '120edc97-e252-459b-9896-c5ff80f1b1c0',
        name: '浙江省宁波市锐力宁波中山东路第二百货DSP',
        region: 'GREATER_CHINA',
        storeNumber: '68812'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: '12181337-50dc-4719-a9d7-a596efb51474',
        name: '나이키 유나이트 청주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6126'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '12275ec6-ce27-4e17-8604-caa7ccd95159',
        name: 'REALLY江苏如皋中山路文峰大世界NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288461'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: '12396f5c-d512-4d9f-b2d7-a7a2495d8ddd',
        name: 'Nike Unite Oceania',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6642'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '12420b96-ddd5-40fc-a111-d58619c642dd',
        name: 'NIKE杭州世纪换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3912'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '12434a7d-398c-4417-9efe-27aec5def3b9',
        name: 'Nike徐州玉带优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4055'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '124d1279-3b7e-4456-92ed-1f6dfc52f0f7',
        name: 'Shenzhen Mission Hill Golf FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6014'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '1251cd17-1643-44d9-ba12-988a5e168215',
        name: 'NIKE南京大桥北换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3784'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '128a5ad6-ffd2-43f1-ac78-35458f9912ed',
        name: '吉林省长春市朝阳区工农大路欧亚商都BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '145994'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1295d269-aeaa-4227-91d9-87fa2b37bc14',
        name: 'POUSHENG陕西西安南门合生汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287262'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: '129A8DDC-C259-4811-8A9E-954108FB8C49',
        name: 'Nike Factory Store - Clinton',
        region: 'NORTH_AMERICA',
        storeNumber: '68'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '12AF2A7F-5C8A-444E-B436-1A1F541C8466',
        name: 'Nike Factory Store - Citadel',
        region: 'NORTH_AMERICA',
        storeNumber: '339'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '12b85a1d-6d26-407e-bd2c-d03a34d0b6e9',
        name: 'Nike Store Porta di Roma (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '873'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '12c82e2d-7e2f-4fdb-9598-970b154cf725',
        name: '나이키 유나이트 시흥',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8104'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: '12cbb354-def9-4dc7-bab9-9eb908beea63',
        name: 'Nike Well Collective - Frontenac',
        region: 'NORTH_AMERICA',
        storeNumber: '598'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '12e09c44-e208-4068-9aa2-9d8c56a66f85',
        name: 'POUSHENG福建厦门嘉禾路SM广场NIKE RISE-1200',
        region: 'GREATER_CHINA',
        storeNumber: '293505'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '13043346-9d79-49a9-95e9-da404cbd1e6a',
        name: '河南省新乡市红旗区宝龙城市广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271330'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '130b67cb-afee-4422-a298-4429e1856892',
        name: 'NIKE宁波江南换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3878'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: '1336d138-9eb3-4009-abb2-432185a81430',
        name: 'Nike Factory Store - Mirabel',
        region: 'NORTH_AMERICA',
        storeNumber: '397211'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '13593f7a-e15d-478e-a21f-f9a53642dbc3',
        name: '浙江省衢州市衢州东方商厦BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '266959'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '13620356-bb17-427d-b26a-a2c24a90796f',
        name: '辽宁省沈阳市和平区元素YY潮流汇KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '260305'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '13771473-acd8-4bb7-a7c5-17fafac20a63',
        name: 'NIKE台州兴华换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3785'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: '139c7367-3d8b-43f4-9436-5b32bb7e0046',
        name: 'Nike Unite Kadoma',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3002'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '13d361dd-436b-4509-bd10-df44abdd3090',
        name: 'Converse Factory Store - The Mills at Jersey Gardens',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3791'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '13e00ba8-46ed-4360-83a0-a65b4599dd7d',
        name: 'TOPSPORTS辽宁大连高新区万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288198'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '13e43a9f-bca9-4efa-80ca-a6ff8719a776',
        name: '内蒙古呼和浩特回民区大统体育SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '9474'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: '13f79af5-d961-411c-bed9-3d418f37b62a',
        name: 'NIKE北京育知换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3798'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '14025892-8c4d-458a-aea8-e2c9793d60fd',
        name: 'Converse Factory Store - Gilroy Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3715'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '142659da-288d-4d75-b97d-1c3efe4fcc4a',
        name: 'TOPSPORTS湖南长沙人民中路友阿百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288444'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1458c9e2-fab6-4713-8ce5-d6c255ce13a0',
        name: '贵州省贵阳市观山湖区光筑西路华润万象汇 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272725'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '14592fdb-71e7-460d-96ba-6a9895eb4cab',
        name: '나이키 애플 사상',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85910'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '146a7a35-0a5c-4966-8521-f6c432aa2012',
        name: 'SANSE广东惠州龙海二路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288176'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '14764a7d-eed7-4139-967c-c94f45d4cbe8',
        name: '나이키 송파',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21990'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '14876f54-9b3c-41f3-a184-82a69712be6a',
        name: 'Nike Store Festival City',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY042'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1488cecc-4147-4988-aedd-b4035aa08ff4',
        name: '北京回龙观东大街首开龙街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281966'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '14960b22-63c5-42b7-ab84-0808d84a50aa',
        name: '耐克成都市太古里KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '528277'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '14dd3da1-b34a-4f54-be51-eb5005afa793',
        name: '나이키 안동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21084'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '14efdac2-2bec-4dcd-8506-29cd5cd937d7',
        name: 'NIKE深圳华南换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3629'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '14f3ca13-f160-4f6d-973c-33a97bc8a5f3',
        name: 'TOPSPORTS山东烟台北马路大悦城NIKE RISE-1200',
        region: 'GREATER_CHINA',
        storeNumber: '293447'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1508ff15-20f5-4997-9f1f-9485d7472fc0',
        name: 'SANSE广东广州云城东路万达广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '287506'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1520d0dd-16f8-441f-a5f4-9bc1342444b9',
        name: 'POUSHENG陕西西安西大街钟楼五环KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288394'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1527e335-54f5-496a-a123-f71e44ad73c7',
        name: '西安西大街钟楼五环NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '268891'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '15396bd0-ef5f-4354-b5fc-06a149b4ee52',
        name: '나이키 갤러리아 진주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '90637'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '15420828-997a-4b53-84f4-f6deb56dd85c',
        name: '上海市百联曲阳购物中心',
        region: 'GREATER_CHINA',
        storeNumber: '531642'
      },
      {
        address: {
          country: 'EST'
        },
        id: '15567658-09ac-4004-aec3-564e9b4c2ddd',
        name: 'Nike Outlet Tartu Tasku Centre (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EST005'
      },
      {
        address: {
          country: 'POL'
        },
        id: '156477e0-7819-4c7d-a9cf-6c28a0b6e4d0',
        name: 'Nike Well Collective Rataje (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2435'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1566a6b2-658e-4ab5-8ff2-669fd47a29d5',
        name: 'POUSHENG北京黄厂路惠多港NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287318'
      },
      {
        address: {
          country: 'EST'
        },
        id: '156f583a-df2f-4a93-bd91-02de501c0aa7',
        name: 'Nike Outlet Tallinn Tondi (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EST013'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: '1571d7b3-9d46-4af1-aa97-728807bf2c31',
        name: 'Nike Factory Store Angers',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '827'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: '157f3e38-d1fb-47ea-b3cc-392df4fde3a4',
        name: 'Nike Unite - Harlem',
        region: 'NORTH_AMERICA',
        storeNumber: '467'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '15fd9bcd-c255-49ac-a4ce-71229a891c6a',
        name: 'TOPSPORTS山东青岛城阳街道正阳中路利客来NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '16611'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '160a47fc-e2a8-4a98-974b-f59e35424ab4',
        name: '나이키 롯데 명동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72490'
      },
      {
        address: {
          country: 'POL'
        },
        id: '160eda0d-ed37-4003-bf8b-d6bff5e16997',
        name: 'Nike Store Czestochowa Galeria Jurajska (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2706'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '161758f5-4640-43fc-8773-68f8cc405ec7',
        name: '上海市锐力大宁路大宁久光BEACON750',
        region: 'GREATER_CHINA',
        storeNumber: '272143'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1627b7da-8c56-4160-9b12-aab8882d87e9',
        name: '江苏省苏州市锐力苏州旺墩路久光百货JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '453354'
      },
      {
        address: {
          country: 'IND'
        },
        id: '16319d3d-9077-48b6-943a-e6af040d1f95',
        name: 'Nike Pacific Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9361'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '163f47b5-b4b6-4f26-a557-5c1525058b92',
        name: '辽宁省大连市高新区高新万达BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '243305'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '16405321-200a-4fd9-bef5-939fe928584f',
        name: '西安未央区赛高金花NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '274372'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '16646dcc-cafa-4a9b-9d8d-f5754adf1ab1',
        name: '나이키 AK 평택',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '83996'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '167b3212-58ce-4ee6-8c8f-08f3d5fe867c',
        name: '北京市怀柔万达广场NIKE SPORT MEDIUM',
        region: 'GREATER_CHINA',
        storeNumber: '269026'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '168489bc-e742-4e09-9cb9-31231a460f75',
        name: '重庆市渝中区大坪正街龙湖时代天街C馆KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '524004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '16989c43-8137-4a6c-b7cc-3f6a8be0496b',
        name: '江苏省连云港市宝胜宝盛连云港中央商场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240069'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '16FBC654-40D8-43EC-A76C-C34700B0DF2A',
        name: 'Nike Factory Store - Las Vegas North',
        region: 'NORTH_AMERICA',
        storeNumber: '119'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '16a9c4e9-31f3-41c7-a0f2-8d87b9dc12a4',
        name: '湖北省鄂州市锐力鄂州新亚太广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526869'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '16c11f8c-90c7-4080-bac5-d85cd156fea0',
        name: 'NIKE南昌明矾换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3910'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '16c6a3e9-4bbe-4794-8869-5fdf5eb9beaf',
        name: '나이키 순천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '90906'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '17140ba0-aa7c-413e-9b98-edda8dee860b',
        name: '广东省广州市盛世长运广州市白云绿地凯德 KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '267159'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '172daf22-b1fa-4d6d-9f24-0ada5a241bba',
        name: '耐克北京新中关KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '259126'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1761a30f-9971-4749-ab2b-0a12c8124b6a',
        name: '重庆市渝北区礼贤路龙湖礼嘉天街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270118'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '177515f2-66da-47e4-bcba-3d544dfdee1c',
        name: 'Nike Store Beaugrenelle (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '893'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '178a518f-0a5d-47b6-9ad6-00ab3552d574',
        name: 'HIGHWAVE SPORTS四川成都郫都区望丛东路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279058'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '179ad1cd-68c7-4616-b5c4-9080c6726c5d',
        name: '安徽省肥东市肥东新城吾悦广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241093'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '17a6973b-a08f-4aca-93a4-cb5cd6c23a72',
        name: 'NIKE杭州江南换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3476'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '17c3c48b-da89-4941-80fc-642640577054',
        name: '陕西省西安市陕西西安未央区熙地港BEACON L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '262399'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '17c65363-fae2-4dd6-a468-57c104650b15',
        name: 'REALLY江苏南京新街口金鹰NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '152817'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: '17c7662a-8dd9-4cf1-8b81-543d1434886f',
        name: 'Nike Unite Liverpool Edge Lane',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2058'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '17ff4996-9fcd-49b5-a118-9eb0c4929e23',
        name: '重庆市大渡口区建桥大道万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '242334'
      },
      {
        address: {
          country: 'POL'
        },
        id: '180895a5-b4c2-4bb7-a1f7-e78c764217d4',
        name: 'Nike Store Krakow Bonarka (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2413'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '180FE5CB-D72F-4F8D-A917-BFDAE16AE1C1',
        name: 'Nike Unite - Chandler',
        region: 'NORTH_AMERICA',
        storeNumber: '207'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '6a92cade-8b98-409e-9582-069514d23a0a',
        id: '180f7d39-1d82-4e89-a3c9-a57ecbeb8707',
        name: 'NIKE 台北員工店',
        region: 'GREATER_CHINA',
        storeNumber: '3305'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '524440b5-a003-410b-9e4b-25c2de21868b',
        id: '183223c5-35dc-47a8-96aa-9b042ef20a70',
        name: 'Nike Unite Roma Da Vinci',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '996'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '183f5b56-9fbd-4985-945c-3b0916710e10',
        name: '北安东北亚NK SPORT（245812）',
        region: 'GREATER_CHINA',
        storeNumber: '245812'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: '188060C8-69F4-46FD-8AA5-7A40752F4CC5',
        name: 'Nike Factory Store - Omaha',
        region: 'NORTH_AMERICA',
        storeNumber: '195'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '18D83739-5A7D-494F-871F-8B5626F7A6EC',
        name: 'Nike Factory Store - Savannah',
        region: 'NORTH_AMERICA',
        storeNumber: '226'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '18ab1299-27b0-4a16-b6f7-09b4396bb1d2',
        name: '耐克重庆龙湖时代天街体验店',
        region: 'GREATER_CHINA',
        storeNumber: '17998'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
        id: '18adc81d-d5e4-43dd-89a1-6e65fa4bc19d',
        name: 'Nike Factory Store York',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '622'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '18ae9e62-46eb-4ea5-a7af-6652406052d2',
        name: '四川省成都市成都市青羊区日月大道万达广场SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '187865'
      },
      {
        address: {
          country: 'GRC'
        },
        districtId: 'f6f807a1-6c74-48f8-af79-d9dad8664dd7',
        id: '18c25f26-e0ec-4f2f-87f3-d11171c74cbb',
        name: 'Nike Factory Store One Salonica',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '766'
      },
      {
        address: {
          country: 'CZE'
        },
        id: '18f9499d-0280-4966-8c26-bc1d65370977',
        name: 'NIKE STORE OSTRAVA',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2386'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '18fb23f3-b9ea-4e8d-9eeb-00489dcf306c',
        name: '宁夏银川兴庆区解放东街新华百货CcmallNIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '271187'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '190c2bbd-e7f4-42ff-891f-d37cb1f673a0',
        name: '安徽省芜湖市八佰伴 BEACON 750',
        region: 'GREATER_CHINA',
        storeNumber: '271310'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '1912db53-e426-4288-ae69-05a9add066e1',
        name: 'NIKE嘉兴城南换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3921'
      },
      {
        address: {
          country: 'CZE'
        },
        id: '193400e1-d9d4-4104-bb85-7ddd7f12c94e',
        name: 'Nike Well Collective - Chodov (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2467'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '19448723-558e-499d-8b94-49f266d981b2',
        name: '江苏省海安市锐力海安镇南路万达广场SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '268807'
      },
      {
        address: {
          country: 'CYP'
        },
        id: '199aa37e-e720-488f-9b92-c7ee369e0df9',
        name: 'Nike Store Lefkosa',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2327'
      },
      {
        address: {
          country: 'IND'
        },
        id: '19a079d7-e244-465b-91a9-2c8ef2ad6805',
        name: 'Nike Alkapuri',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8433'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '19dd09b4-1b60-45fa-bede-3be4add645be',
        name: '陕西省宝鸡市高新区蟠龙路与西宝公路交叉口东100米吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '242940'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '8b2ae4b8-eb16-4d51-83bb-dba72ba34715',
        id: '19e010ec-2e9d-4906-8afa-3f634d838d82',
        name: 'Nike Factory Store Provence',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2038'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: '1ABCFF3D-1876-4803-848E-5AC4A9DD504D',
        name: 'Nike Unite - Carlsbad',
        region: 'NORTH_AMERICA',
        storeNumber: '232'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '1B61E6D3-03E6-4553-9DBA-CF7D8AE178E5',
        name: 'Nike Well Collective - Pasadena',
        region: 'NORTH_AMERICA',
        storeNumber: '323'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: '1DE0AFD3-F99F-451B-BBF5-266DE3EB60D0',
        name: 'Nike Unite - Reno',
        region: 'NORTH_AMERICA',
        storeNumber: '335'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: '1E207656-5175-4000-A72A-F002F28EF541',
        name: 'Nike Factory Store - Merrimack',
        region: 'NORTH_AMERICA',
        storeNumber: '355'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: '1FD720D8-6BC8-42E6-B773-5002387B457F',
        name: 'Nike Factory Store - Eagan',
        region: 'NORTH_AMERICA',
        storeNumber: '234'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '1a0f32f5-34b0-4a32-b7a2-c060e203c8cd',
        name: 'NIKE昆明西三环换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4024'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1a1629c1-9b1c-41e8-8e53-769242113eeb',
        name: '나이키 PPO 평택',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95160'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1a38b51d-0217-48c2-bb8a-62f1343cc199',
        name: '나이키 충주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71306'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1a3eea60-ec0c-4d26-8389-df5e829b99fa',
        name: '江苏省南京市南京中央KL2',
        region: 'GREATER_CHINA',
        storeNumber: '481512'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: '1a6f05e1-fd9a-4b7e-ae66-94c72e5af7a5',
        name: 'Nike Factory Store Bayonne',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2037'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '1a7a47dd-0967-4a20-9341-74170825a586',
        name: 'Converse Factory Store - Chicago Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3741'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1a7b5f49-55af-4085-ac80-8518f2608023',
        name: '나이키 롯데 강남',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72493'
      },
      {
        address: {
          country: 'SWE'
        },
        id: '1a93d652-af0e-45aa-b644-f30da86a90ea',
        name: 'Nike Well Collective - Taby (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2485'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'aedd4b07-bd95-46bc-bedc-c1dafa8b491e',
        id: '1a957166-44cd-4002-9eac-3bd66979ecaf',
        name: 'Nike Clearance Store Roubaix',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '557'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '1a9f2f72-f83e-46f8-b719-e4e8cd48ca72',
        name: 'Nike Store Novi Sad Promenada (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER108'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1ad216f3-0e97-43df-a75a-dfb38fbefe7e',
        name: '浙江省嘉兴市滔搏嘉兴南湖广益路万达广场BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '260740'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1ae85f81-5b87-4f42-b982-8582169c2e13',
        name: 'TOPSPORTS山东招远罗峰路金都百货NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '293697'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '1afc91c0-11c4-47d8-8d5a-09c5876e1ca7',
        name: 'Nike Store Belle Epine (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '892'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1b05bd10-f36f-45a8-afd1-a7cb1f661a95',
        name: '河南省信阳市浉河区西亚和美广场NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269455'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1b0be34d-3ebf-4354-9516-0fb25d7d2f6a',
        name: 'TOPSPORTS吉林四平铁东区万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287581'
      },
      {
        address: {
          country: 'MKD'
        },
        id: '1b50718f-417e-4ddc-9a6b-8588bc77f315',
        name: 'Nike Outlet Skopje K Plaza (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MAC104'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1b565d7c-3467-40ec-b7c4-de895989b37c',
        name: 'TOPSPORTS辽宁沈阳三台子万象汇NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '293685'
      },
      {
        address: {
          country: 'IND'
        },
        id: '1b67de07-1e00-46e5-b918-a40c7734b709',
        name: 'Nike Pacific Mall Dehradun',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5098712'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1b6b19c9-df31-4b8c-a5a3-93d424497f81',
        name: '나이키 탄방로데오',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71309'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1b6fdf5f-6888-49ff-ac10-f020b668ad44',
        name: '江苏省南京市锐力南京詹园路卓冉奥莱购物广场NSW BEST',
        region: 'GREATER_CHINA',
        storeNumber: '16905'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1b765603-a24e-42c8-911d-0793de2f9aba',
        name: '江苏省南京市南京水平方KL L2',
        region: 'GREATER_CHINA',
        storeNumber: '482435'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1b88ef89-8050-447c-b7b7-eaf2656f77ff',
        name: '山西长治市长兴北路万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '525651'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: '1b9c4757-cd29-4c77-a18d-1bed059a1474',
        name: 'Converse Factory Store - Grapevine Mills',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3099'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '1ba87523-6a76-4233-85fc-a46a8b23813b',
        name: 'Nike Well Collective La Vaguada (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2428'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1bc93cd6-2338-480c-b434-262aa6a5f2bb',
        name: '耐克昆明南屏步行街体验店',
        region: 'GREATER_CHINA',
        storeNumber: '273388'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1bd67110-e3ec-42a6-b0e0-ea7d64d876e6',
        name: '广东省东莞市松山湖万象汇Kicks Lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '274307'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '1c024ae2-7f24-4fad-9d5f-ce8f788fe13f',
        name: 'NIKE扬州江都换季优惠点',
        region: 'GREATER_CHINA',
        storeNumber: '4011'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1c0e2c33-d005-41ac-93fd-caf35ca09ca0',
        name: '나이키 롯데 울산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78520'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '1c104835-f93e-4eb6-8793-2a9289d2d245',
        name: 'Converse Factory Store - Las Americas Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3703'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1c23f59c-67ba-4599-8f54-324a073fce0b',
        name: '나이키 단구 원주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85553'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
        id: '1c256356-7912-412c-929b-826ffc3f57b9',
        name: '나이키 팩토리 스토어 여주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6109'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c282206-56df-463d-9355-b0ecd0f9c4ec',
        name: '天津滨江道世纪隆Beacon 1200',
        region: 'GREATER_CHINA',
        storeNumber: '259059'
      },
      {
        address: {
          country: 'JOR'
        },
        id: '1c35b05d-8635-4ff7-93ff-48ef59899341',
        name: 'Nike Taj Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'JOR010'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c3b518a-01c2-443b-bbfb-2da5df96ee05',
        name: '吉林省长春市朝阳区欧亚卖场BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '243025'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c441160-12a1-46bc-86b3-de2b451b39b7',
        name: '陕西省西安市未央区北辰东路东郡万科NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288850'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c54820d-7a2f-4a6c-90f4-787db61205ad',
        name: '日照海曲利群NK',
        region: 'GREATER_CHINA',
        storeNumber: '13561'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1c601982-ebde-4781-9f91-aa898ca4ee4c',
        name: '나이키 금촌',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '60211'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1c6bf712-a113-4132-8157-afa322b16430',
        name: '나이키 롯데 광주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87367'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c7611dc-9624-4e8c-acd5-92b2edf9ba54',
        name: '浙江省义乌市义乌新城吾悦广场KL',
        region: 'GREATER_CHINA',
        storeNumber: '241178'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c7eab6a-e7fd-414a-96db-8d9bfe9ea090',
        name: '江苏省南京市南京江宁龙湖天街KL',
        region: 'GREATER_CHINA',
        storeNumber: '266953'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c7f8b0b-ac3d-4ab7-8158-b586f0897a53',
        name: '成都高新区天府二街高新伊藤洋华堂NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288828'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '1c7f90cc-8854-499c-aa33-a3e905d03cee',
        name: 'NIKE郑州长江优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4041'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1c98fca9-1fca-481c-b6d3-77bc1d106af5',
        name: 'POUSHENG河北秦皇岛文化路茂业KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '292584'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '1cd651a8-ed69-4c38-b256-eeb50356b67a',
        name: 'NIKE換季優惠店 桃園家樂福',
        region: 'GREATER_CHINA',
        storeNumber: '3375'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1cf04110-4b81-431e-ae22-66b03282f6a5',
        name: '山西省太原市太原市晋源区万象城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '527477'
      },
      {
        address: {
          country: 'IND'
        },
        id: '1cfe4e66-9976-4798-8c51-1f987b6674a6',
        name: 'Nike Jubilee Hills Hyderabad',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4366'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '1d0d251f-737e-4a80-91b7-a1c7227d2df6',
        name: 'Nike Store Maadi',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY051'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1d0efdc5-6914-4ef6-a55c-c5e31dae6f92',
        name: '云南昆明青年路299号Beacon L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '1689'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: '1d1d70d9-9f7c-47b3-8bbf-52abaf2e99a8',
        name: '나이키 유나이트 대전',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8108'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1d21e1eb-d224-4b91-9d1c-099196f78b2e',
        name: '陕西省西安市利和陕西西安雁塔区曲江银泰百货NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270871'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '1d3ff302-43c2-4b05-937c-fa92780cc476',
        name: 'NIKE上海长寿换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3698'
      },
      {
        address: {
          country: 'IND'
        },
        id: '1d40b49b-bc4b-47a9-b5c4-3ba91c4e2a23',
        name: 'Nike Colaba 4',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4900'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '84065b43-8aa0-4415-b314-5ff958ddbecd',
        id: '1d7be63a-99a7-4330-9a0d-43eb3b99702f',
        name: 'Nike Factory Store Murcia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '618'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1da2b766-3dd2-4897-8057-c67a6ae6a8ac',
        name: 'TOPSPORTS山东潍坊胜利东街财富银座NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '17647'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '1da4c8b3-561e-48a2-9f4b-445bc3a35768',
        name: 'Nike Store Kyiv Khreshchatyk (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR038'
      },
      {
        address: {
          country: 'IND'
        },
        id: '1da7c968-f739-48b6-9b12-7e13ab62948c',
        name: 'Nike Mall of India',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5083663'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1db191a0-932d-4b70-9c9c-36815e463e38',
        name: 'POUSHENG江苏盐城解放南路金鹰天地NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288379'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1de3d7e2-22b6-4335-856c-7f08550f90e7',
        name: '耐克山东华润万象城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '184986'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1debdd7e-7a9f-46ad-b258-6793e915eefe',
        name: 'NIKE昆山光明优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4075'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '1dfd8c93-1f17-439b-a3dd-7937d4c75999',
        name: 'Nike by ATC',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5082647'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1e2f6e96-2ef3-44cb-9b11-b0851f55e994',
        name: 'REALLY江苏如东珠江路中央商场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287546'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '1e31d2fc-33ac-483a-a2f1-f07a7e4c13d2',
        name: 'NIKE广州香山优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4043'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1e41d529-f386-4f5a-bafe-1eac86a8c458',
        name: '나이키 연제',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '99687'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '1e5cf736-51da-4b45-b14d-30788846bc8b',
        name: 'NIKE換季優惠店 台中福容',
        region: 'GREATER_CHINA',
        storeNumber: '3392'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: '1e67f128-0c15-49b3-ad71-04261b6fc37c',
        name: '나이키 유나이트 남양주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8107'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '1e691232-7544-4a25-9e4e-d4de9bf5bbb5',
        name: 'NIKE厦门滨海东优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4069'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1e6feef9-4cfe-4eea-b592-dd7d64e173f8',
        name: '나이키 세정 광주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '90907'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '1e7dce7a-97ae-4af7-916f-d614ae6c12b0',
        name: 'NIKE泉州东海换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3731'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '1e999d7e-f2a3-405e-963c-705adc545d07',
        name: 'NIKE兰州兴隆山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3877'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1eba27d3-a002-47f5-977d-e08396e052e4',
        name: '山东省烟台市山东省烟台牟平通海路振华商厦  NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272774'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1ec0e341-14a8-43bc-9649-da41241ed332',
        name: '나이키 압구정',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '73632'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1ecbda55-e140-45ac-a19d-a66450de37c6',
        name: '湖北省武汉市上海锐力武汉摩尔城NBEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241694'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1ed46dc5-d3ed-421b-9f3b-bd90e216f57e',
        name: '安徽省滁州市宝胜滁州市琅琊区苏宁广场Kicks lounge',
        region: 'GREATER_CHINA',
        storeNumber: '243444'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '1f05da08-b994-44bb-b9b7-a99787a83a89',
        name: 'NSP ForumCamlik',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2350'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '1f11b4d4-8b5a-4b70-a263-d0362d297ade',
        name: 'NIKE杭州紫丁香换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3760'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '1f153480-ec2c-4b16-aae7-53f343d24a10',
        name: 'NIKE广州发发换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3616'
      },
      {
        address: {
          country: 'IND'
        },
        id: '1f21527a-9ce6-47f8-9912-5ce3cc9029d4',
        name: 'Nike Jamnagar',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9458'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1f2602d1-3fd6-4e5d-951e-f4ccfae640f1',
        name: 'JORDAN 128 ZHONGJIE',
        region: 'GREATER_CHINA',
        storeNumber: '523131'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1f2611e5-9001-4d64-a0a7-72906410bf7e',
        name: '浙江省杭州市锐力下城区延安路武林银泰C馆KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '266729'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1f3acfce-49b0-45d5-83a3-129c0280dd32',
        name: 'POUSHENG江西南昌中山路天虹NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288439'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1f48abb1-b59e-409e-9864-72eb25c2403d',
        name: '浙江省平阳市锐力温州平阳鳌江车站大道平阳银泰城NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '271236'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1f4eedb6-853e-4cbb-9df2-5e035ecc8247',
        name: '黑龙江省哈尔滨市三大动力路乐松NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270517'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1f599590-7865-45c9-9e2f-e0ec3776d508',
        name: '广东省广州市宝胜广州国金友谊 Beacon L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '241496'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1f64e56f-2518-4647-a60b-38e54747ae28',
        name: 'TOPSPORTS浙江杭州延安路嘉里中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288442'
      },
      {
        address: {
          country: 'SWE'
        },
        id: '1f87fc64-d2e7-470e-a8a0-338c7f847c0b',
        name: 'Nike Store Gallerian (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2476'
      },
      {
        address: {
          country: 'IND'
        },
        id: '1f94597b-2fed-4f14-a57c-cb71eac96e13',
        name: 'Nike Mantri Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4735'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1fa077f2-8b7a-45ca-b540-3522dfa6ec2d',
        name: '四川省成都市武侯区天府大道环球中心 KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '271289'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '1fc28f36-c982-440f-8b5f-d42ff7e7add7',
        name: 'NIKE遵义温州换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4002'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1fc501fe-de30-4ef1-bc4f-dedb4b4561c5',
        name: '湖南省长沙市滔搏湖南长沙龙湖天街NIKE  SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271024'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '1fe7a2a1-b10e-4189-a6fa-17ed85c7594f',
        name: '黑龙江省鹤岗市工农区工交路比优特时代广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '17696'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '1ffc1d70-ae80-41ee-943e-cf691ef65278',
        name: '나이키 전주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21356'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: '2004b306-d678-47e7-bc27-b4618b5bff7c',
        name: 'Nike Unite Rinku',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3017'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2012f333-85d2-4e59-b431-6351b6b3a46f',
        name: 'NJDJ Outlets FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6012'
      },
      {
        address: {
          country: 'BEL'
        },
        id: '2014bd7d-7a6d-48f8-880f-fd33fde579cd',
        name: "NIKE STORE BRUSSELS AVENUE TOISON D'OR",
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2423'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '2028acbd-1c83-4cec-ab8a-5539f9dab514',
        name: 'NIKE无锡欣荣换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3718'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '3ff1a7ff-de7d-4f2b-b7e7-e85a5c5a68c7',
        id: '203de8b5-74f6-422e-80dd-2acfbc01d12d',
        name: 'Nike Unite Badalona Montigala',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '987'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '204e1a9c-adc1-4e12-8821-9b96cd584e66',
        name: 'Nike Store Odesa Preobrazhenska (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR057'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '205bceb4-7326-4096-a2e4-94608e03516c',
        name: '耐克北京悠唐购物中心KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '194717'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '2078d8c0-72b6-4ee4-a308-9014c866b219',
        name: 'Nike North Edsa',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4867'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2088c535-6d39-484f-b2c7-b0f05787747b',
        name: '浙江省宁波市滔搏宁波鄞州海晏北路阪急百货BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '270772'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '20a7490b-089a-4df7-a73b-242d9afacb40',
        name: '锐力宁波慈溪影清路云泰新河坊RISE',
        region: 'GREATER_CHINA',
        storeNumber: '297785'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '20b4db56-5e4c-4795-b972-db539d4e15a8',
        name: '河北省唐山市唐山市路南区新华路万达广场BEACON-L2-M',
        region: 'GREATER_CHINA',
        storeNumber: '246709'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '20db31a1-f937-48cd-8d8f-f311b428c384',
        name: '나이키 모다 천안',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '59800'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '20ede461-a53a-4fe3-84db-c7c5be906516',
        name: '나이키 롯데 김포',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72442'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '20fbdad3-82b5-4f7e-8ec7-17ecd55dd979',
        name: '河南省驻马店市驿城区大商新玛特NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269458'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2104a69c-1178-40cf-afe2-f0f1e1d65f2b',
        name: '辽宁省抚顺市新抚区西一路苏宁NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271075'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '21070783-5cb1-4eb7-a0a5-c54d6a0c35fe',
        name: 'NIKE西安保税换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3755'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2114603c-1e3b-40e5-9d18-442f4b5dc0ef',
        name: '河北省衡水市衡水桃城区衡百国际BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526021'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: '212556d4-1787-42ef-aa60-864c2ae1705d',
        name: 'NIKE ONLY MALL OF AFRICA',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2312'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '212a43d2-2572-46e0-aeee-4b0a500a7572',
        name: '福建省厦门市宝胜厦门湖里区SM城市广场 KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '267103'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '212cb2f3-5f89-4c31-81a1-ab5ee19772bd',
        name: 'NIKE温州永定换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3702'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '213bd514-41de-4d50-a9bd-70b4712b2898',
        name: '山东省青岛市城阳区青特万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '242937'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '214266b0-4b71-49df-8e9c-2c8935a8ad45',
        name: 'Nike Factory Store - Puente Alto',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6408'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '21519E41-194C-4400-93A5-FA551E03368F',
        name: 'Nike Clearance Store - Pigeon Forge',
        region: 'NORTH_AMERICA',
        storeNumber: '23'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2179a287-e4e8-416c-a800-1d41c4a391ef',
        name: '内蒙古自治区包头市昆区苏宁广场 NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269374'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '217c1c2d-5c33-49e5-a0a0-d9b3fa2ac05a',
        name: 'Nike Factory Store - Concepcion',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6406'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '21a48bcc-9d0e-4bb9-9974-0d71530c55be',
        name: 'Nike Ayala Bay',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8970'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '21ab3a8f-681c-4aba-8282-7baca91cc508',
        name: 'POUSHENG山东莱西上海西路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287276'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '21b94a70-5f8f-49c6-835e-a8c011e465fb',
        name: 'Converse Factory Store - Woodburn Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3719'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '21f148c6-485d-48c5-8f46-813c3dcf817a',
        name: 'Nike Kicks Lounge Costanera Center',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7629'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '21f4efab-001f-4f1a-a3a4-2cdf6e090118',
        name: 'Nike Well Collective - Orio Al Serio (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '729'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '21fa9d89-837b-49f0-a6be-1023e3b37ff5',
        name: 'Nike Store New Rehovot',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2769'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: '22135b91-bc2b-4d3d-8207-bcc4ac44e533',
        name: 'Nike Factory Store Antalya',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '948'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '659456f0-a9b5-4c12-8bd8-649097373619',
        id: '222fcc4b-5154-4657-97ed-53b9ab1a1433',
        name: 'Nike Factory Store Ursus',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '947'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2269d358-8dff-49ce-a1ab-9320d3dcfaa9',
        name: 'TOPSPORTS江苏常州横街金坛八佰伴NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287543'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '22738e2f-9cad-44ad-880d-c370b37eb485',
        name: '北京市海淀区华润五彩城NIKE SPORT L',
        region: 'GREATER_CHINA',
        storeNumber: '269208'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '22a1e92d-0ef9-4eb9-af74-1810b513c060',
        name: 'Nike Somerset Collection North',
        region: 'NORTH_AMERICA',
        storeNumber: '487'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '22d3a139-32a0-42e4-9375-f4c937f9778a',
        name: '江苏省苏州市江苏苏州青剑湖龙湖天街KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '268806'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '22d5fa8f-0cf8-4aa5-986b-3738021af92f',
        name: 'NIKE上海青浦换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3401'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '22d7dcf0-a259-4216-b668-80b93b095273',
        name: '河南省驻马店市驿城区爱克玖隆茂Beacon L2-Ext',
        region: 'GREATER_CHINA',
        storeNumber: '267204'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '22fecabe-1866-4aec-aaaf-4e1575d27b82',
        name: 'POUSHENG安徽省芜湖市银泰城 SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274295'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '23056f46-2e4f-4876-bef0-6d4cdc4ff7dd',
        name: '나이키 현대 신촌',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '28450'
      },
      {
        address: {
          country: 'IND'
        },
        id: '23130024-301b-49b4-bb18-2f7838d8d695',
        name: 'Nike Tonk',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1631'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '232705c1-800c-4ff8-b268-897f949fa983',
        name: 'POUSHENG辽宁盘锦双台城购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287293'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: '23292D12-12EB-4F25-BB6F-CC85B105C2E1',
        name: 'Nike Clearance Store - Grapevine',
        region: 'NORTH_AMERICA',
        storeNumber: '135'
      },
      {
        address: {
          country: 'HKG'
        },
        districtId: '4da1e8ed-2893-4ac4-a578-e03e4ac4c5aa',
        id: '23523f8d-5ca5-4b80-a37b-6418c9616f1d',
        name: 'NIKE香港東涌換季優惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3263'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '238ff450-7cd5-4f04-aad2-a3fc31ef8810',
        name: 'Nike Melbourne Central',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4918'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '23ef2e11-49a4-45b4-96ec-3ac20afd80c5',
        name: '湖北省武汉市百丽武汉青山商场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '106341'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '23fba7e4-e846-4b96-9bfd-e9031ab5a22c',
        name: '滔搏福建泉州市丰泽区安吉南路中骏世界城1F+2F NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '273022'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '240cfa10-b230-4253-b853-7a41dd76e085',
        name: '耐克上海南京东路体验店',
        region: 'GREATER_CHINA',
        storeNumber: '6422'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '51a1785a-96a0-4fb2-a5d8-c3d531ada5b3',
        id: '2411031d-b98a-48e9-b88e-b8ccb3a0472e',
        name: '나이키 서울',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6104'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2430df51-315c-4d94-a433-b295c34faba4',
        name: '北京崇文区新世界一期BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '1489'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '2431aaad-b624-4927-9146-a4ccceb44160',
        name: 'Nike Factory Store - São Bernardo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6305'
      },
      {
        address: {
          country: 'AUT'
        },
        id: '2433b04d-11ce-4eab-b79a-725f452bc2b8',
        name: 'Nike Well Collective - Vösendorf (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2459'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '24350820-25b4-4f45-9ba3-33d8a1221066',
        name: '나이키 아이즈빌 인천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '58312'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '2445a957-0e99-4e94-8364-394112f42839',
        name: 'Nike Onaiza',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA079'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '244d9c8c-d994-495a-a4fa-fe31283e3d9f',
        name: '나이키 청주 성안',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '47349'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: '2455c1fb-d9fd-436b-8b24-09eacc127bed',
        name: 'Nike Factory Store Plaisir',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '645'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '245fcc3e-94bb-4e5a-9967-e60e8c864379',
        name: '北京朝阳区朝北大悦城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '191706'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '24749e43-839f-4037-8126-cb705653c0d5',
        name: '나이키 안산 고잔',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85930'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '2480b8b4-5485-4e56-91bb-0a960dca2b29',
        name: 'NIKE苏州人民换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3786'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
        id: '2497e7cc-dac9-4206-82ed-a5e01a89caf5',
        name: 'NIKE KICKS LOUNGE体验店 (环贸广场)',
        region: 'GREATER_CHINA',
        storeNumber: '3727'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: '24ade445-f87f-45ae-b9cb-a9dcf02fded7',
        name: 'Nike Factory Store Durham',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '802'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '24b6bb21-aeda-476f-ae49-72829b67af83',
        name: '河北省秦皇岛市海港区万达BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '260570'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '24b752fc-aff7-424b-87ac-a253b152305a',
        name: 'NIKE长沙阿波罗换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3613'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '24cd85b4-c0c1-4109-a7b9-9a93a518531e',
        name: '新疆乌鲁木齐友好北路BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '258616'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '24f924a7-0c62-4392-8f15-27580db917c5',
        name: '江苏省苏州市锐力苏州园区景城邻里中心DSP店',
        region: 'GREATER_CHINA',
        storeNumber: '195332'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
        id: '250ad962-a5ee-4d19-8bc7-07fb4ff12916',
        name: 'NIKE太仓员工店',
        region: 'GREATER_CHINA',
        storeNumber: '3669'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '2511c9c9-7513-4678-949b-892362fd8ec1',
        name: 'Nike Store Bucharest Mega Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM109'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '254c575a-c894-4146-a9b8-8fef99cc6f1b',
        name: '河北省石家庄市石家庄北国BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '1391'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '25719523-5568-4525-aa24-2d06cfc8be2e',
        name: 'POUSHENG内蒙古鄂尔多斯伊金霍洛西街王府井KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288492'
      },
      {
        address: {
          country: 'IND'
        },
        id: '2572e108-87d6-460e-a10f-a34384692399',
        name: 'NFS Dahisar',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090020'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: '257510d9-9f98-4992-a4af-989fbc6530c8',
        name: 'NIKE OFF SITE - GILROY OFF SITE',
        region: 'NORTH_AMERICA',
        storeNumber: '9037'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '25bb2006-6d8b-41fa-97a5-9d626ec6644f',
        name: 'TOPSPORTS河北唐山国丰大街港岛中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293686'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '25bb4cc5-6ae3-44a9-a2f8-321f6596a489',
        name: '安徽省淮北市百丽淮北南湖路万达广场Beacon L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '526839'
      },
      {
        address: {
          country: 'IND'
        },
        id: '25e85b02-de48-4eac-b067-d8f8a6c5ee15',
        name: 'Nike Linking 6',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5041'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '25e9f1d4-84e7-4ce3-b6d5-d03324b495d6',
        name: '安徽省合肥市宝胜政务区银泰城BEACON 750',
        region: 'GREATER_CHINA',
        storeNumber: '268803'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '260659c9-d071-45f9-aca3-1824f571c4a3',
        name: 'SHAANXI LIHE陕西西安唐延路万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '195427'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '260e44e5-e4d8-4e15-9276-fa46bf4f20bd',
        name: '나이키 연신내',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '26268'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '260f7f46-3c86-4bef-8cae-97dca6d7b148',
        name: 'Nike Kichijoji Running',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3019'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2631731a-5345-4e24-b9bc-00900de734d2',
        name: 'SANSE广东广州广州大道南广州合生广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287334'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '265b8165-4d80-48d0-b422-1f87c90f5b5a',
        name: 'TOPSPORTS江苏南通北大街万象城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '519943'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2663f006-62ca-47ea-89a3-c0982900fb0c',
        name: '江苏省苏州市锐力相城大道高铁吾悦购物中心NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270744'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '26754cda-01ba-4047-93e8-a4ec7453bfab',
        name: 'NIKE KIDS沈阳和平万象城店',
        region: 'GREATER_CHINA',
        storeNumber: '530912'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '267cfc7b-54fd-4faa-a0dc-ec70f916471b',
        name: 'Nike Store Malatya (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2776'
      },
      {
        address: {
          country: 'GRC'
        },
        id: '26805d1c-674d-46c1-8e46-3bb4894c386c',
        name: 'Nike Store Golden Hall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2441'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '26859070-227d-4ada-b2e8-db19142d8ffa',
        name: '四川省成都市锦江区春熙路金开运动城 BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '271216'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2699e5ea-61e1-41e2-b8ce-388b070f3675',
        name: 'POUSHENG河南周口东新万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287479'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '26b34382-b5c3-4523-a2a7-866884472bf9',
        name: 'Converse Brand Outlet Store Valmontone',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8115'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '26d614a5-14b7-4d11-bcba-44da7e18a076',
        name: 'Converse Factory Store - Tanger Outlet Mebane',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3756'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '51a1785a-96a0-4fb2-a5d8-c3d531ada5b3',
        id: '26e311d0-aded-4eec-b473-14a84bf40a59',
        name: '나이키 서울 CS',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6102'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '125d973d-d862-4d06-b2b2-5d14f960d4af',
        id: '26e8b85c-b695-4547-a46f-4f62faa3d9e4',
        name: 'Nike Factory Store Valladolid',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '810'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '2701a28a-26ee-476b-83c4-85a3d9fd9297',
        name: 'Converse Factory Store - Jersey Shore Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3740'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: '27068282-7b13-4d28-bcff-88c2df9e1a43',
        name: 'Nike Well Collective - CityCentre',
        region: 'NORTH_AMERICA',
        storeNumber: '229'
      },
      {
        address: {
          country: 'NZL'
        },
        id: '270d36dd-8d8d-4764-a3cf-a579cb0210f0',
        name: 'Nike Lambton Quay',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '850'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '270e7042-afff-4ccd-aec4-c2ce8bad5401',
        name: '浙江省瑞安市瑞祥大道吾悦广场KL L2',
        region: 'GREATER_CHINA',
        storeNumber: '270865'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
        id: '27160ab2-68f8-4776-baaa-0d9d56773818',
        name: 'NIKE MITIKAH',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '522129'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2728ad1b-d55f-484d-a0cb-eb5d27d97e57',
        name: '江苏省徐州市徐州市鼓楼区中山北路金鹰国际购物中心二期',
        region: 'GREATER_CHINA',
        storeNumber: '258757'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '273d91a5-5e1d-4a13-a8b6-5fabac60679a',
        name: '나이키 AK 세종',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85938'
      },
      {
        address: {
          country: 'NZL'
        },
        id: '274b2222-a609-48bc-b083-63b131be6b70',
        name: 'Nike Well Collective - Auckland',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8007'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'c39640bc-c790-42de-8810-e227062b5fb5',
        id: '274d2842-a679-40a8-b3de-ce11ca839525',
        name: 'Nike Shinsaibashi',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3054'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '274d30f6-073d-4740-ac8b-e7605adccbb2',
        name: 'REALLY上海奉贤宝龙NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288284'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '274f6a46-ffcf-4014-8311-8cbd1f1ade7e',
        name: '云南昆明五华荟聚中心NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '273476'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '277cbbe6-1aea-449e-bd14-d32996410806',
        name: '河北省保定市万达广场(未来石店) BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '243661'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: '277d91ad-abe5-4599-a5de-7f08cd7084a7',
        name: 'Nike Well Collective - Santana Row',
        region: 'NORTH_AMERICA',
        storeNumber: '411'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '278fb72b-6c78-48d8-8de6-55960e0b1634',
        name: '河北邯郸市丛台区联纺路阳光天鸿广场NIKE BEACON L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '269457'
      },
      {
        address: {
          country: 'IND'
        },
        id: '279f878a-331e-46c7-bd4c-291969adc3d3',
        name: 'Nike Inorbit Malad',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5070274'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '27cab8ac-2e71-4e81-9a86-b4ffb7891cb0',
        name: '浙江省杭州市滔搏杭州下城延安路武林银泰ABEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '189382'
      },
      {
        address: {
          country: 'IND'
        },
        id: '27e7f4e0-f379-41df-86dc-6b8a213fa319',
        name: 'Nike Forum Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4733'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '27f29d9f-9bf2-499a-8e1e-20466591759b',
        name: 'Nike Galería Imperio',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3084'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '281ed841-94a4-4f35-82ed-4936a8f0b27d',
        name: 'TOPSPORTS北京延庆区环球新意百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '275329'
      },
      {
        address: {
          country: 'EST'
        },
        id: '28238308-f237-4f58-b6fd-9d428e9e88af',
        name: 'Nike Store Tallinn Viru Keskus (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EST003'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: '28253945-6D2F-4DF4-8A18-4B11B139C5AD',
        name: 'Nike Factory Store - Monterrey Centro',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6627'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '286E3D93-5FD2-4F15-8FE6-E3CEC49F9BB7',
        name: 'Nike Factory Store - Edinburgh',
        region: 'NORTH_AMERICA',
        storeNumber: '6'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '286eaea9-dc50-48c2-a0ff-18ba33d3533a',
        name: 'HIGHWAVE SPORTS四川宜宾护国路与长翠路交叉路口万象汇NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288451'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '288ca477-033d-4819-80b4-9977153f7ecb',
        name: '耐克贵阳中华路体验店',
        region: 'GREATER_CHINA',
        storeNumber: '482144'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '28926ff8-b3e9-4e77-86f3-2f761341116d',
        name: '广东省广州市宝胜广州天河区正佳广场 BE-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '259082'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '28b8b3a9-8a74-44b1-a650-66fad83368fa',
        name: '广西壮族自治区梧州市百丽广西梧州市万秀区高旺路万达广场1楼NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273145'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '2904b9a3-f48a-4338-bdcb-c0c8eeebe395',
        name: 'Nike Northland',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7172'
      },
      {
        address: {
          country: 'CHE'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: '29096f4b-1f46-49b9-a654-e94db32a70d5',
        name: 'Nike Factory Store Landquart',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '630'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '291ba546-b46b-4283-bf2f-cd309ae2c557',
        name: 'Nike Well Collective Yokohama',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3198'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '293b227e-91fb-44f7-a77c-c2e4f4d70b0e',
        name: '나이키 현대 가든파이브',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '76534'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '2942bbe3-bbce-41c7-bb90-1035aee2b540',
        name: '나이키 현대 일산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '42119'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '294dd4b9-1801-461e-97bf-9fc083c57d1c',
        name: 'NIKE西安迎宾换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3859'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '294ef174-c2c4-4514-af1e-db26b6eab8a5',
        name: '나이키 에버세이브 청주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '59121'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2953520c-fc4b-426b-b3cc-fcbca04d7986',
        name: '辽宁省沈阳市皇姑区万象汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274025'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '29645fa1-a083-45fa-84ab-b5387f2490ac',
        name: '나이키 현대 미아',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '25543'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '659456f0-a9b5-4c12-8bd8-649097373619',
        id: '29717ebb-3554-41be-9c72-8209548a0765',
        name: 'Nike Factory Store Warsaw Fashion House',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2040'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '29749b80-4277-4d64-9475-04da138c0d71',
        name: 'NIKE杭州江滨换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3876'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2977ca5e-a472-4d45-8176-1b1df2d11ab7',
        name: 'REALLY江苏如东珠江路中央商场KICKSLOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287547'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: '29a18e74-1b9c-42cd-81de-98043604a7b3',
        name: 'Nike Factory Store - Fort Worth',
        region: 'NORTH_AMERICA',
        storeNumber: '286'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '29a75895-2c0a-40ab-9d47-7873b1946520',
        name: '沈阳和平青年大街万象城BEC',
        region: 'GREATER_CHINA',
        storeNumber: '195346'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '29b0b916-3d3f-4946-bab6-dfee91d3475b',
        name: '四川遂宁万达BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '259352'
      },
      {
        address: {
          country: 'IND'
        },
        id: '29ca8e8f-7b42-44cd-b076-250a24af6a93',
        name: 'Nike CG Road 2',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5065'
      },
      {
        address: {
          country: 'ZAF'
        },
        districtId: '43bbce93-a2ad-4606-a7df-205395646fdf',
        id: '29e6e666-86dd-440d-a938-6469eb53b534',
        name: 'Nike Factory Store Woodmead',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '954'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '29fc8297-d927-4c37-ab6f-874ea2c229e8',
        name: '浙江省杭州市锐力杭州市富春路万象城 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '266733'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: '29fd914b-ee4f-48a9-b0fd-d65f3eab1fc7',
        name: 'Converse Factory Store - Tampa Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3107'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: '2C6A2EF1-1150-401A-A9CD-DC6573BC5FDF',
        name: 'Nike Clearance Store - Woodbridge',
        region: 'NORTH_AMERICA',
        storeNumber: '174'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '2E998ED8-A18A-40D0-8A52-F5F372256329',
        name: 'Nike Factory Store - Mebane',
        region: 'NORTH_AMERICA',
        storeNumber: '370'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: '2ECFC5A2-2865-4953-8A39-4C420E50BB48',
        name: 'Nike Factory Store - Destin',
        region: 'NORTH_AMERICA',
        storeNumber: '89'
      },
      {
        address: {
          country: 'LBN'
        },
        id: '2a270f5a-83d7-45a4-b01c-9b0fd7bc8591',
        name: 'Nike Hamra',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LEB073'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: '2a2eb69d-de72-4afa-8c69-910847c26e81',
        name: 'Nike Unite Herblay',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2047'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '2a3340ef-c8f1-446f-8a5a-bad88fe2f39c',
        name: '나이키 롯데 노원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '91127'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a58f784-3ef9-4ebc-bd8a-cb00c2500c37',
        name: 'TOPSPORTS上海淀山湖大道新城吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287263'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a659955-3823-4c4a-93cf-4c5c3b0a96ca',
        name: 'TOPSPORTS河南洛阳洛龙区牡丹大道大商NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287478'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a809dce-5cd8-4578-9ecd-5773e4038e07',
        name: '广东省广州市盛世长运广州中华广场 NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270985'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a86ab98-aa06-4e13-a3d1-f79479b68d0b',
        name: '浙江省宁波市锐力浙江宁波市海曙区中山东路天一银泰NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '267206'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a8fb8da-99fc-4656-9699-bdf9c13b966f',
        name: '内蒙古自治区呼和浩特市呼和浩特市赛罕区新华东街维多利摩尔城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '266998'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a8fd776-cb9c-47d9-98f7-f461051f75fd',
        name: '耐克南通金鹰购物中心体验店',
        region: 'GREATER_CHINA',
        storeNumber: '195049'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a960a6d-5aca-437f-9a7a-86d6e1d8cfe2',
        name: '新疆维吾尔自治区乌鲁木齐市新疆乌鲁木齐CC MALL 时代广场HOOP DREAMS-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269452'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2a9bae93-d043-4418-81e2-95ea53497534',
        name: '浙江省金华市浙江胜道金华银泰福泰隆店SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '155'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2aa94513-edcf-4e6c-bb40-112da7ba384a',
        name: '山西省太原市迎泽区柳巷南路美莎运动城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '179094'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2aafd5fe-7005-45a9-9a66-94bf5c736d85',
        name: '北京大兴龙湖NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '272962'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: '2af1634d-56d4-4bad-b27f-5b97fa906be0',
        name: 'NIKE北京丰台换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3855'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '2afcd856-80f2-4445-866d-100b68500828',
        name: 'Converse Brand Outlet Store Barcelona',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8108'
      },
      {
        address: {
          country: 'GRC'
        },
        id: '2b080b00-c8ab-45fe-80b6-643b9294686a',
        name: 'Nike By Kifisias (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2479'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2b2c4854-385e-4dba-9ee6-4d84ec77f16f',
        name: 'POUSHENG新疆克拉玛依汇嘉时代NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274318'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '2b462e31-8149-4f0f-af66-8ee0915fdc3e',
        name: 'Nike Carindale',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8027'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2b47804d-909d-460e-92e5-71a574751486',
        name: 'TOPSPORTS山东桓台桓台中心大街惠仟佳NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279062'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '2b7efd0b-f07b-43ec-b164-3d0496e11051',
        name: 'Nike Store RASHID MALL',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA020'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
        id: '2baf9829-9988-4604-aecd-38da7a73e102',
        name: 'Nike Factory Store Groningen',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2043'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '2be5565c-7d71-454c-b58e-6f6b338c3a6a',
        name: 'Nike Parque las Antenas',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3278'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2beb90da-c4f2-415d-b00b-c619509552f5',
        name: '重庆长寿桃花大道长寿重百NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '12706'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2bf7faf5-8f89-441d-a49a-8bf8dfe1cbf0',
        name: '辽宁省沈阳市沈河区运动汇 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '266738'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '2c5e095a-9576-444a-9d38-f98acaf5bf3b',
        name: 'Nike Makkah',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA076'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2c8c5014-9cfa-448c-a6c5-d2890bec40a4',
        name: '福建省厦门市湖里区SM城市广场Beacon',
        region: 'GREATER_CHINA',
        storeNumber: '270869'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2c9cf5c2-c08b-411e-83e0-51d1c301702d',
        name: '浙江省义乌市宝胜义乌银泰伊美店耐克NIKE SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '266731'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '2caec3d2-033f-4c11-b516-a811b7b141cb',
        name: 'NIKE換季優惠店 內湖大潤發',
        region: 'GREATER_CHINA',
        storeNumber: '3374'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2cb40452-d749-4cc9-a908-7eedf8df6e26',
        name: '内蒙古呼和浩特市中山西路维多利商厦NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '241504'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '2cba22d2-6e3e-4efa-a9c3-cebaf6b94ea9',
        name: 'Nike Store Lyon Part Dieu (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2496'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '2cd261ec-9bf8-48b2-ba42-d9c913dc0bc2',
        name: '나이키 신림',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '83874'
      },
      {
        address: {
          country: 'IND'
        },
        id: '2cd31f34-294e-44b8-b1d7-314b2148b6bf',
        name: 'Nike Gandhidham',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5048127'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2cf8e66c-8dc5-4571-b886-fa4a027a550c',
        name: 'POUSHENG黑龙江双鸭山新兴大街第一百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288395'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: '2cfed350-3380-4199-8931-71f40536cb57',
        name: 'Converse Clearance Store - Florida Keys Outlet Center',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3798'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d1cc5c8-0188-457f-a9aa-495e5a32c62b',
        name: '柳州五星大厦NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '274316'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d24c13c-f1f1-4f4f-936e-1b600c41ab92',
        name: '浙江省衢州市衢州东方商厦',
        region: 'GREATER_CHINA',
        storeNumber: '267207'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d3667b2-1593-4f59-be23-7b1d5e1fd618',
        name: '河北省唐山市路北区学院北路吾悦广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269146'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '2d3ae1a1-5400-43db-8e02-42e57ab2d358',
        name: 'Nike Well Collective - Red Rock',
        region: 'NORTH_AMERICA',
        storeNumber: '445'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '2d4ab0c2-872e-40d4-9bad-f682a8c69e80',
        name: 'NIKE苏州钟园换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3805'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '2d52eab4-d52f-49a9-9f64-e8aa0b56af93',
        name: 'NIKE宁德天湖换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3680'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d5bc474-87da-4048-996e-ab97b01920b0',
        name: '耐克大连恒隆广场KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '258604'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d65b30e-e655-4ba8-a1b4-5a0f1d2b1778',
        name: '天津市和平区恒隆 KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '267096'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d6d2b6a-732e-4e39-9956-101a5a91a0ee',
        name: '安徽省合肥市合肥滨湖银泰城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '525673'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '659456f0-a9b5-4c12-8bd8-649097373619',
        id: '2d7948dd-d0cc-4b6a-be75-30f9c3ff5d17',
        name: 'Nike Unite Gdansk',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2017'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d84f505-c1cb-46ae-b3e4-be69161d8c24',
        name: '江苏省常州市锐力金坛新天地购物中心NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '246702'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d9952e7-8834-4f9a-bc35-0417ac629b9a',
        name: '贵州贵阳观山湖华润万象汇KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '294017'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2d9a5b81-c539-414f-b440-997115ba9cc4',
        name: 'TOPSPORTS上海龙华路龙华会万科里NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288376'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '2da5ef45-d328-4d56-b849-fcd46be8c5aa',
        name: 'Nike Store Beer Sheva',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2712'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2da85906-43a9-4595-a416-b0f05b3bf107',
        name: '湖北省武汉市百丽武汉市江汉区中山大洋 BEACON-L EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '3121'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2dbc028c-8def-472e-88ef-ae1f7637de8b',
        name: '吉林省延吉市中百商厦BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '246717'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2dc7378b-33fe-4bc2-bdcd-d15958e7adcf',
        name: '广西壮族自治区百色市百丽广西省百色市右江区东增路爱琴海购物公园NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '246882'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '2dcc1c96-9be0-4101-a017-f3f27132c418',
        name: '나이키 광주 용봉',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '50621'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '2dcd5985-cc9b-4c9f-98f0-7cd88473cff1',
        name: 'NIKE南京双龙换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3415'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '2dda33db-0e16-400b-ac9e-8b12374f7df3',
        name: 'Nike Alto las Condes',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7241'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2dde923d-963e-4336-91f3-3e8dd83553ca',
        name: '滔搏海南海口龙华区南海大道友谊南海城国际购物中心1F NIKE SPORT–M',
        region: 'GREATER_CHINA',
        storeNumber: '273488'
      },
      {
        address: {
          country: 'PRT'
        },
        id: '2ded523c-1c87-4b92-bb65-ac62209b011d',
        name: 'Nike Store Porto Norte II (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2473'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2deeda66-a0b1-4080-8c8f-36c45b0535fe',
        name: '广东省深圳市百丽深圳光明大仟里NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '531925'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: '2dfa96a3-51f9-4d5e-b09c-de178a289712',
        name: 'Nike Factory Store Birmingham',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2000'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2dff219c-46a9-4eb7-8db5-875a3806e7a8',
        name: '山东省青岛市黄岛区井冈山路利群购物中心NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '271319'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2e093967-0a77-4152-9a83-82a1df08473e',
        name: 'TOPSPORTS广西柳州中山路五星商厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '275363'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '2e29111a-457d-4452-9295-e232afbdb831',
        name: '나이키 롯데 센텀',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '39861'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2e4b5e96-a907-4668-9dab-1190817eebcc',
        name: '广东省深圳市盛世长运深圳龙岗万达广场Beacon L2-M',
        region: 'GREATER_CHINA',
        storeNumber: '273124'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2e6e98ee-a7b4-4caf-9330-8e5eb40ca236',
        name: 'Sanya Yalongwan FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6031'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: '2e8b06c1-65c1-4903-ac18-eae2e720a570',
        name: 'Nike Unite Koshigaya',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3186'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2e8fb157-d364-41a2-9081-1dc86cde967a',
        name: 'JORDAN 546 YANAN',
        region: 'GREATER_CHINA',
        storeNumber: '481698'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '2e96c20d-29be-40f8-ad28-4a13c64ffc24',
        name: 'Converse Factory Store - Legends Outlets Kansas City',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3708'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2ee44f03-d1cc-47c8-9aab-02b4d356729b',
        name: '四川自贡新美运动城SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '485628'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '2eff7786-30ae-4e93-b2fa-0216a402da4f',
        name: 'NIKE郑州华南城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3861'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: '2f2c2020-42b8-4425-bbee-b4da59e890d4',
        name: 'Nike Well Collective - Domain Northside',
        region: 'NORTH_AMERICA',
        storeNumber: '430'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2f377f85-1ab5-4b06-93dd-33fb95e6b333',
        name: '山西朔州万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '243710'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2f64d3e2-a668-4ea2-a3c3-b8428a0ae38e',
        name: 'TOPSPORTS江苏南京中大街海乐城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287529'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2f7484da-acd7-49c4-bc2f-9474915b1b04',
        name: '浙江省台州市椒江宝龙城 SPORTS L',
        region: 'GREATER_CHINA',
        storeNumber: '274496'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2f8a64b1-3300-4e6c-8f49-1bd711a59f8c',
        name: 'TOPSPORTS山东潍坊东风东街KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '288172'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '2f8f01da-3896-4b77-9cf9-b8703cd432e6',
        name: 'Nike Store Panora (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2685'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2f9e277d-81a6-4006-a1fa-ae7fb14d239e',
        name: 'TOPSPORTS浙江湖州吴兴南街银泰商业BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '274039'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2f9f40ad-42a7-4d80-9f78-08ff0a9daf87',
        name: '浙江省台州市宝胜黄岩吾悦广场KL L2',
        region: 'GREATER_CHINA',
        storeNumber: '531783'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: '2fa34ef2-0187-4141-97a2-5539c411ff42',
        name: 'Nike Factory Store Izmit',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '683'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2fbd64e6-c738-4512-abba-e4afaa834c49',
        name: '贵州遵义汇川珠海路国贸二店购物中心NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '273599'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '2fc10e87-8b16-4ea6-929e-c7d62ca62d0e',
        name: '江苏省苏州市东吴龙湖天街KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '272109'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '659456f0-a9b5-4c12-8bd8-649097373619',
        id: '2ff935eb-0aa9-4606-8bc1-9ad2c59ce935',
        name: 'Nike Factory Store Ptak',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2025'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: '3068f7cd-e47a-4214-a2ce-0c7b2e6f84ef',
        name: 'Nike Well Collective - Century City (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2308'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '309443bc-0d7b-4675-be12-544480bb6857',
        name: 'Nike Clearance Store - Dania Pointe',
        region: 'NORTH_AMERICA',
        storeNumber: '469'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: '309CB4EE-3CE1-4E19-BB41-B8A34EBF972E',
        name: 'Nike Factory Store - Kansas City',
        region: 'NORTH_AMERICA',
        storeNumber: '130'
      },
      {
        address: {
          country: 'IND'
        },
        id: '30a49e60-8a44-47dc-a4b6-6efd80528b0a',
        name: 'Nike Jio World Drive',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4910'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '30ac00fa-2f17-4b9f-a7ec-2582fcc193bb',
        name: '山东省济南市历城区洪楼银座BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241162'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '30ae6caa-522e-47ff-b217-5a9bb4d28d85',
        name: '湖北省襄阳市襄阳天元四季城 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '242349'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '30b2e472-c9c2-436b-b025-0a7d0b154f5e',
        name: 'WuHan Aeon FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6016'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '30d56e36-564f-43af-ba14-2b166ed1f79b',
        name: '辽宁省大连市沙河口区西安路罗斯福 BEACON-L2',
        region: 'GREATER_CHINA',
        storeNumber: '329366'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '30e57540-5409-4bed-b124-96717859bdf2',
        name: 'NIKE成都万科换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3677'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: '30fccf89-e941-4059-8985-1beed0c786fa',
        name: 'Nike Community Store - Watts',
        region: 'NORTH_AMERICA',
        storeNumber: '264'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '011c974d-ad7b-4a21-ae1b-ff52a2a066cd',
        id: '3108a5c0-f3a1-4802-b04d-de54d3de8742',
        name: 'Nike Factory Store - Queretaro PO',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6639'
      },
      {
        address: {
          country: 'THA'
        },
        id: '31284792-e7c8-4a72-9880-22213d3cd226',
        name: 'Nike Store Central Plaza Westgate',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2751'
      },
      {
        address: {
          country: 'BIH'
        },
        id: '3133ff0f-3f04-4ddb-9a1a-77ef41955d03',
        name: 'Nike Store Sarajevo City Center (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BOS100'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '313aa3c4-fea8-4aa0-8bfb-56a85dcdd77e',
        name: '长沙中心印象城NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '269151'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '313af177-de7a-4bbb-831a-4ef5449b127f',
        name: '나이키 범일',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95904'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3168bd59-ca20-4546-bc0b-49984b55940c',
        name: '江苏省镇江市宝原镇江苏宁广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '245795'
      },
      {
        address: {
          country: 'POL'
        },
        id: '3170a588-c13f-4ffa-8cb2-23f576e6f012',
        name: 'Nike Store Mokotov (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2486'
      },
      {
        address: {
          country: 'IND'
        },
        id: '318f283c-36a7-465f-ad1a-ed4a3a96034d',
        name: 'Nike PVP SQUARE MALL',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8257'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '31900f06-50f6-4189-ba8d-e3a048d6060c',
        name: 'POUSHENG新疆乌鲁木齐MM3(原昊元尚品购物中心)MM3NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288051'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '31940ba0-cdeb-4aa8-9bac-fec26d66d5fd',
        name: '江苏省无锡市宝胜中山路260号街铺Beacon 1200',
        region: 'GREATER_CHINA',
        storeNumber: '269224'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '31DDFFA3-4E46-43F9-9A57-FB1A16FD9152',
        name: 'Company Store',
        region: 'NORTH_AMERICA',
        storeNumber: '19'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '31b2a991-7624-46f5-a69d-03395a2f29f0',
        name: 'TOPSPORTS浙江湖州吴兴区南街浙北大厦NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288817'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: '31b9da1a-4b54-4b50-9203-545ec5e9b672',
        name: 'Nike Unite Sano',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3035'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '31cc1f8f-32b0-4df3-803d-d3047794d02e',
        name: 'POUSHENG湖南岳阳东茅岭路步步高新天地NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '281933'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '31eb2b7b-d2dd-4879-8623-ba263d34ea1c',
        name: '江苏省南京市江宁万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '268770'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '31fc6569-ddba-4060-a3da-0acc51d76645',
        name: '广东省深圳市深圳宝安万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270495'
      },
      {
        address: {
          country: 'THA'
        },
        id: '31ff3616-7c3d-489b-8908-0d460adced55',
        name: 'Nike Bangkok',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1445'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '3226440f-a201-4a6e-a4f4-4022f5e99fa6',
        name: 'Nike Well Collective Diagonal (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2458'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: '3231DF4C-F80E-433B-B510-91D6193B591F',
        name: 'Nike Factory Store - Dawsonville',
        region: 'NORTH_AMERICA',
        storeNumber: '14'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '324c9a83-fc95-4158-ad56-8bbdc74d90e7',
        name: 'POUSHENG新疆乌鲁木齐友好北路MM2KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '288426'
      },
      {
        address: {
          country: 'BEL'
        },
        districtId: '031af3aa-9b11-4b5d-b05f-80d63e997ea7',
        id: '325f6b23-3415-4c16-82ab-4bbeba57b027',
        name: 'Nike Factory Store Antwerp',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '643'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '32865b61-3f25-49d6-90c2-60f9626b968e',
        name: 'Nike Wollongong',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9404'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '32a1e7e3-7678-44e3-a355-97b0facdb505',
        name: 'SANSE海南省海口市龙华区海秀中路71号王府井JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288430'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '32b65f0a-9570-40b6-af3d-aa34d997b5c1',
        name: 'POUSHENG北京黄厂路惠多港KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287319'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: '32ba5c4a-992e-4ff4-86bc-8554746292c0',
        name: 'Nike Well Collective - University Park Village',
        region: 'NORTH_AMERICA',
        storeNumber: '463'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '32c9a6e9-2d73-47c6-9f83-fd2656b802f2',
        name: '浙江省嘉兴市滔搏嘉兴南湖中山东路旭辉广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270750'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '32d95f62-d4ea-40b0-b60b-045953d1a59f',
        name: '山东省济南市历下区印象城Kicks lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '244491'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '32d97f5d-7cd0-4626-b4a3-0b5ad7ec9c7a',
        name: '河南省驻马店市驿城区雪松大道爱家购物中心Beacon L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '240349'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: '32e2ff64-9844-4285-b661-c1c761d95328',
        name: 'Nike Factory Store Jettingen-Scheppach',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '922'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '524440b5-a003-410b-9e4b-25c2de21868b',
        id: '32ec6e90-5831-437b-9f23-612d2b5f8e13',
        name: 'Nike Unite Castel Romano',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '563'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '32ef1902-723a-47e7-9b9e-e55bd5d1073e',
        name: '重庆市南岸区江南大道南坪万达KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '241685'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '32fe75af-456c-4c84-9b1d-2c420d167c7a',
        name: 'Nike Werribee',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6227'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '332f6b8a-905c-49ad-a551-2c53cdf8064a',
        name: '辽宁沈阳沈北新区黄河北大街吾悦广场KICKS LOUNGE L2',
        region: 'GREATER_CHINA',
        storeNumber: '272379'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '33370967-cdbb-4e7b-964d-c72694de8dd7',
        name: '上海市宝胜长宁龙之梦KL L1',
        region: 'GREATER_CHINA',
        storeNumber: '267092'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '334dcb7a-498a-40d2-b1bd-66999ffb9768',
        name: 'TOPSPORTS内蒙古包头青山区王府井NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287470'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: '3368E5B2-6CBA-4034-BB09-05D337F76F1C',
        name: 'Nike Factory Store - Paramus',
        region: 'NORTH_AMERICA',
        storeNumber: '349'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '337203f8-27b5-44b5-ac14-e25b082a83bb',
        name: '吉林省吉林市昌邑区重庆街财富BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '246571'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '337731c6-bdcd-4fd5-a3db-135756c5e563',
        name: '河南郑州中原区科学大道正弘汇NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274487'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3381419f-ce7b-4b89-b073-f7892e910ebb',
        name: '나이키 롯데 건대',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '91128'
      },
      {
        address: {
          country: 'POL'
        },
        id: '3384ad75-c24c-42b2-94a3-3ab3aca29bd5',
        name: 'Nike Store Gdynia Riviera (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2668'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '3393a6a2-6509-48af-ae6c-e108d4d91cec',
        name: 'NIKE厦门海沧换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3505'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '33973b2b-ded9-4c6c-a372-896c1fa60280',
        name: 'NIKE青岛峨眉山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4016'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '339EF669-C20D-4B2E-E053-36680C0A6639',
        name: 'Nike The Grove',
        region: 'NORTH_AMERICA',
        storeNumber: '246'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '339EF669-C228-4B2E-E053-36680C0A6639',
        name: 'Nike Chicago',
        region: 'NORTH_AMERICA',
        storeNumber: '60'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '339EF669-C25A-4B2E-E053-36680C0A6639',
        name: 'Nike Las Vegas',
        region: 'NORTH_AMERICA',
        storeNumber: '93'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: '339EF669C20E4B2EE05336680C0A6639',
        name: 'Nike Well Collective - Fashion Valley',
        region: 'NORTH_AMERICA',
        storeNumber: '307'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: '339EF669C2134B2EE05336680C0A6639',
        name: 'Nike San Francisco',
        region: 'NORTH_AMERICA',
        storeNumber: '86'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: '339EF669C2154B2EE05336680C0A6639',
        name: 'Nike Well Collective - Lynnfield',
        region: 'NORTH_AMERICA',
        storeNumber: '240'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '339EF669C22F4B2EE05336680C0A6639',
        name: 'Nike Santa Monica',
        region: 'NORTH_AMERICA',
        storeNumber: '379'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: '339EF669C2474B2EE05336680C0A6639',
        name: 'Nike Boston',
        region: 'NORTH_AMERICA',
        storeNumber: '81'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: '339EF669C2584B2EE05336680C0A6639',
        name: 'Nike Well Collective - South Coast Plaza',
        region: 'NORTH_AMERICA',
        storeNumber: '303'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: '339ada28-707b-4a8c-89dd-2ab316793ef7',
        name: 'Nike Clearance Store - Sacramento',
        region: 'NORTH_AMERICA',
        storeNumber: '573'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '33e2dfe6-6525-4a83-8d6c-e85909a358d5',
        name: 'TOPSPORTS浙江杭州五常大道印象城NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '281956'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '33e863a8-ac1a-4e6d-a790-d49210f0dc1f',
        name: 'NSP Gindi TLV',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2767'
      },
      {
        address: {
          country: 'CZE'
        },
        districtId: '3c364fbe-617b-46b1-b867-1579b1e478f2',
        id: '33f3b190-dcb8-437e-af2d-7a1ac398e34c',
        name: 'Nike Factory Store Prague Airport',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2049'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '33fc895e-9545-4987-9618-2e502c61a875',
        name: '나이키 아이파크몰 용산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85979'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '341ff324-86aa-4ea4-94bd-9b475d9cec20',
        name: 'TOPSPORTS上海广富林路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287294'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '3481e158-e520-40d1-802e-a80b1db15839',
        name: 'Nike Store Ramat Aviv (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2324'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '348252a6-f9a8-4636-83d5-6d98e9171bf7',
        name: '耐克武汉江汉路KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '240449'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
        id: '348f8f91-9241-4685-b71b-fba178153680',
        name: 'Nike Factory Store Roppenheim',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '680'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '34930d95-b50e-4278-93b5-868b9e34f96c',
        name: 'NIKE济南春晖换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4014'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '34a61ff6-4895-49c2-aefd-239067d0689f',
        name: 'Nike SM Pampanga',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090058'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '34a936d9-4040-45ba-8bb5-1d1e532b87fa',
        name: 'Nike Mississauga',
        region: 'NORTH_AMERICA',
        storeNumber: 'CA8'
      },
      {
        address: {
          country: 'PRT'
        },
        districtId: 'd018da20-ba06-4a8b-82d2-eaa2a5670c5d',
        id: '34aea763-7504-4cb7-95fb-8d23f9567cc8',
        name: 'Nike Factory Store Lisbon Freeport',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '812'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '34b7e1b3-1bef-4716-88b0-501c0c49b9f2',
        name: 'NIKE上海大宁换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3403'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '34cc7215-44ff-4a71-9880-a7ae5935a5dd',
        name: 'Nike Unite - Antioch',
        region: 'NORTH_AMERICA',
        storeNumber: '490'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '34d4e2c0-3e63-4758-854e-6d47330e7a49',
        name: '나이키 노량진',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '26269'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '34f1c7dd-0b16-459f-8263-a137f8d6e147',
        name: 'Nike Store San Stefano',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY053'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '34fdc04e-9d05-400c-ace4-1e2d8988ef9e',
        name: '四川省成都市成都市郫都区龙湖蜀新天街KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287267'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '350043d3-a1df-4bb4-b8d7-256736165c99',
        name: '吉林省长春市南关区摩天活力城Nike Sport Large',
        region: 'GREATER_CHINA',
        storeNumber: '271171'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3530d41c-85b6-40f6-b443-a275a0d9bf13',
        name: '广东省东莞市盛世长运东莞虎门万达KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '259362'
      },
      {
        address: {
          country: 'BGR'
        },
        id: '35461e56-7864-4a74-95db-735e7041bbc2',
        name: 'Nike Store Plovdiv Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BUL126'
      },
      {
        address: {
          country: 'BEL'
        },
        id: '3558315b-bedd-4c6f-8765-e8d5470b0714',
        name: 'Nike Store LEUVEN',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2355'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '358d0625-550a-4507-8147-9579b5ba1811',
        name: '四川省成都市成都环球中心 Beacon Level 2 Extend',
        region: 'GREATER_CHINA',
        storeNumber: '268681'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3598655e-2a7e-4d9a-8933-63fc90c6a6e6',
        name: '上海市锐力上海龙皓路金山万达KL L3',
        region: 'GREATER_CHINA',
        storeNumber: '485193'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '359ab6d5-96a0-43a4-a354-111c365c72cd',
        name: '黑龙江省大庆市大庆市开发区新玛特KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '481322'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '35C06930-8D67-42B4-9328-929520003EA3',
        name: 'Nike Factory Store - Sawgrass Mills',
        region: 'NORTH_AMERICA',
        storeNumber: '131'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '35bc88a2-89ba-4c9a-9044-bf6523eb0f43',
        name: '河北秦皇岛海港区文化南路茂业金都NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271105'
      },
      {
        address: {
          country: 'MYS'
        },
        id: '35c787fc-d253-4ce5-b75a-a1768ef9f091',
        name: 'Nike Well Collective – Sunway Pyramid',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6302'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: '35d89b69-c14c-4b22-9a94-00346c2951ce',
        name: 'Nike Well Collective - Cranston',
        region: 'NORTH_AMERICA',
        storeNumber: '320'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '35ecb45f-780b-4f70-8875-07ab0d01eaf3',
        name: 'Nike Store Almaza City Center',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY056'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '36071416-37d7-46e6-8948-3069f60c40f0',
        name: 'Nike Castle Towers',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9785'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '360af696-7d0c-440c-8ac8-00e8915d020c',
        name: 'Nike Outlet Kyiv Plaza (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR027'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '36222bdb-cf1d-49b3-ad37-7c34c3353a3b',
        name: '浙江省宁波市锐力宁波市江北区来福士广场BEACON 550',
        region: 'GREATER_CHINA',
        storeNumber: '267162'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '362fbb01-8bb0-4c0a-bc4a-b3b7f19de8ee',
        name: '耐克北京淘汇体验店',
        region: 'GREATER_CHINA',
        storeNumber: '269508'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '364a5c69-8d44-42ce-acfe-84c6a0d38de4',
        name: 'Nike Bali Galeria',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4753'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3653f0d5-bbee-45c6-b337-cee0c05a91f7',
        name: '福州鳌峰万达广场NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '241676'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '2f1b4c39-e8ac-4cea-abd2-95d8b9b7aefe',
        id: '365eb1e7-42e5-4489-b6a4-4ec6e89834cf',
        name: 'Nike Unite Berlin Eiche',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '995'
      },
      {
        address: {
          country: 'IND'
        },
        id: '365f44cf-f6c1-4a8a-bfdd-53d8fdceddb9',
        name: 'Nike Oberoi',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5070472'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: '36654FC1-BDBE-4EE8-A6ED-43E9A44D9536',
        name: 'Nike Factory Store - Zapopan',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6622'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: '3669cc02-ddff-4cc4-bbdc-3ba02dfe98c5',
        name: 'Nike Unite - Watertown',
        region: 'NORTH_AMERICA',
        storeNumber: '283'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: '367e498a-7a26-49f8-82df-809ec3ecc28e',
        name: 'Converse Factory Store - Tanger Outlet Houston',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3770'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '368f1e3f-43b1-4bb6-8315-c3613c223e78',
        name: '나이키 스퀘어원 인천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '50588'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '36aa9551-4c69-4e39-a9ae-fdd8004099f4',
        name: '四川省西昌市航天大道五段万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270732'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '36d098be-b537-45df-be50-552833915171',
        name: '浙江省杭州市杭州萧山银隆二期HOOP DREAMS-L2',
        region: 'GREATER_CHINA',
        storeNumber: '135890'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '36ed5b6a-8300-47a6-8511-0c944996da9a',
        name: 'NIKE重庆南滨换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3847'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '36f976c4-2b88-4318-b013-93b562479f71',
        name: '北京海淀区复兴路万达广场KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '288814'
      },
      {
        address: {
          country: 'NLD'
        },
        id: '371f45c2-3fcb-4380-9d3c-329312549489',
        name: 'Nike Well Collective - Grote Houtstraat (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2421'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '37204af9-4bc8-4ccf-adec-dd00dc48967d',
        name: 'Nike Well Collective - Cary',
        region: 'NORTH_AMERICA',
        storeNumber: '458'
      },
      {
        address: {
          country: 'MYS'
        },
        districtId: '0f249a59-1a39-4d43-815f-b9ed824f45b6',
        id: '372ded9f-2e4f-4056-aee7-dce6376523c9',
        name: 'Nike Unite - KLIA',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6823'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '37302604-013c-4e52-9d47-7485c7d42880',
        name: '江苏省无锡市锡东八佰伴中心 KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270499'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'dcc4eb42-f310-4b95-ba54-d696a999c727',
        id: '374d6df0-4328-4d0d-849e-894218648aa4',
        name: 'Nike Well Collective Bromley',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2503'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '37636268-e787-40d6-967a-c1c5e5b06877',
        name: 'FAYA山西太原解放北路万达广场JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '190865'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '377a842a-9158-4926-8395-7f6b6d4ac98a',
        name: '나이키 갤러리아 센터시티',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '90636'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3780f60e-fe12-44e2-80e2-b8fc47fcc9db',
        name: 'POUSHENG浙江省金华之心（银泰百货）NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288401'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '3782e27d-6e50-4e81-8a8a-a43fc0c946ec',
        name: 'Nike Store Aeroville (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '894'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: '37890b55-5201-4440-82ca-ef0d899d10c4',
        name: 'Nike Unite Gotemba',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3014'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '37a16343-2ee2-4ae8-9806-9b7f79a4b9b5',
        name: '贵州贵阳观山湖江华荔星广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274135'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '37be98cb-d17e-4b3f-82c9-37477a7f1583',
        name: 'SHAANXI LIHE陕西渭南新区东路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279093'
      },
      {
        address: {
          country: 'IND'
        },
        id: '37bfa0d4-55be-42bc-8729-42d3d1408fdd',
        name: 'Nike Colaba 1',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4868'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '37c5035a-d6c3-49b7-9041-0bd4e848e44e',
        name: 'REALLY浙江宁波中山路江东银泰NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '529244'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '38053845-5d61-4ae4-9573-f08cac6fcf8d',
        name: 'NIKE深圳大梅沙换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3673'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '05f259e2-5c3f-43b8-aae2-8a59f239af36',
        id: '3813196f-6ceb-4184-8ce2-ce22f254180b',
        name: 'Nike Unite Croydon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2059'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '383c883e-4bd5-4f6a-9c3f-542179b116ce',
        name: 'Nike Charlestown',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6973'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '3843ca17-e33f-4989-ae8a-a84d232a0d3f',
        name: 'Nike Temuco',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6009'
      },
      {
        address: {
          country: 'IND'
        },
        id: '385c3fc4-8d49-4d90-8dbc-6c0d706f1831',
        name: 'Nike Panjim',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1561'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '385df2c3-c9eb-4d8a-87e1-95769de1e577',
        name: '辽宁省大连市沙河口区锦辉购物广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '270870'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '38604620-4352-41e8-8a1d-92abaa85646a',
        name: 'NIKE上海申迪换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3829'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3862dfe5-5221-4524-af9c-2546ef130afc',
        name: '나이키 스타일 홍대',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '70312'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3863fd6c-9e03-4166-bbd9-ece99dc8c2b3',
        name: '上海浦东新区世纪大道世纪汇 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274446'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '386e62aa-38c9-4145-8ebb-98db8148771b',
        name: '四川省成都市成都世茂广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270983'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '38797e26-63ab-408c-8448-693b80d517e4',
        name: '北京市大兴宜家购物中心BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '271013'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3884fb1b-ba5b-4ebd-bf43-0ff0c4738e3d',
        name: 'TOPSPORTS浙江安吉云鸿东路浙北大厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279073'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '388a1671-e05b-4eec-ae28-e7139f466c38',
        name: '浙江省温州市温州五马街金三益KL',
        region: 'GREATER_CHINA',
        storeNumber: '481518'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '38b0905f-6010-4af4-9a5f-f3a2c9f30fe2',
        name: '四川省成都市锦江区东大路环贸ICD KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '274285'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '38c44172-3623-4a0d-a1c2-2c87dee8562e',
        name: '辽宁省鞍山市铁东区胜利路新玛特 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '260218'
      },
      {
        address: {
          country: 'NZL'
        },
        id: '38d58be1-62ae-4314-83ea-8fdc063a5031',
        name: 'Nike Sylvia Park',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9212'
      },
      {
        address: {
          country: 'IND'
        },
        id: '38d5a1a4-f062-4fce-9fb0-f2d1c13e49e8',
        name: 'Nike Mumbai',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5095068'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '38d9dec4-dc7e-4ccc-9130-fa90ed18119f',
        name: 'NIKE厦门仙岳换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3635'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '38e7ad26-cf7c-474a-b65c-39cea8601548',
        name: 'MASONVILLE PLACE',
        region: 'NORTH_AMERICA',
        storeNumber: '499032'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '38ef094a-007f-405d-abd3-c719dd7a6ed3',
        name: '黑龙江省哈尔滨市松北区世茂大道融创BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '267173'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '38fb02ea-01ae-4555-81ed-4c7c47dde68c',
        name: '浙江省义乌市滔搏金华义乌新科路万达广场 NK BEACON L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '246568'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '6a92cade-8b98-409e-9582-069514d23a0a',
        id: '3920abfb-a264-4776-b6d0-6eb82ebdfa49',
        name: 'NIKE 台中員工店',
        region: 'GREATER_CHINA',
        storeNumber: '3306'
      },
      {
        address: {
          country: 'IND'
        },
        id: '39250034-d0c4-41c9-8d0c-595b4180fd41',
        name: 'Nike Forum Mall Mangalore',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8153'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '39268cbc-80da-4509-ba96-5810241c1983',
        name: '나이키 계산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '96693'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '394c99a6-62b8-4a4c-be72-6d8e8a919c94',
        name: 'Nike Chapel Street',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8372'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '3968a97f-04b2-4fc1-93bf-47f0f656d2fe',
        name: 'Converse Factory Store Clarksburg - Clarksburg Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3113'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '396ed0ae-1088-4cfd-b9b2-06f8c79c6bf4',
        name: 'REALLY浙江苍南银泰城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287338'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '396f4586-b45e-4723-8948-bddb6731e561',
        name: '河南省漯河市源汇区大商新玛特NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270482'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '397a504d-2962-4c02-bfe9-413bd23eb0d2',
        name: "Xi'An YingBin FOS",
        region: 'GREATER_CHINA',
        storeNumber: 'C6027'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '3980e177-1d13-40d8-ac26-9841fb01b2cb',
        name: 'NIKE STORE BOLZANO',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2368'
      },
      {
        address: {
          country: 'LVA'
        },
        id: '3983ddd3-937a-46c5-a9e3-35db25728a0a',
        name: 'Nike Outlet Riga Olimpia (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LVL029'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '3991eb6b-4040-42f0-a4f2-1d8cc593624b',
        name: 'NIKE湖州环太湖换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3758'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '3998beb4-9ad8-4ad8-b9b9-46faac5ad941',
        name: 'NIKE OFF SITE - SAWGRASS OFF SITE',
        region: 'NORTH_AMERICA',
        storeNumber: '9131'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '39D45985-EE80-496F-A147-FD538BBA5253',
        name: 'Nike Factory Store - Simpsonville',
        region: 'NORTH_AMERICA',
        storeNumber: '233'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '39b14142-e19f-4ffb-bf0a-00babde2b6a5',
        name: 'TOPSPORTS浙江海宁海昌南路银泰商业NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287283'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '39be755f-6522-4141-9006-9650695ab58f',
        name: 'NIKE佛山腾龙换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3873'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '39bfbe08-a9fd-4066-affa-aec2f9ffc3bb',
        name: '福建省厦门市宝胜厦门五缘湾湾悦城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '242347'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '39d87b2b-82aa-4840-944d-a524541a8f94',
        name: 'Shenzhen South China City FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6038'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '39edbad7-5963-487f-8e93-032fbc7482ab',
        name: 'Nike Store Alexandria',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY021'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: '3A8ABD5D-2C54-4012-9CEE-3B65FF392C2E',
        name: 'Nike Factory Store - Lake Buena Vista',
        region: 'NORTH_AMERICA',
        storeNumber: '128'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: '3C83B366-C549-48BD-9A71-C8058D171EF3',
        name: 'Nike Factory Store - Wisconsin Dells',
        region: 'NORTH_AMERICA',
        storeNumber: '141'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3a05cdf1-8b05-4686-88bf-339c8cc119e5',
        name: '重庆市沙坪坝区龙湖金沙天街B座 NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269366'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '3a075eed-83b4-4b26-93f2-26b518fa3ec5',
        name: 'Nike Company Store',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6111'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3a29fe46-189f-4bf9-932c-aeb01119733a',
        name: '나이키 사가정',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21977'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '3a2c6c30-dc8d-47f1-9da7-b78277dbca03',
        name: 'Nike南宁五一优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4062'
      },
      {
        address: {
          country: 'KWT'
        },
        id: '3a2e0b15-25b2-4e06-9fd7-7bb8de97f574',
        name: 'Nike Store Avenues Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KUW001'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '3a437434-438e-47a8-a369-e31fd6f51408',
        name: 'NIKE济南天桥换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3418'
      },
      {
        address: {
          country: 'TWN'
        },
        id: '3a4fc3b5-70a3-43c6-955e-8602bed6c18c',
        name: 'NIKE經銷商門市- Jordan 16 Songgao',
        region: 'GREATER_CHINA',
        storeNumber: '5073131'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3a50f362-dee4-479b-8965-9917472e1940',
        name: '山西省太原市太原小店区北美新天地KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '266967'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3a52e79c-be58-4644-9c8f-b4ad2b4dd63e',
        name: 'POUSHENG福建泉州新华北路开元盛世广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '187797'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '3a5d3749-7137-4789-b331-8c71a49a7df5',
        name: 'Nike Store Izmir Hilltown (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2783'
      },
      {
        address: {
          country: 'MNE'
        },
        id: '3a661023-e9e0-4f9e-8b49-fbb09240d05a',
        name: 'Nike Store Podgorica (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MON001'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3a6c575e-1e78-4173-9d06-512c2b27378e',
        name: '나이키 신세계 명동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '37794'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3a83d615-551a-41ba-8918-a1c1a7214d5e',
        name: 'SHUANGJIAN浙江杭州文一西路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '247619'
      },
      {
        address: {
          country: 'DEU'
        },
        id: '3a856380-24b1-442a-b64a-a0dd6b685e3d',
        name: 'Nike Store Berlin Leipziger Platz (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '739'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3ac64d7b-1fbc-496f-8071-7aaa5a450fd0',
        name: '新疆乌鲁木齐友好北路MM2 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '523202'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3aca1116-d535-49dc-94bd-ad2628595cc7',
        name: '吉林省吉林市昌邑区重庆街财富广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269027'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3ae69906-60f1-4c5d-9a0a-fb53e34ddd51',
        name: '上海市锐力上海浦东新区三井lalaport上海金桥NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270911'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '3af56acd-b522-4968-8846-12d25eb4c49a',
        name: 'Nike Pacific Fair',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5640'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3af70ce3-6a6f-454a-ac30-2befffbdd1e5',
        name: '나이키 현대 울산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '28424'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3afc9830-a0dd-4241-b30f-7e3cba8bf12f',
        name: '나이키 신포',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '96669'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3b1edf8b-a51a-4258-8a41-9f84d31cdc81',
        name: '广东省中山市盛世长运中山星河COCO city NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271057'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'de5b82a0-e649-4103-9387-cf868dd85899',
        id: '3b56b992-8037-11e6-ae22-56b6b6499611',
        name: 'Nike Soho',
        region: 'NORTH_AMERICA',
        storeNumber: '325'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3b5aa24b-09bf-44ae-b3e8-d89893f985fb',
        name: '江苏省镇江市宝胜江苏省镇江市京口区八佰伴BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '260207'
      },
      {
        address: {
          country: 'CHE'
        },
        id: '3b647d47-6d5f-40a0-a136-5ebaf0ca4659',
        name: 'Nike Store Basel (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '724'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3b72df33-ae2f-4235-9292-a9dcce8dcccc',
        name: '辽宁省大连市甘井子区中华西路华南安盛BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '267213'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3ba705a8-adf5-4561-8c19-bd68d7e96b6b',
        name: '四川省宜宾市宜宾金汶运动城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '268914'
      },
      {
        address: {
          country: 'MYS'
        },
        id: '3ba9c0ed-a5a8-4277-ad7b-182e5f32871f',
        name: 'Nike Pavilion',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1040'
      },
      {
        address: {
          country: 'NLD'
        },
        id: '3bc2a377-7a2d-4a0c-9704-beaf1e48d36f',
        name: 'Nike Store Amsterdam (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '864'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3bd3f5a2-7d62-4afe-856a-92467582fb45',
        name: 'JORDAN 2 ZHONGSHANNAN',
        region: 'GREATER_CHINA',
        storeNumber: '331126'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3bd6c8fc-09b7-463e-984b-952c702d9a96',
        name: 'TOPSPORTS广西梧州新兴三路梦之岛百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279068'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3bde8169-1925-4ef1-8c89-20de39c24815',
        name: '天津南开区鲁能城购物中心BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '331914'
      },
      {
        address: {
          country: 'DEU'
        },
        id: '3c11bbf6-0081-4746-9eb9-7cdb6d7dd303',
        name: 'Converse Brand Outlet Store Zweibrücken',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8105'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3c1796b4-4ff8-4a08-9009-702ec9da5274',
        name: '四川省成都市成都市金牛区龙湖上城天街NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '268786'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '3c2c0637-2201-493d-9f55-06c925e10918',
        name: 'Nike Store Meydan (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2615'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '3c340349-0a83-470d-92a5-6d06e6324ab1',
        name: 'Nike Gallaria Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA062'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '3c34e372-115a-43af-878e-e16ab62f4504',
        name: 'Nike Macquarie Centre',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9424'
      },
      {
        address: {
          country: 'BGR'
        },
        id: '3c5be399-0f9f-426d-9b42-0347f654e7c7',
        name: 'Nike Grand Mall Varna',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BUL014'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3c5fe2a8-8307-4702-a696-2d523f236601',
        name: '浙江省温州市百丽温州温瑞大道万象城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '454836'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '3c623f54-498d-4ff6-b1d0-e3c993b19f94',
        name: 'NIKE晋江石鼓换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3684'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3c686285-21a1-432a-b93e-a14ecbaea7a3',
        name: '耐克北京蓝色港湾体验店',
        region: 'GREATER_CHINA',
        storeNumber: '180296'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '3c6ef6f0-8871-4cb9-95f5-97eab9b3ed50',
        name: 'Converse Factory Store - Camarillo Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3743'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3ccef6b4-2ec1-4d0f-a44c-0aa8e99770be',
        name: '广东省广州市盛世长运广州市天河区天河路万菱汇 KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '258481'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3ce9c963-21a3-43dd-ba73-c8e153349deb',
        name: 'TOPSPORTS山东淄博柳泉路银座NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287204'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3d0ac70b-a9b6-4ab5-9b20-3b9f461943ce',
        name: 'POUSHENG辽宁鞍山二道街百盛NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '18716'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3d2dad08-570b-45e0-833f-7f06ee86b8ed',
        name: '河北省石家庄市桥西人民商场Nike Sport L',
        region: 'GREATER_CHINA',
        storeNumber: '272095'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'aedd4b07-bd95-46bc-bedc-c1dafa8b491e',
        id: '3d3c503a-27fc-4076-914e-104ddbdbea76',
        name: 'Nike Factory Store Creil',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '980'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '3dad18e0-062b-426a-b9ab-92d62c5aa0c7',
        name: 'Nike Serena',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9561'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3db6c66a-bcdf-42c1-a79c-f042d30a35e3',
        name: '杭州西湖珊瑚沙之江银泰NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '273485'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '3dd3883e-1bf9-4c6f-b2b4-0b6d6efd0cad',
        name: 'NIKE合肥长宁换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3831'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3de1d2d3-066c-42c7-a3e1-9d440e50d58d',
        name: '广西壮族自治区桂林市百丽桂林市临桂区凤凰路新城吾悦广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '259299'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '3e2c12d7-f715-476c-b9ae-db0af086f623',
        name: 'Nike Store KHALIDIYAH',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA037'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3e2c70f6-8a2c-45e6-887a-869422bdf32d',
        name: '나이키 롯데월드타워',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '79564'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3e33679a-8748-4494-bd50-027262b86769',
        name: '安徽省合肥市合肥庐阳华润万象汇BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '531634'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3e4aa975-9a28-4728-8875-bb36df180a6a',
        name: 'TOPSPORTS吉林松原铂金路金钻百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287541'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: '3e689fa6-88cc-4758-a843-09dec728f6ea',
        name: 'Nike Factory Store Ochtrup',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '586'
      },
      {
        address: {
          country: 'IND'
        },
        id: '3e6a45ac-f3c6-4759-8ae1-02c75f0fc96c',
        name: 'Nike Quest Mall Kolkata',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5565'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'de5b82a0-e649-4103-9387-cf868dd85899',
        id: '3e76a028-3f61-480b-aa41-1013c13efe47',
        name: 'Nike Well Collective - Battery Park',
        region: 'NORTH_AMERICA',
        storeNumber: '556'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3e8904a0-3f72-4d9b-871f-9f346fe4613c',
        name: '新疆库尔勒朝阳路汇嘉时代广场NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '272094'
      },
      {
        address: {
          country: 'IND'
        },
        id: '3e9a48cb-d111-4477-8534-439d53156c18',
        name: 'Nike Phoenix Market City Chennai',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5052483'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '3e9ab39e-ebaa-4831-86a6-2744681d75ca',
        name: 'Nike Well Collective Baie Des Anges (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2446'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '3e9b029d-f95a-4c78-914b-f62e0578a34e',
        name: 'Nike Cf Markville',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD7'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3e9b3321-ed5d-41fe-801f-9eb90ed8022e',
        name: '山东省青岛市北区凯德广场NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '177570'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3ebe9717-d264-42fb-96ab-c3e5ba7c54fe',
        name: '四川省乐山市乐山世豪NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288047'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3ec7648c-2cd0-40d4-b252-a9cae2dd3232',
        name: '江苏省扬州市锐力东方购物中心KL L1',
        region: 'GREATER_CHINA',
        storeNumber: '266935'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: '3ecb121e-f1a5-4929-98b7-3cb383f48cb8',
        name: 'Nike Factory Store Neumunster',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '688'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3edf4841-8462-4735-89db-4db563179f46',
        name: 'POUSHENG贵州凯里北京西路国贸NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '279078'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3ef6e7a1-53b4-471e-827f-0f6913eeec05',
        name: '나이키 수유',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '63307'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3f0d6cf3-27f3-44ba-9655-cd3e54d86e82',
        name: '广东省深圳市百丽深圳宝安大仟里 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '260268'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '3f3a76e1-db80-4254-9897-6f6fec41a89c',
        name: 'Nike Hay Street',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2464'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '3f5c1d41-f05d-45ed-b818-c7d28456d5c2',
        name: 'NIKE兰州北滨河换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4018'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3f6174a8-11ec-44e5-acc5-9ea03905d7ce',
        name: 'JORDAN 218 TIANHE',
        region: 'GREATER_CHINA',
        storeNumber: '187200'
      },
      {
        address: {
          country: 'MYS'
        },
        districtId: '0f249a59-1a39-4d43-815f-b9ed824f45b6',
        id: '3f6f4e20-36e0-49d8-ba1f-5bc7a34b83f7',
        name: 'Nike Factory Store - Genting Premium Outlets',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6825'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: '3fc236e9-f2f6-48b3-806a-a3a617c10440',
        name: 'Nike Unite - Fairfax',
        region: 'NORTH_AMERICA',
        storeNumber: '448'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3fc75858-611e-4ff9-9cf3-692e37e8df64',
        name: '나이키 유성',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71308'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3fc7de53-0cae-4eab-b7d9-71a5a13c189a',
        name: '武汉江夏文化大道永旺梦乐城SP',
        region: 'GREATER_CHINA',
        storeNumber: '288841'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3fd0f67e-38cb-4a0f-8806-1dac541773af',
        name: 'TOPSPORTS山东淄博张店区金晶大道淄博商厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287203'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '3fd392e7-08c0-4343-8c43-c0132b035ffd',
        name: '浙江省永康市万达广场 NIKE SPORTS-L',
        region: 'GREATER_CHINA',
        storeNumber: '274207'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'fe144077-30cf-4be6-9316-3a64d50a3124',
        id: '3fe099ec-7a8f-4c53-ab4e-ad1126cf46b1',
        name: 'Nike Factory Store Lyon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2020'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: '3fef30a1-da0a-4b47-8979-bbebbb0eb409',
        name: 'NIKE石家庄长江换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3771'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '3ff17c18-a648-478a-86c6-8ea96fcff6d7',
        name: '나이키 롯데 광주 월드컵',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87396'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '40098e05-0bd5-4f81-9c86-dc29838ede4a',
        name: 'POUSHENG江苏省宜兴和信NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279090'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '40195407-f10d-464b-9a00-b647019f0105',
        name: '河北承德名都购物广场NIKE SPORTS S',
        region: 'GREATER_CHINA',
        storeNumber: '246718'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4040d88b-f930-4a3e-9a91-0597011098db',
        name: '北京市海淀区五道口华联NIKE SPORT L',
        region: 'GREATER_CHINA',
        storeNumber: '269005'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '40438e2b-4c5f-4af2-89f4-9f45652ed0e3',
        name: '나이키 홍천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '66046'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '406a02d8-81f4-40c3-af33-9e1ccaa81f2c',
        name: '河北省唐山市北新西道八方购物广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '4141'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '406e411b-488a-43ae-9906-afe35855c8c2',
        name: '江苏省南京市夫子庙水游城KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '281930'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
        id: '40E3C00D-2F62-4A3C-8A35-B8C20BB9B30E',
        name: 'Nike Factory Store - Centro',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6610'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '40ac8485-5038-4cce-9b2b-6e0ec586297a',
        name: '山东省济南市荣盛时代广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526847'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: '40aee111-cc60-4f9a-b942-f7c2fe4f993b',
        name: 'Nike Factory Store - Kanata',
        region: 'NORTH_AMERICA',
        storeNumber: '392981'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '40c5b3db-f9e4-4a4d-9236-04fd4dd24da7',
        name: '内蒙古自治区呼和浩特市内蒙古呼和浩特市回民区维多利购物中心KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '267093'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '40d504f9-8d71-45ad-a59b-8e9ae449a610',
        name: 'NIKE武汉金银潭优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4044'
      },
      {
        address: {
          country: 'LBN'
        },
        id: '40d89043-1760-42d8-8d1d-856739a6250a',
        name: 'Nike Abc Achrafieh',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LEB062'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '40d99ec4-9dd1-458f-96dd-83f6477b1f3f',
        name: '四川省成都市成都大悦城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '454904'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '40e67b28-b7b6-40f6-a823-b0cb23b5efcb',
        name: '陕西省西安市新城区益田假日购物中心Nike Kicks Lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '192733'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '40face12-c9e6-4ac0-b68a-0807a01efdb3',
        name: 'Shanghai Tianshan Outlets',
        region: 'GREATER_CHINA',
        storeNumber: 'C6025'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: '410d0416-7369-4116-b52e-806172876b75',
        name: 'NIKE ONLY V&A WATERFRONT',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2302'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4156d6d8-448e-4587-adce-ee53a2a3d1bf',
        name: '나이키 롯데 동부산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95990'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
        id: '41607fd1-558a-4b7c-abd6-ffab9d2ac0fa',
        name: 'Nike Factory Store Dijon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '976'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: '416fb0ef-9c0b-421b-acb7-fc410864a2e3',
        name: 'Nike Unite Cheshire Oaks',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '504'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '41789f53-f704-4d43-bd16-5080885f39b3',
        name: '浙江省杭州市远洋乐堤港KL2',
        region: 'GREATER_CHINA',
        storeNumber: '269037'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '417a8fdc-4cf9-4203-9d2d-b25f3652544a',
        name: '浙江省金华市宝胜金华银泰城BEACON750',
        region: 'GREATER_CHINA',
        storeNumber: '269228'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '41a2dfc5-0a95-47b8-a77f-cb75354d078c',
        name: '江苏省常熟市常熟八佰伴BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241175'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '41a46354-6c38-4d4b-bd15-e5c8c3f50c8f',
        name: 'NIKE合肥金寨换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3792'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '41b1899b-039a-4b50-95bf-5975cfbb1bff',
        name: 'Converse Brand Outlet Store Madrid',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8111'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: '41d8d9c3-e6a2-4b03-82a5-7f1a6968ccae',
        name: 'NIKE OFF SITE - ESTERO OFF SITE',
        region: 'NORTH_AMERICA',
        storeNumber: '9067'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '41dbe557-0808-40a7-810e-a76eb2d18eab',
        name: '나이키 신세계 타임스퀘어',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '37807'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: '41e30009-22bf-4894-a03c-41c91c937b74',
        name: 'Nike Well Collective - Marlton',
        region: 'NORTH_AMERICA',
        storeNumber: '472'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '41e3ac8d-5215-49f4-a301-e87334fbb0fd',
        name: 'Nike Factory Store - Jindalee',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6173'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '41e4948b-4bbc-4ce2-a7bb-62eaacd99506',
        name: 'JORDAN 5 XINGHUO',
        region: 'GREATER_CHINA',
        storeNumber: '523593'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: '41fb76cf-2dcf-494c-83dd-f60d8b160d37',
        name: 'Nike Factory Store - Torrance',
        region: 'NORTH_AMERICA',
        storeNumber: '275'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '420973d4-e798-4b7a-a269-56db32a8d4e9',
        name: 'REALLY湖北武汉珞喻路银泰城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '111063'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '42348ef3-04b0-47b0-9863-859241216158',
        name: '浙江省杭州市浙江省杭州江干区新塘路七堡花园城购物中心NIKE SPORTS-S',
        region: 'GREATER_CHINA',
        storeNumber: '272375'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
        id: '424e646e-fab1-4c84-85f5-36665cb77be3',
        name: '나이키 유나이트 부산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6127'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4284442c-e2d3-473d-9e16-714ecd683300',
        name: 'Shanghai Yangpu Fashion Center',
        region: 'GREATER_CHINA',
        storeNumber: 'C6002'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '42ae03b7-c6b2-4118-9ec9-1dcd78fccb60',
        name: '나이키 제천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85552'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '42af54a2-3e23-4299-bc61-05143290201c',
        name: 'SHAANXI LIHE陕西西安常缨西路万和城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '279087'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '42d210b6-8d7f-4efd-9698-7af327f7ff09',
        name: '河南省周口市川汇区开元大道万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '270749'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '42e2ca55-903a-4fe9-bcc8-cbfcbbaf0d50',
        name: '나이키 엔터식스 안양',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72492'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '42f80ef0-1aae-4954-8e63-9debf3de7471',
        name: '河南省许昌市魏都区万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272737'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: '031af3aa-9b11-4b5d-b05f-80d63e997ea7',
        id: '4320cbd8-631d-4d71-bc01-7f8482c4fa3e',
        name: 'Nike Factory Store Roermond',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '548'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '43232061-2679-438b-bff6-198766e60911',
        name: '江苏苏州吴江区万象汇BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241696'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '432ecbc6-4f29-4f2c-91d4-717255eee9e4',
        name: '北京亦庄天街NK-M',
        region: 'GREATER_CHINA',
        storeNumber: '274279'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '43305bb5-bbe9-469f-9071-66023393199a',
        name: 'Nike Store ALIAT MADINAT',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA078'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '433153ad-7cc1-4140-a7af-ce361c7cd133',
        name: '河北省沧州市河北省沧州运河区永安南大道吾悦广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '526541'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '43322758-12df-462f-98c2-b929f75f3ebd',
        name: 'Nike Store Iasi Palas Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM117'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4336182d-caa3-4208-9101-74d94af7ef4c',
        name: '上海市宝胜上海长宁龙之梦BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '4396'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '434133a0-8aca-4701-a8f0-ae968c890f27',
        name: '北京西单君太NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '180070'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '434bbda3-b60c-4487-af57-89c24fb67c2e',
        name: 'NIKE常州永宁换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3734'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '434ee9de-f838-4735-8686-5059b6830506',
        name: '云南省昆明市官渡区富康城NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '267112'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '43568e98-1803-487a-a645-26051fc119cf',
        name: '广东省佛山市百丽佛山市顺德区大良镇马地街新基时代广场SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '1719'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4363ea27-f084-4728-99a9-74dd89d22e04',
        name: '广东省东莞市百丽广东东莞市南城鸿福路200号汇一城购物中心1F BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '269540'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '436e9425-2984-4e1c-b8dc-974e28fd6f85',
        name: '江苏省泰州市泰州市海陵永定东路万象城二期BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '270953'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: '4374D90F-09D3-4DF5-B803-1FCEDB3E2F4E',
        name: 'Nike Factory Store - Calhoun',
        region: 'NORTH_AMERICA',
        storeNumber: '58'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '43872fcc-90b6-4c64-812f-f8b537cd7336',
        name: '나이키 롯데 포항',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78514'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '43a86f6d-4a15-484e-89ff-626a059f9d45',
        name: '河南省洛阳市中州中路建业凯旋广场NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271148'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '43dbe765-e6ae-46f3-ad50-7b3b11032e98',
        name: '广州西湾路悦汇城NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '270494'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '43efdc65-551a-4d21-99ff-f8af30601030',
        name: 'Nike St Moritz',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4676'
      },
      {
        address: {
          country: 'SGP'
        },
        id: '43f04fd1-1138-48b9-b441-b71886ab7fa9',
        name: 'Nike Orchard Road',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7787'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '43f8328c-002e-408f-aa86-1001312c5177',
        name: '辽宁省大连市甘井子区亿合城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '455400'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '43fd95b5-7b0a-4fd0-b7ef-13fc6cdd222d',
        name: 'Nike Unite Gold Coast',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6063'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: '4416c30e-36c9-4365-918c-e94fcf61a6ff',
        name: 'Nike Factory Store Tosu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3034'
      },
      {
        address: {
          country: 'CZE'
        },
        id: '4416c5d2-7801-481c-bfb4-180da415308c',
        name: 'Nike Well Collective - Zlicin (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2436'
      },
      {
        address: {
          country: 'IND'
        },
        id: '443427bf-4031-4984-bd3d-0ef8d09a931e',
        name: 'Nike Vapi',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3985'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'de5b82a0-e649-4103-9387-cf868dd85899',
        id: '44362512-7d00-40f1-85d0-40716627100f',
        name: 'Nike Well Collective - Williamsburg',
        region: 'NORTH_AMERICA',
        storeNumber: '434'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '44510ca4-ef62-4ce2-8261-28c704033e41',
        name: '广东省深圳市百丽深圳罗湖区太阳百货Beacon L2 extend',
        region: 'GREATER_CHINA',
        storeNumber: '268451'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: '44521604-219C-4F60-9B92-6276AFA9F688',
        name: 'Nike Factory Store - Morelia',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6632'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '44575E44-FDA0-4760-93FD-693FC4A42F91',
        name: 'Nike Factory Store - Pembroke Pines',
        region: 'NORTH_AMERICA',
        storeNumber: '220'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4498f1c4-7d54-4f00-ab23-fff21379af9f',
        name: 'TOPSPORTS山东德州德城区澳德乐NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287274'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '44a68069-4cf3-472c-be1d-f5d7c27b0b18',
        name: '山东青岛夏庄路李沧区乐客城NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '287222'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '44b84ed6-5166-456f-be65-dc9556d523a5',
        name: '나이키 울산성남',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21650'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '44bc1737-9091-4d5f-bf30-a267152d20a5',
        name: '锐力上海市黄浦区南京东路大丸百货BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '266728'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '44c3460a-ea9e-4ab8-bc7e-aeb5acf049d9',
        name: '泉州浦西苏宁易购NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '180185'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '45001c12-c553-45d2-b5c0-1164baf26afc',
        name: 'Nike Well Collective - Kfar Saba (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2719'
      },
      {
        address: {
          country: 'SGP'
        },
        id: '4540a686-c7c3-4484-be1c-816e11cb3b10',
        name: 'Nike Vivo L1',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5096357'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4542ccd7-f3ce-4bf7-9021-d6f4b49e5442',
        name: '나이키 롯데 일산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '91131'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '454f99f1-f5f5-4a53-ae80-054ccded8f5e',
        name: 'TOPSPORTS山东济南历下区泺源大街银座NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '194684'
      },
      {
        address: {
          country: 'POL'
        },
        id: '4558782e-d7b0-4e17-9724-0df627bc2435',
        name: 'Nike Store Focus Zielona Gora (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2382'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: '4578CF3A-59CA-4200-BF87-E27DABCEF5D2',
        name: 'Nike Factory Store - Estero',
        region: 'NORTH_AMERICA',
        storeNumber: '67'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4582515d-eabb-43b1-8ed4-938ad3d157cd',
        name: '深圳KK MALL NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '279066'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '011c974d-ad7b-4a21-ae1b-ff52a2a066cd',
        id: '45A9938A-36B8-446B-A649-BC8C5979CE27',
        name: 'Nike Factory Store - Hermosillo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6624'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '45bc3dbb-3b02-47a9-8add-a47e7e9113f3',
        name: '陕西省西安市中铁立丰购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '260317'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '45c0e4eb-3d07-4168-86c3-e1ddb2077c73',
        name: '江苏省苏州市悠方购物中心SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271312'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '45fb17a4-5d3a-4571-82f3-687f401e9afb',
        name: 'Nike Factory Store - Contagem',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6313'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '461285c9-eef0-40b1-89fc-ce5ee34f5661',
        name: '福建省漳州市TOPSPORTS福建省漳州龙文区建元东路碧湖万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '266796'
      },
      {
        address: {
          country: 'SWE'
        },
        id: '461e68fc-715a-4ebf-a9b0-05cdaca1039f',
        name: 'Nike Well Collective - Hyllie (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2455'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: '46202EC1-8096-47C3-B660-7A60BED2FECA',
        name: 'Nike Factory Store - San Jose',
        region: 'NORTH_AMERICA',
        storeNumber: '211'
      },
      {
        address: {
          country: 'GRC'
        },
        id: '46392840-05c4-4088-9385-7f572d270ec2',
        name: 'Nike Store Attica (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2329'
      }
    ],
    pages: {
      next: 'store/store_locations/v1?search=%28id%21%3Dfoo%29&anchor=1000&count=1000&fields=id%2Cname%2CstoreNumber%2Caddress.country%2CdistrictId%2Cregion',
      totalPages: 4,
      totalResources: 3599
    }
  };
