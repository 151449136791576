import { Input, Loading, TextArea } from '@nike/frame-component-library';
import { ModalActionsStyled, ModalStyled, Button } from '@nike/nike-design-system-components';
import { Default, isSome, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { getStoreOfferingById, putStoreOffering } from '../../../../utils/service-calls/sls';

const ModalEditTranslation = ({
  fetchUpdatedOffering, index, isOpen, localization, offeringId, setIsOpen, userToken,
}) => {
  const [offeringName, setOfferingName] = useState('');
  const [offeringDescription, setOfferingDescription] = useState('');
  const [originalName, setOriginalName] = useState();
  const [originalDescription, setOriginalDescription] = useState();
  const [submitStatusMessage, setSubmitStatusMessage] = useState(undefined);
  const [isCancelOrClosedDisabled, setIsCancelOrClosed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setSubmitStatusMessage(undefined);
  };

  const displaySomeMessage = ({ message, status }) => match(status)(
    ['initiating', <p className="text-color-primary-dark ta-sm-c body-2 mt2-sm">{message}</p>],
    ['success', <p className="text-color-success ta-sm-c body-2 mt2-sm">{message}</p>],
    ['failed', <p className="text-color-error ta-sm-c body-2 mt2-sm">{message}</p>],
    [Default, null],
  );

  const isSomeMessage = isSome(submitStatusMessage);

  const isOfferingSameAsDescription = offeringDescription === originalDescription;
  const isOfferingSameAsName = offeringName === originalName;
  const isNamesAndDescriptionsMatch = isOfferingSameAsDescription && isOfferingSameAsName;

  const isSaveDisabled = isNamesAndDescriptionsMatch || isSomeMessage;

  const saveTranslation = async () => getStoreOfferingById(userToken, encodeURIComponent(offeringId))
    .then(async (data) => {
      const updatedLocalization = {
        ...localization,
        offering: { description: offeringDescription, name: offeringName },
        status: 'COMPLETED',
      };
      const offeringToPut = {
        ...data.body,
        id: encodeURIComponent(data.body.id),
        localizations: [
          ...data.body.localizations.slice(0, index),
          updatedLocalization,
          ...data.body.localizations.slice(index + 1)],
      };
      return putStoreOffering(userToken, offeringToPut);
    });

  const submitSave = async () => {
    setIsCancelOrClosed(true);
    setIsLoading(true);
    setSubmitStatusMessage({ message: 'Initiating manual translation', status: 'initiating' });
    return saveTranslation()
      .then(async () => {
        await fetchUpdatedOffering();
        return setSubmitStatusMessage({ message: 'Successfully completed manual translation!', status: 'success' });
      })
      .catch((err) => setSubmitStatusMessage({ message: `Failed with error: ${err.message}`, status: 'failed' }))
      .finally(() => {
        setIsCancelOrClosed(false);
        setIsLoading(false);
      });
  };

  const Actions = () => (
    <ModalActionsStyled>
      <section>
        <Button
          appearance="primary"
          className="ncss-btn-primary-dark bg-accent mt6-sm ml2-sm border"
          disabled={isSaveDisabled}
          showErrorMessage={false}
          onClick={submitSave}
        >
          Save Translation
        </Button>
        <Button
          className="ncss-btn-secondary-dark mt6-sm ml2-sm border"
          disabled={isCancelOrClosedDisabled}
          label={isSomeMessage ? 'Close' : 'Cancel'}
          onClick={closeModal}
        >
          Close
        </Button>
      </section>
    </ModalActionsStyled>
  );

  useEffect(() => {
    const {
      offering: { description, name } = { description: '', name: '' },
    } = localization;
    setOriginalName(name);
    setOfferingName(name);
    setOriginalDescription(description);
    setOfferingDescription(description);
  }, [localization]);

  return (
    <>
      <ModalStyled
        closeModal={closeModal}
        isOpen={isOpen}
        slots={{ FooterSlot: <Actions /> }}
        title="Edit Translation"
      >
        <article className="ncss-col-sm-12 va-sm-t">
          {isSomeMessage
            ? (
              <>
                {displaySomeMessage(submitStatusMessage)}
                {isLoading ? <Loading /> : null}
              </>
            )
            : (
              <>
                <Input
                  errorMessage=""
                  label="Offering Name"
                  value={offeringName}
                  onChange={(e) => setOfferingName(e.target.value)}
                />
                <TextArea
                  cols="100"
                  label="Offering Description"
                  rows="5"
                  value={offeringDescription}
                  onChange={({ target: { value } }) => setOfferingDescription(value)}
                />
              </>
            )}
        </article>
      </ModalStyled>
      <div id="modal-root" />
    </>
  );
};

ModalEditTranslation.defaultProps = {
  offeringId: '',
};

ModalEditTranslation.propTypes = {
  fetchUpdatedOffering: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  localization: PropTypes.shape().isRequired,
  offeringId: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default ModalEditTranslation;
