import React from 'react';

import initializeSite from '../utils/initializeSite';

class Authorize extends React.Component {
  constructor(props) {
    super(props);
    initializeSite();
  }

  render = () => (
    <main className="ncss-col-sm-12 mt12-sm pt2-sm ta-sm-c">
      <p className="headline-4">You are being authorized.</p>
    </main>
  )
}

export default Authorize;
