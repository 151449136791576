/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { TextFieldStyled, TextAreaStyled } from '@nike/nike-design-system-components';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import './ConfigManager.css';
import { loadState } from '../../utils/local-storage';
import { requestWrapper } from '../../utils/service-calls/reusable';
import { isUserAllowed } from '../../utils/tab-permissions';
import {
 ButtonBlack, ButtonRed, ButtonSubmit, ButtonWhite,
} from '../reusable';

import Modal from './Modal'; // Import the Modal component

const CCMContent = () => {
  const [serviceName, setServiceName] = useState({});
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [configName, setConfigName] = useState('');
  const [configValue, setConfigValue] = useState('');
  const [configs, setConfigs] = useState([]);
  const [serviceConfigs, setServiceConfigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMessageModalOpen, setisMessageModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [newValue, setNewValue] = useState('');
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const { accessToken } = loadState('auth');
  const [serviceSelected, setServiceSelected] = useState(false);
  const [updateServiceConfigList, setUpdateServiceConfigList] = useState(false);
  const ccmKeyGroups = ['CCM', 'CCM.ADMIN'];
  const userInfo = useSelector((state) => state.authorizationReducer.auth);
  const [ccmGroup, setCcmGroup] = useState([]);

  useEffect(() => {
    const ccmGroups = ccmKeyGroups.filter((group) => isUserAllowed(userInfo.groups, group));
    setCcmGroup(ccmGroups);
  }, []);

  const services = [
    // { label: 'SCS', value: 'scs' },
    { label: 'CMP', value: 'cmp-store-event-listener' },
  ];

  const handleToggle = () => {
    setSelectedKey('');
    setSelectedValue('');
    setIsCreateMode(!isCreateMode);
    if (isCreateMode) {
      // Reset values when switching to Update/Delete
      setConfigName('');
      setConfigValue('');
    }
  };

  const handleSave = async () => {
    if (serviceName && configName && configValue) {
      if (
        Object.keys(serviceName).length === 0
        || serviceName === 'all'
      ) {
        setisMessageModalOpen(true);
        setMessage('You cannot select ALL as service name during config creation.');
      } else {
        setLoading(true);
        const id = `${serviceName}.${configName}`;
        const value = `${configValue}`;
        try {
          await requestWrapper(accessToken, '/ccm/config', 'post', undefined, {
            id,
            value,
          });
          setisMessageModalOpen(true);
          setMessage('Config saved successfully!');
          setConfigName('');
          setConfigValue('');
        } catch (error) {
          console.error('Error saving config:', error);
          setisMessageModalOpen(true);
          setMessage('Error saving config. Please try again.');
        } finally {
            setLoading(false);
        }
      }
    } else {
      setisMessageModalOpen(true);
      setMessage('Please fill in all fields.');
    }
  };

  // Only fetch the configs whose config name starts with the selected service name
  const handleConfigsByService = () => {
    try {
      // if service name is 'ALL', fetch all configs else fetch configs by service name
      if (serviceName === 'all') {
        setServiceConfigs(configs);
      } else {
        const filteredConfigs = configs.filter((conf) => conf.key.startsWith(serviceName));
        setServiceConfigs(filteredConfigs);
      }
    } catch (error) {
      console.error('Error fetching configs by service:', error);
      setisMessageModalOpen(true);
      setMessage('Error fetching configs. Please try again.');
    }
  };

  const fetchConfigs = async () => {
    setLoading(true);
    try {
      const token = `${accessToken}`;
      const response = await requestWrapper(token, '/ccm/config', 'get');
      setConfigs(response.body);
      setUpdateServiceConfigList(!updateServiceConfigList);
    } catch (error) {
      console.error('Error fetching configs:', error);
      setisMessageModalOpen(true);
      setMessage('Error fetching configs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (selectedKey) {
      setIsDeleteModalOpen(true);
    } else {
      setisMessageModalOpen(true);
      setMessage('Please select a config to delete.');
    }
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await requestWrapper(accessToken, `/ccm/config/${selectedKey}`, 'delete');
      await fetchConfigs(); // Refresh the list
      setIsDeleteModalOpen(false);
      setisMessageModalOpen(true);
      setMessage('Config deleted successfully!');
      setSelectedKey('');
      setSelectedValue('');
    } catch (error) {
      console.error('Error deleting config:', error);
      setIsDeleteModalOpen(false);
      setisMessageModalOpen(true);
      setMessage('Error deleting config. Please try again.');
    } finally {
        setLoading(false);
    }
    setSelectedValue('');
  };

  const handleUpdate = async () => {
    if (selectedKey) {
      setNewValue(selectedValue);
      setIsUpdateModalOpen(true);
    } else {
      setisMessageModalOpen(true);
      setMessage('Please select a config to update.');
    }
  };

  const confirmUpdate = async () => {
    setLoading(true);
    try {
      await requestWrapper(accessToken, `/ccm/config/${selectedKey}`, 'put', undefined, { value: newValue });
      fetchConfigs(); // Refresh the list
      setIsUpdateModalOpen(false);
      setisMessageModalOpen(true);
      setMessage('Config updated successfully!');
    } catch (error) {
      console.error('Error updating config:', error);
      setIsUpdateModalOpen(false);
      setisMessageModalOpen(true);
      setMessage('Error updating config. Please try again.');
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    if (!isCreateMode) {
      fetchConfigs();
    }
  }, [isCreateMode]);

  useEffect(() => {
    setSelectedKey('');
    setSelectedValue('');
    handleConfigsByService();
  }, [serviceName]);

  useEffect(() => {
    handleConfigsByService();
  }, [updateServiceConfigList]);

  useEffect(() => {
    const selectedConfig = configs.find((conf) => conf.key === selectedKey);
    if (selectedConfig) {
      setSelectedValue(selectedConfig.value);
    }
  }, [selectedKey, configs]);

  return (
    <div className="config-manager">
      <div className="controls">
        <div className="service-dropdown">
          <label htmlFor="serviceSelect">Select Service:</label>
          <ReactSelect
            classNamePrefix="react-select"
            clearable={false}
            id="serviceSelect"
            // isDisabled={this.props.isDisabled}
            options={services}
            styles={{
              container: (styles) => ({ ...styles, zIndex: 110 }),
              control: (styles) => ({
                ...styles,
                borderColor: false ? 'red' : 'lightgrey',
                padding: '0px 0px 0px 3px',
              }),
            }}
            value={serviceName.value}
            onChange={(e) => {
              setServiceName(e.value);
              setServiceSelected(true);
            }}
          />
        </div>
        <div className="toggle">
          <label className={isCreateMode ? 'toggleLabel' : 'toggleLabelActive'} htmlFor="toggleSwitch">
            Update/Delete
          </label>
          <label className="switch">
            <input
              checked={!isCreateMode}
              type="checkbox"
              onChange={handleToggle}
            />
            <span className="slider" />
          </label>
          <label className={isCreateMode ? 'toggleLabelActive' : 'toggleLabel'} htmlFor="toggleSwitch">Create</label>
        </div>
      </div>

      {isCreateMode ? (
        <>
          {serviceSelected ? (
            <div className="create-mode">
              <div className="row">
                <div className="col-75">
                  <TextFieldStyled
                    label="Config Name"
                    placeholder="Config Name"
                    requiredIndicator="*"
                    type="text"
                    value={configName}
                    onChange={(e) => setConfigName(e.target.value)}
                  />
                  <TextAreaStyled
                    required
                    ariaLive="polite"
                    label="Config Value"
                    placeholder="Config Value"
                    requiredIndicator="*"
                    rows={5}
                    type="text"
                    value={configValue}
                    wrap="soft"
                    onChange={(e) => setConfigValue(e.target.value)}
                  />
                </div>
              </div>
              <div className="row" id="create-button-id">
                {loading ? (
                  <ButtonWhite label="Loading" onClick={() => (true)} />
                ) : (
                  <ButtonSubmit isDisabled={!(serviceName && configName && configValue)} label="Save" onClick={handleSave} />
                )}
              </div>
            </div>
          ) : (
            <div>Please select a service</div>
          )}
        </>
      ) : (
        <div className="update-delete-mode">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {serviceSelected ? (
                <>
                  {serviceConfigs.length === 0 ? (
                    <div>No configs found for the selected service.</div>
                  ) : (
                    <>
                      <div className="config-group">
                        <div className="key-list">
                          {serviceConfigs.map((conf) => (
                            <div
                              key={conf.key}
                              className={`key-item ${
                                selectedKey === conf.key ? 'selected' : ''
                              }`}
                              onClick={() => setSelectedKey(conf.key)}
                            >
                              {conf.key}
                            </div>
                          ))}
                        </div>
                        <div className="key-value">
                          <textarea
                            readOnly
                            className="key-value-textarea"
                            placeholder="Config Value"
                            type="text"
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="button-group">
                        <ButtonBlack
                          isDisabled={!ccmGroup.includes('CCM.ADMIN') || !selectedKey}
                          label="UPDATE"
                          onClick={handleUpdate}
                        />
                        <ButtonRed
                          confirm={false}
                          isDisabled={!ccmGroup.includes('CCM.ADMIN') || !selectedKey}
                          label="DELETE"
                          onClick={handleDelete}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div>Please select a service</div>
              )}
            </>
          )}
        </div>
      )}

      {/* Update Modal */}
      <Modal
        buttonName="CANCEL"
        isOpen={isUpdateModalOpen}
        loading={loading}
        title="Update Config"
        onClose={() => setIsUpdateModalOpen(false)}
      >
        <div className="modal-textarea-div">
          <textarea
            className="modal-textarea"
            placeholder="New Config Value"
            type="text"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </div>
        <ButtonWhite label="UPDATE" onClick={confirmUpdate} />
      </Modal>

      {/* Delete Modal */}
      <Modal
        buttonName="CANCEL"
        isOpen={isDeleteModalOpen}
        loading={loading}
        title="Confirm Delete"
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <p>Are you sure you want to delete the {selectedKey} key?</p>
        <ButtonRed confirm={false} label="DELETE" onClick={confirmDelete} />
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        buttonName="OK"
        isOpen={isMessageModalOpen}
        title=""
        onClose={() => setisMessageModalOpen(false)}
      >
        <p>{message}</p>
      </Modal>
    </div>
  );
};

export default CCMContent;
