{
    "lastA11yAudit": "07/08/22",
    "name": "Checkbox Group",
    "docs": "#/components/forms/CheckboxGroup",
    "description": "Checkbox Group is a grouping of checkboxes.",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=48802%3A348770",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/CheckboxGroup",
    "tags": ["checkboxgroup", "inputs", "form elements", "forms"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
