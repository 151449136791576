var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { Label } from "../Label/Label";
import { InputMessageGroup } from "../InputMessageGroup/InputMessageGroup";
export var TextArea = React.forwardRef(function (_a, ref) {
    var onChange = _a.onChange, value = _a.value, maxChars = _a.maxChars, showCount = _a.showCount, hideLabel = _a.hideLabel, errorMessage = _a.errorMessage, message = _a.message, hasErrors = _a.hasErrors, _b = _a.noBorder, noBorder = _b === void 0 ? false : _b, id = _a.id, _c = _a.resize, resize = _c === void 0 ? "none" : _c, label = _a.label, subtitle = _a.subtitle, autoResize = _a.autoResize, maxRows = _a.maxRows, _d = _a.minRows, minRows = _d === void 0 ? 1 : _d, defaultValue = _a.defaultValue, className = _a.className, style = _a.style, rest = __rest(_a, ["onChange", "value", "maxChars", "showCount", "hideLabel", "errorMessage", "message", "hasErrors", "noBorder", "id", "resize", "label", "subtitle", "autoResize", "maxRows", "minRows", "defaultValue", "className", "style"]);
    var localRef = React.useRef(null);
    var _e = React.useState(defaultValue || value), localInputValue = _e[0], setLocalInputValue = _e[1];
    var handleChange = function (e) {
        setLocalInputValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };
    var valueSpreadMapping = value === undefined ? { defaultValue: defaultValue } : { value: localInputValue };
    // Based of vertical padding and font size vs line height
    var maxHeight = maxRows ? "calc(32px + ".concat(maxRows * 1.5, "rem)") : "none";
    return (_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: id, font: "title-6", screenReaderOnly: hideLabel, hasErrors: hasErrors }, { children: label })), subtitle && (_jsx(Label, __assign({ htmlFor: id, font: "body-3", color: "text-secondary" }, { children: subtitle }))), _jsx("div", __assign({ className: classNames("eds-text-field", {
                    "eds-text-field--no-border": noBorder,
                    "eds-text-field--auto-resize": autoResize,
                }, className), "data-auto-resize": autoResize ? localInputValue : undefined, style: { maxHeight: maxHeight } }, { children: _jsx("textarea", __assign({ className: classNames("eds-text-field__input", {
                        "eds-text-field__input--error": hasErrors,
                        "eds-text-field__input--no-border": noBorder === true,
                    }), rows: minRows, id: id, style: __assign({ maxHeight: maxHeight, resize: resize }, style), ref: function (node) {
                        if (localRef !== null) {
                            localRef.current = node;
                        }
                        if (ref !== null) {
                            // eslint-disable-next-line no-param-reassign
                            ref.current = node;
                        }
                    }, onChange: handleChange, "aria-describedby": "".concat(id, "-meta") }, valueSpreadMapping, rest)) })), (hasErrors || maxChars || showCount || message) && (_jsx(InputMessageGroup, { id: "".concat(id, "-meta"), message: message, errorMessage: hasErrors && (errorMessage || "Error"), count: showCount ? localInputValue === null || localInputValue === void 0 ? void 0 : localInputValue.toString().length : undefined, maxCount: showCount ? maxChars : undefined }))] }));
});
