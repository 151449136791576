"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstValidationError = void 0;
const types_1 = require("../types");
const getFirstValidationError = (errors) => {
    if (!errors || !Array.isArray(errors)) {
        return undefined;
    }
    const validationErrors = errors.find((e) => e.type === types_1.UploadErrorType.validation);
    return validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.error[0];
};
exports.getFirstValidationError = getFirstValidationError;
