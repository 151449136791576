export const ERR_CODE_CONFLICT_EXCEPTION = 'ConflictException';
export const ERR_CODE_DEVICE_ALREADY_ASSIGNED = 'DEVICE_ALREADY_ASSIGNED';
export const ERR_MESSAGE_CONFLICT_EXCEPTION = 'Update Failed. Device Vendor & Serial Number is allready assigned to another Workstation.';
export const ERR_MESSAGE_DEVICE_ALREADY_ASSIGNED = 'Adding Failed. The device is already assigned to another Workstation Number.';

export const WORKSTATION_DEVICE_TYPES = [
  { label: 'Nike POS', value: 'FIXED_POSITION' },
  { label: 'Kiosk Selfcheckout', value: 'SELF_CHECKOUT' },
  { label: 'Assist Mobile', value: 'MOBILE' },
];

/*
  isMandatory: Will decide whether the field is required while adding / updating
  isUpdatable: Will decide whether the field is editable while updating
  isUserInput: Will decide whether the field is autogenerated or user input
*/
export const REGISTERS_TABLE_COLUMNS = [
  {
    accessor: 'type',
    control: 'dropdown',
    Header: 'Device Type',
    isMandatory: true,
    isUpdatable: false,
    isUserInput: true,
  },
  {
    accessor: 'workstationNumber',
    control: 'textfield',
    Header: 'Workstation Number',
    isMandatory: false,
    isUpdatable: false,
    isUserInput: false,
    width: 200,
  },
  {
    accessor: 'vendorCode',
    control: 'textfield',
    Header: 'Hardware Vendor Code',
    isMandatory: true,
    isUpdatable: true,
    isUserInput: true,
  },
  {
    accessor: 'serialNumber',
    control: 'textfield',
    Header: 'Hardware Serial Number',
    isMandatory: true,
    isUpdatable: true,
    isUserInput: true,
    width: 400,
  },
  {
    accessor: 'actions',
    control: 'actions',
    Header: 'Actions',
    isUpdatable: false,
    width: 250,
  },
];
