import {
  Default, isNone, isSome, match
} from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, {
  createContext, useCallback, useEffect, useMemo, useState
} from 'react';
import { useSelector } from 'react-redux';

import {
  getHierarchy
} from '../services/hierarchy-service';
import { getDistrictStores } from '../services/stores-service';
import {
  isChildOf, isNodeType, isNodeTypeAndName
} from '../utils/hierarchy-utils';
import { getUserStoreHierarchyGroups } from '../utils/permissions';

export const StoreHierarchyContext = createContext();

const DEFAULT_INITIAL_STATE = null;
const IS_LOADING_INITIAL_STATE = true;
const LOAD_PAGE_INITIAL_STATE = true;

const getHierarchyForNode = (node = null, allNodes = [], currentHierarchy = []) => {
  if (isNone(node)) {
    return currentHierarchy;
  }
  const parent = allNodes.find((n) => n.id === node.parentId);
  return getHierarchyForNode(parent, allNodes, [node, ...currentHierarchy]);
};

const StoreHierarchyContextProvider = ({ children }) => {
  const [loadPage, setLoadPage] = useState(LOAD_PAGE_INITIAL_STATE);
  const [isPageLoading, setIsPageLoading] = useState(IS_LOADING_INITIAL_STATE);
  const [hierarchyNodes, setHierarchyNodes] = useState([]);
  const [hierarchyNodeLookupTable, setHierarchyNodeLookupTable] = useState({});
  const [error, setErrorImpl] = useState(DEFAULT_INITIAL_STATE);
  const [selectedBrand, setSelectedBrand] = useState(DEFAULT_INITIAL_STATE);
  const [selectedRegion, setSelectedRegion] = useState(DEFAULT_INITIAL_STATE);
  const [selectedTerritory, setSelectedTerritory] = useState(DEFAULT_INITIAL_STATE);
  const [selectedDistrict, setSelectedDistrict] = useState(DEFAULT_INITIAL_STATE);
  const [regionStores, setRegionStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(DEFAULT_INITIAL_STATE);
  const setError = useCallback((err) => {
    if (isSome(err) && process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify({
        error: String(err),
         fn: 'setError'
      }, null, 2));
    }
    return setErrorImpl(err);
  }, []);
  const [districtStores, setDistrictStores] = useState(DEFAULT_INITIAL_STATE);
  const [successMessage, setSuccessMessage] = useState(DEFAULT_INITIAL_STATE);
  const [activeNodeId, setActiveNodeId] = useState(DEFAULT_INITIAL_STATE);

  const auth = useSelector((state) => state?.authorizationReducer?.auth ?? '');
  const accessToken = useMemo(() => auth?.accessToken ?? '', [auth]);
  const userGroups = useMemo(() => getUserStoreHierarchyGroups(auth?.groups ?? []), [auth]);

  const sortBrands = ({ name: l }, { name: r }) => match()(
    [() => l === 'NIKE' && r !== 'NIKE', -1],
    [() => l !== 'NIKE' && r === 'NIKE', 1],
    [Default, () => l.localeCompare(r)]
  );

  const brands = useMemo(() => (Array.isArray(hierarchyNodes)
    ? hierarchyNodes
      .filter(isNodeType('BRAND'))
      .sort(sortBrands)
    : []),
    [hierarchyNodes]);

  // Update hierarchy lookup table when hierarchy nodes change
  useEffect(() => {
    const newHierarchyLookupTable = isSome(hierarchyNodes)
      ? hierarchyNodes.reduce((acc, node) => ({ ...acc, [node.id]: node }), {})
      : {};
    setHierarchyNodeLookupTable(newHierarchyLookupTable);
  }, [hierarchyNodes]);

  // Load/Reload Page
  useEffect(() => {
    if (!loadPage || isNone(accessToken)) {
      return;
    }
    setLoadPage(false);
    setIsPageLoading(true);
    setSelectedStore(DEFAULT_INITIAL_STATE);
    setSelectedDistrict(DEFAULT_INITIAL_STATE);
    setSelectedTerritory(DEFAULT_INITIAL_STATE);
    setSelectedRegion(DEFAULT_INITIAL_STATE);
    setSelectedBrand(DEFAULT_INITIAL_STATE);
    // eslint-disable-next-line promise/catch-or-return
    getHierarchy(accessToken)
      .then(setHierarchyNodes)
      .catch(setError)
      .finally(() => {
        if (isSome(selectedBrand)) {
          setSelectedBrand(selectedBrand);
          setActiveNodeId(selectedBrand.id);
        }
        setIsPageLoading(false);
      });
  }, [accessToken, selectedBrand, loadPage, setError]);

  // Set initial selected brand
  useEffect(() => {
    if (isSome(selectedBrand)) {
      return;
    }

    const isNikeBrand = isNodeTypeAndName('BRAND', 'NIKE');
    const initialSelectedBrand = match()(
      [() => brands.length === 0, () => DEFAULT_INITIAL_STATE],
      [() => brands.length === 1, () => brands[0]],
      [() => brands.some(isNikeBrand), () => brands.find(isNikeBrand)],
      [Default, () => brands[0]]
    );

    if (isSome(initialSelectedBrand)) {
      setSelectedBrand(initialSelectedBrand);
      setActiveNodeId(initialSelectedBrand.id);
    }
  }, [brands, selectedBrand]);

  // District Stores
  useEffect(() => {
    if (isNone(selectedDistrict) || isNone(accessToken)) {
      setDistrictStores(DEFAULT_INITIAL_STATE);
      return;
    }

    setIsPageLoading(true);
    // eslint-disable-next-line promise/catch-or-return
    getDistrictStores(selectedDistrict, accessToken)
      .then(setDistrictStores)
      .catch(setError)
      .finally(() => setIsPageLoading(false));
  }, [selectedDistrict, setError, accessToken]);

  // If there is a activeNodeId, clear it then set it and its parent nodes as selected
  useEffect(() => {
    if (isNone(activeNodeId) || isNone(hierarchyNodes) || hierarchyNodes.length === 0) {
      return;
    }
    setActiveNodeId(DEFAULT_INITIAL_STATE);

    // If there's no corresponding node, do nothing
    const activeNode = hierarchyNodes.find((node) => node.id === activeNodeId);
    if (isNone(activeNode)) {
      return;
    }

    const activeNodeHierarchy = getHierarchyForNode(activeNode, hierarchyNodes);
    const [
      brand = DEFAULT_INITIAL_STATE,
      region = DEFAULT_INITIAL_STATE,
      territory = DEFAULT_INITIAL_STATE,
      district = DEFAULT_INITIAL_STATE,
      store = DEFAULT_INITIAL_STATE
    ] = activeNodeHierarchy;
    const brandRegions = hierarchyNodes.filter(isChildOf(brand));
    const [firstRegion] = brandRegions;
    const isOnlyOneRegion = brandRegions.length === 1;
    const effectiveRegion = isOnlyOneRegion ? firstRegion : region;

    const regionTerritories = isOnlyOneRegion
      ? hierarchyNodes.filter(isChildOf(firstRegion))
      : [];
    const [firstTerritory] = regionTerritories;
    const isOnlyOneTerritory = regionTerritories.length === 1;
    const effectiveTerritory = isOnlyOneTerritory ? firstTerritory : territory;

    setSelectedStore(store);
    setSelectedDistrict(district);
    setSelectedTerritory(effectiveTerritory);
    setSelectedRegion(effectiveRegion);
    setSelectedBrand(brand);
  }, [activeNodeId, hierarchyNodes, selectedBrand, selectedDistrict, selectedRegion, selectedStore, selectedTerritory]);

  return (
    <StoreHierarchyContext.Provider value={{
      accessToken,
      activeNodeId,
      brands,
      districtStores,
      error,
      hierarchyNodeLookupTable,
      hierarchyNodes,
      isPageLoading,
      loadPage,
      regionStores,
      selectedBrand,
      selectedDistrict,
      selectedRegion,
      selectedStore,
      selectedTerritory,
      setActiveNodeId,
      setDistrictStores,
      setError,
      setHierarchyNodeLookupTable,
      setHierarchyNodes,
      setIsPageLoading,
      setLoadPage,
      setRegionStores,
      setSelectedBrand,
      setSelectedDistrict,
      setSelectedRegion,
      setSelectedStore,
      setSelectedTerritory,
      setSuccessMessage,
      successMessage,
      userGroups,
    }}
    >
      {children}
    </StoreHierarchyContext.Provider>
  );
};

StoreHierarchyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StoreHierarchyContextProvider;
