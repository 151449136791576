import {
  Button,
  ModalActionsStyled,
  ModalStyled, NikeDesignSystemProvider,
} from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import '@nike/nike-design-system-components/polyfills';
import React from 'react';

import './ConfirmationModal.css';

const ConfirmationModal = ({
  campaignName, deleteCampaignHandler, isOpen, toggleModal,
}) => {
  if (!isOpen) return null;

  const closeModal = () => toggleModal(false);

  const Actions = () => (
    <ModalActionsStyled>
      <section>
        <Button
          className="ncss-btn-primary-dark bg-accent mt6-sm ml2-sm border"
          onClick={deleteCampaignHandler}
        >
          Confirm
        </Button>
        <Button
          className="ncss-btn-secondary-dark mt6-sm ml2-sm border"
          onClick={closeModal}
        >
          Close
        </Button>
      </section>
    </ModalActionsStyled>
  );

  return (
    <>
      <NikeDesignSystemProvider>
        <ModalStyled
          isOpen={isOpen}
          slots={{ FooterSlot: <Actions /> }}
          title="Confirm"
        > <p>{`Are you sure you want to delete ${campaignName}?`}</p>
        </ModalStyled>
      </NikeDesignSystemProvider>
      <article id="modal-root" />
    </>
  );
};

ConfirmationModal.defaultProps = {
  isOpen: false,
};

ConfirmationModal.propTypes = {
  campaignName: PropTypes.string.isRequired,
  deleteCampaignHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

export default ConfirmationModal;
