{
    "lastA11yAudit": "07/08/22",
    "name": "Text Area",
    "docs": "#/components/forms/TextArea",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/TextArea",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=638%3A153114",
    "tags": [
        "textarea",
        "combobox",
        "input",
        "forms",
        "form elements",
        "textbox",
        "text box"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
