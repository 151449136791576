import { Checkbox, Input, Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { createOrUpdateCampaign, deleteCampaign } from '../../../utils/service-calls/qrcodegenerator';
import { REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import { ButtonWhite, ButtonSubmit } from '../../reusable';
import ToolTip from '../../reusable/ToolTip/ToolTip';
import ConfirmationModal from '../common/confirmation-modal/ConfirmationModal';
import * as constants from '../manage-campaign/constants';

const CreateCampaign = (props) => {
  const auth = useSelector((state) => state.authorizationReducer.auth);

  const [campaignName, setCampaignName] = useState('');
  const [campaignDescription, setCampaignDescription] = useState('');
  const [urlTemplate, setUrlTemplate] = useState('');
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [campaignId, setCampaignId] = useState('');
  const [callPending, setCallPending] = useState(false);
  const [callFailed, setCallFailed] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [webUrl, setWebUrl] = useState(false);
  const [formErrors] = useState({
    campaignDescription: REQUIRED_FIELD,
    campaignName: REQUIRED_FIELD,
    urlTemplate: REQUIRED_FIELD,
  });

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.editCampaign) {
      const data = props.location.state.campaignData;
      setCampaignName(data.campaignName);
      setCampaignDescription(data.campaignDescription);
      setUrlTemplate(data.campaignUrlTemplate);
      setCampaignId(data.campaignId);
      setWebUrl(data.webUrl);
      setEditModeEnabled(true);
    }
  }, [props.location]);

  const createOrUpdateCampaignHandler = () => {
    if (callPending) return;
    const body = {
      campaignDescription,
      campaignName,
      campaignUrlTemplate: urlTemplate,
      emailId: auth.email,
      webUrl,
    };
    if (campaignId) body.campaignId = campaignId;

    setCallPending(true);
    createOrUpdateCampaign(auth.accessToken, body, editModeEnabled ? 'put' : 'post')
      .then((data) => {
        setCallPending(false);
        setCallFailed(false);
        props.history.push({ pathname: constants.PATH_NAME, state: !editModeEnabled ? 'Create' : '' });
        return data;
      })
      .catch(() => {
        setCallPending(false);
        setCallFailed(true);
      });
  };

  const toggleModal = (val) => setShowModal(val);

  const deleteCampaignHandler = () => {
    setDeleteMode(true);
    toggleModal(false);
    if (callPending) return;
    setCallPending(true);
    deleteCampaign(auth.accessToken, { campaignId, emailId: auth.email })
      .then((data) => {
        setCallPending(false);
        setCallFailed(false);
        props.history.push({ pathname: constants.PATH_NAME, state: 'Delete' });
        return data;
      })
      .catch(() => {
        setCallPending(false);
        setCallFailed(true);
      });
  };

  const isDisabled = () => !campaignName || !campaignDescription || !urlTemplate;

  return (
    <section className="ncss-col-sm-9">
      <section className="ncss-col-xl-9 ncss-col-lg-10 ncss-col-md-10 ncss-col-sm-12 mb2-sm">
        <section className="mb6-sm body-3" style={{ lineHeight: '30px' }}>
          <Input
            errorMessage={!campaignName && formErrors.campaignName}
            id="campaignName"
            label="Campaign Name"
            type="string"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
        </section>
        <section className="mb6-sm body-3" style={{ lineHeight: '30px' }}>
          <Input
            errorMessage={!campaignDescription && formErrors.campaignDescription}
            id="campaignDescription"
            label="Campaign Description"
            type="string"
            value={campaignDescription}
            onChange={(e) => setCampaignDescription(e.target.value)}
          />
        </section>
        <section className="mb6-sm body-3" style={{ lineHeight: '30px' }}>
          <ToolTip>
            <p>Set URL Template with {'<storeNo>'} for Store Number, {'<storeId>'} for Store Id, and {'<sequenceNo>'} for Sequence Number as placeholders. The placeholders will be replaced with actual values in the final URL.</p>
            <br />
            <p>URL Template: mynike://x-callback-url/retail-mode?store_id={'<storeId>'}</p>
            <p>Final URL: mynike://x-callback-url/retail-mode?store_id=339EF669C2254B2EE05336680C0A6639</p>
          </ToolTip>
          <Input
            errorMessage={!urlTemplate && formErrors.urlTemplate}
            id="urlTemplate"
            label="URL Template"
            type="string"
            value={urlTemplate}
            onChange={(e) => setUrlTemplate(e.target.value)}
          />
        </section>
        <section className="ncss-row ta-sm-l">
          <article className="ncss-col-sm-12">
            <Checkbox
              isDisabled
              className="mb8-sm va-sm-t ncss-col-sm-6 ncss-col-md-4 ncss-col-lg-3 ncss-col-xl-2 ta-sm-c"
              id="webUrl"
              isChecked={webUrl}
              label="Web URL"
              onChange={({ target: { checked } }) => setWebUrl(checked)}
            />
          </article>
        </section>
        {callFailed && <article className="text-color-error">{constants.SOMETHING_WENT_WRONG}</article>}
        {callPending && (
        <>
          <Loading />
          {deleteMode ? constants.DELETE_CAMPAIGN_PENDING : constants.SAVE_CAMPAIGN_PENDING}
        </>
        )}
        <section className="ncss-row ta-sm-l">
          <article className="ncss-col-sm-12">
            <ButtonSubmit
              className={`va-sm-t ${!editModeEnabled ? 'ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 ncss-col-xl-3' : 'ncss-col-sm-5 ncss-col-md-3 ncss-col-lg-2 ncss-col-xl-1'} pl0-sm`}
              isDisabled={isDisabled()}
              label={editModeEnabled ? 'Save' : 'Create Campaign'}
              onClick={createOrUpdateCampaignHandler}
            />
            {editModeEnabled && (
            <ButtonSubmit
              className="mb8-sm va-sm-t ncss-col-sm-6 ncss-col-md-4 ncss-col-lg-3 ncss-col-xl-2 ta-sm-c"
              isDisabled={isDisabled()}
              label="Delete"
              onClick={() => setShowModal(true)}
            />
            )}
            <Link to="/qrcodegenerator/managecampaign">
              <ButtonWhite
                classname="ncss-col-sm-1 ml10-sm"
                label="Cancel"
                onClick={() => null}
              />
            </Link>
          </article>
        </section>
      </section>
      <ConfirmationModal
        campaignName={campaignName}
        deleteCampaignHandler={deleteCampaignHandler}
        isOpen={showModal}
        toggleModal={toggleModal}
      />
    </section>
  );
};

CreateCampaign.defaultProps = {
  history: {},
  location: {},
};

CreateCampaign.propTypes = {
  history: PropTypes.shape(),
  location: PropTypes.shape(),
};

export default CreateCampaign;
