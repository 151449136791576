{
    "lastA11yAudit": "07/08/22",
    "name": "Icon Button",
    "docs": "#/components/icons/IconButton",
    "figma": "",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/IconButton",
    "tags": ["iconbutton", "cta", "call to action", "glyph", "symbol"],
    "designStatuses": ["Backlog"],
    "devStatuses": ["Shipped"]
}
