import { requestWrapper } from './reusable';

const config = require('../../config')();

/* ZONES */

export const getZones = (storeId, token) => requestWrapper(
  token,
  config.zonesResource,
  'get',
  { filter: `storeId(${storeId})` },
);

export const postZone = (zone, token) => requestWrapper(
  token,
  config.zonesResource,
  'post',
  undefined,
  zone,
);

export const putZone = (zone, token) => requestWrapper(
  token,
  config.zonesResource,
  'put',
  undefined,
  zone,
);

export const deleteZone = (zoneId, token) => requestWrapper(
  token,
  config.zonesResource,
  'delete',
  undefined,
  { zoneId },
);

/* LOCALIZATIONS */

export const getZoneLocalizations = (zoneId, token) => requestWrapper(
  token,
  config.zoneLocalizationsResource,
  'get',
  { filter: `zoneId(${zoneId})` },
);

export const postZoneLocalization = (localization, token) => requestWrapper(
  token,
  config.zoneLocalizationsResource,
  'post',
  undefined,
  localization,
);

export const putZoneLocalization = (localization, token) => requestWrapper(
  token,
  config.zoneLocalizationsResource,
  'put',
  undefined,
  localization,
);

export const deleteZoneLocalization = (localizationId, token) => requestWrapper(
  token,
  config.zoneLocalizationsResource,
  'delete',
  undefined,
  { localizationId },
);
