import { Checkbox, Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import {
  ButtonBlack, ButtonRed, CustomPanel, DateInput, LeadTimeInput, Select, TimeInput,
} from '../../reusable';
import ToolTip from '../../reusable/ToolTip/ToolTip';

import { carrierCodeOptions, daysOfTheWeek } from './constants';

// used to enforce user-typed input for numbers
export const enforceMinMax = (element) => {
  if (element.value !== '') {
    if (parseInt(element.value, 10) < parseInt(element.min, 10)) {
      // eslint-disable-next-line no-param-reassign
      element.value = element.min;
    }
    if (parseInt(element.value, 10) > parseInt(element.max, 10)) {
      // eslint-disable-next-line no-param-reassign
      element.value = element.max;
    }
  }
};

const StoresSFS = (props) => (
  <article className="ncss-row mt2-sm">
    <section className="ncss-col-sm-12 ta-sm-l mt4-sm">
      <section>
        <ToolTip left="290px">
          <p><b>Default Fulfillment Lead Time: </b>Fulfillment Lead Time helps determine the default order cutoff time at the store. Subtract the Lead Time from the Pickup Time to calculate the order cutoff time for the selected store that day of week. This order cutoff time determines when capacity at the store will reset, and new orders drop for fulfillment.</p>
          <br />
          <p><b>Example: </b>If Default Fulfillment Lead Time is 2 hours and pickup time for FedEx is 9:15am on Monday, the order cutoff time for the store will be 7:15am on Monday.</p>
        </ToolTip>
      </section>
      <ToolTip left="290px">
        <p><b>Default Fulfillment Lead Time: </b>Fulfillment Lead Time helps determine the default order cutoff time at the store. Subtract the Lead Time from the Pickup Time to calculate the order cutoff time for the selected store that day of week. This order cutoff time determines when capacity at the store will reset, and new orders drop for fulfillment.</p>
        <br />
        <p><b>Example: </b>If Default Fulfillment Lead Time is 2 hours and pickup time for FedEx is 9:15am on Monday, the order cutoff time for the store will be 7:15am on Monday.</p>
      </ToolTip>
      <LeadTimeInput
        label="Default Fulfillment Lead Time"
        labelClass="headline-2"
        leadTimeData={props.leadTimeData}
        updateLeadTimeData={props.updateLeadTime}
      />
    </section>
    <section className="ncss-col-sm-12 ta-sm-l mt4-sm">
      <h1 className="headline-2">Default Capacities</h1>
      <section className="border">
        {daysOfTheWeek.map((day) => (
          <article key={day} className="ncss-col-sm-3">
            {day.charAt(0).toUpperCase() + day.slice(1)}
            <Input
              className="ncss-input pl3-sm text-border"
              id={`${day}Capacity`}
              max="3000"
              min="0"
              step="1"
              type="number"
              value={props.fulfillmentCapacities.defaultCapacity[day].maxUnits}
              onChange={({ target }) => {
                enforceMinMax(target);
                props.updateFulfillmentCapacities('defaultCapacity', { ...props.fulfillmentCapacities.defaultCapacity, [day]: { maxUnits: parseInt(target.value || 0, 10) } });
              }}
            />
          </article>
        ))}
        <aside className="ncss-col-sm-12 mt2-sm"><hr /></aside>
        <section className="ncss-col-sm-12 mb2-sm">
          <h1 className="headline-4">Capacity Exceptions</h1>
          <article className="ncss-col-sm-12 border">
            {(props.fulfillmentCapacities.capacityExceptions || []).map((exception, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <section key={i} className="mt1-sm mb1-sm" style={{ marginLeft: '-8px', marginRight: '-8px' }}>
                <article className="ncss-col-sm-11 va-sm-t">
                  <DateInput
                    className="ncss-col-sm-4"
                    errorMessage={!exception.from ? REQUIRED_FIELD : ''}
                    id="exceptionFrom"
                    label="From"
                    value={exception.from}
                    onChange={(from) => props.updateFulfillmentCapacities('capacityExceptions', { ...exception, from }, i)}
                  />
                  <DateInput
                    className="ncss-col-sm-4"
                    errorMessage={!exception.to ? REQUIRED_FIELD : ''}
                    id="exceptionTo"
                    label="To"
                    value={exception.to}
                    onChange={(to) => props.updateFulfillmentCapacities('capacityExceptions', { ...exception, to }, i)}
                  />
                  <section className="ncss-col-sm-4">
                    Max Units
                    <Input
                      id="capacityException"
                      max="3000"
                      min="0"
                      step="1"
                      type="number"
                      value={exception.maxUnits}
                      onChange={({ target }) => {
                        enforceMinMax(target);
                        props.updateFulfillmentCapacities('capacityExceptions', { ...exception, maxUnits: parseInt(target.value || 0, 10) }, i);
                      }}
                    />
                  </section>
                  {exception.from > exception.to && <aside className="text-color-error body-4 mt2-sm">Error: From date must be before To date.</aside>}
                </article>
                <ButtonRed className="va-sm-t mt7-sm" label={<i className="g72-x-thick" />} onClick={() => props.deleteCapacityException(i)} />
                <aside className="mt2-sm" style={{ borderTop: '1px dashed' }} />
              </section>
            ))}
            {props.exceptionDatesOverlap && <aside className="text-color-error body-4 mt2-sm">Error: Capacity Exceptions must not overlap.</aside>}
            <ButtonBlack className="m2-sm" label="Add Capacity Exception" onClick={props.addCapacityException} />
          </article>
        </section>
      </section>
    </section>
    <section className="ncss-col-sm-12 ta-sm-l mt4-sm">
      <h1 className="headline-2">Carrier Pickup Times</h1>
      <article className="ncss-col-sm-12 border pt2-sm">
        {daysOfTheWeek.map((day) => (
          <CustomPanel
            key={day}
            isOpen={props.isOpen[day] || false}
            label={day.charAt(0).toUpperCase() + day.slice(1)}
            onClick={() => props.panelToggle(day)}
          >
            <article className="ncss-col-sm-12">
              {props.fulfillmentCapacities.carrierPickupTimes[day].map((pickup, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <section key={i}>
                  <ToolTip left="16px">
                    <p><b>Pickup Time: </b>This is the time of day we expect a carrier to arrive to pick up outbound orders. This time is combined with Fulfillment Lead Time to calculate the order cutoff time at the store.</p>
                  </ToolTip>
                  <TimeInput
                    className="ncss-col-sm-6 va-sm-t"
                    errorMessage={!pickup.pickupTime ? REQUIRED_FIELD : ''}
                    label="Pickup Time"
                    value={pickup.pickupTime}
                    onChange={(pickupTime) => props.updateFulfillmentCapacities(`carrierPickupTimes.${day}`, { ...pickup, pickupTime }, i)}
                  />
                  <Select
                    className="ncss-col-sm-3 va-sm-t"
                    errorMessage={!pickup.carrierCode ? REQUIRED_FIELD : ''}
                    id="carrierCode"
                    label="Carrier Code"
                    options={carrierCodeOptions}
                    value={pickup.carrierCode}
                    zIndex={-8}
                    onChange={(carrierCode) => props.updateFulfillmentCapacities(`carrierPickupTimes.${day}`, { ...pickup, carrierCode }, i)}
                  />
                  <section className="ncss-col-sm-3 va-sm-t ta-sm-r ">
                    <ButtonRed className="mt6-sm" label={<i className="g72-x-thick" />} onClick={() => props.deleteCarrierPickupTime(day, i)} />
                  </section>
                  <section className="ncss-col-sm-6 va-sm-t">
                    <ToolTip left="220px">
                      <p><b>Override Default Fulfillment Lead Time: </b>If the user selects “Override Default Fulfillment Lead Time”, the new lead time will replace the “Default Fulfillment Lead Time” for the day and pickup time. If the user selects this box, they can then enter a new lead time for the store on this day of week.</p>
                    </ToolTip>
                    <Checkbox
                      key="carrierLeadTime"
                      isChecked={pickup.leadTimeDuration || false}
                      label="Override Default Fulfillment Lead Time"
                      onChange={({ target: { checked } }) => (checked
                        ? props.updateCarrierPickupLeadTime(day, i, '2', '0', '0')
                        : props.updateCarrierPickupLeadTime(day, i, undefined, undefined, undefined, true))}
                    />
                  </section>
                  <section className="ncss-col-sm-9">
                    {pickup.leadTimeDuration && (
                      <LeadTimeInput
                        label="Lead Time Duration"
                        leadTimeData={props.carrierPickupLeadTimeData[day][i]}
                        updateLeadTimeData={(h, m, s) => props.updateCarrierPickupLeadTime(day, i, h, m, s)}
                      />
                    )}
                  </section>
                  <aside className="mt1-sm mb1-sm" style={{ borderTop: '1px dashed' }} />
                </section>
              ))}
              <ButtonBlack className="mt2-sm" label="Add Carrier Pickup Time" onClick={() => props.addCarrierPickupTime(day)} />
            </article>
          </CustomPanel>
        ))}
      </article>
    </section>
  </article>
);

StoresSFS.propTypes = {
  addCapacityException: PropTypes.func.isRequired,
  addCarrierPickupTime: PropTypes.func.isRequired,
  carrierPickupLeadTimeData: PropTypes.shape().isRequired,
  deleteCapacityException: PropTypes.func.isRequired,
  deleteCarrierPickupTime: PropTypes.func.isRequired,
  exceptionDatesOverlap: PropTypes.bool.isRequired,
  fulfillmentCapacities: PropTypes.shape({
    capacityExceptions: PropTypes.arrayOf(PropTypes.shape()),
    carrierPickupTimes: PropTypes.shape().isRequired,
    defaultCapacity: PropTypes.shape().isRequired,
  }).isRequired,
  isOpen: PropTypes.shape().isRequired,
  leadTimeData: PropTypes.shape().isRequired,
  panelToggle: PropTypes.func.isRequired,
  updateCarrierPickupLeadTime: PropTypes.func.isRequired,
  updateFulfillmentCapacities: PropTypes.func.isRequired,
  updateLeadTime: PropTypes.func.isRequired,
};

export default StoresSFS;
