import PropTypes from 'prop-types';
import React from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  ButtonSubmit, DateInput, Select, StoreSelect,
} from '../reusable';

const Form = (props) => (
  <section className="ncss-col-sm-12 ta-sm-c mt2-sm mb2-sm">
    <aside className="text-color-accent ta-sm-l">
      Please select a country and company to Generate WFM Reports.
    </aside>
    <section className="ncss-row">
      <article className="ncss-col-sm-12">
        <StoreSelect
          isRequired
          noStores
          formErrors={props.formErrors}
          selectCountry={(country) => props.onCountryChange(country)}
          selectStore={() => { }}
          selectedCountry={props.selectedCountry}
          zIndex={10}
        />
      </article>
      <Select
        className="ncss-col-sm-6 ta-sm-l"
        errorMessage={props.formErrors.company}
        id="companySelect"
        label="Company"
        options={[{ label: 'Nike', value: 'Nike' }, { label: 'Converse', value: 'Converse' }]}
        value={props.storeDetails?.company}
        zIndex={5}
        onChange={(company) => props.onCompanyChange(company)}
      />
      <br />
      <DateInput
        className="ncss-col-sm-6"
        id="fromDate"
        label="From"
        value={props.startDate || ''}
        onChange={(value) => props.onStartDateChange(value)}
      />
      <DateInput
        className="ncss-col-sm-6"
        id="untilDate"
        label="Until"
        value={props.endDate || ''}
        onChange={(value) => props.onEndDateChange(value)}
      />
      {props.startDate > props.endDate && <aside className="ncss-col-sm-12 text-color-error">From date must be before Until date</aside>}
      <ButtonSubmit
        isDisabled={!(props.storeDetails?.address.country && props.storeDetails?.company) || props.startDate > props.endDate}
        isLoading={props.fetching}
        label="Generate WFM Reports"
        onClick={props.onSubmit}
      />
    </section>
  </section>
);

Form.defaultProps = {
  storeDetails: null,
};

Form.propTypes = {
  endDate: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  formErrors: PropTypes.shape().isRequired,
  onCompanyChange: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedCountry: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
  storeDetails: PropTypes.shape(),
};

export default Form;
