const validateStyleColorCode = (code, i) => {
  const minStyleLength = 5;
  const reqColorLength = 3;
  const [style, color] = code.split('-');
  const styleError = style.length >= minStyleLength ? [] : [`${i + 1}: Column A style code must be at least 5 characters (style-color)`];
  const colorError = !color || color.length === reqColorLength ? [] : [`${i + 1}: Column A color code must be 3 characters if present (style-color)`];
  return [...styleError, ...colorError];
};

const validateColumnA = (a, i) => (a ? validateStyleColorCode(a, i) : [`${i + 1}: Column A is required (style-color)`]);

const validateColumnB = (b, i) => (b ? [] : [`${i + 1}: Column B is required`]);

const validateRow = ([a, b], i) => [...validateColumnA(a, i), ...validateColumnB(b, i)];

const prevalidateData = (unvalidatedData) => unvalidatedData.data.slice(1).reduce((errors, row, index) => [...errors, ...(row.length !== 1 && row[0] !== '' ? validateRow(row, index) : [])], []);

module.exports = { prevalidateData };
