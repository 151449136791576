{
    "lastA11yAudit": "07/08/22",
    "name": "Status Icon",
    "description": "Status Icon provides additional context through the use of color and icons.",
    "docs": "#/components/StatusIcon",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=1355%3A180764",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/StatusIcon",
    "tags": ["Status", "Icon", "Status Indicator", "glyph", "symbol"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
