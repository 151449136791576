import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel, DateInput } from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!properties.formErrors.openingDate || !!properties.formErrors.closingDate);

class Operational extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Operational"
      onClick={this.panelToggle}
    >
      <DateInput
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.openingDate}
        id="openingDate"
        isDisabled={this.props.userIsReadOnly}
        label="Opening Date"
        value={this.props.formData.openingDate || ''}
        onChange={(value) => this.props.updateFormData('openingDate', value)}
      />
      <DateInput
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.closingDate}
        id="closingDate"
        isDisabled={this.props.userIsReadOnly}
        label="Closing Date"
        value={this.props.formData.closingDate || ''}
        onChange={(value) => this.props.updateFormData('closingDate', value)}
      />
    </CustomPanel>
  );
}

Operational.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Operational;
