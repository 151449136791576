const converseNumberMap = {
  3102: '3102',
  3702: '2',
  3703: '0003',
  3704: '4',
  3707: '7',
  3708: '8',
  3709: '0009',
  3711: '0011',
  3712: '0012',
  3713: '13',
  3715: '15',
  3719: '0019',
  3720: '20',
  3721: '21',
  3724: '0024',
  3725: '25',
  3727: '27',
  3728: '28',
  3729: '29',
  3730: '0030',
  3732: '32',
  3733: '33',
  3734: '034',
  3735: '0035',
  3739: '39',
  3740: '040',
  3741: '0041',
  3742: '42',
  3743: '0043',
  3744: '0044',
  3745: '45',
  3746: '46',
  3747: '47',
  3749: '49',
  3750: '0050',
  3754: '0056',
  3755: '58',
  3756: '059',
  3758: '0062',
  3770: '3770',
  3783: '3783',
  5303: '0060',
};

export default converseNumberMap;
