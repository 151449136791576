import { isSome } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';

import { companyIdMappings } from '../../utils/static/pos-reasons-mappings';
import { posReasonPermissions } from '../../utils/tab-permissions';
import { PageTemplate, Select } from '../reusable';

import ReasonForm from './ReasonForm';
import ViewReason from './ViewReason';

const POSReasons = ({
  location,
}) => {
  const userToken = useSelector((state) => state.authorizationReducer.auth.accessToken);
  const [companyId, setCompanyId] = useState();
  const TEST_COMPANY_OPTION = { label: 'Test Company', value: '9999' };
  const companyOptions = process.env.NODE_ENV !== 'production'
    ? [TEST_COMPANY_OPTION, ...companyIdMappings]
    : companyIdMappings;
  return (
    <PageTemplate
      auth={posReasonPermissions}
      description="View and manage POS Reasons"
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+POS+Reasons+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
    )}
      page={(
        <>
          <Select
            className="ncss-col-sm-12 mb2-sm ta-sm-l"
            id="companyId"
            label="Company"
            options={companyOptions}
            value={companyId}
            onChange={(id) => setCompanyId(id)}
          />
          { isSome(companyId) && (
          <Tabs>
            <TabList>
              <Tab>View Reasons</Tab>
              <Tab>Add Reason</Tab>
            </TabList>
            <TabPanel>
              <ViewReason
                companyId={companyId}
                userToken={userToken}
              />
            </TabPanel>
            <TabPanel>
              <ReasonForm
                companyId={companyId}
                userToken={userToken}
              />
            </TabPanel>
          </Tabs>
          ) }
        </>
    )}
      path={location.pathname}
      title="POS Reasons"
    />
  );
};

POSReasons.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default POSReasons;
