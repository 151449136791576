/* eslint-disable react/no-array-index-key */
import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import {
  ButtonBlack, DateInput,
} from '../reusable';

import ButtonRedAligned from './ButtonRedAligned';
import ButtonSubmitAligned from './ButtonSubmitAligned';

const FourPartKeyIds = ({
  createBlankQueryId,
  transactionIds,
  setCheckingIngestStatus,
  setTransactionIds,
  maxRows,
}) => {
  const updateFourPartKey = (fieldName, fieldValue, idxToUpdate) => {
    const updatedTransactionIds = transactionIds.map((fourPartKey, idx) => (idx === idxToUpdate
      ? { ...fourPartKey, [fieldName]: fieldValue }
      : fourPartKey));
    setTransactionIds(updatedTransactionIds);
  };

  const addNewRow = () => setTransactionIds([...transactionIds, createBlankQueryId()]);

  const removeRow = (idxToRemove) => setTransactionIds(transactionIds.filter((_, idx) => idx !== idxToRemove));

  const formIsValid = transactionIds
    .every(({
      businessDate, storeNumber, transactionNumber, workstationNumber,
    }) => businessDate !== ''
      && storeNumber !== ''
      && transactionNumber !== ''
      && workstationNumber !== '');

  return (
    <>
      {
        transactionIds.map(({
          businessDate, storeNumber, transactionNumber, workstationNumber,
        }, idx) => (
          <div key={`tx-id-row-${idx}`} className="ncss-row">
            <div className="ncss-col-sm-1" />
            <div className="ncss-col-sm-2">
              <DateInput
                key={`businessDate-${idx}`}
                errorMessage=""
                id={`businessDate-${idx}`}
                label={idx === 0 ? 'Business Date' : ''}
                placeholder="Business Date"
                value={businessDate}
                onChange={(value) => updateFourPartKey('businessDate', value ?? '', idx)}
              />
            </div>
            <div className="ncss-col-sm-2">
              <Input
                key={`storeNumber-${idx}`}
                errorMessage=""
                id={`storeNumber-${idx}`}
                label={idx === 0 ? 'Store Number' : ''}
                placeholder="Store Number"
                value={storeNumber}
                onChange={(event) => updateFourPartKey('storeNumber', event?.target?.value ?? '', idx)}
              />
            </div>
            <div className="ncss-col-sm-2">
              <Input
                key={`transactionNumber-${idx}`}
                errorMessage=""
                id={`transactionNumber-${idx}`}
                label={idx === 0 ? 'Transaction Number' : ''}
                placeholder="Transaction Number"
                value={transactionNumber}
                onChange={(event) => updateFourPartKey('transactionNumber', event?.target?.value ?? '', idx)}
              />
            </div>
            <div className="ncss-col-sm-2">
              <Input
                key={`workstationNumber-${idx}`}
                errorMessage=""
                id={`workstationNumber-${idx}`}
                label={idx === 0 ? 'Workstation Number' : ''}
                placeholder="Workstation Number"
                value={workstationNumber}
                onChange={(event) => updateFourPartKey('workstationNumber', event?.target?.value ?? '', idx)}
              />
            </div>
            <div className="ncss-col-sm-1 ta-sm-l">
              { idx !== 0 && idx === transactionIds.length - 1 && (
              <ButtonRedAligned
                className=""
                label="x"
                onClick={() => removeRow(idx)}
              />
              ) }
            </div>
          </div>
        ))
      }
      <div className="ncss-row">
        <div className="ncss-col-sm-9" />
        <div className="ncss-col-sm-1 ta-sm-l">
          <ButtonBlack
            className=""
            isDisabled={transactionIds.length >= maxRows}
            label="+"
            onClick={addNewRow}
          />
        </div>
      </div>
      <footer className="ncss-row">
        <ButtonSubmitAligned
          className=""
          isDisabled={!formIsValid}
          label="Submit"
          showErrorMessage={false}
          onClick={() => setCheckingIngestStatus(true)}
        />
      </footer>
    </>
  );
};

FourPartKeyIds.propTypes = {
  createBlankQueryId: PropTypes.func.isRequired,
  maxRows: PropTypes.number.isRequired,
  setCheckingIngestStatus: PropTypes.func.isRequired,
  setTransactionIds: PropTypes.func.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default FourPartKeyIds;
