import PropTypes from 'prop-types';
import React from 'react';

import { scsPermissions } from '../../../utils/tab-permissions';
import { PageTemplate } from '../../reusable';

import ConfigByKey from './Main';

const SCSConfigbyKey = (props) => (
  <PageTemplate
    auth={scsPermissions}
    description="View key configuration by store."
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+SCS+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
    )}
    page={<ConfigByKey location={props.location} />}
    path={props.location.pathname}
    title="Store Config Service"
  />
);

SCSConfigbyKey.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default SCSConfigbyKey;
