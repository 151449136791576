/* eslint-disable promise/no-return-wrap */
const crypto = require('crypto');

const removeWebSafe = (safeEncodedString) => safeEncodedString.replace(/-/g, '+').replace(/_/g, '/');
const makeWebSafe = (encodedString) => encodedString.replace(/\+/g, '-').replace(/\//g, '_');
const decodeBase64Hash = (code) => (Buffer.from ? Buffer.from(code, 'base64') : Buffer.from(code, 'base64'));
const encodeBase64Hash = (key, data) => crypto.createHmac('sha1', key).update(data).digest('base64');

const sign = (path) => {
  const secret = '6FCW-sITvtv9KqqUPQzk0ehiTqo=';
  const safeSecret = decodeBase64Hash(removeWebSafe(secret));
  const hashedSignature = makeWebSafe(encodeBase64Hash(safeSecret, path));
  return hashedSignature;
};

const apiGet = (path, query) => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');

  const uri = encodeURI(`/maps/api/${path}/json?client=gme-nikeinc${query}`);
  const signature = sign(uri);
  const requestUrl = `https://maps.googleapis.com${uri}&signature=${signature}`;

  const request = new Request(requestUrl, { headers, method: 'GET' });

  return fetch(request)
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        const contentLength = parseInt(res.headers.get('Content-Length'), 10);
        if (res.status === 204 || contentLength === 0) {
          return {};
        }
        return res.json();
      } else {
        const err = new Error(res.statusText);
        err.status = res.status;
        return res.json().then(
          (errBody) => {
            err.body = errBody;
            throw err;
          },
          // ignore a parsing error, and just throw the original
          () => Promise.reject(err),
        );
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getStoreCoords = (storeData, noLanguage) => {
  let {
    address1,
    city,
    state,
    country,
  } = storeData.address;
  if (noLanguage !== true) {
    address1 = storeData.address.en.address1;
    city = storeData.address.en.city;
    state = storeData.address.en.state;
    country = storeData.address.en.country;
  }
  const query = {
    address1: address1.replace(/#/, '%23'),
    city,
    state: state === '' ? country : state,
  };
  if (!(query.address1 === '' || query.city === '' || query.state === '')) {
    return apiGet('geocode', `&address=${query.address1},${query.city},${query.state}`)
      .then((loc) => {
        const location = { ...loc.results[0].geometry.location };
        return { calculating: false, lat: location.lat, lng: location.lng };
      })
      .catch(() => ({ calculating: false }));
  } else {
    return Promise.reject(new Error('Missing one or more of address line 1, city, or state/country'));
  }
};

export const getTimezone = ({ lat, lng }) => apiGet('timezone', `&location=${lat},${lng}&timestamp=${Date.now() / 1000}`)
  .then((res) => ({ status: res.status, timeZoneId: res.timeZoneId }))
  .catch((err) => err);

export const getCountry = async ({ latitude, longitude }) => (latitude && longitude) && apiGet('geocode', `&latlng=${latitude},${longitude}&result_type=country&timestamp=${Date.now() / 1000}`);
