import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getDevicesByWaitline } from '../selectors/associates';
import getStatusByWaitline from '../selectors/waitlines';

import WaitlineInfo from './WaitlineInfo';

class Waitlines extends React.Component {
  componentDidMount() {
    this.props.waitlinesFetch();
  }

  render = () => {
    if (this.props.fetchingWaitlines) {
      return <aside className="ncss-col-sm-12 mb2-sm"><Loading /></aside>;
    } else if (this.props.error) {
      return <aside className="ncss-col-sm-12 mb2-sm text-color-error">{this.props.error.message}</aside>;
    } else {
      return this.props.waitlines.map((waitline) => (
        <WaitlineInfo
          key={waitline.id}
          device={this.props.devicesByWaitline[waitline.id]}
          status={this.props.statusByWaitline[waitline.id]}
          tickets={this.props.ticketsByWaitline[waitline.id] || []}
          waitline={waitline}
        />
      ));
    }
  }
}

Waitlines.defaultProps = {
  error: undefined,
};

Waitlines.propTypes = {
  devicesByWaitline: PropTypes.shape().isRequired,
  error: PropTypes.shape(),
  fetchingWaitlines: PropTypes.bool.isRequired,
  statusByWaitline: PropTypes.shape().isRequired,
  ticketsByWaitline: PropTypes.shape().isRequired,
  waitlines: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  waitlinesFetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  devicesByWaitline: getDevicesByWaitline(state),
  error: state.waitlinesReducer.fetchWaitlinesError,
  fetchingWaitlines: state.waitlinesReducer.fetchWaitlinesRequest !== null,
  statusByWaitline: getStatusByWaitline(state),
  ticketsByWaitline: state.waitlinesReducer.ticketsByWaitline,
  waitlines: state.waitlinesReducer.waitlines,
});

export default connect(mapStateToProps)(Waitlines);
