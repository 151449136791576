{
    "lastA11yAudit": "07/08/22",
    "name": "Text Group",
    "docs": "#/components/typography/TextGroup",
    "figma": "",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/TextGroup",
    "tags": [
        "textgroup",
        "vertical rhythm",
        "whitespace",
        "spacing",
        "layout",
        "stack"
    ],
    "designStatuses": ["N/A"],
    "devStatuses": ["Shipped"]
}
