import { requestWrapper } from './reusable';

const config = require('../../config')();

// eslint-disable-next-line import/prefer-default-export
export const getHierarchyNodes = (token, queryStringParams) => requestWrapper(
  token,
  config.hierarchyNodesResource,
  'get',
  queryStringParams,
);

export const getHierarchyNode = (token, id) => requestWrapper(
  token,
  `${config.hierarchyNodesResource}/${id}`,
  'get',
);
