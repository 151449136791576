{
    "lastA11yAudit": "07/08/22",
    "name": "Label",
    "description": "Label displays text in all of the various weights and sizes design calls for.",
    "docs": "#/components/forms/Label",
    "figma": "",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Label",
    "tags": [
        "label",
        "inputs",
        "form elements",
        "forms",
        "accessibility",
        "a11y"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
