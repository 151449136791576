import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../../reusable';

const AddOptions = ({ record, onClickAddConfirm, onClickCancel }) => (
  record.isProcessing
    ? <section className="ncss-row full-width"><article className="ncss-col-sm-6 ta-sm-r va-sm-t"><Loading isNotCentered /></article><article className="ncss-col-sm-6 ta-sm-l">Adding...</article></section>
    : (
      <section className="ncss-row full-width">
        <ButtonBlack
          className="m1-sm"
          label="Add"
          onClick={() => onClickAddConfirm(record)}
        />
        <ButtonBlack
          className="m1-sm"
          label="Cancel"
          onClick={() => onClickCancel(record, 'add')}
        />
      </section>
    )
);

AddOptions.propTypes = {
  onClickAddConfirm: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  record: PropTypes.shape().isRequired,
};

export default AddOptions;
