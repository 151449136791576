import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { waitlineCreate } from '../../../actions/waitlines';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import { ButtonBlack, ButtonSubmit } from '../../reusable';

class CreateWaitlineCard extends React.Component {
  constructor() {
    super();
    this.state = {
      alternateId: '',
      destinationId: '',
      destinationType: '',
      formErrors: {
        alternateId: REQUIRED_FIELD,
        name: REQUIRED_FIELD,
      },
      name: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  createInputHandler = (stateProp) => (evt) => this.setState({ [stateProp]: evt.currentTarget.value });

  handleCancel = () => this.props.onCancel();

  handleSubmit = () => this.props.waitlineCreate(this.props.accessToken, this.state);

  isValid = () => !!this.state.name && !!this.state.alternateId;

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <article className="ncss-col-sm-12 border bg-white ta-sm-c mt3-sm mb3-sm">
      <h1 className="p2-sm mb4-sm">Create New Waitline</h1>
      <Input
        errorMessage={this.state.formErrors.name}
        id="name"
        isDisabled={this.props.isSaving}
        label="Friendly Name"
        type="text"
        onChange={this.createInputHandler('name')}
      />
      <Input
        errorMessage={this.state.formErrors.alternateId}
        id="alternateId"
        isDisabled={this.props.isSaving}
        label="Alternate ID"
        type="text"
        onChange={this.createInputHandler('alternateId')}
      />
      <Input
        id="destinationType"
        isDisabled={this.props.isSaving}
        label="Destination Type"
        placeholder="Machine Name, Store Service, etc."
        type="text"
        onChange={this.createInputHandler('destinationType')}
      />
      <Input
        htmlFor="destinationId"
        isDisabled={this.props.isSaving}
        label="Destination ID"
        type="text"
        onChange={this.createInputHandler('destinationId')}
      />
      <ButtonSubmit
        isDisabled={!this.isValid()}
        isLoading={this.props.isSaving}
        label="Save"
        submitError={this.props.error?.message || ''}
        onClick={this.handleSubmit}
      />
      <ButtonBlack
        className="m2-sm mt10-sm"
        isDisabled={this.props.isSaving}
        label="Close"
        onClick={this.handleCancel}
      />
    </article>
  );
}

CreateWaitlineCard.defaultProps = {
  accessToken: undefined,
  error: undefined,
  isSaving: undefined,
  onCancel: undefined,
};

CreateWaitlineCard.propTypes = {
  accessToken: PropTypes.string,
  error: PropTypes.shape(),
  isSaving: PropTypes.bool,
  onCancel: PropTypes.func,
  waitlineCreate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
  error: state.waitlinesReducer.createWaitlineError,
  isSaving: !!state.waitlinesReducer.createWaitlineRequest,
});

const mapDispatchToProps = (dispatch) => ({
  waitlineCreate: (token, waitlineData) => dispatch(waitlineCreate(token, waitlineData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWaitlineCard);
