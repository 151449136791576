export default {
  'de-DE': 'Deutsche',
  'en-GB': 'English',
  'en-US': 'English(American)',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'nl-NL': 'Nederlands',
  'zh-CN': '中文',
};
