import development from './development.json';
import production from './production.json';
import standard from './standard.json';
import test from './test.json';

const config = {
  development,
  production,
  test,
};

const stage = process.env.NODE_ENV;
let activeConfig = {};

module.exports = () => {
  if (Object.keys(activeConfig).length === 0) {
    activeConfig = { ...standard, ...config[stage.toLowerCase()] };
  }
  // to run in the test or production environment locally, add "TESTING=true" to the npm run start:test or :prod script
  if (process.env.TESTING) {
    activeConfig.host = development.host;
    activeConfig.redirectUrl = development.redirectUrl;
  }
  return activeConfig;
};
