import { Checkbox, Loading } from '@nike/frame-component-library';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { HMStoISO, ISOtoHMS, parseSLSandBrickworksErrors } from '../../../utils/formatting';
import { putStore, getStoreById } from '../../../utils/service-calls/sls';
import { ButtonSubmit, LeadTimeInput } from '../../reusable';

import bopis from './constants';

const BOPIS = (props) => {
  const accessToken = useSelector((state) => state.authorizationReducer.auth.accessToken);
  const [bopisEnabled, setBopisEnabled] = useState(false);
  const [bopisExists, setBopisExists] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [leadTimeData, setLeadTimeData] = useState('');
  const [newData, setNewData] = useState({});
  const [oldData, setOldData] = useState({});
  const [saving, setSaving] = useState(false);
  const [storesFetchError, setStoresFetchError] = useState('');
  const [storesSaveError, setStoresSaveError] = useState('');
  const [storesSaveSuccess, setStoresSaveSuccess] = useState('');

  // find the index of BOPIS within the storeServices array
  // if BOPIS does not exist, return the index where BOPIS should be added (the length of the array)
  const findIndexOfBOPIS = (services) => {
    const index = services.findIndex((service) => service.storeServiceTypeId === bopis.storeServiceTypeId);
    return index >= 0 ? index : services.length || 0;
  };

  const updateLeadTime = (hours, minutes, seconds) => {
    setLeadTimeData({ hours, minutes, seconds });
    const toSet = cloneDeep(newData);
    toSet.storeServices[findIndexOfBOPIS(toSet.storeServices)].fulfillmentGuarantees.leadTime = HMStoISO(hours, minutes, seconds);
    setNewData(toSet);
  };

  const updateBopisEnabled = (value) => {
    setBopisEnabled(value.target.checked);
    const toSet = cloneDeep(newData);
    toSet.storeServices[findIndexOfBOPIS(toSet.storeServices)].enabled = value.target.checked;
    setNewData(toSet);
  };

  useEffect(() => {
    if (!(accessToken && props.selectedStore.id)) { return; }

    setFetching(true);
    setFetched(false);
    setOldData({});
    setNewData({});
    setLeadTimeData('');

    const isServiceEnabled = (services) => {
      const i = findIndexOfBOPIS(services);
      return !!services[i]?.enabled;
    };

    const getStoreInfo = () => getStoreById(accessToken, props.selectedStore.id)
      .then(({ body }) => {
        setOldData(body);
        const toSet = cloneDeep(body);
        const index = findIndexOfBOPIS(toSet.storeServices);
        if (!toSet.storeServices[index]) {
          toSet.storeServices[index] = bopis;
          setBopisExists(false);
          setBopisEnabled(true);
        } else {
          setBopisExists(true);
          setBopisEnabled(isServiceEnabled(body.storeServices));
        }
        setNewData(toSet);
        return setLeadTimeData(ISOtoHMS(body.storeServices?.[findIndexOfBOPIS(body.storeServices)]?.fulfillmentGuarantees.leadTime));
      })
      .catch((error) => setStoresFetchError(error.message))
      .finally(() => {
        setFetching(false);
        setFetched(true);
      });

    getStoreInfo();
  }, [accessToken, props.selectedStore]);

  const isSubmitDisabled = () => (
    // disable if we are missing the hours minutes or seconds that make up defaultLeadTimeDuration
    !leadTimeData.hours || !leadTimeData.minutes || !leadTimeData.seconds
  );

  const onSubmit = () => {
    setSaving(true);
    setStoresSaveError('');
    setStoresSaveSuccess('');

    const saveStore = () => putStore(accessToken, { new: newData, old: oldData })
      .then(() => setStoresSaveSuccess('Successfully updated Store.'))
      .catch((error) => {
        const err = parseSLSandBrickworksErrors(error, true);
        if (err.timeout) return setStoresSaveError('PUT timed out. Please refresh and try again.');
        if (err.brickworks) {
          // ignore brickworks errors and since stores was successful, tell the user the update was successful
          return setStoresSaveSuccess('Successfully updated Store.');
        }
        return setStoresSaveError(`PUT failed with error: ${err.message}`);
      })
      .finally(() => setSaving(false));

    saveStore();
  };

  return (
    <main>
      {fetching && <Loading />}
      {fetched && !bopisExists && (
        <section className="ncss-col-sm-6 va-sm-b">
          <aside className="ncss-col-sm-12 mt8-sm text-color-accent">This store has no BOPIS service associated with it. Please click the button below to add BOPIS to this store.</aside>
          <ButtonSubmit
            label="Add BOPIS"
            onClick={() => setBopisExists(true)}
          />
        </section>
      )}
      {fetched && storesFetchError && (
        <aside className="ncss-col-sm-12 mt8-sm text-color-error">{storesFetchError}</aside>
      )}
      {fetched && !storesFetchError && bopisExists && (
        <section className="ncss-col-sm-6 va-sm-b">
          <article className="ncss-col-sm-3 va-sm-t mt6-sm">
            <Checkbox
              id="bopisEnabled"
              isChecked={bopisEnabled}
              label="Enabled"
              onChange={(value) => updateBopisEnabled(value)}
            />
          </article>
          <article className="ncss-col-sm-9 va-sm-b">
            <LeadTimeInput
              label="Lead Time"
              labelClass="ta-sm-l"
              leadTimeData={leadTimeData}
              updateLeadTimeData={updateLeadTime}
            />
          </article>
          <ButtonSubmit
            isDisabled={isSubmitDisabled()}
            isLoading={saving}
            label="Save BOPIS Configuration"
            submitError={storesSaveError}
            onClick={onSubmit}
          />
          {storesSaveSuccess && (
            <aside className="ncss-col-sm-12 mt8-sm text-color-success">{storesSaveSuccess}</aside>
          )}
        </section>
      )}
    </main>
  );
};

BOPIS.propTypes = {
  selectedStore: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
};

export default BOPIS;
