{
    "lastA11yAudit": "07/08/22",
    "name": "Toggle Button Group",
    "description": "ToggleButtonGroup emphasizes a group of related toggle buttons.",
    "docs": "#/components/buttons/ToggleButtonGroup",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=585%3A0",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/ToggleButtonGroup",
    "tags": [
        "togglebuttongroup",
        "togglebutton",
        "segmented",
        "control",
        "cta",
        "call to action",
        "toolbar"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
