import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { getHierarchyNodes } from '../../../../utils/service-calls/hierarchy';
import {
  currencyCodeValues, isoCountryObjectArray, languageValues, localeValues, regionIds, regionValues, storeDistricts, storeTerritories,
} from '../../../../utils/static/sls-property-values';
import {
  ButtonBlack, CustomPanel, Select, SelectMultiple,
} from '../../../reusable';

import AdditionalLocalizations from './AdditionalLocalizations';

const territoryOptionsFromPropertiesFile = storeTerritories.map((territory) => ({ name: territory }));
const districtOptionsFromPropertiesFile = storeDistricts.map((district) => ({ name: district }));

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties, previousProperties = null) => (properties.adding
  || !!properties.formErrors.region
  // open the localization area if you change the country (which defaults the region)
  || (previousProperties && properties.formData.region !== previousProperties.formData.region)
  || !!properties.formErrors.locale
  || !!properties.formErrors.currencies);

Object.filterForObjects = (obj, func) => Object.keys(obj)
  .filter((key) => func(obj[key]))
  .reduce((result, key) => Object.assign(result, { [key]: obj[key] }), {});

const getRemainingLanguages = (localizations = []) => Object.filterForObjects(isoCountryObjectArray, (language) => !(localizations.map((localization) => localization.language)).includes(language[0]));

const loadTerritories = async (userToken, regionId) => (await getHierarchyNodes(userToken, { parentId: regionId })).body.objects;

const loadDistricts = async (userToken, territoryId) => ((await getHierarchyNodes(userToken, { parentId: territoryId })).body.objects);

const loadHierarchyForId = async (userToken, childId) => ((await getHierarchyNodes(userToken, { childId })).body.objects);

const getTerritoryId = (territoryName, territories) => (
  territories
    .find((territory) => territory.name === territoryName)
    .id
);

const getDistrictId = (districtName, districts) => (
  districts
    .find((district) => district.name === districtName)
    .id
);

class Localization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      districts: null,
      hasLoadedDistrictsFromHierarchyService: false,
      hasLoadedTerritoriesFromHierarchyService: false,
      isOpen: calculateIsOpen(props),
      loadingDistrictsFromHierarchyService: false,
      loadingTerritoriesFromHierarchyService: false,
      remainingLanguages: getRemainingLanguages(props?.formData?.localizations),
      territories: null,
    };
  }

  async componentDidMount() {
    if (!this.props.enforceHierarchy) {
      const [districts, territories]
          = [districtOptionsFromPropertiesFile, territoryOptionsFromPropertiesFile];
      this.setState(() => ({ districts, territories }));
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.enforceHierarchy && prevProps.formData.region !== this.props.formData.region) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState(() => ({ territories: [] }));
      this.setState(() => ({ districts: [] }));
      this.setState(() => ({ hasLoadedDistrictsFromHierarchyService: false }));
      this.setState(() => ({ hasLoadedTerritoriesFromHierarchyService: false }));
      this.props.updateFormData('district', '');
      this.props.updateFormData('districtId', '');
      this.props.updateFormData('territory', '');
    }
    if (!this.props.enforceHierarchy && prevProps.enforceHierarchy !== this.props.enforceHierarchy) {
      // enforce-hierarchy has been deactivated, so we revert to territory and district the options from property files
      const [districts, territories] = [districtOptionsFromPropertiesFile, territoryOptionsFromPropertiesFile];
      this.setState(() => ({ districts, territories }));
      // in case the previously selected districts or territories are not a part of the newly set options, clear
      // them from the form data so that the placeholder text ('Select...) appears in the respective dropdown
      const districtNames = districts.map(({ name }) => (name));
      const territoryNames = territories.map(({ name }) => (name));
      if (!districtNames.includes(this.props.formData.district)) {
        this.props.updateFormData('district', '');
      }
      if (!territoryNames.includes(this.props.formData.territory)) {
        this.props.updateFormData('territory', '');
      }
    } else if (this.props.enforceHierarchy && prevProps.enforceHierarchy !== this.props.enforceHierarchy) {
      // enforce-hierarchy has been activated, so we clear the districts and territories
      this.setState(() => ({ districts: null }));
      this.setState(() => ({ territories: null }));
      this.setState(() => ({ hasLoadedDistrictsFromHierarchyService: false }));
      this.setState(() => ({ hasLoadedTerritoriesFromHierarchyService: false }));
    }
    if (prevProps.formErrors !== this.props.formErrors || prevProps.formData.region !== this.props.formData.region) {
      if (this.state.isOpen) { return; }
      this.setState(() => ({ isOpen: calculateIsOpen(this.props, prevProps) }));
      /* eslint-enable react/no-did-update-set-state */
    }
  }

  async handleDistrictChange(newDistrict) {
    this.props.updateFormData('district', newDistrict);
    if (this.props.enforceHierarchy) {
      this.props.updateFormData('districtId', getDistrictId(newDistrict, this.state.districts));
    }
  }

  async handleTerritoryChange(newTerritory) {
    this.props.updateFormData('territory', newTerritory);
    if (this.props.enforceHierarchy) {
      const districts = await loadDistricts(this.props.userToken, getTerritoryId(newTerritory, this.state.territories));
      this.setState(() => ({ districts }));
      if (this.props.formData.district) {
        this.props.updateFormData('district', '');
        this.props.updateFormData('districtId', '');
      }
    }
  }

  /**
   * Function that is invoked when the district dropdown is opened.
   * If 'enforceHierarchy' is false, the function does nothing, as the districts should have already been loaded from the property files.
   * Otherwise:
   * If 'enforceHierarchy' is true, and we have not already loaded the districts, then we need to load them.
   * If the store has a districtId, we retrieve the hierarchy for the district from the hierarchy service, and use the id of the territory to load the districts.
   * If the store does not have a districtId, then we have to load the districts from the id of the store's territory. So we first check to see
   * if the territories have already been loaded, if they have, we find the matching territory and use its id. If they haven't been loaded,
   * we load them and do the same.
   * @returns {Promise<void>}
   */
  async handleDistrictSelectOpen() {
    if (!this.state.hasLoadedDistrictsFromHierarchyService && this.props.enforceHierarchy) {
      this.setState(() => ({ loadingDistrictsFromHierarchyService: true }));
      if (this.props.formData.districtId) {
        const hierarchyForDistrict = await loadHierarchyForId(this.props.userToken, this.props.formData.districtId);
        const territory = hierarchyForDistrict.find((node) => node.nodeType === 'TERRITORY');
        const district = hierarchyForDistrict.find((node) => node.nodeType === 'DISTRICT');
        this.props.updateFormData('territory', territory.name);
        this.props.updateFormData('district', district.name);
        const districts = await loadDistricts(this.props.userToken, territory.id);
        this.setState(() => ({
          districts,
          hasLoadedDistrictsFromHierarchyService: true,
          loadingDistrictsFromHierarchyService: false,
        }));
      } else {
        let districts;
        if (this.state.hasLoadedTerritoriesFromHierarchyService) {
          districts = await loadDistricts(this.props.userToken, getTerritoryId(this.props.formData.territory, this.state.territories));
        } else {
          const territories = await loadTerritories(this.props.userToken, regionIds[this.props.formData.brand][this.props.formData.region]);
          districts = await loadDistricts(this.props.userToken, getTerritoryId(this.props.formData.territory, territories));
          this.setState(() => ({ territories }));
        }
        this.setState(() => ({
          districts,
          hasLoadedDistrictsFromHierarchyService: true,
          loadingDistrictsFromHierarchyService: false,
        }));
        // if current district is in the loaded districts, we populate the districtId
        if (districts.map((district) => district.name).includes(this.props.formData.district)) {
          this.props.updateFormData('districtId', getDistrictId(this.props.formData.district, districts));
        }
      }
    }
  }

  /**
   * Function that is invoked when the territory dropdown is opened.
   * If 'enforceHierarchy' is false, the function does nothing, as the territories should have already been loaded from the property files.
   * Otherwise, we load the hierarchy based on the brand & region of the store.
   * @returns {Promise<void>}
   */
  async handleTerritorySelectOpen() {
    if (!this.state.hasLoadedTerritoriesFromHierarchyService && this.props.enforceHierarchy) {
      this.setState({ loadingTerritoriesFromHierarchyService: true });
      const territories = await loadTerritories(this.props.userToken, regionIds[this.props.formData.brand][this.props.formData.region]);
      this.setState(() => ({ hasLoadedTerritoriesFromHierarchyService: true, loadingTerritoriesFromHierarchyService: false, territories }));
    }
  }

  addLocalization = () => {
    const newLocalization = {
      address: {
        address1: '',
        address2: '',
        address3: '',
        area: '',
        city: '',
        country: '',
        county: '',
        postalCode: '',
        state: '',
        storeName: '',
      },
      announcements: [],
      contextualAddress: {
        address: '',
        neighborhood: '',
        parkingSuggestions: '',
        transit: '',
      },
      language: Object.values(this.state.remainingLanguages)[0][0],
    };
    const oldLocalization = this.props.formData.localizations || [];
    this.props.updateFormData('localizations', [...oldLocalization, newLocalization]);
    this.setState({ remainingLanguages: getRemainingLanguages(this.props?.formData?.localizations) });
  };

  getDistrictOptions = () => (this.state.districts
    ? this.state.districts.sort((a, b) => a.name.localeCompare(b.name))
          .map((district) => ({ label: district.name, value: district.name }))
    : [{ label: this.props.formData.district, value: this.props.formData.district },
    ]);
  /* eslint-enable react/sort-comp */

  getLanguages = (remaining, additional) => {
    const newLanguage = { additional: isoCountryObjectArray[additional] };
    return { ...remaining, ...newLanguage };
  }

  getTerritoryOptions = () => (this.state.territories
    ? this.state.territories.sort((a, b) => a.name.localeCompare(b.name))
          .map((territory) => ({ label: territory.name, value: territory.name }))
    : [{ label: this.props.formData.territory, value: this.props.formData.territory },
    ]);

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  removeLocalization = (indexToPop) => {
    const { localizations } = this.props.formData;
    localizations.splice(indexToPop, 1);
    this.props.updateFormData('localizations', localizations);
    this.setState({ remainingLanguages: getRemainingLanguages(this.props?.formData?.localizations) });
  };

  updateAddress = (indexToAlter, key, value) => {
    const localizations = cloneDeep(this.props.formData.localizations);
    localizations[indexToAlter].address[key] = value;
    this.props.updateFormData('localizations', localizations);
  };

  updateAddressFields = (indexToAlter, fieldsObj) => {
    const localizations = cloneDeep(this.props.formData.localizations);
    Object.keys(fieldsObj).forEach((field) => { localizations[indexToAlter].address[field] = fieldsObj[field]; });
    this.props.updateFormData('localizations', localizations);
  };

  updateContextualAddress = (indexToAlter, key, value) => {
    const localizations = cloneDeep(this.props.formData.localizations);
    if (!localizations[indexToAlter].contextualAddress) {
      localizations[indexToAlter].contextualAddress = {};
    }
    localizations[indexToAlter].contextualAddress[key] = value;
    this.props.updateFormData('localizations', localizations);
  };

  updateLocalization = (indexToAlter, key, value) => {
    const localizations = cloneDeep(this.props.formData.localizations.filter((localization) => localization.language));
    localizations[indexToAlter][key] = value;
    this.props.updateFormData('localizations', localizations);
    if (key === 'language') {
      this.setState({ remainingLanguages: getRemainingLanguages(this.props?.formData?.localizations) });
    }
  };

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Localization"
      onClick={this.panelToggle}
    >
      <Select
        className="ncss-col-sm-4 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.region}
        id="region"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Region"
        options={regionValues.map((val) => ({ label: val, value: val }))}
        value={this.props.formData.region || ''}
        zIndex={31}
        onChange={(value) => this.props.updateFormData('region', value)}
      />
      <Select
        className="ncss-col-sm-2 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.locale}
        id="locale"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Locale"
        options={localeValues.map((val) => ({ label: val, value: val }))}
        value={this.props.formData.locale || ''}
        zIndex={30}
        onChange={(value) => this.props.updateFormData('locale', value)}
      />
      <SelectMultiple
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.currencies}
        id="currencies"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Currencies"
        options={currencyCodeValues.map((value) => ({ label: value, value }))}
        values={this.props.formData.currencies && this.props.formData.currencies.length !== 0
          ? this.props.formData.currencies.map((currency) => ({ label: currency, value: currency }))
          : []}
        width={6}
        zIndex={29}
        onChange={(values) => this.props.updateFormData('currencies', values.map((value) => value.value))}
      />
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.territory}
        id="territory"
        isDisabled={this.props.userIsReadOnly || (this.props.enforceHierarchy && !this.props.formData.region && !this.state.territories.length > 0)}
        label="Territory"
        options={this.state.loadingTerritoriesFromHierarchyService ? [{ disabled: true, label: 'Loading...' }] : this.getTerritoryOptions()}
        value={this.props.formData.territory || ''}
        zIndex={28}
        onChange={(value) => this.handleTerritoryChange(value)}
        onMenuOpen={() => this.handleTerritorySelectOpen()}
      />
      <Select
        className="ncss-col-sm-6 va-sm-t"
        id="defaultLanguage"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Default Language"
        options={languageValues.map((val) => ({ label: val, value: val }))}
        value={this.props.formData.defaultLanguage || ''}
        zIndex={27}
        onChange={(value) => this.props.updateFormData('defaultLanguage', value)}
      />
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.district}
        id="district"
        isDisabled={this.props.userIsReadOnly || (this.props.enforceHierarchy && !this.props.formData.territory)}
        label="District"
        options={this.state.loadingDistrictsFromHierarchyService ? [{ disabled: true, label: 'Loading...' }] : this.getDistrictOptions()}
        value={this.props.formData.district || ''}
        zIndex={26}
        onChange={(value) => this.handleDistrictChange(value)}
        onMenuOpen={() => this.handleDistrictSelectOpen()}
      />
      <SelectMultiple
        id="supportedLanguages"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Supported Languages"
        options={languageValues.map((value) => ({ label: value, value }))}
        values={this.props.formData.supportedLanguages && this.props.formData.supportedLanguages.length !== 0
          ? this.props.formData.supportedLanguages.map((language) => ({ label: language, value: language }))
          : []}
        zIndex={25}
        onChange={(values) => this.props.updateFormData('supportedLanguages', values.map((value) => value.value))}
      />
      <article className="ncss-col-sm-12 mt4-sm">
        <header className="headline-4">Additional Localizations</header>
        <aside className="mt2-sm" style={{ borderTop: '4px solid' }} />
        {this.props.formData?.localizations?.filter((localization) => localization.language)?.map((localization, localizationIndex) => (
          <AdditionalLocalizations
            key={localization.language}
            index={localizationIndex}
            localization={localization}
            localizationErrors={this.props.formErrors.localizations[localizationIndex]}
            remainingLanguages={this.getLanguages(this.state.remainingLanguages, localization.language)}
            removeLocalization={this.removeLocalization}
            timezone={this.props.formData?.timezone || ''}
            updateAddress={this.updateAddress}
            updateAddressFields={this.updateAddressFields}
            updateContextualAddress={this.updateContextualAddress}
            updateLocalization={this.updateLocalization}
            userIsReadOnly={this.props.userIsReadOnly}
          />
        ))}
        <ButtonBlack
          className="mt2-sm"
          isDisabled={this.props.userIsReadOnly}
          label="Add Additional Localization"
          onClick={this.addLocalization}
        />
      </article>
    </CustomPanel>
  );
}

Localization.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  enforceHierarchy: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default Localization;
