import { requestWrapper } from './reusable';

const config = require('../../config')();

export const postPriceFeed = (token, body) => requestWrapper(
  token,
  config.generatePriceDCNResource,
  'post',
  undefined,
  body,
);

export const getPriceFeedStatus = (token, jobId) => requestWrapper(
  token,
  config.generatePriceDCNResource,
  'get',
  { jobId },
);
