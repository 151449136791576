"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFiles = void 0;
const types_1 = require("../types");
const validateFile = (file, validator) => __awaiter(void 0, void 0, void 0, function* () {
    const validationPromises = Array.isArray(validator)
        ? validator.map((v) => v(file))
        : [validator(file)];
    const validations = yield Promise.all(validationPromises);
    return validations.filter((v) => v);
});
const validateFiles = (files, validator) => __awaiter(void 0, void 0, void 0, function* () {
    const validatorResults = yield Promise.all(files.map((f) => validateFile(f, validator)));
    const validatedFiles = files.reduce((acc, file, fileIndex) => {
        var _a;
        if ((_a = validatorResults[fileIndex]) === null || _a === void 0 ? void 0 : _a.length) {
            return Object.assign(Object.assign({}, acc), { failed: [
                    ...(acc.failed || []),
                    {
                        file: file === null || file === void 0 ? void 0 : file.name,
                        error: validatorResults[fileIndex],
                        type: types_1.UploadErrorType.validation,
                    },
                ] });
        }
        return Object.assign(Object.assign({}, acc), { succeeded: [...acc.succeeded, file] });
    }, { succeeded: [], failed: [] });
    return validatedFiles;
});
exports.validateFiles = validateFiles;
