import { Loading } from '@nike/frame-component-library';
import {
  Default, Either, Maybe, chain, isSome, match,
} from '@nike/rcf-fp';
import { format } from 'date-fns';
import { detailedDiff } from 'deep-object-diff';
import flatten from 'flat';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactTable from 'react-table';

import { getStoreVersions } from '../../../../utils/service-calls/sls';
import { ButtonBlack, CustomPanel } from '../../../reusable';

const DEFAULT_VERSIONS = [];
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_ERROR = '';
const DEFAULT_IS_LOADING = false;
const DEFAULT_PAGE_SIZE = 5;

const getShortKey = (key) => match(key)(
  [(s) => s.startsWith('operationalDetails.hoursOfOperation.'), (s) => s.replace('operationalDetails.hoursOfOperation.', '')],
  [Default, key],
);

const formatDate = (sDate) => Either.ofTry(() => Date.parse(sDate))
  .toMaybe()
  .filter((dt) => !Number.isNaN(dt))
  .map((date) => format(date, 'yyyy-MM-dd HH:mm:ss O'))
  .orElse(sDate);

const removeDiffFields = (store) => {
  const {
    storeServices,
    modificationDate,
    creationDate,
    createdBy,
    lastUpdatedBy,
    resourceType,
    links,
    ...rest
  } = store;
  return rest;
};

const collapseSpecialHoursArray = ({ hours, ...restOfSpecialHour }) => {
  const [firstHour] = hours;
  const hoursMap = Maybe.of(firstHour).orElse({});
  return { ...restOfSpecialHour, hours: hoursMap };
};

const convertOperationHoursToMap = ({ operationalDetails: { hoursOfOperation: { regularHours = {}, specialHours = [] } }, ...restOfStore }) => {
  const specialHoursMap = isSome(specialHours)
    ? specialHours.reduce((acc, hour = {}, idx) => {
      const key = Maybe.of(hour.date).orElseGet(() => String(idx));
      return { ...acc, [key]: collapseSpecialHoursArray(hour) };
    }, {})
    : '-';
  const regularHoursMap = Object.keys(regularHours).reduce((acc, key) => {
    const [firstHour] = regularHours[key];
    const hoursMap = Maybe.of(firstHour).orElse({});
    acc[key] = hoursMap;
    return acc;
  }, {});
  return { ...restOfStore, regularHours: regularHoursMap, specialHours: specialHoursMap };
};

const convertOfferingsToMap = ({ offerings = [], ...restOfStore }) => {
  const offeringsMap = offerings.reduce((acc, { id, ...restOfOffering }) => ({
    ...acc,
    [id]: restOfOffering,
  }), {});
  return { ...restOfStore, offerings: offeringsMap };
};

const stripObjects = (store) => {
  const stringEntries = Object.entries(store)
    .filter(([, val]) => typeof val !== 'object');
  return Object.fromEntries(stringEntries);
};

const normalizeString = (str) => Maybe.of(str)
  .map((s) => s.trim())
  .filter((s) => s !== '')
  .orElse('-');

const makeValuesStrings = (store) => {
  const entries = Object.entries(store)
    .map(([key, val]) => [key, normalizeString(String(val))]);
  return Object.fromEntries(entries);
};

const sanitizeStore = chain(
  removeDiffFields,
  convertOfferingsToMap,
  convertOperationHoursToMap,
  flatten,
  stripObjects,
  makeValuesStrings,
);

const getDiffInfo = (originalArg = {}, updatedArg = {}) => {
  const [originalPrepped, updatedPrepped] = [originalArg, updatedArg]
    .map((arg) => Maybe.of(arg).orElse({}))
    .map(sanitizeStore);
  const lastUpdatedBy = Maybe.of(updatedArg.lastUpdatedBy)
    .orElse('Name Unavailable');
  const dateModified = Maybe.of(updatedArg.modificationDate)
    .map(formatDate)
    .orElse('Date Unavailable');
  const { added, deleted, updated } = detailedDiff(originalPrepped, updatedPrepped);
  const allKeys = [added, deleted, updated]
    .map(Object.keys)
    .flat();
  const uniqeKeys = [...new Set(allKeys)].sort();
  return uniqeKeys.map((field) => {
    const [changedFrom, changedTo] = [originalPrepped, updatedPrepped]
      .map((o) => Maybe.of(o[field]).orElse('-'));
    return {
      changedFrom,
      changedTo,
      dateModified,
      field: getShortKey(field),
      lastUpdatedBy,
    };
  });
};

const getDiffRows = (vers) => ((vers.length < 2)
  ? []
  : vers.slice(0, -1)
    .map((ver, idx) => getDiffInfo(vers[idx + 1], ver))
    .flat());
const StoreHistory = ({
  storeId,
}) => {
  const userToken = useSelector((state) => state.authorizationReducer.auth.accessToken);

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [versions, setVersions] = useState(DEFAULT_VERSIONS);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [fetchError, setFetchError] = useState(DEFAULT_ERROR);
  const [isLoading, setIsLoading] = useState(DEFAULT_IS_LOADING);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);

  const diffRows = getDiffRows(versions);

  const toggleOpen = () => {
    const newIsPanelOpen = !isPanelOpen;
    if (!newIsPanelOpen) {
      setVersions(DEFAULT_VERSIONS);
      setPageNumber(DEFAULT_PAGE_NUMBER);
      setFetchError(DEFAULT_ERROR);
      setIsLoading(DEFAULT_IS_LOADING);
    }
    setIsPanelOpen(newIsPanelOpen);
  };

  const fetchVersions = useCallback(() => ((!isPanelOpen)
    ? []
    : (() => {
      setFetchError(DEFAULT_ERROR);
      setIsLoading(true);
      const anchor = pageNumber * pageSize;
      const effectivePageSize = pageSize + 1; // Add an extra version for diffing previous
      return getStoreVersions(userToken, storeId, anchor, effectivePageSize)
        .then(({ body }) => setVersions(body.objects))
        .catch((err) => setFetchError(err.message))
        .finally(() => setIsLoading(false));
    })()),
  [storeId, userToken, pageNumber, isPanelOpen, pageSize]);

  useEffect(() => {
    fetchVersions();
  }, [fetchVersions, storeId, userToken, pageNumber, isPanelOpen]);

  const columns = [
    ['dateModified', 'Date', '15px'],
    ['lastUpdatedBy', 'Updated By', '15px'],
    ['field', 'Changed', '20px'],
    ['changedFrom', 'From', '20px'],
    ['changedTo', 'To', '20px'],
  ].map(([accessor, Header, fontSize]) => (
    {
      accessor,
      Header,
      headerStyle: {
        background: 'black',
        color: 'white',
        fontSize,
      },
    }));

  return (
    <CustomPanel
      isOpen={isPanelOpen}
      label="History"
      onClick={toggleOpen}
    >
      {isLoading && (
      <article className="ncss-col-sm-12 va-sm-t">
        <Loading />
      </article>
      )}
      {fetchError !== '' && (
      <>
        <article className="ncss-col-sm-12 va-sm-t">
          <aside className="ncss-col-sm-12 mt8-sm text-color-error">
            {fetchError}
          </aside>
        </article>
        <article className="ncss-col-sm-12 va-sm-t">
          <ButtonBlack
            isDisabled={pageNumber > 0 && versions.length < (pageSize + 1)}
            label="Try Again"
            onClick={fetchVersions}
          />
        </article>
      </>

      )}
      {versions.length > 0 && !isLoading && (
      <article className="ncss-col-sm-12 va-sm-t">
        <div><strong>Page {pageNumber + 1}</strong> ({formatDate(versions[versions.length - 1].modificationDate)} - {formatDate(versions[0].modificationDate)})</div>
      </article>
      )}
      {versions.length > 0 && diffRows.length === 0 && !isLoading && (
        <article className="ncss-col-sm-12 va-sm-t">
          <aside className="ncss-col-sm-12 mt8-sm text-color-error">
            No applicable changes detected for this timeframe.
          </aside>
        </article>
      )}
      {diffRows.length > 0 && !isLoading && (
        <article className="ncss-col-sm-12 va-sm-t">
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={diffRows}
            getTdProps={() => ({
              style: {
                display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'auto', whiteSpace: 'pre',
              },
            })}
            pageSize={diffRows.length}
            showPagination={false}
          />
        </article>
      )}
      {versions.length > 0 && !isLoading && (
        <article className="ncss-col-sm-12 va-sm-t">
          <ButtonBlack
            isDisabled={pageNumber === 0}
            label="Previous"
            onClick={() => setPageNumber(pageNumber - 1)}
          />
          <ButtonBlack
            isDisabled={pageNumber > 0 && versions.length < (pageSize + 1)}
            label="Next"
            onClick={() => setPageNumber(pageNumber + 1)}
          />
        </article>
      )}
    </CustomPanel>
  );
};

StoreHistory.propTypes = {
  storeId: PropTypes.string.isRequired,
};

export default StoreHistory;
