import { Input, Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { formatStoreAlias } from '../../utils/formatting';
import { loadState } from '../../utils/local-storage';
import {
  scsGet, scsDefinitionPost, scsDefinitionGet, scsPost,
} from '../../utils/service-calls/scs';
import { isUserAllowed } from '../../utils/tab-permissions';
import Modal from '../ccm/Modal';
import {
 StoreSelect, Select, ButtonBlack,
} from '../reusable';

import Card from './Card';
import './config-by-key/Overlay.css';
import CreateConfigOverlay from './CreateConfigOverlay';

const config = require('../../config')();

const otherSettingsGroup = 'Other Settings';

// if we don't get the correct security group we don't show that card
const securityGroupMap = new Map([
  ['POS Settings', 'SCS.POS'],
  ['AIC Settings', 'SCS.ASSIST'],
  ['Assist Settings', 'SCS.ASSIST'],
  ['Nike+ Link Settings', 'SCS.LINK'],
  ['NSP Settings', 'SCS.ASSIST'],
  ['RISE Settings', 'SCS.RISE'],
  ['SCORE Settings', 'SCS.SFS'],
  ['SIM Settings', 'SCS.SIM'],
  ['Self Checkout Settings', 'SCS.SCO'],
  ['NPOS Settings', 'SCS.NPOS'],
  ['Retail Launchpad', 'SCS.RL'],
  ['Promote App Settings', 'SCS.PROMOTE'],
  ['Other Settings', 'SCS.OTHER'],
]);

const scsKeyGroups = ['SCS.ALL', 'SCS.ASSIST', 'SCS.LINK', 'SCS.NPOS', 'SCS.OTHER', 'SCS.POS', 'SCS.PROMOTE', 'SCS.RL', 'SCS.RISE', 'SCS.SFS', 'SCS.SIM', 'SCS.SCO'];

const storeviewsFields = ['address.country', 'brand', 'id', 'storeNumber', 'region'];

const getKey = ({ key }) => key;

const getIncludedKeysFrom = ({ keys }) => ({ key: settingKey }) => keys.map(getKey).includes(settingKey);

const getSearchedKeys = (search, keyConfigs) => keyConfigs.filter((keyConfig) => {
  if (search !== '') {
    const regExp = new RegExp(search.trim(), 'i');
    return regExp.test(String(keyConfig.key));
  } else {
    return true;
  }
});

export const getCurrentConfigForCard = (storeConfig, card) => storeConfig.filter(getIncludedKeysFrom(card));

class SCSMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: [],
      filteredGroup: [],
      getting: false,
      isMessageModalOpen: false,
      isOverlayVisible: false,
      isSaving: false,
      keyCreationDefinitions: [],
      keyDefinitions: [],
      keySaveMessage: '',
      messageError: '',
      saveSuccess: false,
      scsGroups: [],
      search: '',
      selectedCountry: loadState('auth')?.country,
      selectedRegion: null,
      selectedStore: null,
      storeKey: '',
      updateKey: '',
    };

    this.overlayRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.scsGroups.length === 0) {
      this.setState({
        isReadOnly: isUserAllowed(this.props.userInfo.groups, 'ReadOnly'),
        scsGroups: scsKeyGroups.filter((group) => isUserAllowed(this.props.userInfo.groups, group)),
      });
    }
  }

  closeMessageModal = () => {
    this.setState({ isMessageModalOpen: false });
  };

  closeOverlay = () => {
    this.setState({ isOverlayVisible: false });
    this.setState({ filteredGroup: [] });
  };

  getSCS = () => this.setState({ getting: true },
    () => scsGet(this.props.userInfo.accessToken, this.state.storeKey, this.state.selectedRegion)
      .then((res) => this.setState({ config: res.body.objects }, async () => {
        const keyDefinitions = await this.getSCSKeyDefinitions();
        this.setState(({ getting: false, keyDefinitions }));
      }))
      .catch((err) => this.setState({ config: [], getting: false, messageError: err.message })));

  getSCSKeyDefinitions = async () => {
    const data = await scsDefinitionGet(this.props.userInfo.accessToken, this.state.selectedRegion);
    const { objects: definitions } = data?.body;
    const groupedItems = definitions.reduce((accumulator, item) => {
      const { group } = item;
      const relevantGroup = !group ? otherSettingsGroup : group;
      if (!accumulator[relevantGroup]) {
        accumulator[relevantGroup] = [];
      }

      // creating key of the format { description: < key description>, key: <key name>, valueType: <key value type> }
      const keyConfig = {
        description: !item.description ? '' : item.description,
        key: item.key,
        valueType: String(item.type).toLowerCase(),
      };
      accumulator[relevantGroup].push(keyConfig);
      return accumulator;
    }, {});

    // creating scs card info based on scs key groups of the format { header: 'group name', keys: <group keys>, securityGroup: 'security group to manage group keys' }
    const scsCardsInfo = Object.entries(groupedItems).sort().map(([key, value]) => ({
      header: key,
      keys: value,
      securityGroup: securityGroupMap.get(key),
    }));
    return scsCardsInfo;
  }

  // Function called from CreateConfigOverlay to get the key definitions and security group for the selected region
  handleOverlayAction = async (selectedRegion) => {
    this.setState({ selectedRegion }, async () => {
        try {
          const keyDefinitions = await this.getSCSKeyDefinitions();
          this.setState({ keyCreationDefinitions: keyDefinitions }, () => {
            const headerSecurityGroupMap = this.state.keyCreationDefinitions.reduce((acc, definition) => {
              acc[definition.header] = definition.securityGroup;
              return acc;
            }, {});
            const filteredGroup = Object.entries(headerSecurityGroupMap)
              .filter(([, securityGroup]) => this.state.scsGroups.includes('SCS.ALL') || this.state.scsGroups.includes(securityGroup))
              .reduce((acc, [header, securityGroup]) => {
                acc[header] = securityGroup;
                return acc;
              }, {});

            const keys = Object.keys(filteredGroup);
            this.setState({ filteredGroup: keys });
          });
        } catch (error) {
          console.error('Error fetching key definitions:', error);
        }
    });
  };

  handleSaveKeyDefinition = async (decription, group, key, type, value, isCreateGlobalConfig, isSaveAndClose) => {
    try {
      this.setState({ isSaving: true });
      await scsDefinitionPost(this.props.userInfo.accessToken, decription, group, key, type, this.state.selectedRegion);
      // If the key is created successfully, and the isCreateGlobalConfig is true, then create the global scoped property
      if (isCreateGlobalConfig) {
        await scsPost(this.props.userInfo.accessToken, {
            key, scope: 'GLOBAL', scopeType: 'GLOBAL', value
            }, this.state.selectedRegion);
      }
      this.clearChildFields();
      this.setState({
       filteredGroup: [], isMessageModalOpen: true, isSaving: false, keySaveMessage: 'Key successfully created'
});
      if (isSaveAndClose) {
        console.log('Closing overlay');
        this.setState({ isOverlayVisible: false });
      }
    } catch (error) {
      this.setState({ isMessageModalOpen: true });
      if (error.message.includes('409')) {
        this.setState({ isSaving: false, keySaveMessage: `Conflict Error. Key already exists !` });
      } else {
        this.setState({ isSaving: false, keySaveMessage: `Error creating key. ${error.message}` });
      }
    }
  };

  onSave = (data) => this.setState({ getting: true, messageError: '', saveSuccess: false },
    () => scsPost(this.props.userInfo.accessToken, data.body, this.state.selectedRegion)
      .then(() => this.setState({ saveSuccess: true, updateKey: data.body.key },
        () => this.getSCS()))
      // the scope doesn't exist, so you have to post the definition of it and try again
      .catch(() => scsDefinitionPost(this.props.userInfo.accessToken, data.description, data.group, data.body.key, data.keyType, this.state.selectedRegion)
        .then(() => scsPost(this.props.userInfo.accessToken, data.body, this.state.selectedRegion)
          .then(() => this.setState({ saveSuccess: true, updateKey: data.body.key },
            () => this.getSCS()))
          .catch((err) => this.setState({ getting: false, messageError: `${data.body.key}: ${err.message}` })))
        .catch((err) => this.setState({ getting: false, messageError: `${data.body.key}: ${err.message}` }))));

  saveConfig = async () => {
    this.setState({ isOverlayVisible: true });
  };

  selectCountry = (selectedCountry) => this.setState({
    config: [], messageError: '', search: '', selectedCountry, selectedStore: null,
  });

  selectRegion = (selectedRegion) => this.setState({ config: [], search: '', selectedRegion },
    () => this.getSCS());

  selectStore = (selectedStore) => this.setState({
    config: [], messageError: '', search: '', selectedRegion: null, selectedStore, storeKey: formatStoreAlias(selectedStore),
  });

  updateSearch = (value) => { this.setState({ search: value }); };

  clearChildFields() {
      this.overlayRef.current.clearFields(); // Call the clearFields method
  }

  render = () => (
    <main className="ncss-col-sm-10">
      <div className="create-key">
        <ButtonBlack
          className="create-key-button"
          isDisabled={false}
          label="Create Key"
          onClick={this.saveConfig}
        />
      </div>

      {this.state.isReadOnly && <h1 className="headline-1 mb4-sm text-color-accent">Read Only</h1>}

      {this.state.scsGroups.length !== 0
        ? (
          <StoreSelect
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            storeviewsFields={storeviewsFields}
          />

        )

        : !this.state.getting && <p>You do not have permission to edit any SCS keys.</p>}

      {this.state.saveSuccess
        ? <aside className="m4-sm text-color-success body-2">{this.state.updateKey}: successfully saved. SCS data is cached; changes will be reflected shortly.</aside>
        : <aside className="m4-sm text-color-error body-2">{this.state.messageError}</aside>}
      {this.state.selectedStore && this.state.selectedStore.id && (
        <header className="ncss-col-sm-6 ta-sm-l">
          <p className="mt4-sm mb2-sm">{`Country: ${this.state.selectedStore.address.country}`}</p>
          <p className="mt4-sm mb2-sm">{`Store Id: ${this.state.selectedStore.id}`}</p>

          <Select
            className="mt4-sm mb4-sm"
            id="scsRegion"
            label="AWS Region"
            options={config.scsRegionOptions}
            value={this.state.selectedRegion}
            onChange={this.selectRegion}
          />
          {this.state.selectedStore && this.state.selectedStore.id && this.state.selectedRegion && !this.state.getting && (
            <section className="ncss-col-sm-12 ta-sm-c p1-sm mt4-sm">
              <Input
                label="Search"
                placeholder="Search for Store Config Key..."
                style={{ zIndex: 1 }}
                type="text"
                value={this.state.search}
                onChange={({ target: { value } }) => this.updateSearch(value)}
              />
            </section>
          )}
        </header>
      )}

      <section className="ncss-row mt8-sm">
        {this.state.getting
          ? <Loading />
          : this.state.config.length !== 0 && (
            this.state.keyDefinitions.filter((card) => (this.state.scsGroups.includes('SCS.ALL') || this.state.scsGroups.includes(card.securityGroup)))
              .filter((card) => getSearchedKeys(this.state.search, card.keys).length > 0)
              .map((card) => (
                <Card
                  key={card.header}
                  currentConfig={getCurrentConfigForCard(this.state.config, card)}
                  header={card.header}
                  isReadOnly={this.state.isReadOnly}
                  keys={getSearchedKeys(this.state.search, card.keys)}
                  store={this.state.selectedStore}
                  onSave={this.onSave}
                />
              ))
          )}
      </section>
      <div>
        <CreateConfigOverlay
          ref={this.overlayRef}
          filteredGroup={this.state.filteredGroup}
          isSaving={this.state.isSaving}
          isVisible={this.state.isOverlayVisible}
          onAction={this.handleOverlayAction}
          onClose={this.closeOverlay}
          onSave={this.handleSaveKeyDefinition}
        />
      </div>
      <div>
        <Modal
          buttonName="OK"
          isOpen={this.state.isMessageModalOpen}
          title=""
          onClose={() => this.closeMessageModal(false)}
        >
          <p>{this.state.keySaveMessage}</p>
        </Modal>
      </div>
    </main>
  )
}

SCSMain.propTypes = {
  userInfo: PropTypes.shape({
    accessToken: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInfo: state.authorizationReducer.auth,
});

export default connect(mapStateToProps, null)(SCSMain);
