{
    "lastA11yAudit": "07/08/22",
    "name": "Card",
    "description": "Cards define how content is accessed across the platform, either directly on the page, or revealed through interactions.",
    "docs": "#/components/Card",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=608%3A559",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Card",
    "tags": ["Card", "Tile", "Pane", "Layout", "teaser"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
