import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import localizedLanguages from '../../../utils/static/localizedLanguages';
import { REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import { Select } from '../../reusable';

const LocalizationFields = (props) => (
  <section className="ncss-col-sm-6">
    <Select
      errorMessage={!props.localization.language ? REQUIRED_FIELD : ''}
      id="languageSelect"
      isDisabled={props.editing}
      label="Select Language"
      options={props.editing
        ? props.languageOptions.map((language) => ({ label: localizedLanguages[language], value: language }))
        : props.languageOptions.filter((language) => !(props.usedLanguages.includes(language)))
          .map((language) => ({ label: localizedLanguages[language], value: language }))}
      value={props.localization.language}
      onChange={(selectedOption) => props.setField('currentLanguage', 'language', selectedOption)}
    />
    <Input
      label="Zone Name"
      maxLength="100"
      value={props.localization.zoneName || ''}
      onChange={({ target: { value } }) => props.setField('currentName', 'zoneName', value)}
    />
    <Input
      label="Zone Description"
      maxLength="100"
      value={props.localization.zoneDescription || ''}
      onChange={({ target: { value } }) => props.setField('currentDescription', 'zoneDescription', value)}
    />
  </section>
);

LocalizationFields.defaultProps = {
  editing: false,
};

LocalizationFields.propTypes = {
  editing: PropTypes.bool,
  languageOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  localization: PropTypes.shape({
    language: PropTypes.string,
    zoneDescription: PropTypes.string,
    zoneName: PropTypes.string,
  }).isRequired,
  setField: PropTypes.func.isRequired,
  usedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LocalizationFields;
