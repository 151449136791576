{
    "lastA11yAudit": "07/08/22",
    "name": "Table Cell",
    "docs": "#/components/tables/TableCell",
    "figma": "",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/TableCell",
    "tags": ["tablecell", "grid", "data"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
