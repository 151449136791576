import React from 'react';

const RouteInvalid = () => (
  <main className="ncss-col-sm-12 ta-sm-c">
    <h1 className="display-3 mt10-sm mb4-sm text-color-error">This Route is Invalid</h1>
    <p className="headline-4">The Retail Operations tab you are trying to reach does not exist.</p>
    <p className="headline-4">Please navigate through the app via the sidebar to the left.</p>
  </main>
);

export default RouteInvalid;
