import {
  Consumer, Fn, Operation, Predicate, Supplier,
} from '../index.js';

const isNull = (v: any) => v === undefined || v === null;

const noOp = () => { };

const noConsumer = <T>(_arg: T) => { };

class Maybe<T> {
  private value?: T;

  constructor(value?: T) {
    this.value = value;
  }

  isNone() {
    return isNull(this.value);
  }

  isSome() {
    return !this.isNone();
  }

  get() {
    if (this.isSome()) {
      return this.value;
    }

    throw new Error('Expected Some type');
  }

  orElse<U>(anotherValue: U): T | U {
    return this.isSome()
      ? this.value as T
      : anotherValue;
  }

  orElseGet<U>(anotherValueSupplier: Supplier<U>): T | U {
    return this.isSome()
      ? this.value as T
      : anotherValueSupplier();
  }

  map<U>(f: Fn<T, U>): Maybe<T> | Maybe<U> {
    return this.isSome()
      ? Maybe.of(f(this.value as T))
      : this;
  }

  flatMap<U>(f: Fn<T, Maybe<U>>): Maybe<T> | Maybe<U> {
    return this.isSome()
      ? f(this.value as T) as Maybe<U>
      : this as Maybe<T>;
  }

  filter(test: Predicate<T>): Maybe<T> {
    return this.isSome() && test(this.value as T)
      ? this as Maybe<T>
      : Maybe.none() as Maybe<T>;
  }

  peek(someConsumer: Consumer<T>, noneConsumer: Operation = noOp): Maybe<T> {
    if (this.isSome()) {
      someConsumer(this.value as T);
    } else {
      noneConsumer();
    }
    return this;
  }

  orElseThrow(errorSupplier: Supplier<any>): T | never {
    if (this.isSome()) {
      return this.value as T;
    }

    throw errorSupplier();
  }

  static of<T>(value?: T): Maybe<T> {
    return new Maybe(value);
  }

  static maybeOf<T>(value?: T): Maybe<T> {
    return Maybe.of(value);
  }

  static none<T>() {
    return Maybe.of() as Maybe<T>;
  }

  static maybeNone<T>() {
    return Maybe.none() as Maybe<T>;
  }

  static ofTry<T>(expr: Supplier<T | never>, errorConsumer: Consumer<any> = noConsumer): Maybe<T> {
    try {
      return Maybe.of(expr());
    } catch (err) {
      errorConsumer(err);
      return Maybe.none() as Maybe<T>;
    }
  }
}

export { Maybe };
