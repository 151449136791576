import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';

import { MSG_AUTH_EXPIRE } from '../../../../constants/constants';
import { cmpSlsSync } from '../../../../utils/service-calls/sls';
import { ButtonBlack, CustomPanel } from '../../../reusable';

const getCmpSyncErrorMessage = (err) => {
  let msg = '';
  if (err?.message === MSG_AUTH_EXPIRE) msg = MSG_AUTH_EXPIRE;
  else if (err?.statusCode?.toString() === '401') msg = "You don't have the permisions to perform the sync.";
  else msg = 'Please retry after sometime.';
  return `CMP sync failed. ${msg}`;
};

const Cmp = ({
  cmp, userToken,
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const toggleOpen = () => setPanelOpen(!isPanelOpen);
  const [cmpSlslSyncStatus, setCmpSlslSyncStatus] = useState({ error: false, isLoading: false, message: '' });

  const onClickCmpSlsSync = () => {
    setCmpSlslSyncStatus({ error: false, isLoading: true, message: 'CMP Sync is in progress...' });
    cmpSlsSync(userToken, { storeIds: [cmp.id] })
      .then(() => {
        setCmpSlslSyncStatus({ error: false, isLoading: false, message: 'CMP sync was successful.' });
        return null;
      })
      .catch((ex) => {
        setCmpSlslSyncStatus({ error: true, isLoading: false, message: getCmpSyncErrorMessage(ex) });
      });
  };

  return (
    <CustomPanel
      isOpen={isPanelOpen}
      label="CMP"
      onClick={toggleOpen}
    >
      <article className="ncss-col-sm-12 va-sm-t">
        <article className="ncss-col-sm-8 va-sm-t">
          {cmpSlslSyncStatus.isLoading
            ? (
              <div className="ncss-row">
                <div className="ncss-col-sm-12">
                  <div className="d-sm-flx">
                    <div className="ncss-col-sm-1">
                      <Loading isNotCentered />
                    </div>
                    <div className="ncss-col-sm-11 pr-sm-2">
                      {cmpSlslSyncStatus.message}
                    </div>
                  </div>
                </div>
              </div>
            )
            : <p className={`${cmpSlslSyncStatus.error ? 'text-color-error' : 'text-color-success'}`}>{cmpSlslSyncStatus.message}</p>}
        </article>
        <article className="ncss-col-sm-4 va-sm-t ta-sm-r">
          <ButtonBlack className="" label="CMP Sync" onClick={onClickCmpSlsSync} />
        </article>
      </article>
      <article className="ncss-col-sm-12 va-sm-t">
        <ReactJson collapsed name="CMP Attributes" src={cmp} />
      </article>
    </CustomPanel>
  );
};

Cmp.propTypes = {
  cmp: PropTypes.shape().isRequired,
  userToken: PropTypes.string.isRequired,
};

export default Cmp;
