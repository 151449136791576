import {
  Default, match, not,
} from '@nike/rcf-fp';

import { getFormattedDate } from '../formatting';
import { facilityTypeValues } from '../static/sls-property-values';

import {
  phoneNumberValidator,
  dateISOValidator,
  localeValidator,
  currencyCodeValidator,
  businessConceptValidator,
  timeFormatValidator,
  languageCodeValidator,
  announcementMessageTypeValidator,
  localizationAnnouncementsValidator,
  partnerNameValidator,
  maxLengthValidator,
  facilityTypeValidator,
} from './sls-validation';

const ABSENT_ATTRIBUTE = 'ABSENT_ATTRIBUTE';
const TEXT_FIELD_MAX_LENGTH = 40;

const getAttributeOrMarkAbsent = (data) => (attribute, extractor = (value) => value) => (data[attribute] ? extractor(data[attribute]) : ABSENT_ATTRIBUTE);
const extractAttributes = (dataFilledGetAttr) => (attributesAndExtractors) => attributesAndExtractors.reduce((acc, [attribute, extractor]) => ({ ...acc, [attribute]: dataFilledGetAttr(attribute, extractor) }), {});
// this should be an array of arrays containing functions to test the data and error messages to print if the test fails
// [[ testFunction, errorMessage], [testFunction, errorMessage], ...]
// testFunctions: (localCopy) => return true if the errorMessage should be added;
const runTests = (tests, localCopy) => tests.map(([test, message]) => (test(localCopy) ? { message } : null)).filter((error) => error);

export const storeAddressValidator = (addressInfo, index, processedStoreList) => {
  const storeAddressExtractAttributes = extractAttributes(getAttributeOrMarkAbsent(addressInfo));
  const attributesAndExtractorPairs = [
    ['storeId'],
    ['address1'],
    ['address2'],
    ['address3'],
    ['postalCode'],
    ['city'],
    ['state'],
  ];
  const localCopy = {
    ...addressInfo,
    ...storeAddressExtractAttributes(attributesAndExtractorPairs),
  };
  const tests = [
    // Duplicate check
    [({ storeId, updateType }) => processedStoreList.some((store) => store.storeId === storeId && store.updateType === updateType), `Row ${index} has a duplicate STORE_ADDRESS for id - ${localCopy.storeId}`],
    // Existence checks
    [({ storeId }) => storeId === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeId`],
    [({ address1 }) => address1 === ABSENT_ATTRIBUTE, `Row ${index} needs to have an address1`],
    [({ postalCode }) => postalCode === ABSENT_ATTRIBUTE, `Row ${index} needs to have a postalCode`],
    [({ city }) => city === ABSENT_ATTRIBUTE, `Row ${index} needs to have a city`],
    [({ state }) => state === ABSENT_ATTRIBUTE, `Row ${index} needs to have a state`],
    // Max length validation
    [({ address1 }) => (address1 !== ABSENT_ATTRIBUTE && maxLengthValidator(address1, TEXT_FIELD_MAX_LENGTH) !== ''), `Row ${index} has an address1 that exceeds that max length 40`],
    [({ address2 }) => (address2 !== ABSENT_ATTRIBUTE && maxLengthValidator(address2, TEXT_FIELD_MAX_LENGTH) !== ''), `Row ${index} has an address2 that exceeds that max length 40`],
    [({ address3 }) => (address3 !== ABSENT_ATTRIBUTE && maxLengthValidator(address3, TEXT_FIELD_MAX_LENGTH) !== ''), `Row ${index} has an address2 that exceeds that max length 40`],
  ];
  const errors = runTests(tests, localCopy);

  return { errors, validatedAddressData: localCopy };
};

export const storeInfoValidator = (storeInfo, index, processedStoreList) => {
  const storeInfoExtractAttributes = extractAttributes(getAttributeOrMarkAbsent(storeInfo));
  const attributesAndExtractorPairs = [
    ['storeId'],
    ['openingDate', (date) => getFormattedDate(new Date(date))],
    ['closingDate', (date) => getFormattedDate(new Date(date))],
    ['storeStatus'],
    ['locale'],
    ['currencyCode'],
    ['businessConcept'],
    ['email'],
    ['phone'],
    ['partnerName'],
    ['name'],
    ['description'],
    ['facilityType'],
    ['imageUrl'],
    ['shipTo'],
    ['soldTo'],
    ['hrLocationId'],
  ];
  const localCopy = {
    ...storeInfo,
    ...storeInfoExtractAttributes(attributesAndExtractorPairs),
  };
  const tests = [
    // Duplicate check
    [({ storeId, updateType }) => processedStoreList.some((store) => store.storeId === storeId && store.updateType === updateType), `Row ${index} has a duplicate STORE_INFO for id - ${localCopy.storeId}`],
    // existence validation
    [({ storeId }) => storeId === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeId`],
    // logical checks
    [({ openingDate }) => openingDate !== ABSENT_ATTRIBUTE && dateISOValidator(openingDate) !== '', `Row ${index} has an invalid opening date`],
    [({ closingDate, storeStatus }) => closingDate !== ABSENT_ATTRIBUTE && (dateISOValidator(closingDate) !== '' || !(storeStatus === 'CLOSED')), `Row ${index} has an invalid closing date and closed store status combination`],
    [({ storeStatus, closingDate }) => (storeStatus === 'CLOSED' && closingDate === ABSENT_ATTRIBUTE), `Row ${index} is missing a closing date, which is required to close a store`],
    [({ locale }) => (locale !== ABSENT_ATTRIBUTE && localeValidator(locale) !== ''), `Row ${index} has an invalid locale`],
    [({ currencyCode }) => (currencyCode !== ABSENT_ATTRIBUTE && currencyCodeValidator(currencyCode) !== ''), `Row ${index} has an invalid currency`],
    [({ businessConcept }) => (businessConcept !== ABSENT_ATTRIBUTE && businessConceptValidator(businessConcept) !== ''), `Row ${index} has an invalid business concept`],
    [({ phone }) => (phone !== ABSENT_ATTRIBUTE && phoneNumberValidator(phone) !== ''), `Row ${index} has an invalid phone number`],
    [(storeData) => (storeData.partnerName !== ABSENT_ATTRIBUTE && partnerNameValidator(storeData, 'partnerName', true) !== ''), `Row ${index} has an invalid partner name`],
    [({ facilityType }) => (facilityType !== ABSENT_ATTRIBUTE && facilityTypeValidator(facilityType) !== ''), `Row ${index} has an invalid facilityType. Must be one of: ${facilityTypeValues.join(', ')}`],
    // max length validation
    [({ name }) => (name !== ABSENT_ATTRIBUTE && maxLengthValidator(name, TEXT_FIELD_MAX_LENGTH) !== ''), `Row ${index} has a name that exceeds that max length 40`],
  ];
  const errors = runTests(tests, localCopy);

  return { errors, validatedStoreData: localCopy };
};

export const regularHoursValidator = (regularHours, index) => {
  const regularHoursExtractAttributes = extractAttributes(getAttributeOrMarkAbsent(regularHours));
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const attributesAndExtractorPairs = [
    ['storeId'],
    ...days.flatMap((day) => [
      [`${day}ClosedAllDay`],
      [`${day}OpenTime`, timeFormatValidator],
      [`${day}CloseTime`, timeFormatValidator],
    ]),
  ];
  const localCopy = {
    ...regularHours,
    ...regularHoursExtractAttributes(attributesAndExtractorPairs),
  };
  const tests = [
    [({ storeId }) => storeId === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeId`],
    ...days.flatMap((day) => [
      [({ [`${day}ClosedAllDay`]: closedAllDay }) => closedAllDay === ABSENT_ATTRIBUTE, `Row ${index} is missing ${day} closed all day value`],
      [({ [`${day}ClosedAllDay`]: closedAllDay }) => closedAllDay !== ABSENT_ATTRIBUTE && !['YES', 'NO'].includes(closedAllDay), `Row ${index} has an invalid value for ${day} closed all day, valid values are YES or NO`],
      [({ [`${day}ClosedAllDay`]: closedAllDay, [`${day}OpenTime`]: localOpenTime }) => closedAllDay === 'NO' && localOpenTime === ABSENT_ATTRIBUTE, `Row ${index} is missing ${day} local open time, which is required for "closed all day" data value of "NO"`],
      [({ [`${day}ClosedAllDay`]: closedAllDay, [`${day}CloseTime`]: localCloseTime }) => closedAllDay === 'NO' && localCloseTime === ABSENT_ATTRIBUTE, `Row ${index} is missing ${day} local close time, which is required for "closed all day" data value of "NO"`],
    ]),
  ];
  const errors = runTests(tests, localCopy);
  return { errors, validatedRegularHour: localCopy };
};

const REGEX_24_HR_TIME_WITH_OPTIONAL_LEADING_ZERO = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const isValidTimeValue = (timeString) => match(timeString)(
  [ABSENT_ATTRIBUTE, true],
  [Default, (ts) => REGEX_24_HR_TIME_WITH_OPTIONAL_LEADING_ZERO.test(String(ts))],
);

export const specialHourValidator = (specialHour, index) => {
  const specialHourExtractAttributes = extractAttributes(getAttributeOrMarkAbsent(specialHour));
  const attributesAndExtractorPairs = [
    ['storeId'],
    ['date', (date) => getFormattedDate(new Date(date))],
    ['closedAllDay'],
    ['localOpenTime'],
    ['localCloseTime'],
  ];
  const localCopy = {
    ...specialHour,
    ...specialHourExtractAttributes(attributesAndExtractorPairs),
  };

  const tests = [
    [({ storeId }) => storeId === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeId`],
    [({ date }) => date === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeId`],
    [({ date }) => date !== ABSENT_ATTRIBUTE && dateISOValidator(localCopy.date) !== '', `Row ${index} has an invalid date`],
    [({ closedAllDay }) => closedAllDay === ABSENT_ATTRIBUTE, `Row ${index} is missing closed all day value`],
    [({ closedAllDay }) => closedAllDay !== ABSENT_ATTRIBUTE && !['YES', 'NO', 'NORMAL'].includes(closedAllDay), `Row ${index} has an invalid value for closed all day, valid values are YES, NO, or NORMAL`],
    [({ closedAllDay, localOpenTime }) => closedAllDay === 'NO' && localOpenTime === ABSENT_ATTRIBUTE, `Row ${index} is missing local open time, which is required for "closed all day" data value of "NO"`],
    [({ closedAllDay, localCloseTime }) => closedAllDay === 'NO' && localCloseTime === ABSENT_ATTRIBUTE, `Row ${index} is missing local close time, which is required for "closed all day" data value of "NO"`],
    [({ localCloseTime, localOpenTime }) => [localCloseTime, localOpenTime].some(not(isValidTimeValue)), `Row ${index} has a local open or close time not in the format "HH:MM"`],
  ];
  const errors = runTests(tests, localCopy);

  return { errors, validatedSpecialHour: localCopy };
};

export const announcementValidator = (specialHour, index, timeZone) => {
  const announcementExtractAttributes = extractAttributes(getAttributeOrMarkAbsent(specialHour));

  const attributesAndExtractorPairs = [
    ['storeId'],
    ['language'],
    ['messageType'],
    ['expirationDate', (expirationDate) => getFormattedDate(new Date(expirationDate))],
    ['content'],
  ];

  const localCopy = { ...specialHour, ...announcementExtractAttributes(attributesAndExtractorPairs) };

  const localizationToValidate = {
    announcements: [{
      content: localCopy.content,
      expiryDate: localCopy.expirationDate,
      newEdit: true,
    }],
  };
  const localizationError = localizationAnnouncementsValidator(localizationToValidate);

  const tests = [
    // existence validation
    [({ storeId }) => storeId === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeId`],
    [({ language }) => language === ABSENT_ATTRIBUTE, `Row ${index} is missing language`],
    [({ messageType }) => messageType === ABSENT_ATTRIBUTE, `Row ${index} is missing message type`],
    [({ content }) => content === ABSENT_ATTRIBUTE, `Row ${index} is missing content`],
    [({ expirationDate }) => expirationDate === ABSENT_ATTRIBUTE, `Row ${index} is missing expiration date`],
    // Logical Checks
    [({ expirationDate }) => dateISOValidator(expirationDate) !== '', `Row ${index} has an invalid expiration date`],
    [({ messageType }) => announcementMessageTypeValidator(messageType) !== '', `Row ${index} has an invalid message type, valid values are ALERT or PROMOTION`],
    [({ language }) => languageCodeValidator(language) !== '', `Row ${index} has an invalid language code`],
    [({ storeId, expirationDate }) => (storeId !== ABSENT_ATTRIBUTE
      && expirationDate !== ABSENT_ATTRIBUTE
      && timeZone
      && localizationError !== ''), `Row ${index}, ${localizationError}`],
  ];
  const errors = runTests(tests, localCopy);

  return { errors, validatedAnnouncement: localCopy };
};
