import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  localizationCreate, localizationDelete, localizationUpdate, clearZonesResults,
} from '../../../actions/zones';
import localizedLanguages from '../../../utils/static/localizedLanguages';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import { ButtonBlack, ButtonSubmit } from '../../reusable';

import Fields from './Fields';
import LocalizationFields from './LocalizationFields';
import LocalizationTable from './LocalizationTable';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      floorNumber: this.props.currentZone.floorNumber || 1,
      formErrors: {
        floorNumber: '',
        zoneDescription: this.props.currentZone.zoneDescription ? '' : REQUIRED_FIELD,
        zoneName: this.props.currentZone.zoneName ? '' : REQUIRED_FIELD,
        zoneType: this.props.currentZone.zoneType ? '' : REQUIRED_FIELD,
      },
      localizationFormOpen: false,
      storeInfo: {
        id: this.props.currentStore.id,
      },
      zoneDescription: this.props.currentZone.zoneDescription || '',
      zoneName: this.props.currentZone.zoneName || '',
      zoneType: this.props.currentZone.zoneType,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  clearLocalizationForm = () => this.setState({
    currentDescription: '',
    currentLanguage: '',
    currentLocalization: { zoneDescription: '', zoneName: '' },
    currentName: '',
  });

  handleLocalizationDelete = (localization, language, deletingIndex) => {
    // eslint-disable-next-line no-nested-ternary
    const highlightRow = (this.state.editingIndex < deletingIndex)
      ? this.state.editingIndex
      : (this.state.editingIndex > deletingIndex)
        ? this.state.editingIndex - 1
        : -1;
    this.props.localizationDelete(localization, this.props.accessToken)
      .then(() => (this.props.localizationDeleteSuccess
        ? this.setState((prevState) => ({ editingIndex: highlightRow, localizationFormOpen: !(!prevState.editing || (highlightRow === -1)) }))
        : null
      ))
      .catch(() => null);
  };

  handleLocalizationEdit = (localization, index) => this.setState({
    currentDescription: localization.zoneDescription,
    currentLanguage: localization.language,
    currentLocalization: localization,
    currentName: localization.zoneName,
    editing: true,
    editingIndex: index,
    localizationFormOpen: true,
  }, () => this.props.clearZonesResults());

  handleLocalizationSave = () => {
    let action = 'Create';
    if (this.state.editing) {
      action = 'Update';
    }
    this.props[`localization${action}`]({
      id: this.state.currentLocalization.id,
      language: this.state.currentLanguage,
      zoneDescription: this.state.currentDescription,
      zoneId: this.props.currentZone.id,
      zoneName: this.state.currentName,
    }, this.props.accessToken)
      .then(() => {
        window.scrollTo(0, 1000);
        return (action === 'Create' && this.clearLocalizationForm());
      })
      .catch(() => {
        window.scrollTo(0, 1000);
        return null;
      });
  };

  isDisabled = () => !(this.props.currentStore.id && this.state.zoneName && this.state.zoneDescription && this.state.floorNumber && this.state.zoneType);

  onSubmit = () => {
    const body = {
      ...this.props.currentZone,
      floorNumber: this.state.floorNumber,
      storeId: this.props.currentStore.id,
      zoneDescription: this.state.zoneDescription,
      zoneName: this.state.zoneName,
      zoneType: this.state.zoneType,
    };
    if (this.props.editing) {
      this.props.updateZones(body, this.props.accessToken);
    } else {
      this.props.createZones(body, this.props.accessToken);
    }
  };

  setLocalizationField = (property, attribute, value) => {
    const localization = { ...this.state.currentLocalization };
    localization[attribute] = value;
    this.setState(() => ({ currentLocalization: localization, [property]: value }));
  };

  updateState = (prop, value) => this.setState({ [prop]: value });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <section className="ncss-row pt10-sm pb10-sm">
      <section className="ncss-col-sm-10 ta-sm-l bg-primary-grey">
        <Fields
          floorNumber={this.state.floorNumber}
          formErrors={this.state.formErrors}
          storeInfo={this.state.storeInfo}
          updateState={this.updateState}
          zoneDescription={this.state.zoneDescription}
          zoneName={this.state.zoneName}
          zoneType={this.state.zoneType}
        />
      </section>
      <article className="ncss-col-sm-6 ta-sm-c">
        <ButtonBlack
          label="Cancel"
          onClick={() => {
            this.props.clearZonesResults();
            this.props.cancelForm();
          }}
        />
      </article>
      <ButtonSubmit
        className="ncss-col-sm-6 va-sm-t ta-sm-c"
        isDisabled={this.isDisabled()}
        isLoading={this.props.posting || this.props.putting}
        label="Save"
        onClick={this.onSubmit}
      />
      <aside className="ncss-col-sm-12 mb4-sm">
        {this.props.zoneError && <p className="text-color-error">{this.props.zoneError}</p>}
        {this.props.zoneSuccess && <p className="text-color-success">{this.props.zoneSuccess}</p>}
        {this.props.fetchLocalizationsError && <p className="text-color-error">{this.props.fetchLocalizationsError}</p>}
      </aside>
      {this.props.editing && (
        <section className="ncss-row mt4-sm">
          {!this.props.fetchLocalizationsRequest
            ? (
              <article className="ncss-col-sm-12 mt6-sm">
                <h2 className="mt4-sm ta-sm-c headline-3 bg-black text-color-primary-light">Zone Localizations</h2>
                <LocalizationTable
                  editingIndex={this.state.editingIndex}
                  localizationDelete={this.handleLocalizationDelete}
                  localizationEdit={this.handleLocalizationEdit}
                  localizations={this.props.localizations}
                  zoneLastModified={this.props.zoneModificationDate}
                />
              </article>
            )
            : <Loading />}
          <article className="ncss-col-sm-12 ta-sm-c mt4-sm mb4-sm">
            {this.props.deletingLocalization
              ? <Loading />
              : (this.props.localizationDeleteSuccess
                && <p className="body-2 text-color-success">{`${this.props.localizationDeleteSuccess} ${localizedLanguages[this.props.localizationLanguage]}`}.</p>
              ) || (this.props.localizationDeleteError
                && <p className="body-2 text-color-error">{this.props.localizationDeleteError}</p>
              )}
            <ButtonBlack
              label={this.state.localizationFormOpen ? 'Cancel' : 'Add Localization'}
              onClick={() => {
                this.props.clearZonesResults();
                this.setState((prevState) => ({ editing: false, editingIndex: -1, localizationFormOpen: !prevState.localizationFormOpen }),
                  () => this.clearLocalizationForm());
              }}
            />
          </article>
          {this.state.localizationFormOpen && (
            <section className="ncss-col-sm-12 ta-sm-c pb5-sm">
              <LocalizationFields
                editing={this.state.editing}
                languageOptions={Object.keys(localizedLanguages)}
                localization={this.state.currentLocalization || {}}
                setField={this.setLocalizationField}
                usedLanguages={this.props.localizations.map((x) => x.language)}
              />
              <ButtonSubmit
                isDisabled={!this.state.currentLanguage}
                isLoading={this.props.creatingLocalization || this.props.updatingLocalization}
                label={!this.state.editing ? 'Add' : 'Update'}
                submitError={this.props.localizationCreateUpdateError}
                onClick={this.handleLocalizationSave}
              />
              {(this.props.localizationCreateUpdateSuccess)
                && <p className="mb4-sm text-color-success">{`${this.props.localizationCreateUpdateSuccess} ${localizedLanguages[this.props.localizationLanguage]}`}.</p>}
            </section>
          )}
        </section>
      )}
    </section>
  );
}

Form.defaultProps = {
  accessToken: undefined,
  currentZone: {
    floorNumber: 1,
    zoneDescription: '',
    zoneName: '',
    zoneType: '',
  },
};

Form.propTypes = {
  accessToken: PropTypes.string,
  cancelForm: PropTypes.func.isRequired,
  clearZonesResults: PropTypes.func.isRequired,
  createZones: PropTypes.func.isRequired,
  creatingLocalization: PropTypes.bool.isRequired,
  currentStore: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  currentZone: PropTypes.shape({
    floorNumber: PropTypes.number,
    id: PropTypes.string,
    zoneDescription: PropTypes.string,
    zoneName: PropTypes.string,
    zoneType: PropTypes.string,
  }),
  deletingLocalization: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  fetchLocalizationsError: PropTypes.string.isRequired,
  fetchLocalizationsRequest: PropTypes.bool.isRequired,
  localizationCreateUpdateError: PropTypes.string.isRequired,
  localizationCreateUpdateSuccess: PropTypes.string.isRequired,
  localizationDelete: PropTypes.func.isRequired,
  localizationDeleteError: PropTypes.string.isRequired,
  localizationDeleteSuccess: PropTypes.string.isRequired,
  localizationLanguage: PropTypes.string.isRequired,
  localizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  posting: PropTypes.bool.isRequired,
  putting: PropTypes.bool.isRequired,
  updateZones: PropTypes.func.isRequired,
  updatingLocalization: PropTypes.bool.isRequired,
  zoneError: PropTypes.string.isRequired,
  zoneModificationDate: PropTypes.string.isRequired,
  zoneSuccess: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
  creatingLocalization: state.zonesReducer.creatingLocalization,
  deletingLocalization: state.zonesReducer.deletingLocalization,
  fetchLocalizationsError: state.zonesReducer.fetchLocalizationsError,
  fetchLocalizationsRequest: state.zonesReducer.fetchLocalizationsRequest,
  localizationCreateUpdateError: state.zonesReducer.localizationCreateUpdateError,
  localizationCreateUpdateSuccess: state.zonesReducer.localizationCreateUpdateSuccess,
  localizationDeleteError: state.zonesReducer.localizationDeleteError,
  localizationDeleteSuccess: state.zonesReducer.localizationDeleteSuccess,
  localizationLanguage: state.zonesReducer.localizationLanguage,
  localizations: state.zonesReducer.localizations,
  posting: state.zonesReducer.posting,
  putting: state.zonesReducer.putting,
  updatingLocalization: state.zonesReducer.updatingLocalization,
  zoneModificationDate: state.zonesReducer.zoneModificationDate,
});

const mapDispatchToProps = (dispatch) => ({
  clearZonesResults: () => dispatch(clearZonesResults()),
  localizationCreate: (localization, accessToken) => dispatch(localizationCreate(localization, accessToken)),
  localizationDelete: (localization, accessToken) => dispatch(localizationDelete(localization, accessToken)),
  localizationUpdate: (localization, accessToken) => dispatch(localizationUpdate(localization, accessToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
