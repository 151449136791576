export default {
  objects: [
    {
      id: 'ba4fe0a4-6886-4fa5-93ff-66a328a7d5c9',
      name: 'EMEA_NTL',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
      name: 'GC_CS_XE',
      nodeType: 'DISTRICT',
      parentId: '866da269-8627-4b2f-ae54-14c09bbdb359'
    },
    {
      id: '011c974d-ad7b-4a21-ae1b-ff52a2a066cd',
      name: 'APLA_MX_DISTRICT_4',
      nodeType: 'DISTRICT',
      parentId: '85c7bcac-d7aa-44af-8333-7fc815346f2c'
    },
    {
      id: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
      name: 'GC_CN_NE',
      nodeType: 'DISTRICT',
      parentId: 'b1352c31-7e9d-4f18-b1c8-4bcef7e47067'
    },
    {
      id: '9e3b5577-c37d-4544-a010-46859843abba',
      name: 'APLA_SEA',
      nodeType: 'TERRITORY',
      parentId: 'dc99749d-da1e-45c3-a798-0e38f143469b'
    },
    {
      id: '78949803-222b-4c23-aa04-e3fdc86be170',
      name: 'CENTRAL',
      nodeType: 'TERRITORY',
      parentId: 'a469ace0-9f91-4c5e-95bb-37f5403a61f0'
    },
    {
      id: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
      name: 'Washington D2',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    },
    {
      id: 'c449e4dc-2f77-47ca-ab57-0aa855745d48',
      name: 'GC_HK_TW_MC',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '119ccd94-5dcd-4040-9080-ecde2c9bb854',
      name: 'GC_CS_NSO',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: 'af5d9302-afc2-430f-a888-c74fa23805bc',
      name: 'PNW_CENTRAL',
      nodeType: 'DISTRICT',
      parentId: 'e773a10b-2edf-45ca-b1fe-9c719829ba50'
    },
    {
      id: '65934df7-0466-4df5-a9e0-fb99fd4f4ba3',
      name: 'GC_CE',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
      name: 'San Diego Inland Empire D7',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    },
    {
      id: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
      name: 'Great Lakes D17',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
      name: 'North TX D13',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: 'dc99749d-da1e-45c3-a798-0e38f143469b',
      name: 'ASIA_PACIFIC_LATIN_AMERICA',
      nodeType: 'REGION',
      parentId: 'f61cd7a9-37c7-4c41-87e9-0f9d37c240bf'
    },
    {
      id: '82fa2b53-4578-4639-8ee7-2b4ce2dd9afc',
      metadata: {
        lastReplayed: '2024-04-10T16:02:50.123Z'
      },
      name: 'NORTH_AMERICA',
      nodeType: 'REGION',
      parentId: 'c009c6a5-f2c1-4e36-9004-10d600a70cc9'
    },
    {
      id: 'caf12979-f3a7-4fe7-a97e-1f226b0d32f7',
      name: 'GC_CN_BJNSO',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '2f1b4c39-e8ac-4cea-abd2-95d8b9b7aefe',
      name: 'EMEA_BERLIN',
      nodeType: 'DISTRICT',
      parentId: '2007f174-9208-420a-adf6-a593e674eebd'
    },
    {
      id: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
      name: 'APLA_JP_WEST',
      nodeType: 'DISTRICT',
      parentId: '35f06651-6b87-475a-b067-ddcf330bb516'
    },
    {
      id: '9d9eff3f-247a-4ecb-92af-3ea47a3ef09f',
      name: 'West Canada D35',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
      name: 'APLA_JP_CENTRAL',
      nodeType: 'DISTRICT',
      parentId: '35f06651-6b87-475a-b067-ddcf330bb516'
    },
    {
      id: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
      name: 'APLA_JP_NSO',
      nodeType: 'DISTRICT',
      parentId: '35f06651-6b87-475a-b067-ddcf330bb516'
    },
    {
      id: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
      name: 'Gulf States D19',
      nodeType: 'DISTRICT',
      parentId: '9c204917-5fc3-4d5e-91bb-4813c2a72a33'
    },
    {
      id: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
      name: 'EMEA_UK_MIDLANDS',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: 'd018da20-ba06-4a8b-82d2-eaa2a5670c5d',
      name: 'EMEA_PORTUGAL',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: '90e4a635-7abd-449f-a2b3-e799d9e8b1f5',
      name: 'EMEA_RUSSIA_MOSCOW',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: '424a9a4d-32cb-431b-936d-31fe4b0de399',
      name: 'APLA_JP_EAST',
      nodeType: 'DISTRICT',
      parentId: '35f06651-6b87-475a-b067-ddcf330bb516'
    },
    {
      id: '1e4c57d2-d7e5-4937-99ca-8c74e84c150c',
      name: 'APLA_SEA_TH_NVS',
      nodeType: 'DISTRICT',
      parentId: '9e3b5577-c37d-4544-a010-46859843abba'
    },
    {
      id: '20571e42-cd9f-476c-9571-962ebd17937c',
      name: 'AZ D10',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: '0f3a1f03-436c-4890-b4d3-4547f527c0cc',
      name: 'GC_HK_NSO',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '54530702-9c61-4bb5-9403-066b5e02d9fe',
      name: 'Coastal FL D22',
      nodeType: 'DISTRICT',
      parentId: '9c204917-5fc3-4d5e-91bb-4813c2a72a33'
    },
    {
      id: '3350ffa3-adb8-4981-8a98-5dc7fddd581c',
      name: 'EUROPE_MIDDLE_EAST_AFRICA',
      nodeType: 'REGION',
      parentId: 'f61cd7a9-37c7-4c41-87e9-0f9d37c240bf'
    },
    {
      id: 'ac92a794-1790-4505-9279-186e2dfa2087',
      name: 'EMEA_UK_NORTH_WEST',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: '66772996-562e-4025-a37f-33b8bab87475',
      name: 'INLINE',
      nodeType: 'DISTRICT',
      parentId: 'e773a10b-2edf-45ca-b1fe-9c719829ba50'
    },
    {
      id: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
      name: 'GC_CE_JS',
      nodeType: 'DISTRICT',
      parentId: '65934df7-0466-4df5-a9e0-fb99fd4f4ba3'
    },
    {
      id: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
      name: 'GC_CN_BJ',
      nodeType: 'DISTRICT',
      parentId: 'b1352c31-7e9d-4f18-b1c8-4bcef7e47067'
    },
    {
      id: 'd69076bd-e5bf-4d7f-91d1-4ea5fe42e7c3',
      name: 'GC_CN_BJNSO',
      nodeType: 'DISTRICT',
      parentId: 'caf12979-f3a7-4fe7-a97e-1f226b0d32f7'
    },
    {
      id: '866da269-8627-4b2f-ae54-14c09bbdb359',
      name: 'GC_CS',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
      name: 'GC_CE_NSO',
      nodeType: 'DISTRICT',
      parentId: 'd446e253-3e10-4a8e-a8fe-575f8bf88a17'
    },
    {
      id: '6f6e8036-8809-4f7d-9afa-a80558caf025',
      name: 'South Florida D24',
      nodeType: 'DISTRICT',
      parentId: '9c204917-5fc3-4d5e-91bb-4813c2a72a33'
    },
    {
      id: 'f61cd7a9-37c7-4c41-87e9-0f9d37c240bf',
      name: 'NIKE',
      nodeType: 'BRAND'
    },
    {
      id: '66590f61-0956-411b-b290-faa23fdcb884',
      name: 'Georgia D20',
      nodeType: 'DISTRICT',
      parentId: '9c204917-5fc3-4d5e-91bb-4813c2a72a33'
    },
    {
      id: '6b3f9bc4-1279-49a9-ac4f-e04b50e3b76b',
      name: 'GC_TW_NSO',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '43bbce93-a2ad-4606-a7df-205395646fdf',
      name: 'EMEA_SOUTH_AFRICA',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: '2a8a5272-2ead-401e-a8ea-15edea456d51',
      name: 'Boston Metro D32',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
      name: 'New England D31',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
      name: 'NORTHEAST',
      nodeType: 'DISTRICT',
      parentId: 'e773a10b-2edf-45ca-b1fe-9c719829ba50'
    },
    {
      id: '125d973d-d862-4d06-b2b2-5d14f960d4af',
      name: 'EMEA_SPAIN_CENTRAL',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: '874db406-7943-4c85-8f22-e671d4c2855e',
      name: 'APLA_PAC_DISTRICT_1',
      nodeType: 'DISTRICT',
      parentId: 'fb33d44b-6096-4bd2-a64f-8dc915a0e217'
    },
    {
      id: '51996a81-74ad-4aaf-9e52-4997bf860de0',
      name: 'Oregon D1',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    },
    {
      id: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
      name: 'EMEA_GER_MID_SWTZ',
      nodeType: 'DISTRICT',
      parentId: '2007f174-9208-420a-adf6-a593e674eebd'
    },
    {
      id: 'f6f807a1-6c74-48f8-af79-d9dad8664dd7',
      name: 'EMEA_GREECE',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
      name: 'Chicago Metro D16',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
      name: 'EMEA_FRANCE_SW',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
      name: 'APLA_KR_NVS_DISTRICT_2',
      nodeType: 'DISTRICT',
      parentId: '559e93c6-18c0-442f-9651-20a2ac51009d'
    },
    {
      id: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
      name: 'DC Metro D26',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: 'b1352c31-7e9d-4f18-b1c8-4bcef7e47067',
      name: 'GC_CN',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
      name: 'New Jersey D28',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: '8b2ae4b8-eb16-4d51-83bb-dba72ba34715',
      name: 'EMEA_FRANCE_SE',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
      name: 'EMEA_GERMANY_N_W',
      nodeType: 'DISTRICT',
      parentId: '2007f174-9208-420a-adf6-a593e674eebd'
    },
    {
      id: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
      name: 'CO D9',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: '51a1785a-96a0-4fb2-a5d8-c3d531ada5b3',
      name: 'APLA_KR_NSO',
      nodeType: 'DISTRICT',
      parentId: '559e93c6-18c0-442f-9651-20a2ac51009d'
    },
    {
      id: '4da1e8ed-2893-4ac4-a578-e03e4ac4c5aa',
      name: 'GC_HK_MC',
      nodeType: 'DISTRICT',
      parentId: 'c449e4dc-2f77-47ca-ab57-0aa855745d48'
    },
    {
      id: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
      name: 'Vegas/UT D8',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    },
    {
      id: '524440b5-a003-410b-9e4b-25c2de21868b',
      name: 'EMEA_ROME',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
      name: 'Central TX D11',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
      name: 'GC_CS_MGQ',
      nodeType: 'DISTRICT',
      parentId: '866da269-8627-4b2f-ae54-14c09bbdb359'
    },
    {
      id: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
      name: 'APLA_MX_DISTRICT_2',
      nodeType: 'DISTRICT',
      parentId: '85c7bcac-d7aa-44af-8333-7fc815346f2c'
    },
    {
      id: '0f249a59-1a39-4d43-815f-b9ed824f45b6',
      name: 'APLA_SEA_MY_NVS',
      nodeType: 'DISTRICT',
      parentId: '9e3b5577-c37d-4544-a010-46859843abba'
    },
    {
      id: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
      name: 'SOUTHWEST',
      nodeType: 'DISTRICT',
      parentId: 'e773a10b-2edf-45ca-b1fe-9c719829ba50'
    },
    {
      id: '9971b364-907d-44a5-9e91-2b790a5b3268',
      name: 'Carolinas D21',
      nodeType: 'DISTRICT',
      parentId: '9c204917-5fc3-4d5e-91bb-4813c2a72a33'
    },
    {
      id: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
      name: 'Midwest D14',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: '84065b43-8aa0-4415-b314-5ff958ddbecd',
      name: 'EMEA_SPAIN_EAST',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: '0edcb3f7-a768-4ade-83d0-d0e5365d6dc5',
      name: 'EMEA_UK_IRELAND_WEST',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
      name: 'East Canada D34',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: 'dcc4eb42-f310-4b95-ba54-d696a999c727',
      name: 'EMEA_LONDON_NSO',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
      name: 'Bay Area D4',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    },
    {
      id: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
      name: 'EMEA_UK_NORTH',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: '6a92cade-8b98-409e-9582-069514d23a0a',
      name: 'GC_TW_NSO',
      nodeType: 'DISTRICT',
      parentId: '6b3f9bc4-1279-49a9-ac4f-e04b50e3b76b'
    },
    {
      id: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
      name: 'EMEA_TURKEY',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
      name: 'WEST',
      nodeType: 'DISTRICT',
      parentId: 'e773a10b-2edf-45ca-b1fe-9c719829ba50'
    },
    {
      id: '31951c7d-b390-44d3-ae7e-552ea5642043',
      name: 'EMEA_FRANCE',
      nodeType: 'TERRITORY',
      parentId: '3350ffa3-adb8-4981-8a98-5dc7fddd581c'
    },
    {
      id: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
      name: 'APLA_KR_NVS_DISTRICT_1',
      nodeType: 'DISTRICT',
      parentId: '559e93c6-18c0-442f-9651-20a2ac51009d'
    },
    {
      id: 'b92f8324-e237-4b72-9463-0020d46f2be6',
      name: 'APLA_SEA_SG_NSO',
      nodeType: 'DISTRICT',
      parentId: '9e3b5577-c37d-4544-a010-46859843abba'
    },
    {
      id: '5a9a0356-121c-4c8e-a37a-c6666b616408',
      name: 'APLA_MX_DISTRICT_1',
      nodeType: 'DISTRICT',
      parentId: '85c7bcac-d7aa-44af-8333-7fc815346f2c'
    },
    {
      id: 'b40757b7-922b-4136-8933-625aa86711bf',
      name: 'LA D6',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    },
    {
      id: '9c204917-5fc3-4d5e-91bb-4813c2a72a33',
      name: 'SOUTHEAST',
      nodeType: 'TERRITORY',
      parentId: 'a469ace0-9f91-4c5e-95bb-37f5403a61f0'
    },
    {
      id: '91703f40-d783-4445-9f26-57eb6a73d2db',
      name: 'GC_TW',
      nodeType: 'DISTRICT',
      parentId: 'c449e4dc-2f77-47ca-ab57-0aa855745d48'
    },
    {
      id: '40880d74-de09-4fd5-81ce-fa42cca1d744',
      name: 'EMEA_UK_SOUTH',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
      name: 'EMEA_PARIS_NORTH',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
      name: 'New Jersey/Pennsylvania D27',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6',
      name: 'EMEA_WEST',
      nodeType: 'TERRITORY',
      parentId: '3350ffa3-adb8-4981-8a98-5dc7fddd581c'
    },
    {
      id: '09984139-b915-496b-a573-4f1d14d1b87c',
      name: 'EMEA_SOUTH',
      nodeType: 'TERRITORY',
      parentId: '3350ffa3-adb8-4981-8a98-5dc7fddd581c'
    },
    {
      id: '1c3af8dc-d58a-4495-909d-823d7d8f09e1',
      name: 'EMEA_ITALY_NE',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: '0f873e25-c3dc-420c-82e5-740c579c5669',
      name: 'EMEA_NE_NORTH',
      nodeType: 'DISTRICT',
      parentId: '2007f174-9208-420a-adf6-a593e674eebd'
    },
    {
      id: 'c49dc1d3-fbc1-47bf-989b-a11a394bffb5',
      name: 'GC_HK_NSO',
      nodeType: 'DISTRICT',
      parentId: '0f3a1f03-436c-4890-b4d3-4547f527c0cc'
    },
    {
      id: '20698335-5f01-4d39-be57-78cf7b399aa7',
      name: 'Long Island D30',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
      name: 'EMEA_UK_EAST',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: '027e5cb7-0256-4a70-882d-cc673a2cf581',
      name: 'N Midwest D15',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41',
      name: 'GREATER_CHINA',
      nodeType: 'REGION',
      parentId: 'f61cd7a9-37c7-4c41-87e9-0f9d37c240bf'
    },
    {
      id: 'fb33d44b-6096-4bd2-a64f-8dc915a0e217',
      name: 'APLA_PAC',
      nodeType: 'TERRITORY',
      parentId: 'dc99749d-da1e-45c3-a798-0e38f143469b'
    },
    {
      id: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
      name: 'GC_CW_NW',
      nodeType: 'DISTRICT',
      parentId: 'c963aec2-5708-42de-b9a0-05ac506f56ba'
    },
    {
      id: 'c39640bc-c790-42de-8810-e227062b5fb5',
      name: 'APLA_JP_NSP',
      nodeType: 'DISTRICT',
      parentId: '35f06651-6b87-475a-b067-ddcf330bb516'
    },
    {
      id: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
      name: 'South TX D12',
      nodeType: 'DISTRICT',
      parentId: '78949803-222b-4c23-aa04-e3fdc86be170'
    },
    {
      id: '1cb2bafd-c74f-4e9b-afa0-deae26868954',
      name: 'EMEA_ITALY_NW',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: '30f321ed-e7ae-43af-8afd-eba31bcf811e',
      name: 'EMEA_HOI',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: 'de5b82a0-e649-4103-9387-cf868dd85899',
      name: 'NYC Metro D29',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
      name: 'GC_CW_CY',
      nodeType: 'DISTRICT',
      parentId: 'c963aec2-5708-42de-b9a0-05ac506f56ba'
    },
    {
      id: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
      name: 'Virginia D25',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: '80d72218-6d2b-46e2-bea0-2210cb625fbb',
      name: 'EMEA_ISRAEL',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: 'a1004c17-3c88-45e2-81a9-15d73108b589',
      name: 'GC_CW_YG',
      nodeType: 'DISTRICT',
      parentId: 'c963aec2-5708-42de-b9a0-05ac506f56ba'
    },
    {
      id: 'c9b68f67-9922-480e-9eda-8de549737439',
      name: 'EMEA_TURKEY_ISTANBUL',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: 'd446e253-3e10-4a8e-a8fe-575f8bf88a17',
      name: 'GC_CE_SHNSO',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
      name: 'GC_CN_JJ',
      nodeType: 'DISTRICT',
      parentId: 'b1352c31-7e9d-4f18-b1c8-4bcef7e47067'
    },
    {
      id: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
      name: 'Central Florida D23',
      nodeType: 'DISTRICT',
      parentId: '9c204917-5fc3-4d5e-91bb-4813c2a72a33'
    },
    {
      id: 'c963aec2-5708-42de-b9a0-05ac506f56ba',
      name: 'GC_CW',
      nodeType: 'TERRITORY',
      parentId: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41'
    },
    {
      id: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
      name: 'GC_CS_SZ',
      nodeType: 'DISTRICT',
      parentId: '866da269-8627-4b2f-ae54-14c09bbdb359'
    },
    {
      id: '3354e3fb-9285-47ab-a3ce-13fcff9bf71c',
      name: 'EMEA_POLAND_SOUTH',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50',
      name: 'WEST',
      nodeType: 'TERRITORY',
      parentId: 'a469ace0-9f91-4c5e-95bb-37f5403a61f0'
    },
    {
      id: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f',
      name: 'EMEA_EAST_AFRICA',
      nodeType: 'TERRITORY',
      parentId: '3350ffa3-adb8-4981-8a98-5dc7fddd581c'
    },
    {
      id: '1c174686-8548-40ed-87f6-4420dde655a3',
      name: 'EMEA_RUSSIA_ST_PETE',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: '74589f48-d268-4548-90ac-e18b348c08cd',
      name: 'GC_CS_NSO',
      nodeType: 'DISTRICT',
      parentId: '119ccd94-5dcd-4040-9080-ecde2c9bb854'
    },
    {
      id: '85c7bcac-d7aa-44af-8333-7fc815346f2c',
      name: 'APLA_MX',
      nodeType: 'TERRITORY',
      parentId: 'dc99749d-da1e-45c3-a798-0e38f143469b'
    },
    {
      id: '2007f174-9208-420a-adf6-a593e674eebd',
      name: 'EMEA_CENTRAL',
      nodeType: 'TERRITORY',
      parentId: '3350ffa3-adb8-4981-8a98-5dc7fddd581c'
    },
    {
      id: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
      name: 'GC_CN_LY',
      nodeType: 'DISTRICT',
      parentId: 'b1352c31-7e9d-4f18-b1c8-4bcef7e47067'
    },
    {
      id: '659456f0-a9b5-4c12-8bd8-649097373619',
      name: 'EMEA_POLAND_NORTH',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: 'd99e4ddd-3a04-4da8-af15-7a4b1648727f',
      name: 'EMEA_MADRID',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: 'fe144077-30cf-4be6-9316-3a64d50a3124',
      name: 'EMEA_FRANCE_CENTRAL',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: '929fe395-4f1e-43ff-9225-7711308d15be',
      name: 'Inland LA D5',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    },
    {
      id: 'deaab327-38f9-46b2-a620-287e15721e6d',
      name: 'GC_CE_SH',
      nodeType: 'DISTRICT',
      parentId: '65934df7-0466-4df5-a9e0-fb99fd4f4ba3'
    },
    {
      id: 'aedd4b07-bd95-46bc-bedc-c1dafa8b491e',
      name: 'EMEA_FRANCE_NORTH',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: 'cd913270-82a6-4a1e-9b7e-d4730ba22184',
      name: 'APLA_SEA_SG_NVS',
      nodeType: 'DISTRICT',
      parentId: '9e3b5577-c37d-4544-a010-46859843abba'
    },
    {
      id: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
      name: 'EMEA_GER_SOUTH_AUS',
      nodeType: 'DISTRICT',
      parentId: '2007f174-9208-420a-adf6-a593e674eebd'
    },
    {
      id: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
      name: 'MID_ATLANTIC',
      nodeType: 'DISTRICT',
      parentId: 'e773a10b-2edf-45ca-b1fe-9c719829ba50'
    },
    {
      id: '30b04d60-e533-4032-bc19-6cc227b6395d',
      name: 'GC_CE_SW',
      nodeType: 'DISTRICT',
      parentId: '65934df7-0466-4df5-a9e0-fb99fd4f4ba3'
    },
    {
      id: '031af3aa-9b11-4b5d-b05f-80d63e997ea7',
      name: 'EMEA_NE_SOUTH',
      nodeType: 'DISTRICT',
      parentId: '2007f174-9208-420a-adf6-a593e674eebd'
    },
    {
      id: '9d2a3b18-08e9-46c4-93be-dc8d1bfc1f1a',
      name: 'EMEA_MILAN',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: '2a0898a2-fe79-4fa0-8d7d-b8c63500ced5',
      name: 'EMEA_PARIS_SOUTH',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
      name: 'GC_CE_JZ',
      nodeType: 'DISTRICT',
      parentId: '65934df7-0466-4df5-a9e0-fb99fd4f4ba3'
    },
    {
      id: '6bec911a-2db5-4c11-b503-0aa21320d385',
      name: 'NORTHEAST',
      nodeType: 'TERRITORY',
      parentId: 'a469ace0-9f91-4c5e-95bb-37f5403a61f0'
    },
    {
      id: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
      name: 'EMEA_NE_MID',
      nodeType: 'DISTRICT',
      parentId: '2007f174-9208-420a-adf6-a593e674eebd'
    },
    {
      id: '35f06651-6b87-475a-b067-ddcf330bb516',
      name: 'APLA_JP',
      nodeType: 'TERRITORY',
      parentId: 'dc99749d-da1e-45c3-a798-0e38f143469b'
    },
    {
      id: 'a469ace0-9f91-4c5e-95bb-37f5403a61f0',
      metadata: {
        lastReplayed: '2024-04-10T16:02:50.136Z'
      },
      name: 'NORTH_AMERICA',
      nodeType: 'REGION',
      parentId: 'f61cd7a9-37c7-4c41-87e9-0f9d37c240bf'
    },
    {
      id: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
      name: 'EMEA_FRANCE_EAST',
      nodeType: 'DISTRICT',
      parentId: '31951c7d-b390-44d3-ae7e-552ea5642043'
    },
    {
      id: 'c009c6a5-f2c1-4e36-9004-10d600a70cc9',
      metadata: {
        lastReplayed: '2024-04-10T15:57:29.815Z'
      },
      name: 'CONVERSE',
      nodeType: 'BRAND'
    },
    {
      id: '3c364fbe-617b-46b1-b867-1579b1e478f2',
      name: 'EMEA_CENTRAL_EUROPE',
      nodeType: 'DISTRICT',
      parentId: 'fe43fc14-fd49-4106-bf8a-748bf9086c2f'
    },
    {
      id: '559e93c6-18c0-442f-9651-20a2ac51009d',
      name: 'APLA_KR',
      nodeType: 'TERRITORY',
      parentId: 'dc99749d-da1e-45c3-a798-0e38f143469b'
    },
    {
      id: '3ff1a7ff-de7d-4f2b-b7e7-e85a5c5a68c7',
      name: 'EMEA_BARCELONA',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
      name: 'Ohio Valley D33',
      nodeType: 'DISTRICT',
      parentId: '6bec911a-2db5-4c11-b503-0aa21320d385'
    },
    {
      id: '05f259e2-5c3f-43b8-aae2-8a59f239af36',
      name: 'EMEA_LONDON_NVS',
      nodeType: 'DISTRICT',
      parentId: 'baf9673b-6c24-4f2a-9aad-9e669114e6c6'
    },
    {
      id: '92c6ee55-0adc-4eab-b0cc-256a9e8b04dd',
      name: 'EMEA_ITALY_SOUTH',
      nodeType: 'DISTRICT',
      parentId: '09984139-b915-496b-a573-4f1d14d1b87c'
    },
    {
      id: 'b5f4164d-1990-4484-a90e-185f85c325fc',
      name: 'TN D18',
      nodeType: 'DISTRICT',
      parentId: '9c204917-5fc3-4d5e-91bb-4813c2a72a33'
    },
    {
      id: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
      name: 'GC_CS_GZ',
      nodeType: 'DISTRICT',
      parentId: '866da269-8627-4b2f-ae54-14c09bbdb359'
    },
    {
      id: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
      name: 'SOUTHEAST',
      nodeType: 'DISTRICT',
      parentId: 'e773a10b-2edf-45ca-b1fe-9c719829ba50'
    },
    {
      id: 'e773a10b-2edf-45ca-b1fe-9c719829ba50',
      name: 'CONVERSE',
      nodeType: 'TERRITORY',
      parentId: '82fa2b53-4578-4639-8ee7-2b4ce2dd9afc'
    },
    {
      id: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
      name: 'APLA_MX_DISTRICT_3',
      nodeType: 'DISTRICT',
      parentId: '85c7bcac-d7aa-44af-8333-7fc815346f2c'
    },
    {
      id: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
      name: 'CA/NV D3',
      nodeType: 'DISTRICT',
      parentId: '0d91c7b1-16b9-42a7-b0d2-395f31a4fb50'
    }
  ]
};
