// Partial implementation of these rules in Java (https://nike.ent.box.com/s/0zsnl03w2y6bs7x1som819uo0zv7mhli/file/854484124131):
//
// <?xml version="1.0" encoding="UTF-8" standalone="yes"?>
// <rules xmlns="http://nike.com/dcs/pi/rules" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
//  <stringManipulationRules>
//    <stringManipulationRule xsi:type="stripHTMLTagRuleType"/>
//    <stringManipulationRule xsi:type="unescapeHtmlRuleType"/>
//    <stringManipulationRule xsi:type="lowerCaseRuleType"/>
//
//    <!-- remove reserved URL special chars -->
//    <stringManipulationRule xsi:type="removeStringsRuleType">
//      <strings>'</strings>
//      <strings>’</strings>
//      <strings>‘</strings>
//      <strings>"</strings>
//      <strings>“</strings>
//      <strings>”</strings>
//      <strings>.</strings>
//      <strings>`</strings>
//      <strings>|</strings>
//      <strings>~</strings>
//      <strings>+</strings>
//      <strings>[</strings>
//      <strings>]</strings>
//      <strings>(</strings>
//      <strings>)</strings>
//      <strings>{</strings>
//      <strings>}</strings>
//      <strings>\</strings>
//      <strings>$</strings>
//      <strings>&amp;</strings>
//      <strings>,</strings>
//      <strings>:</strings>
//      <strings>;</strings>
//      <strings>=</strings>
//      <strings>?</strings>
//      <strings>@</strings>
//      <strings>#</strings>
//      <strings>%</strings>
//      <strings>…</strings>
//      <strings>˜</strings>
//      <strings>∽</strings>
//      <strings>*</strings>
//      <strings>^</strings>
//      <strings>"</strings>
//      <strings>◦</strings>
//      <strings>•</strings>
//      <strings>_</strings>
//    </stringManipulationRule>
//
//    <!-- Replace all whitespace including non-breaking spaces (U+00A0) with dashes -->
//    <stringManipulationRule xsi:type="regexReplaceRuleType">
//      <regex>[\p{Z}\s]+</regex>
//      <replacement>-</replacement>
//    </stringManipulationRule>
//
//    <!-- Replace multiple sets of special chars with a single dash -->
//    <stringManipulationRule xsi:type="regexReplaceRuleType">
//      <regex>/+</regex>
//      <replacement>-</replacement>
//    </stringManipulationRule>
//    <stringManipulationRule xsi:type="regexReplaceRuleType">
//      <regex>\s+</regex>
//      <replacement>-</replacement>
//    </stringManipulationRule>
//    <stringManipulationRule xsi:type="regexReplaceRuleType">
//      <regex>-+</regex>
//      <replacement>-</replacement>
//    </stringManipulationRule>
//
//    <!-- remove leading/trailing dashes -->
//    <stringManipulationRule xsi:type="regexReplaceFirstRuleType">
//      <regex>^-+</regex>
//    </stringManipulationRule>
//    <stringManipulationRule xsi:type="regexReplaceRuleType">
//      <regex>-+$</regex>
//    </stringManipulationRule>
//  </stringManipulationRules>
// </rules>
const calculateSlug = (stringOrNull) => {
  const EMPTY = '';
  const DASH = '-';
  const string = stringOrNull ?? EMPTY;
  return string
    .replace(/[\\+]{2,}|\s+/g, '-') // multiple plus or one or more spaces -> dash
    .replace(/&amp;|[.'’‘"“”`|~+[\](){}\\/$,:;=?@#%…˜∽*^◦•_]/g, '') // special character (including single plus) or HTML ampersand -> empty
    .replace(/-+/g, DASH) // multiple dashes -> dash
    .replace(/^-+|-+$/, EMPTY) // leading or trailing dashes -> empty
    .toLowerCase(); // lower-case
};

module.exports = {
  calculateSlug,
};
