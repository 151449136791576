import { requestWrapper } from './reusable';

const config = require('../../config')();

export const checkIngestStatus = async (token, queryType, identifiers, region) => {
  const identifiersLabel = {
    FOUR_PART_ID: 'fourPartIds',
    ORDER_ID: 'orderIds',
    TRANSACTION_ID: 'transactionIds',
  }[queryType];
  return requestWrapper(
    token,
    config.transactionAnalyzerV2Resource,
    'post',
    undefined,
    {
      operation: 'CHECK_INGEST_STATUS',
      region,
      request: {
        [identifiersLabel]: identifiers,
        queryType,
      },
    },
  );
};

export const ingestReplay = async (token, transactionIds, region) => requestWrapper(
  token,
  config.transactionAnalyzerV2Resource,
  'post',
  undefined,
  {
    operation: 'INGEST_REPLAY',
    region,
    request: transactionIds,
  },
);
