import PropTypes from 'prop-types';
import React from 'react';
import Table from 'react-table';

import localizedLanguages from '../../../utils/static/localizedLanguages';
import { ButtonBlack, ButtonRed } from '../../reusable';

const DEFAULT_TABLE_SIZE = 5;
const localizationIsExpired = (localizationDate, zoneDate) => new Date(localizationDate) < new Date(zoneDate);

const LocalizationTable = (props) => {
  const localizations = props.localizations.filter((localization) => localization.language !== 'default');
  return localizations.length === 0
    ? <p className="body-2">No Zone Localizations exist for this Zone.</p>
    : (
      <Table
        key={props.localizations.length}
        className="-striped -highlight"
        columns={[
          {
            accessor: (d) => localizedLanguages[d.language],
            Cell: (d) => localizedLanguages[d.original.language],
            Header: 'Language',
            id: 'language',
          },
          {
            accessor: 'zoneName',
            Cell: (d) => d.original.zoneName,
            Header: 'Zone Name',
          },
          {
            accessor: 'zoneDescription',
            Cell: (d) => d.original.zoneDescription,
            Header: 'Zone Description',
          },
          {
            Cell: (d) => <ButtonBlack className="" label="Edit" onClick={() => props.localizationEdit(d.original, d.index)} />,
            Header: 'Edit',
            width: 80,
          },
          {
            Cell: (d) => <ButtonRed className="" label="Delete" onClick={() => props.localizationDelete(d.original, localizedLanguages[d.original.language], d.index)} />,
            Header: 'Delete',
            width: 100,
          },
          {
            accessor: 'modificationDate',
            Cell: (d) => (
              <p className={localizationIsExpired(d.original.modificationDate, props.zoneLastModified) ? 'text-color-error' : ''}>
                {d.original.modificationDate.split(/[T|.]/).slice(0, 2).join('  ')}
                {localizationIsExpired(d.original.modificationDate, props.zoneLastModified) ? ' (Out of Date)' : ''}
              </p>
            ),
            Header: 'Last Modified',
          },
        ]}
        data={localizations}
        getTdProps={() => ({
          style: {
            display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowX: 'auto', whiteSpace: 'unset',
          },
        })}
        getTrProps={(state, row) => ((row && row.row)
          ? ({ style: { background: row.index === props.editingIndex ? '#FA5400' : '', color: row.index === props.editingIndex ? 'white' : 'black' } })
          : ({})
        )}
        pageSize={(localizations.length > DEFAULT_TABLE_SIZE) ? DEFAULT_TABLE_SIZE : localizations.length}
        showPagination={localizations.length > DEFAULT_TABLE_SIZE}
      />
    );
};

LocalizationTable.defaultProps = {
  editingIndex: -1,
  zoneLastModified: '',
};

LocalizationTable.propTypes = {
  editingIndex: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  localizationDelete: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  localizationEdit: PropTypes.func.isRequired,
  localizations: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  zoneLastModified: PropTypes.string,
};

export default LocalizationTable;
