import { Button, Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

// to be used for all generic buttons

const ButtonBlack = ({
  className, isDisabled, isLoading, label, onAway, onClick, onHover,
}) => (isLoading
  ? <Loading />
  : (
    <Button
      className={`ncss-btn-primary-dark border ${className}`}
      isDisabled={isDisabled}
      style={{ flexDirection: 'row', outline: 'none', verticalAlign: 'middle' }}
      onClick={onClick}
      onMouseLeave={onAway}
      onMouseOver={onHover}
    >
      {label}
    </Button>
  )
);

ButtonBlack.defaultProps = {
  className: 'mt10-sm',
  isDisabled: false,
  isLoading: false,
  onAway: () => {},
  onHover: () => {},
};

ButtonBlack.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  onAway: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func,
};

export default ButtonBlack;
