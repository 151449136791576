import { Skeleton } from '@nike/eds';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { StoreHierarchyContext } from '../../StoreHierarchyContextProvider';

const HierarchyColumn = ({ component }) => {
  const { isPageLoading } = useContext(StoreHierarchyContext);
  return (
    <div className="hierarchy-column eds-flex" style={{ gap: '2px' }}>
      {
        isPageLoading
          ? <Skeleton height="100%" style={{ margin: '0 auto' }} width="100%" />
          : component
      }
    </div>
  );
};

HierarchyColumn.propTypes = {
  component: PropTypes.node.isRequired,
};

export default HierarchyColumn;
