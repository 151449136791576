import { Loading } from '@nike/frame-component-library';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';

import { getCampaignsList } from '../../../utils/service-calls/qrcodegenerator';
import { ButtonSubmit } from '../../reusable';

import * as constants from './constants';
import manageCampaignColumns from './manage-campaign-columns';
import './ManageCampaign.css';

// Table Styles
const tableStyles = {
  border: 'none',
  boxShadow: 'none',
  textAlign: 'left',
};

const ManageCampaign = ({
  getTableDataCellProps, getTableRowProps, handleAddCampaignClick, location,
}) => {
  const accessToken = useSelector((state) => state.authorizationReducer.auth.accessToken);
  const [campaignRecords, setCampaignRecords] = useState([]);
  const [isCallFailed, setIsCallFailed] = useState(false);
  const [isCallPending, setIsCallPending] = useState(true);
  const [successMessage, setSuccesMessage] = useState('');
  const DELAY = 3;
  const timer = useRef(null);
  const INTIAL_CAMPAIGN_NUMBER = 999;
  const numberOfrecordsPerPage = 20;

  useEffect(() => {
    const shopTheLookCampaign = { campaignDescription: 'This process will create upto 100 unique QR codes for Shop The Look signage.', campaignId: '5b03109e551b9', campaignName: 'Shop The Look' };
    const nikeAppCampaign = { campaignDescription: 'This process will create one QR code that will take the user to the App Store to download the Nike App.', campaignId: '5a7b29d44ccc2', campaignName: 'Nike App' };

    getCampaignsList(accessToken)
      .then((data) => {
        setIsCallPending(false);
        setIsCallFailed(false);
        let resp = (data && data.body && data.body.Items) || [];
        // Filtering the Active Records
        const activeCampaigns = resp.filter((key) => key.campaignStatus !== 'INACTIVE');
        // Sorting the records based on date and timestamp
        let sortedCampaigns = activeCampaigns.sort((campaignA, campaignB) => {
          const date1 = moment(campaignA.createdOn);
          const date2 = moment(campaignB.createdOn);
          return moment(date2).diff(date1);
        });
        // Adding existing campaign records to active records
        sortedCampaigns = [...sortedCampaigns, shopTheLookCampaign, nikeAppCampaign];
        // Total campaigns
        const totalCampaigns = sortedCampaigns.length + INTIAL_CAMPAIGN_NUMBER;
        // Adding Campaign Number to the records
        resp = sortedCampaigns.map((campaign, index) => ({ ...campaign, campaignNo: (totalCampaigns - index) }));
        if (resp && location.state === 'Delete') {
          setSuccesMessage(constants.DELETE_CAMPAIGN_SUCCESS);
        } else if (resp && location.state === 'Create') {
          setSuccesMessage(constants.SAVE_CAMPAIGN_SUCCESS);
        }
        timer.current = setTimeout(() => setSuccesMessage(''), DELAY * 1000);
        return setCampaignRecords(resp);
      })
      .catch(() => {
        setIsCallPending(false);
        setIsCallFailed(true);
        return setCampaignRecords([]);
      });
    return () => {
      clearTimeout(timer);
    };
  }, [accessToken, location]);

  return (
    <section>
      <article className="ncss-row">
        {successMessage && (
        <section className="ncss-col-sm-7 va-sm-m">
          <section className="success-message">{successMessage}</section>
        </section>
        )}
        <section className={successMessage ? 'ncss-col-sm-5 ta-sm-c' : 'ncss-col-sm-8 ta-sm-r'}>
          <Link to="/qrcodegenerator/createcampaign">
            <ButtonSubmit
              className="ncss-col-sm-4 ta-sm-r"
              id="add-campaign"
              label="Add Campaign"
              onClick={handleAddCampaignClick}
            />
          </Link>
        </section>
      </article>
      {isCallPending && (
      <section className="ncss-col-sm-7 ta-sm-c">
        <Loading />
        {constants.GETTING_CAMPAIGNS_LIST}
      </section>
      )}
      {isCallFailed && <section className="text-color-error">{constants.SOMETHING_WENT_WRONG}</section>}
      <article className="ncss-row ta-sm-c">
        {campaignRecords && campaignRecords.length > 0
          ? (
            <section className="ncss-col-sm-12 ncss-col-lg-9 ncss-col-md-12 ncss-col-xl-7">
              <ReactTable
                className="body-3"
                columns={manageCampaignColumns}
                data={campaignRecords}
                defaultPageSize={campaignRecords.length}
                getTdProps={getTableDataCellProps}
                getTrProps={getTableRowProps}
                minRows={0}
                pageSize={campaignRecords.length > numberOfrecordsPerPage
                  ? numberOfrecordsPerPage
                  : campaignRecords.length}
                resizable={false}
                showPagination={campaignRecords.length > numberOfrecordsPerPage}
                style={tableStyles}
              />
            </section>
          )
          : (!isCallPending && !isCallFailed && (
            <section className="ncss-col-sm-12">
              <p className="text-color-error">{constants.NO_RECORDS_FOUND}</p>
            </section>
          )
          )}
      </article>
    </section>
  );
};

ManageCampaign.defaultProps = {
  getTableDataCellProps: () => ({
    style: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
  getTableRowProps: (rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          backgroundColor: '#FFFFFF',
          border: '1px solid #CFCFCF',
          borderRadius: '2px',
          boxSizing: 'border-box',
          height: '50px',
        },
      };
    }
    return {};
  },
  handleAddCampaignClick: () => null,
  location: {},
};

ManageCampaign.propTypes = {
  getTableDataCellProps: PropTypes.func,
  getTableRowProps: PropTypes.func,
  handleAddCampaignClick: PropTypes.func,
  location: PropTypes.shape(),
};

export default ManageCampaign;
