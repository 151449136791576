import { match, Default as _ } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

const JSONFormatter = ({ data }) => {
  if (data === null) { return null; }
  const partString = (string) => `${string},`;
  const partObject = (obj) => (
    <>
      {' {'}
      {Object.entries(obj).map(([key, value]) => (
        <section key={`${key}-${value}`} className="ta-sm-l ml4-sm">
          {`${key}: `}<JSONFormatter data={value} />
        </section>
      ))}
      {'},'}
    </>
  );
  const partArray = (array) => (
    <>
      [
      {array.map((value) => (
        <section key={value} className="ta-sm-l ml4-sm">
          <JSONFormatter data={value} />
        </section>
      ))}
      ],
    </>
  );
  return match(data)(
    [() => Array.isArray(data), partArray],
    [() => (typeof data === 'object') && !Array.isArray(data), partObject],
    [_, partString],
  );
};

JSONFormatter.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default JSONFormatter;
