import { SINGULAR_LONG_LINK } from '../../components/qr-code-generator/common/constants';

import { requestWrapper } from './reusable';

const config = require('../../config')();

const logQRResult = (token, body) => requestWrapper(
  token,
  config.qrCodeGeneratorResource,
  'post',
  undefined,
  body,
);

const saveDownLoadHistory = (token, body) => requestWrapper(
  token,
  config.qrCodeGeneratorHistory,
  'post',
  undefined,
  body,
);

const getDownLoadHistory = (token, campaignId) => requestWrapper(
  token,
  `${config.qrCodeGeneratorHistory}?campaignId=${campaignId}`,
  'get',
);

const getCampaignsList = (token) => requestWrapper(
  token,
  config.qrCodeGeneratorCampaigns,
  'get',
);

const createShortLinkFromBranch = (token, payload) => {
  const body = {
    longLink: `${SINGULAR_LONG_LINK}?_smtype=3&pcid=${payload.storeId}&pcn=${payload.pcn}&_dl=${encodeURIComponent(payload.deeplink_path)}`,
  };
  return requestWrapper(
    token,
    config.qrCodeShortenURL,
    'post',
    undefined,
    body,
  );
};

const createOrUpdateCampaign = (token, body, method) => requestWrapper(
  token,
  config.qrCodeGeneratorCampaigns,
  method,
  undefined,
  body,
);

const deleteCampaign = (token, body) => requestWrapper(
  token,
  config.qrCodeGeneratorCampaigns,
  'patch',
  undefined,
  body,
);

export {
  logQRResult,
  saveDownLoadHistory,
  getDownLoadHistory,
  getCampaignsList,
  createOrUpdateCampaign,
  deleteCampaign,
  createShortLinkFromBranch,
};
