import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

const ManagerName = (props) => (
  <section className="ncss-row">
    <header className="ncss-col-sm-12 headline-2">Store Manager</header>
    <article className="ncss-col-sm-3 va-sm-t">
      <Input
        errorMessage={!props.disableCmpFields && props.managerNameErrors}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="First Name"
        value={props.managerName.firstName || ''}
        onChange={({ target: { value } }) => props.updateManagerName('firstName', value)}
      />
    </article>
    <article className="ncss-col-sm-3 va-sm-t">
      <Input
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Alternate First Name"
        value={props.managerName.altFirstName || ''}
        onChange={({ target: { value } }) => props.updateManagerName('altFirstName', value)}
      />
    </article>
    <article className="ncss-col-sm-3 va-sm-t">
      <Input
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Middle Name"
        value={props.managerName.middleName || ''}
        onChange={({ target: { value } }) => props.updateManagerName('middleName', value)}
      />
    </article>
    <article className="ncss-col-sm-3 va-sm-t">
      <Input
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Middle Initial"
        value={props.managerName.middleInitial || ''}
        onChange={({ target: { value } }) => props.updateManagerName('middleInitial', value)}
      />
    </article>
    <article className="ncss-col-sm-3 va-sm-t">
      <Input
        errorMessage={!props.disableCmpFields && props.managerNameErrors}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Last Name"
        value={props.managerName.lastName || ''}
        onChange={({ target: { value } }) => props.updateManagerName('lastName', value)}
      />
    </article>
    <article className="ncss-col-sm-3 va-sm-t">
      <Input
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Alternate Last Name"
        value={props.managerName.altLastName || ''}
        onChange={({ target: { value } }) => props.updateManagerName('altLastName', value)}
      />
    </article>
    <article className="ncss-col-sm-3 va-sm-t">
      <Input
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Given Name"
        value={props.managerName.givenName || ''}
        onChange={({ target: { value } }) => props.updateManagerName('givenName', value)}
      />
    </article>
  </section>
);

ManagerName.propTypes = {
  disableCmpFields: PropTypes.bool.isRequired,
  managerName: PropTypes.shape().isRequired,
  managerNameErrors: PropTypes.string.isRequired,
  updateManagerName: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default ManagerName;
