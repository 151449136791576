import PropTypes from 'prop-types';
import React from 'react';

import { padStoreNumber } from '../../utils/formatting';
import { loadState } from '../../utils/local-storage';
import { WaitlinesPermissions } from '../../utils/tab-permissions';
import { PageTemplate, StoreSelect } from '../reusable';

import WaitlineCard from './WaitlineCard';

class Waitlines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      storeId: '',
    };
  }

  selectCountry = (selectedCountry) => this.setState({ selectedCountry, selectedStore: null });

  // intentional mismatch: d+w services expect a storeId query with a padded storeNumber
  selectStore = (selectedStore) => this.setState({ selectedStore, storeId: padStoreNumber(selectedStore.storeNumber) });

  render = () => (
    <PageTemplate
      auth={WaitlinesPermissions}
      description="Manage waitline queues for in-store customer services or experiences."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Waitlines+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-10 m2-sm">
          <StoreSelect
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
          />
          {this.state.selectedStore && this.state.selectedStore.storeNumber && (
            <article className="ncss-row">
              <section className="ncss-col-sm-6 va-sm-t">
                <WaitlineCard storeId={this.state.storeId} />
              </section>
            </article>
          )}
        </main>
      )}
      path={this.props.location.pathname}
      title="Waitlines"
    />
  );
}

Waitlines.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default Waitlines;
