{
    "lastA11yAudit": "07/08/22",
    "name": "Icon",
    "docs": "#/components/icons/Icon",
    "description": "Icon is how users utilize icon assets as well as give them a background shape and color.",
    "figma": "https://www.figma.com/file/WHGv51Q14Zeq8XZ3Y6UIT3/Podium-DS-Icon-Library?node-id=0%3A25",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Icon",
    "tags": ["Icon", "glyph", "symbol", "svg"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
