import {
 Button, ButtonGroup, TextField, TextGroup
} from '@nike/eds';
import {
 Check, Close, Delete
} from '@nike/nike-design-system-icons';
import { isSome } from '@nike/rcf-fp';
import React, {
 useContext, useEffect, useMemo, useState
} from 'react';

import { deleteNode, updateNode, createNode } from '../../services/hierarchy-service';
import { isChildOf } from '../../utils/hierarchy-utils';
import { userCanDeleteDistrict, userCanEditDistrict, userHasWritePermissionForRegion } from '../../utils/permissions';
import { isNotWhitespaceOrNil } from '../../utils/string-utils';
import { Modal, Select } from '../eds-custom';
import { StoreHierarchyContext } from '../StoreHierarchyContextProvider';

import AddNodeButton from './common/AddNodeButton';
import HierarchyColumnTitle from './common/HierarchyColumnTitle';
import HierarchyNode from './common/HierarchyNode';

export default () => {
  const {
    accessToken,
    selectedTerritory = {},
    hierarchyNodes = [],
    selectedDistrict,
    userGroups,
    hierarchyNodeLookupTable,
    districtStores,
    selectedRegion,
    setIsPageLoading,
    setHierarchyNodes,
    setError,
    setSuccessMessage,
    setActiveNodeId,
  } = useContext(StoreHierarchyContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalName, setEditModalName] = useState(selectedDistrict?.name ?? '');
  const [editModalParentId, setEditModalParentId] = useState(selectedDistrict?.parentId ?? '');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalName, setAddModalName] = useState('');

  // Update editModalName and newParentRegion when selectedTerritory changes
  useEffect(() => {
    setEditModalName(selectedDistrict?.name ?? '');
    setEditModalParentId(selectedDistrict?.parentId ?? '');
  }, [selectedDistrict, setEditModalName, setEditModalParentId]);

  // Reset addModalName and addModalParentId when isAddModalOpen changes
  useEffect(() => {
      setAddModalName('');
  }, [isAddModalOpen]);

  const territories = useMemo(() => hierarchyNodes.filter(isChildOf(selectedRegion)), [hierarchyNodes, selectedRegion]);
  const districts = useMemo(() => hierarchyNodes.filter(isChildOf(selectedTerritory)), [hierarchyNodes, selectedTerritory]);
  const parentSelectValue = useMemo((newParent = hierarchyNodeLookupTable?.[editModalParentId] ?? {}) => ({ label: newParent?.name, value: newParent?.id }), [hierarchyNodeLookupTable, editModalParentId]);
  const parentSelectOptions = useMemo(() => territories.map(({ id, name }) => ({ label: name, value: id })), [territories]);
  const isDirtyEditModalName = useMemo(() => editModalName !== selectedDistrict?.name, [editModalName, selectedDistrict]);
  const isDirtyEditModalParentId = useMemo(() => editModalParentId !== selectedDistrict?.parentId, [editModalParentId, selectedDistrict]);
  const isDirtyForEdit = useMemo(() => isDirtyEditModalName || isDirtyEditModalParentId, [isDirtyEditModalName, isDirtyEditModalParentId]);
  const isDuplicateNameForEdit = useMemo(() => hierarchyNodes.some((node) => node.name === editModalName
      && node.nodeType === 'DISTRICT'
      && node.parentId === editModalParentId
      && node.id !== selectedDistrict?.id),
    [hierarchyNodes, editModalName, editModalParentId, selectedDistrict]);
  const isValidNameForEdit = useMemo(() => !isDuplicateNameForEdit && isNotWhitespaceOrNil(editModalName), [isDuplicateNameForEdit, editModalName]);
  const isValidForEdit = useMemo(() => isValidNameForEdit && isNotWhitespaceOrNil(editModalParentId), [editModalParentId, isValidNameForEdit]);
  const DUPLICATE_NAME_ERROR_MESSAGE = 'A district with this name already exists.';
  const editModalNameErrorMessage = useMemo(() => (isDuplicateNameForEdit ? 'A district with this name already exists.' : ''), [isDuplicateNameForEdit]);
  const isDuplicateNameForAdd = useMemo(() => hierarchyNodes.some((node) => node.name === addModalName
      && node.nodeType === 'DISTRICT'
      && node.parentId === selectedTerritory?.id),
  [hierarchyNodes, addModalName, selectedTerritory]);
  const isValidForAdd = useMemo(() => isNotWhitespaceOrNil(addModalName) && !isDuplicateNameForAdd, [addModalName, isDuplicateNameForAdd]);
  const addModalErrorMessage = useMemo(() => (isDuplicateNameForAdd ? DUPLICATE_NAME_ERROR_MESSAGE : ''), [isDuplicateNameForAdd]);
  const actionComponent = useMemo(() => (
    isSome(selectedTerritory)
      && userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)
      && (<AddNodeButton label="Add District" onClick={() => setIsAddModalOpen(true)} />)
  ), [selectedTerritory, userGroups, hierarchyNodeLookupTable, selectedRegion]);

  return (
    <>
      {isEditModalOpen && (
        <Modal
          footerSlot={(
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!(isDirtyForEdit && isValidForEdit)}
                size="small"
                variant="primary"
                onClick={() => {
                  setIsPageLoading(true);
                  const updatedDistrict = { ...selectedDistrict, name: editModalName, parentId: editModalParentId };
                  // eslint-disable-next-line promise/catch-or-return
                    updateNode(updatedDistrict, accessToken)
                    .then((nodes) => {
                      setHierarchyNodes(nodes);
                      setActiveNodeId(updatedDistrict.id);
                      setSuccessMessage(`District ${updatedDistrict.name} updated.`);
                      return updatedDistrict;
                    })
                    .catch(setError)
                    .finally(() => setIsPageLoading(false));
              }}
              >Save
              </Button>
              <Button
                afterSlot={<Delete />}
                disabled={!userCanDeleteDistrict(userGroups, hierarchyNodeLookupTable, districtStores, selectedDistrict)}
                size="small"
                variant="secondary"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  const confirmDelete = window.confirm(`Delete district ${selectedDistrict?.name}?`);
                  if (confirmDelete) {
                    setIsPageLoading(true);
                    // eslint-disable-next-line promise/catch-or-return
                    deleteNode(selectedDistrict, accessToken)
                      .then((nodes) => {
                        setHierarchyNodes(nodes);
                        setActiveNodeId(selectedDistrict.parentId);
                        setSuccessMessage(`District ${selectedDistrict?.name} deleted.`);
                        return selectedDistrict;
                      })
                      .catch(setError)
                      .finally(() => setIsPageLoading(false));
                  }
              }}
              >Delete
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Edit District"
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
        >
          <TextGroup>
            <TextField
              errorMessage={editModalNameErrorMessage}
              hasErrors={isDuplicateNameForEdit}
              label="Name"
              value={editModalName}
              onChange={(e) => setEditModalName(e.target.value)}
            />
            <Select
              id="territory-select"
              label="Territory"
              options={parentSelectOptions}
              value={parentSelectValue}
              onChange={(e) => setEditModalParentId(e?.value)}
            />
          </TextGroup>
        </Modal>
      )}
      {isAddModalOpen && (
        <Modal
          footerSlot={(
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!isValidForAdd}
                size="small"
                variant="primary"
                onClick={() => {
                  setIsPageLoading(true);
                  const newDistrict = { name: addModalName, nodeType: 'DISTRICT', parentId: selectedTerritory?.id };
                  // eslint-disable-next-line promise/catch-or-return
                  createNode(newDistrict, accessToken)
                    .then((nodes) => {
                      setHierarchyNodes(nodes);
                      const savedNewDistrict = nodes.find((node) => node.name === newDistrict.name
                        && node.parentId === newDistrict.parentId
                        && node.nodeType === 'DISTRICT');
                      setActiveNodeId(savedNewDistrict.id);
                      setSuccessMessage(`District ${newDistrict.name} added.`);
                      return newDistrict;
                    })
                    .catch(setError)
                    .finally(() => {
                      setIsAddModalOpen(false);
                      setIsPageLoading(false);
                    });
                }}
              >Add
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Add District"
          isOpen={isAddModalOpen}
          onDismiss={() => setIsAddModalOpen(false)}
        >
          <TextGroup>
            <TextField
              errorMessage={addModalErrorMessage}
              hasErrors={isDuplicateNameForAdd}
              label="Name"
              value={addModalName}
              onChange={(e) => setAddModalName(e.target.value)}
            />
            <TextField
              disabled
              label="Territory"
              value={selectedTerritory?.name}
            />
          </TextGroup>
        </Modal>
      )}
      <HierarchyColumnTitle actionComponent={actionComponent} title="Districts" />
      {
      districts.map((district) => (
        <HierarchyNode
          key={district.id}
          hierarchyNode={district}
          isDeletable={userCanDeleteDistrict(userGroups, hierarchyNodeLookupTable, districtStores, district)}
          isEditable={userCanEditDistrict(userGroups, hierarchyNodeLookupTable, district)}
          isSelected={district.id === selectedDistrict?.id}
          onClick={() => setActiveNodeId(district.id)}
          onEdit={() => setIsEditModalOpen(true)}
        />
      ))
     }
    </>
  );
};
