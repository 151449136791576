import { Loading } from '@nike/frame-component-library';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { HMStoISO, ISOtoHMS, parseSLSandBrickworksErrors } from '../../../utils/formatting';
import { sfsGet, sfsPost, sfsPut } from '../../../utils/service-calls/o2o-capabilities';
import { putStore, getStoreById } from '../../../utils/service-calls/sls';
import { ButtonSubmit } from '../../reusable';

import {
  carrierPickupTimes, daysOfTheWeek, defaultCapacity, defaultCapacityException, defaultCarrierPickupTime, sfs,
} from './constants';
import FulfillmentCapacitiesSFS from './FulfillmentCapacitiesSFS';
import StoresSFS from './StoresSFS';

const ShipFromStore = (props) => {
  const accessToken = useSelector((state) => state.authorizationReducer.auth.accessToken);
  const [carrierPickupLeadTimeData, setCarrierPickupLeadTimeData] = useState(null);
  const [checked, setChecked] = useState(false);
  const [exceptionDatesOverlap, setExceptionDatesOverLap] = useState(false);
  const [fcSaveError, setFcSaveError] = useState('');
  const [fetched, setFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fulfillmentCapacities, setFulfillmentCapacities] = useState(null);
  const [fulfillmentCapacitiesError, setFulfillmentCapacitiesError] = useState('');
  const [isOpen, setIsOpen] = useState({});
  const [leadTimeData, setLeadTimeData] = useState({});
  const [originalChecked, setOriginalChecked] = useState(false);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [storeServices, setStoreServices] = useState([]);
  const [storesError, setStoresError] = useState('');
  const [storesSaveError, setStoresSaveError] = useState('');
  const [storesStoreInfo, setStoresStoreInfo] = useState(null);

  const dateRangeOverlaps = (aStart, aEnd, bStart, bEnd) => (aStart <= bStart && bStart <= aEnd) || (aStart <= bEnd && bEnd <= aEnd) || (bStart < aStart && aEnd < bEnd);

  const multipleDateRangeOverlaps = (ranges) => {
    let i = 0; let j = 0;
    const dateRanges = ranges.filter((range) => range.from && range.to);

    if (dateRanges && dateRanges.length > 1) {
      for (i = 0; i < dateRanges.length - 1; i += 1) {
        for (j = i + 1; j < dateRanges.length; j += 1) {
          if (
            dateRangeOverlaps(
              dateRanges[i].from, dateRanges[i].to,
              dateRanges[j].from, dateRanges[j].to,
            )
          ) return true;
        }
      }
    }
    return false;
  };

  /* Fulfillment Capacities functions */
  const addCapacityException = () => {
    const newFulfillmentCapacities = cloneDeep(fulfillmentCapacities);
    newFulfillmentCapacities.capacityExceptions = newFulfillmentCapacities.capacityExceptions
      ? [...newFulfillmentCapacities.capacityExceptions, ...[defaultCapacityException]]
      : [defaultCapacityException];
    setExceptionDatesOverLap(multipleDateRangeOverlaps(newFulfillmentCapacities.capacityExceptions));
    setFulfillmentCapacities(newFulfillmentCapacities);
  };

  // find the index of SFS within the storeServices array
  const addCarrierPickupTime = (day) => {
    const newFulfillmentCapacities = cloneDeep(fulfillmentCapacities);
    newFulfillmentCapacities.carrierPickupTimes[day] = [...newFulfillmentCapacities.carrierPickupTimes[day], ...[defaultCarrierPickupTime]];
    setFulfillmentCapacities(newFulfillmentCapacities);
  };

  const deleteCapacityException = (indexToDelete) => {
    const newFulfillmentCapacities = cloneDeep(fulfillmentCapacities);
    newFulfillmentCapacities.capacityExceptions.splice(indexToDelete, 1);
    setExceptionDatesOverLap(multipleDateRangeOverlaps(newFulfillmentCapacities.capacityExceptions));
    setFulfillmentCapacities(newFulfillmentCapacities);
  };

  const deleteCarrierPickupTime = (day, indexToDelete) => {
    const newFulfillmentCapacities = cloneDeep(fulfillmentCapacities);
    newFulfillmentCapacities.carrierPickupTimes[day].splice(indexToDelete, 1);
    setFulfillmentCapacities(newFulfillmentCapacities);
  };

  // if SFS does not exist, return the index where SFS should be added (the length of the array)
  const findIndex = (services) => {
    const index = services.findIndex((service) => service.storeServiceTypeId === sfs.storeServiceTypeId);
    return index >= 0 ? index : services.length || 0;
  };

  useEffect(() => {
    if (fulfillmentCapacities && !storesError && !fulfillmentCapacitiesError) {
      setFetched(true);
      setFetching(false);
    }
  }, [fulfillmentCapacities, storesError, fulfillmentCapacitiesError]);

  useEffect(() => {
    if (!(accessToken && props.selectedStore.id)) { return; }
    const isServiceEnabled = (services) => {
      const i = findIndex(services);
      return !!services[i]?.enabled;
    };
    setFetching(true);

    const getStoreInfo = () => getStoreById(accessToken, props.selectedStore.id)
      .then((response) => {
        setChecked(isServiceEnabled(response.body.storeServices));
        setOriginalChecked(isServiceEnabled(response.body.storeServices));
        setStoresError('');
        setStoreServices(response.body.storeServices);
        return setStoresStoreInfo(response.body);
      })
      .catch((error) => {
        setStoresError(error.message);
      });

    sfsGet(accessToken, props.selectedStore.id)
      .then((res) => {
        const newLeadTimeData = ISOtoHMS(res.body.objects[0]?.defaultLeadTimeDuration || 'PT2H');
        setLeadTimeData(newLeadTimeData);
        setCarrierPickupLeadTimeData({
          friday: (res.body.objects[0]?.carrierPickupTimes?.friday || []).map((pickup) => ISOtoHMS(pickup.leadTimeDuration)),
          monday: (res.body.objects[0]?.carrierPickupTimes?.monday || []).map((pickup) => ISOtoHMS(pickup.leadTimeDuration)),
          saturday: (res.body.objects[0]?.carrierPickupTimes?.saturday || []).map((pickup) => ISOtoHMS(pickup.leadTimeDuration)),
          sunday: (res.body.objects[0]?.carrierPickupTimes?.sunday || []).map((pickup) => ISOtoHMS(pickup.leadTimeDuration)),
          thursday: (res.body.objects[0]?.carrierPickupTimes?.thursday || []).map((pickup) => ISOtoHMS(pickup.leadTimeDuration)),
          tuesday: (res.body.objects[0]?.carrierPickupTimes?.tuesday || []).map((pickup) => ISOtoHMS(pickup.leadTimeDuration)),
          wednesday: (res.body.objects[0]?.carrierPickupTimes?.wednesday || []).map((pickup) => ISOtoHMS(pickup.leadTimeDuration)),
        });
        setFulfillmentCapacities(
          {
            ...res.body.objects[0],
            // initialize the request body since these values are required, but users can submit the default values
            // but in case we are editing, be sure to overwrite those default values with any data that is in the get response
            carrierPickupTimes: res.body.objects[0]?.carrierPickupTimes || carrierPickupTimes,
            defaultCapacity: res.body.objects[0]?.defaultCapacity || defaultCapacity,
            defaultLeadTimeDuration: HMStoISO(newLeadTimeData.hours, newLeadTimeData.minutes, newLeadTimeData.seconds),
          },
        );
        return setFulfillmentCapacitiesError('');
      })
      .catch((err) => {
        setCarrierPickupLeadTimeData({});
        setFulfillmentCapacities(null);
        setFulfillmentCapacitiesError(err.message);
        setLeadTimeData({});
      });
    getStoreInfo();
  }, [accessToken, props.selectedStore]);

  const isSubmitDisabled = () => (
    // disable if we are missing the hours minutes or seconds that make up defaultLeadTimeDuration
    !leadTimeData.hours || !leadTimeData.minutes || !leadTimeData.seconds
    // disable if capacityExceptions exist and do not have form or to values (or they are in a bad order)
    || (fulfillmentCapacities.capacityExceptions || [])
      .some((exception) => !exception.from || !exception.to || (exception.from > exception.to))
    // disable if capacityExceptions are overlapping
    || exceptionDatesOverlap
    // disable if carrierPickupTimes exist and...
    || daysOfTheWeek.map((day) => fulfillmentCapacities.carrierPickupTimes[day]
      // ...we do not have a pickupTime or carrierCode value
      .some((pickup, i) => !pickup.pickupTime || !pickup.carrierCode
        // ...and IF leadTimeDuraction exists, disable if we are missing the hours minutes or seconds that it is made of
        || (pickup.leadTimeDuration && (
          !carrierPickupLeadTimeData[day][i].hours
          || !carrierPickupLeadTimeData[day][i].minutes
          || !carrierPickupLeadTimeData[day][i].seconds))))
      .some((check) => check)
    // we are not checking for any maxUnits, because those are set so that they can never be empty
  );

  const updateStoreServices = () => {
    const newStoreServices = cloneDeep(storeServices);
    const i = findIndex(newStoreServices);
    if (newStoreServices[i]) {
      newStoreServices[i].enabled = checked;
    } else {
      newStoreServices[i] = {
        // Populate new store service with default sfs object and sfs enabled flag
        ...sfs,
        enabled: checked,
      };
    }

    return newStoreServices;
  };

  const onSubmit = async () => {
    setSaving(true);
    const newStoreServices = updateStoreServices();
    const storesData = {
      new: { ...storesStoreInfo, storeServices: newStoreServices },
      old: { ...storesStoreInfo },
    };

    await putStore(accessToken, storesData)
      .then(() => {
        setStoreServices(newStoreServices);
        return setStoresSaveError('');
      })
      .catch((error) => {
        const err = parseSLSandBrickworksErrors(error, true);
        if (err.timeout) {
          setStoresSaveError('SLS Error: The request timed out. Please try again.');
        } else if (err.brickworks) {
          // ignore brickworks errors and since stores was successful, tell the user the update was successful
          setStoreServices(newStoreServices);
          setStoresSaveError('');
        } else {
          setStoresSaveError(`SLS Error: ${err.message}`);
        }
      });

    return fulfillmentCapacities.id
      ? sfsPut(accessToken, props.selectedStore.id, fulfillmentCapacities, fulfillmentCapacities.id)
        .then(() => setFcSaveError(''))
        .catch((err) => setFcSaveError(`Fulfillment Capacities Error: ${err.message.includes('res body: ') ? err.response.body.split('res body: ')[1] : err.message}`))
        .finally(() => {
          setSaved(true);
          setSaving(false);
        })
      : sfsPost(accessToken, props.selectedStore.id, fulfillmentCapacities)
        .then(() => setFcSaveError(''))
        .catch((err) => setFcSaveError(`Fulfillment Capacities Error: ${err.message.includes('res body: ') ? err.response.body.split('res body: ')[1] : err.message}`))
        .finally(() => {
          setSaved(true);
          setSaving(false);
        });
  };

  const panelToggle = (day) => setIsOpen({ ...isOpen, [day]: !isOpen[day] });

  const updateFulfillmentCapacities = (key, value, arrayIndex = -1) => {
    const newFullfillmentCapacities = cloneDeep(fulfillmentCapacities);
    if (arrayIndex >= 0) {
      if (key.includes('.')) {
        // if we have a nested prop for an array (example: carrierPickupTimes.monday[o])
        const [parent, child] = key.split('.');
        newFullfillmentCapacities[parent][child][arrayIndex] = value;
      } else {
        newFullfillmentCapacities[key][arrayIndex] = value;
      }
    } else {
      newFullfillmentCapacities[key] = value;
    }
    setExceptionDatesOverLap((key === 'capacityExceptions') ? multipleDateRangeOverlaps(newFullfillmentCapacities.capacityExceptions) : false);
    setFcSaveError('');
    setFulfillmentCapacities(newFullfillmentCapacities);
    setSaved(false);
    setStoresSaveError('');
  };

  const updateCarrierPickupLeadTime = (day, i, hours, minutes, seconds, remove = false) => {
    const newCarrierPickupLeadTimeData = cloneDeep(carrierPickupLeadTimeData);
    newCarrierPickupLeadTimeData[day][i] = { hours, minutes, seconds };
    setCarrierPickupLeadTimeData(newCarrierPickupLeadTimeData);
    updateFulfillmentCapacities(`carrierPickupTimes.${day}`, {
      ...fulfillmentCapacities.carrierPickupTimes[day][i],
      leadTimeDuration: remove ? undefined : HMStoISO(hours, minutes, seconds),
    }, i);
  };

  const updateLeadTime = (hours, minutes, seconds) => {
    setLeadTimeData({ hours, minutes, seconds });
    updateFulfillmentCapacities('defaultLeadTimeDuration', HMStoISO(hours, minutes, seconds));
  };

  return (
    <main>
      {storesError === fulfillmentCapacitiesError
        ? <aside className="text-color-error body-4 mt2-sm">{storesError}</aside>
        : <aside className="text-color-error body-4 mt2-sm">{storesError}<br />{fulfillmentCapacitiesError}</aside>}
      {fetching && <Loading />}
      {fetched && (
        <article className="ncss-col-sm-10 ta-s-c mt8-sm mb10-sm">
          <StoresSFS
            checked={checked}
            originalChecked={originalChecked}
            store={props.selectedStore}
            updateCheckBox={(update) => {
              setChecked(update);
              setFcSaveError('');
              setSaved(false);
              setStoresSaveError('');
            }}
          />
          <FulfillmentCapacitiesSFS
            addCapacityException={addCapacityException}
            addCarrierPickupTime={addCarrierPickupTime}
            carrierPickupLeadTimeData={carrierPickupLeadTimeData}
            deleteCapacityException={deleteCapacityException}
            deleteCarrierPickupTime={deleteCarrierPickupTime}
            exceptionDatesOverlap={exceptionDatesOverlap}
            fulfillmentCapacities={fulfillmentCapacities}
            isOpen={isOpen}
            leadTimeData={leadTimeData}
            panelToggle={panelToggle}
            updateCarrierPickupLeadTime={updateCarrierPickupLeadTime}
            updateFulfillmentCapacities={updateFulfillmentCapacities}
            updateLeadTime={updateLeadTime}
          />
          <ButtonSubmit
            isDisabled={isSubmitDisabled()}
            isLoading={saving}
            label="Save Fulfillment Option"
            submitError={storesSaveError || fcSaveError}
            onClick={onSubmit}
          />
          {saved && !(storesSaveError || fcSaveError)
            && <p className="text-color-success">Ship From Store options saved successfully! Please refresh the page to see updates.</p>}
        </article>
      )}
    </main>
  );
};

ShipFromStore.propTypes = {
  selectedStore: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
};

export default ShipFromStore;
