{
    "lastA11yAudit": "07/08/22",
    "name": "Avatar",
    "description": "Avatar is a graphical representation of a user: usually a photo, illustration or initial.",
    "docs": "#/components/Avatar",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Avatar",
    "figma": "https://www.figma.com/proto/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=577%3A23757&scaling=contain&page-id=13%3A46",
    "tags": ["Avatar", "dropdown", "Profile", "User"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
