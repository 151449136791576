import { Ellipsis } from '@nike/nike-design-system-icons';
import React from 'react';
import { Link } from 'react-router-dom';

const campaignIdStyles = () => ({
  style: {
    border: 'none',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '17px',
    whiteSpace: 'unset',
  },
});

const campaignNameStyles = () => ({
  style: {
    border: 'none',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '17px',
    paddingBottom: '10px',
    paddingTop: '10px',
    whiteSpace: 'unset',
  },
});

// Header Styles
const headerStyles = {
  border: 'none',
  marginBottom: '10px',
  marginTop: '10px',
  textAlign: 'left',
};

const manageCampaignColumns = [{
  accessor: 'campaignNo',
  getProps: campaignIdStyles,
  Header: 'Campaign No.',
  headerStyle: headerStyles,
  maxWidth: 150,
},
{
  accessor: 'campaignName',
  getProps: campaignNameStyles,
  Header: 'Campaign Name',
  headerStyle: headerStyles,
  maxWidth: 250,
},
{
  accessor: 'campaignDescription',
  getProps: () => ({
    style: {
      border: 'none',
      whiteSpace: 'unset',
    },
  }),
  Header: 'Campaign Description',
  headerStyle: headerStyles,
  maxWidth: 600,
},
{
  Cell: (data) => {
    const disableMode = data.original.campaignId === '5b03109e551b9' || data.original.campaignId === '5a7b29d44ccc2';
    return (
      <section className="popup">
        <Ellipsis
          style={{
            color: '#BDC3C7',
            cursor: 'pointer',
            height: '18px',
            width: '18px',
          }}
          title="Options"
          onClick={() => null}
        />
        <section className="popup-content">
          <ul>
            {!disableMode
            && (
            <Link to={{ pathname: `/qrcodegenerator/createcampaign`, state: { campaignData: data.original, editCampaign: true } }}>
              <li><span>Edit</span></li>
            </Link>
            )}
            <Link to={{ pathname: `/qrcodegenerator/history`, state: { campaignId: data.original.campaignId, campaignName: data.original.campaignName, from: 'manageCampaigns' } }}>
              <li><span>History</span></li>
            </Link>
          </ul>
        </section>
      </section>
    );
  },
  maxWidth: 50,
},
];
export default manageCampaignColumns;
