export const DELETE_CAMPAIGN_PENDING = 'Please wait. Deleting campaign details...';
export const DELETE_CAMPAIGN_SUCCESS = 'Your campaign deleted succesfully';

export const SAVE_CAMPAIGN_PENDING = 'Please wait. Saving details...';
export const SAVE_CAMPAIGN_SUCCESS = 'Your campaign has been created';

export const GETTING_CAMPAIGNS_LIST = 'Getting the campaigns list. Please wait...';
export const GETTING_HISTORY_LIST = 'Getting the history. Please wait...';

export const SELECT_CAMPAIGN_ID = 'Please select a campaign to view its history.';

export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later';
export const NO_RECORDS_FOUND = 'No Records Found';

export const PATH_NAME = '/qrcodegenerator/managecampaign';
