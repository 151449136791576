import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel } from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || (properties.formErrors.sellingArea
    ? (!!properties.formErrors.sellingArea.grossSize || !!properties.formErrors.sellingArea.netSize)
    : false)); // false because this object is optional

class SellingArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Selling Area"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-4 va-sm-t">
        <Input
          errorMessage={!this.props.disableCmpFields && this.props.formErrors.sellingArea.grossSize}
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Gross Selling Area"
          type="number"
          value={this.props.formData.sellingArea?.grossSize || ''}
          onChange={({ target: { value } }) => this.props.updateSellingArea('grossSize', value)}
        />
      </article>
      <article className="ncss-col-sm-4 va-sm-t">
        <Input
          errorMessage={!this.props.disableCmpFields && this.props.formErrors.sellingArea.netSize}
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Net Selling Area"
          type="number"
          value={this.props.formData.sellingArea?.netSize || ''}
          onChange={({ target: { value } }) => this.props.updateSellingArea('netSize', value)}
        />
      </article>
      <article className="ncss-col-sm-4 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Measurement Units"
          value={this.props.formData.sellingArea?.unitOfMeasure || ''}
          onChange={({ target: { value } }) => this.props.updateSellingArea('unitOfMeasure', value)}
        />
      </article>
    </CustomPanel>
  );
}

SellingArea.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateSellingArea: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default SellingArea;
