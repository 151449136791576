import PropTypes from 'prop-types';
import React from 'react';
import {
  Tabs, Tab, TabList, TabPanel,
} from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

import CsvForm from '../forms/CsvForm';
import NormalForm from '../forms/NormalForm';

const FormCsvTab = (props) => (
  <article className="ncss-col-sm-10 va-sm-b ta-sm-c mt5-sm">
    <Tabs onSelect={(index) => props.handleUploadTypeChange(index === 1)}>
      <TabList>
        <Tab>Form</Tab>
        <Tab>CSV</Tab>
      </TabList>
      <TabPanel>
        <NormalForm
          codes={props.codes}
          couponDiscountEnabled={props.couponDiscountEnabled}
          employeeDiscountCode={props.employeeDiscountCode}
          formErrors={props.formErrors}
          handleCodeChange={props.handleCodeChange}
          handleFormChange={props.handleFormChange}
          id={props.id}
          itemDiscountCode={props.itemDiscountCode}
          priceOverrideEnabled={props.priceOverrideEnabled}
          promotionEnabled={props.promotionEnabled}
          thresholdDiscountCode={props.thresholdDiscountCode}
          transactionDiscountCode={props.transactionDiscountCode}
        />
      </TabPanel>
      <TabPanel>
        <CsvForm
          formErrors={props.formErrors}
          handleFileUpload={props.handleFileUpload}
        />
      </TabPanel>
    </Tabs>
  </article>
);

FormCsvTab.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  couponDiscountEnabled: PropTypes.string.isRequired,
  employeeDiscountCode: PropTypes.string.isRequired,
  formErrors: PropTypes.shape().isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleUploadTypeChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  itemDiscountCode: PropTypes.string.isRequired,
  priceOverrideEnabled: PropTypes.string.isRequired,
  promotionEnabled: PropTypes.string.isRequired,
  thresholdDiscountCode: PropTypes.string.isRequired,
  transactionDiscountCode: PropTypes.string.isRequired,
};

export default FormCsvTab;
