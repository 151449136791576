{
    "lastA11yAudit": "",
    "name": "Alert",
    "description": "A inline messaging component for section and feature level communication. Can be used to display important information, alerts, or calls to action.",
    "docs": "#/components/Alert",
    "github": "",
    "figma": "",
    "tags": ["banner", "card"],
    "designStatuses": ["In Development"],
    "devStatuses": ["In Development"]
}
