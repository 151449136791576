{
    "lastA11yAudit": "07/08/22",
    "name": "Toggle",
    "docs": "#/components/forms/Toggle",
    "description": "Toggle is a binary selector indicating an on or off state.",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Toggle",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=48848%3A348383",
    "tags": ["input", "form", "forms", "switch", "lightswitch", "pill"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
