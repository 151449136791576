import countries from 'i18n-iso-countries';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { addAuth } from '../actions/authorization';
import { getCountry } from '../utils/service-calls/google';

class Authorized extends React.Component {
  constructor(props) {
    super(props);
    const token = qs.parse(window.location.hash.substring(1));
    const accessValues = token.access_token ? token.access_token.split('.') : [];
    const openIdValues = token.id_token ? token.id_token.split('.') : [];
    const accessClaims = accessValues.length > 0 ? JSON.parse(window.atob((accessValues[1]))) : {};
    const openIdClaims = openIdValues.length > 0 ? JSON.parse(window.atob((openIdValues[1]))) : {};

    this.state = {
      auth: {
        accessToken: token.access_token,
        email: openIdClaims.email,
        expiresAt: accessClaims.exp,
        expiresIn: token.expires_in,
        groups: accessClaims.groups,
        name: openIdClaims.name,
        openIdToken: token.id_token,
        scope: accessClaims.scp,
        token,
        username: accessClaims.sub,
      },
    };

    this.setAuth();
  }

  setAuth = async () => navigator.geolocation?.getCurrentPosition(async (pos) => getCountry(pos.coords)
    .then((res) => {
      // set the user's country to be the address_component of type country, converted into the three-letter country code
      const country = countries.alpha2ToAlpha3(res.results[0].address_components.find((component) => component.types.includes('country')).short_name);
      return this.props.addAuth({ ...this.state.auth, country: { label: countries.getName(country, 'en'), value: country } });
    })
    // if google's getCountry function fails, use USA as country
    .catch(() => this.props.addAuth({ ...this.state.auth, country: { label: countries.getName('USA', 'en'), value: 'USA' } })),
  // if getCurrentPosition fails, use USA as country
  () => this.props.addAuth({ ...this.state.auth, country: { label: countries.getName('USA', 'en'), value: 'USA' } }))
  // if user has not yet accepted us to use their location, use USA as country (next time they visit we will use their actual location)
  || this.props.addAuth({ ...this.state.auth, country: { label: countries.getName('USA', 'en'), value: 'USA' } });

  render = () => (
    <>
      {this.state.auth.accessToken && this.state.auth.openIdToken
        ? <Redirect to={{ pathname: this.state.auth.token.state }} />
        : <Redirect to={{ pathname: 'unauthorized' }} />}
    </>
  );
}

Authorized.propTypes = {
  addAuth: PropTypes.func.isRequired,
};

export default connect(null, { addAuth })(Authorized);
