import {
  Consumer, Fn, Maybe, Supplier,
} from '../index.js';

class Either<L, R> {
  private m_isRight: boolean;

  private m_value: L | R;

  constructor(isRight: boolean, value: L | R) {
    this.m_isRight = isRight;
    this.m_value = value;
  }

  isRight(): boolean {
    return this.m_isRight;
  }

  isLeft(): boolean {
    return !this.isRight();
  }

  getRight(): R | never {
    if (this.isRight()) {
      return this.m_value as R;
    }

    throw new Error('Expected Right type');
  }

  get(): R | never {
    return this.getRight();
  }

  getLeft(): L | never {
    if (this.isLeft()) {
      return this.m_value as L;
    }

    throw new Error('Expected Left type');
  }

  toMaybe(): Maybe<R> {
    return this.isRight()
      ? Maybe.of(this.m_value as R)
      : Maybe.none();
  }

  map<S>(f: Fn<R, S>): Either<L, S> | Either<L, R> {
    return this.isRight()
      ? Either.right(f(this.m_value as R))
      : this;
  }

  mapLeft<M>(f: Fn<L, M>): Either<L, R> | Either<M, R> {
    return this.isLeft()
      ? Either.left(f(this.m_value as L))
      : this;
  }

  flatMap<S>(f: Fn<R, Either<L, S>>): Either<L, R> | Either<L, S> {
    return this.isRight()
      ? f(this.m_value as R)
      : this;
  }

  flatMapLeft<M>(f: Fn<L, Either<M, R>>): Either<L, R> | Either<M, R> {
    return this.isLeft()
      ? f(this.m_value as L)
      : this;
  }

  peek(rightConsumer: Consumer<R>, leftConsumer: Consumer<L> = () => { }): Either<L, R> {
    if (this.isRight()) {
      rightConsumer(this.m_value as R);
    } else {
      leftConsumer(this.m_value as L);
    }
    return this;
  }

  peekLeft(leftConsumer: Consumer<L>): Either<L, R> {
    // istanbul ignore next
    return this.peek((_) => { }, leftConsumer);
  }

  orElse<S>(anotherValue: S): R | S {
    return this.isRight()
      ? this.m_value as R
      : anotherValue;
  }

  orElseGet<S>(anotherValueSupplier: Supplier<S>): R | S {
    return this.isRight()
      ? this.m_value as R
      : anotherValueSupplier();
  }

  fold<M, S>(leftTransformer: Fn<L, M>, rightTransformer: Fn<R, S>): M | S {
    return this.isRight()
      ? rightTransformer(this.m_value as R)
      : leftTransformer(this.m_value as L);
  }

  swap(): Either<R, L> | Either<L, R> {
    if (this.isRight()) {
      return Either.left(this.m_value as L) as unknown as Either<R, L>;
    }

    return Either.right(this.m_value as R) as unknown as Either<L, R>;
  }

  orElseThrow<E>(f?: Fn<L, L> | Fn<L, E>): R | never {
    if (this.isRight()) {
      return this.m_value as R;
    }

    throw f!(this.m_value as L);
  }

  static right<L, R>(value: R): Either<L, R> {
    return new Either(true, value) as unknown as Either<L, R>;
  }

  static eitherRight<L, R>(value: R): Either<L, R> {
    return Either.right(value);
  }

  static left<L, R>(value: L): Either<L, R> {
    return new Either(false, value) as unknown as Either<L, R>;
  }

  static eitherLeft<L, R>(value: L): Either<L, R> {
    return Either.left(value);
  }

  static ofTry<L, R>(expr: Supplier<R | never>): Either<L, R> {
    try {
      const value = expr();
      return Either.right(value);
    } catch (err) {
      return Either.left(err) as unknown as Either<L, R>;
    }
  }
}

export { Either };
