import { isSome } from '@nike/rcf-fp';

import pause from '../../../utils/pause';
import { getStoreDisplayName } from '../../../utils/store-utils';

import mockStores1 from './mock-stores-1';
import mockStores2 from './mock-stores-2';
import mockStores3 from './mock-stores-3';
import mockStores4 from './mock-stores-4';

const mockStores = [mockStores1, mockStores2, mockStores3, mockStores4]
    .map(({ objects }) => objects)
    .flat()
    .map((store) => (isSome(store?.districtId) ? { ...store, district: 'yes' } : store))
    .sort((l, r, lDispalayName = getStoreDisplayName(l), rDisplayName = getStoreDisplayName(r)) => lDispalayName.localeCompare(rDisplayName));

export const getRegionStores = async ({ name }) => {
    await pause();
    return mockStores.filter(({ region }) => name === region);
};

export const getDistrictStores = async ({ id }) => {
    await pause();
    return mockStores.filter(({ districtId }) => id === districtId);
};

export const updateStore = async (store) => {
    await pause();
    const updatedStore = isSome(store?.districtId)
        ? { ...store, district: 'yes' }
        : { ...store, district: undefined };
    const index = mockStores.findIndex(({ id }) => id === store.id);
    if (index !== -1) {
        mockStores[index] = updatedStore;
    }
    return updatedStore;
};
