import { ADD_AUTH } from '../actions/authorization';
import { saveState } from '../utils/local-storage';

const initialState = {
  auth: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AUTH:
      saveState('auth', action.auth);
      return {
        ...state,
        auth: action.auth,
      };
    default:
      return state;
  }
};

export default reducer;
