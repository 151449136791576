import { Checkbox } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

const StoresSFS = (props) => (
  <section className="ncss-col-sm-12">
    <h1 className="headline-1 text-color-accent">Ship From Store is currently {props.originalChecked ? 'enabled' : 'disabled'} for store {props.store.address.country}-{props.store.storeNumber}</h1>
    <article className="ncss-col-sm-12">
      <Checkbox
        key="checked"
        isChecked={props.checked}
        label="Enabled"
        onChange={({ target: { checked } }) => props.updateCheckBox(checked)}
      />
    </article>
    {props.originalChecked !== props.checked && <aside className="text-color-accent">You must click SAVE FULFILLMENT OPTION at the bottom of the page to change the Enabled/Disabled status</aside>}
  </section>
);

StoresSFS.propTypes = {
  checked: PropTypes.bool.isRequired,
  originalChecked: PropTypes.bool.isRequired,
  store: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
    }).isRequired,
    storeNumber: PropTypes.string.isRequired,
  }).isRequired,
  updateCheckBox: PropTypes.func.isRequired,
};

export default StoresSFS;
