"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsImage = void 0;
const isImageName = 'IsImage';
const IsImage = () => (file) => {
    var _a;
    if ((_a = file.type) === null || _a === void 0 ? void 0 : _a.startsWith('image')) {
        return Promise.resolve(undefined);
    }
    return Promise.resolve({
        message: `File type ${file.type} is not an image.`,
        rule: isImageName,
    });
};
exports.IsImage = IsImage;
