import {
  countryCodeValidator,
  currencyCodeValidator,
  dateISOValidator,
  IPKeyCityValidator,
  IPShipToValidator,
  IPSubTerritoryValidator,
  IPTerritoryValidator,
  IPTimezoneValidator,
  regionValidator,
  requiredValidator,
  storeChannelValidator,
  storeNumberValidator,
} from './sls-validation';

export const fieldsToValidate = [
  { prop: 'name', validators: [requiredValidator] },
  { prop: 'storeNumber', validators: [requiredValidator, storeNumberValidator] },
  { prop: 'country', validators: [requiredValidator, countryCodeValidator] },
  { prop: 'storeChannel', validators: [requiredValidator, storeChannelValidator] },
  { prop: 'currencyCode', validators: [requiredValidator, currencyCodeValidator] },
  { prop: 'region', validators: [requiredValidator, regionValidator] },
  { prop: 'openingDate', validators: [dateISOValidator] },
  { prop: 'closingDate', validators: [dateISOValidator] },
  { prop: 'islandPacific.sapShipTo', validators: [IPShipToValidator] },
  { prop: 'islandPacific.ipTerritory', validators: [IPTerritoryValidator] },
  { prop: 'islandPacific.ipSubTerritory', validators: [IPSubTerritoryValidator] },
  { prop: 'islandPacific.timeZone', validators: [IPTimezoneValidator] },
  { prop: 'islandPacific.ipKeyCity', validators: [IPKeyCityValidator] },
];

export default fieldsToValidate;
