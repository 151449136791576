import PropTypes from 'prop-types';
import React from 'react';

import { ccmPermissions } from '../../utils/tab-permissions';
import { PageTemplate } from '../reusable';

import CCMContent from './Main';

const CCM = (props) => (
  <PageTemplate
    auth={ccmPermissions}
    description="View and edit the configuration settings for a service."
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+CCM+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
        )}
    page={<CCMContent />}
    path={props.location.pathname}
    title="Common Configuration Manager"
  />
    );

CCM.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default CCM;
