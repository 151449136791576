import { combineReducers } from 'redux-loop';

import authorizationReducer from './authorization';
import devicesReducer from './devices';
import storeServicesReducer from './store-services';
import waitlinesReducer from './waitlines';
import zonesReducer from './zones';

const rootReducer = combineReducers({
  authorizationReducer,
  devicesReducer,
  storeServicesReducer,
  waitlinesReducer,
  zonesReducer,
});

export default rootReducer;
