import { requestWrapper } from './reusable';

const config = require('../../config')();

export const getRegisters = (token, queryParams) => requestWrapper(
  token,
  `${config.rltWorkstationNumberServiceResource}?${queryParams}`,
  'get',
);

export const postRegisters = (token, data) => requestWrapper(
  token,
  `${config.rltWorkstationNumberServiceResource}`,
  'post',
  undefined,
  data,
);

export const putRegisters = (token, id, data) => requestWrapper(
  token,
  `${config.rltWorkstationNumberServiceResource}?id=${id}`,
  'put',
  undefined,
  data,
);

export const deleteRegisters = (token, id) => requestWrapper(
  token,
  `${config.rltWorkstationNumberServiceResource}?id=${id}`,
  'delete',
);
