import {
  Button, ButtonGroup, TextField, TextGroup
} from '@nike/eds';
import {
  Check, Close, Delete
} from '@nike/nike-design-system-icons';
import { isSome } from '@nike/rcf-fp';
import React, {
  useContext, useEffect, useMemo, useState
} from 'react';

import { deleteNode, updateNode, createNode } from '../../services/hierarchy-service';
import { isChildOf } from '../../utils/hierarchy-utils';
import { userCanDeleteTerritory, userCanEditTerritory, userHasWritePermissionForRegion } from '../../utils/permissions';
import { isNotWhitespaceOrNil } from '../../utils/string-utils';
import { Modal, Select } from '../eds-custom';
import { StoreHierarchyContext } from '../StoreHierarchyContextProvider';

import AddNodeButton from './common/AddNodeButton';
import HierarchyColumnTitle from './common/HierarchyColumnTitle';
import HierarchyNode from './common/HierarchyNode';

export default () => {
  const {
    accessToken,
    selectedRegion = {},
    hierarchyNodes = [],
    selectedTerritory,
    userGroups,
    hierarchyNodeLookupTable,
    selectedBrand,
    setIsPageLoading,
    setHierarchyNodes,
    setError,
    setSuccessMessage,
    setActiveNodeId,
  } = useContext(StoreHierarchyContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalName, setEditModalName] = useState(selectedTerritory?.name ?? '');
  const [editModalParentId, setEditModalParentId] = useState(selectedTerritory?.parentId ?? '');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalName, setAddModalName] = useState('');

  // Update editModalName and newParentRegion when selectedTerritory changes
  useEffect(() => {
    setEditModalName(selectedTerritory?.name ?? '');
    setEditModalParentId(selectedTerritory?.parentId ?? '');
  }, [selectedTerritory]);

  // Reset addModalName and addModalParentId when isAddModalOpen changes
  useEffect(() => {
    setAddModalName('');
  }, [isAddModalOpen]);

  const regions = useMemo(() => hierarchyNodes.filter(isChildOf(selectedBrand)), [hierarchyNodes, selectedBrand]);
  const territories = useMemo(() => hierarchyNodes.filter(isChildOf(selectedRegion)), [hierarchyNodes, selectedRegion]);
  const parentSelectValue = useMemo((newParent = hierarchyNodeLookupTable?.[editModalParentId] ?? {}) => ({ label: newParent?.name, value: newParent?.id }), [hierarchyNodeLookupTable, editModalParentId]);
  const parentSelectOptions = useMemo(() => regions.map(({ id, name }) => ({ label: name, value: id })), [regions]);
  const isDirtyEditModalName = useMemo(() => editModalName !== selectedTerritory?.name, [editModalName, selectedTerritory]);
  const isDirtyEditModalParentId = useMemo(() => isNotWhitespaceOrNil(editModalParentId) && editModalParentId !== selectedTerritory?.parentId, [editModalParentId, selectedTerritory]);
  const isDirtyForEdit = useMemo(() => isDirtyEditModalName || isDirtyEditModalParentId, [isDirtyEditModalName, isDirtyEditModalParentId]);
  const isDuplicateNameForEdit = useMemo(() => hierarchyNodes.some((node) => node.name === editModalName
      && node.nodeType === 'TERRITORY'
      && node.parentId === editModalParentId
      && node.id !== selectedTerritory?.id),
    [hierarchyNodes, editModalName, editModalParentId, selectedTerritory]);
  const isValidNameForEdit = useMemo(() => !isDuplicateNameForEdit && isNotWhitespaceOrNil(editModalName), [isDuplicateNameForEdit, editModalName]);
  const isValidForEdit = useMemo(() => isValidNameForEdit && isNotWhitespaceOrNil(editModalParentId), [editModalParentId, isValidNameForEdit]);
  const DUPLICATE_NAME_ERROR_MESSAGE = 'A territory with this name already exists.';
  const editModalNameErrorMessage = useMemo(() => (isDuplicateNameForEdit ? DUPLICATE_NAME_ERROR_MESSAGE : ''), [isDuplicateNameForEdit]);
  const isDuplicateNameForAdd = useMemo(() => hierarchyNodes.some((node) => node.name === addModalName
      && node.nodeType === 'TERRITORY'
      && node.parentId === selectedRegion?.id),
    [hierarchyNodes, addModalName, selectedRegion]);
  const isValidForAdd = useMemo(() => isNotWhitespaceOrNil(addModalName) && !isDuplicateNameForAdd, [addModalName, isDuplicateNameForAdd]);
  const addModalErrorMessage = useMemo(() => (isDuplicateNameForAdd ? DUPLICATE_NAME_ERROR_MESSAGE : ''), [isDuplicateNameForAdd]);
  const actionComponent = useMemo(() => (
    isSome(selectedRegion)
    && userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)
    && (<AddNodeButton label="Add Territory" onClick={() => setIsAddModalOpen(true)} />)
  ), [userGroups, hierarchyNodeLookupTable, selectedRegion]);

  return (
    <>
      {isEditModalOpen && (
        <Modal
          footerSlot={(
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!(isDirtyForEdit && isValidForEdit)}
                size="small"
                variant="primary"
                onClick={() => {
                  setIsPageLoading(true);
                  const updatedTerritory = { ...selectedTerritory, name: editModalName, parentId: editModalParentId };
                  // eslint-disable-next-line promise/catch-or-return
                  updateNode(updatedTerritory, accessToken)
                    .then(async (newNodes) => {
                      await setHierarchyNodes(newNodes);
                      setActiveNodeId(updatedTerritory.id);
                      setSuccessMessage(`Territory ${updatedTerritory.name} updated.`);
                      return newNodes;
                    })
                    .catch(setError)
                    .finally(() => setIsPageLoading(false));
                }}
              >Save
              </Button>
              <Button
                afterSlot={<Delete />}
                disabled={!userCanDeleteTerritory(userGroups, hierarchyNodeLookupTable, selectedTerritory)}
                size="small"
                variant="secondary"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  const confirmDelete = window.confirm(`Delete territory ${selectedTerritory?.name}?`);
                  if (confirmDelete) {
                    setIsPageLoading(true);
                    // eslint-disable-next-line promise/catch-or-return
                    deleteNode(selectedTerritory, accessToken)
                      .then(async (newNodes) => {
                        await setHierarchyNodes(newNodes);
                        setSuccessMessage(`Territory ${selectedTerritory.name} deleted.`);
                        setActiveNodeId(selectedTerritory.parentId);
                        return newNodes;
                      })
                      .catch(setError)
                      .finally(() => {
                        setIsPageLoading(false);
                      });
                  }
                }}
              >Delete
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Edit Territory"
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
        >
          <TextGroup>
            <TextField
              errorMessage={editModalNameErrorMessage}
              hasErrors={isDuplicateNameForEdit}
              label="Name"
              value={editModalName}
              onChange={(e) => setEditModalName(e.target.value)}
            />
            <Select
              id="region-select"
              label="Region"
              options={parentSelectOptions}
              value={parentSelectValue}
              onChange={(e) => setEditModalParentId(e?.value)}
            />
          </TextGroup>
        </Modal>
      )}
      {isAddModalOpen && (
        <Modal
          footerSlot={(
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!isValidForAdd}
                size="small"
                variant="primary"
                onClick={() => {
                  setIsPageLoading(true);
                  const newTerritory = { name: addModalName, nodeType: 'TERRITORY', parentId: selectedRegion?.id };
                  // eslint-disable-next-line promise/catch-or-return
                  createNode(newTerritory, accessToken)
                    .then((newNodes) => {
                      setHierarchyNodes(newNodes);
                      const savedNewTerritory = newNodes.find((node) => node.parentId === newTerritory.parentId
                        && node.name === newTerritory.name
                        && node.nodeType === newTerritory.nodeType);
                      setActiveNodeId(savedNewTerritory.id);
                      setSuccessMessage(`Territory ${newTerritory.name} added.`);
                      return newTerritory;
                    })
                    .catch(setError)
                    .finally(() => {
                      setIsPageLoading(false);
                      setIsAddModalOpen(false);
                    });
                }}
              >Add
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Add Territory"
          isOpen={isAddModalOpen}
          onDismiss={() => setIsAddModalOpen(false)}
        >
          <TextGroup>
            <TextField
              errorMessage={addModalErrorMessage}
              hasErrors={isDuplicateNameForAdd}
              label="Name"
              value={addModalName}
              onChange={(e) => setAddModalName(e.target.value)}
            />
            <TextField
              disabled
              label="Region"
              value={selectedRegion?.name}
            />
          </TextGroup>
        </Modal>
      )}
      <HierarchyColumnTitle actionComponent={actionComponent} title="Territories" />
      {
        territories.map((territory) => (
          <HierarchyNode
            key={territory.id}
            hierarchyNode={territory}
            isDeletable={userCanDeleteTerritory(userGroups, hierarchyNodeLookupTable, territory)}
            isEditable={userCanEditTerritory(userGroups, hierarchyNodeLookupTable, territory)}
            isSelected={territory.id === selectedTerritory?.id}
            onClick={() => {
              setActiveNodeId(territory.id);
            }}
            onEdit={() => setIsEditModalOpen(true)}
          />
        ))
      }
    </>
  );
};
