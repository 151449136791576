import { Checkbox } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { loadState } from '../../utils/local-storage';
import { dcnLoadPostApi, dcnGtinStatusApi } from '../../utils/service-calls/generate-product-dcn';
import { rosPermissions } from '../../utils/tab-permissions';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonBlack, ButtonSubmit, Creatable, PageTemplate, StoreSelect,
} from '../reusable';

/* EXAMPLE COMBINATION:
 * country = AUS
 * store = any (6111)
 * gtin = 00640135803154
 */

const STORE_GTINS = 'STORE_GTINS';
const STORE_FULL_LOAD = 'STORE_FULL_LOAD';

class GenerateProductDCN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        gtin: REQUIRED_FIELD,
        selectedCountry: '',
        selectedStore: REQUIRED_FIELD,
      },
      gtin: null,
      jobType: STORE_GTINS,
      postDcnErrorMessage: '',
      regexMessage: '',
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      selectedStores: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  checkStatus = async () => {
    this.setState({ dcnGtinStatusFetching: true });
    // eslint-disable-next-line react/no-access-state-in-setstate
    const updatedResults = await Promise.all(this.state.generateGtinResult.map((res) => dcnGtinStatusApi(this.props.accessToken, this.state.selectedCountry.value, res.body.id)
      .then((result) => result)
      .catch((error) => error)));
    this.setState({ dcnGtinStatusFetching: false, generateGtinResult: updatedResults });
  };

  dcnGtinStatusReset = () => {
    const initialState = {
      badGtin: false,
      dcnGtinStatusErrorMessage: '',
      dcnGtinStatusFetching: false,
      dcnGtinStatusResult: {},
      dcnGtinStatusSuccess: false,
      generateGtinResult: {},
      generateProductDCNFetching: false,
      generateProductDCNSuccess: false,
      isCompleted: false,
      postDcnErrorMessage: '',
    };
    this.setState(initialState);
  };

  formSubmit = async () => {
    this.dcnGtinStatusReset();
    this.setState({ generateProductDCNFetching: true });
    const gtin = this.state.jobType === STORE_GTINS && this.state.gtin.value;
    dcnLoadPostApi(
      this.props.accessToken,
      this.state.selectedCountry.value,
      this.state.selectedStores.map((store) => ({ country: store.address.country, id: store.id, number: store.storeNumber })),
      this.state.jobType,
      gtin,
    )
      .then((result) => {
        if (result.body.generateDCN) {
          return this.setState({
            generateGtinResult: result.body.generateDCN,
            generateProductDCNFetching: false,
            generateProductDCNSuccess: true,
          });
        } else {
          return this.setState({
            generateGtinResult: '',
            generateProductDCNFetching: false,
            generateProductDCNSuccess: false,
            postDcnErrorMessage: result.body.status || result.body,
          });
        }
      })
      .catch((error) => this.setState({
        generateGtinResult: '',
        generateProductDCNFetching: false,
        generateProductDCNSuccess: false,
        postDcnErrorMessage: error.message,
      }));
  };

  handleSelectFullLoad = (checked) => this.setState({
    jobType: checked ? STORE_FULL_LOAD : STORE_GTINS,
  }, () => this.dcnGtinStatusReset());

  onClear = () => this.setState({ postDcnErrorMessage: '', selectedStore: null, selectedStores: [] });

  selectCountry = (selectedCountry) => {
    this.setState({ selectedCountry });
    this.onClear();
  };

  selectStore = (selectedStore) => this.setState((prevState) => {
    const { selectedStores } = prevState;
    if (selectedStores.includes(selectedStore)) {
      return ({});
    }
    selectedStores.push(selectedStore);
    return ({ selectedStore, selectedStores });
  });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <PageTemplate
      auth={rosPermissions}
      description="Generate product DCN files for a GTIN or a product Full load and send them to a store."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Generate+Product+DCN+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-8">
          <StoreSelect
            isRequired
            formErrors={this.state.formErrors}
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            selectedStores={this.state.selectedStores}
            onClear={this.onClear}
          />
          <section className="ncss-row">
            <article className="ncss-col-sm-12 ta-sm-c mt2-sm">
              <Checkbox
                id="fullLoad"
                isChecked={this.state.jobType === STORE_FULL_LOAD}
                label="Full Load"
                onChange={({ target: { checked } }) => this.handleSelectFullLoad(checked)}
              />
            </article>
            {this.state.jobType === STORE_GTINS && (
              <Creatable
                errorMessage={this.state.formErrors.gtin}
                label="GTIN"
                value={this.state.gtin}
                width={12}
                onChange={(gtin) => this.setState({ gtin })}
              />
            )}
            <article className="ncss-col-sm-12 ta-sm-c">
              <ButtonSubmit
                isDisabled={!this.state.selectedStores.length || (this.state.jobType === STORE_GTINS && !this.state.gtin)}
                isLoading={this.state.generateProductDCNFetching || this.state.dcnGtinStatusFetching}
                submitError={this.state.postDcnErrorMessage && `${this.state.postDcnErrorMessage}: Please Contact Administrator`}
                onClick={this.formSubmit}
              />
              {this.state.generateProductDCNSuccess && this.state.generateGtinResult.map((result) => (
                <section key={result.body.id} className="p4-sm">
                  <h2 className="text-color-accent">Status: {result.body.status}</h2>
                  <h2 className="text-color-primary-light">ID: {result.body.id}</h2>
                </section>
              ))}
            </article>
            {this.state.generateProductDCNSuccess && !this.state.badGtin && (
              <article className="ncss-col-sm-8 ta-sm-c">
                <ButtonBlack
                  isDisabled={this.state.dcnGtinStatusFetching}
                  label="Check Status"
                  onClick={this.checkStatus}
                />
                <p className="text-color-error body-4 mt2-sm">{this.state.statusErrorMessage}</p>
              </article>
            )}
          </section>
        </main>
      )}
      path={this.props.location.pathname}
      title="Generate Product DCN"
    />
  );
}

GenerateProductDCN.defaultProps = {
  accessToken: undefined,
};

GenerateProductDCN.propTypes = {
  accessToken: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(GenerateProductDCN);
