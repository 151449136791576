import { Input, Loading } from '@nike/frame-component-library';
import {
  match, Default, isNone, sort,
} from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getOktaToken } from '../../../../utils/service-calls/sls';
import allZoneTypes from '../../../../utils/static/zone-types';
import { SelectMultiple, Upload } from '../../../reusable';

/// /////////////////////////////////////////////////////////////
// Named 'magic' values
/// /////////////////////////////////////////////////////////////
const DEFAULT_STRING = '';
const MIN_LATITUDE = -90;
const MAX_LATITUDE = 90;
const MIN_LONGITUDE = -180;
const MAX_LONGITUDE = 180;
const BASE_Z_INDEX = 15;

/// /////////////////////////////////////////////////////////////
// Validation
/// /////////////////////////////////////////////////////////////
const isMissingOrDefault = (value) => match(value)(
  [isNone, true],
  [DEFAULT_STRING, true],
  [Default, false],
);

export const validateLatitude = ({ storeContext = {} }) => match(storeContext.coordinates ?? {})(
  [({ latitude, longitude }) => isMissingOrDefault(latitude) && !isMissingOrDefault(longitude), 'Latitude is required if Longitude is present'],
  [({ latitude }) => isMissingOrDefault(latitude), DEFAULT_STRING],
  [({ latitude }) => Number.isNaN(Number.parseFloat(latitude)), 'Latitude must be a number'],
  [({ latitude }) => latitude < MIN_LATITUDE, `Latitude must be >= ${MIN_LATITUDE}`],
  [({ latitude }) => latitude > MAX_LATITUDE, `Latitude must be <= ${MAX_LATITUDE}`],
  [Default, DEFAULT_STRING],
);

export const validateLongitude = ({ storeContext = {} }) => match(storeContext.coordinates ?? {})(
  [({ latitude, longitude }) => isMissingOrDefault(longitude) && !isMissingOrDefault(latitude), 'Longitude is required if Latitude is present'],
  [({ longitude }) => isMissingOrDefault(longitude), DEFAULT_STRING],
  [({ longitude }) => Number.isNaN(Number.parseFloat(longitude)), 'Longitude must be a number'],
  [({ longitude }) => longitude < MIN_LONGITUDE, `Longitude must be >= ${MIN_LONGITUDE}`],
  [({ longitude }) => longitude > MAX_LONGITUDE, `Longitude must be <= ${MAX_LONGITUDE}`],
  [Default, DEFAULT_STRING],
);

/// /////////////////////////////////////////////////////////////
// Common utilities
/// /////////////////////////////////////////////////////////////
const getOfferingHash = ({ name, publicOffering }) => `${name}-${publicOffering ? '1' : '0'}`;

/// /////////////////////////////////////////////////////////////
// Component for a single store context instance
/// /////////////////////////////////////////////////////////////
const StoreContextInstance = ({
  offering,
  formData: { offerings = [] },
  updateFormData,
  userIsReadOnly,
  username,
  oktaError,
  oktaToken,
}) => {
  //
  // State
  //

  // State for string versions of number inputs so we can maintain what the user types in addition to the numeric values in the parent state.
  const [latitudeString, setLatitudeString] = useState(String(offering?.storeContext?.coordinates?.latitude ?? DEFAULT_STRING));
  // State for string versions of number inputs so we can maintain what the user types in addition to the numeric values in the parent state.
  const [longitudeString, setLongitudeString] = useState(String(offering?.storeContext?.coordinates?.longitude ?? DEFAULT_STRING));
  // State for image error messaging
  const [imageError, setImageError] = useState(oktaError);
  // State for image upload success messaging
  const [imageSuccess, setImageSuccess] = useState(DEFAULT_STRING);
  // State for form submission
  const [isSubmitting, setIsSubmitting] = useState(false);

  //
  // Helper functions for state management in regard to updating individual fields in formData
  //
  const updateOffering = (updatedOffering) => {
    const replaceOfferingIfMatch = (someOffering) => (getOfferingHash(someOffering) === getOfferingHash(updatedOffering) ? updatedOffering : someOffering);
    const updatedOfferings = offerings.map(replaceOfferingIfMatch);
    updateFormData('offerings', updatedOfferings);
  };
  const getStoreContext = () => offering.storeContext ?? {};
  const updateStoreContext = (storeContext) => updateOffering({ ...offering, storeContext });
  const updateDescription = ({ target: { value: description } }) => updateStoreContext({ ...getStoreContext(), description });
  const updateImageUrl = ({ target: { value: imageUrl } }) => updateStoreContext({ ...getStoreContext(), imageUrl });
  const updateCoordinates = (coordinates) => updateStoreContext({ ...getStoreContext(), coordinates });
  const getCoordinates = () => offering?.storeContext?.coordinates ?? {};
  const updateLatitude = ({ target: { value: sLatitude } }) => {
    setLatitudeString(sLatitude);
    const latitudeParsed = Number.parseFloat(sLatitude);
    const latitude = Number.isNaN(latitudeParsed) ? null : latitudeParsed;
    updateCoordinates({ ...getCoordinates(), latitude });
  };
  const updateLongitude = ({ target: { value: sLongitude } }) => {
    setLongitudeString(sLongitude);
    const longitudeParsed = Number.parseFloat(sLongitude);
    const longitude = Number.isNaN(longitudeParsed) ? null : longitudeParsed;
    updateCoordinates({ ...getCoordinates(), longitude });
  };
  const updateZones = (zoneOptions) => updateStoreContext({
    ...getStoreContext(),
    zones: zoneOptions.map(({ value }) => value),
  });
  const onImageUpload = (submitting) => {
    setIsSubmitting(submitting);
    setImageSuccess(DEFAULT_STRING);
    setImageError(DEFAULT_STRING);
  };
  const onImageUploadError = (error) => {
    setImageError(String(error));
  };
  const onImageUploadSuccess = (imageUrl) => {
    setImageSuccess('Successfully uploaded a new image, the Image Url field has been updated to direct to the new image. Don\'t forget to Submit your change at the bottom of the page.');
    updateImageUrl({
      target: {
        value: imageUrl,
      },
    });
  };

  //
  // Values for this form's current data
  //
  const offeringHeader = `${offering.name} (${offering.publicOffering ? 'Public' : 'Private'})`;
  const zones = sort(offering?.storeContext?.zones ?? []);
  const zoneOptions = [...allZoneTypes]
    .sort()
    .filter((zoneType) => !zones.includes(zoneType))
    .map((zoneType) => ({ label: zoneType, value: zoneType }));
  const zoneValues = zones.map((zone) => ({ label: zone, value: zone }));
  const offeringHash = getOfferingHash(offering);
  const latitudeValidation = validateLatitude({
    ...offering,
    storeContext: {
      ...getStoreContext(),
      coordinates: {
        ...getCoordinates(),
        latitude: latitudeString,
      },
    },
  });
  const longitudeValidation = validateLongitude({
    ...offering,
    storeContext: {
      ...getStoreContext(),
      coordinates: {
        ...getCoordinates(),
        longitude: longitudeString,
      },
    },
  });
  const description = offering?.storeContext?.description ?? DEFAULT_STRING;
  const imageUrl = offering?.storeContext?.imageUrl ?? DEFAULT_STRING;
  const [zonesControlZIndex] = [...offerings] // The base z-index + the offering array index of this offering in reverse order.
    .reverse()
    .map((anOffering, index) => ({ ...anOffering, offeringZIndex: BASE_Z_INDEX + index }))
    .filter((anOffering) => getOfferingHash(anOffering) === getOfferingHash(offering))
    .map(({ offeringZIndex }) => offeringZIndex);

  //
  // JSX template
  //
  return (
    <>
      <article key={`offering-form-article-${offeringHash}`} className="ncss-col-sm-12 mt4-sm">
        <header key={`offering-form-header-${offeringHash}`} className="headline-5">{offeringHeader}</header>
      </article>
      <article key={`offering-form-description-article-${offeringHash}`} className="ncss-col-sm-6 ta-sm-l">
        <Input
          key={`offering-form-description-control-${offeringHash}`}
          isDisabled={userIsReadOnly}
          label="Description"
          value={description}
          onChange={updateDescription}
        />
      </article>
      <SelectMultiple
        key={`offering-form-zones-control-key-${offeringHash}`}
        id={`offering-form-zones-control-${offeringHash}`}
        isDisabled={userIsReadOnly}
        label="Zones"
        options={zoneOptions}
        type="float"
        values={zoneValues}
        zIndex={zonesControlZIndex}
        onChange={updateZones}
      />
      <article key={`offering-form-image-url-article-${offeringHash}`} className="ncss-col-sm-6 ta-sm-l">
        <Input
          key={`offering-form-image-url-control-${offeringHash}`}
          isDisabled={userIsReadOnly}
          label="Image URL"
          value={imageUrl}
          onChange={updateImageUrl}
        />
      </article>
      <article key={`offering-form-image-url-upload-article-${offeringHash}`} className="ncss-col-sm-6 ta-sm-l">
        {!userIsReadOnly && (
          <div className="ncss-input-container">
            <span className="ncss-label pr3-sm">Image File Drop-Zone</span>
            <section className="border pt2-sm pb1-sm">
              <Upload
                key={`offering-form-image-upload-control-${offeringHash}`}
                prompt="Drop new offering image here"
                setImageError={onImageUploadError}
                setImageUrl={onImageUploadSuccess}
                setIsUploading={onImageUpload}
                token={oktaToken}
                username={username}
              />
            </section>
            <section
              key={`offering-form-image-url-upload-article-error-${offeringHash}`}
              className="ncss-error-msg body-4 ta-sm-c"
              style={{ color: '#d43f21' }}
            >{imageError}
            </section>
            <section
              key={`offering-form-image-url-upload-article-success-${offeringHash}`}
              className="text-color-success body-4 ta-sm-c"
            >{imageSuccess}
            </section>
            <sectionn key={`offering-form-image-url-upload-article-loading-${offeringHash}`}>
              {isSubmitting && <Loading />}
            </sectionn>
          </div>
        )}
      </article>
      <article key={`offering-form-latitude-article-${offeringHash}`} className="ncss-col-sm-6 ta-sm-l">
        <Input
          key={`offering-form-latitude-control-${offeringHash}`}
          errorMessage={latitudeValidation}
          isDisabled={userIsReadOnly}
          label="Coordinates - Latitude"
          value={latitudeString}
          onChange={updateLatitude}
        />
      </article>
      <article key={`offering-form-longitude-article-${offeringHash}`} className="ncss-col-sm-6 ta-sm-l">
        <Input
          key={`offering-form-longitude-control-${offeringHash}`}
          errorMessage={longitudeValidation}
          isDisabled={userIsReadOnly}
          label="Coordinates - Longitude"
          value={longitudeString}
          onChange={updateLongitude}
        />
      </article>
      <aside key={`offering-form-zones-separator-aside-${offeringHash}`} className="mt2-sm" style={{ borderTop: '1px solid' }} />
    </>
  );
};

/// /////////////////////////////////////////////////////////////
// Component for all offering store contexts
/// /////////////////////////////////////////////////////////////
export const StoreContexts = ({
  formData, updateFormData, userIsReadOnly, userToken, username,
}) => {
  // State for the okta token for accessing Nike CMS
  const [oktaToken, setOktaToken] = useState(DEFAULT_STRING);
  const [oktaError, setOktaError] = useState(DEFAULT_STRING);

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return
    getOktaToken(userToken)
      .then(({ body }) => setOktaToken(body))
      .catch(({ message }) => setOktaError(message));
  }, [userToken]);

  return (
    <>
      {(formData.offerings ?? []).length > 0 && (
      <>
        <article className="ncss-col-sm-12 mt4-sm">
          <header className="headline-4">Store-Specific Offering Information</header>
          <aside className="mt2-sm" style={{ borderTop: '3px solid' }} />
        </article>
        {}
        {[...formData.offerings.filter((anOffering) => anOffering.publicOffering),
          ...formData.offerings.filter((anOffering) => !anOffering.publicOffering)].map((offering) => (
            <StoreContextInstance
              key={`offering-store-context-instance-${getOfferingHash(offering)}`}
              formData={formData}
              offering={offering}
              oktaError={oktaError}
              oktaToken={oktaToken}
              updateFormData={updateFormData}
              userIsReadOnly={userIsReadOnly}
              userToken={userToken}
              username={username}
            />
        ))}
      </>
      )}
    </>
  );
};

/// /////////////////////////////////////////////////////////////
// Component property types
/// /////////////////////////////////////////////////////////////

const commonPropTypes = {
  formData: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
};

StoreContexts.propTypes = {
  ...commonPropTypes,
  userToken: PropTypes.string.isRequired,
};

StoreContextInstance.propTypes = {
  ...commonPropTypes,
  offering: PropTypes.shape().isRequired,
  oktaError: PropTypes.string.isRequired,
  oktaToken: PropTypes.string.isRequired,
};
