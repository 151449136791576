import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import {
  IPChannelValues, IPSubTerritory, IPTempClosureReasonValues, IPTerritory,
  IPTimezones, keyCityValues, warehouseIPTypeValues,
} from '../../../../utils/static/sls-property-values';
import {
  Creatable, CustomPanel, DateInput, Select,
} from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!properties.islandPacificErrors.ipWarehouseType || !!properties.islandPacificErrors.warehouseNumber
  || !!properties.islandPacificErrors.ipChannel
  || !!properties.islandPacificErrors.sapShipTo || !!properties.islandPacificErrors.ipTimezone
  || !!properties.islandPacificErrors.temporaryClosure || !!properties.islandPacificErrors.keyCity
  || !!properties.islandPacificErrors.ipTerritory || !!properties.islandPacificErrors.ipSubTerritory);

class IslandPacific extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.islandPacificErrors !== this.props.islandPacificErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Island Pacific"
      onClick={this.panelToggle}
    >
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipWarehouseType}
        id="ipWarehouseType"
        isDisabled={this.props.userIsReadOnly}
        label="IP Warehouse Type"
        options={warehouseIPTypeValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipWarehouseType || ''}
        zIndex={15}
        onChange={(value) => this.props.updateIslandPacific('ipWarehouseType', value)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          errorMessage={this.props.islandPacificErrors.warehouseNumber}
          id="warehouseNumber"
          isDisabled={this.props.userIsReadOnly}
          label="Warehouse Number"
          value={this.props.islandPacific.warehouseNumber || ''}
          onChange={(value) => this.props.updateIslandPacific('warehouseNumber', value.target.value)}
        />
      </article>
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipChannel}
        id="ipChannel"
        isDisabled={this.props.userIsReadOnly}
        label="IP Channel"
        options={IPChannelValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipChannel || ''}
        zIndex={15}
        onChange={(value) => this.props.updateIslandPacific('ipChannel', value)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="vatIdentifier"
          isDisabled={this.props.userIsReadOnly}
          label="VAT Identifier"
          value={this.props.islandPacific.vatIdentifier || ''}
          onChange={(value) => this.props.updateIslandPacific('vatIdentifier', value.target.value)}
        />
      </article>
      <Creatable
        isMulti
        errorMessage={this.props.islandPacificErrors.sapShipTo}
        helpMessage="must be 6 digits"
        isDisabled={this.props.userIsReadOnly}
        label="SAP Ship To"
        value={this.props.islandPacific.sapShipTo ? this.props.islandPacific.sapShipTo.map((value) => ({ label: value, value })) : []}
        width={6}
        onChange={(values) => this.props.updateIslandPacific('sapShipTo', values ? values.map((value) => value.value) : null)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="partnerName"
          isDisabled={this.props.userIsReadOnly}
          label="Partner Name"
          value={this.props.islandPacific.partnerName || ''}
          onChange={(value) => this.props.updateIslandPacific('partnerName', value.target.value)}
        />
      </article>
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipTimezone}
        id="ipTimezone"
        isDisabled={this.props.userIsReadOnly}
        label="Timezone"
        options={IPTimezones.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipTimezone || ''}
        zIndex={15}
        onChange={(value) => this.props.updateIslandPacific('ipTimezone', value)}
      />
      <DateInput
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.temporaryClosure}
        id="fromDate"
        isDisabled={this.props.userIsReadOnly}
        label="Temp Closure From Date"
        value={this.props.islandPacific.temporaryClosure?.fromDate || ''}
        onChange={(value) => {
          const temporaryClosure = {
            ...this.props.islandPacific.temporaryClosure,
            fromDate: value,
            reason: (this.props.islandPacific.temporaryClosure?.reason || ''),
          };
          this.props.updateIslandPacific('temporaryClosure', temporaryClosure);
        }}
      />
      <DateInput
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.temporaryClosure}
        id="toDate"
        isDisabled={this.props.userIsReadOnly}
        label="Temp Closure To Date"
        value={this.props.islandPacific.temporaryClosure?.toDate || ''}
        onChange={(value) => {
          const temporaryClosure = {
            ...this.props.islandPacific.temporaryClosure,
            reason: (this.props.islandPacific.temporaryClosure?.reason || ''),
            toDate: value,
          };
          this.props.updateIslandPacific('temporaryClosure', temporaryClosure);
        }}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.temporaryClosure}
        id="reason"
        isDisabled={this.props.userIsReadOnly}
        label="Temp Closure Reason"
        options={IPTempClosureReasonValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.temporaryClosure?.reason || ''}
        zIndex={10}
        onChange={(value) => {
          const temporaryClosure = { ...this.props.islandPacific.temporaryClosure, reason: value };
          this.props.updateIslandPacific('temporaryClosure', temporaryClosure);
        }}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.keyCity}
        id="keyCity"
        isDisabled={this.props.userIsReadOnly}
        label="Key City"
        options={keyCityValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.keyCity || ''}
        zIndex={10}
        onChange={(value) => this.props.updateIslandPacific('keyCity', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipTerritory}
        id="ipTerritory"
        isDisabled={this.props.userIsReadOnly}
        label="Territory"
        options={IPTerritory.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipTerritory || ''}
        zIndex={10}
        onChange={(value) => this.props.updateIslandPacific('ipTerritory', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipSubTerritory}
        id="ipSubTerritory"
        isDisabled={this.props.userIsReadOnly}
        label="Sub Territory"
        options={IPSubTerritory.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipSubTerritory || ''}
        zIndex={10}
        onChange={(value) => this.props.updateIslandPacific('ipSubTerritory', value)}
      />
    </CustomPanel>
  );
}

IslandPacific.propTypes = {
  islandPacific: PropTypes.shape().isRequired,
  islandPacificErrors: PropTypes.shape().isRequired,
  updateIslandPacific: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default IslandPacific;
