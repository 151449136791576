{
    "lastA11yAudit": "07/08/22",
    "name": "Tab Group",
    "description": "Tab Group is a wrapper for tabs to handle the functionality of tabs.",
    "docs": "#/components/tabs/TabGroup",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-EDS?node-id=360%3A11836",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/TabGroup",
    "tags": ["tabgroup", "toggle", "filter", "pivot"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
