import { Checkbox } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { TimeInput } from '../../../reusable';

const isOpenTimeEqualToCloseTime = (properties) => properties.localOpenTime === properties.localCloseTime;

const OpenDuration = (props) => (
  <>
    <TimeInput
      className="ncss-col-sm-4 va-sm-t"
      isDisabled={props.userIsReadOnly || props.disableCmpFields}
      label="Local Open Time"
      value={isOpenTimeEqualToCloseTime(props) ? '' : props.localOpenTime}
      onChange={(value) => props.updateHours('localOpenTime', value, props.index)}
    />
    <TimeInput
      className="ncss-col-sm-4 va-sm-t"
      isDisabled={props.userIsReadOnly || props.disableCmpFields}
      label="Local Close Time"
      value={isOpenTimeEqualToCloseTime(props) ? '' : props.localCloseTime}
      onChange={(value) => props.updateHours('localCloseTime', value, props.index)}
    />
    <article className={`ncss-col-sm-${props.regular ? '2' : '1'} va-sm-t mt4-sm`}>
      <Checkbox
        isChecked={props.closed || isOpenTimeEqualToCloseTime(props)}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Closed All Day"
        onChange={({ target: { checked } }) => props.close(checked)}
      />
    </article>
    {props.regular && (
      <>
        <aside className="ncss-col-sm-2 va-sm-t" />
        <aside className="ncss-col-sm-10">
          <p className="text-color-error body-4 mt2-sm">{props.errorMessage}</p>
        </aside>
      </>
    )}
  </>
);

OpenDuration.defaultProps = {
  closed: false,
  errorMessage: '',
  index: undefined,
  regular: false,
  userIsReadOnly: false,
};

OpenDuration.propTypes = {
  close: PropTypes.func.isRequired,
  closed: PropTypes.bool,
  disableCmpFields: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  index: PropTypes.number,
  localCloseTime: PropTypes.string.isRequired,
  localOpenTime: PropTypes.string.isRequired,
  regular: PropTypes.bool,
  updateHours: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool,
};

export default OpenDuration;
