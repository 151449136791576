import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonRed } from '../../reusable';

const DeleteOptions = ({ record, onClickDelete, isReadOnly }) => (
  record.isProcessing
    ? <section className="ncss-row"><article className="ncss-col-sm-6 ta-sm-r va-sm-t"><Loading isNotCentered /></article><article className="ncss-col-sm-6 ta-sm-l">Deleting...</article></section>
    : (
      <>
        <ButtonRed
          className="m1-sm"
          confirmationMessage="Deleting will de-assign the Workstation Number from the device. Do you want to proceed ?"
          isDisabled={isReadOnly}
          label="Delete"
          onClick={() => onClickDelete(record)}
        />
      </>
    )
);

DeleteOptions.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  record: PropTypes.shape().isRequired,
};

export default DeleteOptions;
