{
    "lastA11yAudit": "07/08/22",
    "name": "App Header",
    "docs": "#/components/AppHeader",
    "description": "App Header is an application level header and filter element.",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=183%3A974",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/AppHeader",
    "tags": [
        "App Header",
        "Header",
        "Layout",
        "Nav",
        "navigation",
        "menu",
        "page header",
        "navigation bar",
        "nav bar",
        "toolbar"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
