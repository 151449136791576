import { Checkbox } from '@nike/frame-component-library';
import {
 ModalActionsStyled, ModalStyled, NikeDesignSystemProvider, Button
} from '@nike/nike-design-system-components';
import { isSome } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

import { countryRegionMap } from '../../../utils/static/country-mappings';
import { defaultNSPImageUrl, defaultNFSImageUrl, cmpCountries } from '../../../utils/static/sls-property-values';
import { isUserAllowed } from '../../../utils/tab-permissions';
import { fieldsToValidate } from '../../../utils/validation/sls-validate-stores';
import { validateFields } from '../../../utils/validation/sls-validation';
import { ButtonBlack, ButtonSubmit, JSONFormatter } from '../../reusable';

import Address from './address';
import Cmp from './cmp';
import Contact from './contact';
import General from './general';
import StoreHistory from './history';
import IslandPacific from './island-pacific';
import JustEnough from './just-enough';
import Localization from './localization';
import NikeApp from './nike-app';
import Operational from './operational';
import Other from './other';
import Partner from './partner';
import SellingArea from './selling-area';
import Service from './service';

const isCmpStore = (formData) => isSome(formData?.cmp?.id);

const currentDate = new Date();
const greyPeriod = currentDate >= new Date('2023-05-09') && currentDate <= new Date('2023-06-20');
const freezePeriod = currentDate >= new Date('2023-06-23') && currentDate <= new Date('2023-07-04');
const storeEditNoticeModalTitle = 'Stop Right There!';
const greyPeriodText
    = 'In order to ensure all updates to Store data are tracked for go-live in the new Customer Management Platform, please track all changes in this spreadsheet [https://nike.box.com/s/0d9kig0x6hlcimyu5u11nhfbap6bdpnk].';
const freezePeriodText
    = 'There is currently a freeze on editing and creating new US & Puerto Rico stores as we transition those activities to the new Customer Management Platform. For more information, see timeline here [https://nike.box.com/s/5e87gccqn3jbxwyj1ucpy7pq0ve2mggi] or contact Mike Rose [mike.rose@nike.com].';

// captures the region/brand combinations for which we should load/enforce hierarchy fields from the hierarchy service
const regionToBrandHierarchyMap = {
  ASIA_PACIFIC_LATIN_AMERICA: ['NIKE'],
  EUROPE_MIDDLE_EAST_AFRICA: ['NIKE'],
  GREATER_CHINA: ['NIKE'],
  NORTH_AMERICA: ['NIKE', 'CONVERSE'],
};

const shouldEnforceHierarchy = ({
  region, facilityType, brand, testStore,
}) => (!testStore)
      && ['NIKE_OWNED_STORE', 'NIKE_STORE_EXTENSION', 'POPUP_STORE'].includes(facilityType)
      && !!regionToBrandHierarchyMap[region]?.includes(brand);

export default class StoresForm extends React.Component {
  constructor(props) {
    super(props);
    const formData = {
      ...this.props.formData,
      coordinates: this.props.formData.coordinates || { latitude: '', longitude: '' },
      imageUrl: this.props.formData.imageUrl || defaultNSPImageUrl,
      operationalDetails: {
        ...this.props.formData.operationalDetails,
        hoursOfOperation: {
          ...this.props.formData.operationalDetails?.hoursOfOperation,
          regularHours: {
            friday: [],
            monday: [],
            saturday: [],
            sunday: [],
            thursday: [],
            tuesday: [],
            wednesday: [],
            ...this.props.formData.operationalDetails?.hoursOfOperation?.regularHours,
          },
        },
      },
    };
    this.state = {
      enforceHierarchy: shouldEnforceHierarchy(this.props.formData),
      formData,
      formErrors: validateFields(formData, fieldsToValidate, this.props.showIP, this.props.showJE, this.props.adding, isCmpStore(formData)),
      showComparison: false,
      showStoreEditNotice: false,
      showStoreEditPrompt:
          (!this.props.adding
          && cmpCountries.includes(this.props.formData.address?.country)
          && (greyPeriod || freezePeriod)),
      storeEditModalText: 'Are you trying to edit data for a store in the US or Puerto Rico? ',
      storeEditModalTitle: 'Notice!',
      submitError: '',
    };
  }

  Actions = () => (
    <ModalActionsStyled>
      <div className="d-md-flx flx-dir-md-rr">
        {this.state.showStoreEditPrompt ? (
          <>
            <Button
              className="ncss-btn-primary-dark bg mt6-sm ml2-sm border"
              onClick={() => {
                            this.setStateForStoreEditPrompt();
                            this.setState({ showStoreEditPrompt: false });
                            this.setState({ showStoreEditNotice: true });
                          }}
            >
              Yes
            </Button>
            <Button
              className="ncss-btn-primary-dark bg mt6-sm ml2-sm border"
              onClick={() => {
                            this.setState({ showStoreEditPrompt: false });
                          }}
            >
              No
            </Button>
          </>
                    )
                      : (
                        <>
                          <div style={{ fontSize: '16px' }}>
                            <Checkbox
                              id="doNotShowStoreEditNotice"
                              label="Do not show again"
                              onChange={() => this.setState((prev) => ({ doNotShowStoreEditNoticeAgain: !prev.doNotShowStoreEditNoticeAgain }))}
                            />
                          </div>
                          <Button
                            className="ncss-btn-primary-dark bg mt6-sm ml2-sm border"
                            onClick={() => {
                              if (this.state.doNotShowStoreEditNoticeAgain) {
                                if (greyPeriod) {
                                  localStorage.setItem('doNotShowStoreEditNoticeGreyPeriod', 'true');
                                } else if (freezePeriod) {
                                  localStorage.setItem('doNotShowStoreEditNoticeFreezePeriod', 'true');
                                }
                              }
                              this.setState({ showStoreEditNotice: false });
                            }}
                          >
                            Got It!
                          </Button>
                        </>
                      )}
      </div>
    </ModalActionsStyled>
  );

  refuseStoreEditNotice = () => (greyPeriod && localStorage.getItem('doNotShowStoreEditNoticeGreyPeriod')) || (freezePeriod && localStorage.getItem('doNotShowStoreEditNoticeFreezePeriod'));

  setStateForStoreEditPrompt = () => {
    if (freezePeriod) {
      this.setState({ storeEditModalTitle: storeEditNoticeModalTitle });
      this.setState({ storeEditModalText: freezePeriodText });
    } else if (greyPeriod) {
      this.setState({ storeEditModalTitle: storeEditNoticeModalTitle });
      this.setState({ storeEditModalText: greyPeriodText });
    }
  };

  submit = (data) => {
    // check if the user has permissions to edit this store
    if (!isUserAllowed(this.props.userGroups, 'SLS.ALL')
        && !isUserAllowed(this.props.userGroups, `SLS.${data.region}`)) {
      this.setState({ submitError: `User does not have permissions to perform actions in the region: ${data.region}` });
      return;
    }
    // confirm if the user has double-checked their data
    // eslint-disable-next-line no-alert
    if (window.confirm('Have you double checked these store settings and ensured these are the values you want to be reflected?')) {
      window.scroll(0, 0);
      this.props.submit(data);
    }
  };

  // testable helper function for updateFormData function
  /* eslint-disable no-param-reassign */
  transformFormData = (propName, data, formData) => {
    if (data === '') {
      delete formData[propName];
    } else {
      formData[propName] = data;
      if (Array.isArray(formData[propName]) && formData[propName].length === 0) {
        delete formData[propName];
      }
    }
    if (propName !== 'imageUrl' && [defaultNFSImageUrl, defaultNSPImageUrl].includes(formData.imageUrl)) {
      formData.imageUrl = formData.facilityType === 'NIKE_OWNED_STORE' && ['FACTORY', 'CLEARANCE', 'COMMUNITY']
        .includes(formData.businessConcept)
        ? defaultNFSImageUrl
        : defaultNSPImageUrl;
    }

    const formErrors = validateFields(formData, fieldsToValidate, this.props.showIP, this.props.showJE, this.props.adding);
    return {
      enforceHierarchy: shouldEnforceHierarchy(formData), formData, formErrors, submitError: '',
    };
  }
  /* eslint-enable no-param-reassign */

  // all form updates except country end up going through this function
  // country has its own update due to its need to default other fields
  updateFormData = (propName, data) => this.setState((prevState) => {
    const formData = { ...prevState.formData };
    return this.transformFormData(propName, data, formData);
  });

  updateGenericObject = (genericObjectName, genericObjectData, childPropName, childData) => {
    let genericObject = { ...genericObjectData };
    if (childData && typeof childData === 'object') {
      if (Object.values(childData).every((value) => (!value))) {
        // eslint-disable-next-line no-param-reassign
        childData = {};
      }
    }
    if (!childData || (typeof childData === 'object' && Object.keys(childData)?.length === 0)) {
      delete genericObject[childPropName];
      if (Object.keys(genericObject).length === 0) {
        // do this so that the empty object is deleted when we call updateFormData
        genericObject = '';
      }
    } else {
      genericObject[childPropName] = childData;
    }
    this.updateFormData(genericObjectName, genericObject);
  };

  render = () => (
    <article className="ncss-row ta-sm-c va-sm-t">
      <NikeDesignSystemProvider>
        <ModalStyled
          closeModal={() => {
            if (this.state.showStoreEditPrompt) {
              this.setState(() => ({ showStoreEditPrompt: false }));
              this.setState(() => ({ showStoreEditNotice: true }));
              this.setStateForStoreEditPrompt();
            } else if (this.state.showStoreEditNotice) {
              this.setState(() => ({ showStoreEditNotice: false }));
            }
            if (this.state.doNotShowStoreEditNoticeAgain) {
              if (greyPeriod) {
                localStorage.setItem('doNotShowStoreEditNoticeGreyPeriod', 'true');
              } else if (freezePeriod) {
                localStorage.setItem('doNotShowStoreEditNoticeFreezePeriod', 'true');
              }
            }
          }}
          isOpen={((this.state.showStoreEditPrompt || this.state.showStoreEditNotice) && !this.refuseStoreEditNotice())}
          slots={{ FooterSlot: <this.Actions /> }}
          title={this.state.storeEditModalTitle}
        >
          <p>{this.state.storeEditModalText}</p>
        </ModalStyled>
        <div id="modal-root" />
      </NikeDesignSystemProvider>
      {this.props.adding && <header className="ncss-col-sm-12 headline-1">Add Store</header>}
      {!this.props.adding && Object.keys(this.props.formData).length > 0 && (
        <header className="ta-sm-l">
          <h1 className="headline-1">{`${this.props.formData.storeNumber} - ${this.props.formData.name}`}</h1>
          <aside className="ncss-row va-sm-t pt4-sm pb6-sm">
            <section className="ncss-col-sm-6">
              <p className="headline-5">Address:</p>
              <p className="body-2 pl3-sm">
                {this.props.formData.address && (
                  `${this.props.formData.address.address1},
                  ${this.props.formData.address.city},
                  ${this.props.formData.address.state},
                  ${this.props.formData.address.postalCode}`
                )}
              </p>
              <p className="headline-5">Phone:</p>
              <p className="body-2 pl3-sm">{this.props.formData.phone}</p>
              <p className="headline-5">Email:</p>
              <p className="body-2 pl3-sm">{this.props.formData.email}</p>
              <p className="headline-5">Store Id:</p>
              <p className="body-2 pl3-sm">{this.props.formData.id}</p>
            </section>
            <section className="ncss-col-sm-6">
              <p className="headline-5">Hours:</p>
              {Object.keys(this.props.formData.operationalDetails.hoursOfOperation.regularHours).map((day) => {
                const { regularHours } = this.props.formData.operationalDetails.hoursOfOperation;
                return (
                  <p key={day} className="body-2 pl3-sm">
                    {regularHours[day].length > 0
                      ? `${day.charAt(0).toUpperCase() + day.slice(1)}: ${regularHours[day][0].localOpenTime} - ${regularHours[day][0].localCloseTime}`
                      : 'Closed'}
                  </p>
                );
              })}
            </section>
          </aside>
        </header>
      )}
      <aside className="ncss-col-sm-12 pb8-sm">
        <ButtonBlack
          label={`${this.state.showComparison ? 'Hide' : 'View'} Generated Fields`}
          onClick={() => this.setState((prevState) => ({ showComparison: !prevState.showComparison }))}
        />
        {this.state.showComparison && (
          <article className="ncss-row ta-sm-l pb4-sm">
            <section className="ncss-col-sm-6 va-sm-t text-color-error">
              Initial Store Data:
              {/* the initial formData */}
              <JSONFormatter data={this.props.formData} />
            </section>
            <section className="ncss-col-sm-6 va-sm-t text-color-success">
              Altered Store Data:
              {/* the edited formData */}
              <JSONFormatter data={this.state.formData} />
            </section>
          </article>
        )}
      </aside>
      <main className="ncss-row ta-sm-l bg-primary-grey pt2-sm prl2-sm border">
        <General
          adding={this.props.adding}
          disableCmpFields={isCmpStore(this.state.formData)}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          history={this.props.history}
          stores={this.props.stores}
          updateCountry={(country) => this.setState((prevState) => {
            const { formData } = prevState;
            formData.address = {};
            formData.address.country = country;
            formData.region = countryRegionMap[country] || '';
            formData.submitError = '';
            const formErrors = validateFields(formData, fieldsToValidate, this.props.showIP, this.props.showJE, this.props.adding);
            return ({ formData, formErrors });
          })}
          updateFormData={this.updateFormData}
          userIsOwner={this.props.userIsOwner}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Contact
          adding={this.props.adding}
          disableCmpFields={isCmpStore(this.state.formData)}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          updateManagerName={(childProp, childData) => this.updateGenericObject('storeManager', this.state.formData.storeManager, childProp, childData)}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Address
          adding={this.props.adding}
          disableCmpFields={isCmpStore(this.state.formData)}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateAddress={(childProp, childData) => this.updateGenericObject('address', this.state.formData.address, childProp, childData)}
          updateFormData={this.updateFormData}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Localization
          adding={this.props.adding}
          disableCmpFields={isCmpStore(this.state.formData)}
          enforceHierarchy={this.state.enforceHierarchy}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          updateLocalization={(childProp, childData) => this.updateGenericObject('localizations', this.state.formData.localizations, childProp, childData)}
          userIsReadOnly={this.props.userIsReadOnly}
          userToken={this.props.userToken}
        />
        <Service
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          offeringsOptions={this.props.offeringsOptions}
          updateFormData={this.updateFormData}
          userIsReadOnly={this.props.userIsReadOnly}
          userToken={this.props.userToken}
          username={this.props.username}
        />
        <Operational
          adding={this.props.adding}
          disableCmpFields={isCmpStore(this.state.formData)}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          updateGenericObject={this.updateGenericObject}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Partner
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <SellingArea
          adding={this.props.adding}
          disableCmpFields={isCmpStore(this.state.formData)}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateSellingArea={(childProp, childData) => this.updateGenericObject('sellingArea', this.state.formData.sellingArea, childProp, childData)}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        {this.props.showIP && !this.props.adding && (
          <IslandPacific
            islandPacific={this.state.formData.islandPacific || {}}
            islandPacificErrors={this.state.formErrors.islandPacific || {}}
            updateIslandPacific={(childProp, childData) => this.updateGenericObject('islandPacific', this.state.formData.islandPacific, childProp, childData)}
            userIsReadOnly={this.props.userIsReadOnly}
          />
        )}
        {this.props.showJE && !this.props.adding && (
          <JustEnough
            justEnough={this.state.formData.justEnough || {}}
            justEnoughErrors={this.state.formErrors.justEnough || {}}
            updateJustEnough={(childProp, childData) => this.updateGenericObject('justEnough', this.state.formData.justEnough, childProp, childData)}
            userIsReadOnly={this.props.userIsReadOnly}
          />
        )}
        <NikeApp
          adding={this.props.adding}
          geoFence={this.state.formData.geoFence || {}}
          geoFenceErrors={this.state.formErrors.geoFence || {}}
          nikeApp={this.state.formData.nikeApp || {}}
          nikeAppErrors={this.state.formErrors.nikeApp || {}}
          updateGeoFence={(childProp, childData) => this.updateGenericObject('geoFence', this.state.formData.geoFence, childProp, childData)}
          updateNikeApp={(childProp, childData) => this.updateGenericObject('nikeApp', this.state.formData.nikeApp, childProp, childData)}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        {this.props.showCMP && !this.props.adding && (
          <Cmp cmp={this.state.formData.cmp ?? {}} userToken={this.props.userToken} />
        )}
        <Other
          adding={this.props.adding}
          disableCmpFields={isCmpStore(this.state.formData)}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        {!this.props.adding
              && (
              <StoreHistory
                storeId={this.state.formData.id}
              />
              )}
      </main>
      <footer className="ncss-row">
        <ButtonSubmit
          isDisabled={this.state.formErrors.errors || this.props.userIsReadOnly || (freezePeriod && cmpCountries.includes(this.state.formData.address.country))}
          submitError={this.state.submitError}
          onClick={() => this.submit(this.state.formData)}
        />
      </footer>
    </article>
  );
}

StoresForm.defaultProps = {
  formData: {},
};

StoresForm.propTypes = {
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape(),
  history: PropTypes.shape().isRequired,
  offeringsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showCMP: PropTypes.bool.isRequired,
  showIP: PropTypes.bool.isRequired,
  showJE: PropTypes.bool.isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  submit: PropTypes.func.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  userIsOwner: PropTypes.bool.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};
