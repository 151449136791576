{
    "lastA11yAudit": "07/08/22",
    "name": "Link",
    "description": "Link allows users to navigate to and from pages.",
    "docs": "#/components/buttons/Link",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Link",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=608%3A503",
    "tags": [
        "Text",
        "Typography",
        "Link",
        "Anchor",
        "CTA",
        "Button",
        "call to action",
        "hyperlink"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
