import { isSome, sortBy } from '@nike/rcf-fp';

import { frameUser } from '../../../utils/tab-permissions';

const REGION_GROUP_LOOKUP = {
    CONVERSE: {
        ASIA_PACIFIC_LATIN_AMERICA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.ConverseAPLA-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.ConverseAPLA',
        },
        EUROPE_MIDDLE_EAST_AFRICA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.ConverseEMEA-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.ConverseEMEA',
        },
        GREATER_CHINA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.ConverseGC-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.ConverseGC',
        },
        NORTH_AMERICA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.ConverseNA-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.ConverseNA',
        },
    },
    NIKE: {
        ASIA_PACIFIC_LATIN_AMERICA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.NikeAPLA-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.NikeAPLA',
        },
        EUROPE_MIDDLE_EAST_AFRICA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.NikeEMEA-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.NikeEMEA',
        },
        GREATER_CHINA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.NikeGC-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.NikeGC',
        },
        NORTH_AMERICA: {
            READ_ONLY_GROUP: 'App.StoreHierarchy.NikeNA-ReadOnly',
            READ_WRITE_GROUP: 'App.StoreHierarchy.NikeNA',
        },
    },
};

const STORE_HIERARCHY_USER_GROUP = 'App.RetailOperations.Users.StoreHierarchy';
const GLOBAL_ADMIN_GROUP = 'App.StoreHierarchy.GlobalAdmin';
const GLOBAL_DELETE_GROUP = 'App.StoreHierarchy.DeletePermission';

/**
 * Get user groups
 * @param {string[]} groupsFromAuth - user groups from auth
 * @returns {Set<string>} user groups
 */
export const getUserStoreHierarchyGroups = (groupsFromAuth = []) => {
    const environment = process.env.NODE_ENV;
    const groups = environment === 'development'
        ? frameUser
        : groupsFromAuth;
    const storeHierarchyGroups = groups
        .filter((group) => group === STORE_HIERARCHY_USER_GROUP || group.startsWith('App.StoreHierarchy.'));
    return new Set(storeHierarchyGroups);
};

export const userHasWritePermissionForRegion = (groups, hierarchyLookup, region) => {
    const regionName = region?.name ?? '';
    const brandName = hierarchyLookup?.[region?.parentId]?.name ?? '';
    const regionReadWriteGroup = REGION_GROUP_LOOKUP?.[brandName]?.[regionName]?.READ_WRITE_GROUP ?? '';
    return groups.has(GLOBAL_ADMIN_GROUP) || groups.has(regionReadWriteGroup);
};

export const userCanEditTerritory = (groups, hierarchyLookup, territory) => userHasWritePermissionForRegion(groups, hierarchyLookup, hierarchyLookup?.[territory?.parentId]);

export const userCanDeleteTerritory = (groups, hierarchyLookup, territory) => {
    const userCanEdit = userCanEditTerritory(groups, hierarchyLookup, territory);
    const userHasDeletePermission = groups.has(GLOBAL_DELETE_GROUP) || groups.has(GLOBAL_ADMIN_GROUP);
    const territoryHasNoChildren = sortBy(Object.values(hierarchyLookup, ({ name }) => name)).every((otherNode) => otherNode?.parentId !== territory?.id);
    return userCanEdit && userHasDeletePermission && territoryHasNoChildren;
};

export const userCanEditDistrict = (groups, hierarchyLookup, district) => userCanEditTerritory(groups, hierarchyLookup, hierarchyLookup[district?.parentId]);

export const userCanDeleteDistrict = (groups, hierarchyLookup, districtStores, district) => {
    const userCanEdit = userCanEditDistrict(groups, hierarchyLookup, district);
    const userHasDeletePermission = groups.has(GLOBAL_DELETE_GROUP) || groups.has(GLOBAL_ADMIN_GROUP);
    const districtHasNoStores = isSome(districtStores) && districtStores.length === 0;
    return userCanEdit && userHasDeletePermission && districtHasNoStores;
};
