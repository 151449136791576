import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-table';
import { bindActionCreators } from 'redux';

import {
  zonesFetch, zoneUpdate, zoneCreate, zoneDelete, setCurrentZone, localizationsFetch, clearZonesResults,
} from '../../actions/zones';
import { loadState } from '../../utils/local-storage';
import { zonesPermissions } from '../../utils/tab-permissions';
import {
  ButtonBlack, ButtonRed, PageTemplate, StoreSelect,
} from '../reusable';

import Form from './form';

const DEFAULT_TABLE_SIZE = 10;
const storeviewsFields = ['id'];

class Zones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentZone: {},
      editing: false,
      formOpen: false,
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
    };
  }

  cancelForm = () => this.setState({ currentZone: {}, formOpen: false });

  selectCountry = (countrySelected) => this.setState({ selectedCountry: countrySelected, selectedStore: null });

  selectStore = (storeData) => this.setState({ currentZone: {}, formOpen: false, selectedStore: storeData },
    () => this.props.fetchZones(storeData.id, this.props.accessToken));

  render = () => (
    <PageTemplate
      auth={zonesPermissions}
      description="Manage retail store zones, which help consumers and athletes navigate the store."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Zones+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-10 ta-sm-c mb8-sm">
          <StoreSelect
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            storeviewsFields={storeviewsFields}
          />
          {this.state.formOpen && (
            <Form
              cancelForm={this.cancelForm}
              createZones={this.props.createZones}
              currentStore={this.state.selectedStore}
              currentZone={this.state.currentZone}
              deleteZone={this.props.deleteZone}
              editing={this.state.editing}
              updateZones={this.props.updateZones}
              zoneError={this.props.zoneError}
              zoneSuccess={this.props.zoneSuccess}
            />
          )}
          {!this.state.formOpen && this.state.selectedStore && this.state.selectedStore.id && (
            this.props.fetchZonesRequest === null
              ? (
                <section className="ncss-col-sm-8">
                  {this.props.deleting
                    ? <Loading />
                    : (
                      <ButtonBlack
                        className="mt6-sm mb3-sm"
                        label="Add New Zone"
                        onClick={() => {
                          this.props.clearZonesResults();
                          this.setState((prevState) => (prevState.formOpen
                            ? { currentZone: {}, formOpen: false }
                            : { editing: false, formOpen: true }));
                        }}
                      />
                    )}
                  {this.props.zoneError && <p className="body-1 text-color-error">{this.props.zoneError}</p>}
                  {this.props.zoneSuccess && <p className="body-1 text-color-success">{this.props.zoneSuccess}</p>}
                  <article className="mt3-sm">
                    <h2 className="mt2-sm ta-sm-c headline-1 bg-black text-color-primary-light">Current Zones</h2>
                    {!this.props.zones.length
                      ? <p className="body-2 mt2-sm">No Zones exist for this Store.</p>
                      : (
                        <Table
                          className="-striped -highlight"
                          columns={[
                            {
                              accessor: 'zoneName',
                              Cell: (d) => d.original.zoneName,
                              Header: 'Zone Name',
                            },
                            {
                              Cell: (thisZone) => (
                                <ButtonBlack
                                  className=""
                                  label="Edit"
                                  onClick={() => {
                                    this.props.settingCurrentZone(thisZone.original);
                                    this.props.fetchLocalizations(thisZone.original.id, this.props.accessToken);
                                    return this.setState({ currentZone: thisZone.original, editing: true, formOpen: true });
                                  }}
                                />
                              ),
                              Header: 'Edit',
                              width: 90,
                            },
                            {
                              Cell: (thisZone) => (
                                <ButtonRed
                                  className=""
                                  label="Delete"
                                  onClick={() => {
                                    this.props.clearZonesResults();
                                    this.props.deleteZone(thisZone.original, this.props.accessToken);
                                  }}
                                />
                              ),
                              Header: 'Delete',
                              width: 100,
                            },
                          ]}
                          data={this.props.zones}
                          getTdProps={() => ({
                            style: {
                              display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'unset',
                            },
                          })}
                          pageSize={(this.props.zones.length > DEFAULT_TABLE_SIZE) ? DEFAULT_TABLE_SIZE : this.props.zones.length}
                          showPagination={this.props.zones.length > DEFAULT_TABLE_SIZE}
                        />
                      )}
                  </article>
                  {this.props.fetchZonesError !== '' && <p className="text-color-error">{this.props.fetchZonesError}</p>}
                </section>
              )
              : <Loading />)}
        </main>
      )}
      path={this.props.location.pathname}
      title="Zones"
    />
  );
}

Zones.defaultProps = {
  accessToken: undefined,
};

Zones.propTypes = {
  accessToken: PropTypes.string,
  clearZonesResults: PropTypes.func.isRequired,
  createZones: PropTypes.func.isRequired,
  deleteZone: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  fetchLocalizations: PropTypes.func.isRequired,
  fetchZones: PropTypes.func.isRequired,
  fetchZonesError: PropTypes.string.isRequired,
  /* eslint-disable react/require-default-props */
  fetchZonesRequest: PropTypes.shape(),
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  settingCurrentZone: PropTypes.func.isRequired,
  updateZones: PropTypes.func.isRequired,
  zoneError: PropTypes.string.isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape).isRequired,
  zoneSuccess: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
  deleting: state.zonesReducer.deleting,
  fetchZonesError: state.zonesReducer.fetchZonesError,
  fetchZonesRequest: state.zonesReducer.fetchZonesRequest,
  zoneError: state.zonesReducer.zoneError,
  zones: state.zonesReducer.zones,
  zoneSuccess: state.zonesReducer.zoneSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  clearZonesResults: bindActionCreators(clearZonesResults, dispatch),
  createZones: bindActionCreators(zoneCreate, dispatch),
  deleteZone: bindActionCreators(zoneDelete, dispatch),
  fetchLocalizations: bindActionCreators(localizationsFetch, dispatch),
  fetchZones: bindActionCreators(zonesFetch, dispatch),
  settingCurrentZone: bindActionCreators(setCurrentZone, dispatch),
  updateZones: bindActionCreators(zoneUpdate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Zones);
