// associates a key-value pair with an object
export const assoc = (obj, key, val) => ({ ...obj, [key]: val });

// disassociates a key with an object
export const dissoc = (obj, key) => {
  const result = { ...obj };
  delete result[key];
  return result;
};

// disassociates an array of keys with an object
export const dissocAll = (obj, keys) => {
  const result = { ...obj };
  keys.forEach((key) => { delete result[key]; });
  return result;
};
