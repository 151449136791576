{
    "lastA11yAudit": "",
    "name": "Legend",
    "docs": "#/components/legend",
    "description": "Legend is a component.",
    "figma": "",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Legend",
    "tags": ["legend"],
    "designStatuses": ["Beta"],
    "devStatuses": ["Beta"]
}
