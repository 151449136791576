import PropTypes from 'prop-types';
import React from 'react';

import OpenDuration from './OpenDuration';

class RegularHours extends React.Component {
  openDurationDisplay = (day, localOpenTime, localCloseTime, i) => (
    <article key={day}>
      <aside className="ncss-col-sm-2 va-sm-t" />
      <OpenDuration
        key={day}
        regular
        close={(checked) => this.props.updateHours(day, checked ? [] : [{ localCloseTime: '22:00', localOpenTime: '08:00' }])}
        // this will update a day's hours when the closed checkbox is toggled, defaulting to open at 8AM and close at 10PM local time
        closed={this.props.hours[day] ? this.props.hours[day].length === 0 : false}
        // this bool decides whether the checkbox is marked or not
        disableCmpFields={this.props.disableCmpFields}
        errorMessage={!this.props.disableCmpFields && this.props.errors[day]}
        index={i}
        localCloseTime={localCloseTime}
        localOpenTime={localOpenTime}
        updateHours={(prop, value, index) => {
          let dayHours = this.props.hours[day];
          if (Array.isArray(dayHours) && index === undefined) {
            dayHours = [{ ...this.props.hours[day][0], [prop]: value }];
          } else if (index === undefined) {
            dayHours = [({ [prop]: value })];
          } else {
            dayHours[index] = { ...this.props.hours[day][index], [prop]: value };
          }
          this.props.updateHours(day, dayHours);
        }}
        userIsReadOnly={this.props.userIsReadOnly}
      />
    </article>
  );

  render = () => (
    <section className="ncss-col-sm-12">
      <header className="headline-5 pt2-sm">Regular Hours</header>
      {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => (
        <section key={day}>
          <p className="body-2">{day.charAt(0).toUpperCase() + day.slice(1)}</p>
          {Array.isArray(this.props.hours[day]) && this.props.hours[day].length > 0
            ? this.props.hours[day].map((openDuration, i) => this.openDurationDisplay(
              day,
              openDuration?.localOpenTime || '',
              openDuration?.localCloseTime || '',
              i,
            ))
            : this.openDurationDisplay(day, '', '')}
        </section>
      ))}
    </section>
  );
}

RegularHours.propTypes = {
  disableCmpFields: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  hours: PropTypes.shape().isRequired,
  updateHours: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default RegularHours;
