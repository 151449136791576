import { NikeDesignSystemProvider } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { getStoreOfferingById, initStoreOfferingAutoTranslation, putStoreOffering } from '../../../../utils/service-calls/sls';
import { autoTranslationLanguages } from '../../../../utils/static/sls-property-values';
import {
  ButtonBlack,
  Table,
} from '../../../reusable';

import ModalEditTranslation from './ModalEditTranslation';
import ModalNewTranslation from './ModalNewTranslation';
import ModalStatus from './ModalStatus';

const styledOfferingCell = (d) => <div style={{ whiteSpace: 'normal' }}>{d.value}</div>;

const OfferingLocalizationsForm = ({
  fetchUpdatedOffering, localizations, offering, userToken,
}) => {
  const offeringId = offering.id;
  const [editIndex, setEditIndex] = useState(NaN);
  const [editLocalization, setEditLocalization] = useState({});
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState({});
  const [isModalMessageOpen, setIsModalMessageOpen] = useState(false);
  const [isModalMessageLoading, setIsModalMessageLoading] = useState(false);
  const [modalMessageTitle, setModalMessageTitle] = useState('');
  const languagesNotYetLocalized = autoTranslationLanguages.filter(({ value }) => !localizations.some(({ language }) => language === value));

  const deleteTranslation = (offeringID, index) => getStoreOfferingById(userToken, encodeURIComponent(offeringID))
    .then((data) => {
      const offeringToPut = {
        ...data.body,
        id: encodeURIComponent(data.body.id),
        localizations: [
          ...data.body.localizations.slice(0, index),
          ...data.body.localizations.slice(index + 1)],
      };
      return putStoreOffering(userToken, offeringToPut);
    });

  const autoTranslation = async (id, data) => {
    const offeringTranslationsToPost = {
      id,
      targetLanguages: [data.original.language],
    };
    return initStoreOfferingAutoTranslation(userToken, offeringTranslationsToPost);
  };

  const deleteCell = (data) => (
    <ButtonBlack
      className=""
      label="Delete"
      onClick={async () => {
        setModalMessageTitle('Delete translation');
        setIsModalMessageLoading(true);
        setStatusMessage({ message: 'Initiating translation delete', status: 'initiating' });
        setIsModalMessageOpen(true);
        return deleteTranslation(offeringId, data.index)
          .then(async () => {
            await fetchUpdatedOffering();
            return setStatusMessage({ message: 'Successfully deleted translation!', status: 'success' });
          })
          .catch((err) => setStatusMessage({ message: `Failed with error: ${err.message}`, status: 'failed' }))
          .finally(() => setIsModalMessageLoading(false));
      }}
    />
  );

  const editCell = (data) => (
    <ButtonBlack
      className="mt0-sm"
      label="Edit"
      onClick={() => {
        setEditIndex(data.index);
        setEditLocalization(data.original);
        setIsModalEditOpen(true);
      }}
    />
  );

  const autoTranslateCell = (data) => (
    <ButtonBlack
      className=""
      label="Auto Translate"
      onClick={async () => {
        setModalMessageTitle('Autotranslation');
        setIsModalMessageLoading(true);
        setStatusMessage({ message: 'Initiating autotranslation', status: 'initiating' });
        setIsModalMessageOpen(true);
        return autoTranslation(offeringId, data)
          .then(() => {
            setStatusMessage({ message: 'Successfully initiated autotranslation!', status: 'success' });
            return fetchUpdatedOffering();
          })
          .catch((err) => setStatusMessage({ message: `Failed with error: ${err.message}`, status: 'failed' }))
          .finally(() => setIsModalMessageLoading(false));
      }}
    />
  );

  return (
    <NikeDesignSystemProvider>
      <section>
        <header className="u-rounded bg-black va-sm-m pt2-sm pb2-sm mb2-sm">
          <span className="headline-3 pl3-sm text-color-primary-light">
            Offering Localizations
          </span>
        </header>
        <ModalNewTranslation
          fetchUpdatedOffering={fetchUpdatedOffering}
          languagesNotYetLocalized={languagesNotYetLocalized}
          offeringId={offeringId}
          userToken={userToken}
        />
        <ModalEditTranslation
          fetchUpdatedOffering={fetchUpdatedOffering}
          index={editIndex}
          isOpen={isModalEditOpen}
          localization={editLocalization}
          offeringId={offeringId}
          setEditIndex={setEditIndex}
          setEditLocalization={setEditLocalization}
          setIsOpen={setIsModalEditOpen}
          userToken={userToken}
        />
        <ModalStatus
          isLoading={isModalMessageLoading}
          isOpen={isModalMessageOpen}
          message={statusMessage}
          setIsOpen={setIsModalMessageOpen}
          setMessage={setStatusMessage}
          setTitle={setModalMessageTitle}
          title={modalMessageTitle}
        />
        <Table
          columns={[
            {
              accessor: 'languageLabel',
              Header: 'Target Language',
            },
            {
              accessor: 'offering.name',
              Cell: styledOfferingCell,
              Header: 'Offering Name',
            },
            {
              accessor: 'offering.description',
              Cell: styledOfferingCell,
              Header: 'Offering Description',
            },
            {
              accessor: 'status',
              Header: 'Translation Status',
            },
            {
              Cell: editCell,
            },
            {
              Cell: deleteCell,
            },
            {
              Cell: autoTranslateCell,
            },
          ]}
          data={localizations}
          getTdProps={() => ({
            style: {
              display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'normal',
            },
          })}
        />
        <h5 className="headline-5 mb10-sm">
          * Autotranslations can take up to 72 hours to process and return results to the table above. Translations that are complete will be denoted with a Translation Status of COMPLETED
        </h5>
      </section>
    </NikeDesignSystemProvider>
  );
};

export default OfferingLocalizationsForm;

OfferingLocalizationsForm.defaultProps = {
  localizations: [],
};

OfferingLocalizationsForm.propTypes = {
  fetchUpdatedOffering: PropTypes.func.isRequired,
  localizations: PropTypes.arrayOf(PropTypes.shape()),
  offering: PropTypes.shape({
    description: PropTypes.string.isRequired,
    externalUrl: PropTypes.string,
    externalUrlText: PropTypes.string,
    id: PropTypes.string,
    localizations: PropTypes.arrayOf(PropTypes.shape()),
    name: PropTypes.string.isRequired,
    publicOffering: PropTypes.bool.isRequired,
    serviceImage: PropTypes.string.isRequired,
  }).isRequired,
  userToken: PropTypes.string.isRequired,
};
