/* eslint-disable react/no-array-index-key */
import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../reusable';

import ButtonRedAligned from './ButtonRedAligned';
import ButtonSubmitAligned from './ButtonSubmitAligned';

const SinglePartIds = ({
  createBlankQueryId,
  transactionIds,
  setTransactionIds,
  queryTypeLabel,
  setCheckingIngestStatus,
  maxRows,
}) => {
  const updateId = (newId, idxToUpdate) => {
    const updatedTransactionIds = transactionIds.map((id, idx) => (idx === idxToUpdate
      ? newId
      : id));
    setTransactionIds(updatedTransactionIds);
  };
  const addNewRow = () => setTransactionIds([...transactionIds, createBlankQueryId()]);
  const removeRow = (idxToRemove) => setTransactionIds(transactionIds.filter((_, idx) => idx !== idxToRemove));
  const formIsValid = transactionIds.every((id) => id !== '');
  return (
    <>
      {
      transactionIds.map((id, idx) => (
        <div key={`tx-id-row-${idx}`} className="ncss-row">
          <div className="ncss-col-sm-1" />
          <div className="ncss-col-sm-4">
            <Input
              key={`transactionId-${idx}`}
              errorMessage=""
              id={`transactionId-${idx}`}
              label={idx === 0 ? queryTypeLabel : ''}
              placeholder={queryTypeLabel}
              type="text"
              value={id}
              onChange={(event) => updateId(event?.target?.value ?? '', idx)}
            />
          </div>
          <div className="ncss-col-sm-1 ta-sm-l">
            { idx !== 0 && idx === transactionIds.length - 1 && (
              <ButtonRedAligned
                className=""
                label="x"
                onClick={() => removeRow(idx)}
              />
            ) }
          </div>
        </div>
      ))
    }
      <div className="ncss-row">
        <div className="ncss-col-sm-5" />
        <div className="ncss-col-sm-1 ta-sm-l">
          <ButtonBlack
            className=""
            isDisabled={transactionIds.length >= maxRows}
            label="+"
            onClick={addNewRow}
          />
        </div>
      </div>
      <footer className="ncss-row">
        <ButtonSubmitAligned
          className=""
          isDisabled={!formIsValid}
          label="Submit"
          showErrorMessage={false}
          onClick={() => setCheckingIngestStatus(true)}
        />
      </footer>

    </>
  );
};

SinglePartIds.propTypes = {
  createBlankQueryId: PropTypes.func.isRequired,
  maxRows: PropTypes.number.isRequired,
  queryTypeLabel: PropTypes.string.isRequired,
  setCheckingIngestStatus: PropTypes.func.isRequired,
  setTransactionIds: PropTypes.func.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SinglePartIds;
