import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { WORKSTATION_DEVICE_TYPES } from '../../constants/registerleveltracking';
import { loadState } from '../../utils/local-storage';
import { getErrorMessage, groupWorkstationRecords } from '../../utils/rlt-utils';
import { getRegisters } from '../../utils/service-calls/register-level-tracking';
import {
  ButtonBlack, StoreSelect, Select,
} from '../reusable';

import RegistersList from './RegistersList';

const storeviewsFields = [
  'id',
  'storeNumber',
];

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceType: 'ALL',
      fetching: false,
      isSubmitted: false,
      messageError: '',
      registers: [],
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
    };
  }

  onInputChange = (value) => this.setState({ deviceType: value, isSubmitted: false, registers: [] });

  onSearchClick = async () => {
    this.setState({ fetching: true, isSubmitted: true });
    this.registersGet(this.props.userInfo.accessToken, this.state.selectedStore.id, this.state.deviceType)
      .then((res) => (this.setState({ fetching: false, registers: res })))
      .catch((err) => this.setState({ fetching: false, messageError: getErrorMessage(err) }));
  };

  registersGet = async (token, storeId, deviceType) => {
    const queryParams = `filter=storeId(${storeId})${deviceType === 'ALL' ? '' : (`,type(${deviceType})`)}`;
    const registersList = await getRegisters(token, queryParams, deviceType)
      .then((response) => groupWorkstationRecords(response.body.objects))
      .catch((err) => { throw err; });
    return registersList;
  }

  resetRegisters = () => this.setState({ registers: [] });

  selectCountry = (selectedCountry) => this.setState({
    messageError: '',
    registers: [],
    selectedCountry,
    selectedStore: null,
  });

  selectStore = (storeData) => this.setState({ isSubmitted: false, registers: [], selectedStore: storeData });

  render = () => (
    <main className="ncss-col-sm-10">
      {this.props.auth === 'RLT.ReadOnly' && (
        <h1 className="headline-1 mb4-sm text-color-accent">Read Only</h1>
      )}
      {
        <>
          <StoreSelect
            singleColumn
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            storeviewsFields={storeviewsFields}
          />
          <section className="ncss-row full-width">
            <article className="ncss-col-sm-6 va-sm-t ta-sm-l m1-sm">
              <Select
                id="deviceType"
                label="Device Type"
                options={[{ label: 'All', value: 'ALL' }, ...WORKSTATION_DEVICE_TYPES]}
                value={this.state.deviceType}
                onChange={(deviceType) => this.onInputChange(deviceType)}
              />
            </article>
            <article className="ncss-col-sm-6 va-sm-t ta-sm-c m2-sm">
              <ButtonBlack
                isDisabled={!this.state.selectedStore}
                isLoading={this.state.fetching}
                label="Search"
                onClick={this.onSearchClick}
              />
            </article>
          </section>
        </>
      }
      {!this.state.fetching && this.state.selectedStore && this.state.isSubmitted && <RegistersList deviceType={this.state.deviceType} isReadOnly={this.props.auth === 'RLT.ReadOnly'} messageError={this.state.messageError} registers={this.state.registers} selectedStore={this.state.selectedStore} onSearchClick={this.onSearchClick} />}
    </main>
  );
}

Container.propTypes = {
  auth: PropTypes.string.isRequired,
  userInfo: PropTypes.shape({
    accessToken: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInfo: state.authorizationReducer.auth,
});

export default connect(mapStateToProps, null)(Container);
