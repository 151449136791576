import { Checkbox } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../reusable';

import ButtonRedAligned from './ButtonRedAligned';

const ViewIngestStatusResults = ({
  ingestStatusResults,
  setTransactionIdsToReplay,
  transactionIdsToReplay,
  setIngestStatusDetails,
  setNavigation,
  setReplayingTransactions,
  transactionIds,
  replayTransactionsError,
}) => {
  const handleRowSelect = (transactionId, checked) => {
    const newTransactionIdsToReplay = checked
      ? [...transactionIdsToReplay, transactionId]
      : transactionIdsToReplay.filter((id) => id !== transactionId);
    setTransactionIdsToReplay(newTransactionIdsToReplay);
  };

  const handleSelectAll = (checked) => {
    const newTransactionIdsToReplay = checked
      ? ingestStatusResults.map(({ transactionId }) => transactionId)
      : [];
    setTransactionIdsToReplay(newTransactionIdsToReplay);
  };

  const handleViewDetails = (transaction) => {
    setIngestStatusDetails(transaction);
    setNavigation('view-ingest-status-details');
  };

  return (
    <div className="ncss-container p4-sm">
      <div className="ncss-row">
        <div className="ncss-col-sm-1">
          <ButtonBlack
            className=""
            label="Back"
            onClick={() => setNavigation('check-ingest-status')}
          />
        </div>
        <div className="ncss-col-sm-11" />
      </div>

      { replayTransactionsError !== '' && (
        <div className="ncss-row">
          <div className="ncss-col-sm-12 text-color-error">{replayTransactionsError}</div>
        </div>
      )}

      <h3 className="headline-3">Transactions</h3>

      <div className="ncss-row">
        <div className="ncss-col-sm-12">
          {`Found ${ingestStatusResults.length} transactions for ${transactionIds.length} search criteria`}
        </div>
      </div>

      { ingestStatusResults.length > 0 && (
      <>
        <div className="ncss-row bg-primary-grey">
          <div className="ncss-col-sm-1 ta-sm-r">
            <Checkbox
              className="ncss-col-sm-1"
              id="ingest-status-result-header-checkbox"
              isChecked={transactionIdsToReplay.length === ingestStatusResults.length && ingestStatusResults.length > 0}
              label=""
              onChange={({ target: { checked } }) => handleSelectAll(checked)}
            />
          </div>
          {[
            ['Store', '2'],
            ['Business Date', '2'],
            ['Transaction', '2'],
            ['Order', '2'],
            ['Workstation', '2'],
            ['Details', '1'],
          ].map(([label, width]) => (
            <div key={`label-${label}`} className={`ncss-col-sm-${width}`}>
              {label}
            </div>
          ))}
        </div>

        {ingestStatusResults.map((transaction) => {
          const {
            transactionId, businessDate, storeNumber, transactionNumber, workstationNumber, orderId,
          } = transaction;
          return (
            <div key={`ingest-status-result-row-${transactionId}`} className="ncss-row">
              <div className="ncss-col-sm-1 ta-sm-r">
                <Checkbox
                  id={`ingest-status-result-checkbox-${transactionId}`}
                  isChecked={transactionIdsToReplay.includes(transactionId)}
                  label=""
                  onChange={({ target: { checked } }) => handleRowSelect(transactionId, checked)}
                />
              </div>
              <div className="ncss-col-sm-2">
                {storeNumber}
              </div>
              <div className="ncss-col-sm-2">
                {businessDate}
              </div>
              <div className="ncss-col-sm-2">
                {transactionNumber}
              </div>
              <div className="ncss-col-sm-2">
                {orderId}
              </div>
              <div className="ncss-col-sm-2">
                {workstationNumber}
              </div>
              <div className="ncss-col-sm-1">
                <ButtonBlack
                  className=""
                  label="Details"
                  onClick={() => handleViewDetails(transaction)}
                />
              </div>
            </div>
          );
        })}
        <div className="ncss-row ">
          <ButtonRedAligned
            className=""
            confirmationMessage={`Are you sure you want to reprocess ${transactionIdsToReplay.length} transactions?`}
            isDisabled={transactionIdsToReplay.length === 0}
            label="Reprocess Transactions"
            onClick={() => setReplayingTransactions(true)}
          />
        </div>
      </>
      )}
    </div>
  );
};

ViewIngestStatusResults.propTypes = {
  ingestStatusResults: PropTypes.arrayOf(PropTypes.shape).isRequired,
  replayTransactionsError: PropTypes.string.isRequired,
  setIngestStatusDetails: PropTypes.func.isRequired,
  setNavigation: PropTypes.func.isRequired,
  setReplayingTransactions: PropTypes.func.isRequired,
  setTransactionIdsToReplay: PropTypes.func.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  transactionIdsToReplay: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ViewIngestStatusResults;
