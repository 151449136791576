const { regionValues } = require('./sls-property-values');

const regionMappings = regionValues.map((region) => ({ label: region, value: region }));

const companyIdMappings = [
  {
    label: 'Belep',
    value: '11a66682-7ec7-4315-b794-8fbbbe05053e',
  },
  {
    label: 'Daeyeon',
    value: '98c5bc64-db00-4cab-8928-15cc4e5df06f',
  },
  {
    label: 'Eungwang',
    value: '821885f6-bfe9-4943-9151-8386bbeb7c59',
  },
  {
    label: 'Fox',
    value: 'fa767e36-b2fd-4169-86bf-2bad6db5dc1d',
  },
  {
    label: 'Nike',
    value: '169282cc-1f84-44ce-bc51-92c3142d9da4',
  },
  {
    label: 'Percassi',
    value: 'c2078067-14b4-4356-9dae-a988c2aa5cd7',
  },
  {
    label: 'Winwin',
    value: '3e7907ed-bf61-4fc1-99be-42418cf862b9',
  },
];

const scopeLevelMappings = [
  {
    label: 'COMPANY',
    value: 'COMPANY',
  },
  {
    label: 'REGION',
    value: 'REGION',
  },
  {
    label: 'COUNTRY',
    value: 'COUNTRY',
  },
  {
    label: 'STORE',
    value: 'STORE',
  },
];

module.exports = { companyIdMappings, regionMappings, scopeLevelMappings };
