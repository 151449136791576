import { Input, Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  ticketDelete, ticketsFetch,
  waitlineDelete, waitlineSave,
} from '../../../actions/waitlines';
import { REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import { ButtonRed, ButtonSubmit, CustomPanel } from '../../reusable';

import TicketInfo from './TicketInfo';

const fetchTicketsInterval = 30000;

class WaitlineInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      name: '',
    };
    this.intervals = [];
    this.panelToggle = this.panelToggle.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDeleteWaitline = this.handleDeleteWaitline.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.clearIntervals();
  }

  clearIntervals = () => {
    this.intervals.forEach(clearInterval);
  }

  handleDeleteWaitline = () => {
    if (this.props.tickets.length !== 0) {
      this.props.tickets.forEach((ticket) => Promise.all(this.props.ticketDelete(this.props.accessToken, ticket.id, this.props.waitline.id))
        .then(() => this.props.waitlineDelete(this.props.accessToken, this.props.waitline.id))
        // Ignore 404 errors, since we just care that the resource is gone.
        .catch((error) => (error.statusCode === 404 ? null : error)));
    } else {
      this.props.waitlineDelete(this.props.accessToken, this.props.waitline.id);
    }
  }

  handleNameChange = (evt) => {
    this.setState({ name: evt.currentTarget.value });
  }

  handleSubmit = () => {
    this.props.waitlineSave(this.props.accessToken, { ...this.props.waitline, name: this.state.name }, this.props.device);
  }

  panelToggle = () => {
    this.setState((oldState) => {
      const isOpen = !oldState.isOpen;
      if (isOpen) {
        this.startFetchingTickets();
      } else {
        this.stopFetchingTickets();
      }
      return { isOpen };
    });
  }

  setInterval = (...args) => {
    this.intervals.push(setInterval(...args));
  }

  startFetchingTickets = () => {
    const fetchTickets = () => this.props.ticketsFetch(this.props.accessToken, this.props.waitline.id);
    fetchTickets();
    this.setInterval(fetchTickets, fetchTicketsInterval);
  }

  stopFetchingTickets = () => {
    this.clearIntervals();
  }

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label={`${this.props.waitline.name} | ${this.props.waitline.alternateId}`}
      onClick={this.panelToggle}
    >
      <section className="ncss-col-sm-12 p2-sm">
        <p className="body-2">Store ID: {this.props.waitline.storeId}</p>
        <p className="body-2">Waitline ID: {this.props.waitline.id}</p>
        <p className="body-2">Waitline Destination ID: {this.props.waitline.destinationId}</p>
        <p className="body-2">Waitline Destination Type: {this.props.waitline.destinationType}</p>
        <Input
          errorMessage={!this.state.name ? REQUIRED_FIELD : ''}
          id={`waitlineName-${this.props.waitline.id}`}
          label="Waitline Friendly Name"
          placeholder={this.props.waitline.name}
          type="text"
          onChange={this.handleNameChange}
        />
        <ButtonSubmit
          isDisabled={!this.state.name}
          label="Save"
          onClick={this.handleSubmit}
        />
        <ButtonRed
          className="m2-sm"
          label="Delete Waitline"
          onClick={this.handleDeleteWaitline}
        />
        {this.props.status.errors.map((err) => <p key={err.message} className="text-color-error body-2"><i className="mr3-sm g72-x-circle" />{err.message}</p>)}
        {this.props.status.successes.map((success) => <p key={success} className="text-color-error body-2"><i className="mr3-sm g72-check-circle" />{success}</p>)}
        <hr />
        <p className="body-2">Waitline Tickets for {this.props.waitline.name}:</p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {this.props.status.isFetchingTickets
          ? <Loading />
          : this.props.tickets.length === 0
            ? <p className="body-2">There are no active tickets for this Waitline.</p>
            : this.props.tickets.map((ticket) => (
              <TicketInfo key={ticket.id} status={this.props.status.statusByTicket[ticket.id]} ticket={ticket} waitlineID={this.props.waitline.id} />
            ))}
      </section>
    </CustomPanel>
  );
}

WaitlineInfo.defaultProps = {
  accessToken: undefined,
  device: undefined,
};

WaitlineInfo.propTypes = {
  accessToken: PropTypes.string,
  device: PropTypes.shape(),
  status: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isDeleting: PropTypes.bool,
    isFetchingTickets: PropTypes.bool,
    isSaving: PropTypes.bool,
    statusByTicket: PropTypes.shape().isRequired,
    successes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  ticketDelete: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ticketsFetch: PropTypes.func.isRequired,
  waitline: PropTypes.shape({
    alternateId: PropTypes.string,
    destinationId: PropTypes.string,
    destinationType: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    storeId: PropTypes.string,
  }).isRequired,
  waitlineDelete: PropTypes.func.isRequired,
  waitlineSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  ticketDelete: (token, waitlineId) => dispatch(ticketDelete(token, waitlineId)),
  ticketsFetch: (token, waitlineId) => dispatch(ticketsFetch(token, waitlineId)),
  waitlineDelete: (token, waitlineId) => dispatch(waitlineDelete(token, waitlineId)),
  waitlineSave: (token, waitline, device) => dispatch(waitlineSave(token, waitline, device)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitlineInfo);
