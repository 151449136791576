import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getRTLPermissionName } from '../../utils/rlt-utils';
import { PageTemplate } from '../reusable';

import Container from './Container';

const RegisterLevelTracking = (props) => {
  const permissionName = getRTLPermissionName(props.userInfo);
  return (
    <PageTemplate
      auth={permissionName}
      description="View and maintain workstation information."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Register+Level+Tracking"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={<Container auth={permissionName} />}
      path={props.location.pathname}
      title="Register Level Tracking"
    />
  );
};

RegisterLevelTracking.defaultProps = {
  userInfo: {},
};

RegisterLevelTracking.propTypes = {
  location: PropTypes.shape().isRequired,
  userInfo: PropTypes.shape({
    expiresAt: PropTypes.number,
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};

const mapStateToProps = (state) => ({
  userInfo: state.authorizationReducer.auth,
});

export default connect(mapStateToProps, null)(RegisterLevelTracking);
