export const nike = {
  /** * Do not edit directly * Generated on Mon, 08 Apr 2024 21:10:42 GMT */ podiumCdsBreakpointXs:
    "320px",
  podiumCdsBreakpointS: "600px",
  podiumCdsBreakpointM: "960px",
  podiumCdsBreakpointL: "1440px",
  podiumCdsBreakpointXl: "1920px",
  podiumCdsColorBlack: "#111111",
  podiumCdsColorWhite: "#FFFFFF",
  podiumCdsColorRed50: "#FFE5E5",
  podiumCdsColorRed100: "#FFC3C4",
  podiumCdsColorRed200: "#FFABAD",
  podiumCdsColorRed300: "#FF7975",
  podiumCdsColorRed400: "#FF4E4A",
  podiumCdsColorRed500: "#EE0005",
  podiumCdsColorRed600: "#D30005",
  podiumCdsColorRed700: "#A70E00",
  podiumCdsColorRed800: "#780700",
  podiumCdsColorRed900: "#530300",
  podiumCdsColorOrange50: "#FFE2D6",
  podiumCdsColorOrange100: "#FFC5AD",
  podiumCdsColorOrange200: "#FFA584",
  podiumCdsColorOrange300: "#FF8255",
  podiumCdsColorOrange400: "#FF5000",
  podiumCdsColorOrange500: "#D33918",
  podiumCdsColorOrange600: "#9F290F",
  podiumCdsColorOrange700: "#7E200D",
  podiumCdsColorOrange800: "#5D180C",
  podiumCdsColorOrange900: "#3E1009",
  podiumCdsColorYellow50: "#FEF087",
  podiumCdsColorYellow100: "#FEEA55",
  podiumCdsColorYellow200: "#FEDF35",
  podiumCdsColorYellow300: "#FFD11B",
  podiumCdsColorYellow400: "#FDC400",
  podiumCdsColorYellow500: "#FCA600",
  podiumCdsColorYellow600: "#F08E0A",
  podiumCdsColorYellow700: "#E57A09",
  podiumCdsColorYellow800: "#CD6006",
  podiumCdsColorYellow900: "#99470A",
  podiumCdsColorGreen50: "#DFFFB9",
  podiumCdsColorGreen100: "#BDFB72",
  podiumCdsColorGreen200: "#83F13B",
  podiumCdsColorGreen300: "#5FE63F",
  podiumCdsColorGreen400: "#3CCD49",
  podiumCdsColorGreen500: "#1EAA52",
  podiumCdsColorGreen600: "#007D48",
  podiumCdsColorGreen700: "#00643E",
  podiumCdsColorGreen800: "#005034",
  podiumCdsColorGreen900: "#003C2A",
  podiumCdsColorBlue50: "#D6EEFF",
  podiumCdsColorBlue100: "#B9E2FF",
  podiumCdsColorBlue200: "#87CEFF",
  podiumCdsColorBlue300: "#4CABFF",
  podiumCdsColorBlue400: "#1190FF",
  podiumCdsColorBlue500: "#1151FF",
  podiumCdsColorBlue600: "#0034E3",
  podiumCdsColorBlue700: "#061DBB",
  podiumCdsColorBlue800: "#02068E",
  podiumCdsColorBlue900: "#020664",
  podiumCdsColorTeal50: "#D4FFFB",
  podiumCdsColorTeal100: "#9CFFF7",
  podiumCdsColorTeal200: "#69EEE7",
  podiumCdsColorTeal300: "#31CBCB",
  podiumCdsColorTeal400: "#1CB2B6",
  podiumCdsColorTeal500: "#008E98",
  podiumCdsColorTeal600: "#0A7281",
  podiumCdsColorTeal700: "#005C68",
  podiumCdsColorTeal800: "#004856",
  podiumCdsColorTeal900: "#043441",
  podiumCdsColorPurple50: "#E4E1FC",
  podiumCdsColorPurple100: "#D6D1FF",
  podiumCdsColorPurple200: "#BEAFFD",
  podiumCdsColorPurple300: "#A082FF",
  podiumCdsColorPurple400: "#8755FF",
  podiumCdsColorPurple500: "#6E0FF6",
  podiumCdsColorPurple600: "#5400CC",
  podiumCdsColorPurple700: "#4400A9",
  podiumCdsColorPurple800: "#300080",
  podiumCdsColorPurple900: "#1C0060",
  podiumCdsColorPink50: "#FFE1F3",
  podiumCdsColorPink100: "#FFCDEA",
  podiumCdsColorPink200: "#FFB0DD",
  podiumCdsColorPink300: "#FE90CC",
  podiumCdsColorPink400: "#FC5DBB",
  podiumCdsColorPink500: "#ED1AA0",
  podiumCdsColorPink600: "#D00B84",
  podiumCdsColorPink700: "#A50767",
  podiumCdsColorPink800: "#770349",
  podiumCdsColorPink900: "#4C012D",
  podiumCdsColorGrey50: "#FAFAFA",
  podiumCdsColorGrey100: "#F5F5F5",
  podiumCdsColorGrey200: "#E5E5E5",
  podiumCdsColorGrey300: "#CACACB",
  podiumCdsColorGrey400: "#9E9EA0",
  podiumCdsColorGrey500: "#707072",
  podiumCdsColorGrey600: "#4B4B4D",
  podiumCdsColorGrey700: "#39393B",
  podiumCdsColorGrey800: "#28282A",
  podiumCdsColorGrey900: "#1F1F21",
  podiumCdsColorScrim100: "rgba(17,17,17,0.36)",
  podiumCdsColorScrim100Inverse: "rgba(17,17,17,0.80)",
  podiumCdsColorScrimPrimary: "rgba(17,17,17,0.36)",
  podiumCdsColorScrimPrimaryInverse: "rgba(17,17,17,0.80)",
  podiumCdsColorBrandOrange: "#FF5000",
  podiumCdsColorBrandRed: "#EE0005",
  podiumCdsColorGradientBrandOrange:
    "linear-gradient(113.7deg, #FF0015 3.64%, #fe5000 50.92%, #FF6A00 97.26%)",
  podiumCdsColorBgActive: "#F5F5F5",
  podiumCdsColorBgCritical: "#D30005",
  podiumCdsColorBgDisabled: "#E5E5E5",
  podiumCdsColorBgHover: "#E5E5E5",
  podiumCdsColorBgOverlay: "#FFFFFF",
  podiumCdsColorBgPrimary: "#FFFFFF",
  podiumCdsColorBgSecondary: "#F5F5F5",
  podiumCdsColorBgSuccess: "#007D48",
  podiumCdsColorBgWarning: "#FEDF35",
  podiumCdsColorBgHoverInverse: "#39393B",
  podiumCdsColorBgPrimaryInverse: "#1F1F21",
  podiumCdsColorBgOnImagePrimary: "#FFFFFF",
  podiumCdsColorBgOnImageSecondary: "#E5E5E5",
  podiumCdsColorBgAlwaysDark: "#111111",
  podiumCdsColorBgAlwaysLight: "#FFFFFF",
  podiumCdsColorBgModal: "#FFFFFF",
  podiumCdsColorTextCritical: "#D30005",
  podiumCdsColorTextDisabled: "#CACACB",
  podiumCdsColorTextHover: "#707072",
  podiumCdsColorTextLink: "#1151FF",
  podiumCdsColorTextPrimary: "#111111",
  podiumCdsColorTextSecondary: "#707072",
  podiumCdsColorTextSuccess: "#007D48",
  podiumCdsColorTextDisabledInverse: "#39393B",
  podiumCdsColorTextHoverInverse: "#E5E5E5",
  podiumCdsColorTextPrimaryInverse: "#FFFFFF",
  podiumCdsColorTextSecondaryInverse: "#9E9EA0",
  podiumCdsColorTextPrimaryOnLight: "#111111",
  podiumCdsColorTextPrimaryOnDark: "#FFFFFF",
  podiumCdsColorTextSecondaryOnLight: "#707072",
  podiumCdsColorTextSecondaryOnDark: "#9E9EA0",
  podiumCdsColorTextHoverOnLight: "#707072",
  podiumCdsColorTextHoverOnDark: "#E5E5E5",
  podiumCdsColorTextDisabledOnLight: "#CACACB",
  podiumCdsColorTextDisabledOnDark: "#39393B",
  podiumCdsColorBorderActive: "#111111",
  podiumCdsColorBorderCritical: "#D30005",
  podiumCdsColorBorderDisabled: "#CACACB",
  podiumCdsColorBorderPrimary: "#707072",
  podiumCdsColorBorderSecondary: "#CACACB",
  podiumCdsColorBorderTertiary: "#E5E5E5",
  podiumCdsColorBorderSuccess: "#007D48",
  podiumCdsColorBorderActiveInverse: "#FFFFFF",
  podiumCdsColorBorderPrimaryInverse: "#9E9EA0",
  podiumCdsColorBorderPrimaryOnLight: "#707072",
  podiumCdsColorBorderPrimaryOnDark: "#9E9EA0",
  podiumCdsColorBorderActiveOnLight: "#111111",
  podiumCdsColorBorderActiveOnDark: "#FFFFFF",
  podiumCdsColorBorderDisabledOnLight: "#CACACB",
  podiumCdsColorBorderDisabledOnDark: "#39393B",
  podiumCdsColorCritical: "#D30005",
  podiumCdsColorCriticalInverse: "#FF4E4A",
  podiumCdsColorSuccess: "#007D48",
  podiumCdsColorSuccessInverse: "#1EAA52",
  podiumCdsColorInfo: "#1151FF",
  podiumCdsColorInfoInverse: "#1190FF",
  podiumCdsColorWarning: "#FEDF35",
  podiumCdsColorWarningInverse: "#FEEA55",
  podiumCdsColorFocusRing: "#1151FF",
  podiumCdsColorFocusShadow: "rgba(17, 17, 17, 0.12)",
  podiumCdsColorBoxFocusRing: "rgba(39, 93, 197, 1)",
  podiumCdsColorTextBadge: "#D33918",
  podiumCdsButtonBorderRadius: "30px",
  podiumCdsButtonBorderWidth: "1.5px",
  podiumCdsButtonBoxShadowWidth: "0",
  podiumCdsButtonBoxShadowHoverWidth: "0",
  podiumCdsButtonTextDecorationLine: "underline",
  podiumCdsButtonPaddingTopS: "6px",
  podiumCdsButtonPaddingTopM: "12px",
  podiumCdsButtonPaddingTopL: "18px",
  podiumCdsButtonPaddingBottomS: "6px",
  podiumCdsButtonPaddingBottomM: "12px",
  podiumCdsButtonPaddingBottomL: "18px",
  podiumCdsButtonPaddingSidesS: "16px",
  podiumCdsButtonPaddingSidesM: "24px",
  podiumCdsButtonPaddingSidesL: "24px",
  podiumCdsButtonPaddingUnderline: "0",
  podiumCdsButtonHeightS: "34px",
  podiumCdsButtonHeightM: "46px",
  podiumCdsButtonHeightL: "58px",
  podiumCdsButtonColorBgPrimary: "#111111",
  podiumCdsButtonColorBgPrimaryOnLight: "#111111",
  podiumCdsButtonColorBgPrimaryOnDark: "#FFFFFF",
  podiumCdsButtonColorBgPrimaryHover: "#707072",
  podiumCdsButtonColorBgPrimaryHoverOnLight: "#707072",
  podiumCdsButtonColorBgPrimaryHoverOnDark: "#CACACB",
  podiumCdsButtonColorBgPrimaryDisabled: "#E5E5E5",
  podiumCdsButtonColorBgPrimaryDisabledOnLight: "#E5E5E5",
  podiumCdsButtonColorBgPrimaryDisabledOnDark: "#4B4B4D",
  podiumCdsButtonColorBgSecondary: "transparent",
  podiumCdsButtonColorBorderSecondary: "#CACACB",
  podiumCdsButtonColorBorderSecondaryOnLight: "#CACACB",
  podiumCdsButtonColorBorderSecondaryOnDark: "#9E9EA0",
  podiumCdsButtonColorBorderSecondaryHover: "#111111",
  podiumCdsButtonColorBorderSecondaryHoverOnDark: "#FFFFFF",
  podiumCdsButtonColorBorderSecondaryDisabled: "#CACACB",
  podiumCdsButtonColorBorderSecondaryDisabledOnDark: "#4B4B4D",
  podiumCdsDialogBgBlur: "4px",
  podiumCdsIconButtonColorBgPrimary: "#111111",
  podiumCdsIconButtonColorBgPrimaryOnLight: "#111111",
  podiumCdsIconButtonColorBgPrimaryOnDark: "#FFFFFF",
  podiumCdsIconButtonColorBgPrimaryHover: "#707072",
  podiumCdsIconButtonColorBgPrimaryHoverOnLight: "#707072",
  podiumCdsIconButtonColorBgPrimaryHoverOnDark: "#9E9EA0",
  podiumCdsIconButtonColorBgSecondary: "#F5F5F5",
  podiumCdsIconButtonColorBgSecondaryOnLight: "#F5F5F5",
  podiumCdsIconButtonColorBgSecondaryOnDark: "#111111",
  podiumCdsIconButtonColorBgSecondaryHover: "#CACACB",
  podiumCdsIconButtonColorBgSecondaryHoverOnLight: "#CACACB",
  podiumCdsIconButtonColorBgSecondaryHoverOnDark: "#39393B",
  podiumCdsIconButtonColorBgTertiaryHover: "#E5E5E5",
  podiumCdsIconButtonColorBgTertiaryHoverOnLight: "#E5E5E5",
  podiumCdsIconButtonColorBgTertiaryHoverOnDark: "#39393B",
  podiumCdsIconButtonColorBgDisabled: "#E5E5E5",
  podiumCdsIconButtonColorBgDisabledOnLight: "#E5E5E5",
  podiumCdsIconButtonColorBgDisabledOnDark: "#707072",
  podiumCdsInputColorDisabled: "#707072",
  podiumCdsElevation10: "10",
  podiumCdsElevation20: "20",
  podiumCdsElevation30: "30",
  podiumCdsElevation40: "40",
  podiumCdsElevation50: "50",
  podiumCdsElevation60: "60",
  podiumCdsElevation70: "70",
  podiumCdsElevation80: "80",
  podiumCdsElevation90: "90",
  podiumCdsElevation100: "100",
  podiumCdsFontWeightRegular: "400",
  podiumCdsFontWeightMedium: "500",
  podiumCdsFontWeightBold: "700",
  podiumCdsFontSizeXxs: "12px",
  podiumCdsFontSizeXs: "14px",
  podiumCdsFontSizeS: "16px",
  podiumCdsFontSizeM: "20px",
  podiumCdsFontSizeL: "24px",
  podiumCdsFontSizeXl: "28px",
  podiumCdsFontSizeXxl: "32px",
  podiumCdsFontSizeXxxl: "40px",
  podiumCdsFontSizeXxxxl: "48px",
  podiumCdsFontSizeXxxxxl: "72px",
  podiumCdsFontStyleNormal: "normal",
  podiumCdsFormShadowFocus: "0 0 0 12px rgba(17, 17, 17, 0.12)",
  podiumCdsMotionEasingFunctional: "cubic-bezier(0.4, 0, 0.2, 1)",
  podiumCdsMotionEasingExpressive: "cubic-bezier(0.6, 0, 0.1, 1)",
  podiumCdsMotionDelay50: "50ms",
  podiumCdsMotionDelay100: "100ms",
  podiumCdsMotionDelay150: "150ms",
  podiumCdsMotionDelay200: "200ms",
  podiumCdsMotionDelay250: "250ms",
  podiumCdsMotionDuration150: "150ms",
  podiumCdsMotionDuration250: "250ms",
  podiumCdsMotionDuration300: "300ms",
  podiumCdsMotionDuration350: "350ms",
  podiumCdsMotionDuration400: "400ms",
  podiumCdsShadow100:
    "0px 4px 8px 0px rgba(17, 17, 17, 0.06), 0px 0px 24px 4px rgba(17, 17, 17, 0.03)",
  podiumCdsShadow100Inverse:
    "0px 4px 8px 0px rgba(17, 17, 17, 0.18), 0px 0px 24px 4px rgba(17, 17, 17, 0.09)",
  podiumCdsSizeSpacingXs: "4px",
  podiumCdsSizeSpacingS: "8px",
  podiumCdsSizeSpacingM: "12px",
  podiumCdsSizeSpacingL: "24px",
  podiumCdsSizeSpacingXl: "36px",
  podiumCdsSizeSpacingXxl: "60px",
  podiumCdsSizeSpacingXxxl: "84px",
  podiumCdsSizeSpacingXxxxl: "120px",
  podiumCdsSizeSpacingGridGutter: "16px",
  podiumCdsSizeSpacingGridGutterL: "12px",
  podiumCdsSizeSpacingGridExteriorGutterS: "24px",
  podiumCdsSizeSpacingGridExteriorGutterL: "48px",
  podiumCdsSizeBorderRadiusS: "4px",
  podiumCdsSizeBorderRadiusM: "8px",
  podiumCdsSizeBorderRadiusL: "12px",
  podiumCdsSizeBorderRadiusXl: "24px",
  podiumCdsSizeBorderWidthS: "1px",
  podiumCdsSizeBorderWidthM: "1.5px",
  podiumCdsSizeBorderWidthL: "2px",
  podiumCdsSizeIconS: "16px",
  podiumCdsSizeIconM: "24px",
  podiumCdsSizeIconL: "48px",
  podiumCdsSizeFormInputContainerHeight: "86px",
  podiumCdsSizeFormInputFieldHeight: "56px",
  podiumCdsTransitionTimingFunctionEase: "cubic-bezier(.25, .1, .25, 1)",
  podiumCdsTransitionDurationFast: "150ms",
  podiumCdsTransitionDurationNormal: "250ms",
  i18nTokens: {
    "ar-sa": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Display1:
        "800 3rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Display2:
        "800 2.5rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Display3:
        "800 2rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Display4:
        "800 1.5rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5  'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5  'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Display1:
        "800 6rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Display2:
        "800 4.75rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Display3:
        "800 3.75rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Display4:
        "800 3rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "800 7.5rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "800 6rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "800 4.75rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "800 3.75rem/1.6 'Neue Frutiger Arabic Extra Black', 'Myriad Arabic Black', 'SF Arabic Black', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.6 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.6 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'Neue Frutiger Arabic Medium', 'Myriad Arabic Semibold', 'SF Arabic Medium', Helvetica, Tahoma, 'تاهوما', sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'Neue Frutiger Arabic Regular', 'Myriad Arabic', 'SF Arabic Regular', Helvetica, Tahoma, 'تاهوما', sans-serif"
    },
    "ca-es": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display1:
        "800 3rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display2:
        "800 2.5rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display3:
        "800 2rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display4:
        "800 1.5rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.35 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.5 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display1:
        "800 6rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display2:
        "800 4.75rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display3:
        "800 3.75rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display4:
        "800 3rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.25 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "800 7.5rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "800 6rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "800 4.75rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "800 3.75rem/1.25 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.25 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.25 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif"
    },
    "cs-cz": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display1:
        "800 3rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display2:
        "800 2.5rem/1.1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display3:
        "800 2rem/1.1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display4:
        "800 1.5rem/1.1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.5 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display1:
        "800 6rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display2:
        "800 4.75rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display3:
        "800 3.75rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display4:
        "800 3rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.25 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "800 7.5rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "800 6rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "800 4.75rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "800 3.75rem/1 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.25 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.25 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif"
    },
    get "da-dk"() {
      return this["cs-cz"];
    },
    get "de-de"() {
      return this["cs-cz"];
    },
    "el-gr": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display1:
        "800 3rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display2:
        "800 2.5rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display3:
        "800 2rem/1.2 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display4:
        "800 1.5rem/1.2 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display1:
        "800 6rem/1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display2:
        "800 4.75rem/1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display3:
        "800 3.75rem/1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display4:
        "800 3rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.1 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "800 7.5rem/1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "800 6rem/1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "800 4.75rem/1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "800 3.75rem/1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif"
    },
    "en-ca": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display1:
        "800 3rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display2:
        "800 2.5rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display3:
        "800 2rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display4:
        "800 1.5rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display1:
        "800 6rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display2:
        "800 4.75rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display3:
        "800 3.75rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display4:
        "800 3rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.1 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.1 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "800 7.5rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "800 6rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "800 4.75rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "800 3.75rem/.9 'Nike Futura ND', 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.2 'Helvetica Now Display Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.1 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.1 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.1 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.35 'Palatino LT Pro Light', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'Helvetica Now Text Medium', Helvetica, Arial, sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif"
    },
    get "en-gb"() {
      return this["en-ca"];
    },
    get "en-jp"() {
      return this["en-ca"];
    },
    get "en-us"() {
      return this["en-ca"];
    },
    get "es-ar"() {
      return this["cs-cz"];
    },
    get "es-ca"() {
      return this["cs-cz"];
    },
    get "es-cl"() {
      return this["cs-cz"];
    },
    get "es-es"() {
      return this["cs-cz"];
    },
    get "es-la"() {
      return this["cs-cz"];
    },
    get "es-mx"() {
      return this["cs-cz"];
    },
    get "es-uy"() {
      return this["cs-cz"];
    },
    get "fr-fr"() {
      return this["ca-es"];
    },
    get "hu-hu"() {
      return this["cs-cz"];
    },
    get "it-it"() {
      return this["cs-cz"];
    },
    "ja-jp": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Display1:
        "900 3rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Display2:
        "900 2.5rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Display3:
        "900 2rem/1.25 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Display4:
        "900 1.5rem/1.25 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.75 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.5 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.5 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.5 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.75 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Display1:
        "900 6rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Display2:
        "900 4.75rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Display3:
        "900 3.75rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Display4:
        "900 3rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.75 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1.35 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "900 7.5rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "900 6rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "900 4.75rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "900 3.75rem/1.2 'Noto Sans JP Black', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.5 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.5 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.5 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.5 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.5 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.75 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.75 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.75 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.75 'Noto Sans JP Medium', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.75 'Noto Sans JP', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'MS Pgothic', 'ＭＳ Ｐゴシック', sans-serif"
    },
    "ko-kr": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Display1:
        "900 3rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Display2:
        "900 2.5rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Display3:
        "900 2rem/1.25 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Display4:
        "900 1.5rem/1.25 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.25 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.75 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.5 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.5 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.5 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.75 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Display1:
        "900 6rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Display2:
        "900 4.75rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Display3:
        "900 3.75rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Display4:
        "900 3rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.75 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1.35 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "900 7.5rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "900 6rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "900 4.75rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "900 3.75rem/1.2 'Noto Sans KR Black', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.5 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.5 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.5 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.5 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.5 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.75 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.75 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.75 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.75 'Noto Sans KR Medium', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.75 'Noto Sans KR', Helvetica, Arial, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo', 'Malgun Gothic', '맑은 고딕', sans-serif"
    },
    get "nl-en"() {
      return this["cs-cz"];
    },
    get "nl-nl"() {
      return this["cs-cz"];
    },
    get "no-no"() {
      return this["cs-cz"];
    },
    get "pl-pl"() {
      return this["ca-es"];
    },
    get "pt-br"() {
      return this["ca-es"];
    },
    get "pt-pt"() {
      return this["ca-es"];
    },
    "ru-ru": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display1:
        "800 3rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display2:
        "800 2.5rem/1.2 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display3:
        "800 2rem/1.2 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Display4:
        "800 1.5rem/1.2 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display1:
        "800 6rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display2:
        "800 4.75rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display3:
        "800 3.75rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Display4:
        "800 3rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "800 7.5rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "800 6rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "800 4.75rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "800 3.75rem/1.1 'Nike Futura ND','HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.2 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.2 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'HelveticaNeueLTW1G-Md', Helvetica, Arial, sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'HelveticaNeueLTW1G-Roman', Helvetica, Arial, sans-serif"
    },
    get "sv-se"() {
      return this["cs-cz"];
    },
    "th-th": {
      podiumCdsTypography320To959Oversize1:
        "700 6rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Oversize2:
        "700 4.75rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Oversize3:
        "700 3.75rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Display1:
        "800 3rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Display2:
        "800 2.5rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Display3:
        "800 2rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Display4:
        "800 1.5rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To1919Title1:
        "700 2.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To1919Title2:
        "700 2rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To1919Title3:
        "700 1.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography320To1919Title4:
        "700 1.25rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "700 7.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "700 6rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "700 4.75rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1439Title1:
        "700 2.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1439Title2:
        "700 2rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1439Title3:
        "700 1.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1439Title4:
        "700 1.25rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Display1:
        "800 6rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Display2:
        "800 4.75rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Display3:
        "800 3.75rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Display4:
        "800 3rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "700 9.375rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "700 7.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "700 6rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "700 11.625rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "700 9.375rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "700 7.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "800 7.5rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "800 6rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "800 4.75rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "800 3.75rem/1.75 'AvenirNextThaiModern-XtraBd','Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "700 3rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "700 2.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "700 2rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "700 1.5rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody1Strong:
        "700 1rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody2Strong:
        "700 .875rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody3Strong:
        "700 .75rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyBody4Strong:
        "700 .625rem/1.75 'Helvetica Neue LT Thai Bold', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.75 'Helvetica Neue LT Thai', Helvetica, Arial, Krungthep, 'กรุงเทพ', Thonburi, 'ธนบุรี', 'Leelawadee UI', 'ลีลาวดี UI', Tahoma, 'ทาโฮม่า', sans-serif"
    },
    get "tr-tr"() {
      return this["ca-es"];
    },
    "zh-cn": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Display1:
        "400 3rem/1 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Display2:
        "400 2.5rem/1 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Display3:
        "400 2rem/1 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Display4:
        "400 1.5rem/1 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Display1:
        "400 6rem/.9 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Display2:
        "400 4.75rem/.9 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Display3:
        "400 3.75rem/.9 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Display4:
        "400 3rem/1 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "400 7.5rem/.9 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "400 6rem/.9 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "400 4.75rem/.9 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "400 3.75rem/.9 'NikeSC365', 'Noto Sans SC Black', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.2 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.2 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'Noto Sans SC Medium', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'Noto Sans SC', Helvetica, Arial, 'PingFang SC', '苹方-简', 'Heiti SC', '黑体-简', 'Microsoft YaHei', '微软雅黑', sans-serif"
    },
    "zh-hk": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display1:
        "900 3rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display2:
        "900 2.5rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display3:
        "900 2rem/1.15  'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display4:
        "900 1.5rem/1.15  'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display1:
        "900 6rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display2:
        "900 4.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display3:
        "900 3.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display4:
        "900 3rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.2 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "900 7.5rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "900 6rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "900 4.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "900 3.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif"
    },
    "zh-tw": {
      podiumCdsTypography320To959Oversize1:
        "500 6rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Oversize2:
        "500 4.75rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Oversize3:
        "500 3.75rem/1.2 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display1:
        "900 3rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display2:
        "900 2.5rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display3:
        "900 2rem/1.15  'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Display4:
        "900 1.5rem/1.15  'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation1:
        "300 2rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation2:
        "300 1.5rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation3:
        "300 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959Conversation4:
        "300 1rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959EditorialBody1:
        "400 1rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To959EditorialBody1Strong:
        "500 1rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title1:
        "500 2.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title2:
        "500 2rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title3:
        "500 1.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography320To1919Title4:
        "500 1.25rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1439Oversize1:
        "500 7.5rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1439Oversize2:
        "500 6rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1439Oversize3:
        "500 4.75rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display1:
        "900 6rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display2:
        "900 4.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display3:
        "900 3.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Display4:
        "900 3rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation1:
        "300 3rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation2:
        "300 2.5rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation3:
        "300 2rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960To1919Conversation4:
        "300 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960PlusEditorialBody1:
        "400 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography960PlusEditorialBody1Strong:
        "500 1.25rem/1.2 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1440To1919Oversize1:
        "500 9.375rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1440To1919Oversize2:
        "500 7.5rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1440To1919Oversize3:
        "500 6rem/1.1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusOversize1:
        "500 11.625rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusOversize2:
        "500 9.375rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusOversize3:
        "500 7.5rem/1 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay1:
        "900 7.5rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay2:
        "900 6rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay3:
        "900 4.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusDisplay4:
        "900 3.75rem/1.1 'Noto Sans TC Black', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle1:
        "500 3rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle2:
        "500 2.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle3:
        "500 2rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusTitle4:
        "500 1.5rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation1:
        "300 3.75rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation2:
        "300 3rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation3:
        "300 2.5rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypography1920PlusConversation4:
        "300 1.25rem/1.2 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody1:
        "400 1rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody1Strong:
        "500 1rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody2:
        "400 .875rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody2Strong:
        "500 .875rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody3:
        "400 .75rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody3Strong:
        "500 .75rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody4:
        "400 .625rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyBody4Strong:
        "500 .625rem/1.5 'Noto Sans TC Medium', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif",
      podiumCdsTypographyLegal:
        "400 .875rem/1.5 'Noto Sans TC', Helvetica, Arial, 'PingFang TC', '苹方-繁', 'Heiti TC', '黑體-繁', 'Microsoft JhengHei', '微軟正黑體', sans-serif"
    }
  }
};
