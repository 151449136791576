import PropTypes from 'prop-types';
import React from 'react';

import Keyset from './Keyset';

const Card = (props) => (
  <article className="ncss-col-sm-12 ncss-col-lg-6 va-sm-t" style={{ float: 'left', marginBottom: '20px', paddingBottom: '10px' }}>
    <h2 className="body-1">{props.header}</h2>
    <section className="border bg-primary-grey p2-sm" style={{ height: '309px', overflowY: 'scroll' }}>
      {props.keys.map((key) => (
        <Keyset
          key={key.key}
          currentPair={props.currentConfig.find((pair) => pair.key === key.key)}
          currentStore={props.store}
          description={key.description}
          group={props.header}
          isReadOnly={props.isReadOnly}
          name={key.key}
          type={key.valueType}
          onSave={props.onSave}
        />
      ))}
    </section>
  </article>
);

Card.defaultProps = {
  isReadOnly: true,
};

Card.propTypes = {
  currentConfig: PropTypes.arrayOf(PropTypes.shape).isRequired,
  header: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  keys: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onSave: PropTypes.func.isRequired,
  store: PropTypes.shape({}).isRequired,
};

export default Card;
