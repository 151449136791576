import PropTypes from 'prop-types';
import React from 'react';

import { trafficPermissions } from '../../utils/tab-permissions';
import { PageTemplate } from '../reusable';

import GeneralTraffic from './GeneralTraffic';

const Traffic = (props) => (
  <PageTemplate
    auth={trafficPermissions}
    description="View traffic, reload traffic, and/or generate WFM reports."
    help={(
      <article>
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Traffic+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
        <br />
        <a
          className="ncss-cta-primary-dark underline text-color-secondary mt4-sm"
          href="https://niketech.service-now.com/nav_to.do?uri=%2Fkb_view.do%3Fsysparm_article%3DKB0021401%26sysparm_stack%3D%26sysparm_view%3D"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB on ServiceNow
        </a>
        <br />
        <a
          className="ncss-cta-primary-dark underline text-color-secondary mt4-sm"
          href="https://confluence.nike.com/pages/viewpage.action?pageId=246038780"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the Service KB
        </a>
      </article>
    )}
    page={<GeneralTraffic />}
    path={props.location.pathname}
    title="Traffic"
  />
);

Traffic.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default Traffic;
