import {
  Button,
  ButtonGroup,
  TextField,
  TextGroup,
  Toggle
} from '@nike/eds';
import { Check, Close } from '@nike/nike-design-system-icons';
import {
  Default, isNone, isSome, match
} from '@nike/rcf-fp';
import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';

import { getRegionStores, updateStore } from '../../services/stores-service';
import { isChildOf } from '../../utils/hierarchy-utils';
import { userHasWritePermissionForRegion } from '../../utils/permissions';
import { getStoreDisplayName } from '../../utils/store-utils';
import { isNotWhitespaceOrNil, isWhitespaceOrNil } from '../../utils/string-utils';
import { Modal, Select } from '../eds-custom';
import { StoreHierarchyContext } from '../StoreHierarchyContextProvider';

import AddNodeButton from './common/AddNodeButton';
import HierarchyColumnTitle from './common/HierarchyColumnTitle';
import HierarchyNode from './common/HierarchyNode';

export default () => {
  const {
    districtStores,
    selectedStore,
    setSelectedStore,
    selectedDistrict,
    selectedRegion,
    hierarchyNodeLookupTable,
    userGroups,
    setIsPageLoading,
    setError,
    hierarchyNodes,
    setSuccessMessage,
    selectedTerritory,
    setSelectedDistrict,
    setSelectedTerritory,
    accessToken,
  } = useContext(StoreHierarchyContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalGrandparentId, setEditModalGrandparentId] = useState(selectedDistrict?.parentId ?? '');
  const [editModalParentId, setEditModalParentId] = useState(selectedDistrict?.id ?? '');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalStore, setAddModalStore] = useState(null);
  const [regionStores, setRegionStores] = useState([]);
  const [isOnlyOrphanStores, setIsOnlyOrphanStores] = useState(true);

  const territories = useMemo(() => (isSome(selectedRegion)
    ? hierarchyNodes.filter(isChildOf(selectedRegion))
    : []), [hierarchyNodes, selectedRegion]);
  const districts = useMemo(() => (isNotWhitespaceOrNil(editModalGrandparentId)
    ? hierarchyNodes.filter(isChildOf(hierarchyNodeLookupTable?.[editModalGrandparentId]))
    : []), [editModalGrandparentId, hierarchyNodes, hierarchyNodeLookupTable]);
  const noneOption = { label: 'None', value: '' };
  const grandparentSelectOptions = useMemo(() => territories.map(({ name, id }) => ({ label: name, value: id })), [territories]);
  const parentSelectOptions = useMemo(() => [noneOption, ...districts.map(({ id, name }) => ({ label: name, value: id }))], [noneOption, districts]);
  const grandparentSelectValue = useMemo(
    (newGrandparent = hierarchyNodeLookupTable?.[editModalGrandparentId] ?? {}) => (isWhitespaceOrNil(editModalGrandparentId)
      ? noneOption
      : { label: newGrandparent?.name, value: newGrandparent?.id }),
    [editModalGrandparentId, hierarchyNodeLookupTable, noneOption]
  );
  const parentSelectValue = useMemo((
    newParent = hierarchyNodeLookupTable?.[editModalParentId] ?? {},
  ) => (isWhitespaceOrNil(editModalParentId) || !parentSelectOptions.some((option) => option.value === editModalParentId)
    ? noneOption
    : { label: newParent?.name, value: newParent?.id }),
    [editModalParentId, hierarchyNodeLookupTable, noneOption, parentSelectOptions]);
  const isDirtyForEdit = useMemo(() => editModalParentId !== selectedDistrict?.parentId, [editModalParentId, selectedDistrict]);
  const storeSelectOptions = useMemo(() => {
    const filteredStores = isOnlyOrphanStores
      ? regionStores.filter((store) => isNone(store.district))
      : regionStores;
    return filteredStores.map((store) => ({ label: getStoreDisplayName(store), value: store.id }));
  }, [regionStores, isOnlyOrphanStores]);
  const storeSelectValue = useMemo(() => match()(
    [() => regionStores.length === 0, () => ({ label: 'Loading...', value: '' })],
    [() => isSome(addModalStore), () => ({ label: getStoreDisplayName(addModalStore), value: addModalStore.id })],
    [Default, () => ({ label: 'Select a store', value: '' })],
  ), [addModalStore, regionStores]);

  const prevSelectedRegionRef = useRef();

  // Update editModalParentId when selectedDistrict changes
  useEffect(() => {
    prevSelectedRegionRef.current = selectedRegion;
  });

  // Reset editModalParentId and editModalGrandparentId when selectedDistrict or selectedStore changes
  useEffect(() => {
    setEditModalGrandparentId(selectedDistrict?.parentId ?? '');
    setEditModalParentId(selectedDistrict?.id ?? '');
  }, [selectedDistrict, selectedStore]);

  // Reset addModalStore when isAddModalOpen changes
  useEffect(() => {
    setAddModalStore(null);
  }, [isAddModalOpen]);

  // Reset regionStores when selectedRegion changes
  useEffect(() => {
    if (selectedRegion !== prevSelectedRegionRef.current) {
      setRegionStores([]);
    }
  }, [selectedRegion, prevSelectedRegionRef]);

  // Update regionStores when selectedRegion changes
  useEffect(() => {
    if (isAddModalOpen && regionStores.length === 0) {
      // eslint-disable-next-line promise/catch-or-return
      getRegionStores(selectedRegion, accessToken)
        .then(setRegionStores)
        .catch(setError);
    }
  }, [isAddModalOpen, regionStores, selectedRegion, setError, setIsPageLoading, isOnlyOrphanStores, accessToken]);

  // When isOnlyOrphanStores changes, reset addModalStore
  useEffect(() => {
    if (!isOnlyOrphanStores) {
      setAddModalStore(null);
    }
  }, [isOnlyOrphanStores]);

  const actionComponent = useMemo(() => (
    isSome(selectedDistrict)
    && userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)
    && (<AddNodeButton label="Add Store" onClick={() => setIsAddModalOpen(true)} />)
  ), [selectedDistrict, userGroups, hierarchyNodeLookupTable, selectedRegion]);

  return (
    <>
      {isEditModalOpen && (
        <Modal
          footerSlot={(
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!(isDirtyForEdit)}
                size="small"
                variant="primary"
                onClick={() => {
                  const isNotWhitespaceOrNilParentId = isNotWhitespaceOrNil(editModalParentId);
                  const editConfirmed = isNotWhitespaceOrNilParentId
                    ? true
                    // eslint-disable-next-line no-alert
                    : window.confirm(`Remove district from store ${getStoreDisplayName(selectedStore)}?`);
                  if (editConfirmed) {
                    setIsPageLoading(true);
                    const newDistrictId = isNotWhitespaceOrNilParentId
                      ? editModalParentId
                      : undefined;
                    const updatedStore = { ...selectedStore, districtId: newDistrictId };
                    // eslint-disable-next-line promise/catch-or-return
                    updateStore(accessToken, updatedStore)
                      .then((savedStore) => {
                        const newDistrict = isSome(newDistrictId)
                          ? hierarchyNodeLookupTable?.[newDistrictId]
                          : selectedDistrict;
                        const newTerritory = hierarchyNodeLookupTable?.[newDistrict?.parentId];
                        setSelectedTerritory(newTerritory);
                        setSelectedDistrict(null); // Set to null to force a refresh
                        setSelectedDistrict(newDistrict);
                        setSelectedStore(savedStore);
                        setSuccessMessage(`Store ${getStoreDisplayName(updatedStore)} updated.`);
                        return savedStore;
                      })
                      .catch(setError)
                      .finally(() => setIsPageLoading(false));
                  }
                }}
              >Save
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Edit Store"
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
        >
          <TextGroup>
            <TextField
              disabled
              label="Store"
              value={getStoreDisplayName(selectedStore)}
            />
            <Select
              id="territory-select"
              label="Territory"
              options={grandparentSelectOptions}
              value={grandparentSelectValue}
              onChange={(e) => setEditModalGrandparentId(e?.value)}
            />
            <Select
              id="district-select"
              label="District"
              options={parentSelectOptions}
              value={parentSelectValue}
              onChange={(e) => setEditModalParentId(e?.value)}
            />
          </TextGroup>
        </Modal>
      )}
      {isAddModalOpen && (
        <Modal
          footerSlot={(
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={isNone(addModalStore)}
                size="small"
                variant="primary"
                onClick={() => {
                  setIsPageLoading(true);
                  const updatedStore = { ...addModalStore, districtId: selectedDistrict?.id };
                  // eslint-disable-next-line promise/catch-or-return
                  updateStore(accessToken, updatedStore)
                    .then((savedStore) => {
                      setSelectedStore(savedStore);
                      setSelectedDistrict(null); // Set to null to force a refresh
                      setSelectedDistrict(selectedDistrict);
                      setSuccessMessage(`Store ${getStoreDisplayName(savedStore)} added.`);
                      return savedStore;
                    })
                    .catch(setError)
                    .finally(() => setIsPageLoading(false));
                }}
              >Add
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Add Store"
          isOpen={isAddModalOpen}
          onDismiss={() => setIsAddModalOpen(false)}
        >
          <TextGroup>
            <Toggle
              checked={isOnlyOrphanStores}
              disabled={regionStores.length === 0}
              id="only-orphan-stores"
              label="Only Orphans"
              onChange={() => setIsOnlyOrphanStores(!isOnlyOrphanStores)}
            />
            <Select
              id="stores-add-modal-district-select"
              label="Store"
              options={storeSelectOptions}
              value={storeSelectValue}
              onChange={(e) => setAddModalStore(regionStores.find((store) => store.id === e?.value))}
            />
            <TextField
              disabled
              label="Territory"
              value={selectedTerritory?.name}
            />
            <TextField
              disabled
              label="District"
              value={selectedDistrict?.name}
            />
          </TextGroup>
        </Modal>
      )}
      <HierarchyColumnTitle actionComponent={actionComponent} title="Stores" />
      {
        districtStores && districtStores.map((store) => (
          <HierarchyNode
            key={store.id}
            getHierarchyCopyPasteValue={getStoreDisplayName}
            getHierarchyName={getStoreDisplayName}
            hierarchyNode={store}
            isDeletable={false}
            isEditable={userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)}
            isSelected={store.id === selectedStore?.id}
            onClick={() => setSelectedStore(store)}
            onEdit={() => setIsEditModalOpen(true)}
          />
        ))
      }
    </>
  );
};
