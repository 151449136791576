export const NIKE_APP_URL = process.env.NODE_ENV === 'production'
  ? 'https://nike.sng.link/Astn5/kqib/ic6w?_forward_params=1&~ad_set_id='
  : 'https://nike.sng.link/Astn5/hj1m/r_12dd5fb10c?_forward_params=1&~ad_set_id=';
export const SHOP_THE_LOOK_URL = process.env.NODE_ENV === 'production'
  ? 'https://nike.sng.link/Astn5/3r0n/r_f4a41186ac?_forward_params=1&~ad_set_id='
  : 'https://nike.sng.link/Astn5/hj1m/r_a475a008b8?_forward_params=1&~ad_set_id=';
export const CONFLUENCE_HELP_LINK = 'https://confluence.nike.com/display/RCFITC/RCF+KB+-+QR+Code+Generator+UI+NEW';
export const SINGULAR_LONG_LINK = process.env.NODE_ENV === 'production'
  ? 'https://nike.sng.link/Astn5/ugj9'
  : 'https://nike.sng.link/Astn5/hj1m';
