import PropTypes from 'prop-types';
import React from 'react';

import { o2oPermissions } from '../../utils/tab-permissions';
import { PageTemplate } from '../reusable';

import SelectFulfillment from './SelectFulfillment';

const O2OCapabilities = (props) => (
  <PageTemplate
    auth={o2oPermissions}
    description="Manage store O2O capabilities and set the attributes required for O2O services."
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+O2O+Capabilities+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
    )}
    page={<SelectFulfillment />}
    path={props.location.pathname}
    title="O2O Capabilities"
  />
);

O2OCapabilities.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default O2OCapabilities;
