// actions
export const STORE_SERVICES_FETCH = 'STORE_SERVICES_FETCH';
export const STORE_SERVICES_FETCH_SUCCESS = 'STORE_SERVICES_FETCH_SUCCESS';
export const STORE_SERVICES_FETCH_FAILURE = 'STORE_SERVICES_FETCH_FAILURE';

export const STORE_SERVICE_TYPES_FETCH = 'STORE_SERVICE_TYPES_FETCH';
export const STORE_SERVICE_TYPES_FETCH_SUCCESS = 'STORE_SERVICE_TYPES_FETCH_SUCCESS';
export const STORE_SERVICE_TYPES_FETCH_FAILURE = 'STORE_SERVICE_TYPES_FETCH_FAILURE';

// action creators
export const storeServicesFetch = (token, storeId) => ({ storeId, token, type: STORE_SERVICES_FETCH });
export const storeServicesFetchSuccess = (requestNum, storeId, storeServices) => ({
  requestNum, storeId, storeServices, type: STORE_SERVICES_FETCH_SUCCESS,
});
export const storeServicesFetchFailure = (requestNum, storeId, error) => ({
  error, requestNum, storeId, type: STORE_SERVICES_FETCH_FAILURE,
});

export const storeServiceTypesFetch = (token) => ({ token, type: STORE_SERVICE_TYPES_FETCH });
export const storeServiceTypesFetchSuccess = (requestNum, storeServiceTypes) => ({ requestNum, storeServiceTypes, type: STORE_SERVICE_TYPES_FETCH_SUCCESS });
export const storeServiceTypesFetchFailure = (requestNum, error) => ({ error, requestNum, type: STORE_SERVICE_TYPES_FETCH_FAILURE });
