import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { getStoreCoords, getTimezone } from '../../../utils/service-calls/google';
import { timezoneValues } from '../../../utils/static/sls-property-values';
import { ButtonBlack, Select } from '../../reusable';

class Coordinates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  calculate = () => getStoreCoords(this.props.formData, true)
    .then(({ lat, lng }) => {
      if (!lat || !lng) {
        this.props.updateCoordinates({ latitude: '', longitude: '' });
        return this.setState({ error: 'Google claims you have entered an invalid address. Sorry, but this button will not work for you. Please check your data again or obtain the coordinates and timezone yourself. Thanks!' });
      } else {
        this.props.updateCoordinates({ latitude: lat, longitude: lng });
        return getTimezone({ lat, lng })
          .then(({ timeZoneId }) => {
            this.props.updateTimezone(timeZoneId);
            return this.setState({ error: '' });
          })
          .catch(({ message }) => {
            this.props.updateCoordinates({ latitude: '', longitude: '' });
            return this.setState({ error: message });
          });
      }
    })
    .catch(({ message }) => {
      this.props.updateCoordinates({ latitude: '', longitude: '' });
      return this.setState({ error: message });
    });

  disableCalculation = () => {
    let disable = !this.props.formData.address;
    if (this.props.formData.address) {
      disable = !this.props.formData.address.address1 || !this.props.formData.address.city
        || !(this.props.formData.address.state || this.props.formData.address.country);
    }
    return disable;
  }

  onClick = ({ lat, lng }) => this.props.updateCoordinates({ latitude: lat, longitude: lng });

  render = () => {
    const { cmp } = this.props.formData || {};
    const { country } = this.props.formData?.address || {};
    const isChina = country === 'CHN';
    return (
      <section className="ncss-row">
        <header className="ncss-col-sm-12 headline-2">Coordinates</header>
        <article className="ncss-col-sm-12 ta-sm-c mt7-sm">
          {isChina
            ? (
              <>
                <a
                  className="ncss-btn-primary-dark"
                  href="https://lbs.amap.com/console/show/picker"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Open Map to Find the Coordinates
                </a>
                <p className="prl6-sm mb3-sm mt3-sm body-4">Fill out the fields below. If you do not know the coordinates, please use the map to find them.</p>
              </>
            )
            : !(cmp?.id) && (
            <ButtonBlack
              className="u-full-width"
              isDisabled={this.disableCalculation() || this.props.userIsReadOnly}
              label="Calculate Coordinates and Timezone"
              onClick={this.calculate}
            />
            )}
          {this.props.formData?.address?.country !== 'CHN' && this.disableCalculation()
          && <p className="text-color-error body-4 mt2-sm">This button requires Address 1, City, and State/Country.</p>}
          {this.state.error
          && <p className="text-color-error body-4 mt2-sm">{this.state.error}</p>}
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={!this.props.disableCmpFields && this.props.formErrors.coordinates.latitude}
            isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
            label="Coordinates - Latitude"
            value={this.props.latitude}
            onChange={({ target: { value } }) => this.props.updateCoordinates({ latitude: value, longitude: this.props.longitude })}
          />
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={!this.props.disableCmpFields && this.props.formErrors.coordinates.longitude}
            isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
            label="Coordinates - Longitude"
            value={this.props.longitude}
            onChange={({ target: { value } }) => this.props.updateCoordinates({ latitude: this.props.latitude, longitude: value })}
          />
        </article>
        <Select
          className="ncss-col-sm-12 va-sm-t"
          errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.timezone}
          id="timezoneSelect"
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Timezone"
          options={[{ label: '', value: '' }].concat(timezoneValues.map((zone) => ({ label: zone, value: zone })))}
          value={this.props.timezone}
          onChange={(value) => this.props.updateTimezone(value)}
        />
      </section>
    );
  };
}

Coordinates.propTypes = {
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  timezone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  updateCoordinates: PropTypes.func.isRequired,
  updateTimezone: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Coordinates;
