import {
  Bag,
  ElevationChange,
  Home,
  LocationPin,
  Navigation,
  Notes,
  Orders,
  Payment,
  Programs,
  Repeat,
  Road,
  Scan,
  Settings,
  Shareaos,
  SortDescending,
  Stages,
  Wallet,
  GridView
} from '@nike/nike-design-system-icons';

export {
  ElevationChange as DataAnalyzer,
  Shareaos as DCN,
  Stages as Waitlines,
  Notes as FiscalDocument,
  Home,
  Orders as KioskConfig,
  Repeat as O2O,
  Scan as Patch,
  Programs as PrinterPortal,
  Settings as SCS,
  SortDescending as StoreHierarchy,
  GridView as CCM,
  LocationPin as SLS,
  Bag as StoreServices,
  Payment as TaxIdentifiers,
  Road as Traffic,
  Navigation as Zones,
  Wallet as POSReasons,
};
