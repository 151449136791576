import { requestWrapper } from './reusable';

const config = require('../../config')();

export const viewTraffic = async (token, params) => requestWrapper(
  token,
  config.trafficViewResource,
  'get',
  {
    company: params.company,
    endDate: params.isoEndDate,
    intervalMinutes: params.interval,
    startDate: params.isoStartDate,
    storeIds: params.storeNumber,
  },
);

export const reloadTraffic = async (token, params) => requestWrapper(
  token,
  config.trafficReloadResource,
  'post',
  {
    company: params.company,
    endDate: params.endDate,
    startDate: params.startDate,
    storeNumber: params.storeNumber,
  },
);

export const generateTraffic = async (token, params) => requestWrapper(
  token,
  config.trafficGenerateResource,
  'get',
  {
    country: params.country,
    endDate: params.endDate,
    organization: params.company,
    startDate: params.startDate,
  },
);

export const reloadThenViewTraffic = (token, params) => reloadTraffic(token, params)
  .then(async (res) => Promise.all([
    Promise.resolve(res),
    await viewTraffic(token, params),
  ]))
  .catch(async (res) => Promise.all([
    // don't use reject, that stops the later call from being called
    Promise.resolve(res),
    await viewTraffic(token, params),
  ]));

export const viewThenGenerateTraffic = (token, params) => viewTraffic(token, params)
  .then(async (res) => Promise.all([
    Promise.resolve(res),
    await generateTraffic(token, params),
  ]))
  .catch(async (res) => Promise.all([
    Promise.resolve(res),
    await generateTraffic(token, params),
  ]));

export const reloadThenGenerateTraffic = (token, params) => reloadTraffic(token, params)
  .then(async (res) => Promise.all([
    Promise.resolve(res),
    await generateTraffic(token, params),
  ]))
  .catch(async (res) => Promise.all([
    Promise.resolve(res),
    await generateTraffic(token, params),
  ]));

export const reloadThenViewThenGenerateTraffic = (token, params) => reloadTraffic(token, params)
  .then(async (res) => Promise.all([
    Promise.resolve(res),
    await viewThenGenerateTraffic(token, params),
  ]))
  .catch(async (res) => Promise.all([
    Promise.resolve(res),
    await viewThenGenerateTraffic(token, params),
  ]));
