import PropTypes from 'prop-types';
import React from 'react';

import {
  sortStoresByNumber,
} from '../../../utils/formatting';
import {
  getStores,
} from '../../../utils/service-calls/sls';
import fields from '../../../utils/static/facility-csv-fields';
import schemas from '../../../utils/static/facility-csv-schema';
import { isUserAllowed } from '../../../utils/tab-permissions';
import { ButtonBlack } from '../../reusable';

const DIGITAL_STORES = 'Digital Stores';
const STORES = 'Stores';
const WAREHOUSES = 'Warehouses';
const ALL_REGIONS = 'All Regions';
const SELECT_REGION = 'Select a Region';

const retrieveFields = (permissions, FACILITY_TYPE) => {
  switch (FACILITY_TYPE) {
    case DIGITAL_STORES:
      return fields.ipDigitalStoreFields;
    case STORES:
      if (permissions.isIP && permissions.isJE) {
        return fields.allStoreFields;
      } else if (permissions.isIP) {
        return fields.ipStoreFields;
      } else if (permissions.isJE) {
        return fields.jeStoreFields;
      } else {
        return fields.baseStoreFields;
      }
    case WAREHOUSES:
      return fields.ipWarehouseFields;
    default:
      throw Error(`Bulk download of ${FACILITY_TYPE} is not implemented`);
  }
};

const transformDigitalStoresData = (facilityList) => {
  let csvContent = '';
  facilityList.forEach((facility) => {
    csvContent = csvContent.concat(schemas.digitalStoreSchema(facility));
  });
  return csvContent;
};

const transformStoresData = (facilityList, permissions) => {
  let csvContent = '';
  let getCsvContent = schemas.baseStoreSchema;
  if (permissions.isIP && permissions.isJE) {
    getCsvContent = schemas.storeSchema;
  } else if (permissions.isIP) {
    getCsvContent = schemas.ipStoreSchema;
  } else if (permissions.isJE) {
    getCsvContent = schemas.jeStoreSchema;
  }
  facilityList.forEach((facility) => {
    csvContent = csvContent.concat(getCsvContent(facility));
  });
  return csvContent;
};

const transformWarehousesData = (facilityList) => {
  let csvContent = '';
  facilityList.forEach((facility) => {
    csvContent = csvContent.concat(schemas.warehouseSchema(facility));
  });
  return csvContent;
};

const convertDataToCsv = (facilityList, FACILITY_TYPE, permissions) => {
  switch (FACILITY_TYPE) {
    case DIGITAL_STORES:
      return transformDigitalStoresData(facilityList);
    case STORES:
      return transformStoresData(facilityList, permissions);
    case WAREHOUSES:
      return transformWarehousesData(facilityList);
    default:
      throw Error(`Bulk download of ${FACILITY_TYPE} is not implemented`);
  }
};

// Headers and variables for the csv file
const recordsToCsv = (facilityList, userGroups, FACILITY_TYPE) => {
  const permissions = {
    isIP: isUserAllowed(userGroups, 'SLS.IP'),
    isJE: isUserAllowed(userGroups, 'SLS.JE'),
  };

  // Retrieves the appropriate fields based on security group IP/JE permissions
  const fieldList = retrieveFields(permissions, FACILITY_TYPE);
  // Converts Facility data from JSON to CSV
  const csvContent = convertDataToCsv(facilityList, FACILITY_TYPE, permissions);
  const csv = document.createElement('a');
  const encodedUri = encodeURI(csvContent);
  csv.setAttribute('href', encodedUri);
  csv.href = URL.createObjectURL(new Blob([`${fieldList}\n${csvContent}\n`], { type: 'text/csv;charset=utf-8' }));
  csv.setAttribute('download', `${FACILITY_TYPE} List.csv`);
  document.body.appendChild(csv);
  csv.click();
  document.body.removeChild(csv);
};

function getFaciltiesList(token, requiredFacilities) {
  const requiredStoreIds = requiredFacilities.map((facility) => facility.id);
  return getStores(token)
    .then((stores) => stores.filter((store) => requiredStoreIds.includes(store.id)))
    .then((res) => sortStoresByNumber(res));
}

async function onSubmit(userGroups, FACILITY_TYPE, userToken, existingFacilities) {
  const facilities = FACILITY_TYPE === STORES
    ? await getFaciltiesList(userToken, existingFacilities)
    : existingFacilities;
  recordsToCsv(facilities, userGroups, FACILITY_TYPE);
}

const getLabel = (region, facilities, facilitiesAll, facilityType) => {
  if ((region === ALL_REGIONS || region === SELECT_REGION) && facilities.length === facilitiesAll.length) {
    return `Download All ${facilityType}`;
  } else {
    return `Download Selected ${facilityType}`;
  }
};

const FacilityListDownload = (props) => <ButtonBlack label={getLabel(props.region, props.facilities, props.facilitiesAll, props.facilityType)} onClick={() => onSubmit(props.userGroups, props.facilityType, props.userToken, props.facilities)} />;

FacilityListDownload.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  facilitiesAll: PropTypes.arrayOf(PropTypes.shape).isRequired,
  facilityType: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  userToken: PropTypes.string.isRequired,
};

export default FacilityListDownload;
