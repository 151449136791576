{
    "lastA11yAudit": "07/08/22",
    "name": "Vertical Navbar",
    "description": "Navigational element pinned to the left of the screen, allows for switching between pages of your application.",
    "docs": "#/components/VerticalNavbar",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/VerticalNavbar",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=278%3A45",
    "tags": ["Navigation", "Vertical Navbar", "application"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Beta"]
}
