{
    "lastA11yAudit": "07/08/22",
    "name": "Snackbar",
    "description": "Snackbar contains Snacks to communicate with the users about the resulting status of an action",
    "docs": "#/components/snacks/Snackbar",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=2805%3A152178",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Snackbar",
    "tags": [
        "Snackbar",
        "Toast",
        "Notification",
        "Snack",
        "Pill",
        "dialog",
        "accessibility",
        "a11y",
        "alert",
        "prompt"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
