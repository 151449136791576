{
    "lastA11yAudit": "07/08/22",
    "name": "Tab",
    "description": "Tabs organize content within a page under different screens, data sets, or other views.",
    "docs": "#/components/tabs/Tab",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-EDS?node-id=360%3A11836",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Tab",
    "tags": ["tab", "tabgroup", "tab group", "pivot"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
