/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, TextArea, Loading } from '@nike/frame-component-library';
import { Close } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './config-by-key/Overlay.css';

import {
  ButtonBlack, Select, ButtonWhite,
 } from '../reusable';

const config = require('../../config')();

class CreateConfigOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
        configKey: '',
        configValue: '',
        description: '',
        isConfigValueProvided: false,
        isCreateGlobalConfig: false,
        saveClicked: false,
        selectedGroup: '',
        selectedRegion: '',
        selectedValueType: '',
    };
  }

  calculateValueType = () => {
    if (this.state.selectedValueType === 'INTEGER') {
      return 'number';
    } else {
      return 'text';
    }
  }

  enableOrDisableSave = () => {
    if (this.state.configKey && this.state.description && this.state.selectedGroup && this.state.selectedRegion && this.state.selectedValueType && !(this.state.isCreateGlobalConfig) && !(this.state.isConfigValueProvided)) {
      return true;
    } else if (this.state.configKey && this.state.description && this.state.selectedGroup && this.state.selectedRegion && this.state.selectedValueType && !(this.state.isCreateGlobalConfig) && (this.state.isConfigValueProvided)) {
      return true;
    } else if (this.state.configKey && this.state.description && this.state.selectedGroup && this.state.selectedRegion && this.state.selectedValueType && this.state.isCreateGlobalConfig && this.state.isConfigValueProvided) {
      return true;
    } else {
      return false;
    }
  };

  handleAction = () => {
    this.props.onAction(this.state.selectedRegion);
  };

  handleConfigKeyChange = (value) => {
    this.setState({ configKey: value });
  };

  handleConfigValueChange = (value) => {
    this.setState({ configValue: value }, async () => {
      if (this.state.configValue) { this.setState({ isConfigValueProvided: true }); } else { this.setState({ isConfigValueProvided: false }); }
  });
  };

  handleDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  handleGroupChange = (event) => {
    this.setState({ selectedGroup: event });
  };

  handleOnClose = () => {
    this.clearFields();
    this.props.onClose();
   };

  handleRegionChange = (event) => {
    this.setState({ selectedRegion: event }, async () => {
        this.handleAction();
    });
  };

  handleSave = () => {
    this.state.saveClicked = true;
    this.props.onSave(this.state.description, this.state.selectedGroup, this.state.configKey, this.state.selectedValueType, this.state.configValue, this.state.isCreateGlobalConfig, false);
  };

  handleSaveAndClose = () => {
    this.state.saveClicked = true;
    this.props.onSave(this.state.description, this.state.selectedGroup, this.state.configKey, this.state.selectedValueType, this.state.configValue, this.state.isCreateGlobalConfig, true);
  };

  handleToggleChange = () => {
    if (this.state.isCreateGlobalConfig) {
      this.setState({ isCreateGlobalConfig: false });
    } else {
      this.setState({ isCreateGlobalConfig: true });
    }
  };

  handleValueTypeChange = (event) => {
    this.setState({ selectedValueType: event });
  };

  clearFields() {
    this.setState({
      configKey: '',
      configValue: '',
      description: '',
      isConfigValueProvided: false,
      isCreateGlobalConfig: false,
      saveClicked: false,
      selectedGroup: '',
      selectedRegion: '',
      selectedValueType: '',
    });
  }

  render() {
    const {
 isVisible, filteredGroup, isSaving
} = this.props;
    const {
 selectedRegion, selectedValueType, selectedGroup, configKey, description, isCreateGlobalConfig, configValue,
} = this.state;

    if (!isVisible) return null;

    return (
      <>(
        <div className="overlay">
          <div className="overlay-content">
            <div className="close-container">
              <Close name="Close" size="m" onClick={this.handleOnClose} />
            </div>
            <h1 className="headline-4">Create Key</h1>
            <div className="input-container">
              <Select
                className="select-container"
                id="scsRegion"
                label="AWS Region"
                options={config.scsRegionOptions}
                value={selectedRegion}
                onChange={this.handleRegionChange}
              />
              <Input
                label="Key Name"
                placeholder="Provide key name"
                type="text"
                value={configKey}
                onChange={({ target: { value } }) => this.handleConfigKeyChange(value)}
              />
            </div>

            <div className="input-container">
              <Select
                required
                className="select-container-valueType "
                id="scsValueType"
                label="Value Type"
                options={['STRING', 'INTEGER', 'BOOLEAN'].map((value) => ({ label: value, value }))}
                value={selectedValueType}
                onChange={this.handleValueTypeChange}
              />
              { selectedRegion && filteredGroup.length === 0
              ? (<Loading style={{ paddingTop: '28px' }} />)
              : (
                <Select
                  className="select-container-group"
                  id="scsGroup"
                  isDisabled={filteredGroup.length === 0 || !selectedRegion}
                  label="Group"
                  options={filteredGroup.map((group) => ({ label: group, value: group }))}
                  value={selectedGroup}
                  onChange={this.handleGroupChange}
                />
)}

            </div>
            <div className="input-container">
              <TextArea
                label="Description"
                placeholder="Provide description for Config Key"
                rows="5"
                value={description}
                onChange={({ target: { value } }) => this.handleDescriptionChange(value)}
              />
            </div>

            <div className="toggle-container">
              <div className="toggle">
                <label className="custom-toggle">
                  <input
                    checked={isCreateGlobalConfig}
                    type="checkbox"
                    onChange={this.handleToggleChange}
                  />
                  <span className="custom-toggle-slider" />
                </label>
                <label className="toggleLabel" htmlFor="toggleSwitch"> Set Default Global Scope Value </label>
              </div>
              {this.state.selectedValueType === 'BOOLEAN' && isCreateGlobalConfig
              ? (
                <Select
                  className="select-container-value"
                  id="configValue"
                  label="Value"
                  options={['true', 'false'].map((value) => ({ label: value, value }))}
                  placeholder="Select Value"
                  value={configValue}
                  onChange={this.handleConfigValueChange}
                />
              )
              : this.state.selectedValueType !== 'BOOLEAN' && isCreateGlobalConfig
              ? (
                <Input
                  label="Value"
                  placeholder="Provide value"
                  type={this.calculateValueType()}
                  value={configValue}
                  onChange={({ target: { value } }) => this.handleConfigValueChange(value)}
                />

              )
              : null}
            </div>
            <div className="button-container">
              <ButtonWhite isDisabled={!(this.enableOrDisableSave()) || isSaving} label={isSaving ? 'Saving...' : 'Save & New'} onClick={this.handleSave} />
              <ButtonBlack isDisabled={!(this.enableOrDisableSave()) || isSaving} label={isSaving ? 'Saving...' : 'Save & Close'} onClick={this.handleSaveAndClose} />
            </div>
            {!(this.enableOrDisableSave()) ? (<p className="req-paragraph"> All fields are required </p>) : null}
          </div>
        </div>
        )
      </>
    );
  }
}

CreateConfigOverlay.propTypes = {
  filteredGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSaving: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CreateConfigOverlay;
