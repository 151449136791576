/* eslint-disable no-underscore-dangle */
import { isNone, trampolineAsync } from '@nike/rcf-fp';

import getConfig from '../../utils/config';

import {
        getRegionStores as getMockRegionStores,
        getDistrictStores as getMockDistrictStores,
        updateStore as mockUpdateStore,
} from './mock-stores-service';

//
// Set useMockStoreService to true in config.js to use the mock service instead of the live one.
//
const { useMockStoresService } = getConfig();

const BASE_URL = process.env.NODE_ENV === 'production'
        ? 'https://hierarchy-us-east-1.rbh-robots-prod.nikecloud.com'
        : 'https://hierarchy-us-east-1.rbh-robots-test.nikecloud.com';

const URL_PATH = '/hierarchy-ui/stores/v1';

const PAGE_SIZE = '1000';

const getInitialUrl = (additionalSearchParams = {}) => {
        const url = new URL(URL_PATH, BASE_URL);
        url.searchParams.append('count', PAGE_SIZE);
        Object.entries(additionalSearchParams).forEach(([key, value]) => url.searchParams.append(key, value));
        return url;
};

const _getStoresFromService = async (accessToken, searchParams = {}, stores = [], url = getInitialUrl()) => {
        Object.entries(searchParams).forEach(([key, value]) => url.searchParams.append(key, value));

        const { objects, pages } = await fetch(url, {
                headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                },
                method: 'GET',
        }).then((response) => {
                if (!response.ok) {
                        throw new Error(`Error fetching hierarchy: ${response.statusText}`);
                }
                return response.json();
        });

        const nextUrlString = (pages?.next || '').trim() === ''
                ? null
                : pages.next;

        const allStores = [...stores, ...objects];

        return isNone(nextUrlString)
                ? allStores
                : () => _getStoresFromService(accessToken, {}, allStores, new URL(nextUrlString, BASE_URL));
};

const getStoresFromService = trampolineAsync(_getStoresFromService);

export const getRegionStores = async (region, accessToken) => (useMockStoresService
        ? getMockRegionStores(region)
        : getStoresFromService(accessToken, { regionId: region?.id }));

export const getDistrictStores = async (district, accessToken) => (useMockStoresService
        ? getMockDistrictStores(district)
        : getStoresFromService(accessToken, { districtId: district?.id }));

const updateStoreInService = async (accessToken, store) => fetch(new URL(URL_PATH, BASE_URL), {
                body: JSON.stringify(store),
                headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                },
                method: 'PUT',
        }).then((response) => {
                if (!response.ok) {
                        throw new Error(`Error updating store: ${response.statusText}`);
                }
                return response.json();
        });

export const updateStore = async (accessToken, store) => (useMockStoresService
                ? mockUpdateStore(store)
                : updateStoreInService(accessToken, store));
