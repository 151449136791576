import React from 'react';

const Unauthorized = () => (
  <main className="ncss-col-sm-12 ta-sm-c">
    <h1 className="display-3 mt10-sm mb4-sm text-color-error">Unauthorized</h1>
    <p className="body-1">You are in the right place, but you are not authorized to view this site.</p>
    <p className="body-1">In order to access this site, you must be in the </p>
    <p className="headline-4 text-color-error mb10-sm">{process.env.NODE_ENV === 'production' ? 'Required AD Groups' : 'group: App.RetailOperations.Users'}</p>
    <p className="body-1 pt4-sm">Please contact your manager and have them follow the process linked below:</p>
    <a
      className="ncss-cta-primary-dark underline text-color-accent pb4-sm"
      href="https://confluence.nike.com/display/RE/Adding+Users+to+UIs"
      rel="noopener noreferrer"
      target="_blank"
    >
      Adding Users to UIs
    </a>
  </main>
);

export default Unauthorized;
