import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { loadState } from '../../utils/local-storage';
import { postPrinter, getPrinterModels, putPrinter } from '../../utils/service-calls/printer-portal';
import { allOrNothingValidator, didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonSubmit,
  ButtonWhite,
  Select,
  StoreSelect,
} from '../reusable';

const roles = [
  { label: 'RECEIPT', value: 'RECEIPT' },
  { label: 'DOCUMENT', value: 'DOCUMENT' },
  { label: 'LABEL', value: 'LABEL' },
  { label: 'OTHER', value: 'OTHER' },
];

class AddEditPrinter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      address: props.data?.connectionInfo?.lan?.address ?? '',
      errorMessage: '',
      formErrors: {
        address: REQUIRED_FIELD,
        mac: REQUIRED_FIELD,
        name: REQUIRED_FIELD,
        selectedCountry: '',
        selectedModel: REQUIRED_FIELD,
        selectedStore: REQUIRED_FIELD,
        serial: REQUIRED_FIELD,
        supportedRoles: REQUIRED_FIELD,
        tcp: '',
      },
      mac: props.data?.connectionInfo?.bluetooth?.mac ?? '',
      name: props.data?.name ?? '',
      passcode: props.data?.connectionInfo?.bluetooth?.passcode ?? '',
      printerId: props.data?.id ?? undefined,
      printerModels: [],
      selectedCountry: loadState('auth')?.country,
      selectedModel: props.data?.modelId ?? '',
      selectedStore: props.data?.storeId ? { id: props.data?.storeId } : null,
      serial: props.data?.serialNumber ?? '',
      status: '',
      supportedRoles: props.data?.supportedRoles ? props.data?.supportedRoles[0] : '',
      tcp: props.data?.connectionInfo?.lan?.tcp ?? { host: '', port: '' },
      uri: props.data?.connectionInfo?.lan?.http?.uri ?? '',
    };
  }

  componentDidMount() {
    if (this.state.printerModels.length < 1) {
      this.fetchPrinterModels();
    }
    this.validateForm(Object.keys(this.state.formErrors));
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateForm(Object.keys(this.state.formErrors));
    }
  }

  fetchPrinterModels = () => getPrinterModels(this.props.accessToken)
    .then((printers) => {
      const defaultModel = printers.body.objects.length > 0 ? printers.body.objects[0].id : '';
      const existingModel = printers.body.objects.find((p) => p.id === this.state.selectedModel);
      return this.setState({
        printerModels: printers.body.objects,
        selectedModel: existingModel ? existingModel.id : defaultModel,
      });
    })
    .catch((err) => this.setState({ errorMessage: `Models failed: ${err.message}` }));

  fieldsComplete = () => {
    const {
      address,
      mac,
      name,
      selectedCountry,
      selectedModel,
      selectedStore,
      serial,
      supportedRoles,
      formErrors,
    } = this.state;

    return !(!selectedCountry || !selectedStore
      || !name
      || !supportedRoles || !selectedModel
      || !serial || !address
      || !mac
      || formErrors.tcp);
  }

  onSubmit = () => {
    this.setState({ adding: true, status: '' });
    const {
      address,
      mac,
      name,
      passcode,
      selectedModel,
      selectedStore,
      serial,
      status,
      supportedRoles,
      tcp,
      printerId,
      uri,
    } = this.state;

    const toSend = {
      connectionInfo: {
        lan: { address, ...(tcp.host ? { tcp } : {}), http: { uri } },
        ...(mac ? { bluetooth: { mac, ...(passcode ? { passcode } : {}) } } : {}),
      },
      modelId: selectedModel,
      name,
      serialNumber: serial,
      storeId: selectedStore.id,
      supportedRoles: [supportedRoles],
    };
    if (status.length === 0) {
      if (printerId) {
        putPrinter(this.props.accessToken, toSend, printerId)
          .then(() => this.props?.doneEdit('Printer succesfully updated'))
          .catch((err) => this.setState({ errorMessage: err.message }));
      } else {
        postPrinter(this.props.accessToken, toSend)
          .then(() => this.setState({ adding: false, status: 'Printer succesfully created.' }))
          .catch((err) => this.setState({ adding: false, errorMessage: err.message }));
      }
    }
  };

  selectCountry = (selectedCountry) => this.setState({ selectedCountry, selectedStore: null, status: '' });

  selectModel = () => this.setState((prevState) => ({ selectedModel: prevState.printerModels.model, status: '' }));

  selectStore = (selectedStore) => this.setState({ selectedStore, status: '' });

  validateField = (field) => {
    switch (field) {
      case 'tcp':
        return allOrNothingValidator(this.state.tcp, ['host', 'port']);
      default:
        return (this.state[field]) ? '' : REQUIRED_FIELD;
    }
  }

  validateForm = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], this.validateField(field)])));
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <section className={`ncss-col-sm-${this.props.data ? '10 border' : '8'} pb10-sm`}>
      {!this.state.printerId && (
        <article className="ncss-col-sm-12 va-sm-t">
          <StoreSelect
            isRequired
            formErrors={this.state.formErrors}
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            storeviewsFields={['id']}
          />
        </article>
      )}
      <article className="ncss-col-sm-12 va-sm-t">
        <Input
          errorMessage={this.state.formErrors.name}
          id="name"
          label="Printer Name"
          type="text"
          value={this.state.name}
          onChange={({ target: { value } }) => this.setState({ name: value, status: '' })}
        />
      </article>
      <Select
        className="ncss-col-sm-6 va-sm-t ta-sm-l"
        errorMessage={this.state.formErrors.supportedRoles}
        id="roles"
        label="Supported Roles"
        options={roles}
        value={this.state.supportedRoles}
        onChange={(role) => this.setState({ status: '', supportedRoles: role })}
      />
      <Select
        className="ncss-col-sm-6 va-sm-t ta-sm-l"
        errorMessage={this.state.formErrors.selectedModel}
        id="models"
        label="Printer Model"
        options={this.state.printerModels.map((model) => ({ label: model.model, value: model.id }))}
        value={this.state.selectedModel}
        onChange={(option) => this.setState({ selectedModel: option, status: '' })}
      />
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.state.formErrors.serial}
          id="serial"
          label="Printer Serial"
          type="text"
          value={this.state.serial}
          onChange={({ target: { value } }) => this.setState({ serial: value, status: '' })}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.state.formErrors.address}
          id="address"
          label="IP Address"
          type="text"
          value={this.state.address}
          onChange={({ target: { value } }) => this.setState({ address: value, status: '' })}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.state.formErrors.mac}
          id="mac"
          label="Mac Address"
          type="text"
          value={this.state.mac}
          onChange={({ target: { value } }) => this.setState({ mac: value, status: '' })}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.state.formErrors.passcode}
          id="passcode"
          label="Bluetooth Passcode"
          type="text"
          value={this.state.passcode}
          onChange={({ target: { value } }) => this.setState({ passcode: value, status: '' })}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.state.formErrors.tcp}
          id="tcpHost"
          label="TCP Host"
          type="text"
          value={this.state.tcp.host}
          onChange={({ target: { value } }) => this.setState((prevState) => ({
            status: '',
            tcp: { host: value, port: prevState.tcp.port },
          }))}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.state.formErrors.tcp}
          id="tcpPort"
          label="TCP Port"
          type="number"
          value={this.state.tcp.port}
          onChange={({ target: { value } }) => this.setState((prevState) => ({
            status: '',
            tcp: { host: prevState.tcp.host, port: Number(value) },
          }))}
        />
      </article>
      <article className="ncss-col-sm-12 va-sm-t">
        <Input
          id="uri"
          label="HTTP URI"
          type="text"
          value={this.state.uri}
          onChange={({ target: { value } }) => this.setState({ status: '', uri: value })}
        />
      </article>
      {this.state.printerId && (
        <section className="ncss-col-sm-6 va-sm-t">
          <ButtonWhite
            label="Cancel"
            onClick={this.props.doneEdit}
          />
        </section>
      )}
      <ButtonSubmit
        className="ncss-col-sm-6 va-sm-t"
        isDisabled={!this.fieldsComplete() || !!this.state.errorMessage}
        isLoading={this.state.adding}
        label={this.state.printerId ? 'Save' : 'Add Printer'}
        submitError={this.state.errorMessage}
        onClick={this.onSubmit}
      />
      <p className="text-color-success body-2">{this.state.status}</p>
    </section>
  );
}

AddEditPrinter.defaultProps = {
  accessToken: undefined,
  data: null,
  doneEdit: undefined,
};

AddEditPrinter.propTypes = {
  accessToken: PropTypes.string,
  data: PropTypes.shape(),
  doneEdit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(AddEditPrinter);
