import { Checkbox, Loading } from '@nike/frame-component-library';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { parseSLSandBrickworksErrors } from '../../../utils/formatting';
import { putStore, getStoreById } from '../../../utils/service-calls/sls';
import { ButtonSubmit } from '../../reusable';

import { standardDoris, fastDoris } from './constants';

const DORIS = ({ selectedStore }) => {
  const accessToken = useSelector((state) => state.authorizationReducer.auth.accessToken);
  const [fetched, setFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [newData, setNewData] = useState({});
  const [oldData, setOldData] = useState({});
  const [saving, setSaving] = useState(false);
  const [storesFetchError, setStoresFetchError] = useState('');
  const [storesSaveError, setStoresSaveError] = useState('');
  const [storesSaveSuccess, setStoresSaveSuccess] = useState('');
  const [fastRefund, setFastRefund] = useState(false);
  const [standardRefund, setStandardRefund] = useState(false);
  const [message, setMessage] = useState('');
  const [fastRefundDisplay, setFastRefundDisplay] = useState(false);
  const [standardRefundDisplay, setstandardRefundDisplay] = useState(false);
  const [fastRefundEnableDb, setFastRefundEnableDb] = useState(false);
  const [standardRefundEnableDb, setStandardRefundEnableDb] = useState(false);

  // find the index of DORIS within the storeServices array
  // if DORIS does not exist, return the index where DORIS should be added
  const findIndexOfDORIS = (services) => {
    let standardRefundIndex = services.findIndex((service) => service.storeServiceTypeId === standardDoris.storeServiceTypeId);
    let fastRefundIndex = services.findIndex((service) => service.storeServiceTypeId === fastDoris.storeServiceTypeId);
    let newIndex = services.length;
    if (standardRefundIndex < 0) {
      standardRefundIndex = newIndex;
      newIndex += 1;
    }
    if (fastRefundIndex < 0) {
      fastRefundIndex = newIndex;
    }
    return {
      fastRefundIndex,
      standardRefundIndex,
    };
  };

  const updateFastRefund = (event) => {
    setFastRefund(event.target.checked);
    const toSet = cloneDeep(newData);
    const { standardRefundIndex, fastRefundIndex } = findIndexOfDORIS(toSet.storeServices);
    toSet.storeServices[fastRefundIndex].enabled = event.target.checked;
    if (event.target.checked) {
      toSet.storeServices[standardRefundIndex].enabled = false;
      setStandardRefund(false);
    }
    setNewData(toSet);
  };
  const updateStandardRefund = (event) => {
    setStandardRefund(event.target.checked);
    const toSet = cloneDeep(newData);
    const { standardRefundIndex, fastRefundIndex } = findIndexOfDORIS(toSet.storeServices);
    toSet.storeServices[standardRefundIndex].enabled = event.target.checked;
    if (event.target.checked) {
      toSet.storeServices[fastRefundIndex].enabled = false;
      setFastRefund(false);
    }
    setNewData(toSet);
  };
  useEffect(() => {
    const partnerStore = ['FRANCHISEE_PARTNER_STORE', 'MONO_BRAND_NON_FRANCHISEE_PARTNER_STORE', 'MULTI_BRAND_NON_FRANCHISEE_PARTNER_STORE'];
    const nonDoris = ['POPUP_STORE', 'NIKE_STORE_EXTENSION'];
    if (selectedStore.facilityType === 'NIKE_OWNED_STORE' && fastRefundEnableDb) {
      setMessage(`Fast Refund is currently enabled for store ${selectedStore.address.country}-${selectedStore.storeNumber}`);
    } else if (selectedStore.facilityType === 'NIKE_OWNED_STORE' && !fastRefundEnableDb) {
      setMessage(`Fast Refund is currently disabled for store ${selectedStore.address.country}-${selectedStore.storeNumber}`);
    } else if (partnerStore.includes(selectedStore.facilityType) && fastRefundEnableDb) {
      setMessage(`Fast Refund is currently enabled for store ${selectedStore.address.country}-${selectedStore.storeNumber}`);
    } else if (partnerStore.includes(selectedStore.facilityType) && standardRefundEnableDb) {
      setMessage(`Standard Refund is currently enabled for store ${selectedStore.address.country}-${selectedStore.storeNumber}`);
    } else if (partnerStore.includes(selectedStore.facilityType) && !fastRefundEnableDb && !standardRefundEnableDb) {
      setMessage(`Fast Refund and Standard Refund are currently disabled for store ${selectedStore.address.country}-${selectedStore.storeNumber}`);
    }
    if (selectedStore.facilityType === 'NIKE_OWNED_STORE') {
      setFastRefundDisplay(true);
    } else if (partnerStore.includes(selectedStore.facilityType)) {
      setFastRefundDisplay(true);
      setstandardRefundDisplay(true);
    } else if (nonDoris.includes(selectedStore.facilityType)) {
      setFastRefundDisplay(false);
      setstandardRefundDisplay(false);
    }
  }, [selectedStore, fastRefundEnableDb, standardRefundEnableDb]);
  useEffect(() => {
    if (!(accessToken && selectedStore.id)) { return; }

    setFetching(true);
    setFetched(false);
    setOldData({});
    setNewData({});
    const getStoreInfo = () => getStoreById(accessToken, selectedStore.id)
      .then(({ body }) => {
        setOldData(body);
        const toSet = cloneDeep(body);
        const { standardRefundIndex, fastRefundIndex } = findIndexOfDORIS(toSet.storeServices);
        if (!toSet.storeServices[standardRefundIndex]) {
          toSet.storeServices[standardRefundIndex] = standardDoris;
          setStandardRefund(false);
          setStandardRefundEnableDb(false);
        } else {
          const standardRefundObject = toSet.storeServices[standardRefundIndex];
          setStandardRefund(standardRefundObject.enabled);
          setStandardRefundEnableDb(standardRefundObject.enabled);
        }
        if (!toSet.storeServices[fastRefundIndex]) {
          toSet.storeServices[fastRefundIndex] = fastDoris;
          setFastRefund(false);
          setFastRefundEnableDb(false);
        } else {
          const fastRefundObject = toSet.storeServices[fastRefundIndex];
          setFastRefund(fastRefundObject.enabled);
          setFastRefundEnableDb(fastRefundObject.enabled);
        }
        setNewData(toSet);
        return true;
      })
      .catch((error) => {
        setStoresFetchError(error.message);
      })
      .finally(() => {
        setFetching(false);
        setFetched(true);
      });
    getStoreInfo();
  }, [accessToken, selectedStore]);

  const onSubmit = () => {
    setSaving(true);
    setStoresSaveError('');
    setStoresSaveSuccess('');
    const toSet = cloneDeep(newData);
    if (selectedStore.facilityType === 'NIKE_OWNED_STORE') {
      const { standardRefundIndex } = findIndexOfDORIS(toSet.storeServices);
      toSet.storeServices.splice(standardRefundIndex, 1);
    }
    const saveStore = () => putStore(accessToken, { new: toSet, old: oldData })
      .then(() => {
        setStandardRefundEnableDb(standardRefund);
        setFastRefundEnableDb(fastRefund);
        return setStoresSaveSuccess('Successfully updated Store.');
      })
      .catch((error) => {
        const err = parseSLSandBrickworksErrors(error, true);
        if (err.timeout) return setStoresSaveError('PUT timed out. Please refresh and try again.');
        if (err.brickworks) {
          return setStoresSaveSuccess('Successfully updated Store.');
        }
        return setStoresSaveError(`PUT failed with error: ${err.message}`);
      })
      .finally(() => setSaving(false));

    saveStore();
  };

  return (
    <main>
      {fetching && <Loading />}
      {fetched && (
        <section className="ncss-col-sm-12 va-sm-b">
          <h1 className="headline-1 text-color-accent">{message}</h1>
        </section>
      )}
      {fetched && storesFetchError && (
        <aside className="ncss-col-sm-12 mt8-sm text-color-error">{storesFetchError}</aside>
      )}
      {(fetched && !storesFetchError) && (
        <section className="ncss-col-sm-10 va-sm-b">
          <article className="ncss-col-sm-3 va-sm-t mt6-sm">
            {fastRefundDisplay && (
            <Checkbox
              display="none"
              id="fastRefund"
              isChecked={fastRefund}
              isDisabled={standardRefund}
              label="Enable Fast Refund"
              onChange={(e) => updateFastRefund(e)}
            />
            )}
            { standardRefundDisplay && (
            <Checkbox
              id="standardRefund"
              isChecked={standardRefund}
              isDisabled={fastRefund}
              label="Enable Standard Refund"
              onChange={(e) => updateStandardRefund(e)}
            />
            )}
          </article>
          {(fastRefundDisplay || standardRefundDisplay) && (
            <ButtonSubmit
              isLoading={saving}
              label="Save DORIS Configuration"
              submitError={storesSaveError}
              onClick={onSubmit}
            />
          )}
          {storesSaveSuccess && (
            <aside className="ncss-col-sm-12 mt8-sm text-color-success">{storesSaveSuccess}</aside>
          )}
        </section>
      )}
    </main>
  );
};

DORIS.propTypes = {
  selectedStore: PropTypes.shape({
    address: PropTypes.shape({ country: PropTypes.string.isRequired }),
    facilityType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    storeNumber: PropTypes.string.isRequired,
  }).isRequired,
};

export default DORIS;
