import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel } from '../../../reusable';

/* display form contents if adding (no form validation here)
 */
const calculateIsOpen = (properties) => (properties.adding);

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Partner"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          id="partnerName"
          isDisabled={this.props.userIsReadOnly}
          label="Partner Name"
          value={this.props.formData.partnerName || ''}
          onChange={(value) => this.props.updateFormData('partnerName', value.target.value)}
        />
      </article>
    </CustomPanel>
  );
}

Partner.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Partner;
