import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel } from '../../../reusable';

import OperationalDetails from './OperationalDetails';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || (properties.formErrors.operationalDetails
    ? (!!properties.formErrors.operationalDetails.openingDate || !!properties.formErrors.operationalDetails.closingDate)
    : true) // true because this object is required
  || !!properties.formErrors.storeStatus
  || (properties.formErrors.operationalDetails?.hoursOfOperation?.regularHours
    ? Object.values(properties.formErrors.operationalDetails.hoursOfOperation.regularHours).reduce((totalError, err) => (totalError || !!err))
    : true) // true because this object is required
  || !!(properties.formErrors.operationalDetails?.hoursOfOperation?.specialHours || false)); // false because this array is optional

class Operational extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Operational"
      onClick={this.panelToggle}
    >
      <OperationalDetails
        disableCmpFields={this.props.disableCmpFields}
        formData={this.props.formData}
        formErrors={this.props.formErrors}
        operationalDetails={cloneDeep(this.props.formData.operationalDetails) || {}}
        operationalDetailsErrors={this.props.formErrors.operationalDetails || {}}
        updateFormData={this.props.updateFormData}
        updateOperationalDetails={(childProp, childData) => this.props.updateGenericObject('operationalDetails', this.props.formData.operationalDetails, childProp, childData)}
        updateSpecialHours={(i, data) => {
          const { specialHours } = cloneDeep(this.props.formData.operationalDetails.hoursOfOperation);
          specialHours[i] = data;
          const hoursOfOperation = { ...this.props.formData.operationalDetails.hoursOfOperation, specialHours };
          this.props.updateGenericObject('operationalDetails', this.props.formData.operationalDetails, 'hoursOfOperation', hoursOfOperation);
        }}
        userIsReadOnly={this.props.userIsReadOnly}
      />
    </CustomPanel>
  );
}

Operational.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  updateGenericObject: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Operational;
