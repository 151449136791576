import { Loading } from '@nike/frame-component-library';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTable from 'react-table';

import { getDownLoadHistory } from '../../../utils/service-calls/qrcodegenerator';
import { ButtonSubmit } from '../../reusable';
import * as constants from '../manage-campaign/constants';

const headerStyles = {
  border: 'none',
  boxShadow: 'none',
  marginBottom: '10px',
  marginTop: '10px',
  paddingLeft: '10px',
  textAlign: 'left',
};

const tableStyles = {
  border: 'none',
  borderBottom: '1px solid #CFCFCF',
  boxShadow: 'none',
  fontSize: '14px',
  textAlign: 'left',
};

const seqStart = { accessor: 'seqStartNumber', Header: 'Sequence Start', headerStyle: headerStyles };
const seqEnd = { accessor: 'seqEndNumber', Header: 'Sequence End', headerStyle: headerStyles };
const columns = [
  { accessor: 'date', Header: 'Date', headerStyle: headerStyles },
  { accessor: 'email', Header: 'User', headerStyle: headerStyles },
  { accessor: 'storeNumber', Header: 'Store No.', headerStyle: headerStyles },
];

const History = (props) => {
  const accessToken = useSelector((state) => state.authorizationReducer.auth.accessToken);

  const [historyData, setHistoryData] = useState([]);
  const [isCallFailed, setIsCallFailed] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(true);
  const [campaignIdMissing, setCampaignIdMissing] = useState(false);
  const numberOfrecordsPerPage = 20;
  const campaignName = props.location && props.location.state && props.location.state.campaignName;

  const getTableDataCellProps = () => ({
    style: {
      borderRight: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  });

  const getTableRowProps = () => ({
    style: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #CFCFCF',
      borderBottom: 'none',
      borderRadius: '2px',
      height: '50px',
      paddingLeft: '10px',
    },
  });

  useEffect(() => {
    const campaignid = props.location && props.location.state && props.location.state.campaignId;

    if (campaignid) {
      getDownLoadHistory(accessToken, campaignid)
        .then((data) => {
          const resp = data && data.body && data.body.Items.length > 0 ? data.body.Items : [];
          const history = resp.sort((history1, history2) => {
            const historyDate1 = moment(history1.fullDate);
            const historyDate2 = moment(history2.fullDate);
            return moment(historyDate2).diff(historyDate1);
          });
          setHistoryData(history);
          setPendingStatus(false);
          setIsCallFailed(false);
          return data;
        })
        .catch(() => {
          setPendingStatus(false);
          setIsCallFailed(true);
        });
    } else {
      setCampaignIdMissing(true);
      setPendingStatus(false);
      setIsCallFailed(false);
    }
  }, [accessToken, props.location]);

  const getCSVContent = (data) => {
    if (campaignName.toLowerCase() === 'shop the look') return `${data.date},${data.email},${data.storeNumber},${data.seqStartNumber ? data.seqStartNumber : ''},${data.seqEndNumber ? data.seqEndNumber : ''}\r\n`;
    else return `${data.date},${data.email},${data.storeNumber}\r\n`;
  };

  const getCSVHref = (csvContent) => {
    if (campaignName.toLowerCase() === 'shop the look') return `data:text/csv;charset=utf-8,${encodeURI('Date,User,StoreNo,Sequence Start,Sequence End')}\r\n${encodeURI(csvContent)}`;
    else return `data:text/csv;charset=utf-8,${encodeURI('Date,User,StoreNo')}\r\n${encodeURI(csvContent)}`;
  };

  const exportCampaignHistoryCsv = () => {
    const csv = document.createElement('a');
    let csvContent = '';
    historyData.map((data) => {
      csvContent += getCSVContent(data);
      return csvContent;
    });
    csv.href = getCSVHref(csvContent);
    csv.target = '_blank';
    csv.download = 'QRCodeHistory.csv';
    csv.click();
  };

  const title = campaignName ? `${campaignName} 90 Days History` : '';
  const historyColumns = campaignName.toLowerCase() === 'shop the look' ? [...columns, seqStart, seqEnd] : columns;

  return (
    <section className="ncss-col-sm-8 ta-sm-l">
      <aside className="ncss-col-sm-12 ta-sm-c">
        <h3 className="headline-4 mb10-sm">{title}</h3>
      </aside>
      {isCallFailed && <p>{constants.SOMETHING_WENT_WRONG}</p>}
      {pendingStatus && <Loading />}
      {campaignIdMissing && <p>{constants.SELECT_CAMPAIGN_ID}</p>}
      {!isCallFailed && !pendingStatus && !campaignIdMissing && historyData && historyData.length > 0 ? (
        <article>
          <ReactTable
            columns={historyColumns}
            data={historyData}
            getTdProps={getTableDataCellProps}
            getTrProps={getTableRowProps}
            minRows={0}
            pageSize={
                historyData.length > numberOfrecordsPerPage
                  ? numberOfrecordsPerPage
                  : historyData.length
              }
            resizable={false}
            showPagination={historyData.length > numberOfrecordsPerPage}
            style={tableStyles}
          />
          <ButtonSubmit
            className="mb4-sm va-sm-t ncss-col-sm-12 pl0-sm"
            label="Download"
            type="button"
            onClick={exportCampaignHistoryCsv}
          />
        </article>
      ) : (!pendingStatus && !historyData && (
        <section className="ncss-col-sm-12">
          <p className="text-color-error">{constants.NO_RECORDS_FOUND}</p>
        </section>
      ))}
    </section>
  );
};

History.defaultProps = {
  location: {},
};

History.propTypes = {
  location: PropTypes.shape(),
};

export default History;
