import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import {
  warehouseCompanyValues, countryOptions, warehouseTypeValues, warehouseChannelValues,
} from '../../../../utils/static/sls-property-values';
import { CustomPanel, Select, SelectMultiple } from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!(properties.formErrors.address ? properties.formErrors.address.country : true) || !!properties.formErrors.warehouseNumber
  || !!properties.formErrors.name || !!properties.formErrors.type || !!properties.formErrors.company);

class General extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="General"
      onClick={this.panelToggle}
    >
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.address?.country || ''}
        id="countrySelect"
        isDisabled={this.props.userIsReadOnly}
        label="Country"
        options={[{ label: '', value: '' }].concat(countryOptions)}
        value={this.props.formData.address?.country || null}
        zIndex={20}
        onChange={(value) => this.props.updateGenericObject('address', this.props.formData.address, 'country', value)}
      />
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.warehouseNumber}
          isDisabled={this.props.userIsReadOnly}
          label="Warehouse Number"
          value={this.props.formData.warehouseNumber}
          onChange={({ target: { value } }) => this.props.updateFormData('warehouseNumber', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.name}
          isDisabled={this.props.userIsReadOnly}
          label="Warehouse Name"
          value={this.props.formData.name}
          onChange={({ target: { value } }) => this.props.updateFormData('name', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly}
          label="Description"
          value={this.props.formData.description}
          onChange={({ target: { value } }) => this.props.updateFormData('description', value)}
        />
      </article>
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.type}
        id="type"
        isDisabled={this.props.userIsReadOnly}
        label="Type"
        options={warehouseTypeValues.map((id) => ({ label: id, value: id }))}
        value={this.props.formData.type || ''}
        zIndex={15}
        onChange={(value) => this.props.updateFormData('type', value)}
      />
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.company}
        id="company"
        isDisabled={this.props.userIsReadOnly}
        label="Company"
        options={warehouseCompanyValues.map((val) => ({ label: val, value: val }))}
        value={this.props.formData.company || ''}
        zIndex={15}
        onChange={(value) => this.props.updateFormData('company', value)}
      />
      <SelectMultiple
        errorMessage={this.props.formErrors.channels}
        id="channels"
        isDisabled={this.props.userIsReadOnly}
        label="Channels"
        options={warehouseChannelValues.map((value) => ({ label: value, value }))}
        values={this.props.formData.channels ? this.props.formData.channels.map((channel) => ({ label: channel, value: channel })) : []}
        zIndex={14}
        onChange={(values) => this.props.updateFormData('channels', values.map((value) => value.value))}
      />
    </CustomPanel>
  );
}
General.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  updateGenericObject: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default General;
