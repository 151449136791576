import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { waitlinesFetch, waitlinesShowCreateForm, waitlinesHideCreateForm } from '../../../actions/waitlines';
import { ButtonBlack } from '../../reusable';

import CreateWaitlineCard from './CreateWaitlineCard';
import Waitlines from './Waitlines';

class WaitlineCard extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.storeId !== this.props.storeId) {
      this.props.waitlinesFetch(this.props.accessToken, this.props.storeId);
    }
  }

  render = () => (
    <section className="ncss-col-sm-12 border bg-primary-grey mt4-sm" style={{ borderRadius: '5px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.3)' }}>
      <h1 className="p2-sm">Store Waitlines</h1>
      <ButtonBlack className="mb4-sm" label="Create Waitline" onClick={this.props.showCreateForm} />
      <hr />
      {this.props.isShowingCreateForm
        ? <CreateWaitlineCard onCancel={this.props.hideCreateForm} />
        : this.props.storeId && <Waitlines waitlinesFetch={() => this.props.waitlinesFetch(this.props.accessToken, this.props.storeId)} />}
    </section>
  );
}

WaitlineCard.defaultProps = {
  accessToken: undefined,
};

WaitlineCard.propTypes = {
  accessToken: PropTypes.string,
  hideCreateForm: PropTypes.func.isRequired,
  isShowingCreateForm: PropTypes.bool.isRequired,
  showCreateForm: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  waitlinesFetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
  isShowingCreateForm: state.waitlinesReducer.showCreateForm,
});

const mapDispatchToProps = (dispatch) => ({
  hideCreateForm: () => dispatch(waitlinesHideCreateForm()),
  showCreateForm: () => dispatch(waitlinesShowCreateForm()),
  waitlinesFetch: (token, storeId) => dispatch(waitlinesFetch(token, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitlineCard);
