import countries from 'i18n-iso-countries';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { MSG_AUTH_EXPIRE } from '../../../constants/constants';
import { parseSLSandBrickworksErrors } from '../../../utils/formatting';
import { postDigitalStore, putDigitalStore } from '../../../utils/service-calls/sls';
import { getFilteredDigitalStores } from '../../../utils/sls-filter-handlers';
import { isUserAllowed, isUserReadOnly } from '../../../utils/tab-permissions';
import { Main } from '../generic';

import DigitalStoresForm from './DigitalStoresForm';

const config = require('../../../config')();
// have to do this to get country names in english
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

class DigitalStoresMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: { label: 'All Countries', value: '' },
      formOpen: this.props.detailOnly || false,
      postError: '',
      posting: false,
      postSuccess: '',
      putError: '',
      putSuccess: '',
      putting: false,
      region: { label: 'All Regions', value: '' },
      search: '',
    };
  }

  clearFilters = () => this.setState({
    country: { label: 'All Countries', value: '' },
    region: { label: 'All Regions', value: '' },
    search: '',
  }, () => this.props.updateDigitalStores(getFilteredDigitalStores(this.state, this.props.allDigitalStores)));

  closeOrOpenForm = (formData = {}) => {
    this.props.updateFormData(formData);
    if (!this.state.formOpen) {
      // if the form is currently closed, open it
      this.setState({
        formOpen: true, postError: '', postSuccess: '', putError: '', putSuccess: '',
      });
    } else {
      // if the form is currently open, close it
      this.setState({ formOpen: false });
    }
    this.props.history.push('/storelocationservices/digital-stores', {
      postError: '', postSuccess: '', putError: '', putSuccess: '',
    });
  };

  handleError = (method, message) => this.setState({ formOpen: false, [method]: message },
    () => this.handleFetch());

  handleFetch = () => this.setState({ posting: false, putting: false, search: '' },
    () => this.props.history.push('/storelocationservices/digital-stores', this.state));

  handleSuccess = (method, message) => this.setState({ formOpen: false, [method]: message },
    () => this.handleFetch());

  post = (data) => this.setState({ posting: true },
    async () => postDigitalStore(this.props.userInfo.accessToken, data)
      .then(() => this.handleSuccess('postSuccess', 'Successfully added Digital Store.'))
      .catch((error) => {
        const err = parseSLSandBrickworksErrors(error);
        if (err.timeout) return this.handleError('postError', 'POST timed out. Please refresh and try again.');
        if (err.message === MSG_AUTH_EXPIRE) {
          // don't refresh the page if the reason their call failed was because of old auth. they need to see the error first.
          return this.setState({ postError: `POST failed with error: ${err.message}`, posting: false });
        }
        return this.handleError('postError', `POST failed with error: ${err.message}`);
      }));

  put = (data) => this.setState({ putting: true },
    async () => {
      const dataToSend = data;
      if (!isUserAllowed(this.props.userInfo.groups, 'SLS.IP')
        || this.props.formData.region !== 'EUROPE_MIDDLE_EAST_AFRICA') {
        // if we are not editing IP attributes, don't send them in the update
        delete dataToSend.islandPacific;
      }
      return putDigitalStore(this.props.userInfo.accessToken, { new: dataToSend, old: this.props.formData })
        .then(() => this.handleSuccess('putSuccess', 'Successfully updated Digital Store.'))
        .catch((error) => {
          const err = parseSLSandBrickworksErrors(error);
          if (err.timeout) return this.handleError('putError', 'PUT timed out. Please refresh and try again.');
          if (err.message === MSG_AUTH_EXPIRE) {
            // don't refresh the page if the reason their call failed was because of old auth. they need to see the error first.
            return this.setState({ putError: `PUT failed with error: ${err.message}`, putting: false });
          }
          return this.handleError('putError', `PUT failed with error: ${err.message}`);
        });
    });

  updateFilter = (filterType, value) => this.setState({ [filterType]: { label: value.label, value: value.value } },
    () => this.props.updateDigitalStores(getFilteredDigitalStores(this.state, this.props.allDigitalStores)));

  updateSearch = (search) => this.setState({ search },
    () => this.props.updateDigitalStores(getFilteredDigitalStores(this.state, this.props.allDigitalStores)));

  render = () => (
    <Main
      changeSearch={(value) => this.updateSearch(value)}
      clearFilters={this.clearFilters}
      closeOrOpenForm={(data) => this.closeOrOpenForm(data)}
      columns={[
        {
          accessor: (d) => (d.storeNumber),
          Cell: (d) => <a href={`${config.host}/storelocationservices/digital-stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.storeNumber}</a>,
          Header: 'Store #',
          id: 'Store #',
          style: { fontWeight: 'bold' },
          width: 100,
        },
        {
          accessor: (d) => (d.name),
          Cell: (d) => <a href={`${config.host}/storelocationservices/digital-stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.name}</a>,
          Header: 'Store Name',
          id: 'Store Name',
          style: { fontWeight: 'bold' },
        },
        {
          accessor: (d) => (d.country),
          Cell: (d) => <a href={`${config.host}/storelocationservices/digital-stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.country}</a>,
          Header: 'Country',
          id: 'Country',
          width: 150,
        },
        {
          accessor: (d) => (d.region),
          Cell: (d) => <a href={`${config.host}/storelocationservices/digital-stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.region}</a>,
          Header: 'Region',
          id: 'Region',
        },
      ]}
      country={this.state.country}
      facilities={this.props.digitalStores}
      facilitiesAll={this.props.allDigitalStores}
      facilityType="Digital Stores"
      filters={[
        {
          accessor: 'region',
          changeValue: (value) => this.updateFilter('region', value),
          getLabel: (code) => code,
          id: 'regionFilter',
          label: 'All Regions',
          option: this.state.region,
        },
        {
          accessor: 'country',
          changeValue: (value) => this.updateFilter('country', value),
          // TODO: remove Türkiye hardcoding when dependent module releases a version with the new name
          getLabel: (code) => (code === 'TUR' ? 'Türkiye' : countries.getName(code, 'en')),
          id: 'countryFilter',
          label: 'All Countries',
          option: this.state.country,
        },
      ]}
      form={(
        <DigitalStoresForm
          adding={this.props.formData.id === undefined}
          formData={this.props.formData}
          showIP={isUserAllowed(this.props.userInfo.groups, 'SLS.IP')
            && this.props.formData.region === 'EUROPE_MIDDLE_EAST_AFRICA'}
          submit={this.props.formData.id === undefined
            ? (data, errs) => this.post(data, errs)
            : (data, errs) => this.put(data, errs)}
          userGroups={this.props.userInfo.groups}
          userIsReadOnly={isUserReadOnly(this.props.userInfo.groups, ['SLS.ReadOnly', 'SLS'])}
        />
      )}
      formOpen={this.state.formOpen}
      getError={this.props.getError}
      getting={this.props.getting}
      post={(data) => this.submit(data)}
      postError={this.props.history.location.state?.postError || this.state.postError || ''}
      postSuccess={this.props.history.location.state?.postSuccess || ''}
      posting={this.state.posting}
      put={(data) => this.submit(data)}
      putError={this.props.history.location.state?.putError || this.state.putError || ''}
      putSuccess={this.props.history.location.state?.putSuccess || ''}
      putting={this.state.putting}
      region={this.state.region}
      search={this.state.search}
      userGroups={this.props.userInfo.groups}
    />
  );
}

DigitalStoresMain.propTypes = {
  allDigitalStores: PropTypes.arrayOf(PropTypes.shape).isRequired,
  detailOnly: PropTypes.bool.isRequired,
  digitalStores: PropTypes.arrayOf(PropTypes.shape).isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  getError: PropTypes.string.isRequired,
  getting: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  updateDigitalStores: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    accessToken: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default withRouter(DigitalStoresMain);
