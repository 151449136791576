import PropTypes from 'prop-types';
import React from 'react';

import { isUserAllowed } from '../../../utils/tab-permissions';
import { fieldsToValidate } from '../../../utils/validation/sls-validate-warehouses';
import { validateFields } from '../../../utils/validation/sls-validation';
import { ButtonBlack, ButtonSubmit, JSONFormatter } from '../../reusable';

import Address from './address';
import Contact from './contact';
import FacilityDimensions from './facility-dimensions';
import General from './general';
import IslandPacific from './island-pacific';
import Localization from './localization';
import Operational from './operational';

export default class WarehousesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { ...this.props.formData },
      formErrors: validateFields(this.props.formData, fieldsToValidate, this.props.showIP, false, this.props.adding),
      showComparison: false,
      submitError: '',
    };
  }

  submit = (data) => {
    const dataToSend = data;
    if (!this.props.showIP) {
      // if we are not viewing IP attributes, don't send them in the update
      delete dataToSend.islandPacificAttributes;
    }
    // check if the user has permissions to edit this store
    if (!isUserAllowed(this.props.userGroups, 'SLS.ALL')
      && !isUserAllowed(this.props.userGroups, `SLS.${data.region}`)) {
      this.setState({ submitError: `User does not have permissions to perform actions in the region: ${data.region}` });
      return;
    }
    // confirm that the user has double-checked their data
    // eslint-disable-next-line no-alert
    if (window.confirm('Have you double checked these store settings and ensured these are the values you want to be reflected?')) {
      window.scroll(0, 0);
      this.props.submit(data);
    }
  };

  // all form updates end up going through this function
  updateFormData = (propName, data) => this.setState((prevState) => {
    const { formData } = prevState;
    if (data === '') {
      delete formData[propName];
    } else {
      formData[propName] = data;
      if (Array.isArray(formData[propName]) && formData[propName].length === 0) {
        delete formData[propName];
      }
    }
    const formErrors = validateFields(formData, fieldsToValidate, this.props.showIP, false, this.props.adding);
    return ({ formData, formErrors, submitError: '' });
  });

  updateGenericObject = (genericObjectName, genericObjectData, childPropName, childData) => {
    let genericObject = { ...genericObjectData };
    if (childData && typeof childData === 'object') {
      if (Object.values(childData).every((value) => (!value))) {
        // eslint-disable-next-line no-param-reassign
        childData = {};
      }
    }
    if (!childData || (typeof childData === 'object' && Object.keys(childData)?.length === 0)) {
      delete genericObject[childPropName];
      if (Object.keys(genericObject).length === 0) {
        // do this so that the empty object is deleted when we call updateFormData
        genericObject = '';
      }
    } else {
      genericObject[childPropName] = childData;
    }
    this.updateFormData(genericObjectName, genericObject);
  };

  render = () => (
    <article className="ncss-row ta-sm-c va-sm-t">
      {this.props.adding && <header className="ncss-col-sm-12 headline-1">Add Warehouse</header>}
      {!this.props.adding && Object.keys(this.props.formData).length > 0 && (
        <header className="ta-sm-l">
          <h1 className="headline-1">
            {this.props.formData.address
              ? `${this.props.formData.address.country} - ${this.props.formData.name}`
              : this.props.formData.name}
          </h1>
          <aside className="va-sm-t pt4-sm pb6-sm">
            <p className="headline-5">Address:</p>
            <p className="body-2 pl3-sm">
              {this.props.formData.address && (
                `${this.props.formData.address.address1},
                ${this.props.formData.address.city},
                ${this.props.formData.address.state},
                ${this.props.formData.address.postalCode}`
              )}
            </p>
            <p className="headline-5">Phone:</p>
            <p className="body-2 pl3-sm">{this.props.formData.phone}</p>
            <p className="headline-5">Email:</p>
            <p className="body-2 pl3-sm">{this.props.formData.email}</p>
            <p className="headline-5">Store Id:</p>
            <p className="body-2 pl3-sm">{this.props.formData.id}</p>
          </aside>
        </header>
      )}
      <aside className="ncss-col-sm-12 pb8-sm">
        <ButtonBlack
          label={`${this.state.showComparison ? 'Hide' : 'View'} Generated Fields`}
          onClick={() => this.setState((prevState) => ({ showComparison: !prevState.showComparison }))}
        />
        {this.state.showComparison && (
          <article className="ncss-row ta-sm-l pb4-sm">
            <section className="ncss-col-sm-6 va-sm-t text-color-error">
              Initial Warehouse Data:
              {/* the initial formData */}
              <JSONFormatter data={this.props.formData} />
            </section>
            <section className="ncss-col-sm-6 va-sm-t text-color-success">
              Altered Warehouse Data:
              {/* the edited formData */}
              <JSONFormatter data={this.state.formData} />
            </section>
          </article>
        )}
      </aside>
      <main className="ncss-row ta-sm-l bg-primary-grey pt2-sm prl2-sm border">
        <General
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          updateGenericObject={this.updateGenericObject}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Contact
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          updateManagerName={(childProp, childData) => this.updateGenericObject('managerName', this.state.formData.managerName, childProp, childData)}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Address
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateAddress={(childProp, childData) => this.updateGenericObject('address', this.state.formData.address, childProp, childData)}
          updateFormData={this.updateFormData}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Localization
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFormData={this.updateFormData}
          updateLocalization={(childProp, childData) => this.updateGenericObject('localizations', this.state.formData.localizations, childProp, childData)}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <Operational
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateGenericObject={this.updateGenericObject}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        <FacilityDimensions
          adding={this.props.adding}
          formData={this.state.formData}
          formErrors={this.state.formErrors}
          updateFacilityDimensions={(childProp, childData) => this.updateGenericObject('facilityDimensions', this.state.formData.facilityDimensions, childProp, childData)}
          userIsReadOnly={this.props.userIsReadOnly}
        />
        {this.props.showIP && !this.props.adding && (
          <IslandPacific
            islandPacific={this.state.formData.islandPacificAttributes || {}}
            islandPacificErrors={this.state.formErrors.islandPacificAttributes || {}}
            updateIslandPacific={(childProp, childData) => this.updateGenericObject('islandPacificAttributes', this.state.formData.islandPacificAttributes, childProp, childData)}
            userIsReadOnly={this.props.userIsReadOnly}
          />
        )}
      </main>
      <footer className="ncss-row">
        <ButtonSubmit
          isDisabled={this.state.formErrors.errors || this.props.userIsReadOnly}
          submitError={this.state.submitError}
          onClick={() => this.submit(this.state.formData)}
        />
      </footer>
    </article>
  );
}

WarehousesForm.propTypes = {
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  showIP: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};
