import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import {
  keyCityValues, IPChannelValues, IPPlatformValues, IPSubTerritory,
  IPTempClosureReasonValues, IPTerritory, IPTimezones,
} from '../../../../utils/static/sls-property-values';
import {
  Creatable, CustomPanel, DateInput, Select, SelectMultiple,
} from '../../../reusable';

/* display form contents...
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => ( // not checking for adding here is intentional
  !!properties.islandPacificErrors.ipChannel
  || !!properties.islandPacificErrors.sapShipTo
  || (properties.islandPacificErrors.sellingArea
    ? (!!properties.islandPacificErrors.sellingArea.gross || !!properties.islandPacificErrors.sellingArea.net)
    : false) // false because this object is optional
  || !!properties.islandPacificErrors.distributionCenter
  || !!properties.islandPacificErrors.temporaryClosure
  || !!properties.islandPacificErrors.keyCity);

class IslandPacific extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.islandPacificErrors !== this.props.islandPacificErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Island Pacific"
      onClick={this.panelToggle}
    >
      <SelectMultiple
        id="ipPlatform"
        isDisabled={this.props.userIsReadOnly}
        label="IP Platforms"
        options={IPPlatformValues.map((platform) => ({ label: platform, platform, value: platform }))}
        values={this.props.islandPacific.ipPlatform ? this.props.islandPacific.ipPlatform.map((platform) => ({ label: platform, platform, value: platform })) : []}
        zIndex={13}
        onChange={(values) => this.props.updateIslandPacific('ipPlatform', values.map((platform) => platform.platform))}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipChannel}
        id="ipChannel"
        isDisabled={this.props.userIsReadOnly}
        label="IP Channel"
        options={IPChannelValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipChannel || ''}
        onChange={(value) => this.props.updateIslandPacific('ipChannel', value)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="vatIdentifier"
          isDisabled={this.props.userIsReadOnly}
          label="VAT Identifier"
          value={this.props.islandPacific.vatIdentifier || ''}
          onChange={(value) => this.props.updateIslandPacific('vatIdentifier', value.target.value)}
        />
      </article>
      <Creatable
        isMulti
        errorMessage={this.props.islandPacificErrors.sapShipTo}
        helpMessage="must be 6 digits"
        isDisabled={this.props.userIsReadOnly}
        label="SAP Ship To"
        value={this.props.islandPacific.sapShipTo ? this.props.islandPacific.sapShipTo.map((value) => ({ label: value, value })) : []}
        width={6}
        zIndex={12}
        onChange={(values) => this.props.updateIslandPacific('sapShipTo', values ? values.map((value) => value.value) : null)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          errorMessage={this.props.islandPacificErrors.sellingArea.gross}
          id="seGross"
          isDisabled={this.props.userIsReadOnly}
          label="Gross Selling Area (in square feet)"
          value={this.props.islandPacific.sellingArea?.gross || ''}
          onChange={(value) => {
            const sellingArea = {
              ...this.props.islandPacific.sellingArea,
              gross: value.target.value,
            };
            this.props.updateIslandPacific('sellingArea', sellingArea);
          }}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          errorMessage={this.props.islandPacificErrors.sellingArea.net}
          id="seNet"
          isDisabled={this.props.userIsReadOnly}
          label="Net Selling Area (in square feet)"
          value={this.props.islandPacific.sellingArea?.net || ''}
          onChange={(value) => {
            const sellingArea = {
              ...this.props.islandPacific.sellingArea,
              net: value.target.value,
            };
            this.props.updateIslandPacific('sellingArea', sellingArea);
          }}
        />
      </article>
      <Select
        className="ncss-col-sm-3 va-sm-t"
        id="ipTerritory"
        isDisabled={this.props.userIsReadOnly}
        label="Territory"
        options={IPTerritory.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipTerritory || ''}
        zIndex={11}
        onChange={(value) => this.props.updateIslandPacific('ipTerritory', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        id="ipSubTerritory"
        isDisabled={this.props.userIsReadOnly}
        label="Sub Territory"
        options={IPSubTerritory.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipSubTerritory || ''}
        zIndex={11}
        onChange={(value) => this.props.updateIslandPacific('ipSubTerritory', value)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          errorMessage={this.props.islandPacificErrors.distributionCenter}
          id="dcCode"
          isDisabled={this.props.userIsReadOnly}
          label="Distribution Center Code"
          value={this.props.islandPacific.distributionCenter?.code || ''}
          onChange={(value) => {
            const distributionCenter = {
              ...this.props.islandPacific.distributionCenter,
              code: value.target.value,
            };
            this.props.updateIslandPacific('distributionCenter', distributionCenter);
          }}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          errorMessage={this.props.islandPacificErrors.distributionCenter}
          id="dcDescription"
          isDisabled={this.props.userIsReadOnly}
          label="Distribution Center Description"
          value={this.props.islandPacific.distributionCenter?.description || ''}
          onChange={(value) => {
            const distributionCenter = {
              ...this.props.islandPacific.distributionCenter,
              description: value.target.value,
            };
            this.props.updateIslandPacific('distributionCenter', distributionCenter);
          }}
        />
      </article>
      <DateInput
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.temporaryClosure}
        id="fromDate"
        isDisabled={this.props.userIsReadOnly}
        label="Temp Closure From Date"
        value={this.props.islandPacific.temporaryClosure?.fromDate || ''}
        onChange={(value) => {
          const temporaryClosure = {
            ...this.props.islandPacific.temporaryClosure,
            fromDate: value,
            reason: this.props.islandPacific.temporaryClosure?.reason || '',
          };
          this.props.updateIslandPacific('temporaryClosure', temporaryClosure);
        }}
      />
      <DateInput
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.temporaryClosure}
        id="toDate"
        isDisabled={this.props.userIsReadOnly}
        label="Temp Closure To Date"
        value={this.props.islandPacific.temporaryClosure?.toDate || ''}
        onChange={(value) => {
          const temporaryClosure = {
            ...this.props.islandPacific.temporaryClosure,
            reason: this.props.islandPacific.temporaryClosure?.reason || '',
            toDate: value,
          };
          this.props.updateIslandPacific('temporaryClosure', temporaryClosure);
        }}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.temporaryClosure}
        id="reason"
        isDisabled={this.props.userIsReadOnly}
        label="Temp Closure Reason"
        options={IPTempClosureReasonValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.temporaryClosure?.reason || ''}
        onChange={(value) => {
          const temporaryClosure = {
            ...this.props.islandPacific.temporaryClosure,
            reason: value,
          };
          this.props.updateIslandPacific('temporaryClosure', temporaryClosure);
        }}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.keyCity}
        id="keyCity"
        isDisabled={this.props.userIsReadOnly}
        label="Key City"
        options={keyCityValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.keyCity || ''}
        onChange={(value) => this.props.updateIslandPacific('keyCity', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        id="ipTimezone"
        isDisabled={this.props.userIsReadOnly}
        label="Timezone"
        options={IPTimezones.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipTimezone || ''}
        onChange={(value) => this.props.updateIslandPacific('ipTimezone', value)}
      />
    </CustomPanel>
  );
}

IslandPacific.propTypes = {
  islandPacific: PropTypes.shape().isRequired,
  islandPacificErrors: PropTypes.shape().isRequired,
  updateIslandPacific: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default IslandPacific;
