import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { countryOptions, storeChannelValues } from '../../../../utils/static/sls-property-values';
import { CustomPanel, Select } from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!properties.formErrors.name || !!properties.formErrors.storeNumber
  || !!properties.formErrors.country || !!properties.formErrors.storeChannel);

class General extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="General"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.name}
          isDisabled={this.props.userIsReadOnly}
          label="Name"
          value={this.props.formData.name || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('name', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.storeNumber}
          isDisabled={!this.props.adding && !this.props.userIsOwner}
          label="Store Number"
          value={this.props.formData.storeNumber || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('storeNumber', value)}
        />
      </article>
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.country}
        id="countrySelect"
        isDisabled={!this.props.adding && !this.props.userIsOwner}
        label="Country"
        options={[{ label: '', value: '' }].concat(countryOptions)}
        value={this.props.formData.country || ''}
        zIndex={20}
        onChange={(value) => this.props.updateFormData('country', value)}
      />
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.storeChannel}
        id="storeChannel"
        isDisabled={this.props.userIsReadOnly}
        label="Store Channel"
        options={[{ label: '', value: '' }].concat(storeChannelValues.map((value) => ({ label: value, value })))}
        value={this.props.formData.storeChannel || ''}
        onChange={(value) => this.props.updateFormData('storeChannel', value)}
      />
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.phone}
          isDisabled={!this.props.adding && !this.props.userIsOwner}
          label="Phone Number"
          value={this.props.formData.phone || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('phone', value)}
        />
      </article>
    </CustomPanel>
  );
}

General.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsOwner: PropTypes.bool.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default General;
