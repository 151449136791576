{
    "lastA11yAudit": "",
    "name": "LegendItem",
    "docs": "#/components/LegendItem",
    "description": "LegendItem is a component.",
    "figma": "",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/LegendItem",
    "tags": ["legenditem"],
    "designStatuses": ["Beta"],
    "devStatuses": ["Beta"]
}
