{
    "lastA11yAudit": "07/08/22",
    "name": "Radio",
    "description": "Radio is a selection from a set of visible options where only one can be selected at a time.",
    "docs": "#/components/forms/Radio",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Radio",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=48843%3A356696",
    "tags": ["Radio", "inputs", "form elements", "forms"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
