import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { storeServicesFetch, storeServiceTypesFetch } from '../../actions/store-services';
import { loadState } from '../../utils/local-storage';
import { fetchTopics } from '../../utils/service-calls/store-services';
import { storeServicesPermissions } from '../../utils/tab-permissions';
import {
  ButtonBlack, PageTemplate, StoreSelect, TableRowClick,
} from '../reusable';

import StoreServiceForm from './StoreServiceForm';
import TopicForm from './TopicForm';

const storeviewsFields = ['id'];

const o2oTypesTest = ['e0131bb6-a2e4-4437-89a4-bf6ce44149d0', 'a3f06c89-4f79-416d-9e78-0dc5437b2307', '0342c6e2-4dd7-4fa0-807d-a36b9efcc3ce'];
const o2oTypesProd = ['349d7825-6974-475b-b0a1-a8d2bd87a4f6', '29e99806-b350-47ea-a3aa-3682a9121f58', '5a8b4322-9c4b-4842-9dc0-80e408591578'];

class StoreServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: true,
      currentService: {},
      errorMessage: '',
      formOpenStoreService: false,
      formOpenTopic: false,
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      topic: {},
      topicsList: [],
    };
  }

  o2oServiceTypeIds = () => (process.env.NODE_ENV === 'production' ? o2oTypesProd : o2oTypesTest);

  selectCountry = (selectedCountry) => this.setState({ selectedCountry, selectedStore: null });

  storeSelected = (selectedStore) => {
    this.setState({ formOpenStoreService: false, formOpenTopic: false, selectedStore });
    this.props.storeServicesFetch(this.props.accessToken, selectedStore.id);
    this.props.storeServiceTypesFetch(this.props.accessToken);
    this.setState({ fetchingTopics: true }, () => fetchTopics(this.props.accessToken, selectedStore.id)
      .then((res) => this.setState({ fetchingTopics: false, topicsList: res.body.objects }))
      .catch((error) => this.setState({ errorMessage: error.message, fetchingTopics: false })));
  };

  render = () => (
    <PageTemplate
      auth={storeServicesPermissions}
      description="Manage store services and workforce topics to handle athlete and customer notifications."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Store+Services+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-10 va-sm-c">
          <StoreSelect
            selectCountry={this.selectCountry}
            selectStore={this.storeSelected}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            storeviewsFields={storeviewsFields}
          />
          {this.state.selectedStore && (
            <section className="ncss-row">
              {!this.state.formOpenTopic && (
                <>
                  <article className="ncss-col-sm-6 pt3-sm pb5-sm">
                    <ButtonBlack
                      label={!this.state.formOpenStoreService ? 'Add New Store Service' : 'Cancel'}
                      onClick={() => this.setState((prevState) => (prevState.formOpenStoreService
                        ? { adding: false, currentService: {}, formOpenStoreService: false }
                        : { adding: true, formOpenStoreService: true }))}
                    />
                  </article>
                  {this.state.formOpenStoreService && (
                    <StoreServiceForm
                      accessToken={this.props.accessToken}
                      adding={this.state.adding}
                      currentService={this.state.currentService}
                      storeId={this.state.selectedStore.id}
                      storeServiceTypeOptions={this.props.storeServiceTypes}
                    />
                  )}
                </>
              )}
              {!this.state.formOpenStoreService && (
                <>
                  <article className="ncss-col-sm-6 pt3-sm pb5-sm">
                    <ButtonBlack
                      label={!this.state.formOpenTopic ? 'Add New Topic' : 'Cancel'}
                      onClick={() => this.setState((prevState) => (prevState.formOpenTopic
                        ? { adding: false, formOpenTopic: false, topic: {} }
                        : { adding: true, formOpenTopic: true }))}
                    />
                  </article>
                  {this.state.formOpenTopic && (
                    <TopicForm
                      accessToken={this.props.accessToken}
                      adding={this.state.adding}
                      storeId={this.state.selectedStore.id}
                      topic={this.state.topic}
                    />
                  )}
                </>
              )}
              {!this.state.formOpenTopic && !this.state.formOpenStoreService && (
                <section className="ncss-row">
                  <article className="ncss-col-sm-6">
                    <TableRowClick
                      columns={[{ accessor: 'name', Header: 'Store Services' }]}
                      // Remove DORIS, SHIP_FROM_STORE, BOPIS and empty service name for store
                      data={this.props.storeServices?.filter((service) => service?.name && !this.o2oServiceTypeIds()?.includes(service?.storeServiceTypeId)) || []}
                      fetching={this.props.fetchStoreServicesRequest !== null}
                      handleClick={(currentService) => this.setState({ adding: false, currentService, formOpenStoreService: true })}
                    />
                  </article>
                  <article className="ncss-col-sm-6">
                    <TableRowClick
                      columns={[{ accessor: 'topicName', Header: 'Topics' }]}
                      data={this.state.topicsList || []}
                      fetching={this.state.fetchingTopics}
                      handleClick={(topic) => this.setState({ adding: false, formOpenTopic: true, topic })}
                    />
                  </article>
                </section>
              )}
            </section>
          )}
          {this.state.errorMessage === this.props.fetchStoreServicesError
            && this.props.fetchStoreServicesError === this.props.fetchStoreServiceTypesError
            ? <p className="text-color-error mt4-sm">{this.state.errorMessage}</p>
            : (
              <p className="text-color-error mt4-sm">
                {this.state.errorMessage}<br />
                {this.props.fetchStoreServicesError}<br />
                {this.props.fetchStoreServiceTypesError}<br />
              </p>
            )}
        </main>
      )}
      path={this.props.location.pathname}
      title="Store Services"
    />
  )
}

StoreServices.defaultProps = {
  accessToken: undefined,
  fetchStoreServicesRequest: undefined,
};

StoreServices.propTypes = {
  accessToken: PropTypes.string,
  fetchStoreServicesError: PropTypes.string.isRequired,
  fetchStoreServicesRequest: PropTypes.shape(),
  fetchStoreServiceTypesError: PropTypes.string.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  storeServices: PropTypes.arrayOf(PropTypes.shape).isRequired,
  storeServicesFetch: PropTypes.func.isRequired,
  storeServiceTypes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  storeServiceTypesFetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
  fetchStoreServicesError: state.storeServicesReducer.fetchStoreServicesError,
  fetchStoreServicesRequest: state.storeServicesReducer.fetchStoreServicesRequest,
  fetchStoreServiceTypesError: state.storeServicesReducer.fetchStoreServiceTypesError,
  storeServices: state.storeServicesReducer.storeServices,
  storeServiceTypes: state.storeServicesReducer.storeServiceTypes,
});

const mapDispatchToProps = (dispatch) => ({
  storeServicesFetch: (token, storeId) => dispatch(storeServicesFetch(token, storeId)),
  storeServiceTypesFetch: (token) => dispatch(storeServiceTypesFetch(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreServices);
