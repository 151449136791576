import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';

import 'react-table/react-table.css';

class TableRowClick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredIndex: null,
    };
  }

  render = () => (
    <article className="ncss-col-sm-12">
      {/* eslint-disable-next-line no-nested-ternary */}
      {this.props.fetching
        ? <Loading className="mt-10-sm" />
        : this.props.data.length > 0
          ? (
            <ReactTable
              className="-striped -highlight"
              columns={this.props.columns.map((col) => ({ ...col, headerStyle: { background: 'black', color: 'white', fontSize: '20px' } }))}
              data={this.props.data}
              defaultPageSize={this.props.showPagination ? this.props.defaultPageSize : this.props.data.length}
              getTdProps={() => ({
                style: {
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowX: 'auto',
                },
              })}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: () => {
                      this.props.handleClick(rowInfo.original);
                    },
                    onMouseLeave: () => {
                      this.setState({ hoveredIndex: null });
                    },
                    onMouseOver: () => {
                      this.setState({ hoveredIndex: rowInfo.index });
                    },
                    style: {
                      background: rowInfo.index === this.state.hoveredIndex ? '#FA5400' : '',
                      color: rowInfo.index === this.state.hoveredIndex ? 'white' : '',
                      cursor: 'pointer',
                    },
                  };
                } else {
                  return {};
                }
              }}
              showPagination={this.props.showPagination}
              style={{ height: 'auto' }}
            />
          )
          : this.props.fetched && <p className="body-1 text-color-accent">No Matches Found.</p>}
    </article>
  );
}

TableRowClick.defaultProps = {
  defaultPageSize: 10,
  fetched: true,
  fetching: false,
  handleClick: () => null,
  showPagination: false,
};

TableRowClick.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  defaultPageSize: PropTypes.number,
  fetched: PropTypes.bool,
  fetching: PropTypes.bool,
  handleClick: PropTypes.func,
  showPagination: PropTypes.bool,
};

export default TableRowClick;
