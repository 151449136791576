import React from 'react';

import StoreHierarchyContextProvider from './StoreHierarchyContextProvider';
import StoreHierarchyPage from './StoreHierarchyPage';

const StoreHierarchyApp = () => (
  <StoreHierarchyContextProvider>
    <StoreHierarchyPage />
  </StoreHierarchyContextProvider>
);

export default StoreHierarchyApp;
