import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel } from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!properties.nikeAppErrors.notificationRadius
  || !!properties.geoFenceErrors.entranceRadius || !!properties.geoFenceErrors.exitRadius);

class NikeApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nikeAppErrors !== this.props.nikeAppErrors || prevProps.geoFenceErrors !== this.props.geoFenceErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Nike App"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-4 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly}
          label="Event Link"
          value={this.props.nikeApp.eventLink || ''}
          onChange={({ target: { value } }) => this.props.updateNikeApp('eventLink', value)}
        />
      </article>
      <article className="ncss-col-sm-4 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly}
          label="Welcome Link"
          value={this.props.nikeApp.welcomeLink || ''}
          onChange={({ target: { value } }) => this.props.updateNikeApp('welcomeLink', value)}
        />
      </article>
      <article className="ncss-col-sm-4 va-sm-t">
        <Input
          errorMessage={this.props.nikeAppErrors.notificationRadius}
          isDisabled={this.props.userIsReadOnly}
          label="Notification Radius"
          value={this.props.nikeApp.notificationRadius || ''}
          onChange={({ target: { value: notificationRadius } }) => this.props.updateNikeApp('notificationRadius', notificationRadius)}
        />
      </article>
      <header className="ncss-col-sm-12 headline-2 mt2-sm">Geo Fence</header>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.geoFenceErrors.entranceRadius}
          isDisabled={this.props.userIsReadOnly}
          label="Entrance Radius"
          type="number"
          value={this.props.geoFence.entranceRadius || ''}
          onChange={({ target: { value: entranceRadius } }) => this.props.updateGeoFence('entranceRadius', entranceRadius)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.geoFenceErrors.exitRadius}
          isDisabled={this.props.userIsReadOnly}
          label="Exit Radius"
          type="number"
          value={this.props.geoFence.exitRadius || ''}
          onChange={({ target: { value: exitRadius } }) => this.props.updateGeoFence('exitRadius', exitRadius)}
        />
      </article>
    </CustomPanel>
  );
}

NikeApp.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  geoFence: PropTypes.shape().isRequired,
  geoFenceErrors: PropTypes.shape().isRequired,
  nikeApp: PropTypes.shape().isRequired,
  nikeAppErrors: PropTypes.shape().isRequired,
  updateGeoFence: PropTypes.func.isRequired,
  updateNikeApp: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default NikeApp;
