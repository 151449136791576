/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Modal as EdsModal } from '@nike/eds';
import React from 'react';

const Modal = ({
  hideFade = true, // Fixes issue of fade effect artifacts when using Select within a Modal
  children,
  ...props
}) => (
  <EdsModal
    {...props}
    hideFade={hideFade}
  >
    {children}
  </EdsModal>
);

export default Modal;
