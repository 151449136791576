{
    "lastA11yAudit": "07/08/22",
    "name": "Text",
    "description": "Text is the base component for displaying text in all of the various weights and sizes design calls for.",
    "docs": "#/components/typography/Text",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=1%3A36",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Text",
    "tags": ["Text", "Type", "Typography", "Font", "content"],
    "designStatuses": ["N/A"],
    "devStatuses": ["Shipped"]
}
