export const schema = {
  address: {
    address1: '',
    city: '',
    country: '',
    postalCode: '', // number
    state: '',
  },
  company: '',
  coordinates: {
    latitude: '', // number
    longitude: '', // number
  },
  currencies: [],
  description: '',
  email: '',
  facilityDimensions: {
    measurementUnits: '',
    totalArea: '', // number
    totalStorageArea: '', // number
  },
  islandPacificAttributes: {
    ipChannel: '',
    ipWarehouseType: '',
    sapShipTo: [],
    warehouseNumber: '',
  },
  managerName: {
    firstName: '',
    lastName: '',
  },
  name: '',
  phone: '',
  timezone: '',
  type: '',
};

export default schema;
