import PropTypes from 'prop-types';
import React from 'react';
import {
  Tabs, Tab, TabList, TabPanel,
} from 'react-tabs';

import { printerPortalPermissions } from '../../utils/tab-permissions';
import { PageTemplate } from '../reusable';

import AddPrinter from './AddEditPrinter';
import ViewPrinters from './ViewPrinters';

const PrinterPortal = (props) => (
  <PageTemplate
    auth={printerPortalPermissions}
    description="View and manage a store's available printers."
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Printer+Portal+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
    )}
    page={(
      <Tabs>
        <TabList>
          <Tab>View Printers</Tab>
          <Tab>Add Printer</Tab>
        </TabList>
        <TabPanel>
          <ViewPrinters />
        </TabPanel>
        <TabPanel>
          <AddPrinter />
        </TabPanel>
      </Tabs>
    )}
    path={props.location.pathname}
    title="Printer Portal"
  />
);

PrinterPortal.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default PrinterPortal;
