{
    "lastA11yAudit": "",
    "name": "Chip",
    "docs": "#/components/Chip",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?type=design&node-id=81232%3A20812&mode=design&t=xFi2RFJaHh9iwLQy-1",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Chip",
    "tags": ["chip", "filter", "tags", "badge", "flag"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
