/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
import { Loading } from '@nike/frame-component-library';
import { Default, isNone, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import { checkIngestStatus, ingestReplay } from '../../utils/service-calls/transaction-analyzer-v2';
import { rosPermissions } from '../../utils/tab-permissions';
import { PageTemplate } from '../reusable';

import CheckIngestStatus from './CheckIngestStatus';
import ViewIngestReplayResults from './ViewIngestReplayResults';
import ViewIngestStatusDetails from './ViewIngestStatusDetails';
import ViewIngestStatusResults from './ViewIngestStatusResults';

const TransactionAnalyzer = ({ location }) => {
  const accessToken = useSelector((state) => state?.authorizationReducer?.auth?.accessToken ?? null);
  const [navigation, setNavigation] = useState('check-ingest-status');
  const [isLoading, setLoading] = useState(false);
  const [queryType, setQueryType] = useState(null);
  const [transactionIds, setTransactionIds] = useState([]);
  const [isCheckingIngestStatus, setCheckingIngestStatus] = useState(false);
  const [region, setRegion] = useState(null);
  const [ingestStatusResults, setIngestStatusResults] = useState([]);
  const [transactionIdsToReplay, setTransactionIdsToReplay] = useState([]);
  const [transactionReplayResults, setTransactionReplayResults] = useState([]);
  const [ingestStatusDetails, setIngestStatusDetails] = useState(null);
  const [isReplayingTransactions, setReplayingTransactions] = useState(false);
  const [checkIngestStatusError, setCheckIngestStatusError] = useState('');
  const [replayTransactionsError, setReplayTransactionsError] = useState('');
  const createBlankQueryId = (theQueryType = queryType) => (theQueryType === 'FOUR_PART_ID'
    ? {
      businessDate: '',
      storeNumber: '',
      transactionNumber: '',
      workstationNumber: '',
    }
    : '');

  useEffect(() => {
    if (isNone(accessToken) || isCheckingIngestStatus !== true) { return; }
    setCheckingIngestStatus(false);
    setLoading(true);
    setIngestStatusResults([]);
    setTransactionIdsToReplay([]);
    setCheckIngestStatusError('');
    setReplayTransactionsError('');
    checkIngestStatus(accessToken, queryType, transactionIds, region)
      .then((response) => {
        setIngestStatusResults(response?.body?.objects ?? []);
        setNavigation('view-ingest-status-results');
      })
      .catch((err) => setCheckIngestStatusError(`An error occurred - please try again (${err.message}).`))
      .finally(() => {
        setLoading(false);
      });
  }, [isCheckingIngestStatus, accessToken, ingestStatusResults, queryType, region, transactionIds]);

  useEffect(() => {
    if (isNone(accessToken) || isReplayingTransactions !== true) { return; }
    setReplayingTransactions(false);
    setLoading(true);
    setTransactionReplayResults(null);
    setReplayTransactionsError('');
    ingestReplay(accessToken, transactionIdsToReplay, region)
      .then((response) => {
        setTransactionReplayResults(response?.body ?? {});
        setTransactionIdsToReplay([]);
        setNavigation('view-ingest-replay-results');
      })
      .catch((err) => setReplayTransactionsError(`An error occurred - please try again (${err.message}).`))
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, isReplayingTransactions, region, transactionIdsToReplay]);

  const MAX_ROWS = 10;

  const handleQueryTypeChange = (newQueryType) => {
    const newQueryIds = match(newQueryType)(
      ['FOUR_PART_ID', () => [createBlankQueryId(newQueryType)]],
      ['ORDER_ID', () => [createBlankQueryId(newQueryType)]],
      ['TRANSACTION_ID', () => [createBlankQueryId(newQueryType)]],
      [Default, () => []],
    );
    setTransactionIds(newQueryIds);
    setQueryType(newQueryType);
  };

  const handleRegionChange = (newRegion) => {
    handleQueryTypeChange(null);
    setRegion(newRegion);
  };

  const renderCheckIngestStatus = () => (
    <CheckIngestStatus
      checkIngestStatusError={checkIngestStatusError}
      createBlankQueryId={createBlankQueryId}
      handleQueryTypeChange={handleQueryTypeChange}
      handleRegionChange={handleRegionChange}
      maxRows={MAX_ROWS}
      queryType={queryType}
      region={region}
      setCheckingIngestStatus={setCheckingIngestStatus}
      setTransactionIds={setTransactionIds}
      transactionIds={transactionIds}
    />
  );

  const renderViewIngestStatusDetails = () => (
    <ViewIngestStatusDetails
      ingestStatusDetails={ingestStatusDetails}
      setNavigation={setNavigation}
    />
  );

  const renderViewIngestReplayResults = () => (
    <ViewIngestReplayResults
      setNavigation={setNavigation}
      transactionReplayResults={transactionReplayResults}
    />
  );

  const renderViewIngestStatusResults = () => (
    <ViewIngestStatusResults
      ingestStatusResults={ingestStatusResults}
      replayTransactionsError={replayTransactionsError}
      setIngestStatusDetails={setIngestStatusDetails}
      setNavigation={setNavigation}
      setReplayingTransactions={setReplayingTransactions}
      setTransactionIdsToReplay={setTransactionIdsToReplay}
      transactionIds={transactionIds}
      transactionIdsToReplay={transactionIdsToReplay}
    />
  );

  return isLoading
    ? (<Loading />)
    : (
      <PageTemplate
        auth={rosPermissions}
        description="Analyze and reprocess transactions in the Data Ingest Service"
        help={(
          <a
            className="ncss-cta-primary-dark underline text-color-secondary"
            href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Transaction+Analyzer+UI"
            rel="noopener noreferrer"
            target="_blank"
          >
            Click here to view the KB
          </a>
    )}
        page={match(navigation)(
          ['check-ingest-status', renderCheckIngestStatus],
          ['view-ingest-status-details', renderViewIngestStatusDetails],
          ['view-ingest-replay-results', renderViewIngestReplayResults],
          ['view-ingest-status-results', renderViewIngestStatusResults],
        )}
        path={location.pathname}
        title="Transaction Analyzer"
      />
    );
};
TransactionAnalyzer.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};
export default TransactionAnalyzer;
