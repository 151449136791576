{
    "lastA11yAudit": "07/08/22",
    "name": "Button",
    "description": "Button is how users interact, take action, and navigate.",
    "docs": "#/components/buttons/Button",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=48892%3A347638",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Button",
    "tags": [
        "Button",
        "CTA",
        "Link",
        "anchor",
        "Call to Action",
        "submit",
        "reset"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
