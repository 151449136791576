import { Card, Icon } from '@nike/eds';
import { Edit } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const HierarchyNode = ({
  hierarchyNode, isEditable, isDeletable, onClick, isSelected, getHierarchyName, onEdit, getHierarchyCopyPasteValue
}) => {
  const userCanEdit = isSelected && (isEditable || isDeletable);
  const [copied, setCopied] = useState(false);

  const resetCopied = () => {
    setTimeout(() => {
      setCopied(false);
    }, 200);
  };
  return (
    <Card
      className={isSelected ? 'hierarchy-node-selected' : 'hierarchy-node-default'}
      onClick={onClick}
    >
      <div className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
        <div className="eds-type--subtitle-2">{getHierarchyName(hierarchyNode)}</div>
        <div className="eds-flex eds-flex--direction-row">
          {isSelected && (
            <Icon
              name={copied ? 'CopyPasteFilled' : 'CopyPaste'}
              size="m"
              title="Copy"
              onClick={() => {
                if (!copied) {
                  // eslint-disable-next-line no-unused-expressions
                  navigator?.clipboard?.writeText(getHierarchyCopyPasteValue(hierarchyNode));
                  setCopied(true);
                  resetCopied();
                }
              }}
            />
          )}
          {userCanEdit && (<Edit size="s" title="Edit" onClick={onEdit} />)}
        </div>
      </div>
    </Card>
  );
};

HierarchyNode.propTypes = {
  getHierarchyCopyPasteValue: PropTypes.func,
  getHierarchyName: PropTypes.func,
  hierarchyNode: PropTypes.shape().isRequired,
  isDeletable: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
};

HierarchyNode.defaultProps = {
  getHierarchyCopyPasteValue: ({ name }) => name,
  getHierarchyName: ({ name }) => name,
  onEdit: () => {},
};

export default HierarchyNode;
