import { Checkbox, Loading } from '@nike/frame-component-library';
import {
 ModalActionsStyled, ModalStyled, NikeDesignSystemProvider, Button
} from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { cmpCountries } from '../../../utils/static/sls-property-values';
import { ButtonBlack, ButtonSubmit, TableRowClick } from '../../reusable';
import Offerings from '../stores/offerings';

import DevOpsTools from './DevOpsTools';
import FacilityListDownload from './FacilityListDownload';
import Filters from './Filters';
import Links from './Links';

const currentDate = new Date();
const firstFreeze = currentDate >= new Date('2023-04-01') && currentDate <= new Date('2023-06-22');
const secondFreeze = currentDate >= new Date('2023-06-23') && currentDate <= new Date('2023-07-04');
const firstFreezeText = 'There is currently a freeze on creating and editing new US & Puerto Rico stores as we transition those activities to the new Customer Management Platform. Please contact Ed Thierry [ed.thierry@nike.com] for support in this activity.';
const secondFreezeText = 'There is currently a freeze on creating and editing new US & Puerto Rico stores as we transition those activities to the new Customer Management Platform. For more information, see timeline here [https://nike.box.com/s/5e87gccqn3jbxwyj1ucpy7pq0ve2mggi] or contact Mike Rose [mike.rose@nike.com].';
const storeCreatePromptTitle = 'Notice!';
const storeCreatePromptText = 'Are you trying to add a new store in the US or Puerto Rico?';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doNotShowStoreCreateNoticeModalAgain: false,
      showStoreCreateNoticeModal: false,
      showStoreCreatePrompt: true,
      storeCreateNoticeModalDisplayText: ' ',
      storeCreateNoticeModalMessageTitle: '',
    };
  }

  Actions = () => (
    <ModalActionsStyled>
      <div className="d-md-flx flx-dir-md-rr">
        {this.state.showStoreCreatePrompt
            && !this.refuseStoreCreateNoticeModal() ? (
              <>
                <Button
                  className="ncss-btn-primary-dark bg mt6-sm ml2-sm border"
                  onClick={() => {
                    this.setState({
                      storeCreateNoticeModalMessageTitle: 'Stop Right There!',
                    });
                    if (firstFreeze) {
                      this.setState({
                        storeCreateNoticeModalDisplayText: firstFreezeText,
                      });
                    } else if (secondFreeze) {
                      this.setState({
                        storeCreateNoticeModalDisplayText: secondFreezeText,
                      });
                    }
                    this.setState({ showStoreCreatePrompt: false });
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="ncss-btn-primary-dark bg mt6-sm ml2-sm border"
                  onClick={() => {
                    this.setState({ showStoreCreateNoticeModal: false });
                    this.setState({ showStoreCreatePrompt: true });
                    this.props.closeOrOpenForm();
                  }}
                >
                  No
                </Button>
              </>
            ) : (
              <>
                <Checkbox
                  id="refuseStoreCreateWarning"
                  label="Do not show again"
                  onChange={() => this.setState((prev) => ({
                      doNotShowStoreCreateNoticeModalAgain:
                        !prev.doNotShowStoreCreateNoticeModalAgain,
                    }))}
                />
                <Button
                  className="ncss-btn-primary-dark bg mt6-sm ml2-sm border"
                  onClick={() => {
                    if (firstFreeze) {
                      this.setState({
                        storeCreateNoticeModalDisplayText: firstFreezeText,
                      });
                      if (this.state.doNotShowStoreCreateNoticeModalAgain) {
                        localStorage.setItem(
                          'doNotShowStoreCreateNoticeModalFirstFreeze',
                          'true'
                        );
                      }
                    } else if (secondFreeze) {
                      this.setState({
                        storeCreateNoticeModalDisplayText: secondFreezeText,
                      });
                      if (this.state.doNotShowStoreCreateNoticeModalAgain) {
                        localStorage.setItem(
                          'doNotShowStoreCreateNoticeModalSecondFreeze',
                          'true'
                        );
                      }
                    }
                    this.setState({
                      storeCreateNoticeModalMessageTitle: storeCreatePromptTitle,
                    });
                    this.setState({ showStoreCreatePrompt: true });
                    this.setState({ showStoreCreateNoticeModal: false });
                  }}
                >
                  Got It!
                </Button>
              </>
            )}
      </div>
    </ModalActionsStyled>
      );

// eslint-disable-next-line react/sort-comp
  facilityType = this.props.facilityType.substring(0, this.props.facilityType.length - 1);

  prepareStoreCreatePrompt = () => {
    this.setState(
      {
        storeCreateNoticeModalDisplayText: storeCreatePromptText,
        storeCreateNoticeModalMessageTitle: storeCreatePromptTitle,
      },
    );
  };

  refuseStoreCreateNoticeModal = () => (firstFreeze && localStorage.getItem('doNotShowStoreCreateNoticeModalFirstFreeze'))
      || (secondFreeze && localStorage.getItem('doNotShowStoreCreateNoticeModalSecondFreeze'));

  render = () => (this.props.getting || this.props.posting || this.props.putting
    ? (
      <aside className="ncss-col-sm-12">
        <h1 className="headline-1 ta-sm-c">Retrieving {this.props.facilityType}</h1>
        <Loading />
      </aside>
    )
    : (
      <main className="ncss-row ta-sm-l">
        <Links facilityType={this.props.facilityType} />
        <section className="ncss-row ta-sm-c">
          {(this.props.postSuccess || this.props.putSuccess) && (
            <aside className="ncss-col-sm-12 mt8-sm text-color-success">
              {[this.props.postSuccess, this.props.putSuccess].filter((success) => success !== '')
                .map((success) => <p key={success}><i className="mr3-sm g72-check-circle" />{success}</p>)}
            </aside>
          )}
          {(this.props.getError || this.props.postError || this.props.putError) && (
            <aside className="ncss-col-sm-12 mt8-sm text-color-error">
              {[this.props.getError, this.props.postError, this.props.putError].filter((error) => error !== '')
                .map((error) => <p key={error}><i className="mr3-sm g72-x-circle" />{error}</p>)}
            </aside>
          )}
        </section>
        {this.props.getError && this.props.formOpen && (
          <ButtonSubmit
            className="ncss-col-sm-4 va-sm-t"
            label={`Back to ${this.props.facilityType}`}
            // these () => this.props.closeOrOpenForm()s are neccessary; without it formData is defaulted with some weirdness
            onClick={() => this.props.closeOrOpenForm()}
          />
        )}
        {!this.props.getError && (
          <article className="ncss-row mt8-sm">
            {this.props.formOpen
              && (
                <section className="ncss-row pb8-sm">
                  <ButtonSubmit
                    className="ncss-col-sm-4"
                    label={`Back to ${this.props.facilityType}`}
                    // these () => this.props.closeOrOpenForm()s are neccessary; without it formData is defaulted with some weirdness
                    onClick={() => this.props.closeOrOpenForm()}
                  />
                  <article className="ncss-col-sm-12 pt8-sm pb8-sm prl4-sm">
                    {this.props.form}
                  </article>
                </section>
              )}
            {!this.props.formOpen && !this.props.offeringFormOpen && (
            // the form and offering form is closed, so we are viewing the facilities
            <section className="ncss-row ta-sm-c">
              <article className={`ncss-col-sm-${this.props.facilityType === 'Stores' ? '4' : '6'} ta-sm-l va-sm-t`}>
                <ButtonBlack
                  label={`Add ${this.facilityType}`}
                  onClick={() => {
                    if (this.props.facilityType === 'Stores'
                        && !this.refuseStoreCreateNoticeModal()
                        && (firstFreeze || secondFreeze)
                        && cmpCountries.length > 0) {
                      this.prepareStoreCreatePrompt();
                      this.setState({ showStoreCreateNoticeModal: true });
                    } else {
                      this.props.closeOrOpenForm();
                    }
                  }}
                />
                <NikeDesignSystemProvider>
                  <ModalStyled
                    closeModal={() => {
                      if (this.state.doNotShowStoreCreateNoticeModalAgain) {
                        if (firstFreeze) {
                          localStorage.setItem('doNotShowStoreCreateNoticeModalFirstFreeze', 'true');
                        } else if (secondFreeze) {
                          localStorage.setItem('doNotShowStoreCreatedNoticeSecondFreeze', 'true');
                        }
                      } else {
                        this.setState({ showStoreCreatePrompt: true });
                      }

                      this.setState({ showStoreCreateNoticeModal: false });
                    }}
                    isOpen={this.state.showStoreCreateNoticeModal}
                    slots={{ FooterSlot: <this.Actions /> }}
                    title={this.state.storeCreateNoticeModalMessageTitle}
                  >
                    <p>{this.state.storeCreateNoticeModalDisplayText}</p>
                  </ModalStyled>
                  <div id="modal-root" />
                </NikeDesignSystemProvider>
              </article>
              {this.props.facilityType === 'Stores'
              // Only display on the stores page
                && (
                  <article className="ncss-col-sm-4 ta-sm-c va-sm-t">
                    <ButtonBlack
                      label="Offerings"
                      onClick={() => this.props.closeOrOpenOfferingForm()}
                    />
                  </article>
                )}
              <article className={`ncss-col-sm-${this.props.facilityType === 'Stores' ? '4' : '6'} ta-sm-r va-sm-t`}>
                <FacilityListDownload
                  facilities={this.props.facilities}
                  facilitiesAll={this.props.facilitiesAll}
                  facilityType={this.props.facilityType}
                  region={this.props.region.label}
                  userGroups={this.props.userGroups}
                  userToken={this.props.userToken}
                />
              </article>
              <section className="mt8-sm ta-sm-l">
                <Filters
                  changeSearch={this.props.changeSearch}
                  clearFilters={this.props.clearFilters}
                  facilities={this.props.facilities}
                  facilityType={this.props.facilityType}
                  filters={this.props.filters}
                  postSuccess={this.props.postSuccess}
                  search={this.props.search}
                  userGroups={this.props.userGroups}
                />
                <TableRowClick
                  showPagination
                  columns={this.props.columns}
                  data={this.props.facilities}
                  defaultPageSize={20}
                  fetching={this.props.getting}
                />
              </section>
              {this.facilityType === 'Store' && this.props.userIsOwner && (
              <DevOpsTools
                facilities={this.props.facilitiesAll}
                userEmail={this.props.userEmail}
                userToken={this.props.userToken}
              />
              )}
            </section>
            )}
            {this.props.offeringFormOpen && (
              <Offerings
                closeOrOpenOfferingForm={this.props.closeOrOpenOfferingForm}
                userToken={this.props.userToken}
                username={this.props.username}
              />
            )}
          </article>
        )}
      </main>
    )
  )
}

Main.defaultProps = {
  form: <section />,
  formOpen: false,
  offeringFormOpen: false,
  userIsOwner: false,
  username: '',
  userToken: '',
};

Main.propTypes = {
  changeSearch: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  closeOrOpenForm: PropTypes.func.isRequired,
  closeOrOpenOfferingForm: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.func,
    header: PropTypes.string,
    path: PropTypes.string,
    style: PropTypes.shape(),
    width: PropTypes.number,
  })).isRequired,
  country: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  facilitiesAll: PropTypes.arrayOf(PropTypes.shape).isRequired,
  facilityType: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    changeValue: PropTypes.func,
    getLabel: PropTypes.func,
    id: PropTypes.string,
    option: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    }),
  })).isRequired,
  form: PropTypes.element,
  formOpen: PropTypes.bool,
  getError: PropTypes.string.isRequired,
  getting: PropTypes.bool.isRequired,
  offeringFormOpen: PropTypes.bool,
  postError: PropTypes.string.isRequired,
  posting: PropTypes.bool.isRequired,
  postSuccess: PropTypes.string.isRequired,
  putError: PropTypes.string.isRequired,
  putSuccess: PropTypes.string.isRequired,
  putting: PropTypes.bool.isRequired,
  region: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  search: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  userIsOwner: PropTypes.bool,
  username: PropTypes.string,
  userToken: PropTypes.string,
};

export default Main;
