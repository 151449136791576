import PropTypes from 'prop-types';
import React from 'react';

import { currencyCodeValues } from '../../../../utils/static/sls-property-values';
import { CustomPanel, SelectMultiple } from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!properties.formErrors.currencies);

class Localization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Localization"
      onClick={this.panelToggle}
    >
      <SelectMultiple
        errorMessage={this.props.formErrors.currencies}
        id="currencies"
        isDisabled={this.props.userIsReadOnly}
        label="Currencies"
        options={currencyCodeValues.map((value) => ({ label: value, value }))}
        values={this.props.formData.currencies ? this.props.formData.currencies.map((currency) => ({ label: currency, value: currency })) : []}
        zIndex={20}
        onChange={(values) => this.props.updateFormData('currencies', values.map((value) => value.value))}
      />
    </CustomPanel>
  );
}

Localization.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Localization;
