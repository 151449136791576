import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { handleGtin, handleStyleColor } from '../../utils/formatting';
import { postGtins, postStyleColors } from '../../utils/service-calls/patch';
import { patchPermissions } from '../../utils/tab-permissions';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonWhite, ButtonSubmit, PageTemplate, StoreSelect,
} from '../reusable';

import PatchTabs from './patch-tabs';

const REQUIRED_FIELDS = 7;
const REQUIRED_LINES = 3;

class Patch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      couponDiscountEnabled: 'true',
      disable: true,
      employeeDiscountCode: '3',
      enteringStyleColors: true,
      error: '',
      fileResponse: '',
      fileUploaded: false,
      formErrors: {
        codes: REQUIRED_FIELD,
        selectedCountry: '',
      },
      itemDiscountCode: '3',
      postingRecords: false,
      priceOverrideEnabled: 'true',
      promotionEnabled: 'true',
      requestResponse: '',
      results: null,
      // ignore user's current location and use china because patch is always for china
      selectedCountry: { label: 'China', value: 'CHN' },
      thresholdDiscountCode: '3',
      transactionDiscountCode: '3',
      uploadingFile: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  clearForm = (requestResponse = '') => {
    const file = document.getElementById('patchFile');
    if (file !== null) {
      file.value = '';
    }
    this.setState({
      codes: [],
      couponDiscountEnabled: 'true',
      disable: true,
      employeeDiscountCode: '3',
      fileResponse: '',
      fileUploaded: false,
      itemDiscountCode: '3',
      postingRecords: false,
      priceOverrideEnabled: 'true',
      promotionEnabled: 'true',
      requestResponse,
      selectedCountry: { label: 'China', value: 'CHN' },
      thresholdDiscountCode: '3',
      transactionDiscountCode: '3',
    });
  };

  formSubmit = () => {
    // turn on loading indicator
    this.setState({ postingRecords: true, results: null });
    // format codes
    const submitCodes = this.state.codes.map((code) => code.value).toString();
    const codeNames = [
      'promotionEnabled',
      'priceOverrideEnabled',
      'couponDiscountEnabled',
      'itemDiscountCode',
      'transactionDiscountCode',
      'employeeDiscountCode',
      'thresholdDiscountCode',
    ];
    // set up body
    const body = {};
    codeNames.forEach((code) => {
      body[code] = this.state[code];
    });
    // set up request and call it
    const requestFunc = this.state.enteringStyleColors ? postStyleColors : postGtins;
    requestFunc(this.props.accessToken, submitCodes, body, this.state.selectedCountry.value)
      .then((res) => {
        // set up response to request
        const dataArray = Object.values(res.body);
        this.setState({ error: '', results: dataArray });
        const errors = dataArray.map((result) => (result.statusCode === 200));
        let message = '';
        if (errors.includes(false)) {
          errors.forEach((error, i) => {
            message = `${message}Code ${i} failed to update. `;
          });
        } else {
          message = 'Successful update!';
        }
        return this.clearForm(message);
      })
      .catch((err) => {
        this.setState({ error: err.message });
        this.clearForm();
      });
  };

  handleCodeChange = (codes) => this.setState({ codes: codes || [], disable: false, results: null });

  handleEntryChange = (enteringStyleColors) => this.setState({ codes: [], enteringStyleColors, results: null });

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (results) => {
        if (this.validateFile(results.data)) {
          const [codeNames, formResults] = results.data;
          codeNames.forEach((code, i) => this.setState({ [code]: formResults[i] }));
          this.setState({ fileUploaded: true, requestResponse: '', results: null });
        }
      },
    });
  };

  handleFormChange = (id, value) => this.setState({ [id]: value, results: null });

  handleUploadTypeChange = (uploadingFile) => this.setState({ codes: [], results: null, uploadingFile });

  separateCodes = (currentCodes, commaCodes = '') => {
    // get rid of repeats in batch entry
    const seen = {};
    const lastIndex = currentCodes.length - 1;
    currentCodes.forEach((el, i) => {
      // want to ignore the codes we're adding
      if (i !== lastIndex) {
        seen[el.value] = true;
      }
    });
    // allow multiple entries separated by a comma
    const batch = commaCodes.split(',').filter((item) => {
      if (seen[item] === undefined) {
        seen[item] = true;
        return true;
      } else {
        return false;
      }
    });
    // returns an array of codes
    return batch;
  };

  validateFile = (data) => {
    const validation = [];
    const [fileCodeNames, fileCodeValues, fileCodes] = data;
    const codeNamesBool = ['promotionEnabled', 'priceOverrideEnabled', 'couponDiscountEnabled'];
    const codeNames0or3 = ['itemDiscountCode', 'transactionDiscountCode', 'employeeDiscountCode', 'thresholdDiscountCode'];
    if (data.length !== REQUIRED_LINES) {
      validation.push('Your csv has too many lines. It should only have three: one with properties, one with property values, and one with codes.');
    }
    if (fileCodeNames.length !== REQUIRED_FIELDS || fileCodeValues.length !== REQUIRED_FIELDS) {
      validation.push('You should have exactly seven properties and seven values.');
    }
    const batch = this.separateCodes(this.state.codes, fileCodes[1]);
    if (!batch.every((el) => (this.state.enteringStyleColors ? handleStyleColor(el) : handleGtin(el)))) {
      validation.push('Your codes were entered incorrectly. Check you are using the right tab for your code type (Style Color or Gtin) and make sure that you are following the specified format for this code type.');
      this.setState({ disable: true });
    } else {
      // update codes
      this.setState({ codes: batch.map((val) => ({ label: val, value: val })), disable: false });
    }
    fileCodeNames.forEach((element, i) => {
      if (codeNamesBool.includes(element) && (fileCodeValues[i].toLowerCase() !== 'true') && (fileCodeValues[i].toLowerCase() !== 'false')) {
        validation.push(`Property ${i + 1}'s value is incorrect: enabled properties should be "true" or "false".`);
      }
      if (codeNames0or3.includes(element) && (fileCodeValues[i] !== '0') && (fileCodeValues[i] !== '3')) {
        validation.push(`Property ${i + 1}'s value is incorrect: discount code properties should be "0" (Non-Discountable) or "3" (Discountable).`);
      }
      if (!(codeNamesBool.includes(element)) && !(codeNames0or3.includes(element))) {
        validation.push(`Property ${i + 1} is incorrect: should be "promotionEnabled", "priceOverrideEnabled", "couponDiscountEnabled", "itemDiscountCode", "transactionDiscountCode", "employeeDiscountCode", or "thresholdDiscountCode".`);
      }
    });
    if (validation.length !== 0) {
      this.setState({ fileResponse: `${validation.join(' ALSO ')}` });
      return false;
    }
    this.setState({ fileResponse: '' });
    return true;
  };

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    if (!this.state.codes.length) {
      formErrors.codes = REQUIRED_FIELD;
    }
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <PageTemplate
      auth={patchPermissions}
      description="Enable and disable product promotions and discount attributes."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Patch+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-8">
          <article className="ncss-col-sm-12 pb4-sm">
            <StoreSelect
              isRequired
              noStores
              formErrors={this.state.formErrors}
              selectCountry={(selectedCountry) => this.setState({ selectedCountry })}
              selectStore={() => { }}
              selectedCountry={this.state.selectedCountry}
            />
          </article>
          <article className="ncss-col-sm-12 ta-sm-c">
            <PatchTabs
              codes={this.state.codes}
              couponDiscountEnabled={this.state.couponDiscountEnabled}
              employeeDiscountCode={this.state.employeeDiscountCode}
              formErrors={this.state.formErrors}
              handleCodeChange={this.handleCodeChange}
              handleEntryChange={this.handleEntryChange}
              handleFileUpload={this.handleFileUpload}
              handleFormChange={this.handleFormChange}
              handleUploadTypeChange={this.handleUploadTypeChange}
              itemDiscountCode={this.state.itemDiscountCode}
              priceOverrideEnabled={this.state.priceOverrideEnabled}
              promotionEnabled={this.state.promotionEnabled}
              thresholdDiscountCode={this.state.thresholdDiscountCode}
              transactionDiscountCode={this.state.transactionDiscountCode}
            />
          </article>
          <p className="text-color-accent">{this.state.fileResponse}</p>
          <section className="ncss-row">
            <ButtonSubmit
              className="ncss-col-sm-3 va-sm-t"
              isDisabled={this.state.disable || (this.state.uploadingFile && !this.state.fileUploaded) || !this.state.selectedCountry}
              isLoading={this.state.postingRecords}
              submitError={this.state.error}
              onClick={this.formSubmit}
            />
            <article className="ncss-col-sm-3 va-sm-t">
              <ButtonWhite label="Clear" onClick={this.clearForm} />
            </article>
          </section>
          <article className="ncss-col-sm-8 mt4-sm mb4-sm">
            {this.state.results && !this.state.error && (
              <ReactTable
                className="-striped -highlight"
                columns={[
                  { accessor: 'code', Header: 'Code', headerStyle: { background: 'black', color: 'white', fontSize: '20px' } },
                  { accessor: 'message', Header: 'Message', headerStyle: { background: 'black', color: 'white', fontSize: '20px' } },
                ]}
                data={this.state.results}
                getTdProps={() => ({ style: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } })}
                getTrProps={(state, rowInfo) => ({ style: { color: rowInfo.original.error ? 'red' : 'black' } })}
                pageSize={this.state.results.length}
                showPagination={false}
              />
            )}
          </article>
        </main>
      )}
      path={this.props.location.pathname}
      title="Patch"
    />
  );
}

Patch.defaultProps = {
  accessToken: undefined,
};

Patch.propTypes = {
  accessToken: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(Patch);
