import { Loading, Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getOktaToken } from '../../../../utils/service-calls/sls';
import { loginClassificationValues, defaultNFSImageUrl, defaultNSPImageUrl } from '../../../../utils/static/sls-property-values';
import {
  ButtonBlack, CustomPanel, Upload, Select,
} from '../../../reusable';
import ToolTip from '../../../reusable/ToolTip/ToolTip';

import { calculateSlug } from './slug';

// Store image size
const MIN_IMAGE_HEIGHT = 700;
const MIN_IMAGE_WIDTH = 1344;

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!properties.formErrors.imageUrl
  || !!properties.formErrors.costCenter
);

class Other extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
      isUploading: false,
      rosToken: '',
      uploadComponentError: '',
      uploadError: '',
      uploadSuccess: false,
    };
  }

  componentDidMount() {
    getOktaToken(this.props.accessToken)
      .then(({ body }) => this.setState({ rosToken: body }))
      .catch(({ message }) => this.setState({ uploadComponentError: message }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  updateGenericObject = (genericObjectName, genericObjectData, childPropName, childData) => {
    let genericObject = { ...genericObjectData };
    if (childData && typeof childData === 'object') {
      if (Object.values(childData).every((value) => (!value))) {
        // eslint-disable-next-line no-param-reassign
        childData = {};
      }
    }
    if (!childData || (typeof childData === 'object' && Object.keys(childData)?.length === 0)) {
      delete genericObject[childPropName];
      if (Object.keys(genericObject).length === 0) {
        // do this so that the empty object is deleted when we call updateFormData
        genericObject = '';
      }
    } else {
      genericObject[childPropName] = childData;
    }
    this.props.updateFormData(genericObjectName, genericObject);
  };

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Other"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly}
          label="Terms of Service Link"
          value={this.props.formData.termsOfServiceUrl || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('termsOfServiceUrl', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly}
          label="Twitter Handle"
          value={this.props.formData.twitterHandle || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('twitterHandle', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly}
          label="HR Location ID"
          value={this.props.formData.alternateIds?.hrLocationId || ''}
          onChange={({ target: { value } }) => this.updateGenericObject('alternateIds', this.props.formData.alternateIds, 'hrLocationId', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={!this.props.disableCmpFields && this.props.formErrors.costCenter}
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Cost Center"
          value={this.props.formData.costCenter || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('costCenter', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Ship To"
          value={this.props.formData.shipTo || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('shipTo', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Sold To"
          value={this.props.formData.soldTo || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('soldTo', value)}
        />
      </article>
      <ToolTip left="775px">
        <p><b>Warning: </b>Changing the Login Classification can have a direct impact on store login.</p>
      </ToolTip>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.slug}
          isDisabled={this.props.userIsReadOnly}
          label="Slug"
          value={this.props.formData.slug || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('slug', value)}
        />
        <ButtonBlack
          className="mt2-sm"
          isDisabled={this.props.userIsReadOnly}
          label="Calculate Slug"
          onClick={() => this.props.updateFormData('slug', calculateSlug(this.props.formData.name))}
        />
      </article>
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.loginClassification}
        id="Login Classification"
        isDisabled={this.props.userIsReadOnly}
        label="Login Classification"
        options={loginClassificationValues.map((val) => ({ label: val, value: val }))}
        value={this.props.formData.loginClassification || ''}
        zIndex={1}
        onChange={(value) => this.props.updateFormData('loginClassification', value)}
      />
      <br />
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.imageUrl}
          isDisabled={this.props.userIsReadOnly}
          label="Image Url"
          value={this.props.formData.imageUrl || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('imageUrl', value)}
        />
        <ButtonBlack
          className="mt2-sm"
          isDisabled={this.props.userIsReadOnly}
          label="Use Default Image"
          onClick={() => {
            this.props.updateFormData(
              'imageUrl',
              this.props.formData.facilityType === 'NIKE_OWNED_STORE' && ['FACTORY', 'CLEARANCE', 'COMMUNITY']
                .includes(this.props.formData.businessConcept)
                ? defaultNFSImageUrl
                : defaultNSPImageUrl,
            );
            this.setState({ uploadError: '', uploadSuccess: false });
          }}
        />
      </article>
      <article className="ncss-col-sm-6 ta-sm-c va-sm-t mt7-sm">
        {this.state.rosToken && (
          <section className="border pt2-sm pb1-sm">
            <Upload
              minimumHeight={MIN_IMAGE_HEIGHT}
              minimumWidth={MIN_IMAGE_WIDTH}
              setImageError={(error) => this.setState({ uploadComponentError: error, uploadSuccess: false })}
              setImageUrl={(url) => {
                this.setState({ uploadSuccess: true });
                this.props.updateFormData('imageUrl', url);
              }}
              setIsUploading={(isUploading) => this.setState({ isUploading })}
              token={this.state.rosToken}
              username={this.props.username}
            />
          </section>
        )}
        {this.state.uploadComponentError && (
          <section className="border p4-sm text-color-error body-4">
            Error loading image file dropzone: {this.state.uploadComponentError}
          </section>
        )}
        {this.state.isUploading && <Loading />}
        {this.state.uploadError && <p className="text-color-error body-4 ta-sm-c">{this.state.uploadError}</p>}
        {this.state.uploadSuccess && (
          <p className="text-color-success body-4 ta-sm-c">
            Successfully uploaded a new image, the Image Url field has been updated to direct to the new image. Don&apos;t forget to Submit your change at the bottom of the page.
          </p>
        )}
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled
          errorMessage=""
          label="Creation Date"
          value={this.props.formData.creationDate || ''}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled
          errorMessage=""
          label="Created By"
          value={this.props.formData.createdBy || ''}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled
          errorMessage=""
          label="Last Update Date"
          value={this.props.formData.modificationDate || ''}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled
          errorMessage=""
          label="Last Updated By"
          value={this.props.formData.lastUpdatedBy || ''}
        />
      </article>

    </CustomPanel>
  );
}

Other.propTypes = {
  accessToken: PropTypes.string.isRequired,
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
  username: state.authorizationReducer.auth.username,
});

export default connect(mapStateToProps)(Other);
