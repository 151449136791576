import { requestWrapper } from './reusable';

const config = require('../../config')();

export const sniPost = (token, body) => requestWrapper(
  token,
  config.sniTransactionSearchSNIForwarderResource,
  'post',
  undefined,
  body,
);

export const forwarderAuditsGet = (token, storeId, transactionId, clientName, transactionDateAfterEq, transactionDateBeforeEq) => requestWrapper(
  token,
  config.sniTransactionSearchResource,
  'get',
  {
    clientName,
    storeId,
    transactionDateAfterEq,
    transactionDateBeforeEq,
    transactionId,
  },
);
