"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchWithToken = void 0;
const fetchWithToken = (url, _a) => __awaiter(void 0, void 0, void 0, function* () {
    var { headers, authToken } = _a, options = __rest(_a, ["headers", "authToken"]);
    const res = yield fetch(url, Object.assign(Object.assign({}, options), { headers: Object.assign(Object.assign({ Accept: 'application/json; charset=UTF-8' }, headers), { Authorization: `Bearer ${authToken}` }) }));
    const resJson = yield res.json();
    const response = {
        status: res.status,
        statusText: res.statusText,
    };
    // If status code is not 200-299
    if (!res.ok) {
        const errorResult = {
            data: resJson,
            response: Object.assign(Object.assign({}, response), { error: resJson }),
        };
        throw errorResult;
    }
    return {
        data: resJson,
        response,
    };
});
exports.fetchWithToken = fetchWithToken;
