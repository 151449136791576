import PropTypes from 'prop-types';
import React from 'react';

import { o2oPermissions } from '../../utils/tab-permissions';
import { PageTemplate } from '../reusable';

import OrderLookup from './components/OrderLookup';

const O2OOrderLookup = (props) => (
  <PageTemplate
    auth={o2oPermissions}
    description="Perform order info lookups for Online to Offline orders."
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+O2O+OrderLookup+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
    )}
    page={<OrderLookup history={props.history} pathIdentifier={props?.match?.params?.orderIdentifier} />}
    path={props.location.pathname}
    title="O2O Order Lookup"
  />
);

O2OOrderLookup.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ orderIdentifier: PropTypes.string }) }).isRequired,
};

export default O2OOrderLookup;
