import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../../reusable';

const exportExampleCSV = () => {
  const csv = document.createElement('a');
  csv.href = 'data:text/csv;charset=utf-8,'
    + `${encodeURI('promotionEnabled,priceOverrideEnabled,couponDiscountEnabled,itemDiscountCode,transactionDiscountCode,employeeDiscountCode,thresholdDiscountCode')}\r\n`
    + 'true,true,true,3,3,3,3\r\n'
    + 'codes,"{code},{code},{code}"';
  csv.target = '_blank';
  csv.download = 'patch.csv';
  csv.click();
};

const PatchCsv = (props) => (
  <article className="ncss-col-sm-8 va-sm-m ta-sm-c mb1-sm">
    <ButtonBlack className="" label="Download Template CSV" onClick={exportExampleCSV} />
    <section className="mt6-sm ta-sm-c mb12-sm">
      <Input
        accept=".csv"
        datatype="text"
        errorMessage={props.formErrors.codes}
        id="patchFile"
        label="File Upload"
        type="file"
        onChange={props.handleFileUpload}
      />
    </section>
  </article>
);

PatchCsv.propTypes = {
  formErrors: PropTypes.shape().isRequired,
  handleFileUpload: PropTypes.func.isRequired,
};

export default PatchCsv;
