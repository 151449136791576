{
    "lastA11yAudit": "07/08/22",
    "name": "Spinner",
    "description": "Spinner is a rotating icon that indicates something that is currently processing.",
    "docs": "#/components/loaders/Spinner",
    "figma": "https://www.figma.com/file/2vjvaz7dmwzJFaAszyJ9nC/FA22(MVP)?node-id=3525%3A4",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Spinner",
    "tags": ["spinner", "load", "loading", "loader"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
