import { any } from '../array';

export const validateDevices = (deviceInfo) => {
  deviceInfo.objects.forEach((device) => {
    const requiredKeys = ['id', 'storeId', 'name', 'type'];
    if (any((key) => device[key] == null, requiredKeys)) {
      throw new Error('Device data was invalid!');
    }
  });
  return deviceInfo.objects;
};

export const validateWaitlines = (waitlineInfo) => {
  waitlineInfo.list.forEach((waitline) => {
    const requiredKeys = ['id', 'alternateId', 'name', 'storeId'];
    if (any((key) => waitline[key] == null, requiredKeys)) {
      throw new Error('Waitline data was invalid!');
    }
  });
  return waitlineInfo.list;
};
