import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

const SelectMultiple = (props) => (
  <article className={`ncss-col-sm-${props.width} va-sm-t ta-sm-l`}>
    {props.label}
    <ReactSelect
      isMulti
      classNamePrefix="multi-select"
      id={props.id}
      isDisabled={props.isDisabled}
      options={props.options}
      placeholder={`Hit [enter] after each ${props.label}`}
      styles={{
        container: (styles) => ({ ...styles, zIndex: props.zIndex }),
        control: (styles) => ({
          ...styles, borderColor: props.errorMessage !== '' ? 'red' : 'lightgrey', padding: '0px 0px 0px 3px', zIndex: props.zIndex,
        }),
      }}
      value={props.values}
      onChange={(values) => props.onChange(values || [])}
    />
    {props.errorMessage && <p className="text-color-error body-4 mt2-sm">{props.errorMessage}</p>}
  </article>
);

SelectMultiple.defaultProps = {
  errorMessage: '',
  isDisabled: false,
  width: 6,
  zIndex: 100,
};

SelectMultiple.propTypes = {
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()])).isRequired,
  width: PropTypes.number,
  zIndex: PropTypes.number,
};

export default SelectMultiple;
