import { Cmd, loop } from 'redux-loop';

import {
  ZONES_FETCH, ZONES_FETCH_SUCCESS, ZONES_FETCH_FAILURE,
  zonesFetchSuccess, zonesFetchFailure,

  CREATE_ZONE, CREATE_ZONE_SUCCESS, CREATE_ZONE_FAILURE,
  zoneCreateSuccess, zoneCreateFailure,

  DELETE_ZONE, DELETE_ZONE_SUCCESS, DELETE_ZONE_FAILURE,
  zoneDeleteSuccess, zoneDeleteFailure,

  UPDATE_ZONE, UPDATE_ZONE_SUCCESS, UPDATE_ZONE_FAILURE,
  zoneUpdateSuccess, zoneUpdateFailure,

  SET_CURRENT_ZONE,

  LOCALIZATIONS_FETCH, LOCALIZATIONS_FETCH_SUCCESS, LOCALIZATIONS_FETCH_FAILURE,
  localizationsFetchSuccess, localizationsFetchFailure,

  LOCALIZATION_CREATE, LOCALIZATION_CREATE_SUCCESS, LOCALIZATION_CREATE_FAILURE,
  localizationCreateSuccess, localizationCreateFailure,

  LOCALIZATION_DELETE, LOCALIZATION_DELETE_SUCCESS, LOCALIZATION_DELETE_FAILURE,
  localizationDeleteSuccess, localizationDeleteFailure,

  LOCALIZATION_UPDATE, LOCALIZATION_UPDATE_SUCCESS, LOCALIZATION_UPDATE_FAILURE,
  localizationUpdateSuccess, localizationUpdateFailure,

  CLEAR_ZONES_RESULTS,
} from '../actions/zones';
import {
  deleteZone, getZones, postZone, putZone,
  deleteZoneLocalization, getZoneLocalizations, postZoneLocalization, putZoneLocalization,
} from '../utils/service-calls/zones';

const replaceZones = (initialZones, updatedZone) => {
  const currentZones = initialZones;
  const { id: updatedZoneId } = updatedZone;
  const updatedIndex = currentZones.findIndex((zone) => zone.id === updatedZoneId);

  if (updatedIndex < 0) {
    currentZones.push(updatedZone);
    return currentZones;
  }
  currentZones[updatedIndex] = updatedZone;

  return currentZones;
};

const initialState = {
  creatingLocalization: false,
  currentStore: {},
  deleting: false,
  deletingLocalization: false,
  fetchLocalizationsError: '',
  fetchLocalizationsRequest: false,
  fetchZonesError: '',
  fetchZonesRequest: null,
  localizationCreateUpdateError: '',
  localizationCreateUpdateSuccess: '',
  localizationDeleteError: '',
  localizationDeleteSuccess: '',
  localizationLanguage: '',
  localizations: [],
  posting: false,
  putting: false,
  requestSerial: 1,
  storeId: null,
  updatingLocalization: false,
  zoneError: '',
  zoneModificationDate: '',
  zones: [],
  zoneSuccess: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ZONES_FETCH: {
      if (action.storeId !== state.storeId || state.fetchZonesRequest === null) {
        return loop(
          {
            ...state,
            fetchZonesError: '',
            fetchZonesRequest: {
              requestNum: state.requestSerial,
              type: 'FETCH',
            },
            requestSerial: state.requestSerial + 1,
            storeId: action.storeId,
            zoneError: '',
            zoneSuccess: '',
          },
          Cmd.run(getZones, {
            args: [action.storeId, action.accessToken],
            failActionCreator: (error) => zonesFetchFailure(state.requestSerial, action.storeId, error),
            successActionCreator: (data) => zonesFetchSuccess(state.requestSerial, action.storeId, data.body.objects),
          }),
        );
      } else {
        return state;
      }
    }
    case ZONES_FETCH_SUCCESS: {
      if (state.fetchZonesRequest) {
        return {
          ...state,
          fetchZonesRequest: null,
          zones: action.zones,
        };
      } else {
        return state;
      }
    }
    case ZONES_FETCH_FAILURE: {
      if (state.fetchZonesRequest) {
        return {
          ...state,
          fetchZonesError: action.error.message,
          fetchZonesRequest: null,
        };
      } else {
        return state;
      }
    }
    case CREATE_ZONE: {
      return loop(
        {
          ...state,
          posting: true,
          requestSerial: state.requestSerial + 1,
        },
        Cmd.run(postZone, {
          args: [action.zone, action.accessToken],
          failActionCreator: (error) => zoneCreateFailure(state.requestSerial, error),
          successActionCreator: (data) => zoneCreateSuccess(state.requestSerial, data.body),
        }),
      );
    }
    case CREATE_ZONE_SUCCESS: {
      if (state.posting) {
        return {
          ...state,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          posting: false,
          zoneError: '',
          zones: state.zones.concat(action.zone),
          zoneSuccess: 'Successfully created new zone. Please refresh the page to view addition.',
        };
      } else {
        return state;
      }
    }
    case CREATE_ZONE_FAILURE: {
      if (state.posting) {
        return {
          ...state,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          posting: false,
          zoneError: action.error.message,
          zoneSuccess: '',
        };
      } else {
        return state;
      }
    }
    case DELETE_ZONE: {
      return loop(
        {
          ...state,
          deleting: true,
        },
        Cmd.run(deleteZone, {
          args: [action.zone.id, action.accessToken],
          failActionCreator: (error) => zoneDeleteFailure(error),
          successActionCreator: () => zoneDeleteSuccess(action.zone),
        }),
      );
    }
    case DELETE_ZONE_SUCCESS: {
      if (state.deleting) {
        return {
          ...state,
          deleting: false,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          zoneError: '',
          zones: state.zones.filter((zone) => zone.id !== action.data.id),
          zoneSuccess: `Successfully deleted zone "${action.data.zoneName}"`,
        };
      } else {
        return state;
      }
    }
    case DELETE_ZONE_FAILURE: {
      if (state.deleting) {
        return {
          ...state,
          deleting: false,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          zoneError: action.error.message,
          zoneSuccess: '',
        };
      } else {
        return state;
      }
    }
    case UPDATE_ZONE: {
      return loop(
        {
          ...state,
          putting: true,
          requestSerial: state.requestSerial + 1,
        },
        Cmd.run(putZone, {
          args: [action.id, action.zone, action.accessToken],
          failActionCreator: (error) => zoneUpdateFailure(state.requestSerial, error),
          successActionCreator: (data) => zoneUpdateSuccess(state.requestSerial, data.body),
        }),
      );
    }
    case UPDATE_ZONE_SUCCESS: {
      if (state.putting) {
        return {
          ...state,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          putting: false,
          zoneError: '',
          zoneModificationDate: action.zone.modificationDate,
          zones: replaceZones(state.zones, action.zone),
          zoneSuccess: 'Successfully updated existing zone.',
        };
      } else {
        return state;
      }
    }
    case UPDATE_ZONE_FAILURE: {
      if (state.putting) {
        return {
          ...state,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          putting: false,
          zoneError: action.error.message,
          zoneSuccess: '',
        };
      } else {
        return state;
      }
    }
    case LOCALIZATIONS_FETCH: {
      if (action.zoneId !== state.zoneId || !state.fetchLocalizationsRequest) {
        return loop(
          {
            ...state,
            fetchLocalizationsRequest: true,
            zoneId: action.zoneId,
          },
          Cmd.run(getZoneLocalizations, {
            args: [action.zoneId, action.accessToken],
            failActionCreator: (error) => localizationsFetchFailure(error),
            successActionCreator: (data) => localizationsFetchSuccess(data.body.objects),
          }),
        );
      } else {
        return state;
      }
    }
    case LOCALIZATIONS_FETCH_SUCCESS: {
      if (state.fetchLocalizationsRequest) {
        return {
          ...state,
          fetchLocalizationsError: '',
          fetchLocalizationsRequest: false,
          localizations: action.localizations,
        };
      } else {
        return state;
      }
    }
    case LOCALIZATIONS_FETCH_FAILURE: {
      if (state.fetchLocalizationsRequest) {
        return {
          ...state,
          fetchLocalizationsError: `Failed to get Zone Localizations: ${action.error.message}`,
          fetchLocalizationsRequest: false,
          localizations: [],
        };
      } else {
        return state;
      }
    }
    case SET_CURRENT_ZONE: {
      return {
        ...state,
        currentZone: action.currentZone,
        localizationCreateUpdateError: '',
        localizationCreateUpdateSuccess: '',
        localizationDeleteError: '',
        localizationDeleteSuccess: '',
        zoneError: '',
        zoneModificationDate: action.currentZone.modificationDate,
        zoneSuccess: '',
      };
    }
    case LOCALIZATION_CREATE: {
      return loop(
        {
          ...state,
          creatingLocalization: true,
        },
        Cmd.run(postZoneLocalization, {
          args: [action.localization, action.accessToken],
          failActionCreator: (error) => localizationCreateFailure(error),
          successActionCreator: (data) => localizationCreateSuccess(data.body),
        }),
      );
    }
    case LOCALIZATION_CREATE_SUCCESS: {
      if (state.creatingLocalization) {
        return {
          ...state,
          creatingLocalization: false,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: 'Successfully created localization in',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          localizationLanguage: action.data.language,
          localizations: [action.data].concat(state.localizations),
        };
      } else {
        return state;
      }
    }
    case LOCALIZATION_CREATE_FAILURE: {
      if (state.creatingLocalization) {
        return {
          ...state,
          creatingLocalization: false,
          localizationCreateUpdateError: `Failed to create localization. ${action.error.message}`,
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
        };
      } else {
        return state;
      }
    }
    case LOCALIZATION_DELETE: {
      return loop(
        {
          ...state,
          deletingLocalization: true,
        },
        Cmd.run(deleteZoneLocalization, {
          args: [action.localization.id, action.accessToken],
          failActionCreator: (error) => localizationDeleteFailure(error),
          successActionCreator: () => localizationDeleteSuccess(action.localization),
        }),
      );
    }
    case LOCALIZATION_DELETE_SUCCESS: {
      if (state.deletingLocalization) {
        return {
          ...state,
          deletingLocalization: false,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: 'Successfully deleted localization in',
          localizationLanguage: action.data.language,
          localizations: state.localizations.filter((localization) => localization.id !== action.data.id),
        };
      } else {
        return state;
      }
    }
    case LOCALIZATION_DELETE_FAILURE: {
      if (state.deletingLocalization) {
        return {
          ...state,
          deletingLocalization: false,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: `Failed to delete localization. ${action.error.message}`,
          localizationDeleteSuccess: '',
        };
      } else {
        return state;
      }
    }
    case LOCALIZATION_UPDATE: {
      return loop(
        {
          ...state,
          updatingLocalization: true,
        },
        Cmd.run(putZoneLocalization, {
          args: [action.localization, action.accessToken],
          failActionCreator: (error) => localizationUpdateFailure(error),
          successActionCreator: (data) => localizationUpdateSuccess(data.body),
        }),
      );
    }
    case LOCALIZATION_UPDATE_SUCCESS: {
      if (state.updatingLocalization) {
        const localizations = state.localizations.slice();
        localizations[state.localizations.findIndex((localization) => localization.id === action.data.id)] = action.data;
        return {
          ...state,
          localizationCreateUpdateError: '',
          localizationCreateUpdateSuccess: 'Successfully updated localization in',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          localizationLanguage: action.data.language,
          localizations,
          updatingLocalization: false,
        };
      } else {
        return state;
      }
    }
    case LOCALIZATION_UPDATE_FAILURE: {
      if (state.updatingLocalization) {
        return {
          ...state,
          localizationCreateUpdateError: `Failed to update localization. ${action.error.message}`,
          localizationCreateUpdateSuccess: '',
          localizationDeleteError: '',
          localizationDeleteSuccess: '',
          updatingLocalization: false,
        };
      } else {
        return state;
      }
    }
    case CLEAR_ZONES_RESULTS: {
      return {
        ...state,
        localizationCreateUpdateError: '',
        localizationCreateUpdateSuccess: '',
        localizationDeleteError: '',
        localizationDeleteSuccess: '',
        localizationLanguage: '',
        zoneError: '',
        zoneSuccess: '',
      };
    }
    default:
      return state;
  }
};

export default reducer;
