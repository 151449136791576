import { requestWrapper } from './reusable';

const config = require('../../config')();

export const getOktaToken = (token) => requestWrapper(
  token,
  config.slsAuthTokenResource,
  'get',
);

export const getReasons = (token, companyId) => requestWrapper(
  token,
  config.posReasonsResource,
  'get',
  { companyId },
);

export const getReasonById = (token, companyId, reasonId) => requestWrapper(
  token,
  config.posReasonsResource,
  'get',
  { companyId, reasonId },
);

export const createReason = (token, companyId, data) => requestWrapper(
  token,
  config.posReasonsResource,
  'post',
  { companyId },
  { data },
);

export const updateReason = (token, companyId, data) => requestWrapper(
  token,
  config.posReasonsResource,
  'put',
  { companyId, reasonId: data.id },
  { data },
);

export const deleteReason = (token, companyId, reasonId) => requestWrapper(
  token,
  config.posReasonsResource,
  'delete',
  { companyId, reasonId },
);
