{
    "lastA11yAudit": "07/08/22",
    "name": "Menu",
    "description": "Menu displays a list of choices on a temporary surface. They are triggered by an interactive component, such as buttons, inputs, actions, or other interactive controls.",
    "docs": "#/components/menus/Menu",
    "github": "https://github.com/nike-enterprise-design-system/eds/tree/main/src/components/Menu",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=248%3A1611",
    "tags": ["menu", "dropdown", "profile", "select"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
