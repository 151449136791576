import PropTypes from 'prop-types';
import React from 'react';
import ReactSelectCreatable from 'react-select/creatable';

import '../../../reusable/Select.css';

const toStoreNumberOptions = (stores) => stores.map((s) => ({
  label: `#${s.storeNumber} "${s.name}" (${s.region}, ${s.conceptCode}, ${s.facilityType})`,
  value: `${s.storeNumber}|${s.id}`,
}));

const toStoreNumber = (storeNumberAndId) => storeNumberAndId.split('|')[0];

const toStoreId = (storeNumberAndId) => storeNumberAndId.split('|')[1];

const storeNumberOptionFilter = (option, input) => input && input !== '' && toStoreNumber(option.value) === input;

const StoreNumber = (props) => (
  <article className="ncss-col-sm-6 va-sm-t">
    Store Number
    <ReactSelectCreatable
      isClearable
      classNamePrefix="react-select"
      filterOption={storeNumberOptionFilter}
      id="storeNumberSelect"
      isDisabled={(!props.adding && !props.userIsOwner) || props.disableCmpFields}
      noOptionsMessage={() => null}
      options={[{ label: '', value: '' }].concat(toStoreNumberOptions(props.stores))}
      styles={{
        container: (styles) => ({ ...styles, zIndex: 100 }),
        control: (styles) => ({
          ...styles, borderColor: props.errorMessage !== '' ? 'red' : 'lightgrey', padding: '0px 0px 0px 3px', zIndex: 100,
        }),
      }}
      value={{ label: props.storeNumber || '', value: props.storeNumber || '' }}
      onChange={(v) => {
        if (v && v.label && v.value) {
          // eslint-disable-next-line no-alert
          if (v.label.startsWith('#') && window.confirm(`Store ${toStoreNumber(v.value)} already exists. Edit existing store instead?`)) {
            props.history.push(`/storelocationservices/stores/detail/${toStoreId(v.value)}`);
          } else {
            props.updateStoreNumber(toStoreNumber(v.value));
          }
        } else {
          props.updateStoreNumber(v || '');
        }
      }}
    />
    <p className="text-color-error body-4 mt2-sm">{props.errorMessage}</p>
  </article>
);

StoreNumber.defaultProps = {
  stores: [],
};

StoreNumber.propTypes = {
  adding: PropTypes.bool.isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
  storeNumber: PropTypes.string.isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape()),
  updateStoreNumber: PropTypes.func.isRequired,
  userIsOwner: PropTypes.bool.isRequired,
};

export default StoreNumber;
