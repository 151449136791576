{
    "lastA11yAudit": "",
    "name": "Divider",
    "docs": "#/components/Divider",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=360%3A11837&t=T1VqSOnzGU8ayhUh-1",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Divider",
    "tags": ["divider", "hr", "border", "line"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Beta"]
}
