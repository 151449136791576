import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactSelectCreatable from 'react-select/creatable';

import { handleGtin, handleStyleColor } from '../../utils/formatting';

import './Select.css';

const getHelpMessage = (label, helpMessage = '') => {
  if (helpMessage) return ` - ${helpMessage}`;
  switch (label) {
    case 'GTIN':
      return ' - must be 14 digits.';
    case 'Style Color':
      return ' - must be 5 or 6 alphanumeric values, hyphen, 3 alphanumeric vlaues. Ex: 123aBc-Lo1';
    default:
      return '';
  }
};

const Creatable = (props) => {
  const [regexMessage, setRegexMessage] = useState('');

  const validateCode = (label, value) => {
    let message = '';
    let code = value;
    if (Array.isArray(value)) {
      // if isMulti, only validate the last value (the new one)
      code = value[value.length - 1];
    }
    switch (label) {
      case 'GTIN':
        message = code && code.value && !handleGtin(code.value) && 'GTIN must be 14 digits.';
        setRegexMessage(message);
        return (!message || !value) ? props.onChange(value) : null;
      case 'Style Color':
        message = code && code.value && !handleStyleColor(code.value) && 'Style Color must be 5 or 6 alphanumeric values, hyphen, 3 alphanumeric values.';
        setRegexMessage(message);
        return (!message || !value) ? props.onChange(value) : null;
      default:
        setRegexMessage('');
        break;
    }
    return props.onChange(value);
  };

  return (
    <article className={`ncss-col-sm-${props.width} va-sm-t ta-sm-l`}>
      {props.label}{props.isMulti && 's'}<span className="text-color-accent body-4">{getHelpMessage(props.label, props.helpMessage)}</span>
      <ReactSelectCreatable
        isClearable
        arrowRenderer={null}
        classNamePrefix={`${props.isMulti ? 'multi' : 'react'}-select`}
        components={{ DropdownIndicator: null }}
        id={props.label}
        isDisabled={props.isDisabled}
        isMulti={props.isMulti}
        noOptionsMessage={() => null}
        placeholder={`Hit [enter] to create the ${props.label}${props.isMulti ? 's' : ''}`}
        styles={{
          container: (styles) => ({ ...styles, zIndex: props.zIndex }),
          control: (styles) => ({
            ...styles, borderColor: props.errorMessage !== '' ? 'red' : 'lightgrey', padding: '0px 0px 0px 3px', zIndex: props.zIndex,
          }),
        }}
        value={props.value}
        onChange={(value) => validateCode(props.label, value)}
      />
      {(regexMessage || props.errorMessage) && <p className="text-color-error body-4 mt2-sm">{regexMessage || props.errorMessage}</p>}
    </article>
  );
};

Creatable.defaultProps = {
  helpMessage: '',
  isDisabled: false,
  isMulti: false,
  value: null,
  width: 12,
  zIndex: 10,
};

Creatable.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  helpMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  width: PropTypes.number,
  zIndex: PropTypes.number,
};

export default Creatable;
