/* eslint-disable no-bitwise */
export const any = (predicate, list) => {
  let r = false;
  for (let i = 0; i < list.length && !r; i++) {
    r |= predicate(list[i]);
  }
  return r;
};

export const all = (fn, list) => {
  let r = true;
  for (let i = 0; r && i < list.length; i++) {
    r &= fn(list[i]);
  }
  return r;
};

export const replaceWhere = (arr, pred, newVal) => {
  const result = [...arr];
  const i = result.findIndex(pred);
  result[i] = newVal;
  return result;
};
