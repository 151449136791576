{
    "lastA11yAudit": "07/08/22",
    "name": "Checkbox",
    "description": "Checkbox is a binary selection to indicate if a value is included within a set of options.",
    "docs": "#/components/forms/Checkbox",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Checkbox",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=48802%3A348770",
    "tags": ["checkbox", "inputs", "form elements", "forms"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
