import { get } from 'lodash';

import { all, any } from './array';

const onlyWhitespace = /^(\s*)$/;
const repeatedWhitespace = /(\s+)/;
const digitalStoreSearchFields = ['id', 'storeNumber', 'name', 'country'];
const storeSearchFields = ['id', 'storeNumber', 'name', 'company', 'address.city', 'region', 'description'];
const warehouseSearchFields = ['id', 'name', 'address.city', 'address.address1', 'company'];

export const getFilteredDigitalStores = (thisState, allDigitalStores) => {
  // define the filtering functions
  // if the filter doesn't have a value, default to true
  let regionFilter = () => true;
  let countryFilter = () => true;
  let searchFilter = () => true;

  if (thisState.region.value !== '') {
    regionFilter = (store) => (store.region === thisState.region.value);
  }
  if (thisState.country.value !== '') {
    countryFilter = (store) => (store.country === thisState.country.value);
  }
  if (thisState.search !== '') {
    const terms = thisState.search.split(repeatedWhitespace)
      .filter((s) => !onlyWhitespace.test(s))
      .map((s) => new RegExp(s, 'i'));
    searchFilter = (store) => all(
      (term) => any(
        (field) => typeof get(store, field) === 'string' && term.test(get(store, field)), digitalStoreSearchFields,
      ),
      terms,
    );
  }
  return allDigitalStores.filter((store) => (
    regionFilter(store)
    && countryFilter(store)
    && searchFilter(store)));
};

export const getFilteredStores = (thisState, allStores) => {
  // define the filtering functions
  // if the filter doesn't have a value, default to true
  let regionFilter = () => true;
  let countryFilter = () => true;
  let stateFilter = () => true;
  let facilityTypeFilter = () => true;
  let offeringFilter = () => true;
  let storeServiceFilter = () => true;
  let searchFilter = () => true;
  let storeStatusFilter = () => true;

  if (thisState.region.value !== '') {
    regionFilter = (store) => (store.region === thisState.region.value);
  }
  if (thisState.country.value !== '') {
    countryFilter = (store) => (store.address ? store.address.country === thisState.country.value : false);
  }
  if (thisState.state.value !== '') {
    stateFilter = (store) => (store.address ? store.address.state === thisState.state.value : false);
  }
  if (thisState.facilityType.value !== '') {
    facilityTypeFilter = (store) => (store.facilityType === thisState.facilityType.value);
  }
  if (thisState.offering.value !== '') {
    offeringFilter = (store) => (store.offerings ? store.offerings.map((offer) => offer.name).includes(thisState.offering.value) : false);
  }
  if (thisState.storeService.value !== '') {
    storeServiceFilter = (service) => {
      let { storeServices } = service;
      if (thisState.serviceStatus.value === 'DISABLED') {
        storeServices = storeServices.filter((services) => !services.enabled);
      } else if (thisState.serviceStatus.value === 'ENABLED') {
        storeServices = storeServices.filter((services) => services.enabled);
      }

      return storeServices ? storeServices.map((services) => services.serviceGroup).includes(thisState.storeService.value) : false;
    };
  }
  if (thisState.search !== '') {
    const terms = thisState.search.split(repeatedWhitespace)
      .filter((s) => !onlyWhitespace.test(s))
      .map((s) => new RegExp(s, 'i'));
    searchFilter = (store) => all(
      (term) => any(
        (field) => typeof get(store, field) === 'string' && term.test(get(store, field)), storeSearchFields,
      ),
      terms,
    );
  }
  if (thisState.storeStatus.value !== '') {
    storeStatusFilter = (store) => (store.storeStatus === thisState.storeStatus.value);
  }
  return allStores.filter((store) => (
    regionFilter(store)
    && countryFilter(store)
    && stateFilter(store)
    && facilityTypeFilter(store)
    && offeringFilter(store)
    && storeServiceFilter(store)
    && searchFilter(store)
    && storeStatusFilter(store)));
};

export const getFilteredWarehouses = (thisState, allWarehouses) => {
  // define the filtering functions
  // if the filter doesn't have a value, default to true
  let regionFilter = () => true;
  let countryFilter = () => true;
  let stateFilter = () => true;
  let searchFilter = () => true;

  if (thisState.region.value !== '') {
    regionFilter = (warehouse) => (warehouse.region === thisState.region.value);
  }
  if (thisState.country.value !== '') {
    countryFilter = (warehouse) => (warehouse.address.country === thisState.country.value);
  }
  if (thisState.state.value !== '') {
    stateFilter = (warehouse) => (warehouse.address.state === thisState.state.value);
  }

  if (thisState.search !== '') {
    const terms = thisState.search.split(repeatedWhitespace)
      .filter((s) => !onlyWhitespace.test(s))
      .map((s) => new RegExp(s, 'i'));
    searchFilter = (warehouse) => all(
      (term) => any(
        (field) => typeof get(warehouse, field) === 'string' && term.test(get(warehouse, field)), warehouseSearchFields,
      ),
      terms,
    );
  }
  return allWarehouses.filter((warehouse) => (
    regionFilter(warehouse)
    && countryFilter(warehouse)
    && stateFilter(warehouse)
    && searchFilter(warehouse)));
};
