const invalidField = ' ';

const missingValueCheck = (pathCheck, invField = invalidField) => ((pathCheck) ? JSON.stringify(pathCheck.replace(/,/g, ' ')) : invField);
const missingValueCheckNumeral = (pathCheck, invField = invalidField) => (pathCheck || invField);
const missingValueCheckArray = (pathCheck, invField = invalidField) => ((pathCheck) ? pathCheck.join(' ') : invField);

const parseLocalizations = (localizations) => {
  let entry = '';
  // eslint-disable-next-line no-unused-expressions
  localizations?.forEach((loc) => {
    let announcements = '';
    // eslint-disable-next-line no-unused-expressions
    loc?.announcements?.forEach((announcement) => {
      announcements += ` [ExpiryDate: ${announcement?.expiryDate} - Content: ${announcement?.content}]`;
    });
    const storeName = missingValueCheck(loc?.address?.storeName);
    const address1 = missingValueCheck(loc?.address?.address1);
    const address2 = missingValueCheck(loc?.address?.address2);
    const address3 = missingValueCheck(loc?.address?.address3);
    const city = missingValueCheck(loc?.address?.city);
    const state = missingValueCheck(loc?.address?.state);
    const postalCode = missingValueCheck(loc?.address?.postalCode);
    const country = missingValueCheck(loc?.address?.country);
    const directions = missingValueCheck(loc?.contextualAddress?.address);
    const neighborhood = missingValueCheck(loc?.contextualAddress?.neighborhood);
    const transit = missingValueCheck(loc?.contextualAddress?.transit);
    const parkingSuggestions = missingValueCheck(loc?.contextualAddress?.parkingSuggestions);
    const address = `Address: [ StoreName: ${storeName} Address1: ${address1} Address2: ${address2} Address3: ${address3} City: ${city} State: ${state} PostalCode: ${postalCode} Country: ${country} ]`;
    const contextualAddress = `Contextual Address: [ address: ${directions} neighborhood: ${neighborhood} transit: ${transit} parkingSuggestions: ${parkingSuggestions} ]`;
    entry += `[Language: ${loc.language} - ${address} - ${contextualAddress} - Announcements [${announcements.trim()}]] `;
  });

  return missingValueCheck(entry.trim());
};

const parseSpecialHours = (specialHours) => {
  let spec = '';
  specialHours.forEach((obj) => {
    spec += `[${obj.date}|${obj.hours[0]?.localOpenTime}|${obj.hours[0]?.localCloseTime}] `;
  });
  return spec;
};

const digitalStoreSchema = (facility) => [
  missingValueCheck(facility?.id),
  missingValueCheck(facility?.storeNumber),
  missingValueCheck(facility?.name),
  missingValueCheck(facility?.country),
  missingValueCheck(facility?.currencyCode),
  missingValueCheck(facility?.creationDate),
  missingValueCheck(facility?.modificationDate),
  missingValueCheck(facility?.storeChannel),
  missingValueCheck(facility?.region),
  missingValueCheck(facility?.partnerName),
  missingValueCheckArray(facility?.islandPacific?.sapShipTo),
  missingValueCheck(facility?.islandPacific?.storePlatform),
  missingValueCheck(facility?.islandPacific?.vatIdentifier),
  missingValueCheck(facility?.islandPacific?.ipTerritory),
  missingValueCheck(facility?.islandPacific?.ipSubterritory),
  missingValueCheck(facility?.islandPacific?.timeZone),
  missingValueCheck(facility?.islandPacific?.ipKeyCity),
  '\r',
];

const warehouseSchema = (facility) => [
  missingValueCheck(facility?.id),
  missingValueCheck(facility?.company),
  missingValueCheck(facility?.name),
  missingValueCheck(facility?.warehouseNumber),
  missingValueCheck(facility?.type),
  missingValueCheck(facility?.description),
  missingValueCheckNumeral(facility?.facilityDimensions?.totalArea),
  missingValueCheckNumeral(facility?.facilityDimensions?.totalStorageArea),
  missingValueCheck(facility?.facilityDimensions?.measurementUnits),
  missingValueCheckArray(facility?.channels),
  missingValueCheck(facility?.email),
  missingValueCheck(facility?.phone),
  missingValueCheck(facility?.timezone),
  missingValueCheckNumeral(facility?.coordinates?.longitude),
  missingValueCheckNumeral(facility?.coordinates?.latitude),
  missingValueCheck(facility?.address?.address1),
  missingValueCheck(facility?.address?.address2),
  missingValueCheck(facility?.address?.address3),
  missingValueCheck(facility?.address?.area),
  missingValueCheck(facility?.address?.city),
  missingValueCheck(facility?.address?.state),
  missingValueCheck(facility?.address?.postalCode),
  missingValueCheck(facility?.address?.country),
  missingValueCheck(facility?.operationalDetails?.openingDate),
  missingValueCheck(facility?.operationalDetails?.closingDate),
  missingValueCheck(facility?.resourceType),
  missingValueCheckArray(facility?.currencies),
  missingValueCheck(facility?.managerName?.firstName),
  missingValueCheck(facility?.managerName?.altFirstName),
  missingValueCheck(facility?.managerName?.middleInitial),
  missingValueCheck(facility?.managerName?.middleName),
  missingValueCheck(facility?.managerName?.lastName),
  missingValueCheck(facility?.managerName?.altLastName),
  missingValueCheck(facility?.managerName?.givenName),
  missingValueCheck(facility?.region),
  missingValueCheck(facility?.islandPacificAttributes?.warehouseNumber),
  missingValueCheck(facility?.islandPacificAttributes?.vatIdentifier),
  missingValueCheckArray(facility?.islandPacificAttributes?.sapShipTo),
  missingValueCheck(facility?.islandPacificAttributes?.partnerName),
  missingValueCheck(facility?.islandPacificAttributes?.ipChannel),
  missingValueCheck(facility?.islandPacificAttributes?.ipTerritory),
  missingValueCheck(facility?.islandPacificAttributes?.ipSubTerritory),
  missingValueCheck(facility?.islandPacificAttributes?.temporaryClosure?.reason),
  missingValueCheck(facility?.islandPacificAttributes?.temporaryClosure?.fromDate),
  missingValueCheck(facility?.islandPacificAttributes?.temporaryClosure?.toDate),
  missingValueCheck(facility?.islandPacificAttributes?.ipWarehouseType),
  missingValueCheck(facility?.islandPacificAttributes?.keyCity),
  '\r',
];
const baseStoreSchema = (facility) => [
  missingValueCheck(facility?.id),
  missingValueCheck(facility?.storeNumber),
  missingValueCheck(facility?.company),
  missingValueCheck(facility?.name),
  missingValueCheck(facility?.facilityType),
  missingValueCheck(facility?.timezone),
  missingValueCheck(facility?.locale),
  missingValueCheck(facility?.costCenter),
  missingValueCheck(facility?.district),
  missingValueCheck(facility?.territory),
  missingValueCheckNumeral(facility?.coordinates?.longitude),
  missingValueCheckNumeral(facility?.coordinates?.latitude),
  missingValueCheck(facility?.address?.address1),
  missingValueCheck(facility?.address?.address2),
  missingValueCheck(facility?.address?.address3),
  missingValueCheck(facility?.address?.area),
  missingValueCheck(facility?.address?.city),
  missingValueCheck(facility?.address?.state),
  missingValueCheck(facility?.address?.postalCode),
  missingValueCheck(facility?.address?.country),
  missingValueCheck(facility?.address?.iso2Country),
  missingValueCheck(facility?.address?.county),
  parseLocalizations(facility?.localizations),
  missingValueCheck(facility?.creationDate),
  missingValueCheck(facility?.modificationDate),
  missingValueCheck(facility?.region),
  missingValueCheck(facility?.operationalDetails?.openingDate),
  missingValueCheck(facility?.operationalDetails?.closingDate),
  missingValueCheck(facility?.operationalDetails?.closures?.[0]),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localCloseTime),
  parseSpecialHours(missingValueCheckNumeral(facility?.operationalDetails?.hoursOfOperation?.specialHours, [])),
  missingValueCheck(facility?.partnerName),
  missingValueCheckNumeral(facility?.sellingArea?.grossSize),
  missingValueCheckNumeral(facility?.sellingArea?.netSize),
  missingValueCheck(facility?.sellingArea?.unitOfMeasure),
  missingValueCheck(facility?.businessConcept),
  missingValueCheck(facility?.storeConcept),
  missingValueCheck(facility?.description),
  missingValueCheck(facility?.email),
  missingValueCheck(facility?.phone),
  missingValueCheck(facility?.imageUrl),
  missingValueCheck(facility?.brand),
  missingValueCheck(facility?.twitterHandle),
  missingValueCheckNumeral(facility?.testStore, false),
  missingValueCheck(facility?.storeStatus),
  missingValueCheckArray(facility?.currencies),
  missingValueCheck(facility?.slug),
  missingValueCheck(facility?.defaultLanguage),
  missingValueCheck(facility?.storeType),
  missingValueCheck(facility?.links?.self?.ref),
  missingValueCheck(facility?.categories),
  missingValueCheck(facility?.resourceType),
  missingValueCheck(facility?.shipTo),
  missingValueCheck(facility?.soldTo),
  missingValueCheck(facility?.alternateIds?.hrLocationId),
  missingValueCheck(facility?.loginClassification),
  '\r',
];
const ipStoreSchema = (facility) => [
  missingValueCheck(facility?.id),
  missingValueCheck(facility?.storeNumber),
  missingValueCheck(facility?.company),
  missingValueCheck(facility?.name),
  missingValueCheck(facility?.facilityType),
  missingValueCheck(facility?.timezone),
  missingValueCheck(facility?.locale),
  missingValueCheck(facility?.costCenter),
  missingValueCheck(facility?.district),
  missingValueCheck(facility?.territory),
  missingValueCheckNumeral(facility?.coordinates?.longitude),
  missingValueCheckNumeral(facility?.coordinates?.latitude),
  missingValueCheck(facility?.address?.address1),
  missingValueCheck(facility?.address?.address2),
  missingValueCheck(facility?.address?.address3),
  missingValueCheck(facility?.address?.area),
  missingValueCheck(facility?.address?.city),
  missingValueCheck(facility?.address?.state),
  missingValueCheck(facility?.address?.postalCode),
  missingValueCheck(facility?.address?.country),
  missingValueCheck(facility?.address?.iso2Country),
  missingValueCheck(facility?.address?.county),
  parseLocalizations(facility?.localizations),
  missingValueCheck(facility?.creationDate),
  missingValueCheck(facility?.modificationDate),
  missingValueCheck(facility?.region),
  missingValueCheck(facility?.operationalDetails?.openingDate),
  missingValueCheck(facility?.operationalDetails?.closingDate),
  missingValueCheck(facility?.operationalDetails?.closures?.[0]),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localCloseTime),
  parseSpecialHours(missingValueCheckNumeral(facility?.operationalDetails?.hoursOfOperation?.specialHours, [])),
  missingValueCheck(facility?.partnerName),
  missingValueCheckNumeral(facility?.sellingArea?.grossSize),
  missingValueCheckNumeral(facility?.sellingArea?.netSize),
  missingValueCheck(facility?.sellingArea?.unitOfMeasure),
  missingValueCheck(facility?.businessConcept),
  missingValueCheck(facility?.storeConcept),
  missingValueCheck(facility?.description),
  missingValueCheck(facility?.email),
  missingValueCheck(facility?.phone),
  missingValueCheck(facility?.imageUrl),
  missingValueCheck(facility?.brand),
  missingValueCheck(facility?.twitterHandle),
  missingValueCheckNumeral(facility?.testStore, false),
  missingValueCheck(facility?.storeStatus),
  missingValueCheckArray(facility?.currencies),
  missingValueCheck(facility?.slug),
  missingValueCheck(facility?.defaultLanguage),
  missingValueCheck(facility?.storeType),
  missingValueCheck(facility?.links?.self?.ref),
  missingValueCheck(facility?.categories),
  missingValueCheck(facility?.resourceType),
  missingValueCheckNumeral(facility?.islandPacific?.ipPlatform),
  missingValueCheck(facility?.islandPacific?.vatIdentifier),
  missingValueCheckArray(facility?.islandPacific?.sapShipTo),
  missingValueCheck(facility?.islandPacific?.ipChannel),
  missingValueCheck(facility?.islandPacific?.sellingArea?.gross),
  missingValueCheck(facility?.islandPacific?.sellingArea?.net),
  missingValueCheck(facility?.islandPacific?.ipTerritory),
  missingValueCheck(facility?.islandPacific?.ipSubTerritory),
  missingValueCheck(facility?.islandPacific?.temporaryClosure?.reason),
  missingValueCheck(facility?.islandPacific?.temporaryClosure?.fromDate),
  missingValueCheck(facility?.islandPacific?.temporaryClosure?.toDate),
  missingValueCheck(facility?.islandPacific?.distributionCenter?.code),
  missingValueCheck(facility?.islandPacific?.distributionCenter?.description),
  missingValueCheck(facility?.islandPacific?.ipTimezone),
  missingValueCheck(facility?.islandPacific?.keyCity),
  missingValueCheck(facility?.shipTo),
  missingValueCheck(facility?.soldTo),
  missingValueCheck(facility?.alternateIds?.hrLocationId),
  missingValueCheck(facility?.loginClassification),
  '\r',
];
const jeStoreSchema = (facility) => [
  missingValueCheck(facility?.id),
  missingValueCheck(facility?.storeNumber),
  missingValueCheck(facility?.company),
  missingValueCheck(facility?.name),
  missingValueCheck(facility?.facilityType),
  missingValueCheck(facility?.timezone),
  missingValueCheck(facility?.locale),
  missingValueCheck(facility?.costCenter),
  missingValueCheck(facility?.district),
  missingValueCheck(facility?.territory),
  missingValueCheckNumeral(facility?.coordinates?.longitude),
  missingValueCheckNumeral(facility?.coordinates?.latitude),
  missingValueCheck(facility?.address?.address1),
  missingValueCheck(facility?.address?.address2),
  missingValueCheck(facility?.address?.address3),
  missingValueCheck(facility?.address?.area),
  missingValueCheck(facility?.address?.city),
  missingValueCheck(facility?.address?.state),
  missingValueCheck(facility?.address?.postalCode),
  missingValueCheck(facility?.address?.country),
  missingValueCheck(facility?.address?.iso2Country),
  missingValueCheck(facility?.address?.county),
  parseLocalizations(facility?.localizations),
  missingValueCheck(facility?.creationDate),
  missingValueCheck(facility?.modificationDate),
  missingValueCheck(facility?.region),
  missingValueCheck(facility?.operationalDetails?.openingDate),
  missingValueCheck(facility?.operationalDetails?.closingDate),
  missingValueCheck(facility?.operationalDetails?.closures?.[0]),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localCloseTime),
  parseSpecialHours(missingValueCheckNumeral(facility?.operationalDetails?.hoursOfOperation?.specialHours, [])),
  missingValueCheck(facility?.partnerName),
  missingValueCheckNumeral(facility?.sellingArea?.grossSize),
  missingValueCheckNumeral(facility?.sellingArea?.netSize),
  missingValueCheck(facility?.sellingArea?.unitOfMeasure),
  missingValueCheck(facility?.businessConcept),
  missingValueCheck(facility?.storeConcept),
  missingValueCheck(facility?.description),
  missingValueCheck(facility?.email),
  missingValueCheck(facility?.phone),
  missingValueCheck(facility?.imageUrl),
  missingValueCheck(facility?.brand),
  missingValueCheck(facility?.twitterHandle),
  missingValueCheckNumeral(facility?.testStore, false),
  missingValueCheck(facility?.storeStatus),
  missingValueCheckArray(facility?.currencies),
  missingValueCheck(facility?.slug),
  missingValueCheck(facility?.defaultLanguage),
  missingValueCheck(facility?.storeType),
  missingValueCheck(facility?.links?.self?.ref),
  missingValueCheck(facility?.categories),
  missingValueCheck(facility?.resourceType),
  missingValueCheck(facility?.justEnough?.justEnoughRegion.code).replace('\\b', ''),
  missingValueCheck(facility?.justEnough?.justEnoughRegion.name),
  missingValueCheck(facility?.justEnough?.justEnoughRegion.id),
  missingValueCheck(facility?.justEnough?.justEnoughTimezone),
  missingValueCheck(facility?.justEnough?.countryDesc),
  missingValueCheck(facility?.justEnough?.storeSubConcept?.id),
  missingValueCheck(facility?.justEnough?.storeSubConcept?.code),
  missingValueCheck(facility?.justEnough?.soldToAccountNumber),
  missingValueCheck(facility?.justEnough?.shipToAccountNumber),
  missingValueCheck(facility?.justEnough?.leadTime),
  missingValueCheck(facility?.justEnough?.orderCategory),
  missingValueCheck(facility?.justEnough?.dimension?.code),
  missingValueCheck(facility?.justEnough?.dimension?.description),
  missingValueCheck(facility?.justEnough?.concept?.description),
  missingValueCheck(facility?.justEnough?.concept?.code),
  missingValueCheck(facility?.justEnough?.locationType?.code),
  missingValueCheck(facility?.justEnough?.locationType?.description),
  missingValueCheck(facility?.justEnough?.storeCapacity),
  missingValueCheck(facility?.justEnough?.apparelStoreCapacity),
  missingValueCheck(facility?.justEnough?.footwearStoreCapacity),
  missingValueCheck(facility?.justEnough?.equipmentStoreCapacity),
  missingValueCheck(facility?.justEnough?.fax),
  missingValueCheck(facility?.justEnough?.areaSize),
  missingValueCheck(facility?.justEnough?.sellAreaSize),
  missingValueCheck(facility?.justEnough?.salesArea?.description),
  missingValueCheck(facility?.justEnough?.salesArea?.code),
  missingValueCheck(facility?.justEnough?.territory?.description),
  missingValueCheck(facility?.justEnough?.territory?.code),
  missingValueCheck(facility?.justEnough?.areaUnitOfMeasure),
  missingValueCheck(facility?.shipTo),
  missingValueCheck(facility?.soldTo),
  missingValueCheck(facility?.alternateIds?.hrLocationId),
  missingValueCheck(facility?.loginClassification),
  '\r',
];

const storeSchema = (facility) => [
  missingValueCheck(facility?.id),
  missingValueCheck(facility?.storeNumber),
  missingValueCheck(facility?.company),
  missingValueCheck(facility?.name),
  missingValueCheck(facility?.facilityType),
  missingValueCheck(facility?.timezone),
  missingValueCheck(facility?.locale),
  missingValueCheck(facility?.costCenter),
  missingValueCheck(facility?.district),
  missingValueCheck(facility?.territory),
  missingValueCheckNumeral(facility?.coordinates?.longitude),
  missingValueCheckNumeral(facility?.coordinates?.latitude),
  missingValueCheck(facility?.address?.address1),
  missingValueCheck(facility?.address?.address2),
  missingValueCheck(facility?.address?.address3),
  missingValueCheck(facility?.address?.area),
  missingValueCheck(facility?.address?.city),
  missingValueCheck(facility?.address?.state),
  missingValueCheck(facility?.address?.postalCode),
  missingValueCheck(facility?.address?.country),
  missingValueCheck(facility?.address?.iso2Country),
  missingValueCheck(facility?.address?.county),
  parseLocalizations(facility?.localizations),
  missingValueCheck(facility?.creationDate),
  missingValueCheck(facility?.modificationDate),
  missingValueCheck(facility?.region),
  missingValueCheck(facility?.operationalDetails?.openingDate),
  missingValueCheck(facility?.operationalDetails?.closingDate),
  missingValueCheck(facility?.operationalDetails?.closures?.[0]),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.monday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.tuesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.wednesday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.thursday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.friday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.saturday?.[0]?.localCloseTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localOpenTime),
  missingValueCheck(facility?.operationalDetails?.hoursOfOperation?.regularHours?.sunday?.[0]?.localCloseTime),
  parseSpecialHours(missingValueCheckNumeral(facility?.operationalDetails?.hoursOfOperation?.specialHours, [])),
  missingValueCheck(facility?.partnerName),
  missingValueCheckNumeral(facility?.sellingArea?.grossSize),
  missingValueCheckNumeral(facility?.sellingArea?.netSize),
  missingValueCheck(facility?.sellingArea?.unitOfMeasure),
  missingValueCheck(facility?.businessConcept),
  missingValueCheck(facility?.storeConcept),
  missingValueCheck(facility?.description),
  missingValueCheck(facility?.email),
  missingValueCheck(facility?.phone),
  missingValueCheck(facility?.imageUrl),
  missingValueCheck(facility?.brand),
  missingValueCheck(facility?.twitterHandle),
  missingValueCheckNumeral(facility?.testStore, false),
  missingValueCheck(facility?.storeStatus),
  missingValueCheckArray(facility?.currencies),
  missingValueCheck(facility?.slug),
  missingValueCheck(facility?.defaultLanguage),
  missingValueCheck(facility?.storeType),
  missingValueCheck(facility?.links?.self?.ref),
  missingValueCheck(facility?.categories),
  missingValueCheck(facility?.resourceType),
  missingValueCheckNumeral(facility?.islandPacific?.ipPlatform),
  missingValueCheck(facility?.islandPacific?.vatIdentifier),
  missingValueCheckArray(facility?.islandPacific?.sapShipTo),
  missingValueCheck(facility?.islandPacific?.ipChannel),
  missingValueCheck(facility?.islandPacific?.sellingArea?.gross),
  missingValueCheck(facility?.islandPacific?.sellingArea?.net),
  missingValueCheck(facility?.islandPacific?.ipTerritory),
  missingValueCheck(facility?.islandPacific?.ipSubTerritory),
  missingValueCheck(facility?.islandPacific?.temporaryClosure?.reason),
  missingValueCheck(facility?.islandPacific?.temporaryClosure?.fromDate),
  missingValueCheck(facility?.islandPacific?.temporaryClosure?.toDate),
  missingValueCheck(facility?.islandPacific?.distributionCenter?.code),
  missingValueCheck(facility?.islandPacific?.distributionCenter?.description),
  missingValueCheck(facility?.islandPacific?.ipTimezone),
  missingValueCheck(facility?.islandPacific?.keyCity),
  missingValueCheck(facility?.justEnough?.justEnoughRegion.code).replace('\\b', ''),
  missingValueCheck(facility?.justEnough?.justEnoughRegion.name),
  missingValueCheck(facility?.justEnough?.justEnoughRegion.id),
  missingValueCheck(facility?.justEnough?.justEnoughTimezone),
  missingValueCheck(facility?.justEnough?.countryDesc),
  missingValueCheck(facility?.justEnough?.storeSubConcept?.id),
  missingValueCheck(facility?.justEnough?.storeSubConcept?.code),
  missingValueCheck(facility?.justEnough?.soldToAccountNumber),
  missingValueCheck(facility?.justEnough?.shipToAccountNumber),
  missingValueCheck(facility?.justEnough?.leadTime),
  missingValueCheck(facility?.justEnough?.orderCategory),
  missingValueCheck(facility?.justEnough?.dimension?.code),
  missingValueCheck(facility?.justEnough?.dimension?.description),
  missingValueCheck(facility?.justEnough?.concept?.description),
  missingValueCheck(facility?.justEnough?.concept?.code),
  missingValueCheck(facility?.justEnough?.locationType?.code),
  missingValueCheck(facility?.justEnough?.locationType?.description),
  missingValueCheck(facility?.justEnough?.storeCapacity),
  missingValueCheck(facility?.justEnough?.apparelStoreCapacity),
  missingValueCheck(facility?.justEnough?.footwearStoreCapacity),
  missingValueCheck(facility?.justEnough?.equipmentStoreCapacity),
  missingValueCheck(facility?.justEnough?.fax),
  missingValueCheck(facility?.justEnough?.areaSize),
  missingValueCheck(facility?.justEnough?.sellAreaSize),
  missingValueCheck(facility?.justEnough?.salesArea?.description),
  missingValueCheck(facility?.justEnough?.salesArea?.code),
  missingValueCheck(facility?.justEnough?.territory?.description),
  missingValueCheck(facility?.justEnough?.territory?.code),
  missingValueCheck(facility?.justEnough?.areaUnitOfMeasure),
  missingValueCheck(facility?.shipTo),
  missingValueCheck(facility?.soldTo),
  missingValueCheck(facility?.alternateIds?.hrLocationId),
  missingValueCheck(facility?.loginClassification),
  '\r',
];

export default {
  baseStoreSchema,
  digitalStoreSchema,
  ipStoreSchema,
  jeStoreSchema,
  storeSchema,
  warehouseSchema,
};
