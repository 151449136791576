import PropTypes from 'prop-types';
import React from 'react';

import { Creatable } from '../../reusable';

const codeOptions = [{ label: 'Discountable', value: '3' }, { label: 'Non-Discountable', value: '0' }];
const enabledOptions = [{ label: 'True', value: 'true' }, { label: 'False', value: 'false' }];

const Attribute = ({
  handleFormChange, id, label, value,
}) => (
  <>
    <header className="ncss-col-sm-6 va-sm-m ta-sm-r mb1-sm">{label}:</header>
    <section className="ncss-col-sm-6 va-sm-m ta-sm-l mb1-sm">
      <select
        id={id}
        name={id}
        value={value}
        onChange={(event) => handleFormChange(event.target.id, event.target.value)}
      >
        {(id.includes('Code') ? codeOptions : enabledOptions).map((opt) => (<option key={opt.label} value={opt.value}>{opt.label}</option>))}
      </select>
    </section>
  </>
);

Attribute.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const NormalForm = (props) => (
  <>
    <Creatable
      isMulti
      errorMessage={props.formErrors.codes}
      label={props.id}
      value={props.codes}
      width={8}
      onChange={(codes) => props.handleCodeChange(codes)}
    />
    <Attribute handleFormChange={props.handleFormChange} id="promotionEnabled" label="Promotion Enabled" value={props.promotionEnabled} />
    <Attribute handleFormChange={props.handleFormChange} id="priceOverrideEnabled" label="Price Override Enabled" value={props.priceOverrideEnabled} />
    <Attribute handleFormChange={props.handleFormChange} id="couponDiscountEnabled" label="Coupon Discount Enabled" value={props.couponDiscountEnabled} />
    <Attribute handleFormChange={props.handleFormChange} id="itemDiscountCode" label="Item Discount Code" value={props.itemDiscountCode} />
    <Attribute handleFormChange={props.handleFormChange} id="transactionDiscountCode" label="Transaction Discount Code" value={props.transactionDiscountCode} />
    <Attribute handleFormChange={props.handleFormChange} id="employeeDiscountCode" label="Employee Discount Code" value={props.employeeDiscountCode} />
    <Attribute handleFormChange={props.handleFormChange} id="thresholdDiscountCode" label="Threshold Discount Code" value={props.thresholdDiscountCode} />
  </>
);

NormalForm.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  couponDiscountEnabled: PropTypes.string.isRequired,
  employeeDiscountCode: PropTypes.string.isRequired,
  formErrors: PropTypes.shape().isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  itemDiscountCode: PropTypes.string.isRequired,
  priceOverrideEnabled: PropTypes.string.isRequired,
  promotionEnabled: PropTypes.string.isRequired,
  thresholdDiscountCode: PropTypes.string.isRequired,
  transactionDiscountCode: PropTypes.string.isRequired,
};

export default NormalForm;
