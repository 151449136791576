import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import {
  IPSubTerritory, IPTerritory, IPTimezones, keyCityValues,
} from '../../../../utils/static/sls-property-values';
import { Creatable, CustomPanel, Select } from '../../../reusable';

/* display form contents...
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => ( // not checking for adding here is intentional
  !!properties.islandPacificErrors.sapShipTo
  || !!properties.islandPacificErrors.ipTerritory || !!properties.islandPacificErrors.ipSubTerritory
  || !!properties.islandPacificErrors.timeZone || !!properties.islandPacificErrors.ipKeyCity);

class IslandPacific extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.islandPacificErrors !== this.props.islandPacificErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Island Pacific"
      onClick={this.panelToggle}
    >
      <Creatable
        isMulti
        errorMessage={this.props.islandPacificErrors.sapShipTo}
        helpMessage="must be 6 digits"
        isDisabled={this.props.userIsReadOnly}
        label="SAP Ship To"
        value={this.props.islandPacific.sapShipTo ? this.props.islandPacific.sapShipTo.map((value) => ({ label: value, value })) : []}
        width={6}
        onChange={(values) => this.props.updateIslandPacific('sapShipTo', values ? values.map((value) => value.value) : null)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        id="storePlatform"
        isDisabled={this.props.userIsReadOnly}
        label="Store Platform"
        options={['IPP_BY_ACCOUNT', 'NIKE_COM', 'NIKEID'].map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.storePlatform || ''}
        zIndex={10}
        onChange={(value) => this.props.updateIslandPacific('storePlatform', value)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="vatIdentifier"
          isDisabled={this.props.userIsReadOnly}
          label="VAT Identifier"
          value={this.props.islandPacific.vatIdentifier || ''}
          onChange={(value) => this.props.updateIslandPacific('vatIdentifier', value.target.value)}
        />
      </article>
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipTerritory}
        id="ipTerritory"
        isDisabled={this.props.userIsReadOnly}
        label="Territory"
        options={IPTerritory.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipTerritory || ''}
        zIndex={5}
        onChange={(value) => this.props.updateIslandPacific('ipTerritory', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipSubTerritory}
        id="ipSubTerritory"
        isDisabled={this.props.userIsReadOnly}
        label="Sub Territory"
        options={IPSubTerritory.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipSubTerritory || ''}
        zIndex={5}
        onChange={(value) => this.props.updateIslandPacific('ipSubTerritory', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.timeZone}
        id="timeZone"
        isDisabled={this.props.userIsReadOnly}
        label="Timezone"
        options={IPTimezones.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.timeZone || ''}
        zIndex={5}
        onChange={(value) => this.props.updateIslandPacific('timeZone', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.islandPacificErrors.ipKeyCity}
        id="ipkeyCity"
        isDisabled={this.props.userIsReadOnly}
        label="Key City"
        options={keyCityValues.map((value) => ({ label: value, value }))}
        value={this.props.islandPacific.ipKeyCity || ''}
        zIndex={5}
        onChange={(value) => this.props.updateIslandPacific('ipKeyCity', value)}
      />
    </CustomPanel>
  );
}

IslandPacific.propTypes = {
  islandPacific: PropTypes.shape().isRequired,
  islandPacificErrors: PropTypes.shape().isRequired,
  updateIslandPacific: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default IslandPacific;
