import PropTypes from 'prop-types';
import React from 'react';

const CSVErrors = ({ errors }) => errors.map((error) => (
  <p key={error} className="text-color-error ta-sm-l body-3">
    {error}
  </p>
));

CSVErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

CSVErrors.defaultProps = {
  errors: [],
};

export default CSVErrors;
