import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { csvPost } from '../../utils/service-calls/tax-identifiers';
import { taxIdentifierPermissions } from '../../utils/tab-permissions';
import { ButtonBlack, ButtonSubmit, PageTemplate } from '../reusable';

import CSVErrors from './CSVErrors';
import InputCSV from './InputCSV';
import PostSuccess from './PostSuccess';
import { prevalidateData } from './utils';

const TaxIdentifiers = ({ accessToken, location }) => {
  const [data, setData] = useState();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [ingestId, setIngestId] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [uploadErrors, setUploadErrors] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // Prevents errors when a user closes their file select prompt without choosing a file
    if (!file) { return; }
    setIngestId('');
    Papa.parse(file, {
      complete: (results) => {
        setData(Papa.unparse(results));
        setUploadErrors(prevalidateData(results));
        setFileUploaded(true);
        setSubmitError('');
      },
      error: () => {
        setUploadErrors(['There was an error parsing your file.']);
        setSubmitError('');
      },
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    csvPost(accessToken, data)
      .then(({ body }) => {
        setIngestId(body.id);
        return setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmitError(err.message);
      });
  };

  const exportExampleCSV = () => {
    const csv = document.createElement('a');
    csv.href = 'data:text/csv;charset=utf-8,'
      + `${encodeURI('No.,Tax Identifier')}\r\n`
      + '00000-001,1\r\n'
      + '000001-010,1';
    csv.target = '_blank';
    csv.download = 'tax-identifiers-example.csv';
    csv.click();
  };

  return (
    <PageTemplate
      auth={taxIdentifierPermissions}
      description="Upload Tax codes to the Product Catalog for NSP stores. "
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Tax+Identifiers+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
    )}
      page={(
        <section className="ncss-col-sm-6">
          <ButtonBlack label="Download Template CSV" onClick={exportExampleCSV} />
          <InputCSV handleFileUpload={handleFileUpload} />
          <CSVErrors errors={uploadErrors} />
          <ButtonSubmit
            isDisabled={!fileUploaded || !!uploadErrors.length}
            isLoading={loading}
            showErrorMessage={false}
            submitError={submitError}
            onClick={handleSubmit}
          />
          <PostSuccess requestId={ingestId} />
        </section>
    )}
      path={location.pathname}
      title="Tax Identifiers"
    />
  );
};

TaxIdentifiers.propTypes = {
  accessToken: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

TaxIdentifiers.defaultProps = {
  accessToken: '',
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(TaxIdentifiers);
