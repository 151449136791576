const ipDigitalStoreFields = ['id', 'storeNumber', 'name', 'country', 'currencyCode', 'creationDate',
  'modificationDate', 'storeChannel', 'region', 'partnerName', 'islandPacific: sapShipTo',
  'islandPacific: storePlatform', 'islandPacific: vatIdentifier', 'islandPacific: ipTerritory', 'islandPacific: ipSubTerritory',
  'islandPacific: timeZone', 'islandPacific: ipKeyCity'];

const baseDigitalStoreFields = ['id', 'storeNumber', 'name', 'country', 'currencyCode', 'creationDate',
  'modificationDate', 'storeChannel', 'region', 'partnerName'];

const ipWarehouseFields = ['id', 'company', 'name', 'warehouseNumber', 'type', 'description', 'facilityDimensions: totalArea',
  'facilityDimensions: totalStorageArea', 'facilityDimensions: measurementUnits', 'channels', 'email', 'phone', 'timezone',
  'coordinates: longitude', 'coordinates: latitude', 'address: address1', 'address: address2', 'address: address3',
  'address: area', 'address: city', 'address: state', 'address: postalCode', 'address: country', 'operationalDetails: openingDate',
  'operationalDetails: closingDate', 'resourceType', 'currencies', 'managerName: firstName',
  'managerName: altFirstName', 'managerName: middleInitial', 'managerName: middleName', 'managerName: lastName',
  'managerName: altLastName', 'managerName: givenName', 'region', 'islandPacificAttributes: warehouseNumber',
  'islandPacificAttributes: vatIdentifier', 'islandPacificAttributes: sapShipTo', 'islandPacificAttributes: partnerName',
  'islandPacificAttributes: ipChannel', 'islandPacificAttributes: ipTerritory', 'islandPacificAttributes: ipSubTerritory',
  'islandPacificAttributes: temporaryClosure: reason', 'islandPacificAttributes: temporaryClosure: fromDate',
  'islandPacificAttributes: temporaryClosure: toDate',
  'islandPacificAttributes: ipWarehouseType', 'islandPacificAttributes: keyCity'];

const baseWarehouseFields = ['id', 'company', 'name', 'warehouseNumber', 'type', 'description', 'facilityDimensions: totalArea',
  'facilityDimensions: totalStorageArea', 'facilityDimensions: measurementUnits', 'email', 'phone', 'timezone',
  'coordinates: longitude', 'coordinates: latitude', 'address: address1', 'address: address2', 'address: address3',
  'address: area', 'address: city', 'address: state', 'address: postalCode', 'address: country', 'operationalDetails: openingDate',
  'operationalDetails: closingDate', 'resourceType', 'currencies', 'managerName: firstName',
  'managerName: altFirstName', 'managerName: middleInitial', 'managerName: middleName', 'managerName: lastName',
  'managerName: altLastName', 'managerName: givenName', 'region'];

const allStoreFields = ['id', 'storeNumber', 'company', 'name', 'facilityType', 'timezone', 'locale',
  'costCenter', 'district', 'territory',
  'coordinates: longitude', 'coordinates: latitude', 'address: address1', 'address: address2', 'address: address3',
  'address: area', 'address: city', 'address: state', 'address: postalCode', 'address: country', 'address: iso2Country', 'address: county', 'localizations', 'creationDate',
  ' modificationDate', 'region', 'operationalDetails: openingDate', 'operationalDetails: closingDate', 'operationalDetails: closures',
  'operationalDetails: hoursOfOperation: regularHours: monday: open', 'operationalDetails: hoursOfOperation: regularHours: monday: close', 'operationalDetails: hoursOfOperation: regularHours: tuesday: open', 'operationalDetails: hoursOfOperation: regularHours: tuesday: close', 'operationalDetails: hoursOfOperation: regularHours: wednesday: open',
  'operationalDetails: hoursOfOperation: regularHours: wednesday: close', 'operationalDetails: hoursOfOperation: regularHours: thursday: open', 'operationalDetails: hoursOfOperation: regularHours: thursday: close', 'operationalDetails: hoursOfOperation: regularHours: friday: open', 'operationalDetails: hoursOfOperation: regularHours: friday: close',
  'operationalDetails: hoursOfOperation: regularHours: saturday: open', 'operationalDetails: hoursOfOperation: regularHours: saturday: close', 'operationalDetails: hoursOfOperation: regularHours: sunday: open', 'operationalDetails: hoursOfOperation: regularHours: sunday: close', ' operationalDetails: hoursOfOperation: specialHours', 'partnerName',
  'sellingArea: grossSize', 'sellingArea: netSize', 'sellingArea: unitOfMeasure',
  'businessConcept', 'storeConcept', 'description', 'email', 'phone', 'imageUrl', 'brand', 'twitterHandle',
  'testStore', 'storeStatus', 'currencies', 'slug', 'defaultLanguage', 'storeType',
  'links: self: ref', 'categories', 'resourceType', 'islandPacific: ipPlatform',
  'islandPacific: vatIdentifier', 'islandPacific: sapShipTo', 'islandPacific: ipChannel', 'islandPacific: sellingArea: gross', 'islandPacific: sellingArea: net',
  'islandPacific: ipTerritory', ' islandPacific: ipSubTerritory', 'islandPacific: temporaryClosure: reason', 'islandPacific: temporaryClosure: fromDate',
  'islandPacific: temporaryClosure: toDate', 'islandPacific: distributionCenter: code', 'islandPacific: distributionCenter :description',
  'islandPacific: distributionCenter: ipTimezone', 'islandPacific: emeaKeyCity', 'justEnough: justEnoughRegion: code', 'justEnough: justEnoughRegion: name', 'justEnough: justEnoughRegion: id', 'justEnough: justEnoughTimezone', 'justEnough: countryDescription', 'justEnough: storeSubConcept: id',
  'justEnough: storeSubConcept: code', 'justEnough: soldToAccountNumber', 'justEnough: shipToAccountNumber', 'justEnough: leadTime', 'justEnough: orderCategory', 'justEnough: dimension: code',
  'justEnough: dimension: description', 'justEnough: concept: description', 'justEnough: concept:code', 'justEnough: locationType: code', 'justEnough: locationType: description', 'justEnough: storeCapacity',
  'justEnough: apparelStoreCapacity', 'justEnough: footwearStoreCapacity', 'justEnough: equipmentStoreCapacity', 'justEnough: fax', 'justEnough: areaSize',
  'justEnough: sellAreaSize', 'justEnough: salesArea: description', 'justEnough: salesArea: code', 'justEnough: territory: description', 'justEnough: territory: code', 'justEnough: areaUnitOfMeasure',
  'shipTo', 'soldTo', 'alternateIds: hrLocationId', 'loginClassification'];

const ipStoreFields = ['id', 'storeNumber', 'company', 'name', 'facilityType', 'timezone', 'locale',
  'costCenter', 'district', 'territory',
  'coordinates: longitude', 'coordinates: latitude', 'address: address1', 'address2',
  'address3', 'area', 'address: city', 'address: state', 'address: postalCode', 'address: country', 'address: iso2Country',
  'address: county', 'localizations', 'creationDate', ' modificationDate', 'region', 'operationalDetails: openingDate',
  'operationalDetails: closingDate', 'operationalDetails: closures', 'operationalDetails: hoursOfOperation: regularHours: monday: open', 'operationalDetails: hoursOfOperation: regularHours: monday: close', 'operationalDetails: hoursOfOperation: regularHours: tuesday: open',
  'operationalDetails: hoursOfOperation: regularHours: tuesday: close', 'operationalDetails: hoursOfOperation: regularHours: wednesday: open', 'operationalDetails: hoursOfOperation: regularHours: wednesday: close', 'operationalDetails: hoursOfOperation: regularHours: thursday: open', 'operationalDetails: hoursOfOperation: regularHours: thursday: close',
  'operationalDetails: hoursOfOperation: regularHours: friday: open', 'operationalDetails: hoursOfOperation: regularHours: friday: close', 'operationalDetails: hoursOfOperation: regularHours: saturday: open', 'operationalDetails: hoursOfOperation: regularHours: saturday: close', 'operationalDetails: hoursOfOperation: regularHours: sunday: open',
  'operationalDetails: hoursOfOperation: regularHours: sunday: close', ' operationalDetails: hoursOfOperation: specialHours', 'partnerName',
  'sellingArea: grossSize', 'sellingArea: netSize', 'sellingArea: unitOfMeasure',
  'businessConcept', 'storeConcept', 'description', 'email',
  'phone', 'imageUrl', 'brand', 'twitterHandle', 'testStore', 'storeStatus', 'currencies',
  'slug', 'defaultLanguage', 'storeType', 'links: self: ref', 'categories', 'resourceType',
  'islandPacific: ipPlatform', 'islandPacific: vatIdentifier', 'islandPacific: sapShipTo',
  'islandPacific: ipChannel', 'islandPacific: sellingArea: gross', 'islandPacific: sellingArea: net',
  'islandPacific: ipTerritory', ' islandPacific: ipSubTerritory', 'islandPacific: temporaryClosure: reason',
  'islandPacific: temporaryClosure: fromDate', 'islandPacific: temporaryClosure: toDate', 'islandPacific: distributionCenter: code',
  'islandPacific: distributionCenter: description', 'islandPacific: distributionCenter: ipTimezone', 'islandPacific: emeaKeyCity',
  'shipTo', 'soldTo', 'alternateIds: hrLocationId', 'loginClassification'];

const jeStoreFields = ['id', 'storeNumber', 'company', 'name', 'facilityType', 'timezone', 'locale',
  'costCenter', 'district', 'territory',
  'coordinates: longitude', 'coordinates: latitude', 'address: address1', 'address2',
  'address3', 'area', 'address: city', 'address: state', 'address: postalCode', 'address: country', 'address: iso2Country',
  'address: county', 'localizations', 'creationDate', ' modificationDate', 'region', 'operationalDetails: openingDate',
  'operationalDetails: closingDate', 'operationalDetails: closures', 'operationalDetails: hoursOfOperation: regularHours: monday: open', 'operationalDetails: hoursOfOperation: regularHours: monday: close', 'operationalDetails: hoursOfOperation: regularHours: tuesday: open',
  'operationalDetails: hoursOfOperation: regularHours: tuesday: close', 'operationalDetails: hoursOfOperation: regularHours: wednesday: open', 'operationalDetails: hoursOfOperation: regularHours: wednesday: close', 'operationalDetails: hoursOfOperation: regularHours: thursday: open', 'operationalDetails: hoursOfOperation: regularHours: thursday: close',
  'operationalDetails: hoursOfOperation: regularHours: friday: open', 'operationalDetails: hoursOfOperation: regularHours: friday: close', 'operationalDetails: hoursOfOperation: regularHours: saturday: open', 'operationalDetails: hoursOfOperation: regularHours: saturday: close', 'operationalDetails: hoursOfOperation: regularHours: sunday: open',
  'operationalDetails: hoursOfOperation: regularHours: sunday: close', ' operationalDetails: hoursOfOperation: specialHours', 'partnerName',
  'sellingArea: grossSize', 'sellingArea: netSize', 'sellingArea: unitOfMeasure',
  'businessConcept', 'storeConcept', 'description', 'email', 'phone',
  'imageUrl', 'brand', 'twitterHandle', 'testStore', 'storeStatus', 'currencies',
  'slug', 'defaultLanguage', 'storeType', 'links: self: ref', 'categories', 'resourceType',
  'justEnough: justEnoughRegion: code', 'justEnough: justEnoughRegion: name', 'justEnough: justEnoughRegion: id', 'justEnough: justEnoughTimezone', 'justEnough: countryDescription', 'justEnough: storeSubConcept: id',
  'justEnough: storeSubConcept: code', 'justEnough: soldToAccountNumber', 'justEnough: shipToAccountNumber', 'justEnough: leadTime', 'justEnough: orderCategory', 'justEnough: dimension: code',
  'justEnough: dimension: description', 'justEnough: concept: description', 'justEnough: concept:code', 'justEnough: locationType: code', 'justEnough: locationType: description', 'justEnough: storeCapacity',
  'justEnough: apparelStoreCapacity', 'justEnough: footwearStoreCapacity', 'justEnough: equipmentStoreCapacity', 'justEnough: fax', 'justEnough: areaSize',
  'justEnough: sellAreaSize', 'justEnough: salesArea: description', 'justEnough: salesArea: code', 'justEnough: territory: description', 'justEnough: territory: code', 'justEnough: areaUnitOfMeasure',
  'shipTo', 'soldTo', 'alternateIds: hrLocationId', 'loginClassification'];

const baseStoreFields = ['id', 'storeNumber', 'company', 'name', 'facilityType', 'timezone', 'locale',
  'costCenter', 'district', 'territory',
  'coordinates: longitude', 'coordinates: latitude', 'address: address1', 'address2',
  'address3', 'area', 'address: city', 'address: state', 'address: postalCode', 'address: country', 'address: iso2Country', 'address: county',
  'localizations', 'creationDate', ' modificationDate', 'region', 'operationalDetails: openingDate',
  'operationalDetails: closingDate', 'operationalDetails: closures', 'operationalDetails: hoursOfOperation: regularHours: monday: open', 'operationalDetails: hoursOfOperation: regularHours: monday: close', 'operationalDetails: hoursOfOperation: regularHours: tuesday: open',
  'operationalDetails: hoursOfOperation: regularHours: tuesday: close', 'operationalDetails: hoursOfOperation: regularHours: wednesday: open', 'operationalDetails: hoursOfOperation: regularHours: wednesday: close', 'operationalDetails: hoursOfOperation: regularHours: thursday: open', 'operationalDetails: hoursOfOperation: regularHours: thursday: close',
  'operationalDetails: hoursOfOperation: regularHours: friday: open', 'operationalDetails: hoursOfOperation: regularHours: friday: close', 'operationalDetails: hoursOfOperation: regularHours: saturday: open', 'operationalDetails: hoursOfOperation: regularHours: saturday: close', 'operationalDetails: hoursOfOperation: regularHours: sunday: open',
  'operationalDetails: hoursOfOperation: regularHours: sunday: close', ' operationalDetails: hoursOfOperation: specialHours', 'partnerName',
  'sellingArea: grossSize', 'sellingArea: netSize', 'sellingArea: unitOfMeasure',
  'businessConcept', 'storeConcept', 'description', 'email', 'phone',
  'imageUrl', 'brand', 'twitterHandle', 'testStore', 'storeStatus', 'currencies',
  'slug', 'defaultLanguage', 'storeType', 'links: self: ref', 'categories', 'resourceType',
  'shipTo', 'soldTo', 'alternateIds: hrLocationId', 'loginClassification'];

export default {
  allStoreFields,
  baseDigitalStoreFields,
  baseStoreFields,
  baseWarehouseFields,
  ipDigitalStoreFields,
  ipStoreFields,
  ipWarehouseFields,
  jeStoreFields,
};
