import { TextArea, Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { announcementMessageTypes } from '../../../../utils/static/sls-property-values';
import {
  ButtonBlack, ButtonRed, DateInput, Select,
} from '../../../reusable';

const Announcements = (props) => (
  <article className="ncss-col-sm-12 mt2-sm">
    <header className="body-1">Announcements</header>
    <aside style={{ borderTop: '2px solid' }} />
    {props.announcements.map((announcement, announcementIndex) => (
      (announcement.expiryDate >= props.currentTime || announcement.newEdit) && (
        /* eslint-disable-next-line react/no-array-index-key */
        <section key={announcementIndex}>
          <article className="ncss-col-sm-6 va-sm-t ">
            <Input
              label="Headline"
              value={props.announcements[announcementIndex]?.headline || ''}
              onChange={({ target: { value } }) => props.updateAnnouncements(announcementIndex, announcement, value, true, 'headline')}
            />
            <Input
              label="Sub-headline"
              value={props.announcements[announcementIndex]?.subHeadline || ''}
              onChange={({ target: { value } }) => props.updateAnnouncements(announcementIndex, announcement, value, true, 'subHeadline')}
            />
            <Input
              label="URL"
              value={props.announcements[announcementIndex]?.url || ''}
              onChange={({ target: { value } }) => props.updateAnnouncements(announcementIndex, announcement, value, true, 'url')}
            />
          </article>
          <article className="ncss-col-sm-5 va-sm-t">
            <Select
              id="messageType"
              isDisabled={props.userIsReadOnly}
              label="Message Type"
              options={announcementMessageTypes.map((val) => ({ label: val, value: val }))}
              value={props.announcements[announcementIndex]?.messageType || 'ALERT'}
              onChange={(value) => props.updateAnnouncements(announcementIndex, announcement, value, true, 'messageType')}
            />
            <DateInput
              id={JSON.stringify(announcementIndex)}
              isDisabled={props.userIsReadOnly}
              label="Expiry Date"
              value={props.announcements[announcementIndex]?.expiryDate || ''}
              onChange={(value) => props.updateAnnouncements(announcementIndex, announcement, value, true, 'expiryDate')}
            />
            <Input
              label="URL Text"
              value={props.announcements[announcementIndex]?.urlText || ''}
              onChange={({ target: { value } }) => props.updateAnnouncements(announcementIndex, announcement, value, true, 'urlText')}
            />
          </article>
          <aside className="ncss-col-sm-1 ta-sm-r pt6-sm">
            <ButtonRed
              className=""
              isDisabled={props.userIsReadOnly}
              label={<i className="g72-x-thick" />}
              onClick={() => props.popAnnouncement(announcementIndex)}
            />
          </aside>
          <article className="ncss-col-sm-12 va-sm-t ta-sm-c mt2-sm">
            <TextArea
              isDisabled={props.userIsReadOnly}
              label="Message"
              value={props.announcements[announcementIndex]?.content || ''}
              onChange={({ target: { value } }) => props.updateAnnouncements(announcementIndex, announcement, value, true, 'content')}
            />
          </article>
          <aside className="mt2-sm" style={{ borderTop: '1px dashed' }} />
        </section>
      )))}
    <p className="text-color-error body-4 mt2-sm">{props.errors}</p>
    <ButtonBlack
      className="mb2-sm mt2-sm"
      isDisabled={props.userIsReadOnly}
      label={<i className="g72-plus" />}
      onClick={props.pushAnnouncement}
    />
    <aside style={{ borderTop: '2px solid' }} />
  </article>
);

Announcements.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentTime: PropTypes.string.isRequired,
  errors: PropTypes.string.isRequired,
  popAnnouncement: PropTypes.func.isRequired,
  pushAnnouncement: PropTypes.func.isRequired,
  updateAnnouncements: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Announcements;
