import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { ticketDelete } from '../../../actions/waitlines';
import { ButtonRed } from '../../reusable';

class TicketInfo extends React.Component {
  deleteTicket = () => {
    this.props.ticketDelete(this.props.accessToken, this.props.ticket.id, this.props.waitlineID);
  };

  render = () => (
    <article className="ticket-id border bg-primary-grey mt4-sm p2-sm">
      <p>
        Ticket ID: {this.props.ticket.id}<br />
        Created By: {this.props.ticket.name}<br />
        Created At: {this.props.ticket.createdAt}<br />
        Expiration: {this.props.ticket.expirationDate}<br />
        Status: {this.props.ticket.status}<br />
        Referrer: {this.props.ticket.referrerType}<br />
        Photo: <img alt="presentation" src={this.props.ticket.photoUrl} />
      </p>
      <ButtonRed
        className="m2-sm"
        isDisabled={this.props.status.isDeleting}
        label="Delete Ticket"
        onClick={this.deleteTicket}
      />
      {this.props.status.errors.map((err) => <p key={err.message} className="text-color-error"><i className="mr3-sm g72-x-circle" />{err.message}</p>)}
    </article>
  );
}

TicketInfo.defaultProps = {
  accessToken: undefined,
};

TicketInfo.propTypes = {
  accessToken: PropTypes.string,
  status: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isDeleting: PropTypes.bool,
  }).isRequired,
  ticket: PropTypes.shape({
    createdAt: PropTypes.string,
    expirationDate: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    referrerType: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  ticketDelete: PropTypes.func.isRequired,
  waitlineID: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  ticketDelete: (token, ticketId, waitlineId) => dispatch(ticketDelete(token, ticketId, waitlineId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketInfo);
