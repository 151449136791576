import { isSome } from '@nike/rcf-fp';

/**
 * Returns a function that checks if a node's type matches the given type.
 *
 * @param {string} nodeTypeToCompare - The node type to compare against.
 * @returns {function({nodeType: string}): boolean} - A function that takes an object with a nodeType property and returns a boolean indicating if it matches nodeTypeToCompare.
 */
export const isNodeType = (nodeTypeToCompare) => ({ nodeType }) => nodeType === nodeTypeToCompare;

/**
 * Returns a function that checks if a node's type and name match the given type and name.
 *
 * @param {string} nodeTypeToCompare - The node type to compare against.
 * @param {string} nameToCompare - The name to compare against.
 * @returns {function({nodeType: string, name: string}): boolean} - A function that takes an object with nodeType and name properties and returns a boolean indicating if they match nodeTypeToCompare and nameToCompare.
 */
export const isNodeTypeAndName = (nodeTypeToCompare, nameToCompare) => ({ nodeType, name }) => nodeType === nodeTypeToCompare && name === nameToCompare;

/**
 * Returns a function that checks if a node is a child of the given node.
 * @param {string} id - The id of the parent node.
 * @returns {function({parentId: string}): boolean} - A function that takes an object with a parentId property and returns a boolean indicating if it matches the id.
 */
export const isChildOf = (parent) => (possibleChild) => isSome(parent) && possibleChild?.parentId === parent?.id;
