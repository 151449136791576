import { sortBy } from 'lodash';
import moment from 'moment';

import { MSG_AUTH_EXPIRE } from '../constants/constants';

import createValidObjectOrArray from './sls-create-valid-object-or-array';
import converseNumberMap from './static/converse-number-map';
import { schema } from './static/sls-warehouses-schema';

const uuidv4 = require('uuid/v4');

const DOUBLE_DIGIT = 10;
const LAST_FIVE = -5;
const TWO_YEARS = 2;

export const getReloadStoreNumber = (storeNumber, company) => (company === 'Converse'
  ? (converseNumberMap[storeNumber.match(/\d+/g)] || storeNumber.match(/\d+/g)?.[0])
  : storeNumber);

// return date as a string in the format YYYY-MM-DD
export const getFormattedDate = (date) => {
  if (!(date instanceof Date)) {
    return '';
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < DOUBLE_DIGIT ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < DOUBLE_DIGIT ? `0${date.getDate()}` : date.getDate();
  return `${year}-${month}-${day}`;
};

export const getTimestamp = () => (new Date()).toISOString()
  .split(':')
  .join('')
  .split('.')
  .join('')
  .split('-')
  .join('');

export const simpleDate = (date) => {
  const dateCopy = new Date(date);
  const dateFormat = getFormattedDate(dateCopy);
  return `${dateFormat} 00:00:00`;
};

export const dateAddDay = (value) => {
  const tomorrow = new Date(Date.parse(value));
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString();
};

export const dateSubtractDay = (value) => {
  const tomorrow = new Date(Date.parse(value));
  tomorrow.setDate(tomorrow.getDate() - 1);
  return tomorrow.toISOString();
};

export const dateSubtractTwoYears = (value) => {
  const twoYearsAgo = new Date(Date.parse(value));
  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - TWO_YEARS);
  return twoYearsAgo.toISOString();
};

export const fromISODateToInput = (value) => (!Number.isNaN(Date.parse(value))
  ? moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD')
  : value);

export const fromInputDateToISO = (value) => (!Number.isNaN(Date.parse(value))
  ? `${moment(value, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
  : value);

export const handleGtin = (value) => (/^[0-9]{14}$/.test(value));

export const handleStyleColor = (value) => (/^[A-Za-z0-9]{5,6}-[A-Za-z0-9]{3}$/.test(value));

export const handleNumber = (value) => (/^[0-9]*$/.test(value));

// magic number from four years ago
export const padStoreNumber = (sN) => `0000${sN}`.substr(LAST_FIVE);

export const unPadStoreNumber = (sN) => ((/[A-Z]+/).test(sN) ? sN : `${Number(sN)}`);

// Returns a Store Alias in the form of CHN-3422
export const formatStoreAlias = (storeInformation) => `${storeInformation.address.country}-${storeInformation.storeNumber}`;

// eslint-disable-next-line no-nested-ternary
export const sortStoresByNumber = (stores) => stores.sort((a, b) => ((/[a-z]/i.test(a.storeNumber) || /[a-z]/i.test(b.storeNumber))
  ? (a.storeNumber > b.storeNumber ? 1 : -1)
  : (Number(a.storeNumber) > Number(b.storeNumber) ? 1 : -1)));

// sort warehouses and then make valid and generate various fields
export const restructureWarehouses = (warehouses) => warehouses
  .map((warehouse) => createValidObjectOrArray(schema, { ...warehouse }));

// Convert attributes from StoreOffering service for StoresV2 service
export const formatStoreOfferingsAttributes = (offerings) => offerings.map((offering) => {
  const newOffering = {
    ...offering,
    code: offering.id,
    id: uuidv4(),
    imageUrl: offering.serviceImage,
  };
  delete newOffering.serviceImage;
  return newOffering;
});

export const parseSLSandBrickworksErrors = (error, brickworks = false) => {
  try {
    /*
      The Nike Resource Client errors, and then the ROS-storeviews-lambda sends back an error message
      that is a concatenated string of multiple stringified JSON objects. This function will attempt
      to extract the error that we get from the storeviews service.
      And if brickworks = true (should always be the case for stores), this function will attempt
      to extract a Brickworks error string in the following format:
      "BrickworkClient::<create/update>Store Unexpected error responseBody=<stringified-JSON-object>"
     */
    if (error.message === MSG_AUTH_EXPIRE) return error;
    let err = { ...error, message: error.response.body.split('res body: ')[1] };
    if (brickworks && JSON.parse(err.message).message.includes('brickworkPayloads')) {
      err = { ...err, brickworks, message: JSON.parse(err.message).message.split(' brickworkPayloads')[0].replace(',', ' and ') };
    }
    return err;
  } catch {
    return error;
  }
};

export const sortOfferings = (offerings) => sortBy(offerings, ['name', 'id']);

/* Lead Time Utils */

export const HMStoISO = (hours, minutes, seconds) => {
  const [h, m, s] = [Number(hours), Number(minutes), Number(seconds)];
  if (h === 0 && m === 0 && s === 0) return '';
  return `PT${(h ? `${h}H` : '')}${(m ? `${m}M` : '')}${(s ? `${s}S` : '')}`;
};

export const ISOtoHMS = (ISO = null) => {
  const leadTimeData = { hours: '0', minutes: '0', seconds: '0' };
  if (ISO === '') {
    // if ISO was previously set to be an empty string, then the leadTime is implicitly PT0H0M0S
    return leadTimeData;
  }
  if (!ISO || !ISO.includes('PT')) {
    // if the ISO was never set (!ISO) or the ISO was set with an invalid value, then the leadTIme should be the default PT2H
    leadTimeData.hours = '2';
    return leadTimeData;
  }
  // now that we've dealt with the two special cases, just parse the data like normal
  let timeISO = ISO.split('PT')[1];
  if (timeISO.includes('H')) {
    [leadTimeData.hours, timeISO] = timeISO.split('H');
  }
  if (timeISO.includes('M')) {
    [leadTimeData.minutes, timeISO] = timeISO.split('M');
  }
  if (timeISO.includes('S')) {
    [leadTimeData.seconds, timeISO] = timeISO.split('S');
  }
  return leadTimeData;
};
