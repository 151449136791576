import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

const CSVInput = ({ handleFileUpload }) => (
  <Input
    accept=".csv"
    datatype="text"
    id="patchFile"
    label="File Upload"
    type="file"
    onChange={handleFileUpload}
  />
);

CSVInput.propTypes = {
  handleFileUpload: PropTypes.func.isRequired,
};

export default CSVInput;
