import { Button } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// to be used for all clear, rerouting, and unimportant buttons

const ButtonWhite = ({
  className, isDisabled, label, onAway, onClick, onHover, style,
}) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  return (
    <Button
      className={`ncss-btn-primary-dark bg-white ${className} ${buttonHovered ? 'text-color-primary-light' : 'text-color-primary-dark'}`}
      isDisabled={isDisabled}
      style={{
        flexDirection: 'row', outline: 'none', verticalAlign: 'middle', ...style,
      }}
      onClick={() => { setButtonHovered(false); onClick(); }}
      onFocus={() => setButtonHovered(true)}
      onMouseLeave={() => { setButtonHovered(false); onAway(); }}
      onMouseOver={() => { setButtonHovered(true); onHover(); }}
    >
      {label}
    </Button>
  );
};

ButtonWhite.defaultProps = {
  className: 'mt10-sm border',
  isDisabled: false,
  onAway: () => {},
  onHover: () => {},
  style: {},
};

ButtonWhite.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onAway: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func,
  style: PropTypes.shape(),
};

export default ButtonWhite;
