{
    "lastA11yAudit": "07/08/22",
    "name": "Menu Item",
    "description": "Menu Item is an item that goes inside of a menu.",
    "docs": "#/components/menus/MenuItem",
    "github": "https://github.com/nike-enterprise-design-system/eds/tree/main/src/components/MenuItem",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=248%3A1611",
    "tags": ["menuitem", "dropdown", "menu", "select"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
