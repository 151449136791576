import {
  Checkbox, Input, Loading,
} from '@nike/frame-component-library';
import { isSome } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  getOktaToken, getStoreOfferingById,
  postStoreOffering, putStoreOffering,
} from '../../../../utils/service-calls/sls';
import { autoTranslationLanguages, defaultOfferingImageUrl } from '../../../../utils/static/sls-property-values';
import { REQUIRED_FIELD } from '../../../../utils/validation/input-validation';
import {
  ButtonBlack,
  ButtonSubmit,
  Upload,
} from '../../../reusable';

import OfferingLocalizationsForm from './OfferingLocalizationsForm';

const DUPLICATE_STATUS_CODE = 409;
const MIN_IMAGE_WIDTH = 440;
const MIN_IMAGE_HEIGHT = 294;

// Validators: return empty string or string containing error message
const requiredField = (value) => (value ? '' : REQUIRED_FIELD);

// Field validation: each field contains array of validators.
const fieldValidation = {
  description: [requiredField],
  name: [requiredField],
  serviceImage: [requiredField],
};

const OfferingForm = ({
  username, userToken, isEditing, offering, closeOffering, fetchUpdatedOffering,
}) => {
  const offeringHasLocalizations = Object.prototype.hasOwnProperty.call(offering, 'localizations');
  const localizations = offeringHasLocalizations
    ? offering.localizations.map((localization) => {
      const language = autoTranslationLanguages.find((languageCode) => localization.language === languageCode.value);
      const isLanguage = isSome(language);
      return ({
        ...localization,
        languageLabel: isLanguage ? language.label : 'invalid language code',
      });
    })
    : [];

  // Form input
  const [formData, setFormData] = useState(isEditing
    ? offering
    : {
      description: '',
      externalUrl: '',
      externalUrlText: '',
      iconUrl: '',
      name: '',
      publicOffering: false,
      serviceImage: '',
    });
  // Form errors
  const [formErrors, setFormErrors] = useState(isEditing
    ? {}
    : {
      description: REQUIRED_FIELD,
      name: REQUIRED_FIELD,
      serviceImage: REQUIRED_FIELD,
    });
  const [isUploading, setIsUploading] = useState(false);
  const [isIconUploading, setIsIconUploading] = useState(false);
  const [uploadState, setUploadState] = useState({
    token: '',
    uploadError: '',
    uploadSuccess: false,
  });
  const [iconUploadState, setIconUploadState] = useState({
    uploadError: '',
    uploadSuccess: false,
  });
  const [savingOffering, setSavingOffering] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Used to retrieve token for file upload
  useEffect(() => {
    getOktaToken(userToken)
      .then(({ body }) => setUploadState((state) => ({ ...state, token: body })))
      .catch(({ message }) => setUploadState((state) => ({ ...state, uploadError: message })));
  }, [userToken]);

  useEffect(() => {
    const validateAllFields = () => {
      const errors = Object.entries(fieldValidation).reduce((acc, [fieldName, validators]) => {
        const error = validators.map((validator) => validator((formData[fieldName])));
        return { ...acc, [fieldName]: error.find((err) => err) || '' };
      }, {});
      setFormErrors(errors);
    };
    const validateDependentFields = () => {
      const validateDependentField = (fieldName, dependentFieldName, dependentFieldPrettyName) => setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: !formData[fieldName] && formData[dependentFieldName] ? `Required if ${dependentFieldPrettyName} is set` : '',
      }));
      validateDependentField('externalUrl', 'externalUrlText', 'External URL Link Text');
      validateDependentField('externalUrlText', 'externalUrl', 'External URL');
    };
    validateAllFields();
    validateDependentFields();
  }, [formData]);

  const setField = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const postOffering = (data) => postStoreOffering(userToken, data)
    .then(() => setSuccessMessage('Successfully added new offering!'))
    .catch((err) => {
      if (err.statusCode === DUPLICATE_STATUS_CODE) {
        setErrorMessage('Offering Name already exists, please choose a new name.');
      } else {
        setErrorMessage(`POST failed with error: ${err.message}`);
      }
    })
    .finally(() => setSavingOffering(false));

  const fetchThenUpdateOffering = async (updatedOffering) => getStoreOfferingById(userToken, encodeURIComponent(updatedOffering.id))
    .then(async (data) => {
      const offeringToPut = {
        ...updatedOffering,
        id: encodeURIComponent(data.body.id),
        localizations: data.body.localizations, // not updating localizations, only updating the meta data of store offering
      };
      await putStoreOffering(userToken, offeringToPut);
      return setSuccessMessage('Successfully saved changes to offering!');
    })
    .catch((err) => setErrorMessage(`Failed with error: ${err.message}`))
    .finally(() => setSavingOffering(false));

  const saveOffering = (data) => {
    setSavingOffering(true);
    setSuccessMessage('');
    setErrorMessage('');
    return isEditing ? fetchThenUpdateOffering(data) : postOffering(data);
  };

  return (
    <main className="ncss-row ta-sm-c">
      <section className="ta-sm-l bg-primary-grey pt2-sm pb2-sm prl2-sm border">
        <header className="u-rounded bg-black va-sm-m pt2-sm pb2-sm mb2-sm">
          <span className="headline-3 pl3-sm text-color-primary-light">
            {isEditing ? 'Edit Offering' : 'New Offering'}
          </span>
        </header>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={formErrors.name}
            label="Offering Name"
            value={formData.name}
            onChange={(e) => setField('name', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6 va-sm-t mt8-sm">
          <Checkbox
            id="publiclyAvailable"
            isChecked={formData.publicOffering}
            label="Publicly Available"
            onChange={({ target: { checked } }) => setField('publicOffering', checked)}
          />
        </article>
        <article className="ncss-col-sm-12 va-sm-t">
          <Input
            errorMessage={formErrors.description}
            label="Description"
            value={formData.description}
            onChange={(e) => setField('description', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={formErrors.serviceImage}
            label="Image Url"
            value={formData.serviceImage}
            onChange={(e) => setField('serviceImage', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6">
          <section className="ncss-col-sm-5">
            <section className="border ta-sm-c va-sm-t mt7-sm pt2-sm pb1-sm">
              <Upload
                minimumHeight={MIN_IMAGE_HEIGHT}
                minimumWidth={MIN_IMAGE_WIDTH}
                prompt="Drop new offering image here"
                setImageError={(error) => setUploadState({ ...uploadState, uploadError: error })}
                setImageUrl={(url) => {
                  setUploadState({ ...uploadState, uploadSuccess: true });
                  setField('serviceImage', url);
                }}
                setIsUploading={(status) => setIsUploading(status)}
                token={uploadState.token}
                username={username}
              />
            </section>
            {isUploading && <Loading />}
            {uploadState.uploadError && <p className="text-color-error body-4 ta-sm-c">{uploadState.uploadError}</p>}
            {uploadState.uploadSuccess && (
            <p className="text-color-success body-4 ta-sm-c">
              Successfully uploaded a new image, the Image Url field has been updated to direct to the new image. Don&apos;t forget to Submit your change at the bottom of the page.
            </p>
            )}
          </section>
          <section className="ncss-col-sm-1 va-sm-t pt9-sm">
            OR
          </section>
          <section className="ncss-col-sm-5 va-sm-t pt5-sm">
            <ButtonBlack
              className="mt2-sm"
              label="Use Default Image"
              onClick={() => setField('serviceImage', defaultOfferingImageUrl)}
            />
          </section>
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={formErrors.iconUrl}
            label="Icon Url"
            value={formData.iconUrl}
            onChange={(e) => setField('iconUrl', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6">
          <section className="border ta-sm-c va-sm-t mt7-sm pt2-sm pb1-sm">
            <Upload
              duplicateImageError="Icon already exists. Using existing icon URL for &apos;Icon Url&apos; field."
              prompt="Drop new offering icon here"
              setImageError={(error) => setIconUploadState({ ...iconUploadState, uploadError: error })}
              setImageUrl={(url) => {
                setIconUploadState({ ...iconUploadState, uploadSuccess: true });
                setField('iconUrl', url);
              }}
              setIsUploading={(status) => setIsIconUploading(status)}
              token={uploadState.token}
              username={username}
            />
          </section>
          {isIconUploading && <Loading />}
          {iconUploadState.uploadError && <p className="text-color-error body-4 ta-sm-c">{iconUploadState.uploadError}</p>}
          {iconUploadState.uploadSuccess && (
            <p className="text-color-success body-4 ta-sm-c">
              Successfully uploaded a new icon, the &apos;Icon Url&apos; field has been updated to direct to the new icon. Don&apos;t forget to Submit your change at the bottom of the page.
            </p>
          )}
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={formErrors.externalUrl}
            label="External URL"
            value={formData.externalUrl}
            onChange={(e) => setField('externalUrl', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={formErrors.externalUrlText}
            label="External URL Link Text"
            value={formData.externalUrlText}
            onChange={(e) => setField('externalUrlText', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-12 va-sm-t mt4-sm">
          <img
            alt=""
            src={formData.serviceImage ? formData.serviceImage : 'https://static.nike.com/a/images/f_auto/7cb8e7f1-ec92-4827-a7ac-a0d91fffae08/image.png'}
            style={{ margin: 'auto' }}
          />
        </article>
        <ButtonSubmit
          className="ncss-col-sm-12 full ta-sm-c mb10-sm"
          isDisabled={Object.values(formErrors).some((err) => !!err)}
          isLoading={savingOffering}
          onClick={() => saveOffering(formData)}
        />
        {isEditing && (
        <OfferingLocalizationsForm
          fetchUpdatedOffering={fetchUpdatedOffering}
          localizations={localizations}
          offering={offering}
          userToken={userToken}
        />
        )}
      </section>
      <footer className="ncss-row">
        {isEditing && (
          <ButtonBlack
            label="Back to Offerings"
            onClick={() => closeOffering()}
          />
        )}
      </footer>
      {successMessage && (
        <p className="text-color-success ta-sm-c body-2 mt2-sm">
          {successMessage}
        </p>
      )}
      {errorMessage && (
        <p className="text-color-error ta-sm-c body-2 mt2-sm">
          {errorMessage}
        </p>
      )}
    </main>
  );
};

OfferingForm.defaultProps = {
  closeOffering: () => null,
  fetchUpdatedOffering: () => {},
  isEditing: false,
  offering: {
    description: '',
    externalUrl: '',
    externalUrlText: '',
    name: '',
    publicOffering: false,
    serviceImage: '',
  },
};

OfferingForm.propTypes = {
  closeOffering: PropTypes.func,
  fetchUpdatedOffering: PropTypes.func,
  isEditing: PropTypes.bool,
  offering: PropTypes.shape({
    description: PropTypes.string.isRequired,
    externalUrl: PropTypes.string,
    externalUrlText: PropTypes.string,
    localizations: PropTypes.arrayOf(PropTypes.shape()),
    name: PropTypes.string.isRequired,
    publicOffering: PropTypes.bool.isRequired,
    serviceImage: PropTypes.string.isRequired,
  }),
  username: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default OfferingForm;
