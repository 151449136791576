/* eslint-disable fp/no-mutating-methods */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { loadState } from '../../utils/local-storage';
import { deletePrinter, getPrinterModels, getPrinters } from '../../utils/service-calls/printer-portal';
import {
  ButtonBlack, ButtonRed, StoreSelect, Table,
} from '../reusable';

import EditPrinter from './AddEditPrinter';

class ViewPrinters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: false,
      editing: false,
      fetched: false,
      fetching: false,
      printerModels: [],
      printers: [],
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      tableData: undefined,
    };
  }

  componentDidMount() {
    if (this.state.printerModels.length < 1) {
      getPrinterModels(this.props.accessToken)
        .then((res) => this.setState({ printerModels: res.body.objects }))
        .catch((err) => this.setState({ errorMessage: `Models failed: ${err.message}` }));
    }
  }

  editPrinter = (printerId) => {
    this.setState((prevState) => ({ editing: printerId, selectedPrinter: prevState.printers.find((printer) => printer.id === printerId) }));
  };

  editPrinterDone = (status) => {
    this.setState({ editing: false, selectedPrinter: null, status });
    this.getPrinters();
  };

  getColumns = (data) => {
    const columns = [];
    if (data.length) {
      const sample = data[0];
      Object.keys(sample).forEach((key) => {
        if (key !== '_id') {
          columns.push({ accessor: key, Header: key });
        }
      });
      columns.push({
        // eslint-disable-next-line no-underscore-dangle
        Cell: (d) => <ButtonBlack className="" label="Edit" onClick={() => this.editPrinter(d.original._id)} />,
        Header: 'Edit',
        width: 80,
      });
      columns.push({
        // eslint-disable-next-line no-underscore-dangle
        Cell: (d) => <ButtonRed className="" label={<i className="g72-x-thick" />} onClick={() => this.removePrinter(d.original._id)} />,
        Header: 'Delete',
        width: 80,
      });
    }
    return columns;
  };

  getPrinters = () => {
    this.setState({ fetching: true });
    getPrinters(this.props.accessToken, this.state.selectedStore.id)
      .then((res) => this.setState({
        deleted: false,
        editing: false,
        fetched: true,
        fetching: false,
        printers: Array.isArray(res.body.objects) ? res.body.objects : [],
      },
      () => this.translatePrintersToTable()))
      .catch((err) => this.setState({ errorMessage: err.message }));
  };

  removePrinter = (printerId) => deletePrinter(this.props.accessToken, printerId)
    .then(() => this.setState({ deleted: true, status: 'Printer successfully removed' }))
    .catch((err) => this.setState({ errorMessage: `Printer failed to delete: ${err.message}` }))
    .finally(() => this.setState((prevState) => ({
      printers: prevState.printers.filter((printer) => printer.id !== printerId),
    }),
    () => this.translatePrintersToTable()));

  selectCountry = (selectedCountry) => this.setState({ selectedCountry, selectedStore: null, tableData: null });

  selectStore = (selectedStore) => this.setState({ selectedStore, tableData: null });

  translatePrintersToTable = () => {
    const tableData = [];
    this.state.printers.forEach((printer) => {
      const printerModel = this.state.printerModels.find((printerModelObj) => (printerModelObj.id === printer.modelId));
      const model = printerModel ? printerModel.model : 'Not Supported';
      tableData.push({
        _id: printer.id,
        Address: printer.connectionInfo.lan.address,
        Host: printer.connectionInfo.lan?.tcp?.host || '',
        MAC: printer.connectionInfo.bluetooth?.mac || '',
        Model: model,
        Name: printer.name,
        Passcode: printer.connectionInfo.bluetooth?.passcode || '',
        Port: printer.connectionInfo.lan?.tcp?.port || '',
        Roles: printer.supportedRoles,
        Serial: printer.serialNumber,
      });
    });

    this.setState({ tableData });
  };

  render = () => (
    <article className="ncss-col-sm-10 ta-sm-l">
      <section className="ncss-col-sm-10 va-sm-b ta-sm-c mt4-sm">
        <StoreSelect
          selectCountry={this.selectCountry}
          selectStore={this.selectStore}
          selectedCountry={this.state.selectedCountry}
          selectedStore={this.state.selectedStore}
          storeviewsFields={['id']}
        />
      </section>
      {this.state.selectedStore?.id && (
        <section className="ncss-col-sm-2">
          <ButtonBlack
            isLoading={this.state.fetching}
            label="Get Printers"
            onClick={this.getPrinters}
          />
        </section>
      )}
      {this.state.tableData && (
        <Table
          columns={this.getColumns(this.state.tableData)}
          data={this.state.tableData}
          defaultPageSize={5}
          fetched={this.state.fetched}
        />
      )}
      {this.state.fetched && this.state.tableData && this.state.tableData.length > 0 && (
        <aside className="ncss-col-sm-12 ta-sm-c">
          {this.state.deleted && <p className="text-color-success body-2">{this.state.status}</p>}
          <p className="text-color-error body-2">{this.state.errorMessage}</p>
        </aside>
      )}
      {this.state.editing && (
        <section className="ncss-col-sm-12 mt2-sm mb2-sm ta-sm-c">
          <EditPrinter data={this.state.selectedPrinter} doneEdit={this.editPrinterDone} />
        </section>
      )}
    </article>
  );
}

ViewPrinters.defaultProps = {
  accessToken: undefined,
};

ViewPrinters.propTypes = {
  accessToken: PropTypes.string,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(ViewPrinters);
