import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel } from '../../../reusable';
import { ManagerName } from '../../generic';

import AltPhones from './AltPhones';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!properties.formErrors.email || !!properties.formErrors.phone
  || !!properties.formErrors.storeManager);

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Contact"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={!this.props.disableCmpFields && this.props.formErrors.email}
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Email"
          value={this.props.formData.email || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('email', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={!this.props.disableCmpFields && this.props.formErrors.phone}
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Primary Phone"
          value={this.props.formData.phone || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('phone', value)}
        />
      </article>
      <AltPhones
        altPhones={this.props.formData.altPhones || []}
        disableCmpFields={this.props.disableCmpFields}
        errors={this.props.formErrors.altPhones || ''}
        popAltPhone={(indexToPop) => {
          const { altPhones } = this.props.formData;
          altPhones.splice(indexToPop, 1);
          this.props.updateFormData('altPhones', altPhones);
        }}
        pushAltPhone={() => {
          const altPhones = this.props.formData.altPhones || [];
          const newAltPhone = { number: '', type: '' };
          altPhones.push(newAltPhone);
          this.props.updateFormData('altPhones', altPhones);
        }}
        updateAltPhone={(i, prop, newValue) => {
          const { altPhones } = this.props.formData;
          const origAltPhone = altPhones[i];
          const newAltPhone = { ...origAltPhone, [prop]: newValue };
          altPhones[i] = newAltPhone;
          this.props.updateFormData('altPhones', altPhones);
        }}
        userIsReadOnly={this.props.userIsReadOnly}
      />
      <section className="ncss-col-sm-12 mt2-sm mb2-sm">
        <ManagerName
          disableCmpFields={this.props.disableCmpFields}
          managerName={this.props.formData.storeManager || {}}
          managerNameErrors={this.props.formErrors.storeManager || ''}
          updateManagerName={this.props.updateManagerName}
          userIsReadOnly={this.props.userIsReadOnly}
        />
      </section>
    </CustomPanel>
  );
}

Contact.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  updateManagerName: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Contact;
