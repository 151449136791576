import React, { useContext } from 'react';

import { isChildOf } from '../../utils/hierarchy-utils';
import { StoreHierarchyContext } from '../StoreHierarchyContextProvider';

import HierarchyColumnTitle from './common/HierarchyColumnTitle';
import HierarchyNode from './common/HierarchyNode';

export default () => {
  const {
    selectedBrand = {},
    hierarchyNodes = [],
    selectedRegion,
    setActiveNodeId,
  } = useContext(StoreHierarchyContext);
  const regions = hierarchyNodes.filter(isChildOf(selectedBrand));
  return (
    <>
      <HierarchyColumnTitle title="Regions" />
      {
      regions.map((region) => (
        <HierarchyNode
          key={region.id}
          hierarchyNode={region}
          isDeletable={false}
          isEditable={false}
          isSelected={region.id === selectedRegion?.id}
          onClick={() => {
            setActiveNodeId(region.id);
           }}
        />
      ))
     }
    </>
  );
};
