import { createSelector } from 'reselect';

export const getAssociates = createSelector(
  (state) => (state && state.devicesReducer && state.devicesReducer.devices) || null,
  (state) => (state && state.waitlinesReducer && state.waitlinesReducer.waitlines) || null,
  (devices, waitlines) => {
    const associates = { devicesByWaitline: {}, waitlinesByDevice: {} };

    if (Array.isArray(waitlines) && Array.isArray(devices)) {
      waitlines.forEach((waitline) => {
        const device = devices.find((dev) => dev.name === waitline.alternateId);
        if (device) {
          associates.devicesByWaitline[waitline.id] = device;
          associates.waitlinesByDevice[device.id] = waitline;
        }
      });
    }

    return associates;
  },
);

export const getDevicesByWaitline = (state) => getAssociates(state).devicesByWaitline;
export const getWaitlinesByDevice = (state) => getAssociates(state).waitlinesByDevice;

export const getAssociatedDevice = (state, waitlineId) => getAssociates(state).devicesByWaitline[waitlineId] || null;
export const getAssociatedWaitline = (state, deviceId) => getAssociates(state).waitlinesByDevice[deviceId] || null;
