import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../reusable';

const ViewIngestReplayResults = ({
  setNavigation,
  transactionReplayResults,
}) => {
  const transactions = transactionReplayResults.map((result) => ({
    businessDate: result?.response?.transactionStatus?.businessDate,
    orderId: result?.response?.transactionStatus?.orderId,
    status: result?.status,
    storeNumber: result?.response?.transactionStatus?.storeNumber,
    transactionId: result?.response?.transactionStatus?.transactionId,
    transactionNumber: result?.response?.transactionStatus?.transactionNumber,
    workstationNumber: result?.response?.transactionStatus?.workstationNumber,
  }));

  return (
    <div className="ncss-container p4-sm">
      <div className="ncss-row">
        <div className="ncss-col-sm-1">
          <ButtonBlack
            className=""
            label="Back"
            onClick={() => setNavigation('view-ingest-status-results')}
          />
        </div>
        <div className="ncss-col-sm-1">
          <ButtonBlack
            className=""
            label="Done"
            onClick={() => setNavigation('check-ingest-status')}
          />
        </div>
        <div className="ncss-col-sm-10" />
      </div>
      <h3 className="headline-3">Transaction Reprocessing Complete</h3>

      <div className="ncss-row bg-primary-grey">
        {[
          ['Store', '2'],
          ['Business Date', '2'],
          ['Transaction', '2'],
          ['Order', '2'],
          ['Workstation', '2'],
          ['Status', '2'],
        ].map(([label, width]) => (
          <div key={`label-${label}`} className={`ncss-col-sm-${width}`}>
            {label}
          </div>
        ))}
      </div>

      {transactions.map((transaction) => {
        const {
          transactionId, businessDate, storeNumber, transactionNumber, workstationNumber, orderId, status,
        } = transaction;
        return (
          <div key={`ingest-status-result-row-${transactionId}`} className="ncss-row">
            <div className="ncss-col-sm-2">
              {storeNumber}
            </div>
            <div className="ncss-col-sm-2">
              {businessDate}
            </div>
            <div className="ncss-col-sm-2">
              {transactionNumber}
            </div>
            <div className="ncss-col-sm-2">
              {orderId}
            </div>
            <div className="ncss-col-sm-2">
              {workstationNumber}
            </div>
            <div className="ncss-col-sm-2">
              {status}
            </div>
          </div>
        );
      })}

    </div>
  );
};

ViewIngestReplayResults.propTypes = {
  setNavigation: PropTypes.func.isRequired,
  transactionReplayResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ViewIngestReplayResults;
