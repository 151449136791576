import { Button } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Tabs, Tab, TabList, TabPanel,
} from 'react-tabs';

import OfferingForm from './OfferingForm';
import ViewOffering from './ViewOffering';

const Offerings = ({
  closeOrOpenOfferingForm, username, userToken,
}) => (
  <article className="ncss-col-sm-12 full ta-sm-c">
    <Button
      className="ncss-btn-primary-dark bg-accent mb10-sm border"
      style={{ flexDirection: 'row', outline: 'none', verticalAlign: 'middle' }}
      onClick={closeOrOpenOfferingForm}
    >
      Back to stores
    </Button>
    <Tabs>
      <TabList>
        <Tab>View Offering</Tab>
        <Tab>New Offering</Tab>
      </TabList>
      <TabPanel>
        <ViewOffering
          userToken={userToken}
          username={username}
        />
      </TabPanel>
      <TabPanel>
        <OfferingForm
          userToken={userToken}
          username={username}
        />
      </TabPanel>
    </Tabs>
  </article>
);

Offerings.propTypes = {
  closeOrOpenOfferingForm: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default Offerings;
