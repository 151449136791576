export const authIsCurrent = (expiresAt) => ((expiresAt - Math.floor(Date.now() / 1000)) >= 1);

export const isCurrent = () => {
  try {
    return (authIsCurrent(localStorage.getItem('oktaExpiration')));
  } catch (err) {
    return false;
  }
};

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    return (serializedState && isCurrent()) ? JSON.parse(serializedState) : undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
    if (key === 'auth') {
      localStorage.setItem('oktaExpiration', state.expiresAt);
    }
    return true;
  } catch {
    return false;
  }
};
