import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../reusable';

const ViewIngestStatusDetails = ({ ingestStatusDetails, setNavigation }) => {
  const {
    transactionId,
    businessDate,
    transactionType,
    countryCode,
    storeNumber,
    transactionNumber,
    workstationNumber,
    orderId,
    spans,
  } = ingestStatusDetails;

  const operations = [...spans].sort(({ startDate: lStartDate = '' }, { startDate: rStartDate = '' }) => lStartDate.localeCompare(rStartDate));

  const dataRow = (label, value) => (
    <div className="ncss-row">
      <div className="ncss-col-sm-1 ta-sm-r bg-primary-grey">
        {label}
      </div>
      <div className="ncss-col-sm-3 ta-sm-l">
        {value}
      </div>
    </div>
  );

  return (
    <div className="ncss-container p4-sm">
      <div className="ncss-row">
        <div className="ncss-col-sm-1">
          <ButtonBlack
            className=""
            label="Back"
            onClick={() => setNavigation('view-ingest-status-results')}
          />
        </div>
        <div className="ncss-col-sm-11" />
      </div>
      <h3 className="headline-3">Transaction Details</h3>

      {[
        ['ID', transactionId],
        ['Country', countryCode],
        ['Store', storeNumber],
        ['Business Date', businessDate],
        ['Type', transactionType],
        ['Transaction', transactionNumber],
        ['Order', orderId],
        ['Workstation', workstationNumber],
      ].map(([label, value]) => dataRow(label, value))}

      <h3 className="headline-3">Transaction Operations</h3>

      <div className="ncss-row">
        <div className="ncss-col-sm-2 bg-primary-grey">
          Type
        </div>
        <div className="ncss-col-sm-2 bg-primary-grey">
          Name
        </div>
        <div className="ncss-col-sm-2 bg-primary-grey">
          Status
        </div>
        <div className="ncss-col-sm-3 bg-primary-grey">
          Start Date
        </div>
        <div className="ncss-col-sm-3 bg-primary-grey">
          End Date
        </div>
      </div>

      { operations.map(({
        type,
        name,
        status,
        startDate,
        endDate,
        id,
      }) => (
        <div key={`ingest-status-details-operation-${id}`} className="ncss-row">
          <div className="ncss-col-sm-2">
            {type}
          </div>
          <div className="ncss-col-sm-2">
            {name}
          </div>
          <div className="ncss-col-sm-2">
            {status}
          </div>
          <div className="ncss-col-sm-3">
            {startDate}
          </div>
          <div className="ncss-col-sm-3">
            {endDate}
          </div>
        </div>
      ))}
    </div>
  );
};

ViewIngestStatusDetails.propTypes = {
  ingestStatusDetails: PropTypes.shape().isRequired,
  setNavigation: PropTypes.func.isRequired,
};

export default ViewIngestStatusDetails;
