import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack, ButtonRed } from '../../../reusable';

const AltPhones = (props) => (
  <section className="ncss-col-sm-12">
    <header className="headline-5 pt2-sm">Alternate Phones</header>
    {props.altPhones.map((altPhone, altPhoneIndex) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <article key={altPhoneIndex} className="ncss-row">
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            isDisabled={props.userIsReadOnly || props.disableCmpFields}
            label="Type"
            value={altPhone.type || ''}
            onChange={({ target: { value } }) => props.updateAltPhone(altPhoneIndex, 'type', value)}
          />
        </article>
        <article className="ncss-col-sm-5 va-sm-t">
          <Input
            isDisabled={props.userIsReadOnly || props.disableCmpFields}
            label="Alternate Phone"
            value={altPhone.number || ''}
            onChange={({ target: { value } }) => props.updateAltPhone(altPhoneIndex, 'number', value)}
          />
        </article>
        <article className="ncss-col-sm-1 va-sm-t">
          <ButtonRed
            className="mt6-sm"
            isDisabled={props.userIsReadOnly}
            label={<i className="g72-x-thick" />}
            onClick={() => props.popAltPhone(altPhoneIndex)}
          />
        </article>
      </article>
    ))}
    <p className="text-color-error body-4 mt2-sm">{props.errors}</p>
    <ButtonBlack
      className="mt2-sm"
      isDisabled={props.userIsReadOnly}
      label="Add Alternate Phones"
      onClick={() => props.pushAltPhone()}
    />
  </section>
);

AltPhones.propTypes = {
  altPhones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  errors: PropTypes.string.isRequired,
  popAltPhone: PropTypes.func.isRequired,
  pushAltPhone: PropTypes.func.isRequired,
  updateAltPhone: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default AltPhones;
