/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import {
 copy, Default, group, match
} from '@nike/rcf-fp';

import { frameUser } from '../../../../utils/tab-permissions';
import pause from '../../utils/pause';

import mockHierarchy from './mock-hierarchy';

   /**
    * @typedef {Object} NewHierarchyNode
    * @property {string} nodeType
    * @property {string} name
    * @property {string} [parentId]
    */

   /**
    * @typedef {Object} PersistedHierarchyNode
    * @property {string} id
    * @property {string} nodeType
    * @property {string} name
    * @property {string} [parentId]
    */

   const mockHierarchyDatabase = copy(mockHierarchy).objects;

   const sortMockHierarchyDatabase = () => mockHierarchyDatabase.sort((a, b) => a.name.localeCompare(b.name));

   const uuidv4 = () => '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) => (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16));

   const filterBrands = (groups) => ({ name: brandName }) => match()(
     [() => groups.has('App.StoreHierarchy.GlobalAdmin'), true],
     [() => brandName === 'NIKE' && Array.from(groups).some((aGroup) => aGroup.includes('Nike')), true],
     [() => brandName === 'CONVERSE' && Array.from(groups).some((aGroup) => aGroup.includes('Converse')), true],
     [Default, false]
   );

   const filterRegions = (brands, groups) => ({ name: regionName, parentId: brandId }) => match()(
     [() => groups.has('App.StoreHierarchy.GlobalAdmin'), true],
     [() => regionName === 'ASIA_PACIFIC_LATIN_AMERICA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'NIKE'
       && Array.from(groups).some((aGroup) => aGroup.includes('NikeAPLA')), true],
     [() => regionName === 'NORTH_AMERICA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'NIKE'
       && Array.from(groups).some((aGroup) => aGroup.includes('NikeNA')), true],
     [() => regionName === 'EUROPE_MIDDLE_EAST_AFRICA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'NIKE'
       && Array.from(groups).some((aGroup) => aGroup.includes('NikeEMEA')), true],
     [() => regionName === 'GREATER_CHINA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'NIKE'
       && Array.from(groups).some((aGroup) => aGroup.includes('NikeGC')), true],
     [() => regionName === 'ASIA_PACIFIC_LATIN_AMERICA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'CONVERSE'
       && Array.from(groups).some((aGroup) => aGroup.includes('ConverseAPLA')), true],
     [() => regionName === 'NORTH_AMERICA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'CONVERSE'
       && Array.from(groups).some((aGroup) => aGroup.includes('ConverseNA')), true],
     [() => regionName === 'EUROPE_MIDDLE_EAST_AFRICA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'CONVERSE'
       && Array.from(groups).some((aGroup) => aGroup.includes('ConverseEMEA')), true],
     [() => regionName === 'GREATER_CHINA'
       && brands.some(({ id }) => id === brandId)
       && brands.find(({ id }) => id === brandId).name === 'CONVERSE'
       && Array.from(groups).some((aGroup) => aGroup.includes('ConverseGC')), true],
     [Default, false]
   );

   /**
    * @returns {Promise<PersistedHierarchyNode[]>}
    */
   export const getHierarchy = async () => {
     await pause();
     const {
       BRAND: allBbrands,
       REGION: allRegions,
       TERRITORY: allTerritories,
       DISTRICT: allDistricts,
     } = group(mockHierarchyDatabase, (node) => node.nodeType);
     const groups = new Set(frameUser);
     const sortByName = ({ name: l }, { name: r }) => l.localeCompare(r);
     const brands = allBbrands.filter(filterBrands(groups)).sort(sortByName);
     const regions = allRegions.filter(filterRegions(brands, groups)).sort(sortByName);
     const territories = allTerritories.filter(({ parentId }) => regions.some(({ id }) => id === parentId)).sort(sortByName);
     const districts = allDistricts.filter(({ parentId }) => territories.some(({ id }) => id === parentId)).sort(sortByName);
     return [...brands, ...regions, ...territories, ...districts];
   };

   /**
    * @param {NewHierarchyNode} newNode
    * @returns {Promise<PersistedHierarchyNode[]>}
    */
   export const createNode = async (newNode) => {
     const uuid = uuidv4();
     const newNodeWithId = { ...newNode, id: uuid };
     await pause();
     mockHierarchyDatabase.push(newNodeWithId);
     sortMockHierarchyDatabase();
     return mockHierarchyDatabase;
   };

   /**
    * @param {PersistedHierarchyNode} updatedNode
    * @returns {Promise<PersistedHierarchyNode[]>}
    */
   export const updateNode = async (updatedNode) => {
     const index = mockHierarchyDatabase.findIndex((node) => node.id === updatedNode.id);
     await pause();
     mockHierarchyDatabase[index] = updatedNode;
     sortMockHierarchyDatabase();
     return mockHierarchyDatabase;
   };

   /**
    * @param {PersistedHierarchyNode} nodeToDelete
    * @returns {Promise<PersistedHierarchyNode[]>}
    */
   export const deleteNode = async (nodeToDelete) => {
     const index = mockHierarchyDatabase.findIndex((node) => node.id === nodeToDelete.id);
     await pause();
     mockHierarchyDatabase.splice(index, 1);
     sortMockHierarchyDatabase();
     return mockHierarchyDatabase;
   };
