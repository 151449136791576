import PropTypes from 'prop-types';
import React from 'react';
import './Overlay.css';

import {
  ButtonBlack, Table
 } from '../../reusable';

const Overlay = ({
 isVisible, data, loading, onClose
}) => {
  if (!isVisible) return null;

  return (
    <>
      {loading ? (
        <div className="overlay">
          <div className="overlay-content-loading">
            <h2>Loading...</h2>
          </div>
        </div>
    ) : (
      <div className="overlay">
        <div className="overlay-content">
          <h1 className="headline-4">Key History</h1>
          <Table
            columns={[
                {
                  accessor: (value) => value.scopeType,
                  Header: 'Scope Type',
                  id: 'scopeType',
                },
                {
                  accessor: (value) => value.modifiedOn,
                  Header: 'Modified On',
                 id: 'modifiedOn',
                },
                {
                 accessor: (value) => value.modifiedBy,
                 Header: 'Modified By',
                  id: 'modifiedBy',
                },
                {
                  accessor: (value) => value.oldValue,
                  Header: 'Old Value',
                  id: 'oldValue',
                },
                {
                  accessor: (value) => value.newValue,
                  Header: 'New Value',
                  id: 'newValue',
                }
              ]}
            data={data}
            defaultPageSize={5}
            showPagination={false}
          />
          <ButtonBlack label="Close" onClick={onClose} />
        </div>
      </div>
    )}
    </>
  );
};
Overlay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    modifiedBy: PropTypes.string.isRequired,
    modifiedOn: PropTypes.string.isRequired,
    newValue: PropTypes.string.isRequired,
    oldValue: PropTypes.string.isRequired,
    scopeType: PropTypes.string.isRequired,
  })).isRequired,
  isVisible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Overlay;
