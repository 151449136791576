import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ButtonBlack } from '../../reusable';

const BackLink = () => {
  const history = useHistory();
  const location = useLocation();
  const [buttonText, setButtonText] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    const path = location.pathname;
    let text = 'Back to QR Code Generator';
    let toLink = '/qrcodegenerator';

    switch (path) {
      case '/qrcodegenerator/createcampaign':
        text = 'Back to Campaigns';
        toLink = '/qrcodegenerator/managecampaign';
        break;
      case '/qrcodegenerator/managecampaign':
        text = 'Back to QR Code Generator';
        toLink = '/qrcodegenerator';
        break;
      case '/qrcodegenerator/history':
        if (location.state && location.state.from !== 'qrgenerator') {
          text = 'Back to Campaigns';
          toLink = '/qrcodegenerator/managecampaign';
        }
        break;
      default:
        return;
    }
    setButtonText(text);
    setLink(toLink);
  }, [location]);

  const backLinkHandler = () => {
    history.push(link);
  };

  return (
    <section className="ta-sm-c">
      <ButtonBlack
        label={buttonText}
        onClick={backLinkHandler}
      />
    </section>
  );
};

export default BackLink;
