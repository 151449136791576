import { Checkbox, Input } from '@nike/frame-component-library';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import qrCodeGenerator from '../../assets/images';
import { loadState } from '../../utils/local-storage';
import {
  getCampaignsList, logQRResult, saveDownLoadHistory, createShortLinkFromBranch,
} from '../../utils/service-calls/qrcodegenerator';
import { rosPermissions, permissionsToGroups } from '../../utils/tab-permissions';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonSubmit, ButtonWhite, PageTemplate, Select, StoreSelect,
} from '../reusable';

import BackLink from './back-link/BackLink';
import BulkDownload from './bulk-download/BulkDownload';
import getHeaderInfo from './common';
import { NIKE_APP_URL, SHOP_THE_LOOK_URL, CONFLUENCE_HELP_LINK } from './common/constants';
import FinalShortlinks from './final-shortlinks/FinalShortlinks';
import QRCodeSubRoutes from './qr-code-sub-routes/QRCodeSubRoutes';

const useCasesOptions = [
  { label: 'Shop the Look', value: 'Shop the Look' },
  { label: 'Nike App', value: 'Nike App' },
];
class QRCodeGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branchCallFailed: false,
      branchUrl: '',
      bulkDownloadFormError: false,
      campaignId: '',
      campaignsCallFailed: false,
      campaignsCallPending: true,
      customCampaign: '',
      customStore: false,
      downloaded: false,
      downloading: false,
      errorMessage: '',
      formErrors: {
        customStore: '', // to ensure the next three update when this updates
        selectedCountry: '',
        selectedStore: REQUIRED_FIELD,
        sequenceEnd: REQUIRED_FIELD,
        sequenceStart: REQUIRED_FIELD,
        storeNumber: REQUIRED_FIELD,
      },
      qrCodeTotal: 0,
      qrData: [],
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      selectedUseCase: '',
      storeNumber: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.allCampaigns = [];
  }

  componentDidMount() {
    this.getCampaigns();
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === '/qrcodegenerator') {
      this.getCampaigns();
      this.clearForm();
    }
  }

  clearForm = () => this.setState({
    branchCallFailed: false,
    campaignId: '',
    campaignsCallFailed: false,
    customCampaign: '',
    customStore: false,
    downloaded: false,
    downloading: false,
    qrCodeTotal: 0,
    qrData: [],
    selectedCountry: loadState('auth')?.country,
    selectedStore: null,
    selectedUseCase: '',
    storeNumber: '',
  });

  generateBranchApi = (qrDetail) => {
    const payload = {
      deeplink_path: qrDetail.url,
      pcn: this.state.selectedUseCase,
      storeId: this.state.selectedStore.id,
    };

    createShortLinkFromBranch(this.props.accessToken, payload)
      .then((response) => {
        this.setState({ urlFromBranch: response.body.short_link });
        const qrDataCopy = [];
        const qrDetails = { creativeId: qrDetail.creativeId, fileName: qrDetail.fileName, url: response.body.short_link };
        qrDataCopy.push(qrDetails);
        this.setState({ qrData: qrDataCopy }, () => this.generateZipFiles());
        return response;
      }).catch((error) => {
        this.setState({ branchCallFailed: true, branchCallFailedReason: error });
      });
  }

  generateQRCode = () => {
    this.setState({ downloaded: false, downloading: true });
    let { sequenceStart } = this.state;
    const { sequenceEnd, storeNumber, selectedUseCase } = this.state;
    this.setState({ qrCodeTotal: sequenceEnd - sequenceStart + 1 || 1 });
    const qrDataCopy = [];
    let adSetId = storeNumber;
    let fileName = `QR_${storeNumber}`;
    let url;

    for (; sequenceStart <= sequenceEnd; sequenceStart++) {
      if (selectedUseCase === 'Shop the Look') {
        const padNumber = 3;
        const sequencePadStart = sequenceStart
          .toString()
          .padStart(padNumber, '0');
        adSetId = `${storeNumber}-${sequencePadStart}`;
        url = `${SHOP_THE_LOOK_URL}${storeNumber}&~ad_id=${adSetId}&pcid=${adSetId}`;
        fileName = `QR_${storeNumber}_${adSetId}`;
      } else if (selectedUseCase === 'Nike App') {
        url = `${NIKE_APP_URL}${adSetId}&pcid=${adSetId}`;
      } else if (this.state.customCampaign) {
        const { campaignUrlTemplate, webUrl } = this.state.customCampaign;
        const storeId = this.state.customStore ? this.state.storeNumber : this.state.selectedStore.id;
        url = campaignUrlTemplate.replaceAll('<storeId>', storeId);
        url = url.replaceAll('<storeNo>', this.state.storeNumber);
        this.setState({ urlToBranch: url });
        const qrDetails = {
          creativeId: adSetId, fileName, url, webUrl,
        };
        this.generateBranchApi(qrDetails);
      }
      // As shop the look campaign can have multiple QR codes generate all of them at once.
      if (selectedUseCase === 'Shop the Look' || selectedUseCase === 'Nike App') {
        const qrDetails = { creativeId: adSetId, fileName, url };
        qrDataCopy.push(qrDetails);
      }
    }
    // Generate Qr codes only for shop the look and nike app.
    if (selectedUseCase === 'Shop the Look' || selectedUseCase === 'Nike App') {
      this.setState({ qrData: qrDataCopy }, () => this.generateZipFiles());
    }
  };

  generateZipFiles = async () => {
    const zip = new JSZip();

    await Promise.all(this.state.qrData.map(async (data) => {
      const pngExportFileName = `${data.fileName}.png`;
      const svgExportFileName = `${data.fileName}.svg`;
      const pngQRCanvas = await QRCode.toCanvas(data.url, { width: 250 });
      const pngQRBlob = await new Promise((resolve) => pngQRCanvas.toBlob(resolve));
      const svgQR = await QRCode.toString(data.url, { type: 'svg' });
      zip.folder('PNGQRCodes').file(pngExportFileName, pngQRBlob);
      zip.folder('SVGQRCodes').file(svgExportFileName, svgQR);
    }));

    // generate final zip
    zip.generateAsync({ type: 'blob' })
      .then((blob) => {
        this.setState({ downloaded: true, downloading: false });
        logQRResult(this.props.accessToken, {
          qrCodeTotal: this.state.qrCodeTotal,
          selectedUseCase: this.state.selectedUseCase,
          storeNumber: this.state.storeNumber,
        });
        this.saveDataToHistory();
        return saveAs(blob, `${this.state.selectedUseCase.replace(/\s/g, '')}QRCodes.zip`);
      })
      .catch((err) => {
        logQRResult(this.props.accessToken, {
          err,
          qrCodeTotal: this.state.qrCodeTotal,
          selectedUseCase: this.state.selectedUseCase,
          storeNumber: this.state.storeNumber,
        });
        this.setState({ downloaded: true, downloading: false, errorMessage: 'There was an issue with your QR Creation' });
      });
  };

  getCampaignDescription = () => {
    let campaignDescription = '';
    if (this.state.selectedUseCase === 'Shop the Look') {
      campaignDescription = <p className="body-3 pl3-sm">1) Please select the store to receive Shop The Look and click Generate & Download.</p>;
    } else if (this.state.selectedUseCase === 'Nike App') {
      campaignDescription = <p className="body-3 pl3-sm">1) Please select the store to receive Shop The Look and click Generate & Download.</p>;
    } else {
      campaignDescription = <p className="body-3 pl3-sm">1) {this.state.campaignDescription} </p>;
    }
    return campaignDescription;
  }

  getCampaigns = () => {
    const accestoken = loadState('auth').accessToken;
    getCampaignsList(accestoken)
      .then((data) => {
        const items = data.body && data.body.Items ? data.body && data.body.Items : [];
        // Filtering the Active Records
        const activeCampaigns = items.filter((key) => key.campaignStatus !== 'INACTIVE');
        // Sorting the records based on date and timestamp
        const sortedCampaigns = activeCampaigns.sort((campaignA, campaignB) => {
          const date1 = moment(campaignA.createdOn);
          const date2 = moment(campaignB.createdOn);
          return moment(date2).diff(date1);
        });

        const mappedData = sortedCampaigns.map((item) => ({
          label: item.campaignName, value: item.campaignName, ...item,
        }));

        this.allCampaigns = [...mappedData, ...useCasesOptions];
        this.setState({ campaignsCallFailed: false, campaignsCallPending: false, useCasesOptions: this.allCampaigns });
        return this.allCampaigns;
      })
      .catch(() => {
        this.setState({ campaignsCallFailed: true, campaignsCallPending: false });
      });
  }

  getHistoryPayload = () => {
    const payload = {
      campaignId: this.state.campaignId,
      customStore: this.state.customStore ? this.state.customStore : '',
      email: this.props.email,
      qrCodeTotal: this.state.qrCodeTotal,
      selectedUseCase: this.state.selectedUseCase,
      storeId: this.state.storeId ? this.state.storeId : '',
      storeNumber: this.state.storeNumber,
      urlFromBranch: this.state.urlFromBranch,
      urlToBranch: this.state.urlToBranch,
      userName: this.props.userName,
    };
    return payload;
  }

  getSelectedUseCaseDetails = () => {
    let selectedUseCaseDetails = '';
    if (this.state.selectedUseCase === 'Shop the Look') {
      selectedUseCaseDetails = <p className="body-3">This process will create upto <span className="text-color-accent">100 unique QR codes</span> for Shop The Look signage.</p>;
    } else if (this.state.selectedUseCase === 'Nike App') {
      selectedUseCaseDetails = <p className="body-3">This process will create <span className="text-color-accent">one QR code</span> that will take the user to the App Store to download the Nike App.</p>;
    } else {
      selectedUseCaseDetails = <p className="body-3 pl3-sm">This process will create <span className="text-color-accent">one QR code</span></p>;
    }
    return selectedUseCaseDetails;
  }

  getShopTheLookHistoryData = () => {
    const payload = {
      campaignId: this.state.campaignId,
      customStore: this.state.customStore ? this.state.customStore : '',
      email: this.props.email,
      qrCodeTotal: this.state.qrCodeTotal,
      selectedUseCase: this.state.selectedUseCase,
      seqEndNumber: this.state.sequenceEnd,
      seqStartNumber: this.state.sequenceStart,
      storeNumber: this.state.storeNumber,
      userName: this.props.userName,
    };
    return payload;
  }

  handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    }, () => {
      this.handleInputValidate(event);
    });
  }

  handleInputValidate = (event) => {
    this.setState({ bulkDownloadFormError: false });
    const qrLimit = 99;
    const { sequenceStart, sequenceEnd } = this.state;
    if (sequenceStart && sequenceEnd && sequenceEnd - sequenceStart > 0 && sequenceEnd - sequenceStart <= qrLimit) {
      if (event.target.checkValidity()) {
        this.setState({ bulkDownloadFormError: true });
      }
    }
  }

  handleUseCaseDetails = (selectedUseCase) => {
    let campaignId;
    let customCampaign;
    let filterSelectedUseCase;
    switch (selectedUseCase) {
      case 'Shop the Look':
        campaignId = '5b03109e551b9';
        this.setState({ bulkDownloadFormError: false });
        break;
      case 'Nike App':
        campaignId = '5a7b29d44ccc2';
        this.setState({ bulkDownloadFormError: true });
        break;
      default:
        this.setState({ bulkDownloadFormError: true });
        filterSelectedUseCase = this.allCampaigns.filter((label) => label.value === selectedUseCase);
        this.setState({ campaignDescription: filterSelectedUseCase[0].campaignDescription });
        customCampaign = Object.assign({}, ...filterSelectedUseCase);
        campaignId = customCampaign.campaignId;
        break;
    }
    this.setState({
      campaignId,
      customCampaign,
      downloaded: false,
      qrCodeTotal: 0,
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      selectedUseCase,
      sequenceEnd: '',
      sequenceStart: '',
      storeNumber: '',
    });
  };

  isAdminUser = () => {
    const { groups } = this.props;
    return groups && groups.includes(permissionsToGroups.AdminO2O) && process.env.NODE_ENV === 'production' ? true : groups.includes(permissionsToGroups.Users);
  }

  saveDataToHistory = () => {
    let payload;
    if (this.state.selectedUseCase === 'Shop the Look') {
      payload = this.getShopTheLookHistoryData();
    } else {
      payload = this.getHistoryPayload();
    }
    saveDownLoadHistory(this.props.accessToken, payload, this.state.campaignId);
  }

  selectCountry = (selectedCountry) => this.setState({ downloaded: false, selectedCountry, selectedStore: null });

  setStoreNumber = (selectedStore) => this.setState({
    downloaded: false, selectedStore, storeId: selectedStore.id, storeNumber: selectedStore.storeNumber,
  });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    if (this.state.customStore) {
      formErrors.selectedCountry = '';
      formErrors.selectedStore = '';
    }
    this.setState({ formErrors });
  };

  render = () => {
    const headerData = getHeaderInfo(this.props.location);
    const manageCampaignLink = (
      <section className="ncss-col-sm-8 ta-sm-r">
        <Link className="ncss-btn-primary-dark bg-accent border" style={{ bottom: '165px', position: 'relative' }} to="/qrcodegenerator/managecampaign">Manage Campaigns </Link>
      </section>
    );
    return (
      <PageTemplate
        auth={rosPermissions}
        description={headerData.description}
        help={(
          <a
            className="ncss-cta-primary-dark underline text-color-secondary"
            href={CONFLUENCE_HELP_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            Click here to view the KB
          </a>
        )}
        page={(
          <main className="ta-sm-c">
            {this.props.location.pathname !== '/qrcodegenerator'
              ? <><QRCodeSubRoutes /> <BackLink /></>
              : (
                <>
                  {this.isAdminUser() && manageCampaignLink }
                  <img alt="Nike QR Code Generator" src={qrCodeGenerator} style={{ margin: 'auto', marginBottom: '42px', width: '300px' }} />
                  <header className="ncss-col-sm-8 ta-sm-l">
                    {this.state.campaignId && (
                    <article className="ta-sm-r">
                      <Link
                        className="text-color-accent"
                        to={{ pathname: `/qrcodegenerator/history`, state: { campaignId: this.state.campaignId, campaignName: this.state.selectedUseCase, from: 'qrgenerator' } }}
                      > View History
                      </Link>
                    </article>
                    ) }
                    {this.state.campaignsCallPending && <p>Getting the campaigns list. Please wait...</p>}
                    {this.state.campaignsCallFailed && <p>Something went wrong. Please try again later.</p>}
                    {!this.state.campaignsCallPending && !this.state.campaignsCallFailed && (
                      <>
                        <Select
                          required
                          id="useCase"
                          isClearable={false}
                          label="Use Case"
                          options={this.allCampaigns}
                          value={this.state.selectedUseCase}
                          onChange={(useCase) => this.handleUseCaseDetails(useCase)}
                        />
                        <div>
                          <p>Important Update:</p>
                          <br />
                          <ul>
                            <li>&#8226; Post July 2022, Newly created QR Codes will not be supporting experiences leading to the Nike.com</li>
                            <li>&#8226; Codes to Nike App will continue to function as usual.</li>
                          </ul>
                        </div>
                      </>
                    )}
                    {this.state.selectedUseCase && (
                      <aside className="pt4-sm pb4-sm">
                        {this.getSelectedUseCaseDetails()}
                        {this.getCampaignDescription()}
                        <p className="body-3 pl3-sm">2) A ZIP folder will be saved to your computer containing all the assets for print.</p>
                      </aside>
                    )}
                  </header>
                  <form noValidate className="needs-validation">
                    {this.state.selectedUseCase !== '' && (
                    <article className="ncss-row">
                      <section className="ncss-col-sm-8 va-sm-b ta-sm-l" style={{ zIndex: '1' }}>
                        <Checkbox
                          key="customStore"
                          isChecked={this.state.customStore}
                          label="Custom Store Id"
                          onChange={({ target: { checked } }) => this.setState({
                            customStore: checked, selectedCountry: loadState('auth')?.country, selectedStore: null, storeNumber: '',
                          })}
                        />
                        {this.state.customStore
                          ? (
                            <>
                              Custom Store Id<span className="text-color-accent body-4"> - Tag the QR Code with a customized id. Ex: WHQ, NANFS, TokyoOlympics</span>
                              <Input
                                errorMessage={this.state.formErrors.storeNumber}
                                id="customStore"
                                placeholder="Enter a custom store id"
                                value={this.state.storeNumber}
                                onChange={({ target: { value } }) => this.setState({ storeNumber: value })}
                              />
                              {this.state.storeNumber.length !== 0 && this.state.storeNumber.match('^[A-Za-z0-9]+$') === null
                          && <aside className="text-color-error body-4 mt2-sm">Please only enter alphanumeric values such as A-Z or 0-9</aside>}
                            </>
                          )
                          : (
                            <StoreSelect
                              isRequired
                              formErrors={this.state.formErrors}
                              selectCountry={this.selectCountry}
                              selectStore={this.setStoreNumber}
                              selectedCountry={this.state.selectedCountry}
                              selectedStore={this.state.selectedStore}
                              storeviewsFields={['storeNumber', 'id']}
                            />
                          )}
                        {((this.state.selectedUseCase === 'Shop the Look' && this.state.customStore) || this.state.selectedUseCase === 'Shop the Look') && (
                        <BulkDownload generateQRCodehandler={this.handleInputChange} state={this.state} />
                        )}
                      </section>
                      <footer className="ncss-col-sm-8">
                        {this.state.branchCallFailed && <p className="text-color-accent mt10-sm">Something went wrong while creating QR codes. Please try again later.</p>}
                        <article style={{ display: 'flex', justifyContent: 'left' }}>
                          <article style={{ marginRight: '5%' }}>
                            <ButtonSubmit
                              isDisabled={!this.state.selectedUseCase || !this.state.storeNumber || !this.state.bulkDownloadFormError}
                              isLoading={this.state.downloading}
                              label="Generate & Download"
                              submitError={this.state.errorMessage}
                              onClick={this.generateQRCode}
                            />
                          </article>
                          <article>
                            <ButtonWhite
                              className="mt10-sm mb6-sm border"
                              label="Clear"
                              onClick={this.clearForm}
                            />
                          </article>
                        </article>
                        {this.state.downloaded && (
                        <article className="ncss-col-sm-6 ta-sm-c va-sm-t">
                          <span className="text-color-success">Success!</span> {this.state.qrCodeTotal} QR code{this.state.qrCodeTotal === 1 ? ' has' : 's have'} been created for <span className="text-color-accent">{this.state.selectedUseCase}!</span>
                        </article>
                        )}
                        {this.state.downloaded && (<FinalShortlinks data={this.state.qrData} />) }
                      </footer>
                    </article>
                    )}
                  </form>
                </>
              )}
          </main>
        )}
        path={this.props.location.pathname}
        title={headerData.title}
      />
    );
  };
}

QRCodeGenerator.defaultProps = {
  accessToken: undefined,
  email: '',
  groups: [],
  userName: '',
};

QRCodeGenerator.propTypes = {
  accessToken: PropTypes.string,
  email: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  userName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
  email: state.authorizationReducer.auth.email,
  groups: state.authorizationReducer.auth.groups,
  userName: state.authorizationReducer.auth.name,
});

export default connect(mapStateToProps, null)(QRCodeGenerator);
