import {
  AppHeader,
  Chip,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tab,
  TabGroup,
  Text,
  TextGroup,
  Tooltip,
} from '@nike/eds';
import {
  Converse, Feed, Global, NikeSwoosh, QuestionCircle, SortDescending
} from '@nike/nike-design-system-icons';
import { Default, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

import getConfig from '../utils/config';

import { StoreHierarchyContext } from './StoreHierarchyContextProvider';

const {
  useMockHierarchyService,
  useMockStoresService
} = getConfig();

const BRAND_ICON_SIZE = 's';

const BrandIcon = ({ name }) => (
  <>
    {match(name)(
      ['NIKE', () => (<NikeSwoosh size={BRAND_ICON_SIZE} />)],
      ['CONVERSE', () => (<Converse size={BRAND_ICON_SIZE} />)],
      [Default, () => (<QuestionCircle size={BRAND_ICON_SIZE} />)]
    )}
  </>
);

BrandIcon.propTypes = {
  name: PropTypes.string.isRequired
};

const BrandIconButtons = () => {
  const {
    isPageLoading,
    selectedBrand,
    setActiveNodeId,
    brands,
  } = useContext(StoreHierarchyContext);
  return (
    <>
      {!isPageLoading && (
        <Tooltip bodySlot="Selected Brand">
          <TabGroup
            activeId={selectedBrand?.id || null}
            name="brand-tab-group"
            size="small"
            variant="toggle-small"
            onChange={(e) => {
              setActiveNodeId(e?.target?.id);
            }}
          >
            {
              brands.map((brand) => (
                <Tab
                  key={brand.id}
                  id={brand.id}
                >
                  <BrandIcon name={brand.name} />
                </Tab>
              ))
            }
          </TabGroup>
        </Tooltip>
      )}
    </>
  );
};

const ReloadPageButton = () => {
  const { isPageLoading, setLoadPage } = useContext(StoreHierarchyContext);
  return (
    <Tooltip bodySlot="Reload Data">
      <IconButton
        disabled={isPageLoading}
        icon="Repeat"
        label="Reload Page"
        variant="ghost"
        onClick={() => setLoadPage(true)}
      />
    </Tooltip>
  );
};

const ProfileButton = () => {
  const { userGroups } = useContext(StoreHierarchyContext);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const isDark = true;
  const MenuHeader = () => (
    <>
      {isProfileOpen && (
        <div className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
          <Text as="h4" font="title-4">Profile</Text>
          <IconButton
            icon="Close"
            label="Close"
            size="small"
            variant="ghost"
            onClick={() => setIsProfileOpen(false)}
          />
        </div>
      )}
    </>
  );

  const ProfileMenuItem = ({ group }) => {
    const [copied, setCopied] = useState(false);

    const resetCopied = () => {
      setTimeout(() => setCopied(false), 200);
    };

    return (
      <MenuItem
        key={group}
        afterSlot={(
          <Icon
            name={copied ? 'CopyPasteFilled' : 'CopyPaste'}
            size="m"
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              navigator?.clipboard?.writeText(group);
              setCopied(true);
              resetCopied();
            }}
          />
        )}
        className="eds-type--body-2"
      >
        {group}
      </MenuItem>
    );
  };

  ProfileMenuItem.propTypes = {
    group: PropTypes.string.isRequired,
  };

  const MenuBody = () => (
    <>
      {isProfileOpen && (
        <>
          <TextGroup style={{ marginLeft: '1em' }}>
            <Text as="h6" font="title-6">User Groups</Text>
          </TextGroup>
          {[...userGroups]
            .sort()
            .map((group) => (
              <ProfileMenuItem key={group} group={group} />
            ))}
        </>
      )}
    </>
  );
  const MenuFooter = () => (
    <>
      {isProfileOpen && (
        <>
          <Link
            className="eds-type--body-2"
            href="https://confluence.nike.com/display/RE/Adding+Users+to+UIs"
            target="_blank"
          >About User Groups
          </Link>
        </>
      )}
    </>
  );
  return (
    <div>
      <Menu
        bodySlot={<MenuBody />}
        footerSlot={<MenuFooter />}
        headerSlot={<MenuHeader />}
        isDark={isDark}
        isOpen={isProfileOpen}
      >
        <Tooltip bodySlot="View Profile">

          <IconButton
            icon={isProfileOpen ? 'ProfileFilled' : 'Profile'}
            label="Profile"
            variant="ghost"
            onClick={() => setIsProfileOpen(!isProfileOpen)}
          />
        </Tooltip>
      </Menu>
    </div>
  );
};

const EnvironmentTag = () => {
  const environment = useMemo(() => (process && process.env && process.env.NODE_ENV) || 'unknown', []);
  const tagText = useMemo(() => match(environment)(
    ['development', () => 'Dev'],
    ['production', () => 'Prod'],
    ['test', () => 'Test'],
    [Default, () => '?']
  ), [environment]);

  const tooltipText = useMemo(() => `${environment
    ? environment.charAt(0).toUpperCase() + environment.slice(1)
    : 'Unknown'} Environment`,
    [environment]);

  return (
    <Tooltip bodySlot={tooltipText}>
      <Chip beforeSlot={<Global />}>{tagText}</Chip>
    </Tooltip>
  );
};

const DataTypeTag = () => {
  const isMockData = useMemo(() => useMockHierarchyService || useMockStoresService, []);
  const tagText = useMemo(() => match()(
    [() => useMockHierarchyService || useMockStoresService, 'Mock'],
    [Default, '']
  ), []);

  const tooltipText = useMemo(() => match()(
    [() => useMockHierarchyService && useMockStoresService, 'Data is mocked and will not affect live systems.'],
    [() => useMockHierarchyService, 'Hierarchy data is mocked and will not affect live systems, but stores data will be affected.'],
    [() => useMockStoresService, 'Stores data is mocked and will not affect live systems, but hierarchy data will be affected.'],
    [Default, '']
  ), []);
  return isMockData && (
    <Tooltip bodySlot={tooltipText}>
      <Chip beforeSlot={<Feed />}>{tagText}</Chip>
    </Tooltip>
  );
};

const HelpButton = () => (
  <Tooltip bodySlot="Help">
    <IconButton
      icon="QuestionCircle"
      label="Help"
      variant="ghost"
      onClick={() => window.open('https://confluence.nike.com/display/RCFITC/RCF+KB+-+Store+Hierarchy+UI', '_blank')}
    />
  </Tooltip>
);

const Toolbar = () => (
  <>
    <BrandIconButtons />
    <EnvironmentTag />
    <DataTypeTag />
    <ReloadPageButton />
    <HelpButton />
  </>
);

const StoreHierarchyHeader = () => (
  <AppHeader
    appName="Store Hierarchy"
    avatarSlot={<ProfileButton />}
    logoSlot={<SortDescending />}
    toolbarSlot={<Toolbar />}
  />
);

export default StoreHierarchyHeader;
