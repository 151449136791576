{
    "lastA11yAudit": "n/a",
    "name": "Box",
    "docs": "#/componenets/Box",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Box",
    "figma": "",
    "tags": ["Box", "Layout", "Utility", "div"],
    "designStatuses": ["N/A"],
    "devStatuses": ["Shipped"]
}
