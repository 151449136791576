import { requestWrapper } from './reusable';

const config = require('../../config')();

/* SHIP FROM STORE */

export const sfsGet = async (token, storeId) => requestWrapper(
  token,
  config.o2oCapabilitiesSFSResource,
  'get',
  { fulfillmentType: 'SHIP_FROM_STORE', storeId },
);

export const sfsPost = (token, storeId, sfsData) => requestWrapper(
  token,
  config.o2oCapabilitiesSFSResource,
  'post',
  undefined,
  { ...sfsData, fulfillmentType: 'SHIP_FROM_STORE', storeId },
);

export const sfsPut = (token, storeId, sfsData, id) => requestWrapper(
  token,
  config.o2oCapabilitiesSFSResource,
  'put',
  { id },
  { ...sfsData, fulfillmentType: 'SHIP_FROM_STORE', storeId },
);

/* ORDER LOOKUP */

export const o2oOrderLookup = (token, orderIdentifier) => requestWrapper(
  token,
  config.o2oOrderLookupResource,
  'get',
  { orderIdentifier },
);
