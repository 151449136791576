import { Loading } from '@nike/frame-component-library';
import {
  match, Default, reduceEntry, isSome,
} from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { deleteReason, getReasons } from '../../utils/service-calls/posreasons';
import { companyIdMappings } from '../../utils/static/pos-reasons-mappings';
import {
  Table, ButtonBlack, ButtonRed,
} from '../reusable';

import ReasonForm from './ReasonForm';

const SUCCESS_STATUS_CODE = 200;

const COMPANY_NAME_BY_ID = companyIdMappings
  .map(({ label, value }) => [value, label])
  .reduce(reduceEntry);

const isNotBlank = (s) => isSome(s) && s !== '';

const ViewReason = ({
  userToken, companyId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [reasonData, setReasonData] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editingReason, setEditingReason] = useState();

  const fetchReasons = useCallback(() => {
    setFetchError('');
    setIsLoading(true);
    return getReasons(userToken, companyId)
      .then(({ body }) => setReasonData(body))
      .catch((err) => setFetchError(err.message))
      .finally(() => setIsLoading(false));
  }, [userToken, companyId]);

  const deleteTheReason = ({ id }) => {
    setErrorMessage('');
    setSuccessMessage('');
    return deleteReason(userToken, companyId, id)
      .catch((err) => {
        setErrorMessage(err.message);
      })
      .then((data) => {
        setErrorMessage(data?.statusCode === SUCCESS_STATUS_CODE ? '' : (data?.body || `Failed to delete ${id}`));
        setSuccessMessage(data?.statusCode === SUCCESS_STATUS_CODE ? `Successfully Deleted ${id}` : '');
        setIsLoading(true);
        return fetchReasons();
      });
  };

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  const getColumns = () => {
    const columns = [
      {
        accessor: 'reasonType',
        Header: 'Type',
      },
      {
        accessor: 'code',
        Header: 'Code',
      },
      {
        accessor: 'partnerCode',
        Header: 'Partner Code',
      },
      {
        accessor: 'context',
        Header: 'Context',
      },
      {
        accessor: 'scopeLevel',
        Header: 'Scope Level',
      },
      {
        accessor: ({ scopeLevel, scopeValue }) => {
          const isValidCompanyScopeValue = isNotBlank(scopeLevel)
                        && isNotBlank(scopeValue)
                        && scopeLevel === 'COMPANY'
                        && Object.keys(COMPANY_NAME_BY_ID).includes(scopeValue);
          return isValidCompanyScopeValue
            ? COMPANY_NAME_BY_ID[scopeValue]
            : scopeValue;
        },
        Header: 'Scope Value',
        id: 'scopeValue',
      },
      {
        Cell: (d) => <ButtonBlack className="" label="Edit" onClick={() => setEditingReason(d.original)} />,
        Header: 'Edit',
        width: 80,
      },
      {
        Cell: (d) => <ButtonRed className="" confirmationMessage="Do you really want to permanently delete this POS reason?" label={<i className="g72-x-thick" />} onClick={() => deleteTheReason(d.original)} />,
        Header: 'Delete',
        width: 80,
      },
    ];
    return columns;
  };

  return match()(
    [() => isLoading, <Loading />],
    [() => editingReason, (
      <ReasonForm
        isEditing
        closeReasonForm={() => {
          setEditingReason(false);
          fetchReasons();
        }}
        companyId={companyId}
        reason={editingReason}
        userToken={userToken}
      />
    )],
    [Default, (
      <article className="ncss-col-sm-10 ta-sm-l"> {
          fetchError
            ? <section>{fetchError}</section>
            : (
              <section>
                {successMessage && (
                  <p className="text-color-success ta-sm-c body-2 mt2-sm">
                    {successMessage}
                  </p>
                )}
                {errorMessage && (
                  <p className="text-color-error ta-sm-c body-2 mt2-sm">
                    {errorMessage}
                  </p>
                )}
                <Table
                  fetched
                  columns={getColumns()}
                  data={reasonData}
                  defaultPageSize={20}
                />
              </section>
            )
        }
      </article>
    )],
  );
};

ViewReason.propTypes = {
  companyId: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default ViewReason;
