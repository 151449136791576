export const countryRegionMap = {
  ABW: 'ASIA_PACIFIC_LATIN_AMERICA',
  AFG: 'ASIA_PACIFIC_LATIN_AMERICA',
  AGO: 'EUROPE_MIDDLE_EAST_AFRICA',
  AIA: 'NORTH_AMERICA',
  ALB: 'EUROPE_MIDDLE_EAST_AFRICA',
  ANT: 'EUROPE_MIDDLE_EAST_AFRICA',
  ARE: 'EUROPE_MIDDLE_EAST_AFRICA',
  ARG: 'ASIA_PACIFIC_LATIN_AMERICA',
  ARM: 'EUROPE_MIDDLE_EAST_AFRICA',
  ATG: 'NORTH_AMERICA',
  AUS: 'ASIA_PACIFIC_LATIN_AMERICA',
  AUT: 'EUROPE_MIDDLE_EAST_AFRICA',
  AZE: 'EUROPE_MIDDLE_EAST_AFRICA',
  BDI: 'EUROPE_MIDDLE_EAST_AFRICA',
  BEL: 'EUROPE_MIDDLE_EAST_AFRICA',
  BEN: 'EUROPE_MIDDLE_EAST_AFRICA',
  BFA: 'EUROPE_MIDDLE_EAST_AFRICA',
  BGD: 'ASIA_PACIFIC_LATIN_AMERICA',
  BGR: 'EUROPE_MIDDLE_EAST_AFRICA',
  BHR: 'EUROPE_MIDDLE_EAST_AFRICA',
  BHS: 'NORTH_AMERICA',
  BIH: 'EUROPE_MIDDLE_EAST_AFRICA',
  BLM: 'ASIA_PACIFIC_LATIN_AMERICA',
  BLR: 'EUROPE_MIDDLE_EAST_AFRICA',
  BLZ: 'NORTH_AMERICA',
  BMU: 'NORTH_AMERICA',
  BOL: 'ASIA_PACIFIC_LATIN_AMERICA',
  BRA: 'ASIA_PACIFIC_LATIN_AMERICA',
  BRB: 'NORTH_AMERICA',
  BRN: 'ASIA_PACIFIC_LATIN_AMERICA',
  BTN: 'ASIA_PACIFIC_LATIN_AMERICA',
  BWA: 'EUROPE_MIDDLE_EAST_AFRICA',
  CAF: 'EUROPE_MIDDLE_EAST_AFRICA',
  CAN: 'NORTH_AMERICA',
  CHE: 'EUROPE_MIDDLE_EAST_AFRICA',
  CHL: 'ASIA_PACIFIC_LATIN_AMERICA',
  CHN: 'GREATER_CHINA',
  CIV: 'EUROPE_MIDDLE_EAST_AFRICA',
  CMR: 'EUROPE_MIDDLE_EAST_AFRICA',
  COD: 'EUROPE_MIDDLE_EAST_AFRICA',
  COG: 'EUROPE_MIDDLE_EAST_AFRICA',
  COL: 'ASIA_PACIFIC_LATIN_AMERICA',
  COM: 'EUROPE_MIDDLE_EAST_AFRICA',
  CPV: 'EUROPE_MIDDLE_EAST_AFRICA',
  CRI: 'ASIA_PACIFIC_LATIN_AMERICA',
  CUB: 'ASIA_PACIFIC_LATIN_AMERICA',
  CYP: 'EUROPE_MIDDLE_EAST_AFRICA',
  CZE: 'EUROPE_MIDDLE_EAST_AFRICA',
  DEU: 'EUROPE_MIDDLE_EAST_AFRICA',
  DJI: 'EUROPE_MIDDLE_EAST_AFRICA',
  DMA: 'ASIA_PACIFIC_LATIN_AMERICA',
  DNK: 'EUROPE_MIDDLE_EAST_AFRICA',
  DOM: 'ASIA_PACIFIC_LATIN_AMERICA',
  DZA: 'EUROPE_MIDDLE_EAST_AFRICA',
  ECU: 'ASIA_PACIFIC_LATIN_AMERICA',
  EGY: 'EUROPE_MIDDLE_EAST_AFRICA',
  ERI: 'EUROPE_MIDDLE_EAST_AFRICA',
  ESP: 'EUROPE_MIDDLE_EAST_AFRICA',
  EST: 'EUROPE_MIDDLE_EAST_AFRICA',
  ETH: 'EUROPE_MIDDLE_EAST_AFRICA',
  FIN: 'EUROPE_MIDDLE_EAST_AFRICA',
  FJI: 'ASIA_PACIFIC_LATIN_AMERICA',
  FRA: 'EUROPE_MIDDLE_EAST_AFRICA',
  FSM: 'ASIA_PACIFIC_LATIN_AMERICA',
  GAB: 'EUROPE_MIDDLE_EAST_AFRICA',
  GBR: 'EUROPE_MIDDLE_EAST_AFRICA',
  GEO: 'EUROPE_MIDDLE_EAST_AFRICA',
  GHA: 'EUROPE_MIDDLE_EAST_AFRICA',
  GIN: 'EUROPE_MIDDLE_EAST_AFRICA',
  GMB: 'EUROPE_MIDDLE_EAST_AFRICA',
  GNB: 'EUROPE_MIDDLE_EAST_AFRICA',
  GNQ: 'EUROPE_MIDDLE_EAST_AFRICA',
  GRC: 'EUROPE_MIDDLE_EAST_AFRICA',
  GRD: 'NORTH_AMERICA',
  GTM: 'ASIA_PACIFIC_LATIN_AMERICA',
  GUF: 'EUROPE_MIDDLE_EAST_AFRICA',
  GUY: 'ASIA_PACIFIC_LATIN_AMERICA',
  HKG: 'GREATER_CHINA',
  HND: 'ASIA_PACIFIC_LATIN_AMERICA',
  HRV: 'EUROPE_MIDDLE_EAST_AFRICA',
  HTI: 'ASIA_PACIFIC_LATIN_AMERICA',
  HUN: 'EUROPE_MIDDLE_EAST_AFRICA',
  IDN: 'ASIA_PACIFIC_LATIN_AMERICA',
  IND: 'ASIA_PACIFIC_LATIN_AMERICA',
  IRL: 'EUROPE_MIDDLE_EAST_AFRICA',
  IRN: 'EUROPE_MIDDLE_EAST_AFRICA',
  IRQ: 'EUROPE_MIDDLE_EAST_AFRICA',
  ISL: 'EUROPE_MIDDLE_EAST_AFRICA',
  ISR: 'EUROPE_MIDDLE_EAST_AFRICA',
  ITA: 'EUROPE_MIDDLE_EAST_AFRICA',
  JAM: 'NORTH_AMERICA',
  JOR: 'EUROPE_MIDDLE_EAST_AFRICA',
  JPN: 'ASIA_PACIFIC_LATIN_AMERICA',
  KAZ: 'ASIA_PACIFIC_LATIN_AMERICA',
  KEN: 'EUROPE_MIDDLE_EAST_AFRICA',
  KGZ: 'ASIA_PACIFIC_LATIN_AMERICA',
  KHM: 'ASIA_PACIFIC_LATIN_AMERICA',
  KIR: 'ASIA_PACIFIC_LATIN_AMERICA',
  KNA: 'NORTH_AMERICA',
  KOR: 'ASIA_PACIFIC_LATIN_AMERICA',
  KWT: 'EUROPE_MIDDLE_EAST_AFRICA',
  LAO: 'ASIA_PACIFIC_LATIN_AMERICA',
  LBN: 'ASIA_PACIFIC_LATIN_AMERICA',
  LBR: 'EUROPE_MIDDLE_EAST_AFRICA',
  LBY: 'EUROPE_MIDDLE_EAST_AFRICA',
  LCA: 'NORTH_AMERICA',
  LIE: 'EUROPE_MIDDLE_EAST_AFRICA',
  LKA: 'ASIA_PACIFIC_LATIN_AMERICA',
  LSO: 'EUROPE_MIDDLE_EAST_AFRICA',
  LTU: 'EUROPE_MIDDLE_EAST_AFRICA',
  LUX: 'EUROPE_MIDDLE_EAST_AFRICA',
  LVA: 'EUROPE_MIDDLE_EAST_AFRICA',
  MAC: 'GREATER_CHINA',
  MAF: 'ASIA_PACIFIC_LATIN_AMERICA',
  MAR: 'EUROPE_MIDDLE_EAST_AFRICA',
  MCO: 'EUROPE_MIDDLE_EAST_AFRICA',
  MDA: 'EUROPE_MIDDLE_EAST_AFRICA',
  MDG: 'EUROPE_MIDDLE_EAST_AFRICA',
  MDV: 'ASIA_PACIFIC_LATIN_AMERICA',
  MEX: 'ASIA_PACIFIC_LATIN_AMERICA',
  MHL: 'ASIA_PACIFIC_LATIN_AMERICA',
  MKD: 'EUROPE_MIDDLE_EAST_AFRICA',
  MLI: 'EUROPE_MIDDLE_EAST_AFRICA',
  MLT: 'EUROPE_MIDDLE_EAST_AFRICA',
  MMR: 'ASIA_PACIFIC_LATIN_AMERICA',
  MNE: 'EUROPE_MIDDLE_EAST_AFRICA',
  MNG: 'ASIA_PACIFIC_LATIN_AMERICA',
  MOZ: 'EUROPE_MIDDLE_EAST_AFRICA',
  MRT: 'EUROPE_MIDDLE_EAST_AFRICA',
  MTQ: 'ASIA_PACIFIC_LATIN_AMERICA',
  MUS: 'EUROPE_MIDDLE_EAST_AFRICA',
  MWI: 'EUROPE_MIDDLE_EAST_AFRICA',
  MYS: 'ASIA_PACIFIC_LATIN_AMERICA',
  NAM: 'EUROPE_MIDDLE_EAST_AFRICA',
  NER: 'EUROPE_MIDDLE_EAST_AFRICA',
  NGA: 'EUROPE_MIDDLE_EAST_AFRICA',
  NIC: 'ASIA_PACIFIC_LATIN_AMERICA',
  NLD: 'EUROPE_MIDDLE_EAST_AFRICA',
  NOR: 'EUROPE_MIDDLE_EAST_AFRICA',
  NPL: 'ASIA_PACIFIC_LATIN_AMERICA',
  NRU: 'ASIA_PACIFIC_LATIN_AMERICA',
  NZL: 'ASIA_PACIFIC_LATIN_AMERICA',
  OMN: 'EUROPE_MIDDLE_EAST_AFRICA',
  PAK: 'ASIA_PACIFIC_LATIN_AMERICA',
  PAN: 'ASIA_PACIFIC_LATIN_AMERICA',
  PER: 'ASIA_PACIFIC_LATIN_AMERICA',
  PHL: 'ASIA_PACIFIC_LATIN_AMERICA',
  PLW: 'ASIA_PACIFIC_LATIN_AMERICA',
  PNG: 'ASIA_PACIFIC_LATIN_AMERICA',
  POL: 'EUROPE_MIDDLE_EAST_AFRICA',
  PRI: 'ASIA_PACIFIC_LATIN_AMERICA',
  PRK: 'ASIA_PACIFIC_LATIN_AMERICA',
  PRT: 'EUROPE_MIDDLE_EAST_AFRICA',
  PRY: 'ASIA_PACIFIC_LATIN_AMERICA',
  PSE: 'EUROPE_MIDDLE_EAST_AFRICA',
  PYF: 'EUROPE_MIDDLE_EAST_AFRICA',
  QAT: 'EUROPE_MIDDLE_EAST_AFRICA',
  REU: 'EUROPE_MIDDLE_EAST_AFRICA',
  ROU: 'EUROPE_MIDDLE_EAST_AFRICA',
  RUS: 'EUROPE_MIDDLE_EAST_AFRICA',
  RWA: 'EUROPE_MIDDLE_EAST_AFRICA',
  SAU: 'EUROPE_MIDDLE_EAST_AFRICA',
  SDN: 'EUROPE_MIDDLE_EAST_AFRICA',
  SEN: 'EUROPE_MIDDLE_EAST_AFRICA',
  SGP: 'ASIA_PACIFIC_LATIN_AMERICA',
  SLB: 'EUROPE_MIDDLE_EAST_AFRICA',
  SLE: 'EUROPE_MIDDLE_EAST_AFRICA',
  SLV: 'ASIA_PACIFIC_LATIN_AMERICA',
  SMR: 'EUROPE_MIDDLE_EAST_AFRICA',
  SOM: 'EUROPE_MIDDLE_EAST_AFRICA',
  SRB: 'EUROPE_MIDDLE_EAST_AFRICA',
  STP: 'EUROPE_MIDDLE_EAST_AFRICA',
  SUR: 'ASIA_PACIFIC_LATIN_AMERICA',
  SVK: 'EUROPE_MIDDLE_EAST_AFRICA',
  SVN: 'EUROPE_MIDDLE_EAST_AFRICA',
  SWE: 'EUROPE_MIDDLE_EAST_AFRICA',
  SWZ: 'EUROPE_MIDDLE_EAST_AFRICA',
  SYC: 'EUROPE_MIDDLE_EAST_AFRICA',
  SYR: 'EUROPE_MIDDLE_EAST_AFRICA',
  TCD: 'EUROPE_MIDDLE_EAST_AFRICA',
  TGO: 'EUROPE_MIDDLE_EAST_AFRICA',
  THA: 'ASIA_PACIFIC_LATIN_AMERICA',
  TJK: 'ASIA_PACIFIC_LATIN_AMERICA',
  TKM: 'ASIA_PACIFIC_LATIN_AMERICA',
  TON: 'ASIA_PACIFIC_LATIN_AMERICA',
  TTO: 'ASIA_PACIFIC_LATIN_AMERICA',
  TUN: 'EUROPE_MIDDLE_EAST_AFRICA',
  TUR: 'EUROPE_MIDDLE_EAST_AFRICA',
  TUV: 'ASIA_PACIFIC_LATIN_AMERICA',
  TWN: 'GREATER_CHINA',
  TZA: 'EUROPE_MIDDLE_EAST_AFRICA',
  UGA: 'EUROPE_MIDDLE_EAST_AFRICA',
  UKR: 'EUROPE_MIDDLE_EAST_AFRICA',
  URY: 'ASIA_PACIFIC_LATIN_AMERICA',
  USA: 'NORTH_AMERICA',
  UZB: 'ASIA_PACIFIC_LATIN_AMERICA',
  VAT: 'EUROPE_MIDDLE_EAST_AFRICA',
  VCT: 'NORTH_AMERICA',
  VEN: 'ASIA_PACIFIC_LATIN_AMERICA',
  VGB: 'NORTH_AMERICA',
  VIR: 'ASIA_PACIFIC_LATIN_AMERICA',
  VNM: 'ASIA_PACIFIC_LATIN_AMERICA',
  VUT: 'ASIA_PACIFIC_LATIN_AMERICA',
  WSM: 'ASIA_PACIFIC_LATIN_AMERICA',
  YEM: 'EUROPE_MIDDLE_EAST_AFRICA',
  ZAF: 'EUROPE_MIDDLE_EAST_AFRICA',
  ZMB: 'EUROPE_MIDDLE_EAST_AFRICA',
  ZWE: 'EUROPE_MIDDLE_EAST_AFRICA',
};

export const countryToServerRegion = (country) => {
  switch (country) {
    case 'AUS':
    case 'CHN':
    case 'HKG':
    case 'JPN':
    case 'KOR':
    case 'MAC':
    case 'TWN':
      return 'ap-southeast-1';
    case 'ARE':
    case 'ARM':
    case 'AUT':
    case 'BEL':
    case 'BGR':
    case 'BHR':
    case 'BIH':
    case 'BLR':
    case 'CHE':
    case 'CNI':
    case 'CYP':
    case 'CZE':
    case 'DEU':
    case 'DNK':
    case 'EGY':
    case 'ESP':
    case 'EST':
    case 'FIN':
    case 'FRA':
    case 'GBR':
    case 'GEO':
    case 'GRC':
    case 'HRV':
    case 'HUN':
    case 'IRL':
    case 'ISR':
    case 'ITA':
    case 'JOR':
    case 'KAZ':
    case 'KGZ':
    case 'KWT':
    case 'LBN':
    case 'LTU':
    case 'LVA':
    case 'MDA':
    case 'MKD':
    case 'NLD':
    case 'NOR':
    case 'OMN':
    case 'POL':
    case 'PRT':
    case 'QAT':
    case 'ROU':
    case 'RUS':
    case 'SAU':
    case 'SRB':
    case 'SVK':
    case 'SVN':
    case 'SWE':
    case 'TKM':
    case 'TUN':
    case 'TUR':
    case 'UKR':
    case 'UZB':
    case 'ZAF':
      return 'eu-west-1';
    case 'ARG':
    case 'BRA':
    case 'CHL':
    case 'MEX':
      return 'sa-east-1';
    case 'CAN':
    case 'USA':
    default:
      return 'us-east-1';
  }
};
