import { Input } from '@nike/frame-component-library';
import {
  isSome, match, Default, isNone, Maybe, reduceEntry,
} from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { createReason, updateReason } from '../../utils/service-calls/posreasons';
import { scopeLevelMappings, regionMappings } from '../../utils/static/pos-reasons-mappings';
import { REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonBlack,
  ButtonRed,
  ButtonSubmit,
  Select,
} from '../reusable';

const uuidv4 = require('uuid/v4');

const addIdToTranslation = (translation) => ({ ...translation, id: Maybe.of(translation.id).orElseGet(uuidv4) });

const addIdToTranslations = (formData) => {
  const translations = Maybe.of(formData.translations).orElse([]);
  const translationsWithId = translations.map(addIdToTranslation);
  return { ...formData, translations: translationsWithId };
};

const ScopeValue = ({
  scopeLevel, scopeValue, validateScopeValue, setField, label,
}) => {
  const errorMessage = validateScopeValue({ scopeLevel, scopeValue });
  return match(scopeLevel)(
    ['', () => (<></>)],
    ['COMPANY', () => (<></>)],
    ['REGION', () => (
      <Select
        className="ncss-col-sm-6 va-sm-t mb2-sm ta-sm-l"
        errorMessage={errorMessage}
        id="scopeValue"
        label={label}
        options={regionMappings}
        value={scopeValue}
        onChange={(value) => setField('scopeValue', value)}
      />
    )],
    [Default, () => (
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={errorMessage}
          label={label}
          value={scopeValue}
          onChange={(e) => setField('scopeValue', e.target.value)}
        />
      </article>
    )],
  );
};

ScopeValue.propTypes = {
  label: PropTypes.string.isRequired,
  scopeLevel: PropTypes.string.isRequired,
  scopeValue: PropTypes.string.isRequired,
  setField: PropTypes.func.isRequired,
  validateScopeValue: PropTypes.func.isRequired,
};

const Translation = ({
  translation, setTranslationField, deleteTranslation, validateTranslationLanguage, validateTranslationText,
}) => {
  const { id, language, text } = translation;
  return (
    <>
      <article key={`translation_article_language_${id}`} className="ncss-col-sm-2 va-sm-t">
        <Input
          key={`translation_article_language_${id}`}
          errorMessage={validateTranslationLanguage(translation)}
          label="Language"
          value={language}
          onChange={(e) => {
            setTranslationField(id, 'language', e.target.value);
          }}
        />
      </article>
      <article key={`translation_article_text_${id}`} className="ncss-col-sm-9 va-sm-t">
        <Input
          key={`text_${id}`}
          errorMessage={validateTranslationText(translation)}
          label="Text"
          value={text}
          onChange={(e) => {
            setTranslationField(id, 'text', e.target.value);
          }}
        />
      </article>
      <article key={`translation_article_button_${id}`} className="ncss-col-sm-1 va-sm-b">
        <ButtonRed
          key={`deletebutton_${id}`}
          className=""
          confirmationMessage="Do you really want to delete this translation?"
          label={<i className="g72-x-thick" />}
          onClick={() => deleteTranslation(id)}
        />
      </article>
    </>
  );
};

Translation.propTypes = {
  deleteTranslation: PropTypes.func.isRequired,
  setTranslationField: PropTypes.func.isRequired,
  translation: PropTypes.shape({ id: PropTypes.string.isRequired, language: PropTypes.string.isRequired, text: PropTypes.string }).isRequired,
  validateTranslationLanguage: PropTypes.func.isRequired,
  validateTranslationText: PropTypes.func.isRequired,
};

const Translations = ({
  translations, setTranslationField, deleteTranslation, validateTranslationLanguage, validateTranslationText,
}) => (
  <>
    <section className="ta-sm-l bg-primary-grey pt2-sm pb2-sm prl2-sm border">
      <header className="u-rounded bg-black va-sm-m pt2-sm pb2-sm mb2-sm">
        <span className="headline-3 pl3-sm text-color-primary-light">
          Translations
        </span>
      </header>

      {translations.map((translation) => (
        <Translation
          key={`translation_${translation.id}`}
          deleteTranslation={deleteTranslation}
          setTranslationField={setTranslationField}
          translation={translation}
          validateTranslationLanguage={validateTranslationLanguage}
          validateTranslationText={validateTranslationText}
        />
      ))}
    </section>
  </>
);

Translations.propTypes = {
  deleteTranslation: PropTypes.func.isRequired,
  setTranslationField: PropTypes.func.isRequired,
  translations: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired, language: PropTypes.string.isRequired, text: PropTypes.string })).isRequired,
  validateTranslationLanguage: PropTypes.func.isRequired,
  validateTranslationText: PropTypes.func.isRequired,
};

const ReasonForm = ({
  userToken, isEditing, reason, closeReasonForm, companyId,
}) => {
  // Form input
  const [formData, setFormData] = useState(isEditing
    ? addIdToTranslations(reason)
    : {
      code: '',
      companyId,
      context: '',
      id: '',
      partnerCode: '',
      reasonType: '',
      scopeLevel: '',
      scopeValue: '',
      translations: [],
    });
  const [savingReason, setSavingReason] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const setField = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const setTranslationField = (id, fieldName, value) => {
    const [theTranslation] = formData.translations.filter((translation) => translation.id === id);
    const updatedTranslation = { ...theTranslation, [fieldName]: value };
    const updatedTranslationArray = formData.translations.map((translation) => (translation.id === id
      ? updatedTranslation
      : translation));
    setField('translations', updatedTranslationArray);
  };

  const removeEmptyFields = (data) => Object.entries(data)
    .filter(([, value]) => value !== '')
    .reduce(reduceEntry);

  const postReason = (data) => createReason(userToken, companyId, removeEmptyFields(data))
    .then(() => setSuccessMessage('Successfully created POS reason!'))
    .catch((err) => {
      setErrorMessage(`POST failed with error: ${err.message}`);
    })
    .finally(() => setSavingReason(false));

  const putReason = (data) => updateReason(userToken, companyId, removeEmptyFields(data))
    .then(() => setSuccessMessage('Successfully saved changes to POS reason!'))
    .catch((err) => setErrorMessage(`PUT failed with error: ${err.message}`))
    .finally(() => setSavingReason(false));

  const saveReason = (data) => {
    setSavingReason(true);
    setSuccessMessage('');
    setErrorMessage('');
    return isEditing ? putReason(data) : postReason(data);
  };

  const UPPER_SNAKE_CASE_REGEX = /^[A-Z0-9]{1}([A-Z0-9_]*[A-Z0-9]{1})*$/;
  const UPPER_SNAKE_CASE_MSG = 'Must be upper snake-case e.g. "MY_VALUE"';
  const validateRegex = (value, regex, msg = `Must match regular expression: ${regex}`) => (regex.test(value)
    ? ''
    : msg);
  const isBlank = (value) => isNone(value) || value === '';
  const validateRequiredField = (value, regex, regexFailureMsg) => match(value)(
    [isBlank, REQUIRED_FIELD],
    [() => isSome(regex), () => validateRegex(value, regex, regexFailureMsg)],
    [Default, ''],
  );
  const validateOptionalField = (value, regex, regexFailureMsg) => (isBlank(value)
    ? ''
    : validateRegex(value, regex, regexFailureMsg));

  const validateScopeValueForCompany = ({ scopeValue }) => (scopeValue === '' || scopeValue === companyId
    ? ''
    : `Scope value for company must be either empty or match the company id (${companyId}).`);
  const validateScopeValueForRegion = ({ scopeValue }) => validateRequiredField(scopeValue, UPPER_SNAKE_CASE_REGEX, UPPER_SNAKE_CASE_MSG);
  const validateScopeValueCountry = ({ scopeValue }) => validateRequiredField(scopeValue, /^[A-Z]{3}$/, 'Must be an ISO3 country code e.g. USA');
  const validateScopeValueStore = ({ scopeValue }) => validateRequiredField(scopeValue);

  const validateReasonType = ({ reasonType }) => validateRequiredField(reasonType, UPPER_SNAKE_CASE_REGEX, UPPER_SNAKE_CASE_MSG);
  const validateReasonCode = ({ code }) => validateRequiredField(code, UPPER_SNAKE_CASE_REGEX, UPPER_SNAKE_CASE_MSG);
  const validatePartnerCode = ({ partnerCode }) => validateOptionalField(partnerCode, UPPER_SNAKE_CASE_REGEX, UPPER_SNAKE_CASE_MSG);
  const validateContext = ({ context }) => validateRequiredField(context);
  const validateScopeLevel = ({ scopeLevel }) => validateRequiredField(scopeLevel);

  const isScopeLevel = (expectedScopeLevel) => ({ scopeLevel }) => scopeLevel === expectedScopeLevel;
  const validateScopeValue = ({ scopeLevel, scopeValue }) => match({ scopeLevel, scopeValue })(
    [isScopeLevel('COMPANY'), validateScopeValueForCompany],
    [isScopeLevel('REGION'), validateScopeValueForRegion],
    [isScopeLevel('COUNTRY'), validateScopeValueCountry],
    [isScopeLevel('STORE'), validateScopeValueStore],
    [Default, 'Please select a valid scope level'],
  );

  const validateTranslationLanguage = ({ language }) => validateRequiredField(language, /^(([a-z]{2})(-[A-Z]{2})?)$/, 'Must be a valid language code e.g. "en-US"');

  const validateTranslationText = ({ text }) => validateRequiredField(text);

  const translationLanguageValidators = ({ translations }) => translations.map((translation) => () => validateTranslationLanguage(translation));

  const translationTextValidators = ({ translations }) => translations.map((translation) => () => validateTranslationText(translation));

  const anyFieldFailsValidation = (form) => [
    validateReasonType,
    validateReasonCode,
    validatePartnerCode,
    validateContext,
    validateScopeLevel,
    validateScopeValue,
    ...translationLanguageValidators(form),
    ...translationTextValidators(form),
  ].some((f) => f(form) !== '');

  const deleteTranslation = (id) => {
    const newTranslations = formData.translations.filter((translation) => translation.id !== id);
    setField('translations', newTranslations);
  };

  const addTranslation = () => {
    const newTranslations = [...formData.translations, { id: uuidv4(), language: '', text: '' }];
    setField('translations', newTranslations);
  };

  const getScopeValueLabel = () => match(formData.scopeLevel)(
    ['COMPANY', 'Company ID'],
    ['REGION', 'Region Code'],
    ['COUNTRY', 'Country Code'],
    ['STORE', 'Store ID'],
    [Default, 'Scope Value'],
  );

  return (
    <main className="ncss-row ta-sm-c">
      <section className="ta-sm-l bg-primary-grey pt2-sm pb2-sm prl2-sm border">
        <header className="u-rounded bg-black va-sm-m pt2-sm pb2-sm mb2-sm">
          <span className="headline-3 pl3-sm text-color-primary-light">
            {isEditing ? 'Edit POS Reason' : 'New POS Reason'}
          </span>
        </header>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={validateReasonType(formData)}
            label="Reason Type"
            value={formData.reasonType}
            onChange={(e) => setField('reasonType', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={validateReasonCode(formData)}
            label="Reason Code"
            value={formData.code}
            onChange={(e) => setField('code', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            // deepcode ignore WrongNumberOfArguments: Might need args later
            errorMessage={validatePartnerCode(formData)}
            label="Partner Code"
            value={formData.partnerCode}
            onChange={(e) => setField('partnerCode', e.target.value)}
          />
        </article>
        <article className="ncss-col-sm-6 va-sm-t">
          <Input
            errorMessage={validateContext(formData)}
            label="Context"
            value={formData.context}
            onChange={(e) => setField('context', e.target.value)}
          />
        </article>
        <Select
          className="ncss-col-sm-6 va-sm-t mb2-sm ta-sm-l"
          errorMessage={validateScopeLevel(formData)}
          id="scopeLevel"
          label="Scope Level"
          options={scopeLevelMappings}
          value={formData.scopeLevel}
          onChange={(scopeLevel) => setFormData({ ...formData, scopeLevel, scopeValue: '' })}
        />

        <ScopeValue
          label={getScopeValueLabel()}
          scopeLevel={formData.scopeLevel}
          scopeValue={formData.scopeValue}
          setField={setField}
          validateScopeValue={validateScopeValue}
        />
      </section>
      <section className="ta-sm-l bg-primary-grey pt2-sm pb2-sm prl2-sm border">
        <article className="ncss-col-sm-12 va-sm-t">
          <Translations
            deleteTranslation={deleteTranslation}
            setTranslationField={setTranslationField}
            translations={formData.translations}
            validateTranslationLanguage={validateTranslationLanguage}
            validateTranslationText={validateTranslationText}
          />
          <ButtonBlack
            label="Add Translation"
            onClick={() => addTranslation()}
          />
        </article>
      </section>
      <footer className="ncss-row">
        <ButtonSubmit
          isDisabled={anyFieldFailsValidation(formData)}
          isLoading={savingReason}
          onClick={() => saveReason(formData)}
        />
        {isEditing && (
          <ButtonBlack
            label="Back to POS Reasons"
            onClick={() => closeReasonForm()}
          />
        )}
      </footer>
      {successMessage && (
        <p className="text-color-success ta-sm-c body-2 mt2-sm">
          {successMessage}
        </p>
      )}
      {errorMessage && (
        <p className="text-color-error ta-sm-c body-2 mt2-sm">
          {errorMessage}
        </p>
      )}
    </main>
  );
};

ReasonForm.defaultProps = {
  closeReasonForm: () => null,
  isEditing: false,
  reason: {
    code: '',
    companyId: '',
    context: '',
    id: '',
    partnerCode: '',
    reasonType: '',
    scopeLevel: '',
    scopeValue: '',
  },
};

ReasonForm.propTypes = {
  closeReasonForm: PropTypes.func,
  companyId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  reason: PropTypes.shape({
    code: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    id: PropTypes.string,
    partnerCode: PropTypes.string,
    reasonType: PropTypes.string.isRequired,
    scopeLevel: PropTypes.string.isRequired,
    scopeValue: PropTypes.string.isRequired,
  }),
  userToken: PropTypes.string.isRequired,
};
export default ReasonForm;
