import { Cmd, loop } from 'redux-loop';

import {
  DEVICES_FETCH, DEVICES_FETCH_SUCCESS, DEVICES_FETCH_FAILURE,
  devicesFetchSuccess, devicesFetchFailure,
  DEVICE_SAVE, DEVICE_SAVE_SUCCESS, DEVICE_SAVE_FAILURE,
  deviceSaveSuccess, deviceSaveFailure,
  DEVICE_WAITLINE_UPDATE_SUCCESS, DEVICE_WAITLINE_UPDATE_FAILURE,
  deviceWaitlineUpdateFailure,
} from '../actions/devices';
import { waitlineDeviceUpdateSuccess } from '../actions/waitlines';
import { replaceWhere } from '../utils/array';
import { assoc, dissoc } from '../utils/object';
import { devicesGet, devicePut, devicePutToWaitlines } from '../utils/service-calls/devices-and-waitlines';
import { validateDevices } from '../utils/validation/devices-and-waitlines';

const initialState = {
  currentStore: {},
  deviceErrors: {}, // { [deviceId]: error }
  deviceRequests: {}, // { [deviceId]: { type: 'SAVE' }}
  devices: [],
  fetchDevicesError: null, // error
  fetchDevicesRequest: null, // { type: 'FETCH' }
  storeId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEVICES_FETCH: {
      if (action.storeId !== state.storeId || state.fetchDevicesRequest === null) {
        return loop({
          ...state,
          deviceErrors: {},
          deviceRequests: {},
          fetchDevicesError: null,
          fetchDevicesRequest: { type: 'FETCH' },
          storeId: action.storeId,
        },
        Cmd.run(devicesGet, {
          args: [action.token, action.storeId],
          failActionCreator: (error) => devicesFetchFailure(action.storeId, error),
          successActionCreator: (data) => devicesFetchSuccess(action.storeId, data.body),
        }));
      } else {
        return state;
      }
    }
    case DEVICES_FETCH_SUCCESS: {
      let devices = [];
      try {
        devices = validateDevices(action.devices);
      } catch (err) {
        return { ...state, fetchDevicesError: err, fetchDevicesRequest: null };
      }
      return { ...state, devices, fetchDevicesRequest: null };
    }
    case DEVICES_FETCH_FAILURE: {
      return { ...state, fetchDevicesError: action.error, fetchDevicesRequest: null };
    }
    case DEVICE_SAVE: {
      if (!(action.device.id in state.deviceRequests)) {
        return loop({
          ...state,
          deviceErrors: dissoc(state.deviceErrors, action.device.id),
          deviceRequests: assoc(state.deviceRequests, action.device.id, { type: 'SAVE' }),
        },
        Cmd.list([
          Cmd.run(devicePut, {
            args: [action.token, action.device],
            failActionCreator: (error) => deviceSaveFailure(action.device, error),
            successActionCreator: () => deviceSaveSuccess(action.device),
          }),
          action.waitline
            ? Cmd.run(devicePutToWaitlines, {
              args: [action.token, action.device, action.waitline],
              failActionCreator: (error) => deviceWaitlineUpdateFailure(action.device, action.waitline, error),
              successActionCreator: () => waitlineDeviceUpdateSuccess(action.waitline),
            })
            : Cmd.none,
        ]));
      } else {
        return state;
      }
    }
    case DEVICE_SAVE_SUCCESS: {
      return {
        ...state,
        deviceRequests: dissoc(state.deviceRequests, action.device.id),
        devices: replaceWhere(state.devices, (dev) => dev.id === action.device.id, action.device),
      };
    }
    case DEVICE_SAVE_FAILURE: {
      return {
        ...state,
        deviceErrors: assoc(state.deviceErrrors, action.device.id, action.error),
        deviceRequests: dissoc(state.deviceRequests, action.device.id),
      };
    }
    case DEVICE_WAITLINE_UPDATE_SUCCESS: {
      return { ...state, devices: replaceWhere(state.devices, (dev) => dev.id === action.device.id, action.device) };
    }
    case DEVICE_WAITLINE_UPDATE_FAILURE: {
      // TODO: tell the user
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
