import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ButtonWhite from './ButtonWhite';

const CustomPanel = ({
  buttonLabel, children, hasButton, isDeviceActive, isOpen, label, onButtonClick, onClick,
}) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  const [headerHovered, setHeaderHovered] = useState(false);
  return (
    <article className="ncss-row">
      <section className="ncss-col-sm-12 va-sm-m pb2-sm">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <article
          className="u-rounded bg-black u-cursor-pointer va-sm-m pt2-sm pb2-sm"
          style={{
            color: headerHovered && !buttonHovered ? '#FA5400' : 'white',
            flexDirection: 'row',
            verticalAlign: 'middle',
          }}
          onClick={onClick}
          onFocus={() => setHeaderHovered(true)}
          onKeyDown={() => null}
          onMouseLeave={() => setHeaderHovered(false)}
          onMouseOver={() => setHeaderHovered(true)}
        >
          <span className="headline-3 pl3-sm">
            {label}
          </span>
          {(hasButton) && (
            <ButtonWhite
              className="ml6-sm mr3-sm"
              label={buttonLabel}
              style={{ border: 0, padding: '4 12', verticalAlign: 'top' }}
              onAway={() => setButtonHovered(false)}
              onClick={onButtonClick}
              onHover={() => setButtonHovered(true)}
            />
          )}
          {isDeviceActive && <i className={`ml3-sm ${isDeviceActive === 'true' ? 'g72-check-circle text-color-success' : 'g72-x-circle text-color-error'}`} />}
          <i className={`ml3-sm ${isOpen ? 'g72-minus' : 'g72-plus'}`} />
        </article>
        <article className={`ncss-row p1-sm ${isOpen ? '' : 'd-sm-h'}`}>
          {children}
        </article>
      </section>
    </article>
  );
};

CustomPanel.defaultProps = {
  buttonLabel: '',
  children: null,
  hasButton: false,
  isDeviceActive: '',
  onButtonClick: () => null,
};

CustomPanel.propTypes = {
  buttonLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  hasButton: PropTypes.bool,
  isDeviceActive: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  onClick: PropTypes.func.isRequired,
};

export default CustomPanel;
