import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { REQUIRED_FIELD } from '../../utils/validation/input-validation';

// this function is needed in case a user types into the input
const enforceMinMax = (element) => {
  if (element.value !== '') {
    if (parseInt(element.value, 10) < parseInt(element.min, 10)) {
      // eslint-disable-next-line no-param-reassign
      element.value = element.min;
    }
    if (parseInt(element.value, 10) > parseInt(element.max, 10)) {
      // eslint-disable-next-line no-param-reassign
      element.value = element.max;
    }
  }
};

const LeadTimeInput = (props) => (
  <section className="ncss-row">
    <header className="ncss-col-sm-12">
      <p className={props.labelClass}>{props.label}</p>
    </header>
    <article className="ncss-col-sm-4 va-sm-t">
      <Input
        className="ncss-input prl4-sm"
        errorMessage={!props.leadTimeData.hours ? REQUIRED_FIELD : ''}
        id="leadTimeHours"
        label="Hours"
        max="24"
        min="0"
        step="1"
        type="number"
        value={props.leadTimeData.hours}
        onChange={({ target }) => {
          enforceMinMax(target);
          props.updateLeadTimeData(target.value, props.leadTimeData.minutes, props.leadTimeData.seconds);
        }}
      />
    </article>
    <article className="ncss-col-sm-4 va-sm-t">
      <Input
        className="ncss-input prl4-sm"
        errorMessage={!props.leadTimeData.hours ? REQUIRED_FIELD : ''}
        id="leadTimeMinutes"
        label="Minutes"
        max="59"
        min="0"
        step="1"
        type="number"
        value={props.leadTimeData.minutes}
        onChange={({ target }) => {
          enforceMinMax(target);
          props.updateLeadTimeData(props.leadTimeData.hours, target.value, props.leadTimeData.seconds);
        }}
      />
    </article>
    <article className="ncss-col-sm-4 va-sm-t">
      <Input
        className="ncss-input prl4-sm"
        errorMessage={!props.leadTimeData.seconds ? REQUIRED_FIELD : ''}
        id="leadTimeSeconds"
        label="Seconds"
        max="59"
        min="0"
        step="1"
        type="number"
        value={props.leadTimeData.seconds}
        onChange={({ target }) => {
          enforceMinMax(target);
          props.updateLeadTimeData(props.leadTimeData.hours, props.leadTimeData.minutes, target.value);
        }}
      />
    </article>
  </section>
);

LeadTimeInput.defaultProps = {
  labelClass: 'body-2',
};

LeadTimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  leadTimeData: PropTypes.shape({
    hours: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
    seconds: PropTypes.string.isRequired,
  }).isRequired,
  updateLeadTimeData: PropTypes.func.isRequired,
};

export default LeadTimeInput;
