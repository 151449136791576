import { Fn } from '../index.js';

/**
 * Chains multiple functions together.
 */
export const chain = (...fs: Fn<any, any>[]): Fn<any, any> => (arg: any) => fs.reduce((acc, f) => (f(acc)), arg);

/**
 * Composes multiple functions together.
 */
export const compose = (...fs: Fn<any, any>[]): Fn<any, any> => (arg: any) => fs.reduceRight((acc, f) => f(acc), arg);
