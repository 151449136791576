/* eslint-disable jsx-a11y/anchor-is-valid */
import {
 Link, Snack, Snackbar
} from '@nike/eds';
import { Default, isSome, match } from '@nike/rcf-fp';
import React, { useContext } from 'react';

import {
  Regions,
  Territories,
  Districts,
  Stores,
  HierarchyColumn
} from './hierarchy-columns';
import { StoreHierarchyContext } from './StoreHierarchyContextProvider';

const StoreHierarchy = () => (
  <>
    <div className="hierarchy-body">
      <HierarchyColumn component={<Regions />} />
      <HierarchyColumn component={<Territories />} />
      <HierarchyColumn component={<Districts />} />
      <HierarchyColumn lastColumn component={<Stores />} />
    </div>
  </>
);

const Error = () => {
  const { error, setLoadPage, setError } = useContext(StoreHierarchyContext);
  return (
    <Snackbar>
      {isSome(error) && (
      <Snack id="page-error" status="error" onDismiss={() => setError(null)}>
        <p>An error occurred - please&nbsp;
          <Link
            as="button"
            onClick={() => {
            setLoadPage(true);
            setError(null);
          }}
          >reload
          </Link>&nbsp;and try again.
        </p>
        <p>If the issue persists please contact support.</p>
      </Snack>
    )}
    </Snackbar>
  );
};

const Success = () => {
  const { successMessage, setSuccessMessage } = useContext(StoreHierarchyContext);
  return (
    <Snackbar>
      {isSome(successMessage) && (
      <Snack
        autoDismissDuration={5000}
        id="page-success"
        status="success"
        onDismiss={() => setSuccessMessage(null)}
      >
        <p>{successMessage}</p>
      </Snack>
    )}
    </Snackbar>
  );
};

const StoreHierarchyContainer = () => {
  const { error } = useContext(StoreHierarchyContext);
  return match(true)(
    [() => isSome(error), () => (<Error />)],
    [Default, () => (
      <>
        <Success />
        <StoreHierarchy />
      </>
    )],
  );
};

export default StoreHierarchyContainer;
