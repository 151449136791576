import PropTypes from 'prop-types';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';

const Links = (props) => (
  <nav className="ncss-row ta-sm-c pl2-sm pb4-sm">
    {/* display route to digital stores if you are not viewing digital stores */}
    {props.facilityType !== 'Digital Stores' && (
      <article className="ncss-col-sm-12 mt2-sm">
        <Link
          className="ncss-cta-primary-dark underline text-color-accent"
          to="/storelocationservices/digital-stores"
          onClick={() => <Redirect to="/storelocationservices/digital-stores" />}
        >
          GO TO DIGITAL STORES
        </Link>
      </article>
    )}
    {/* display route to stores if you are not viewing stores */}
    {props.facilityType !== 'Stores' && (
      <article className="ncss-col-sm-12 mt2-sm">
        <Link
          className="ncss-cta-primary-dark underline text-color-accent"
          to="/storelocationservices/stores"
          onClick={() => <Redirect to="/storelocationservices/stores" />}
        >
          GO TO STORES
        </Link>
      </article>
    )}
    {/* display route to warehouses if you are not viewing warehouses */}
    {props.facilityType !== 'Warehouses' && (
      <article className="ncss-col-sm-12 mt2-sm">
        <Link
          className="ncss-cta-primary-dark underline text-color-accent"
          to="/storelocationservices/warehouses"
          onClick={() => <Redirect to="/storelocationservices/warehouses" />}
        >
          GO TO WAREHOUSES
        </Link>
      </article>
    )}
  </nav>
);

Links.propTypes = {
  facilityType: PropTypes.string.isRequired,
};

export default Links;
