import { createSelector } from 'reselect';

// const example = { // waitline status
//   isFetchingTickets: false,
//   isSaving: false,
//   isDeleting: false,
//   errors: [],
//   statusByTicket: {
//     someTicketId: { // ticket status
//       isDeleting: false,
//       errors: [],
//     },
//   },
// };

const getTicketStatus = (waitlines, ticket) => {
  const status = {};

  const ticketRequest = waitlines.ticketRequests[ticket.id];
  status.isDeleting = ticketRequest && ticket.type === 'DELETE';

  const err = waitlines.ticketErrors[ticket.id];
  status.errors = (err) ? [err] : [];

  return status;
};

const getWaitlineStatus = (waitlines, waitline) => {
  const status = {};

  const fetchTicketsRequest = waitlines.waitlineTicketsRequests[waitline.id];
  status.isFetchingTickets = !!fetchTicketsRequest;

  const request = waitlines.waitlineRequests[waitline.id];
  status.isSaving = !!request && request.type === 'SAVE';
  status.isDeleting = !!request && request.type === 'DELETE';

  status.errors = [];
  const modifyWaitlineError = waitlines.waitlineErrors[waitline.id];
  if (modifyWaitlineError) {
    status.errors.push(modifyWaitlineError);
  }

  status.successes = [];
  const modifyWaitlineSuccess = waitlines.waitlineSaveSuccesses[waitline.id];
  if (modifyWaitlineSuccess) {
    status.successes.push(modifyWaitlineSuccess);
  }

  const fetchTicketsError = waitlines.waitlineTicketsErrors[waitline.id];
  if (fetchTicketsError) {
    status.errors.push(fetchTicketsError);
  }

  status.statusByTicket = {};
  if (!!waitlines.ticketsByWaitline && !!waitlines.ticketsByWaitline[waitline.id]) {
    waitlines.ticketsByWaitline[waitline.id].forEach((ticket) => {
      status.statusByTicket[ticket.id] = getTicketStatus(waitlines, ticket);
    });
  }

  return status;
};

const getStatusByWaitline = createSelector(
  (state) => state.waitlinesReducer,
  (waitlinesReducer) => {
    const result = {};
    if (Array.isArray(waitlinesReducer.waitlines)) {
      waitlinesReducer.waitlines.forEach((waitline) => {
        result[waitline.id] = getWaitlineStatus(waitlinesReducer, waitline);
      });
    }
    return result;
  },
);

export default getStatusByWaitline;
