import { get } from 'lodash';

import { all, any } from '../../utils/array';
import { facilityTypeValues, regionValues, serviceStatusValues } from '../../utils/static/sls-property-values';

const onlyWhitespace = /^(\s*)$/;
const repeatedWhitespace = /(\s+)/;
const storeSearchFields = ['id', 'storeNumber', 'name', 'company', 'address.city', 'region', 'description'];

const getStaticFilterOptions = (accessor) => {
    switch (accessor) {
      case 'facilityType':
        return facilityTypeValues.map((val) => ({ label: val, value: val }));
      case 'region':
        return regionValues.map((val) => ({ label: val, value: val }));
      case 'serviceStatus':
        return serviceStatusValues.map((val) => ({ label: val, value: val }));
      case 'configDefined':
        return [{ label: 'Defined', value: 'true' }, { label: 'Undefined', value: 'false' }];
      default:
        return [];
    }
  };

export const getFilterOptions = (facilities, filter) => [{ label: filter.label, value: '' }]
    .concat(getStaticFilterOptions(filter.accessor).length
      ? getStaticFilterOptions(filter.accessor)
      : [...new Set([].concat(...facilities.map((facility) => (typeof filter.accessor === 'string' ? get(facility, filter.accessor) : filter.accessor(facility)))))]
        .sort().map((code) => ({ label: filter.getLabel(code), value: code })));

export const fetchFilteredStores = (thisState, allStores) => {
  // define the filtering functions
  // if the filter doesn't have a value, default to true
  let regionFilter = () => true;
  let countryFilter = () => true;
  let stateFilter = () => true;
  let facilityTypeFilter = () => true;
  // let offeringFilter = () => true;
  // let storeServiceFilter = () => true;
  let searchFilter = () => true;
  let storeStatusFilter = () => true;

  if (thisState.region.value !== '') {
    regionFilter = (store) => (store.region === thisState.region.value);
  }
  if (thisState.country.value !== '') {
    countryFilter = (store) => (store.address ? store.address.country === thisState.country.value : false);
  }
  if (thisState.state.value !== '') {
    stateFilter = (store) => (store.address ? store.address.state === thisState.state.value : false);
  }
  if (thisState.facilityType.value !== '') {
    facilityTypeFilter = (store) => (store.facilityType === thisState.facilityType.value);
  }
  // if (thisState.offering.value !== '') {
  //   offeringFilter = (store) => (store.offerings ? store.offerings.map((offer) => offer.name).includes(thisState.offering.value) : false);
  // }
  // if (thisState.storeService.value !== '') {
  //   storeServiceFilter = (service) => {
  //     let { storeServices } = service;
  //     if (thisState.serviceStatus.value === 'DISABLED') {
  //       storeServices = storeServices.filter((services) => !services.enabled);
  //     } else if (thisState.serviceStatus.value === 'ENABLED') {
  //       storeServices = storeServices.filter((services) => services.enabled);
  //     }

  //     return storeServices ? storeServices.map((services) => services.serviceGroup).includes(thisState.storeService.value) : false;
  //   };
  // }
  if (thisState.search !== '') {
    const terms = thisState.search.split(repeatedWhitespace)
      .filter((s) => !onlyWhitespace.test(s))
      .map((s) => new RegExp(s, 'i'));
    searchFilter = (store) => all(
      (term) => any(
        (field) => typeof get(store, field) === 'string' && term.test(get(store, field)), storeSearchFields,
      ),
      terms,
    );
  }
  if (thisState.storeStatus.value !== '') {
    storeStatusFilter = (store) => (store.storeStatus === thisState.storeStatus.value);
  }
  return allStores.filter((store) => (
    regionFilter(store)
    && countryFilter(store)
    && stateFilter(store)
    && facilityTypeFilter(store)
    // && offeringFilter(store)
    // && storeServiceFilter(store)
    && searchFilter(store)
    && storeStatusFilter(store)));
};
