import { Checkbox, Loading } from '@nike/frame-component-library';
import { ModalActionsStyled, ModalStyled, Button } from '@nike/nike-design-system-components';
import { Default, isSome, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { initStoreOfferingAutoTranslation } from '../../../../utils/service-calls/sls';
import { ButtonSubmit } from '../../../reusable';

const ModalNewTranslation = ({
  fetchUpdatedOffering, languagesNotYetLocalized, offeringId, userToken,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetLanguages, setTargetLanguages] = useState([]);

  const [submitStatusMessage, setSubmitStatusMessage] = useState(undefined);
  const [isCancelOrClosedDisabled, setIsCancelOrClosed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheck = (value) => {
    const updatedTargetLanguages = !targetLanguages.includes(value)
      ? [...targetLanguages, value]
      : [...targetLanguages.slice(0, targetLanguages.indexOf(value)), ...targetLanguages.slice(targetLanguages.indexOf(value) + 1)];
    setTargetLanguages(updatedTargetLanguages);
  };

  const isTargetLanguages = (item) => targetLanguages.includes(item);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setTargetLanguages([]);
    setSubmitStatusMessage(undefined);
    setIsOpen(false);
  };

  const isTargetLanguagesEmpty = targetLanguages.length === 0;

  const isSomeMessage = isSome(submitStatusMessage);

  const displaySomeMessage = ({ message, status }) => match(status)(
    ['initiating', <p className="text-color-primary-dark ta-sm-c body-2 mt2-sm">{message}</p>],
    ['success', <p className="text-color-success ta-sm-c body-2 mt2-sm">{message}</p>],
    ['failed', <p className="text-color-error ta-sm-c body-2 mt2-sm">{message}</p>],
    [Default, null],
  );

  const isSaveDisabled = isTargetLanguagesEmpty || isSomeMessage;

  const saveTranslations = async () => {
    const offeringTranslationsToPost = {
      id: offeringId,
      targetLanguages,
    };

    return initStoreOfferingAutoTranslation(userToken, offeringTranslationsToPost);
  };

  const handleSave = async () => {
    setIsCancelOrClosed(true);
    setIsLoading(true);
    setSubmitStatusMessage({ message: 'Initiating autotranslate', status: 'initiating' });
    return saveTranslations()
      .then(async () => {
        await fetchUpdatedOffering();
        return setSubmitStatusMessage({ message: 'Successfully initiated autotranslations!', status: 'success' });
      })
      .catch((err) => setSubmitStatusMessage({ message: `Failed with error: ${err.message}`, status: 'failed' }))
      .finally(() => {
        setIsCancelOrClosed(false);
        setIsLoading(false);
      });
  };

  const Actions = () => (
    <ModalActionsStyled>
      <section>
        <Button
          appearance="primary"
          className="ncss-btn-primary-dark bg-accent mt6-sm ml2-sm border"
          disabled={isSaveDisabled}
          showErrorMessage={false}
          onClick={handleSave}
        >
          Save Translations
        </Button>
        <Button
          className="ncss-btn-secondary-dark mt6-sm ml2-sm border"
          disabled={isCancelOrClosedDisabled}
          label={isSomeMessage ? 'Close' : 'Cancel'}
          onClick={() => {
          setTargetLanguages([]);
          closeModal();
        }}
        >
          Cancel
        </Button>
      </section>
    </ModalActionsStyled>
  );
  const OpenModalButton = () => (
    <ButtonSubmit
      className="ncss-col-sm-12 full ta-sm-c mb10-sm"
      label="New"
      onClick={openModal}
    />
  );

  const languages = languagesNotYetLocalized.map(({ label, value }) => (
    <Checkbox
      key={label}
      isChecked={isTargetLanguages(value)}
      label={label}
      onChange={() => handleCheck(value)}
    />
  ));

  return (
    <>
      <OpenModalButton />
      <ModalStyled
        closeModal={closeModal}
        isOpen={isOpen}
        slots={{ FooterSlot: <Actions /> }}
        title="New Translation"
      >
        <article className="ncss-col-sm-12 va-sm-t">
          {isSomeMessage
            ? (
              <>
                {displaySomeMessage(submitStatusMessage)}
                {isLoading ? <Loading /> : null}
              </>
            )
            : languages}
        </article>
      </ModalStyled>
      <div id="modal-root" />
    </>
  );
};

ModalNewTranslation.defaultProps = {
  offeringId: '',
};

ModalNewTranslation.propTypes = {
  fetchUpdatedOffering: PropTypes.func.isRequired,
  languagesNotYetLocalized: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  offeringId: PropTypes.string,
  userToken: PropTypes.string.isRequired,
};

export default ModalNewTranslation;
