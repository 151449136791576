import { Checkbox, Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import {
  concepts, dimensions, jeCountries, jeDistricts, jeRegions, jeTerritories,
  locationTypes, orderCategories, salesAreas, storeSubConcepts,
} from '../../../../utils/static/sls-property-values';
import { CustomPanel, DateInput, Select } from '../../../reusable';

/* display form contents...
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => ( // not checking for adding here is intentional
  !!properties.justEnoughErrors.justEnoughTimezone
  || !!properties.justEnoughErrors.leadTime
  || !!properties.justEnoughErrors.orderCategory
  || !!properties.justEnoughErrors.ordersFlowDate
  || !!properties.justEnoughErrors.countryDesc
  || !!properties.justEnoughErrors.justEnoughRegion
  || !!properties.justEnoughErrors.concept
  || !!properties.justEnoughErrors.dimension
  || !!properties.justEnoughErrors.locationType
  || !!properties.justEnoughErrors.salesArea
  || !!properties.justEnoughErrors.storeSubConcept
  || !!properties.justEnoughErrors.territory);

class JustEnough extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.justEnoughErrors !== this.props.justEnoughErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Just Enough"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          errorMessage={this.props.justEnoughErrors.justEnoughTimezone}
          id="justEnoughTimezone"
          isDisabled={this.props.userIsReadOnly}
          label="Timezone"
          value={this.props.justEnough.justEnoughTimezone}
          onChange={(value) => this.props.updateJustEnough('justEnoughTimezone', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="areaSize"
          isDisabled={this.props.userIsReadOnly}
          label="Area Size"
          value={this.props.justEnough.areaSize}
          onChange={(value) => this.props.updateJustEnough('areaSize', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="sellAreaSize"
          isDisabled={this.props.userIsReadOnly}
          label="Sell Area Size"
          value={this.props.justEnough.sellAreaSize}
          onChange={(value) => this.props.updateJustEnough('sellAreaSize', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="areaUnitOfMeasure"
          isDisabled={this.props.userIsReadOnly}
          label="Area Measurement Unit"
          value={this.props.justEnough.areaUnitOfMeasure}
          onChange={(value) => this.props.updateJustEnough('areaUnitOfMeasure', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="storeCapacity"
          isDisabled={this.props.userIsReadOnly}
          label="Store Capacity"
          value={this.props.justEnough.storeCapacity}
          onChange={(value) => this.props.updateJustEnough('storeCapacity', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="apparelStoreCapacity"
          isDisabled={this.props.userIsReadOnly}
          label="Apparel Store Capacity"
          value={this.props.justEnough.apparelStoreCapacity}
          onChange={(value) => this.props.updateJustEnough('apparelStoreCapacity', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="equipmentStoreCapacity"
          isDisabled={this.props.userIsReadOnly}
          label="Equipment Store Capacity"
          value={this.props.justEnough.equipmentStoreCapacity}
          onChange={(value) => this.props.updateJustEnough('equipmentStoreCapacity', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="footwearStoreCapacity"
          isDisabled={this.props.userIsReadOnly}
          label="Footwear Store Capacity"
          value={this.props.justEnough.footwearStoreCapacity}
          onChange={(value) => this.props.updateJustEnough('footwearStoreCapacity', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="fax"
          isDisabled={this.props.userIsReadOnly}
          label="Fax"
          value={this.props.justEnough.fax}
          onChange={(value) => this.props.updateJustEnough('fax', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          errorMessage={this.props.justEnoughErrors.leadTime}
          id="leadTime"
          isDisabled={this.props.userIsReadOnly}
          label="Lead Time"
          value={this.props.justEnough.leadTime}
          onChange={(value) => this.props.updateJustEnough('leadTime', value.target.value)}
        />
      </article>
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.orderCategory}
        id="orderCategory"
        isDisabled={this.props.userIsReadOnly}
        label="Order Category"
        options={orderCategories.map((value) => ({ label: value, value }))}
        value={this.props.justEnough.orderCategory || ''}
        onChange={(value) => this.props.updateJustEnough('orderCategory', value)}
      />
      <DateInput
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.ordersFlowDate}
        id="ordersFlowDate"
        isDisabled={this.props.userIsReadOnly}
        label="Orders Flow Date"
        value={this.props.justEnough.ordersFlowDate || ''}
        onChange={(value) => this.props.updateJustEnough('ordersFlowDate', value)}
      />
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="shipToAccountNumber"
          isDisabled={this.props.userIsReadOnly}
          label="Ship To Account Number"
          value={this.props.justEnough.shipToAccountNumber}
          onChange={(value) => this.props.updateJustEnough('shipToAccountNumber', value.target.value)}
        />
      </article>
      <article className="ncss-col-sm-3 va-sm-t">
        <Input
          id="soldToAccountNumber"
          isDisabled={this.props.userIsReadOnly}
          label="Sold To Account Number"
          value={this.props.justEnough.soldToAccountNumber}
          onChange={(value) => this.props.updateJustEnough('soldToAccountNumber', value.target.value)}
        />
      </article>
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.countryDesc}
        id="countryDesc"
        isDisabled={this.props.userIsReadOnly}
        label="Country"
        options={jeCountries.map((value) => ({ label: value, value }))}
        value={this.props.justEnough.countryDesc || ''}
        onChange={(value) => this.props.updateJustEnough('countryDesc', value)}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.justEnoughRegion}
        id="justEnoughRegion"
        isDisabled={this.props.userIsReadOnly}
        label="Region"
        options={jeRegions}
        value={this.props.justEnough.justEnoughRegion?.code || ''}
        onChange={(value) => this.props.updateJustEnough('justEnoughRegion', { code: value, description: jeRegions.find((el) => el.value === value).label })}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.concept}
        id="concept"
        isDisabled={this.props.userIsReadOnly}
        label="Concept"
        options={concepts}
        value={this.props.justEnough.concept?.code || ''}
        onChange={(value) => this.props.updateJustEnough('concept', { code: value, description: concepts.find((el) => el.value === value).label })}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.dimension}
        id="dimension"
        isDisabled={this.props.userIsReadOnly}
        label="Dimension"
        options={dimensions}
        value={this.props.justEnough.dimension?.code || ''}
        onChange={(value) => this.props.updateJustEnough('dimension', { code: value, description: dimensions.find((el) => el.value === value).label })}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        id="district"
        isDisabled={this.props.userIsReadOnly}
        label="District"
        options={jeDistricts}
        value={this.props.justEnough.district?.code || ''}
        onChange={(value) => this.props.updateJustEnough('district', { code: value, description: jeDistricts.find((el) => el.value === value).label })}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.locationType}
        id="locationType"
        isDisabled={this.props.userIsReadOnly}
        label="Location Type"
        options={locationTypes}
        value={this.props.justEnough.locationType?.code || ''}
        onChange={(value) => this.props.updateJustEnough('locationType', { code: value, description: locationTypes.find((el) => el.value === value).label })}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.salesArea}
        id="salesArea"
        isDisabled={this.props.userIsReadOnly}
        label="Sales Area"
        options={salesAreas}
        value={this.props.justEnough.salesArea?.code || ''}
        onChange={(value) => this.props.updateJustEnough('salesArea', { code: value, description: salesAreas.find((el) => el.value === value).label })}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.storeSubConcept}
        id="storeSubConcept"
        isDisabled={this.props.userIsReadOnly}
        label="Store Sub Concept"
        options={storeSubConcepts}
        value={this.props.justEnough.storeSubConcept?.id || ''}
        onChange={(value) => this.props.updateJustEnough('storeSubConcept', { code: storeSubConcepts.find((el) => el.value === Number(value)).label, id: Number(value) })}
      />
      <Select
        className="ncss-col-sm-3 va-sm-t"
        errorMessage={this.props.justEnoughErrors.territory}
        id="territory"
        isDisabled={this.props.userIsReadOnly}
        label="Territory"
        options={jeTerritories}
        value={this.props.justEnough.territory?.code || ''}
        onChange={(value) => this.props.updateJustEnough('territory', { code: value, description: jeTerritories.find((el) => el.value === value).label })}
      />
      <article className="ncss-col-sm-3 va-sm-b">
        <Checkbox
          id="partnerDoor"
          isChecked={this.props.justEnough.partnerDoor === 'YES'}
          isDisabled={this.props.userIsReadOnly}
          label="Partner Door"
          onChange={({ target: { checked } }) => this.props.updateJustEnough('partnerDoor', checked ? 'YES' : 'NO')}
        />
      </article>
    </CustomPanel>
  );
}

JustEnough.propTypes = {
  justEnough: PropTypes.shape().isRequired,
  justEnoughErrors: PropTypes.shape().isRequired,
  updateJustEnough: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default JustEnough;
