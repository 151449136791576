import { Cmd, loop } from 'redux-loop';

import {
  STORE_SERVICES_FETCH,
  STORE_SERVICES_FETCH_SUCCESS,
  STORE_SERVICES_FETCH_FAILURE,
  storeServicesFetchSuccess,
  storeServicesFetchFailure,
  STORE_SERVICE_TYPES_FETCH,
  STORE_SERVICE_TYPES_FETCH_SUCCESS,
  STORE_SERVICE_TYPES_FETCH_FAILURE,
  storeServiceTypesFetchSuccess,
  storeServiceTypesFetchFailure,
} from '../actions/store-services';
import { fetchStoreServices, fetchStoreServiceTypes } from '../utils/service-calls/store-services';

const initialState = {
  currentStore: {},
  fetchStoreServicesError: '',
  fetchStoreServicesRequest: null,
  fetchStoreServiceTypesError: '',
  fetchStoreServiceTypesRequest: null,
  requestSerial: 1,
  storeId: null,
  storeServices: [],
  storeServiceTypes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SERVICES_FETCH: {
      if (action.storeId !== state.storeId || state.fetchStoreServicesRequest === null) {
        return loop(
          {
            ...state,
            fetchStoreServicesRequest: {
              requestNum: state.requestSerial,
              type: 'FETCH',
            },
            requestSerial: state.requestSerial + 1,
            storeId: action.storeId,
          },
          Cmd.run(fetchStoreServices, {
            args: [action.token, action.storeId, state.requestSerial],
            failActionCreator: (error) => storeServicesFetchFailure(state.requestSerial, action.storeId, error),
            successActionCreator: (data) => storeServicesFetchSuccess(state.requestSerial, action.storeId, data.body.objects),
          }),
        );
      }
      return state;
    }
    case STORE_SERVICES_FETCH_SUCCESS: {
      if (state.fetchStoreServicesRequest && state.fetchStoreServicesRequest.requestNum === action.requestNum) {
        return {
          ...state,
          fetchStoreServicesRequest: null,
          storeServices: action.storeServices,
        };
      }
      return state;
    }
    case STORE_SERVICES_FETCH_FAILURE: {
      if (state.fetchStoreServicesRequest && state.fetchStoreServicesRequest.requestNum === action.requestNum) {
        return {
          ...state,
          fetchStoreServicesError: action.error.message,
          fetchStoreServicesRequest: null,
          storeServices: [],
        };
      }
      return state;
    }
    case STORE_SERVICE_TYPES_FETCH: {
      if (state.fetchStoreServiceTypesRequest === null) {
        return loop(
          {
            ...state,
            fetchStoreServiceTypesRequest: {
              requestNum: state.requestSerial,
              type: 'FETCH',
            },
            requestSerial: state.requestSerial + 1,
          },
          Cmd.run(fetchStoreServiceTypes, {
            args: [action.token],
            failActionCreator: (error) => storeServiceTypesFetchFailure(state.requestSerial, error),
            successActionCreator: (data) => storeServiceTypesFetchSuccess(state.requestSerial, data.body.objects),
          }),
        );
      }
      return state;
    }
    case STORE_SERVICE_TYPES_FETCH_SUCCESS: {
      if (state.fetchStoreServiceTypesRequest) {
        return {
          ...state,
          fetchStoreServiceTypesRequest: null,
          storeServiceTypes: action.storeServiceTypes,
        };
      }
      return state;
    }
    case STORE_SERVICE_TYPES_FETCH_FAILURE: {
      if (state.fetchStoreServiceTypesRequest) {
        return {
          ...state,
          fetchStoreServiceTypesError: action.error.message,
          fetchStoreServiceTypesRequest: null,
          storeServiceTypes: [],
        };
      }
      return state;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
