import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CustomPanel, SelectMultiple } from '../../../reusable';

import { StoreContexts } from './store-contexts';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate (nothing currently for store services)
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (adding, formErrors) => !!(adding || formErrors.offerings);

const Service = ({
  adding,
  formData,
  formErrors,
  offeringsOptions,
  updateFormData,
  userIsReadOnly,
  username,
  userToken,
}) => {
  const [isOpen, setIsOpen] = useState(calculateIsOpen(adding, formErrors));
  const [publicOfferings, setPublicOfferings] = useState([]);
  const [privateOfferings, setPrivateOfferings] = useState([]);
  const publicOfferingOptions = offeringsOptions.filter((option) => option.publicOffering);
  const privateOfferingOptions = offeringsOptions.filter((option) => !option.publicOffering);

  const panelToggle = () => setIsOpen(!isOpen);

  const getOfferingsByPublicness = (isPublic, offerings = []) => offerings.filter((offering) => offering.publicOffering === isPublic);

  const updateOfferingsFormData = (isPublic) => (values) => updateFormData('offerings', [
    // Get all of our current offerings from the opposite list of publicness, then add all of the ones in from the list that we're currently editing
    ...getOfferingsByPublicness(!isPublic, formData.offerings),
    ...(values ? values.map((value) => value.offering) : []),
  ]);

  const updatePublicOfferingFormData = updateOfferingsFormData(true);
  const updatePrivateOfferingFormData = updateOfferingsFormData(false);

  useEffect(() => {
    setPublicOfferings(formData.offerings?.length ? formData.offerings.filter((offering) => offering.publicOffering) : []);
    setPrivateOfferings(formData.offerings?.length ? formData.offerings.filter((offering) => !offering.publicOffering) : []);
  }, [formData.offerings]);

  useEffect(() => {
    setIsOpen(isOpen && calculateIsOpen({ adding, formErrors }));
  }, [adding, formErrors, isOpen]);

  return (
    <CustomPanel
      isOpen={isOpen}
      label="Service"
      onClick={panelToggle}
    >
      <SelectMultiple
        errorMessage={formErrors.offerings}
        id="public-offerings"
        isDisabled={userIsReadOnly}
        label="Public Offerings"
        // filter ensures that a user is unable to select the same offering twice.
        options={publicOfferings
          ? publicOfferingOptions.filter((option) => !(publicOfferings.map((curr) => curr.code).includes(option.code.toString())))
            .map((offering) => ({ label: offering.name, offering, value: offering.code }))
          : publicOfferingOptions.map((offering) => ({ label: offering.name, offering, value: offering.code }))}
        values={publicOfferings ? publicOfferings.map((offering) => ({ label: offering.name, offering, value: offering.code })) : []}
        zIndex={24}
        onChange={updatePublicOfferingFormData}
      />
      <SelectMultiple
        errorMessage={formErrors.offerings}
        id="private-offerings"
        isDisabled={userIsReadOnly}
        label="Private Offerings"
        // filter ensures that a user is unable to select the same offering twice.
        options={privateOfferings
          ? privateOfferingOptions.filter((option) => !(privateOfferings.map((curr) => curr.code).includes(option.code.toString())))
            .map((offering) => ({ label: offering.name, offering, value: offering.code }))
          : privateOfferingOptions.map((offering) => ({ label: offering.name, offering, value: offering.code }))}
        values={privateOfferings ? privateOfferings.map((offering) => ({ label: offering.name, offering, value: offering.code })) : []}
        zIndex={23}
        onChange={updatePrivateOfferingFormData}
      />
      <StoreContexts
        formData={formData}
        updateFormData={updateFormData}
        userIsReadOnly={userIsReadOnly}
        userToken={userToken}
        username={username}
      />
    </CustomPanel>
  );
};

Service.propTypes = {
  adding: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  offeringsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default Service;
