import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { canadaProvinceValues, stateValues } from '../../../utils/static/sls-property-values';
import { Select } from '../../reusable';

const Address = (props) => (
  <section className="ncss-row">
    <header className="ncss-col-sm-12 headline-2">Address</header>
    <article className="ncss-col-sm-12 va-sm-t">
      <Input
        errorMessage={!props.disableCmpFields && props.addressErrors.address1}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Address 1"
        maxLength="40"
        value={props.address.address1 || ''}
        onChange={({ target: { value: address1 } }) => props.updateAddress('address1', address1)}
      />
    </article>
    <article className="ncss-col-sm-12 va-sm-t">
      <Input
        errorMessage={!props.disableCmpFields && props.addressErrors.address2}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Address 2"
        maxLength="40"
        value={props.address.address2 || ''}
        onChange={({ target: { value: address2 } }) => props.updateAddress('address2', address2)}
      />
    </article>
    <article className="ncss-col-sm-12 va-sm-t">
      <Input
        errorMessage={!props.disableCmpFields && props.addressErrors.address3}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Address 3"
        maxLength="40"
        value={props.address.address3 || ''}
        onChange={({ target: { value: address3 } }) => props.updateAddress('address3', address3)}
      />
    </article>
    <article className="ncss-col-sm-12 va-sm-t">
      <Input
        errorMessage={!props.disableCmpFields && props.addressErrors.city}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="City"
        value={props.address.city || ''}
        onChange={({ target: { value: city } }) => props.updateAddress('city', city)}
      />
    </article>
    <article className="ncss-col-sm-6 va-sm-t">
      {props.facilityType === 'Stores' && (props.address.country === 'USA' || props.address.country === 'CAN')
        ? (
          <Select
            errorMessage={props.disableCmpFields ? '' : props.addressErrors.state}
            id="stateSelect"
            isDisabled={props.userIsReadOnly || props.disableCmpFields}
            label={props.address.country === 'USA' ? 'State' : 'Province'}
            options={props.address.country === 'USA' ? stateValues : canadaProvinceValues}
            value={props.address.state || ''}
            zIndex={32}
            onChange={(state) => props.updateAddress('state', state)}
          />
        )
        : (
          <Input
            errorMessage={!props.disableCmpFields && props.addressErrors.state}
            isDisabled={props.userIsReadOnly || props.disableCmpFields}
            label="State"
            value={props.address.state || ''}
            onChange={({ target: { value: state } }) => props.updateAddress('state', state)}
          />
        )}
    </article>
    <article className="ncss-col-sm-6 va-sm-t">
      <Input
        errorMessage={!props.disableCmpFields && props.addressErrors.postalCode}
        isDisabled={props.userIsReadOnly || props.disableCmpFields}
        label="Postal Code"
        value={props.address.postalCode || ''}
        onChange={({ target: { value: postalCode } }) => props.updateAddress('postalCode', postalCode)}
      />
    </article>
    {props.facilityType === 'Stores' && (
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={props.userIsReadOnly || props.disableCmpFields}
          label="County"
          value={props.address.county || ''}
          onChange={({ target: { value: county } }) => props.updateAddress('county', county)}
        />
      </article>
    )}
  </section>
);

Address.propTypes = {
  address: PropTypes.shape().isRequired,
  addressErrors: PropTypes.shape().isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  facilityType: PropTypes.string.isRequired,
  updateAddress: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Address;
