import { useSynchronousDropzone, getFirstValidationError, IsMinImageDimensions } from '@nike/asset-delivery-distributed-assets-upload-component';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const replaceDefaultWithAutoTransform = (url) => url.replace('t_default', 'f_auto');

const Upload = ({
  duplicateImageError, minimumHeight, minimumWidth, prompt, setImageError, setImageUrl, setIsUploading, token, username,
}) => {
  const onError = useCallback(
    (errors) => {
      console.error(errors);
      const validationError = getFirstValidationError(errors);
      if (validationError) {
        setImageError(validationError.message);
      } else {
        setImageError('Failed to upload');
      }
    },
    [setImageError],
  );

  const onStartUploading = useCallback(() => {
    setIsUploading(true);
    setImageError('');
  }, [setIsUploading, setImageError]);

  const onStopUploading = useCallback(
    (results) => {
      // If it doesn't exist, then we should've gotten an error set in onError, but onStopUploading fires every time
      setIsUploading(false);
      const result = results?.[0];
      if (!result || !result.data) {
        return;
      }
      setImageUrl(replaceDefaultWithAutoTransform(result.data.externalUrl));

      // Check for duplicate asset
      if (result.isDuplicate && result.message) {
        setImageError(duplicateImageError);
      }
    },
    [duplicateImageError, setIsUploading, setImageUrl, setImageError],
  );

  const { getRootProps } = useSynchronousDropzone({
    authToken: token,
    createdBy: username,
    envName: process.env.NODE_ENV.toLowerCase() === 'production' ? 'prod' : 'test',
    onError,
    onStartUploading,
    onStopUploading,
    // eslint-disable-next-line no-magic-numbers
    validator: [IsMinImageDimensions(minimumWidth, minimumHeight)],
  });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...getRootProps()}>{prompt}</div>;
};

Upload.defaultProps = {
  duplicateImageError: 'Image already exists. Using existing Image URL',
  minimumHeight: 0,
  minimumWidth: 0,
  prompt: 'Drop new store image file here',
};

Upload.propTypes = {
  duplicateImageError: PropTypes.string,
  minimumHeight: PropTypes.number,
  minimumWidth: PropTypes.number,
  prompt: PropTypes.string,
  setImageError: PropTypes.func.isRequired,
  setImageUrl: PropTypes.func.isRequired,
  setIsUploading: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default Upload;
