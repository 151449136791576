import { Button, Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

// to be used for all delete buttons and failure downloads (which should have confirm={false})

const ButtonRedAligned = ({
  className, confirm, isDisabled, isLoading, label, onAway, onClick, onHover, confirmationMessage,
}) => (isLoading
  ? <Loading />
  : (
    <Button
      className={`ncss-btn-primary-dark bg-error border ${className}`}
      isDisabled={isDisabled}
      style={{ flexDirection: 'row', outline: 'none', verticalAlign: 'bottom' }}
      onClick={() => (confirm
        // eslint-disable-next-line no-alert
        ? (window.confirm(confirmationMessage)) && onClick()
        : onClick())}
      onMouseLeave={onAway}
      onMouseOver={onHover}
    >
      {label}
    </Button>
  )
);

ButtonRedAligned.defaultProps = {
  className: 'mt10-sm',
  confirm: true,
  confirmationMessage: "Please click 'OK' to confirm the deletion.",
  isDisabled: false,
  isLoading: false,
  onAway: () => {},
  onHover: () => {},
};

ButtonRedAligned.propTypes = {
  className: PropTypes.string,
  confirm: PropTypes.bool,
  confirmationMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  onAway: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func,
};

export default ButtonRedAligned;
