import { TextArea } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { loadState } from '../utils/local-storage';
import { emailFeedback } from '../utils/service-calls/reusable';
import { rosPermissions } from '../utils/tab-permissions';

import { ButtonSubmit, PageTemplate } from './reusable';

const helpFooter = () => (
  <footer className="ta-sm-l">
    <p className="headline-4">Encountering an issue?</p>
    <p className="body-2">Please create a&nbsp;
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://niketech.service-now.com/nav_to.do?uri=%2Fincident.do%3Fsys_id%3D-1%26sys_is_list%3Dtrue%26sys_target%3Dincident%26sysparm_checked_items%3D%26sysparm_fixed_query%3D%26sysparm_group_sort%3D%26sysparm_list_css%3D%26sysparm_query%3Dactive%3Dtrue%5Eassignment_group%3Ddd90bf891b240090ee09fd115b4bcbbc%26sysparm_referring_url%3Dincident_list.do%3Fsysparm_query%3Dactive%253Dtrue%255Eassignment_group%253Ddd90bf891b240090ee09fd115b4bcbbc@99@sysparm_first_row%3D1%26sysparm_target%3D%26sysparm_view%3D%26sysparm_view_forced%3Dtrue"
        rel="noopener noreferrer"
        target="_blank"
      >
        ServiceNow
      </a>
      &nbsp;ticket and assign it to <span className="text-color-accent">Retail-ROS</span>.
    </p>
    <p className="headline-4 mt4-sm">Questions?</p>
    <p className="body-2">If the &quot;Need Help?&quot; button in the top right corner and linked Confluence KB do not answer your questions, then please contact Retail Commerce Foundation (Rangers squad) at Lst-Dtcsolutions.RCFRanger-Devs@nike.com or on Slack at<span> </span>
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://nikedigital.slack.com/archives/C021UEBCKUY"
        rel="noopener noreferrer"
        target="_blank"
      >
        #rcf-rangers-support
      </a>. Thank you!
    </p>
  </footer>
);

const Home = ({ location }) => {
  const [feedback, setFeedback] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [failure, setFailure] = React.useState('');

  const { accessToken, email } = loadState('auth') || {};

  return (
    <main className="ncss-row ta-sm-c">
      <PageTemplate
        auth={rosPermissions}
        description="Please navigate through the app via the sidebar to the left."
        help={<footer className="ncss-col-sm-8">{helpFooter()}</footer>}
        page={(
          <section className="ncss-col-sm-8 mb12-sm">
            <article className="mt12-sm mb12-sm">
              <TextArea
                cols="100"
                id="feedback-input"
                label="Please help us improve this tool by leaving feedback:"
                name="feedback-input"
                placeholder="Please be specific as possible and be sure to mention which page you are using. For example: 'I believe the SLS page could be improved by...'"
                rows="10"
                value={feedback}
                onChange={({ target: { value } }) => {
                  setFeedback(value);
                  setSuccess('');
                  setFailure('');
                }}
              >
                {feedback}
              </TextArea>
              <ButtonSubmit
                isDisabled={!feedback}
                showErrorMessage={false}
                submitError={failure}
                onClick={() => emailFeedback(accessToken, { email, text: feedback })
                  .then((res) => {
                    setSuccess(res.body);
                    return setFailure('');
                  })
                  .catch((err) => {
                    setSuccess('');
                    return setFailure(err.message);
                  })}
              />
              <p className="text-color-success">{success}</p>
            </article>
            <footer>{helpFooter()}</footer>
          </section>
        )}
        path={location.pathname}
        title="Welcome to Retail Operations!"
      />
    </main>
  );
};

Home.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default Home;
