export default {
    objects: [
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '8f2e2e05-6f19-402e-894e-5f78667924dc',
        name: 'NIKE換季優惠店 台灣青埔',
        region: 'GREATER_CHINA',
        storeNumber: '3390'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8f31a177-8cc9-4d94-851e-7d0a0c754da9',
        name: '浙江省杭州市百丽富阳春秋北路富阳银泰BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '487096'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8f462e2e-f9ac-4695-8c84-2b131031ab42',
        name: '耐克武汉国际广场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '179809'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '8f464ffa-5d28-48ce-ab09-8defd61672f4',
        name: 'NIKE昆山前进换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3699'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '8f624f96-862c-4a79-9275-9946b84ec776',
        name: 'Nike Well Collective Donosti (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2418'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8f71bce3-dd7f-48ed-995a-8719ea5bf5f8',
        name: '나이키 영주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21671'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8f75c017-280c-457b-8d73-6252cf442488',
        name: '青海省西宁市城西区五四大街力盟步行街NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271002'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8f765a8e-76bd-4142-83df-9e2827177405',
        name: '上海莘庄仲盛购物中心BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '529298'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '8f7ca35d-4e92-4880-9833-854ef73ccda3',
        name: 'NIKE重庆奥特莱斯换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4030'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '8f9bc6bd-40ee-4aac-a0ce-b8fce7fbfa8f',
        name: 'Nike Santa Fe',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1963'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8ff8798f-7671-4f5e-9664-3f30bdeca762',
        name: '河南省周口市七一路万顺达BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '268842'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8ffd2496-980a-4d25-8af0-b4f431f2b1b8',
        name: 'TOPSPORTS海南海口金龙路友谊阳光城KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '288404'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
        id: '8ffe80fc-22d8-48db-9dcd-5088604ec774',
        name: 'Nike Factory Store Rotherham',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '897'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8ffed33d-9b17-4f2a-baaf-3fe3ef56061d',
        name: '锐力江苏扬州广陵区运河北路SM-MALLNIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '292583'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '901b8890-c4b8-4e6a-9b3f-587a611af373',
        name: '天津河西区万象城 BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '270835'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '903ed353-77da-4e1f-80d4-5a1f1d99b419',
        name: 'NIKE厦门梧侣换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3828'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9043f920-cc1f-4b27-943b-683e14caae73',
        name: '三明万达广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '189186'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9063c39d-4f1b-4a31-afec-e479f67aedc4',
        name: '成都天府大悦城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293688'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: '908F43C5-0DD7-408A-B3EE-71F5A22641CA',
        name: 'Nike Factory Store - Cabazon',
        region: 'NORTH_AMERICA',
        storeNumber: '38'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '909b3de3-1aa6-4d23-a9fc-64ec36b76d85',
        name: '河南省平顶山市卫东区万达广场BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '195415'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: '909d4dcf-10b3-4263-9a73-e101500b265c',
        name: 'Nike Factory Store Soltau',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '686'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
        id: '90B3AA51-C7A3-4161-AC5E-B5CCBD5195CB',
        name: 'Nike Factory Store - Cancun',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6613'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '90b82355-576d-4447-894a-b5cf16f0b5ac',
        name: '나이키 첨단',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '36548'
      },
      {
        address: {
          country: 'MYS'
        },
        id: '90bc19fc-773f-48b5-a4d4-ae0231a3aa3f',
        name: 'Nike Pavilion Bukit Jalil',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8081'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '90bdd977-4f97-4eb1-b1fb-14efed00200f',
        name: '湖南省株洲市滔搏湖南株洲芦淞区新华西路999号王府井百货1+2F BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '271315'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '90be9f46-d1da-488a-8fe5-cdc0c53f76c6',
        name: 'REALLY湖北武汉光谷大洋百货KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '6717'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '90ca4300-e565-47ac-ba6a-907349b0ee2c',
        name: '广东揭阳榕城区临江南路万达广场NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '272412'
      },
      {
        address: {
          country: 'ARG'
        },
        id: '90d18e5c-dca6-4fe0-88a2-4f8a0207ae5a',
        name: 'Nike Buenos Aires',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6514'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '125d973d-d862-4d06-b2b2-5d14f960d4af',
        id: '90ebfe0d-0bd2-4369-b5b1-d29f33fde29d',
        name: 'Nike Factory Store Malaga',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '505'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '90f23170-4cce-48e8-8d08-d3b42977dcc3',
        name: '나이키 울산 무거',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78531'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '90f7816a-7d77-4d7c-9d00-4df13edc2931',
        name: 'Nike Store Kyiv Respublika (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR056'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '9107cc1c-8f5d-493b-8d3e-318fc090c012',
        name: 'Converse Factory Store - Potomac Mills',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3124'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '911ace35-a4fd-4f19-8f2c-abe33e22344c',
        name: '耐克上海恒基名人购物中心KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '240462'
      },
      {
        address: {
          country: 'THA'
        },
        id: '91327be5-3085-453f-8f27-d0a4d8c0c3c6',
        name: 'Nike Central Plaza Pinklao',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2745'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '913ab2fb-e0e7-48e7-a649-947c2c48de76',
        name: '耐克武汉江汉路体验店',
        region: 'GREATER_CHINA',
        storeNumber: '242963'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '913bf88b-2a81-44df-856a-e893a2104af7',
        name: '江苏省南京市百丽南京江北大洋NK店',
        region: 'GREATER_CHINA',
        storeNumber: '481614'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '913eaf2d-d2a7-4805-af8d-ea20345dc6ab',
        name: 'Nike Factory Store - Zona Leste',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6352'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9162f5b6-360e-40b2-bad0-862fb0d6b723',
        name: 'REALLY湖北武汉龙阳大道摩尔城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288842'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '91957a3d-4b96-4339-8e32-ec52256d879a',
        name: '河北石家庄市瑞城万达KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '242327'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '91AC92CA-74BE-4E2C-9964-4BB697F23EC0',
        name: 'Nike Factory Store - Glendale',
        region: 'NORTH_AMERICA',
        storeNumber: '386'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '91b6e7e2-3d44-485f-b90b-6bbe30788027',
        name: '江苏省无锡市贡湖大道方圆荟SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273481'
      },
      {
        address: {
          country: 'GBR'
        },
        id: '91dc3468-68dc-4961-8968-3f1e88ec9365',
        name: 'Converse Brand Outlet Store Wembley',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8123'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '91e3a14c-270b-4f83-ab5b-b901b1e36dc3',
        name: 'NIKE济南唐冶换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4010'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '91e62b12-3194-4496-9740-ec14cc047ffa',
        name: 'TOPSPORTS广东广州白云区白云大道北888号安华汇1F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '294560'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '91f21ee8-20e0-4763-ac15-393b333da9b5',
        name: 'Nike Clearance Store - Santa Clarita',
        region: 'NORTH_AMERICA',
        storeNumber: '250'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '92090d5d-7fbc-4505-9d20-8e3d99c641f7',
        name: 'Nike SM Clark',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6596'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '92117c05-a7ad-418c-9556-0d5809e78f6a',
        name: 'REALLY浙江杭州滨江银泰NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274489'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9243993d-ef16-4054-871b-834141ce2aa5',
        name: 'TOPSPORTS上海顾唐路花园城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288813'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '925686f9-971e-4fe7-8aae-85d45da851b0',
        name: '山东省济南市历下区万象城BEACON-L2',
        region: 'GREATER_CHINA',
        storeNumber: '260089'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9278cf69-8f3b-4753-b9a9-7bb7988c43a7',
        name: 'SANSE广东广州广州大道北信达金茂KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287205'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '928ae594-94f3-4d5a-97ec-0d60250b1207',
        name: '重庆市南岸区南坪南路南坪万达广场BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '240415'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '928cf2fe-5c57-4d6e-a58d-2febb1397b95',
        name: '辽宁省沈阳市于洪区黄海路新玛特 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '267105'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9290d27e-2a19-4334-99c5-4f3449d922d8',
        name: '辽宁省沈阳市沈阳市皇姑区北行佳禾TOTAL HOOPS-L2',
        region: 'GREATER_CHINA',
        storeNumber: '189656'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '92a178c8-2272-48f4-b993-24ca7c1c08cb',
        name: 'Nike Store Pristina Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'RKS102'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '92a76868-bfea-4532-a013-b8fa16dc7c36',
        name: '福州长乐路世欧广场NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '519028'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '92bd0b9a-0601-4762-b0a4-f72357875553',
        name: 'Converse Factory Store - Assembly Row',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3789'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '84065b43-8aa0-4415-b314-5ff958ddbecd',
        id: '92c7db27-2ad9-4286-a462-9f595429c6ad',
        name: 'Nike Clearance Store Alicante',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '509'
      },
      {
        address: {
          country: 'IND'
        },
        id: '92f35192-32f3-4362-b124-f0221d1832d3',
        name: 'Nike Delhi',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4911'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '92f90f46-8c17-4b94-9712-730509cdf8a9',
        name: '安徽省合肥市宝胜合肥漫乐城Kicks lounge-L2',
        region: 'GREATER_CHINA',
        storeNumber: '244480'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9300df7e-06e2-4869-a5ad-3670857ecdfe',
        name: '浙江省宁波市锐力宁波天一广场酷购KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '271060'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
        id: '9315e12a-d215-4d78-8156-18e36a5ac788',
        name: 'Nike Well Collective - Boulder',
        region: 'NORTH_AMERICA',
        storeNumber: '453'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
        id: '93206C3C-CCE4-4F1F-82B3-3400E3AA3FAB',
        name: 'Nike Factory Store - Plaza Central',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6616'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '93254971-33d1-43ab-beef-7da473484b62',
        name: 'Nike Outlet Belgrade Ava Retail Park (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER110'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '932ac18c-02bf-4bdd-ba9c-01dd59cb08bd',
        name: 'NIKE OFF SITE - NIKE COMPANY STORE SERVICE CENTER',
        region: 'NORTH_AMERICA',
        storeNumber: '297'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '932ae46a-7c04-4765-b3a4-f498626e21b9',
        name: '北京市延庆区万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269338'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '932d2151-c77f-46f2-b2df-8d9b1c7cc534',
        name: '天津市津南区津歧公路新城吾悦广场NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '259359'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '933dd049-eb51-4ead-af14-d23aa8887919',
        name: 'POUSHENG贵州安顺国贸KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288845'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '938000c2-00e9-4267-b061-72a7d1683e1ae',
        name: 'NIKE合肥国际换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3880'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9390cd48-ae53-4614-b6da-35e48970903d',
        name: '广东省深圳市盛世长运深圳来福士广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '268837'
      },
      {
        address: {
          country: 'HUN'
        },
        id: '9396f1cf-2f92-4951-bda9-7b5583ff3b69',
        name: 'Nike Store Árkád (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2463'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '93a7fc61-9fad-42aa-97f3-1db1e8cb705c',
        name: 'Nike Brentwood',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD20'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '93a8aa1b-62d4-4cc6-bb46-643c8a5d5608',
        name: '上海市锐力上海虹口区东大名路外滩来福士Beacon-350',
        region: 'GREATER_CHINA',
        storeNumber: '270832'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '93bdf0f0-9204-48cb-a93d-10c0db0cb678',
        name: '나이키 롯데 광복',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '39862'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '93c180ee-234e-4194-8d84-cd91999d2b6b',
        name: 'Nike Well Collective - 롯데 전주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87368'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '93fa1766-f70e-4a9b-b34a-f40027552101',
        name: 'Nike Ambarrukmo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4129'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '94069d8f-3f75-479b-b7c1-66c772b51c9a',
        name: '河南省三门峡市湖滨区梦之城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241446'
      },
      {
        address: {
          country: 'BGR'
        },
        id: '940d2c29-ab60-494e-bf98-34594352916c',
        name: 'Nike Store Sofia Paradise Center (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BUL127'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '941169e1-1901-4b95-b508-046551b4efd3',
        name: 'Nike Store Rosny (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2799'
      },
      {
        address: {
          country: 'SGP'
        },
        districtId: 'cd913270-82a6-4a1e-9b7e-d4730ba22184',
        id: '9423e354-8f0d-4c8f-839c-cb531bba1e31',
        name: 'Nike Unite - CCP',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8003'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '942eb2d7-8fc5-4a5d-83bd-b6674a0fadae',
        name: '耐克扬州万象汇体验店',
        region: 'GREATER_CHINA',
        storeNumber: '259052'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '94348e3e-8ce6-4890-9549-c91655bbec02',
        name: 'POUSHENG四川成都龙湖北城天街KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287320'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9448c080-fd99-43e5-83c2-cc6c1f2f26da',
        name: '宁夏回族自治区银川市银川金凤新华联购物中心NIKE SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '260431'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '945dd4d1-5f72-4756-aab2-d7b58d2944fc',
        name: 'TOPSPORTS浙江杭州古墩路龙湖天街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287339'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '946bc6fc-3688-4b18-b513-cb366a11c65b',
        name: 'Nike Ripley Costanera',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7166'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '9472e014-513f-4801-9b07-ca836b4fee6c',
        name: 'NIKE西安三星换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3935'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '948d3c61-10fe-41aa-b0c6-f518d9106e07',
        name: '安徽省合肥市宝胜合肥银泰中BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '281923'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '94914a93-34b2-4e15-bf14-3006128866a8',
        name: '山东省济宁市兖州区贵和NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272139'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
        id: '94CDFB25-5F45-48B6-ABC9-EB3F59FD616A',
        name: 'Nike Factory Store - San Luis Potosi',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6625'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '94ae3023-b348-4599-8ead-03028c85aced',
        name: 'Nike Well Collective - Bodrum Midtown (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2331'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '94f69ecb-3aee-4a0b-8024-e2a8a91f223b',
        name: 'NSP Zaragoza',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2374'
      },
      {
        address: {
          country: 'CZE'
        },
        districtId: '3c364fbe-617b-46b1-b867-1579b1e478f2',
        id: '94f8d3c7-7277-47af-a7b0-e0cbdd750c27',
        name: 'Nike Clearance Store Hate',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '562'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '9505e79c-2b80-497f-9adb-4880c8cce3d8',
        name: 'Nike Well Collective La Canada (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2434'
      },
      {
        address: {
          country: 'ARG'
        },
        id: '950bb045-e7cf-47c7-8466-a68e32f35e18',
        name: 'Unicenter',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6530'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '95104102-dcb2-47e0-81c5-3ad22b538e3e',
        name: '나이키 반야월',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21801'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: '9518043D-E070-4F80-BD23-9D9957EBF6F3',
        name: 'Nike Factory Store - Long Beach',
        region: 'NORTH_AMERICA',
        storeNumber: '227'
      },
      {
        address: {
          country: 'SGP'
        },
        districtId: 'cd913270-82a6-4a1e-9b7e-d4730ba22184',
        id: '95342d3a-3e60-4645-b5ec-8b9bf576e044',
        name: 'Nike Unite - IMM',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8005'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '954be4f9-24d7-4433-914b-b041e6b2b36c',
        name: '天津市武清开发区福源道武清万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270670'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '955c5739-6a3f-4acb-9baf-c1b5feb2d7b0',
        name: 'TOPSPORTS广东深圳市福田区华强北茂业天地七楼NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '195150'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '957cfd90-2e40-4dae-a85d-b7ce2407594d',
        name: 'REALLY江苏如皋中山路文峰大世界KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288462'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9591e4f2-2e58-4772-8b5a-fc50f4d654a0',
        name: '陕西省咸阳市西安市咸阳区人民中路世纪金花BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '190869'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '05f259e2-5c3f-43b8-aae2-8a59f239af36',
        id: '95a9fd35-f1af-4c95-8327-84fb651fc39f',
        name: 'Nike Factory Store London Taplow',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '972'
      },
      {
        address: {
          country: 'CHE'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: '95c78c1d-40ff-49b3-80e5-e3d5de6f196d',
        name: 'Nike Factory Store Fashion Fish',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2002'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '95cdab74-6bfc-42f1-ab20-15236aea55c6',
        name: '重庆市渝北区秋成大道158号龙湖中央公园天街NIKE SPORTS-L',
        region: 'GREATER_CHINA',
        storeNumber: '272167'
      },
      {
        address: {
          country: 'AZE'
        },
        id: '95d91bf1-0904-4c56-8b48-08368ffe822c',
        name: 'Nike Store Baku Deniz Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'AZE003'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '95daba21-4b41-4e84-af64-69863d6e1704',
        name: 'Nike Store Vadi (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2624'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '95eea82b-710f-4db2-a912-82af0f7b395d',
        name: 'TOPSPORTS吉林省长春农安繁华路88号 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287471'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '95fe5da0-6ae8-41e6-8e4e-2810cddc8156',
        name: 'POUSHENG河南濮阳黄河路万嘉广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '275347'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '960e6ba0-1f0f-4cee-a6f6-246d40fed900',
        name: '河北省保定市朝阳北大街保百超市 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '481507'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '96514e08-70a2-4e7e-85cd-e7d78fccf672',
        name: '福州工业路华润万象城NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '274210'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '965182b8-964a-47dd-a8d0-acd77eb7dd69',
        name: '天津蓟州区南环路万达广场NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '272149'
      },
      {
        address: {
          country: 'BEL'
        },
        id: '965d25fc-f051-400e-b80c-cd157d8ff523',
        name: 'Nike Well Collective - Wijnegem (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2439'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '965d26c0-fea6-46b1-a2e1-998c80c04e00',
        name: 'TOPSPORTS安徽滁州来安建阳南路白云商厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287629'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '965d3e0a-d408-4351-b7c0-57f1d25d3032',
        name: '重庆市杨家坪万象城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269237'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '9664eb97-52c1-40bd-8716-3e54bac53a01',
        name: 'NIKE江阴万达换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3686'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: '9669251A-5A7B-4833-840B-4DAABB9366E0',
        name: 'Nike Factory Store - Riverhead',
        region: 'NORTH_AMERICA',
        storeNumber: '3'
      },
      {
        address: {
          country: 'IND'
        },
        id: '967494a7-26a0-42de-8189-ab51b8f5d97b',
        name: 'Nike TTK Road',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5070881'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9682fe52-385d-4c63-b98c-3f766ebecffb',
        name: '耐克上海晶品体验店',
        region: 'GREATER_CHINA',
        storeNumber: '190160'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '968bde8e-fe08-4ae8-b185-f26fdde8c8c5',
        name: 'HIGHWAVE SPORTS四川南充顺庆区红光路1227购物广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288470'
      },
      {
        address: {
          country: 'ZAF'
        },
        districtId: '43bbce93-a2ad-4606-a7df-205395646fdf',
        id: '968ecc50-5189-48a2-9e78-5e9c97944f03',
        name: 'Nike Unite Durban',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '952'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '969775ce-9eca-4c94-8919-081282a0ad51',
        name: '宁夏固原新华百货NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '287527'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: '96C36FC7-C43B-4CB0-876F-5FC4ED9850C3',
        name: 'Nike Factory Store - Newark',
        region: 'NORTH_AMERICA',
        storeNumber: '223'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '96EA7949-FEC4-4273-8135-0DA7C57402FE',
        name: 'Nike Factory Store - Grand Rapids',
        region: 'NORTH_AMERICA',
        storeNumber: '197'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: '96a96ea2-12a5-4e04-a993-787e79b13ac3',
        name: 'Nike Well Collective - Bethesda',
        region: 'NORTH_AMERICA',
        storeNumber: '538'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '96abae70-a261-4df2-a68c-baee187e92bd',
        name: '广东省惠州市惠城区三环南路32号隆生金山湖中心1F BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '269450'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '96b63ff7-929f-4f94-a933-c3ee02e4d82d',
        name: '青海省西宁市城东区中惠万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272378'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '96f5f871-d32b-4bf7-b2e7-3c1d222ed1eb',
        name: '나이키 봉담',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '26891'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9733175a-57c6-4430-a13d-4d00d4f86f5b',
        name: '云南昆明呈贡吾悦广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271286'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '9d2a3b18-08e9-46c4-93be-dc8d1bfc1f1a',
        id: '973d657f-7d2b-43bf-ac50-a9f8947b4eb4',
        name: 'Nike Unite Milano Scalo',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '914'
      },
      {
        address: {
          country: 'CHE'
        },
        id: '973f4133-9610-40e6-b175-6eccd2986ef4',
        name: 'Nike Well Collective - Glatt (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2422'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '974e64b7-595f-4595-a092-03151587dcb2',
        name: 'NIKE银川大连路换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3846'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9753b5de-b995-4940-b553-8bb125066b45',
        name: '北京市华联丽泽天地购物中心 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '274450'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: '97612f6a-f318-4cf5-8f38-75d648e2e5bc',
        name: 'Nike Cumbres',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6643'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '976c529b-0384-4732-8937-44a9724ec76b',
        name: 'Converse Factory Store - Twin Cities Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3793'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9773a43f-72fd-4645-8406-1e25c8f238bd',
        name: '广东省广州市盛世长运广州市番禺天河城Beacon-L2-M（750）',
        region: 'GREATER_CHINA',
        storeNumber: '269307'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9782c16e-c65d-4c1e-ae70-f9e2bba66f30',
        name: '天津市塘沽区金元宝Beacon-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '258614'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: '97CB40E6-B6A1-46AB-9BCF-FBA3B721EEAF',
        name: 'Nike Factory Store - West Palm Beach',
        region: 'NORTH_AMERICA',
        storeNumber: '213'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '97a8158d-2387-44dc-9f11-c2958c59fdba',
        name: 'TOPSPORTS福建厦门演武西路世贸EMALLNIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293810'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '97af5b88-5fd8-45c3-bda6-00082b8d6036',
        name: '나이키 신세계 의정부',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '48682'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '97c6f5fd-1424-4488-b6b8-97f4a71fb0aa',
        name: '江苏省南京市锐力江宁金鹰NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274014'
      },
      {
        address: {
          country: 'HKG'
        },
        districtId: 'c49dc1d3-fbc1-47bf-989b-a11a394bffb5',
        id: '97c701a4-9885-4215-a609-327208f21974',
        name: 'NIKE KICKS LOUNGE体验店 (海港城)',
        region: 'GREATER_CHINA',
        storeNumber: '3205'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '97cfa39d-d858-440d-b232-8bad99d616ca',
        name: 'NIKE LAVAL',
        region: 'NORTH_AMERICA',
        storeNumber: 'S232'
      },
      {
        address: {
          country: 'FIN'
        },
        id: '97d877c0-c274-4c6f-807c-5df8fe2eeb3b',
        name: 'NIKE STORE ESPOO ISO OMENA',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2407'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9805c8e2-69d6-4a92-91b4-cec3c610f9f5',
        name: '北京丰台区大成路翠微NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287295'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '98063c80-9836-4564-8a55-24acc08ccd93',
        name: 'SHERWAY GARDENS',
        region: 'NORTH_AMERICA',
        storeNumber: '468982'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '98277525-3050-46b2-9df5-1703374265f2',
        name: '安徽省合肥市宝胜合肥步行街百盛购物中心KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '2126'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '982de7b0-3a10-4b27-8781-08e2d9344e9c',
        name: 'TOPSPORTS浙江慈溪新城大道南路新城吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287282'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '983046b4-e3c3-4b14-ae34-0b7dc04de2a1',
        name: 'REALLY上海邯郸路五角场万达NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288188'
      },
      {
        address: {
          country: 'ARG'
        },
        id: '98332713-2b89-439b-8ae7-d84ff3c83355',
        name: 'Nike Factory Store - Quilmes',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6507'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9834e63d-8888-4244-ac73-0ba19b619f96',
        name: '上海市锐力上海一二八纪念路宝山万达KL',
        region: 'GREATER_CHINA',
        storeNumber: '529301'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '985161db-007b-4438-ac45-69c9cd12bcc1',
        name: 'Nike Well Collective - Maltepe (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2340'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '98862750-5722-451d-aca2-a6a0b5c0d7f4',
        name: 'NIKE深圳中怡换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3852'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '98b695f8-e720-4fd7-b44e-802ceddef334',
        name: '重庆市巴南区龙洲大道万达广场KL-L3',
        region: 'GREATER_CHINA',
        storeNumber: '527920'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '98b9db09-2617-4c1b-897e-ddf5e301699b',
        name: '江苏省苏州市宝胜龙湖狮山天街BEACON750',
        region: 'GREATER_CHINA',
        storeNumber: '268881'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: '98bdf49a-4cfa-42ea-94a0-1ff454b0bfcd',
        name: 'Nike Well Collective - Woodcliff Lake',
        region: 'NORTH_AMERICA',
        storeNumber: '471'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '98d642f0-2309-46de-b03b-820c3142813b',
        name: 'NIKE重庆大学城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3810'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: '98e0b077-8f7f-4c77-902a-a29c29e3b50c',
        name: 'Nike Well Collective - Natick',
        region: 'NORTH_AMERICA',
        storeNumber: '597'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: '98e3c907-dbd6-42d0-b7b5-cb739087e22c',
        name: 'Nike Factory Store Gretna',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '634'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '98e92346-4359-4142-a82b-c4a6e8f7ad14',
        name: 'Nike Store Mersin Forum (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2778'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '99004b56-82f8-4df7-8cd1-09ca23a51afb',
        name: 'Nike Well Collective - Eugene',
        region: 'NORTH_AMERICA',
        storeNumber: '319'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '9905ef89-aed9-44fd-8c16-ce740189a97a',
        name: 'NIKE珠海翠微换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3739'
      },
      {
        address: {
          country: 'IND'
        },
        id: '991b2e1d-2e6e-4b76-b419-32a198fca3fd',
        name: 'Nike Elante New',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5050750'
      },
      {
        address: {
          country: 'SVK'
        },
        id: '991dbe4d-6850-4c54-b8a5-db046cbb3469',
        name: 'Nike Well Collective - Petrzalka (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2445'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '992d8d11-dfee-4706-b007-9220e09e3f18',
        name: '나이키 논산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '48935'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '992dd60a-849a-4776-bd66-acd74b6beb36',
        name: '山东省威海市环翠区新威路振华商厦 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272764'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9940dbd2-79e0-4332-a82a-1cc90bb31e14',
        name: '黑龙江省大庆市黑龙江省大庆让胡路区乘风大街新玛特 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '194651'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: '9948ed8d-b00c-42e9-9c48-9bdd04ebbfc5',
        name: 'Nike Clearance Store Magdeburg',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '677'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '99529ca3-08a5-472b-85d8-ca88c3572620',
        name: '福建省晋江市宝胜泉州晋江SM广场莱雅BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '4187'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '995893ad-5429-42d9-8d85-66140a88ad60',
        name: 'Nike Store Genova (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2498'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '995c30ea-0cd0-4f3a-921a-fde787dd1fea',
        name: '湖南省郴州市百丽郴州友阿购物中心 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271179'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '99664e6d-99d8-4080-91c1-c274197cdf79',
        name: '湖北省武汉市锐力武汉中山大道大洋百货 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '8525'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '84065b43-8aa0-4415-b314-5ff958ddbecd',
        id: '996dbfdf-f503-41e4-b221-a523edde74a7',
        name: 'Nike Unite Valencia Bonaire',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '908'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '2a0898a2-fe79-4fa0-8d7d-b8c63500ced5',
        id: '999fc899-77b9-4ff3-9b49-6f9a60406885',
        name: 'Nike Well Collective Haussmann',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '770'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '99be9780-5bfc-4f65-927b-0f1aa3e3fffe',
        name: '广东省汕头市百丽汕头苏宁广场BEACON',
        region: 'GREATER_CHINA',
        storeNumber: '267199'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '99d2a0d7-b750-4427-ab32-edc35569e649',
        name: 'TOPSPORTS安徽长丰蒙城北路世纪金源NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273662'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '99eb0116-8ac3-4323-9579-05bb43a8da3f',
        name: '나이키 롯데 목포',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72671'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: '9AAEAC25-B68F-4C5A-B087-F4D2C789D895',
        name: 'Nike Factory Store - Branson',
        region: 'NORTH_AMERICA',
        storeNumber: '164'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: '9BBAC688-7ED8-45F0-B04D-C56B54CD1346',
        name: 'Nike Factory Store - Pleasant Prairie',
        region: 'NORTH_AMERICA',
        storeNumber: '25'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'de5b82a0-e649-4103-9387-cf868dd85899',
        id: '9BBCFDA5-1145-4FF4-8CC9-6203FD620A51',
        name: 'Nike Well Collective - Upper East Side',
        region: 'NORTH_AMERICA',
        storeNumber: '359'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '9C659645-36E8-4B8D-AF29-573B48C75E27',
        name: 'Nike Factory Store - Charleston',
        region: 'NORTH_AMERICA',
        storeNumber: '101'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: '9D62808A-C081-42F1-BF69-2ECA538B5138',
        name: 'Nike Factory Store - Orlando International',
        region: 'NORTH_AMERICA',
        storeNumber: '88'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
        id: '9a0513da-389f-4f48-9466-19efbd3ba293',
        name: 'Nike Factory Store Muiden',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '981'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9a11cca0-4da6-4cb4-9d53-ca1fac1519b3',
        name: 'POUSHENG广东深圳沙井中心路天虹KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288190'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9a318f80-1ee2-4265-8a79-b8be5eafae9e',
        name: '北京天宫院凯德BEACON L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '527926'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '9a4238e9-0a6d-429d-a4fe-db3c100384e4',
        name: 'Converse Factory Store - Williamsburg Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3707'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: '9a660f4e-4b5e-4612-8863-8b8f9c929d71',
        name: 'Nike Clearance Store - Kissimmee',
        region: 'NORTH_AMERICA',
        storeNumber: '291'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '9a721464-c91c-4583-a504-0013c4d6b173',
        name: 'NIKE深圳深国投换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3452'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: '9a729cfa-320d-4b7b-adc9-edd3c5f96249',
        name: 'Nike Factory Store Munich Parsdorf',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '691'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
        id: '9a87e500-3702-4e5a-a0a4-f45aba2cc67c',
        name: 'Nike Unite Leicester',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2057'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '9a9679ab-c391-45ca-8f4c-df13687b9b31',
        name: 'Nike Store Nis Delta Planet Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER109'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '9a9c7537-1c2e-4d46-a3a6-c8c36aa46d3a',
        name: 'NIKE昆明世纪城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3493'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '9ab05e30-2d8e-4705-ab1c-31d3f52effea',
        name: 'Converse Factory Store - Great Lakes Crossing Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3765'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9abd1256-37a6-49aa-8c21-9629d191f210',
        name: '济南北宸天街NK-M',
        region: 'GREATER_CHINA',
        storeNumber: '293443'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9ad2f083-6d0a-4e5e-a354-4fe8ec922c61',
        name: 'POUSHENG 河北衡水大庆东路怡然城KICK LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287323'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9adb1ec8-7ebe-484b-b061-3a74afa0601a',
        name: '江苏省盐城市滔搏盐城新都东路宝龙城市广场NK-S',
        region: 'GREATER_CHINA',
        storeNumber: '270745'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '9ae10a16-8d60-4a92-bfdc-eaa2e5f00857',
        name: 'NIKE温州瓯海换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3870'
      },
      {
        address: {
          country: 'BHR'
        },
        id: '9af1b481-5003-4c2e-b7cd-46dac0fa2a6a',
        name: 'Nike Store BAHREIN CITY CENTRE MG',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BAH002'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9af347d0-e447-45ae-8375-fe66114ed831',
        name: '宜宾叙州莱茵春天NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288830'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9b1612ea-e374-4757-a144-d418e7477ff6',
        name: '浙江省宁波市锐力浙江宁波市海曙区中山东路天一银泰KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '267205'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9b203b9f-5f0b-4574-983f-4788e1f35b6a',
        name: '湖南长沙梅溪湖步步高NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '258434'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9b31fc8b-5c5c-4778-8da4-4140663ee174',
        name: '天津市西青区大寺镇万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274290'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: '9b3f0541-fd7a-40ef-ab9c-9a500c5ac4a5',
        name: 'Nike Factory Store Minamiosawa',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3015'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: '9b5d9db2-ce55-403a-b2dd-a8e427aac72b',
        name: 'Nike Unite - Salem',
        region: 'NORTH_AMERICA',
        storeNumber: '449'
      },
      {
        address: {
          country: 'NZL'
        },
        id: '9b5e5619-f250-466c-982d-8ffafce8a22d',
        name: 'Nike Christchurch',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1464'
      },
      {
        address: {
          country: 'KAZ'
        },
        id: '9b68f115-a23e-452e-b915-55261356e45a',
        name: 'Nike Store Astana Mega Silk Way (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KAZ024'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9b6d511e-3790-4f19-9b63-721fb4b23c42',
        name: '上海市百丽上海闸北大宁国际广场 Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '180701'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '9b71242f-7fda-495f-9a40-60908f6a2336',
        name: "Nike Store Val D'Europe (Partnered)",
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2602'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9b76f0bc-4082-477f-a740-0c036a440322',
        name: '广东省广州市盛世长运广州市增城区新塘万达广场SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '240314'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9b7e7a99-58e5-4277-ad96-af4679add078',
        name: '耐克北京摩方KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '189464'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9b919e3c-47f3-4398-b2db-802948c93bce',
        name: '北京市朝阳区龙湖长楹天街KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '269295'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '9b946f52-c854-49c1-b470-60fbe389d6f3',
        name: 'NIKE成都华侨城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3448'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '9b9deb1a-64af-44cb-9ce1-f60d0be42903',
        name: 'Nike Plaza Norte',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6968'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '9ba925c7-84c3-4055-b1d0-3b9e971b3003',
        name: 'NIKE上海杨树浦换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3670'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9bb24297-2100-449f-bbb1-0ae1acccf03a',
        name: 'JORDAN 77 SONGHU',
        region: 'GREATER_CHINA',
        storeNumber: '440364'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9bb2ab4e-4746-4a62-8d77-888024cc5da1',
        name: 'POUSHENG辽宁本溪市华联NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287344'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: '9bbb8979-4941-494f-a4d7-ba7b4cca0fb5',
        name: 'Nike Clearance Store Talke',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '642'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66772996-562e-4025-a37f-33b8bab87475',
        id: '9bc4714d-5d6d-4221-b0aa-e9648614881d',
        name: 'Chicago Pop Up',
        region: 'NORTH_AMERICA',
        storeNumber: 'C5404'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '9bfea7d1-d6d4-46e2-9237-0e83e95487d5',
        name: 'NIKE STORE MADRID CASTELLANA',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2375'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '9c03597b-41cf-483f-bcef-0d37f0e0be5a',
        name: 'Salmon Street',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6210'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9c10d687-9906-4d32-a5f8-645f2a351fb2',
        name: 'HIGHWAVE SPORTS四川眉山文忠路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288454'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9c395838-6df1-4e0d-8393-af732f7a659d',
        name: '浙江省衢州市衢江区东方广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272142'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '9c3a5c46-ca46-44a6-9372-d65abe39e402',
        name: 'Converse Factory Store - The Outlet Shoppes at Atlanta',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3781'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9c86a4cc-9e7c-4b39-9d0e-7e3f030ff265',
        name: '江西省南昌万象城NK RISE 1200',
        region: 'GREATER_CHINA',
        storeNumber: '274477'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9c8a523a-d5df-4e3f-9fa3-9db23802aad4',
        name: '厦门嘉禾SM新生活NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '10845'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '9c93e583-885e-438b-8bdd-cbc6b8b0adb8',
        name: 'Converse Brand Outlet Store Scalo Milano',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8116'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9ce5a6e6-e3dd-4e8f-a829-e6630da99ee8',
        name: '河北省衡水市桃城区育才大街万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274481'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '9d0b507c-839c-4b63-8b14-c66f8dad4e97',
        name: '나이키 광주 경기',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85551'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '9d1377d5-340f-4da3-aa77-b1987b045c02',
        name: 'Nike Guildford',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD5'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '9d1fd518-a623-4975-a9aa-5b105c705ef7',
        name: 'Nike Royal Mount',
        region: 'NORTH_AMERICA',
        storeNumber: 'S235'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9d5a9c0c-e45d-4533-988c-1a787b72c430',
        name: 'POUSHENG江苏省无锡市梁溪区无锡大东方百货KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287300'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9d5c6fa1-2b6f-42a2-b9b2-70788a7d1a98',
        name: '上虞购物中心NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '241172'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9d794063-50c6-4bdc-85c7-81c7d0d0a044',
        name: '北京海淀区双安商场 Beacon L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '200000'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9d93a9ca-921b-4a28-8e64-d4b754127b54',
        name: '山东省烟台市芝罘区中粮大悦城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '260219'
      },
      {
        address: {
          country: 'ARE'
        },
        id: '9dd9cbef-1e86-42ac-a812-b5749b3f1b1d',
        name: 'Nike Mirdiff City Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UAE105'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9e449fd9-3844-4118-9362-344971b022c6',
        name: 'TOPSPORTS河南开封龙亭区郑开大街万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288058'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '9e59d701-a551-4e8d-8c1d-e6368a616453',
        name: '나이키 홍성',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '47423'
      },
      {
        address: {
          country: 'IND'
        },
        id: '9e61a9c7-f2d9-4ef2-b4dd-258d9bf31502',
        name: 'Nike VR Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2846'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9ec5d87c-5210-4a30-8638-c47e51c3d469',
        name: '海南省海口市万象城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274254'
      },
      {
        address: {
          country: 'GEO'
        },
        id: '9ee41988-2f1b-439b-954e-3fb0a34ac444',
        name: 'Nike Store Batumi (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'GEO004'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: '9f0417b2-1e49-4089-8b39-5ca238a6d16e',
        name: 'Nike Well Collective - Scarsdale',
        region: 'NORTH_AMERICA',
        storeNumber: '455'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '9f0b39b5-82fd-411b-956b-ce581d010fac',
        name: '나이키 롯데 청량리',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '91129'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1c3af8dc-d58a-4495-909d-823d7d8f09e1',
        id: '9f0ff2fd-f0b6-4bf7-a8fa-3e19e1c39d6e',
        name: 'Nike Factory Store Castel Guelfo',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '568'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f179816-59b9-4276-a0a0-d6aaabb71fd9',
        name: '天津市滨海新区第一大街K11 NIKE SPORT M店',
        region: 'GREATER_CHINA',
        storeNumber: '271159'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f1ffc5a-6c0f-4e72-827c-324440642d57',
        name: '辽宁省大连市中山区柏威年NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272377'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: '9f5718cc-41ee-42c4-b465-13ba6fc792d1',
        name: 'Nike Unite Uni Hill',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6170'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f5fa7a1-88e7-44e1-a004-b48f4b0c7f89',
        name: '浙江省丽水市丽水万地广场Nike KL-2',
        region: 'GREATER_CHINA',
        storeNumber: '258588'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f6ddc81-6d22-4716-8af2-3fae64f87634',
        name: '浙江绍兴国商BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '529284'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '9f73fca0-8d07-4f48-b362-9f6cacc840c0',
        name: 'NIKE昆明环湖换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3934'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f7564bf-9844-41bc-933c-cf6122936f13',
        name: '合肥之心城NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '279061'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f818cf9-edea-4cd8-8e87-66dbf40280a8',
        name: '贵州省贵阳市贵阳市观山湖区万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269359'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f942454-0078-4d25-a08d-92c1ec078f52',
        name: '湖北省武汉市滔搏湖北武汉江汉区航测村青年路龙湖江宸天街1F  NIKE  SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271152'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '9f9d12dc-f5bf-4729-9de7-dd2a51253b46',
        name: 'Nike Factory Store - SLEX',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9296'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9f9ddac6-2b83-4ec7-b7d7-f79b7901392b',
        name: '陕西省西安市西安市雁塔区高新区唐延路五环TOTAL HOOPS-L2',
        region: 'GREATER_CHINA',
        storeNumber: '105598'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '9fc2b589-a05e-4424-9c81-e130e2ccc89b',
        name: '河北省秦皇岛市秦皇岛世纪港湾一期KICKS LOUNGE-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '282165'
      },
      {
        address: {
          country: 'BLR'
        },
        id: '9fc93565-8954-4f32-b9aa-0a8165a17d09',
        name: 'Nike Store Minsk Nemiga (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BYE008'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '9ff70195-9eab-41de-b3d3-4b35659b0ce5',
        name: 'Nike Thalia Street',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA052'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: 'A34F6507-9004-4B52-A5C5-6847497B4B9E',
        name: 'Nike Clearance Store - Alpine',
        region: 'NORTH_AMERICA',
        storeNumber: '4'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
        id: 'A629AD0A-0087-415A-A9CE-3B2AB47BDB1A',
        name: 'Nike Factory Store - Plaza Aeropuerto',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6633'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: 'A70E557C-E0FD-44E0-B298-98D09F6C6581',
        name: 'Nike Factory Store - Kittery',
        region: 'NORTH_AMERICA',
        storeNumber: '188'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: 'A731176C-E234-462A-A960-E2EA3479DC6E',
        name: 'Nike Factory Store - Wrentham',
        region: 'NORTH_AMERICA',
        storeNumber: '106'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: 'A83B21A0-93C5-4822-A9C0-C7C990B2D70E',
        name: 'Nike Factory Store - Folsom',
        region: 'NORTH_AMERICA',
        storeNumber: '35'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: 'AA2C68CE-6E8E-4A0C-863B-FE7EB4BC5E29',
        name: 'Nike Clearance Store - San Leandro',
        region: 'NORTH_AMERICA',
        storeNumber: '377'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: 'B07867A5-957D-4521-8FE3-FBB7FFA2FB59',
        name: 'Nike Clearance Store - Auburn Hills',
        region: 'NORTH_AMERICA',
        storeNumber: '338'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: 'B117E7CE-007B-465D-A937-345B2DC203D7',
        name: 'Nike Factory Store - Foxwoods',
        region: 'NORTH_AMERICA',
        storeNumber: '228'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: 'B3495965-07CF-4E18-81E1-335E5549F1ED',
        name: 'Nike Factory Store - St Augustine',
        region: 'NORTH_AMERICA',
        storeNumber: '102'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: 'B886B856-F161-48E9-8C68-1CB70253AFA5',
        name: 'Nike Factory Store - Las Plazas Outlet',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6608'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: 'B949E736-43C6-4BD7-A2F2-DBCB56EC96CE',
        name: 'Nike Factory Store - Orange',
        region: 'NORTH_AMERICA',
        storeNumber: '189'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: 'B9DFB6B6-965D-46C1-A49A-CB3DC5901FD1',
        name: 'Nike Factory Store - Williamsburg  VA',
        region: 'NORTH_AMERICA',
        storeNumber: '41'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: 'BD5147A2-EA22-4664-AC33-94575118E64F',
        name: 'Nike Factory Store - Atlantic City',
        region: 'NORTH_AMERICA',
        storeNumber: '140'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '011c974d-ad7b-4a21-ae1b-ff52a2a066cd',
        id: 'BD654649-D97F-4945-9CF8-1BD18E0BBF10',
        name: 'Nike Factory Store - Punta Norte',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6607'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: 'BE4117C4-4EF7-46E6-84FE-29C1152F84D5',
        name: 'Nike Factory Store - Jackson',
        region: 'NORTH_AMERICA',
        storeNumber: '79'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: 'BE6F2069-8113-4632-8C64-A1CFCBAC145D',
        name: 'Nike Factory Store - Chesterfield',
        region: 'NORTH_AMERICA',
        storeNumber: '202'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: 'BF2BC5C9-C60E-40E5-9C2D-835C78263144',
        name: 'Nike Factory Store - Tinton Falls',
        region: 'NORTH_AMERICA',
        storeNumber: '118'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: 'BF9B3874-7CE3-4B78-8E2B-DDBC88381AEC',
        name: 'Nike Factory Store - Jersey Gardens',
        region: 'NORTH_AMERICA',
        storeNumber: '173'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: 'BFC0AF16-3A10-4F1F-BA64-6A62B7F1D261',
        name: 'Nike Atlanta',
        region: 'NORTH_AMERICA',
        storeNumber: '201'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: 'C0B0107D-847B-47D0-817B-65B3E7A83157',
        name: 'Nike Factory Store - Michigan City',
        region: 'NORTH_AMERICA',
        storeNumber: '137'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: 'C575F28A-5C2A-4E01-81B9-228E3AB08B30',
        name: 'Nike Factory Store - Miami',
        region: 'NORTH_AMERICA',
        storeNumber: '185'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: 'C5E2DB2B-DBD5-41FE-91CE-91D774F1E9D5',
        name: 'Nike Factory Store - Milpitas',
        region: 'NORTH_AMERICA',
        storeNumber: '123'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: 'C800947D-A871-45FF-8E78-09CBA6AC08B9',
        name: 'Nike Factory Store - Deer Park',
        region: 'NORTH_AMERICA',
        storeNumber: '167'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: 'C814906A-DD52-473A-B8BA-8FF1A4C2201D',
        name: 'Nike Factory Store - Gilroy',
        region: 'NORTH_AMERICA',
        storeNumber: '37'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: 'C885B040-BDF3-487D-880C-CCA5B8332706',
        name: 'Nike Factory Store - Tampa',
        region: 'NORTH_AMERICA',
        storeNumber: '235'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: 'CD9D0C53-6360-402C-B14C-49DB36C15260',
        name: 'Nike Factory Store - San Ysidro',
        region: 'NORTH_AMERICA',
        storeNumber: '30'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: 'D0AD9D5C-6F3B-44C8-973B-35D6EC2143F2',
        name: 'Nike Factory Store - San Marcos',
        region: 'NORTH_AMERICA',
        storeNumber: '42'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: 'D1948822-0F89-495E-B430-99B89AE1772B',
        name: 'Nike Clearance Store - Johnson Creek',
        region: 'NORTH_AMERICA',
        storeNumber: '21'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: 'D1B912A2-A145-4548-B202-75A1298AAD30',
        name: 'Nike Factory Store - Texas City',
        region: 'NORTH_AMERICA',
        storeNumber: '321'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: 'D1FF1870-74F9-4800-8A0D-4AF649B86CA3',
        name: 'Nike Factory Store - Tannersville',
        region: 'NORTH_AMERICA',
        storeNumber: '120'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: 'D46326EF-5ACC-487A-A35E-A4F999A8BCBD',
        name: 'Nike Unite - Gloucester',
        region: 'NORTH_AMERICA',
        storeNumber: '219'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: 'D89FDD7A-DF6A-46AA-80F9-826660EC882D',
        name: 'Nike Clearance Store - Williamsburg  IA',
        region: 'NORTH_AMERICA',
        storeNumber: '22'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: 'DAB0A619-2B6F-4634-8C0A-F193E8CE3809',
        name: 'Nike Factory Store - Oklahoma City',
        region: 'NORTH_AMERICA',
        storeNumber: '331'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: 'DBC55BEB-CD46-4E26-B98F-26409E18E6D1',
        name: 'Nike Factory Store - Albertville',
        region: 'NORTH_AMERICA',
        storeNumber: '16'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: 'DCB97823-B9BB-45DA-97A9-4BA2810D3BCC',
        name: 'Nike Factory Store - Oceanside',
        region: 'NORTH_AMERICA',
        storeNumber: '358'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: 'DDE74A2E-822D-4809-AC70-9D6BEB5683D1',
        name: 'Nike Factory Store - Charlotte',
        region: 'NORTH_AMERICA',
        storeNumber: '203'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: 'DEA0D0DD-A02B-4710-806F-C3E312085F66',
        name: 'Nike Factory Store - Barstow',
        region: 'NORTH_AMERICA',
        storeNumber: '216'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: 'DEB665B3-B2B7-4C22-A686-62CD05832128',
        name: 'Nike Factory Store - Asheville',
        region: 'NORTH_AMERICA',
        storeNumber: '242'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: 'E01A8DA4-C916-4AB8-8DF6-C3C6827EA456',
        name: 'Nike Factory Store - Grand Prairie',
        region: 'NORTH_AMERICA',
        storeNumber: '357'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
        id: 'E1F0828A-20BE-4821-8BCF-670E00898080',
        name: 'Nike Factory Store - Castle Rock',
        region: 'NORTH_AMERICA',
        storeNumber: '73'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: 'E27A0871-F1C5-4682-B685-26312C828CF8',
        name: 'Nike Factory Store - Lincoln City',
        region: 'NORTH_AMERICA',
        storeNumber: '178'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: 'E2F9693E-64D0-4C7D-8626-7211D26D12A2',
        name: 'Nike Factory Store - Columbus',
        region: 'NORTH_AMERICA',
        storeNumber: '217'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: 'E3352D13-8B4C-47FE-B4BE-71A7296473E2',
        name: 'Nike Factory Store - Tulare',
        region: 'NORTH_AMERICA',
        storeNumber: '121'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: 'E58EEB87-34FC-4DA4-ADAA-C093DFDA27CF',
        name: 'Nike Factory Store - Des Moines',
        region: 'NORTH_AMERICA',
        storeNumber: '376'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: 'E8479098-554E-4DE0-A2EC-A23D5783EF17',
        name: 'Nike Factory Store - Ellenton',
        region: 'NORTH_AMERICA',
        storeNumber: '63'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: 'EDEA1F6C-4CC4-4E4B-B9F6-41AF4EB813DE',
        name: 'Nike Factory Store - Gonzales',
        region: 'NORTH_AMERICA',
        storeNumber: '163'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: 'EEA50BE4-3877-470B-9539-829801C7DB84',
        name: 'Nike Factory Store - Finger Lakes',
        region: 'NORTH_AMERICA',
        storeNumber: '138'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '011c974d-ad7b-4a21-ae1b-ff52a2a066cd',
        id: 'EF499329-79C5-4F67-B237-609C23EAEF42',
        name: 'Nike Unite Lerma',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6606'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: 'EFEDBCF2-B98D-40DA-B6E3-8FBBDC1CCD0C',
        name: 'Nike Factory Store - Smithfield',
        region: 'NORTH_AMERICA',
        storeNumber: '40'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
        id: 'F0B0A829-2DB9-4484-9FDF-2E4179392FD0',
        name: 'Nike Unite Puebla',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6628'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: 'F19D419D-61D9-428A-90BF-E6DDD104D4AB',
        name: 'Nike Factory Store - Bend',
        region: 'NORTH_AMERICA',
        storeNumber: '53'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: 'F21F535B-D799-4CBF-9C08-77C7D97FE514',
        name: 'Nike Factory Store - Lancaster',
        region: 'NORTH_AMERICA',
        storeNumber: '116'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: 'F47DCCB5-ED58-4220-BAF9-0ED4E2F6F651',
        name: 'Nike Factory Store - Lawrenceville',
        region: 'NORTH_AMERICA',
        storeNumber: '9'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: 'F64F1B94-1255-4A34-B7EF-11EC506963BA',
        name: 'Nike Factory Store - Orlando Vineland',
        region: 'NORTH_AMERICA',
        storeNumber: '96'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
        id: 'F75D1222-DA0C-4D67-9B80-B003FC1C6209',
        name: 'Nike Factory Store - North Bend',
        region: 'NORTH_AMERICA',
        storeNumber: '76'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: 'F987DBC4-474B-4A35-AA07-4E2B1F7886A1',
        name: 'Nike Factory Store - Leesburg',
        region: 'NORTH_AMERICA',
        storeNumber: '71'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: 'FA55A450-F321-41E3-AA2B-12C95709A8CF',
        name: 'Nike Factory Store - Little Rock East',
        region: 'NORTH_AMERICA',
        storeNumber: '244'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'FB26A2C4-A129-4197-A6DF-6018F9A94347',
        name: 'Nike Factory Store - Sao Goncalo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6308'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'FF9C3F0D-78F5-4072-ACEB-A4BC50B8857E',
        name: 'Nike Factory Store - Premium RJ',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6337'
      },
      {
        address: {
          country: 'AUT'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: 'a01be878-0be5-44c2-b95f-218bbd5fcf1b',
        name: 'Nike Factory Store Parndorf',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '517'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a027db33-d92b-4713-82a3-a38def3a2f74',
        name: '恩施九立方购物中心NK',
        region: 'GREATER_CHINA',
        storeNumber: '269318'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'a0350826-f040-4c20-b896-4d839c8605a4',
        name: 'Nike Store Marmaris (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2335'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: 'a06ef974-d3a9-4d82-b469-4547ca0969a8',
        name: 'Converse Factory Store - Rio Grande Valley Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3716'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a080b830-ebb3-4bb7-85fc-6876f4749134',
        name: 'POUSHENG安徽省合肥市胜道潮流汇NIKE RISE 1200',
        region: 'GREATER_CHINA',
        storeNumber: '279060'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a0820d41-6299-426b-9692-3a15e8f7f23c',
        name: '四川成都银泰中心BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '266737'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: 'a0879fca-1b69-49d2-adda-415c1a9a64c6',
        name: 'NIKE換季優惠店 台中三井',
        region: 'GREATER_CHINA',
        storeNumber: '3314'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '84065b43-8aa0-4415-b314-5ff958ddbecd',
        id: 'a0ae3991-0170-409b-afbb-b4bbf13867c7',
        name: 'Nike Factory Store Mallorca',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '551'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: 'a0bcde11-ecaf-4b66-bb58-21b8e21e17db',
        name: 'Nike Unite - H Street',
        region: 'NORTH_AMERICA',
        storeNumber: '482'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'a0d28950-180f-4419-b4e5-9b453f1b7145',
        name: 'Nike Inorbit Baroda',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3986'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a14495d4-ce9b-4c7d-906c-9bcb0ac73664',
        name: '辽宁省沈阳市大东区大悦城 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '524965'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a1523e56-76d0-4bf0-a8ea-689c726204b5',
        name: 'HIGHWAVE SPORTS四川绵阳涪城路锦盛NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287345'
      },
      {
        address: {
          country: 'POL'
        },
        id: 'a16e414a-4d24-4cab-9143-3099cd71f9e7',
        name: 'Nike Store Wroclavia (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2403'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a174a982-10e3-449e-9f97-347764e4f15e',
        name: '广东省湛江市宝胜湛江市开发区滨海大道万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '453685'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'a1787a8a-01dd-4a45-b142-24244825af54',
        name: 'Nike Karrinyup',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5075'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a17b80dd-af78-4f9f-b59c-f98b71a9df55',
        name: '나이키 신세계 하남',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71724'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'a18cb672-0a68-459d-971d-2d0f27e8628d',
        name: 'Nike Store Istinye Park (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2613'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a1daa66c-718e-496b-b236-a25205253b8e',
        name: '厦门集美银泰百货NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '269406'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: 'a1db8996-211b-4321-b691-d4c765a95746',
        name: 'Nike Factory Store Kisarazu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3163'
      },
      {
        address: {
          country: 'HRV'
        },
        id: 'a1e1d65c-cfd2-4bf2-a5fd-999b78513b39',
        name: 'NIKE STORE ARENA CENTAR II',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2354'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a1e465cf-f9d0-47aa-90e8-eacb7de2e0ad',
        name: '重庆市渝北区光环购物公园NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270999'
      },
      {
        address: {
          country: 'HRV'
        },
        id: 'a1efabd9-9aa2-4139-bbbb-8ad93c104cf8',
        name: 'NIKE STORE SUPERNOVA II',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2381'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a1fad112-9ad5-44f8-b8db-bdf7b2708b00',
        name: 'REALLY温州瑞安虹桥南路206号KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '5104'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'a1fe590e-f626-4937-846e-e64aed1c4fa4',
        name: 'Nike Parque Delta',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1964'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a20695a6-69e6-4675-8dd6-52b8ff99c978',
        name: '浙江省绍兴市银泰城 NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '274018'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a2189335-eb1e-4e2b-8acb-750e0150c3ad',
        name: '宿州CBD万达NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '279081'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a220989c-1cb3-4aa6-aaae-c54e8e43fe37',
        name: 'POUSHENG江西抚州金巢大道硕果时代购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288473'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a2400751-23b5-427f-af0b-0cd9cfdc567a',
        name: '广东省梅州市百丽梅州客都汇商场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '114742'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a26bdb8f-eb35-4b24-ab41-d8cd5b232f78',
        name: 'Wuxi Bailian Outlets FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6005'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a276091e-778b-40b1-a657-c0810ec0f47d',
        name: '贵州省贵阳市云岩区北京西路万达广场 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269236'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a279b237-129c-4d0d-8e67-448635bbf933',
        name: '重庆市合阳大道绿港财富广场 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270756'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a27baf0b-a5c7-414f-ad32-b913d79ef143',
        name: '山东省诸城市诸城百盛SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '6293'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'a29076fb-b292-442b-babc-eb1b56e78f6c',
        name: 'Nike Store SSS MALL OF DHAHRAN',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA104'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a2967134-68ff-4a37-bd7a-f9cf501599ef',
        name: '河北省邯郸市邯郸市丛台区美乐城NIKE BEACON-L2 EXTED',
        region: 'GREATER_CHINA',
        storeNumber: '258597'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: 'a2a490ed-3360-4bba-a7b3-dc1380b2af05',
        name: 'NIKE南昌华南换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3745'
      },
      {
        address: {
          country: 'ESP'
        },
        id: 'a2a93fa9-9f2e-485d-a34c-907c511c4f25',
        name: 'Nike Store Barcelona (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2793'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'a2aa4c0a-8900-450b-9f6b-5f875976b0e0',
        name: 'Nike Qurtubah',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA073'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a2ab9a30-4c7e-4d54-ac9a-22e4fe95c135',
        name: '나이키 만수',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '82264'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
        id: 'a2b980b2-99fb-4fc0-b785-93d98a976039',
        name: 'Nike Unite Mansfield',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '633'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a2cb5350-ddec-4202-a154-4c14b0c76418',
        name: '江苏省泰州市锐力海陵区南通路茂业天地BEACON 750',
        region: 'GREATER_CHINA',
        storeNumber: '269523'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a2e64f04-b06e-4180-8bef-23e0686f306d',
        name: '山东省济南市山东省莱芜市莱城区银座商城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '260223'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a2eb70ae-959f-461f-a22a-f6df572986fa',
        name: '江苏省盐城市锐力金鹰购物中心Kicks Lounge L1',
        region: 'GREATER_CHINA',
        storeNumber: '274016'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: 'a2ef2c6a-98b1-4bc5-84fe-f8e2da4ea17a',
        name: 'Nike Factory Store Paris Usines',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a2f5b0a4-c8cb-49c0-9b85-860b0346989c',
        name: '耐克河南丹尼斯大卫城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '189599'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: 'a33e5817-3e4f-4211-8844-2a6cbaf3d112',
        name: 'Nike Unite - Princeton',
        region: 'NORTH_AMERICA',
        storeNumber: '446'
      },
      {
        address: {
          country: 'ROU'
        },
        id: 'a348a00d-1ad1-4d9c-9c16-f41984c1e10a',
        name: 'Nike Store Cluj Iulius Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM112'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: 'a3563dd6-1dc7-421a-ae14-570a031558f4',
        name: 'NIKE合肥里河换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3675'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a35faaea-1b74-4319-9334-edc29d17df35',
        name: 'POUSHENG安徽肥西紫蓬路百大NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '242451'
      },
      {
        address: {
          country: 'ROU'
        },
        id: 'a3723945-a79c-4cd2-9f9f-46e61191269c',
        name: 'Nike Store Brasov Afi Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM107'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: 'a37f9158-4107-47e3-862a-e952b9f06922',
        name: 'NIKE乌鲁木齐东进场换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4027'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a39d558a-b019-4e3a-be7b-8d8fb650a17c',
        name: '山西省大同市中央公园BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '270475'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: 'a3aa9986-aedf-4f22-93c8-aa06ea8feba2',
        name: 'NIKE长沙大元换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4007'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a3c754e2-c121-4787-8b32-371ecdceaf0f',
        name: '浙江省东阳市浙江省金华东阳兴平西路东阳银泰NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '268848'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
        id: 'a3eb9e55-d424-4bb8-a762-66679f2f33cc',
        name: 'Nike Well Collective - Colorado Springs',
        region: 'NORTH_AMERICA',
        storeNumber: '462'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a401d777-66c1-41f8-ba6d-dafc4339388a',
        name: '广西壮族自治区玉林市百丽玉林金玉路万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '531885'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a40672e8-ad5e-46ef-9dcb-a357d0465d01',
        name: 'TOPSPORTS山东潍坊奎文区渤海路与桂园街新城吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '292338'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a41dc920-5133-4ce6-986e-a171b3635a42',
        name: '辽宁省沈阳市沈阳市和平区太原街中兴KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '481421'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a4217c63-28ee-4557-81ed-2a39a5570fb1',
        name: 'POUSHENG浙江宁海西子国际KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288403'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a42fa6f5-86c4-45a9-bf20-33c9d301a8f7',
        name: '重庆永川区永川万达NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287481'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a44bc0a4-eedc-4cda-91e1-3c6af252677a',
        name: '江苏省昆山市宝原昆山人民路昆山商厦BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '492185'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: 'a451e3c1-d7d9-41a7-a7bf-3e275f45f730',
        name: 'NIKE南京中山门换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3787'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a46a194e-3a92-466a-8d1c-3b34a4d43389',
        name: '上海港汇广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '263309'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: 'a48fd078-0c29-4c13-8d8d-d29347d834b1',
        name: 'Nike Factory Store - Clarksburg',
        region: 'NORTH_AMERICA',
        storeNumber: '256'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a496efc1-a292-4f39-95cf-928ea75d9aa8',
        name: '广西壮族自治区南宁市青秀区金湖路梦之岛水晶城3F NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272776'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a4bed9dd-14a5-4c0e-94a0-794de0dee922',
        name: 'TOPSPORTS山东青岛山东省青岛市市北区辽阳西路丽达茂NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274461'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a4c3096f-70c2-4965-9a09-8fad67e2037c',
        name: 'POUSHENG黑龙江齐齐哈尔龙沙区百货大楼NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '260217'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a4c5fe65-c82c-49c4-8e98-f45963fccc6f',
        name: '나이키 타임빌라스 수원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85993'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: 'a4cae39d-3930-45a7-b1d3-d3c7a4096332',
        name: 'NIKE宜兴万达换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3682'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a4d8ff6b-0e38-4f68-bbd8-7b4d88eac1ad',
        name: '나이키 LF스퀘어 인천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '51523'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a4ed157c-c366-4018-81ed-c3ad7665a801',
        name: '上海市宝胜上海浦东第一八佰伴Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '774'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a50834e9-2b21-45f4-a745-c550c193bcbd',
        name: '广东省深圳市宝胜 KK ONE 购物中心 Beacon-ext',
        region: 'GREATER_CHINA',
        storeNumber: '268879'
      },
      {
        address: {
          country: 'EGY'
        },
        id: 'a509a5e0-bf29-41b3-b08c-941113ebfc6f',
        name: 'Nike Store Mall of Egypt',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY054'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: 'a51e59cc-c2e9-427d-8572-cb7efbc78591',
        name: 'Nike NorthPark',
        region: 'NORTH_AMERICA',
        storeNumber: '512'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: 'a542b16f-f7dd-4f2b-aad6-7e381728b889',
        name: 'NIKE南京汤铜换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3812'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a54e0e36-38ea-45bd-a999-a7419f1f5714',
        name: '福建省福州市宝胜爱琴海广场东百百货 KICKS LOUNGE L2',
        region: 'GREATER_CHINA',
        storeNumber: '269404'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a54fe7d8-0b86-4044-91fb-ef539fb24012',
        name: '江苏省南京市建邺区楠溪江东街河西龙湖NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '271327'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a58072f7-12fd-4c52-b0ba-c24ec8b8b482',
        name: '浙江省温州市滔搏温州鹿城荷花路银泰百货NK',
        region: 'GREATER_CHINA',
        storeNumber: '267208'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: 'a596612a-1399-4416-9386-2d4c986f692c',
        name: 'Nike Unite - Tempe Marketplace',
        region: 'NORTH_AMERICA',
        storeNumber: '483'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a59f579d-2648-41ae-8747-63f046e56242',
        name: '江苏省苏州市观前街1BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '243061'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'a5b85d7d-ec3c-40b0-a3c3-71bcb8fc238b',
        name: 'Nike Tijuana',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '527026'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a5e36f06-ae32-4a50-a705-cb42a26cfa65',
        name: '山东省青岛市青岛李沧万达NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260635'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a5e9e446-a33e-4a16-93fe-419314ab188a',
        name: '重庆江北大融城Kicks Lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '454121'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a602fc83-a95f-4945-8f9b-b9212cb8cca5',
        name: '耐克河南正弘城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '531019'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a6038386-7c9c-4341-8d57-c4dcf1480f63',
        name: '辽宁省大连市沙河口区和平广场NIKE KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '180045'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'a620bbcd-9cc0-46a2-bada-d74286c04d02',
        name: 'Nike Mall Kelapa Gading',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6145'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a622a79b-f348-42fd-a569-8c9cbe39d97e',
        name: '安徽省淮北市宝胜淮北金鹰商厦BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '23869'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '92c6ee55-0adc-4eab-b0cc-256a9e8b04dd',
        id: 'a65242d5-e307-457c-9f3c-c297b58ce1bf',
        name: 'Nike Factory Store La Reggia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '637'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'a653cb94-48d2-4529-b7cd-2cf33eaaf7d2',
        name: 'Nike Factory Store - Osasco',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6309'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: 'a6553494-4e8f-470d-a4b9-d00573794eb7',
        name: 'NIKE ONLY ROSEBANK',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2309'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'a656d1b8-4b4f-4dbb-bd7e-e28e2aa8f6a7',
        name: 'Nike Riverside Kolkata',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6847'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a65c0f2e-36db-42a2-9145-056b012255f7',
        name: 'Nike 品牌体验店（保定北国先天下）',
        region: 'GREATER_CHINA',
        storeNumber: '481500'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: 'a6759b2b-be42-4871-8cf5-1d4438fbd9a2',
        name: 'Nike Well Collective - Hyde Park Village',
        region: 'NORTH_AMERICA',
        storeNumber: '431'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a67f67da-d176-44b0-b73a-88642b54aa9f',
        name: '江西省南昌市江西宝元南昌中山路百盛B馆 KICKS LOUNGE L1',
        region: 'GREATER_CHINA',
        storeNumber: '269143'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ba4fe0a4-6886-4fa5-93ff-66a328a7d5c9',
        id: 'a688e0ae-abf5-4fcc-97b0-91def8da2340',
        name: 'Nike Town London',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '531'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'a691a7e4-b574-4c0e-b9b8-c77252f517a7',
        name: 'Nike Southex',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4756'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'a6931e33-e2fc-42f1-96c2-26853c6d47f4',
        name: 'Nike South City Kolkata',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5083662'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a70477ae-df9e-4464-b42a-e60111ad8e02',
        name: 'TOPSPORTS山东烟台迎春大街莱山振华NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '57316'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a70acb9f-ab8f-4d87-9cbd-49e7df4a650d',
        name: 'POUSHENG河南汝州广成中路金博大NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293783'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7268458-1b07-4a67-9870-063a96fe0f91',
        name: '山东省青岛市青岛崂山区秦岭路丽达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273142'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: 'a74585c4-4640-4f5d-af35-09fabb69696b',
        name: 'Nike Factory Store Kurashiki',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3162'
      },
      {
        address: {
          country: 'CAN'
        },
        id: 'a74ac488-85e9-4339-b689-dfaa5a5daed4',
        name: 'Nike Scarborough',
        region: 'NORTH_AMERICA',
        storeNumber: 'CA10'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a74cd611-450a-43c8-8d87-d4caa416590e',
        name: '福建省厦门市百丽厦门湖滨东路华润万象城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526875'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7623d53-e1f7-4269-b768-c816c1b379cf',
        name: '福建省晋江市宝胜福建晋江市宝龙城市广场 Beacon  L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '243037'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'a7675589-22b5-42f0-b3d7-b078cffeaa0b',
        name: 'Nike Punto Valle',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6541'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7860fae-b0e5-4e21-8647-1f6f09e94969',
        name: '北京市海淀区西三旗万象汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288159'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: 'a79a1c7c-0396-4f58-9ebb-44fc7af001d4',
        name: 'Nike Well Collective - Manhasset',
        region: 'NORTH_AMERICA',
        storeNumber: '443'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a79ecc70-2ee5-476c-b37b-c8fd2b007ac7',
        name: '吉林省长春市南关区重庆路678 BEACON1200',
        region: 'GREATER_CHINA',
        storeNumber: '269340'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7a1d718-05cb-4885-975a-6a2ad13c52fb',
        name: '内蒙古赤峰市松山区万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272571'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7a50724-b9d6-44b9-b08c-cb902bcaddac',
        name: 'POUSHENG重庆沙坪坝重百NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293785'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7a7066f-a556-4d20-8e83-4596683bfd8d',
        name: '江苏省盐城市宝原金鹰天地KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271313'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7c69cbb-40fa-40b6-994c-259723395f0b',
        name: '重庆市南川区西大街万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '243435'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7da7554-20f3-422d-95e4-ce0aaa64197e',
        name: 'POUSHENG辽宁鞍山胜利南路新玛特KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288446'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: 'a7e90b11-ab78-44b3-9eaf-91b31a14501a',
        name: 'Converse Factory Store - Grand Prairie Premium Outlet',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3768'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a7fff811-b683-4ac1-b7e9-aba11f7e6641',
        name: '吉林省长春市双阳区双阳大街 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269007'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a83c3f05-ef1f-44bc-92e7-805068cb93ab',
        name: '나이키 강릉',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21258'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'a85ae0ea-7034-4cf7-b2d9-4f467c61b4b8',
        name: 'Nike Carousel',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2475'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a8f41887-fd1c-4c52-b50b-30d7e4bf68ba',
        name: '云南省文山市光大购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287578'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a904aadc-3115-474f-bef1-4e13c8f47280',
        name: '安庆迎江区金华联NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '259061'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a9215559-62fd-417d-91a8-251e8411bd8a',
        name: '海南省海口市盛世长运海口王府井免税店NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288429'
      },
      {
        address: {
          country: 'ARG'
        },
        id: 'a92b890a-b3fb-4e0f-98a4-2cfabb1bc230',
        name: 'Nike Factory Store - La Plata',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6505'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'a92c9cf0-e12e-4d7b-81c9-4a8895026754',
        name: 'Nike Ayala Capitol Bacolod',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8971'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a92ff087-e5d0-4871-9966-8815ffd174ce',
        name: 'POUSHENG河北衡水育才大街爱琴海NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274482'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a9318fae-ac11-4eac-b185-8cd2082fc778',
        name: '陕西西安雁塔区小寨赛格购物中心Jordan-L1',
        region: 'GREATER_CHINA',
        storeNumber: '279080'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'a940dbb5-ec3f-4aec-8f83-8fa15c4a6911',
        name: 'NFS Ludhiana',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9752'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a961f454-d4f0-4e5b-b9cc-640fc3275ac1',
        name: '나이키 의정부',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '28005'
      },
      {
        address: {
          country: 'POL'
        },
        id: 'a9683408-3624-4880-838f-825334fd7790',
        name: 'Nike Well Collective Katowicka (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2412'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a96a0611-1e03-40bd-b835-ca13052a19ba',
        name: '나이키 롯데 대전',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87369'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a980c47d-04da-463c-8c9e-0668e376c083',
        name: '重庆市江北区北大街九龙仓 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '177738'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a9b9fb36-018e-4be0-96ad-953037d55776',
        name: '上海黄浦徐家汇路日月光NK750',
        region: 'GREATER_CHINA',
        storeNumber: '246566'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'a9dc5a79-92b6-4084-9f0e-784b8fcd306a',
        name: 'Nike Store Caserta (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '852'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'a9e9a637-22bb-4412-b0c2-9544ae81f51e',
        name: '北京海淀区复兴路翠微百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '267351'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'a9f04de2-45f8-41ec-9ab1-baf5ca7bfe6a',
        name: '나이키 공주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '47420'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'aa112b11-4827-4c7c-b77f-ab3bd4d50568',
        name: '나이키 AK 기흥',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85937'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'aa15526b-afb0-419c-8bf3-48c2d7263657',
        name: 'NSP Ashdod Star',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2710'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'aa2d6cc2-1867-45b8-8f9b-51f2bbd0f7bf',
        name: '나이키 두타',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85990'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aa555d3c-6a1e-42aa-9d03-5ccb67adbdb5',
        name: 'POUSHENG江苏省张家港市金茂览秀城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287322'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aa55b38a-7f8c-4a92-9f8f-6a6ea9bc13e4',
        name: '辽宁省盘锦市盘锦兴隆台区运动城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '243076'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aa699e99-052f-4369-9f44-bdfe7685be68',
        name: '浙江省杭州市杭州丁桥龙湖天街NK',
        region: 'GREATER_CHINA',
        storeNumber: '272780'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aa763336-6434-45f5-b82f-e3a3e667502f',
        name: 'POUSHENG广西南宁朝阳路百盛西南商都NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '282192'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '74589f48-d268-4548-90ac-e18b348c08cd',
        id: 'aa7fd236-7729-4b9a-ba3f-636c3836a979',
        name: 'NIKE广州品牌体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3439'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aa828731-2e51-478d-a3e5-27d9fa5080ec',
        name: 'Suzhou Village FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6039'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: 'aa9b8801-7cdf-49af-89e2-413615c5fb0b',
        name: '나이키 유나이트 교하',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6992'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aaece32c-9446-4de5-b746-3917a3156356',
        name: 'REALLY浙江乐清柳市杨帆路现代广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287342'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ab4fa7be-ab38-4e7a-a5f6-0d521e1b3a52',
        name: 'POUSHENG安徽合肥高新区望江西路银泰NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288488'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'ab61d3f7-8900-4d8b-8764-5c5677635ddf',
        name: 'NSP Ayalon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2722'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ab78085c-59e1-42c3-a817-b143704f4e7f',
        name: '나이키 광주몰',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '52862'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'ab79bc98-ec5c-48f8-8511-e5f08a763d32',
        name: 'Nike Phoenix Citadel Mall Indore',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5100123'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'ab7afbcc-cd63-4257-b720-f85966baa653',
        name: 'NIKE重庆奥体换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3818'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ab81007c-5ffd-4822-99a8-14e67bbb3b8e',
        name: 'TOPSPORTS珠海金湾华发商都1F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274314'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ab8519db-e081-4cf4-a2ff-b9da23cc91b0',
        name: '浙江省宁波市宁波高桥(海曙) 印象城NS',
        region: 'GREATER_CHINA',
        storeNumber: '269018'
      },
      {
        address: {
          country: 'MYS'
        },
        id: 'ab904a07-7158-4e1c-86e4-d0a7d750c171',
        name: 'Nike Gurney',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6304'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ab94408b-3abf-47ba-994b-0f4ae473d671',
        name: '나이키 칠성',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21350'
      },
      {
        address: {
          country: 'SGP'
        },
        id: 'ab9eba75-a1a1-46b5-990e-79fa3b843040',
        name: 'Nike Jewel',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4025'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aba9cb3e-082d-49ac-88e8-ababff45c391',
        name: '北京市房山区龙湖天街KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '272372'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'abad78ac-44b8-46bc-a004-d3c740007965',
        name: 'Nike Grand Indonesia',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2559'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'abaf4ad1-35ad-4854-9c02-01505a51c328',
        name: '广东省佛山市百丽广东省佛山市顺德区欢乐海岸SP',
        region: 'GREATER_CHINA',
        storeNumber: '269138'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'abc22618-91df-4133-b0c3-eaac356ece93',
        name: '安徽省滁州市百丽滁州南谯北路白云商厦Beacon L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '529271'
      },
      {
        address: {
          country: 'KWT'
        },
        id: 'abc9445c-0212-4da2-99ff-97bafcbbf8bc',
        name: 'SSS AL ZAHRA',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KUW108'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: 'abd24674-bfc3-4d8a-9916-371ee10b020c',
        name: 'Nike Factory Store Loanhead',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '962'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'abd5c25b-363b-4e07-8874-6d68da527001',
        name: '나이키 남원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21507'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'de5b82a0-e649-4103-9387-cf868dd85899',
        id: 'ac01cef7-3dec-4056-9dc5-10dfe1c76f67',
        name: 'Nike House of Innovation NYC',
        region: 'NORTH_AMERICA',
        storeNumber: '365'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: 'ac2933c6-3089-4476-8d8e-b84f18bcad0a',
        name: 'Nike Factory Store Bicester',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2051'
      },
      {
        address: {
          country: 'UKR'
        },
        id: 'ac4c9a00-1b1c-4f5c-a81e-208dd58079e7',
        name: 'Nike Store Kyiv Lavina Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR053'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ac560ea1-047b-4f2a-8337-8e6d8a3ad8cc',
        name: 'POUSHENG河北武安中兴路新世纪广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288179'
      },
      {
        address: {
          country: 'OMN'
        },
        id: 'ac612381-93da-4195-a4e2-02cbf2890e78',
        name: 'Nike Muscat City Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'OMA001'
      },
      {
        address: {
          country: 'HUN'
        },
        id: 'ac73200d-2ea3-45c9-831a-8f142eb394b5',
        name: 'Nike Well Collective - Etele (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2438'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ac7765dc-0c42-4823-82ad-1f9e9da02cc9',
        name: 'POUSHENG安徽省合肥市包河区万达广场Kicks Lounge-L2',
        region: 'GREATER_CHINA',
        storeNumber: '275330'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ac7961e0-41a9-4449-a79e-693382928f1f',
        name: '浙江省宁波市锐力海曙区天一广场JORDAN-L1',
        region: 'GREATER_CHINA',
        storeNumber: '269229'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ac80014c-d9ae-4bf4-8a48-aabd8f972c9b',
        name: '四川省德阳市旌阳区洋洋百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272772'
      },
      {
        address: {
          country: 'UZB'
        },
        id: 'ac828cfa-74c0-4117-be16-9d9ccb55929d',
        name: 'Nike Store Tashkent Amir Temur (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UZB003'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'acc5003a-fd45-49b6-acf1-74804b019f9e',
        name: '浙江省杭州市杭州大悦城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '525638'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'acdb4e59-9ca7-437f-ad60-176c3f348adc',
        name: '나이키 롯데 분당',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72489'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'ad0ca7b0-ba72-439d-9f04-82f3f9da8786',
        name: 'Nike Express Avenue Chennai',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9726'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ad1d987c-58e8-4f00-9762-48a1390fb1ef',
        name: 'TOPSPORTS广东惠州惠城区江北文昌一路9号华贸天地2F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274310'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'ad4a986b-fa5b-4756-acfd-7892418b721b',
        name: 'Nike Store Migros (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2675'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ad7e0c90-63b4-4873-9bea-d093a45bfbaa',
        name: '耐克沈阳恒隆KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '189588'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ad892c5c-31cd-46af-a7dc-518fc25e438c',
        name: '上海南京西路新世界NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287329'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'add3499b-bc38-4bc4-9417-9f094226f001',
        name: '石狮宝岛路泰禾NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '526073'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'add40f9a-de56-41e4-9ac1-51bcf41d4f4b',
        name: 'POUSHENG浙江省嵊州市新城吾悦广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '275341'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: 'ae06b622-0afe-4829-b193-567ab81ed5f2',
        name: 'Nike Factory Store Toulouse',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '647'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'ae0b88d3-0370-4509-adc5-25df5e3dac4d',
        name: 'Nike Antea',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '13023'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: 'ae494fcc-1924-4550-84fb-1c6aa13d58cb',
        name: 'Nike Well Collective - Irvine',
        region: 'NORTH_AMERICA',
        storeNumber: '480'
      },
      {
        address: {
          country: 'SWE'
        },
        districtId: '0f873e25-c3dc-420c-82e5-740c579c5669',
        id: 'ae687df1-2ffc-438b-b85a-a853b11d9056',
        name: 'Nike Factory Store Barkaby',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '609'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'aea413b8-3b89-494a-bb38-c9ca30a12e85',
        name: 'NIKE北京鼓楼换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3710'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'aebb8b11-71fc-4ac4-872f-f3d5de5a9605',
        name: '나이키 롯데 동탄',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '94987'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'aeea9d97-6cef-4e56-9970-17d4c4465a60',
        name: '山东省青岛市南区华润万象城Jordan L1',
        region: 'GREATER_CHINA',
        storeNumber: '269009'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'af13194e-bd2f-434d-a36b-88f171e29479',
        name: '浙江省东阳市东阳第一百货KL2',
        region: 'GREATER_CHINA',
        storeNumber: '531786'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'af25392c-0f40-4245-982d-ff2e2eb33ab3',
        name: '四川省成都市成都市金牛区交大路金牛凯德KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '195046'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'af2703f1-54e7-471a-94e3-1ad4edc84dcf',
        name: '나이키 현대 판교',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '64909'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'af2d0d7c-d601-4553-8c55-8629dcb1daaa',
        name: '上海真光路百联购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '522604'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: 'af47965b-05c3-43dd-a594-36a8250813a8',
        name: 'Nike Unite Teesside',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2066'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'af5fde2f-a64d-4033-a58e-8ce75e9efac1',
        name: '광양',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '37604'
      },
      {
        address: {
          country: 'GRC'
        },
        id: 'af770051-722a-46c6-93ab-d85b536b26d4',
        name: 'Nike Store Glyfada (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2456'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'af7b6250-1b2d-4545-945e-4c8890652138',
        name: 'Nike Store Torino (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2786'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: 'af826cc0-a61e-4f35-b18e-ac33da6078f6',
        name: 'Nike Factory Store Ingolstadt',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '651'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'af8b44c2-2f63-47c9-956b-b26882a173f6',
        name: '河北省邯郸市邯山区滏河西大街万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269155'
      },
      {
        address: {
          country: 'HKG'
        },
        districtId: 'c49dc1d3-fbc1-47bf-989b-a11a394bffb5',
        id: 'afad8c47-6ffb-4319-80b3-3ee93a9cc874',
        name: 'NIKE銅鑼灣白沙道-NS CHK PS7',
        region: 'GREATER_CHINA',
        storeNumber: '3207'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'afe33de0-c610-4949-95d3-7765b2f70eed',
        name: '广东省深圳市沙井区领创广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274533'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'aff3d6ca-8d77-4bb8-9e19-64ec28f283f4',
        name: 'Nike Indiranagar',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3974'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'affd285c-eff8-47f1-aeb7-512d7d7d20ca',
        name: 'SANSE海南海口金龙路海口友谊阳光城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '259303'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: 'b01a940f-4a4f-4ac8-938e-5dd22e2f557d',
        name: 'Converse Factory Store - Orlando Vineland Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3739'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'b036aa54-b60f-4dab-aa1d-761b431192b8',
        name: 'Nike Store Akmerkez (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2623'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'b03d4ac2-d9b6-4a3c-9963-c8de9b0cc8a3',
        name: 'Nike Red Sea Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA054'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: 'b04596c0-1339-4fd9-8271-ad2a5abe43a6',
        name: 'Nike Well Collective - Downtown Nashville',
        region: 'NORTH_AMERICA',
        storeNumber: '460'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'b058bc84-daa4-4f61-ac19-f1d1486c8b48',
        name: 'Nike Store Lille (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2603'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: 'b05dfeec-1db0-4356-9267-c451dc865f89',
        name: 'NIKE宁波联丰换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3633'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b07589ae-67be-4913-9995-4a2735398201',
        name: '四川省成都市锦江区东大街晶融汇HOOP DREAMS-L1',
        region: 'GREATER_CHINA',
        storeNumber: '267190'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b0a8f492-171c-4858-9dd9-f5e12ca9c8de',
        name: '北京东城王府井百货大楼BEACON L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '481339'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b0baa04d-f0f8-4c2f-9a33-ffd05f3aee17',
        name: '湖南省衡阳市宝胜衡阳蒸湘区步步高百货 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '266944'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b0c81dd9-8909-4a65-b879-59bda5800425',
        name: '나이키 동해',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '79812'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b0d15365-79a9-4d4b-9b90-4092c2356209',
        name: 'Kunming Shazhichuan FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6029'
      },
      {
        address: {
          country: 'JOR'
        },
        id: 'b0d783fa-752b-4a72-b6bd-79f84c5e97b9',
        name: 'Nike City Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'JOR009'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: 'b0d9b37c-520f-4c5c-927e-d318dc2484a7',
        name: 'Converse Factory Store - The Outlet Shoppes at Oklahoma City',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3758'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b13e093b-a789-4cbe-8e30-4e79945688a0',
        name: '나이키 엔터식스 상봉',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '83872'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: 'b149b18a-c71b-4732-9055-8db6b28ce426',
        name: 'Nike Clearance Store - Mississauga',
        region: 'NORTH_AMERICA',
        storeNumber: '464362'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'b1542b33-cc60-4ea0-843c-e3168af2ff48',
        name: 'NFS Bangalore',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5128'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b15cb6e6-2111-403c-95ae-1b80420bc1f1',
        name: '나이키 롯데 부여',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85911'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b162ba10-ceea-4225-8d2c-58e799c4855a',
        name: '福建省漳州市百丽福建漳州龙海市海澄镇月港大道万科广场1F NIKE SPORT SMALL',
        region: 'GREATER_CHINA',
        storeNumber: '271338'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b167990d-0a6e-4384-a8b3-008e6a630360',
        name: '陕西省西安市西安未央区未央印象城KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '189341'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b169e151-12db-476a-a12b-cccf293922da',
        name: '湖南省长沙市滔搏湖南长沙运达中央广场BEACON350',
        region: 'GREATER_CHINA',
        storeNumber: '270684'
      },
      {
        address: {
          country: 'CYP'
        },
        id: 'b172d217-cfdc-440f-b417-e6a694b9392d',
        name: 'Nike Store Nicosia Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2453'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: 'b179b5c5-3e95-471a-bc1e-c1350dac9900',
        name: 'Nike Clearance Store - Laredo',
        region: 'NORTH_AMERICA',
        storeNumber: '265'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b1849dee-57ea-48a0-9208-b00c6d588ecb',
        name: '内蒙古自治区呼和浩特市内蒙古呼和浩特玉泉区凯德MALL KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269380'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: 'b19cf3b6-507d-4fc9-b4e6-c7b73d14724b',
        name: 'NIKE芜湖赭山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4019'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b19d69ff-27c4-417e-b52d-2f31f3930773',
        name: '나이키 신흥',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95739'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b1acadae-636b-4d05-814d-3d865cf7d2a7',
        name: '나이키 현대 천호',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '25540'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: 'b1bc13ec-00b3-4ed8-b589-5d39b12cece0',
        name: 'NIKE天津环河换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3768'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: 'b1bcba2d-3e34-49ba-9208-4e12b358f5bb',
        name: 'Converse Factory Store - Miromar Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3754'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b1beffdb-142b-4e17-aee8-d20d0a8499fc',
        name: '耐克南昌红谷滩万达体验店',
        region: 'GREATER_CHINA',
        storeNumber: '266736'
      },
      {
        address: {
          country: 'UKR'
        },
        id: 'b20fb5ba-3947-4148-b287-f2deda220f11',
        name: 'Nike Store Kyiv Sky Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR048'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b229de28-3e9c-495b-aa95-3baab31cf048',
        name: '吉林省长春市南关区亚泰大街新天地 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '258601'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b22dd9ff-6a6a-47e9-967a-5f7c331d4bd1',
        name: '나이키 영천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21668'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd99e4ddd-3a04-4da8-af15-7a4b1648727f',
        id: 'b2581e9f-bbc6-40b0-a04f-4b5abbd71966',
        name: 'Nike Factory Store Madrid H2O',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '998'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: 'b26e71ea-da91-4e6a-9fe1-9d3119b1b863',
        name: 'Converse Factory Store - Round Rock Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3713'
      },
      {
        address: {
          country: 'ARG'
        },
        id: 'b277550e-4dc4-40e6-bbaf-67d3d86c03f9',
        name: 'Nike Argentina S.R.L.',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6509'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b27a6a9f-92a2-48f1-b89d-997cdf0ce742',
        name: '大连开区安盛商场NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '274380'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'b2a24df2-b5fa-472d-80e8-429704eab07c',
        name: 'Nike Summarecon Mal Serpong',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1023'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b2b08fe2-dd51-4ad1-84c0-a1d1e390da6c',
        name: '河北保定朝阳北大街保百购物广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288440'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b2b2cb8e-18ca-47dd-b6c1-8ea888f71249',
        name: '安徽省宿州市宿州国购广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '525664'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'b2b55a98-d2d3-4b17-be0d-ad54ca5658b1',
        name: 'Nike Aeon Serpong',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4400'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b2cbc035-9b9c-4e1c-b5cd-c23422a12164',
        name: '北京市房山龙湖BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '525649'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: 'b2d8062b-b7ba-48b6-a40b-4d87c57e562f',
        name: 'Nike Unite Karuizawa',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3003'
      },
      {
        address: {
          country: 'SRB'
        },
        id: 'b2dc0eaf-c06a-410e-8785-fb980f01416b',
        name: 'Nike Outlet Indjija (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER102'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b313a260-fcd6-4001-9d8a-a0accd83501a',
        name: '浙江省嵊州市嵊州新城吾悦广场BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '266813'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3442ecb-330e-4162-b6dd-1f7e5cc853a8',
        name: '北京市丰台区丽泽桥龙湖NIKE SPORTS L',
        region: 'GREATER_CHINA',
        storeNumber: '270833'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b374fee2-1d71-4a8c-9df9-0aa5fb99090b',
        name: 'REALLY江苏无锡清扬路茂业NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274337'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3810eed-d989-41a1-afe5-167a15c7f1cc',
        name: 'TOPSPORTS湖北黄冈奥康路步行街NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '8086'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3957930-0434-4657-b1fe-1ca04249be4f',
        name: 'TOPSPORTS黑龙江大庆纬二路新玛特NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '529878'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'b3be4643-4db1-4f72-8d9f-75cc4d8c42f1',
        name: 'Nike Trebol',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7232'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b3bfe684-6e72-42bb-a1d6-aa6335e3717f',
        name: '나이키 롯데 미아',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '91126'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'b3c0259e-d3cc-4ddf-b0db-53d664c5d67a',
        name: 'NIKE北京龙背村换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3707'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: 'b3c12509-e1f1-494c-90e5-5420790856d0',
        name: 'NIKE郑州环城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4012'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3c2591e-13e8-426f-b505-28ae0603811a',
        name: '广东省佛山市百丽顺德容桂桂洲大道中路天佑城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '527538'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3c351b4-e228-4f02-8d7d-1c36ec0aa04c',
        name: '阿克苏市新华东路太百购物NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288435'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: 'b3c39d9c-3ac4-45be-a37d-2ac3cbe723ae',
        name: 'Nike Well Collective - Long Beach',
        region: 'NORTH_AMERICA',
        storeNumber: '328'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3df67ce-6581-485e-8740-fc6a159f8b00',
        name: '辽宁省沈阳市和平区太原里Nike Sport L',
        region: 'GREATER_CHINA',
        storeNumber: '262411'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3ed56a4-42c0-4612-9038-23f421af1036',
        name: '河北省廊坊市廊坊市广阳区新华路明珠大厦 BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '526263'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b3f4a9d1-51d2-4ea6-bd95-9a702dbf38b6',
        name: '湖北省武汉市武汉市锐力武昌中南商业大楼NSW店',
        region: 'GREATER_CHINA',
        storeNumber: '8016'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd99e4ddd-3a04-4da8-af15-7a4b1648727f',
        id: 'b3f77e54-493a-4688-982c-f86ba02a2b90',
        name: 'Nike Factory Store Getafe',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '617'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b417495f-554b-44d3-b1d5-7ef197cb7945',
        name: 'REALLY江苏南通海门长江南路龙信广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282193'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b41f548f-ed1a-48b3-a8ed-8a56f904c87b',
        name: '上海市宝胜上海浦东第一八佰伴Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '77400'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'b42d3ef4-d7bf-4321-9d47-7524529ce363',
        name: 'Nike Eastland',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '447'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: 'b438f072-da28-4845-a925-a9ec800427f8',
        name: 'Nike Factory Store Herzogenaurach',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '564'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b444700e-53db-46d2-aa79-ce030ccf417f',
        name: 'REALLY浙江平湖三港路八佰伴NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282196'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b46fd603-83e6-4b4a-8f18-9a4f8f644d9a',
        name: '广西壮族自治区柳州市百丽柳州万象城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '189359'
      },
      {
        address: {
          country: 'LVA'
        },
        id: 'b48ef76b-6f6d-44cf-b990-88c4131aceab',
        name: 'Nike Store Riga Akropole (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LVL026'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'b4c11de5-8365-4534-9e88-fb518218fd02',
        name: 'Nike Plaza Oeste',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1355'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b4c3ef8b-b878-4d30-9b10-fa5bfabeba33',
        name: '山东省滕州市滕州万达NIKESPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260993'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b4de761b-127a-4a88-8c44-550ff892b44f',
        name: '上海市锐力浦东新区世纪大道世纪汇 KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '274243'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b4e87a78-2c40-4c61-b111-77972f8d3f80',
        name: '四川省绵阳市涪城区兴达街茂业百货SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '523596'
      },
      {
        address: {
          country: 'MYS'
        },
        id: 'b5161749-4ca1-4563-b7cc-564fc0f6f1ca',
        name: 'Nike TRX',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7161'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b5203a74-5272-4138-a874-e720315d7a10',
        name: '吉林省通化市东昌区万达NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260304'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b5237dec-aaf2-4161-8417-7f1a910d83da',
        name: 'TOPSPORTS广东深圳罗湖区东门街道东门社区东门中路2047号东门茂业3F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287580'
      },
      {
        address: {
          country: 'CAN'
        },
        id: 'b52b7a30-e80d-4399-be58-aaac871d14fd',
        name: 'RIDEAU CENTER',
        region: 'NORTH_AMERICA',
        storeNumber: '494889'
      },
      {
        address: {
          country: 'GRC'
        },
        id: 'b57dec86-02bd-46af-a17c-ef2c223cee8c',
        name: 'NIKE STORE THESSALONIKI COSMOS II',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2352'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b5a7b710-8d9a-4275-9104-3842ea98f4fb',
        name: '上海市百丽上海合生汇BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '178808'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b5c96800-6943-4415-b774-b53bd338b791',
        name: '上海金汇天街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274445'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: 'b5cc55dd-492e-4cb5-91c4-c5023e70e747',
        name: 'Converse Clearance Store - Marina Square',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3766'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b5d97aca-b0b0-4df7-8979-fc7c0bb9009c',
        name: '上海浦东周浦苏宁Beacon 750',
        region: 'GREATER_CHINA',
        storeNumber: '270940'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b5e247e1-79fc-4039-b1a6-b2c22b5ff8fa',
        name: '江苏省无锡市锐力无锡清扬路茂业天地KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '197113'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b6071184-d588-43f1-a79c-9d082c29bcd4',
        name: '陕西省西安市陕西西安未央区凤城八路大融城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '530486'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'b6194e07-2692-439c-b9c0-330f8682941d',
        name: 'NIKE成都鱼凫换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3660'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: 'b636bb42-8a27-4f26-8f0f-eaac40e9cb27',
        name: 'Nike Well Collective - The Woodlands',
        region: 'NORTH_AMERICA',
        storeNumber: '397'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b6383db1-8561-4804-b4ad-53dbc65331a8',
        name: '江苏省南通市锐力通州建设路文峰大世界KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '272140'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b63c542e-98bd-429a-9441-79841e19f456',
        name: '安徽省合肥市合肥万科广场(森林公BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '532000'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: 'b650828f-875b-448f-a9a4-4e08ef0d1127',
        name: 'Converse Factory Store - Katy Mills',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3103'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: 'b6542785-69ea-4ee5-84f3-a4568112e91b',
        name: 'Converse Factory Store - Carlsbad Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3742'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'b68f47b1-b83f-4b56-88c0-4fbdf172ba7d',
        name: 'Nike Ermita',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4515'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b695855a-5aaf-41fb-a6ac-98f7bac6fee6',
        name: '云南昆明市大悦城1期BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '260321'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b6ac7303-0dfb-40e1-8f3c-d0df5baa7fcd',
        name: '河南省洛阳市涧西区王府井MALL Beacon L2-750',
        region: 'GREATER_CHINA',
        storeNumber: '266963'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b6b4df7b-52f9-4c08-8edf-3a23d7c0e0e8',
        name: 'TOPSPORTS山东济南历下工业南路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '177554'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'b6cf8db4-b8a8-4c09-b3ff-2d5170c59faa',
        name: 'NIKE北京长城优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3808'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b6f7896a-0f6b-4a25-aa54-115fbb89f62f',
        name: '耐克北京东方广场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '190805'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b709c366-b3cc-4402-aaa7-dffc11605930',
        name: 'HangZhou Aeon FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6015'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b71070ca-e4d8-4ac5-865f-e6cb4383020c',
        name: '安徽省蚌埠市淮上万达广场SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '268989'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: 'b72c1eba-4736-4734-b936-9990bb3bbf13',
        name: 'Nike Mall of America',
        region: 'NORTH_AMERICA',
        storeNumber: '616'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b72e6ee4-b5cf-4ae4-af73-1c1958b8614d',
        name: '福建省福清市百丽福建福州福清音西街道福和路9号福和万达广场2楼Nike Sport Small',
        region: 'GREATER_CHINA',
        storeNumber: '270754'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b732f638-db20-4545-a4c8-d126658a7fcc',
        name: 'POUSHENG四川峨眉山名山购物广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279088'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b73832f6-cc4a-4d0c-b1c1-4ea7dc8225c7',
        name: '甘肃省兰州市兰州国芳百盛BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '481540'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b752a636-4867-423c-ad84-256517670855',
        name: '浙江省台州市青悦城 SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274206'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: 'b7677ea9-a8ec-4c91-af35-8dfd2ee9c549',
        name: 'Nike Factory Store Swindon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '510'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'b77eaa56-f5b4-4886-8b98-a67e15f9fd08',
        name: 'Nike Infinity Mall Mumbai',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5042'
      },
      {
        address: {
          country: 'MYS'
        },
        id: 'b7810885-7b6d-4d53-8a47-6636bf3b1a9e',
        name: 'Nike Imago',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1041'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b7a56996-33ba-4e47-8316-5832d776f0e7',
        name: '浙江省杭州市宝胜杭州下沙龙湖BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526542'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: 'b7a72d77-14fe-4441-b109-5b23f62f535f',
        name: 'NIKE青岛漓江换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3730'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b7afe60e-7d5c-4ac5-8af9-eb9d83b73553',
        name: '나이키 군산 나운',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21288'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b7b78409-0d9a-4157-8ef7-84eac84c21a0',
        name: '上海宝原浦东世博大道百联世博源Beacon L2-Extended',
        region: 'GREATER_CHINA',
        storeNumber: '97840'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b7b86b9b-fff9-4c29-9766-6bf6b6c18a50',
        name: '四川绵阳江油解放路NIKE SPORT M T2',
        region: 'GREATER_CHINA',
        storeNumber: '16810'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b7b887d7-ce60-44a5-8c05-d6207dc14077',
        name: '重庆市渝北爱琴海购物公园BEACON-L2 EXTED(550)',
        region: 'GREATER_CHINA',
        storeNumber: '270793'
      },
      {
        address: {
          country: 'ARG'
        },
        id: 'b7cc27e9-c7e6-47c1-b148-69f101f479c0',
        name: 'Nike Factory Store - Barracas',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6513'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b7d52d63-ba28-4a8b-a926-623107974d2b',
        name: '나이키 신세계 마산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95993'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: 'b7d88712-64f3-43c2-bdbd-f359a12bdc5c',
        name: 'Converse Factory Store - Great Mall',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3721'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: 'b7f42f46-df82-4c1f-a2f1-2eb731de7fcb',
        name: 'Converse Factory Store - Houston Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3732'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8073404-88a7-4c3a-a37b-6a018689f5df',
        name: '山东省泰安市泰安区银座商城NIKESPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271033'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b845c6fe-b90d-406e-9291-6fd74439174f',
        name: 'POUSHENG河南南阳孔明路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279085'
      },
      {
        address: {
          country: 'CHE'
        },
        id: 'b84ab7a9-6cef-4b6b-8e6d-050b3160be6b',
        name: 'Nike Well Collective - Emmen (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2408'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8635c46-0e3c-4cc7-8f8d-9c6b03693f3b',
        name: 'JORDAN 188 MINZU',
        region: 'GREATER_CHINA',
        storeNumber: '246878'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'b8804bd6-8d05-4fca-9d0c-68c805d83c7a',
        name: 'Nike Store Akasya (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2614'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '2f1b4c39-e8ac-4cea-abd2-95d8b9b7aefe',
        id: 'b8964dc7-773b-48c7-b1b3-2cbe6d217725',
        name: 'Nike Unite Neukölln',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2055'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd99e4ddd-3a04-4da8-af15-7a4b1648727f',
        id: 'b8a185b5-8c62-42d3-b42f-e620cdc37f94',
        name: 'Nike Factory Store Parque Oeste',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '963'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8a57f7f-6573-4b49-8bad-d563e7af6869',
        name: '四川省成都市成都市青羊区苏坡东路西单商场SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '8010'
      },
      {
        address: {
          country: 'THA'
        },
        id: 'b8a5e4ce-9585-4d99-b548-b311f3a40ce6',
        name: 'Nike at Central World Plaza',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2755'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8a64403-2385-4f88-9faa-aa5bdcbfe6af',
        name: '安徽省合肥市宝胜合肥华润万象城 KL',
        region: 'GREATER_CHINA',
        storeNumber: '281924'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: 'b8a8e9e4-fd0c-4ca4-9a39-cb3ceeae00e5',
        name: 'Nike Factory Store Sendaiko',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3033'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8aa21c5-bc8e-4d99-ada3-5b1ab77b2005',
        name: '南昌中山天虹NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '260231'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8d99c62-9317-4857-b1b2-a4f2de5f9321',
        name: '上海市锐力上海浦东新区三井lalaport上海金桥KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270912'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: 'b8db6a6a-288b-4c6a-97e2-648f1b563874',
        name: 'Nike Well Collective - Easton',
        region: 'NORTH_AMERICA',
        storeNumber: '432'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8dd833b-f094-4877-bc34-54177347ebd2',
        name: '上海市滔搏七宝万科城市广场750',
        region: 'GREATER_CHINA',
        storeNumber: '269204'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b8e5f95d-02f1-421c-8bc2-193d9b933c0a',
        name: '广西壮族自治区南宁市百丽广西南宁江南区星光大道万达广场BEACON350',
        region: 'GREATER_CHINA',
        storeNumber: '267129'
      },
      {
        address: {
          country: 'EGY'
        },
        id: 'b90f6c85-6779-409b-884f-229ffe416384',
        name: 'Nike Store Lebanon Str',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY008'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b9178858-88be-4c2d-bc6e-119f3397f52f',
        name: '新疆维吾尔自治区奎屯市宝胜宝新新疆奎屯市友好时尚购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '16941'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b93e6eab-e5bf-4bc2-963d-64fb28d4b389',
        name: 'HIGHWAVE SPORTS四川乐山乐青路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '190437'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'b95306bf-59f5-4bb5-8b6e-dc9da5298d83',
        name: 'Nike Jayanagar',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4734'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: 'b9586def-86e6-4370-82d5-b7238047ce98',
        name: 'Nike Unite Shonanhiratsuka',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b96e8d9b-270f-49f1-bffc-a7ab2cad329f',
        name: 'POUSHENG河北承德都统府大街金龙购物广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293501'
      },
      {
        address: {
          country: 'NOR'
        },
        districtId: '0f873e25-c3dc-420c-82e5-740c579c5669',
        id: 'b9797c49-1987-4128-9f94-8185949b6621',
        name: 'Nike Factory Store Oslo Vestby',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2001'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b97bc81b-0a4c-401a-a742-19cb8e37f75c',
        name: 'TOPSPORTS山东淄博般阳路SM 城市广场 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288386'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: 'b97d18a5-67a8-44c8-9754-98bd63d68967',
        name: 'Converse Factory Store - The Outlets - Orange',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3747'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b97d5212-4e2e-483f-b383-36b9f166f30a',
        name: '河南省洛阳市中州中路王府井百货NIKESPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271259'
      },
      {
        address: {
          country: 'ARG'
        },
        id: 'b9c45cd0-db39-4897-ad18-dfd811472b25',
        name: 'Nike Factory Store - Chacarita',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6504'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: 'b9d59414-7211-49f3-acfa-b1fdf852082c',
        name: 'NIKE长沙木莲换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3678'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: 'b9e17270-3b52-41e8-8bd5-31038b9427ee',
        name: 'Nike Factory Store - Gainesville',
        region: 'NORTH_AMERICA',
        storeNumber: '261'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'b9ebb202-e6b3-4483-9616-ed3be1e79a20',
        name: 'JORDAN 188 JIEFANGXI',
        region: 'GREATER_CHINA',
        storeNumber: '242895'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'b9fb0bf1-43fc-43e6-bc5a-41842243b709',
        name: '나이키 시티세븐 창원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95986'
      },
      {
        address: {
          country: 'GRC'
        },
        districtId: 'f6f807a1-6c74-48f8-af79-d9dad8664dd7',
        id: 'ba071155-be6a-43c3-9a14-df2de2668ca6',
        name: 'Nike Factory Store Crete',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '681'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'ba0864d5-5034-44f9-8990-6dc484dfd812',
        name: 'Nike Store Petach Tikva',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2721'
      },
      {
        address: {
          country: 'ESP'
        },
        id: 'ba2c9d70-a255-42ce-8341-f06678b60f94',
        name: 'Malaga',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2075'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'c39640bc-c790-42de-8810-e227062b5fb5',
        id: 'ba303560-c139-4d86-acde-be917dc80e8f',
        name: 'Nike Well Collective Kobe',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3051'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'ba4433a3-1727-407c-9124-de54f874c0f6',
        name: 'Nike Store Capacity (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2616'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ba6e7cc1-c43d-48f2-9e17-ed7f2a55add2',
        name: 'POUSHENG广西梧州大学路旺城购物广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281954'
      },
      {
        address: {
          country: 'CAN'
        },
        id: 'ba7a37b3-d2a8-4a3f-85dc-aff6b7295563',
        name: 'FAIRVIEW MALL',
        region: 'NORTH_AMERICA',
        storeNumber: '469247'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'd69076bd-e5bf-4d7f-91d1-4ea5fe42e7c3',
        id: 'ba7f2d3b-5a2c-4af9-b7da-f7c083f298c7',
        name: 'NIKE JORDAN篮球体验店 (中关村)',
        region: 'GREATER_CHINA',
        storeNumber: '3460'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ba7f973c-9196-4b8c-bf73-885a190faf9d',
        name: '나이키 진영',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95987'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ba8c0a4a-3461-464a-9ba9-7176eb6092fb',
        name: '鸡西红军路新玛特一店NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '2875'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: 'baa4fcf4-d39d-4de1-a4a0-186978dcc1ba',
        name: 'Company Store Chiba',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3023'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bac06968-9f17-4d0e-8fe8-cf0db98ced66',
        name: 'REALLY上海闵行颛桥万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288824'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bac6c72f-0ded-465d-8997-16b717e8cde5',
        name: '辽宁省大连市大连市青泥洼新玛特休闲购物广场KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '3069'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'badc7e79-e8ea-4bc9-9d54-53a1ab554255',
        name: '江西省南昌市宝胜南昌市红谷滩新区会展路999号万达广场 KICKS LOUNGE L1',
        region: 'GREATER_CHINA',
        storeNumber: '259312'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: 'bb05c3e8-9cda-4baa-a95d-b3045f78bb30',
        name: 'Nike Factory Store - Winter Garden',
        region: 'NORTH_AMERICA',
        storeNumber: '284'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bb2746a5-269a-48d2-8888-177476183230',
        name: '山西省太原市杏花岭区苏宁易购广场Nike Sport-L',
        region: 'GREATER_CHINA',
        storeNumber: '270839'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: 'bb3199c4-86b2-4129-a528-4511dccfebda',
        name: 'NIKE上海莘松换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3917'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
        id: 'bb321f12-db9a-4670-a71c-d52cc03dc1fd',
        name: 'Nike Factory Store - Spokane',
        region: 'NORTH_AMERICA',
        storeNumber: '289'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'bb34ef8a-9a46-4405-850a-04568758e617',
        name: 'Nike Factory Store - San Ignacio',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6426'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bb4f6ec5-235a-453b-96c1-c756d3eca094',
        name: 'TOPSPORTS广东深圳龙岗区龙岗大道双龙天虹3F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287473'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'aedd4b07-bd95-46bc-bedc-c1dafa8b491e',
        id: 'bb65e568-15b4-40f6-a34a-c9915a7caddd',
        name: 'Nike Factory Store Honfleur',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2046'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: 'bb717167-fc24-4ff4-8287-40cd41b5517b',
        name: 'Nike Factory Store - Downtown Philadelphia',
        region: 'NORTH_AMERICA',
        storeNumber: '281'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bb793fde-3f5f-41d4-83f4-47cd846ba686',
        name: '山西省太原市迎泽区五一路铜锣湾购物中心NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '481626'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'bb7cdf7c-ae62-4d66-8db1-101b95d036fd',
        name: '나이키 포천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '51620'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: 'bba2c21a-1b12-4f00-9b9e-405dd2914334',
        name: 'Nike Well Collective - Pinecrest',
        region: 'NORTH_AMERICA',
        storeNumber: '452'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bbc474f5-c1f7-4c1d-b62e-55e8099c2079',
        name: '山西太原迎泽西大街公园时代城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287333'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: 'bbf433e1-c581-4db7-b635-68908a968f27',
        name: 'NIKE常州龙潭换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3783'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '2a0898a2-fe79-4fa0-8d7d-b8c63500ced5',
        id: 'bbf7dccb-55d0-4447-b3dc-2487bca74917',
        name: 'Nikelab P75 Paris',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '537'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bc0be374-89c3-4fa1-a523-99d5ea5dace2',
        name: '浙江省金华市宝原金华一百耐克店BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '527983'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bc1645ca-46e0-40ea-8d4e-815016cc6414',
        name: '陕西省西安市南门合生汇KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '274368'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bc1a028a-c569-4f55-bcad-38d1f81872ba',
        name: '江苏省南通市锐力南通工农路圆融金鹰BEACON-350',
        region: 'GREATER_CHINA',
        storeNumber: '136435'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bc4f78cf-b45f-4a7b-b126-ee6a82d2d043',
        name: '江苏省无锡市无锡锡东八佰伴 NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '270636'
      },
      {
        address: {
          country: 'LTU'
        },
        id: 'bc56dc89-7034-4039-aafc-5ab0f33b38ef',
        name: 'Nike Store Kaunas Akropolis (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LIT015'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: 'bc6fa469-6f96-4d14-91e0-58e0b1e5b249',
        name: 'Nike Store Glasgow',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '532'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: 'bc73ad3e-7d10-4ba2-bb51-7e5c2be7cbef',
        name: 'Nike Factory Store Leipzig Brehna',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2013'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: 'bc824764-bfd5-4ff5-8cb7-618f8b595c9b',
        name: 'NIKE扬州新都换季优惠点',
        region: 'GREATER_CHINA',
        storeNumber: '4022'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bc9b8a1d-1863-485f-9f9f-27457c372274',
        name: '浙江省杭州市百丽杭州延安路武林银泰BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '1412'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bcbdd3b0-9833-4fa1-a3d1-bd9ba7dcc4e9',
        name: '广东省东莞市盛世长运东莞市虎门镇体育路虎门万达广场SP- SIS',
        region: 'GREATER_CHINA',
        storeNumber: '189853'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bcc666e7-6a4e-40c7-b27d-15454f490257',
        name: '浙江省丽水市丽水万地广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241171'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: 'bcced147-405f-4254-83a2-c63242e29dd2',
        name: 'Nike Factory Store Makuhari',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3016'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bd015906-d357-4d81-a966-e931dbd076e3',
        name: '湖南省长沙市宝胜长沙阿波罗广场SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '136374'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bd07c1e5-b644-4e03-8cff-35a99829a8a9',
        name: '贵州省贵阳市国贸玖富城（八匹马店）NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '269360'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bd0f1773-4eac-46cd-bd36-1bc89752ae79',
        name: '浙江省杭州市浙江杭州市上城区延安路西湖银泰百货KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '133224'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9d9eff3f-247a-4ecb-92af-3ea47a3ef09f',
        id: 'bd407f56-f604-4b97-be2c-48a43a01d585',
        name: 'Nike Factory Store - Tsawwassen Mills',
        region: 'NORTH_AMERICA',
        storeNumber: '435748'
      },
      {
        address: {
          country: 'AUT'
        },
        id: 'bd49f9d4-8a40-4a40-9da5-de474c0b9bb3',
        name: 'Nike Store Innsbruck (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2387'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bd5e227c-72b8-4101-9c5e-25044dee72e4',
        name: '黑龙江省哈尔滨市远大购物广场 KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '260216'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bd7537ae-d4d9-4af7-90ea-c2dfc8b4f9bb',
        name: '辽宁省辽阳市白塔区新运街万达广场 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '194821'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: 'bd858643-2046-4766-845b-3cae505b31bb',
        name: 'Nike Factory Store Aubergenville',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '988'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bda72b0f-16a6-4656-bda1-ac260f709715',
        name: '锐力苏州常熟海虞南路印象城KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '520303'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'bdb8af9e-dd85-4d0d-bc3f-2fa307a7cc9e',
        name: 'Nike 4D',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5332'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bdd38306-4ea4-4d73-b41b-d53f063a3d2b',
        name: 'TOPSPORTS内蒙古包头清源路与文化路交叉路口印象城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293676'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bdebcf89-b466-44e8-ba1d-ed51cae23a51',
        name: 'POUSHENG河北承德迎宾路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288161'
      },
      {
        address: {
          country: 'GRC'
        },
        districtId: 'f6f807a1-6c74-48f8-af79-d9dad8664dd7',
        id: 'bdeeef05-4dc3-443d-af9f-5760ff265581',
        name: 'Nike Factory Store Athens',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '656'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'bdfd7490-991b-46bb-9148-7c6c21977f3e',
        name: '나이키 칠곡',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21143'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'be0e44c2-7e09-40f7-9d17-d0d80701997d',
        name: '浙江省绍兴市绍兴银泰城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '481505'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'be214a76-a13c-425d-b0f7-e55a30ef1d8f',
        name: '江苏省苏州市宝原苏州龙湖时代天街KL',
        region: 'GREATER_CHINA',
        storeNumber: '482439'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'be2289e5-864b-4897-ab45-c15ad55c00ef',
        name: '나이키 스타필드 고양',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '77975'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'be30cf7e-1385-4449-a3ac-6c980b575a37',
        name: '西安高新中大国际NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '272592'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'be569206-5f1d-40c5-8fc7-9b6f4ad2a6bc',
        name: '山东省淄博市临淄区时代广场NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271160'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'be5700f7-3a0e-4ba9-b58f-2ffa46c2182c',
        name: '耐克福州泰禾广场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260267'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'be5fe6ea-6991-4de4-a43b-cf5fb483c182',
        name: '广东省佛山市盛世长运佛山王府井购物中心NIKE Beacon 550',
        region: 'GREATER_CHINA',
        storeNumber: '269392'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'be971854-2a5e-4804-95eb-b1c8fc6360ad',
        name: '黑龙江省哈尔滨市南岗区红博西城红场 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '194041'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'beb533dc-8a90-4f6a-8e9e-a52fdec2bffb',
        name: 'REALLY上海启帆路森兰花园城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288811'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bef01bde-3bff-4bf4-a6c8-09c0a5a5d151',
        name: 'NIKE KIDS上海久光百货店',
        region: 'GREATER_CHINA',
        storeNumber: '9774'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'bf03048a-4e93-4f24-90ad-cdc6a30935d7',
        name: 'Nike Store Sankopark (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2775'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bf06b829-981d-4333-b360-a125ac896b2e',
        name: 'SHUANGJIAN浙江杭州市心路银隆百货NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288443'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bf12bbf9-ce6e-43ef-9b74-8bb49fab267b',
        name: '江苏省苏州市东吴龙湖天街NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '272110'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bf191c27-ee85-4e93-82d3-e3c3caff59cb',
        name: '上海市黄浦区淮海中路尚贤坊JordanL1',
        region: 'GREATER_CHINA',
        storeNumber: '270471'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bf2d2f2c-aaaa-4b5b-881e-8ddacfa273fd',
        name: 'TOPSPORTS浙江长兴金陵北路与龙山大道交界处浙北大厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279074'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '51a1785a-96a0-4fb2-a5d8-c3d531ada5b3',
        id: 'bf7865ba-4733-4952-a2d2-dc22be3f4c81',
        name: '나이키 강남',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8100'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bf96d4b7-b90e-438c-a05b-07417fcfafd7',
        name: '上海市上海宝元八佰伴KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '80642'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: 'bf9ae952-fe85-458e-909c-1001d286117e',
        name: '나이키 유나이트 김포',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8103'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bf9ec00c-21ca-4b4a-93c0-206417d8468a',
        name: 'JORDAN 9668 SHENNAN',
        region: 'GREATER_CHINA',
        storeNumber: '525677'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'bfa9b624-253b-45fe-860a-f43c195d3cd8',
        name: 'Nike Well Collective - Eur (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2477'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'bfa9da87-ccd9-4bef-879b-1ef58694b860',
        name: 'Nike Well Collective - AK 원주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '79045'
      },
      {
        address: {
          country: 'DNK'
        },
        districtId: '0f873e25-c3dc-420c-82e5-740c579c5669',
        id: 'bfd867a9-e1f5-4fbf-9d75-9ae133042b77',
        name: 'Nike Factory Store Ringsted',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '610'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'bff4eb89-2d66-47f3-97f4-1d28dd3e1d03',
        name: '湖北省武汉市武汉白沙天街KL L2',
        region: 'GREATER_CHINA',
        storeNumber: '274354'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'bffe8665-9bd2-47c2-a12f-a865637ca532',
        name: 'NIKE包头包固换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3926'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
        id: 'c00c4e71-4716-45e1-a841-4a4bc487de76',
        name: 'Nike Factory Store Crown Point',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '983'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c0324545-722e-4711-99b4-c14617d91111',
        name: 'POUSHENG浙江杭州城北万象城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288453'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'c051c5f1-b626-4afc-9cd8-318526ab559c',
        name: 'Nike Falak',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA106'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'c0525114-6b76-42fa-907c-b8c0116a8d7e',
        name: 'Nike Factory Store - Irarrazabal',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6405'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'c083582b-9cb2-45ab-be82-2b2a054c68c8',
        name: 'Converse Brand Outlet Store Torino',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8114'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'c085f595-4317-4bd1-b3a9-88a719303562',
        name: 'NIKE重庆璧青换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3748'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c08e652d-e3f4-4cd3-bb96-3074c257fb19',
        name: 'POUSHENG安徽合肥银泰中心二期KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '268766'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c0964c08-abf6-4c51-ade4-53489d527a30',
        name: 'POUSHENG山东夏津银山路德百广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281953'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'c0b7f647-cd1a-436d-82c1-fa780a2a31c8',
        name: 'Nike Store Milan Porta Nuova (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '764'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c0c95290-d308-4bd2-a465-e136db34ba0b',
        name: '四川绵阳涪城区南河路王府井NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '274288'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'c0cce7f5-aa7d-45cf-a43c-35a7bfdb3bc8',
        name: 'Nike Store Capitol (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2621'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: 'c0e0564f-e17b-40e9-b979-44981395eba3',
        name: 'NIKE杭州五常换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3996'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c0e37afa-77ce-4380-9529-bd946a205ad9',
        name: '广东珠海香洲区珠海大道8号华发商都1F层NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '279067'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c0ea8b14-fb90-49b5-8e42-8e449b0302b1',
        name: '陕西宝鸡渭滨区开元商场BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '240342'
      },
      {
        address: {
          country: 'MYS'
        },
        id: 'c0fd83f6-6c06-4510-98b3-bd1e24d2c21f',
        name: 'Nike Midvalley',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3210'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c1050df1-9870-4eaf-bb98-01679bbe4ef9',
        name: '山东省东营市西城区振华商厦 NIKE SPORT L',
        region: 'GREATER_CHINA',
        storeNumber: '269539'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c106f4cb-3369-458f-a5e1-9d0456343079',
        name: 'POUSHENG浙江绍兴胜利东路百盛NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287341'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c10736ea-2171-48bf-b11c-42ab2821ab60',
        name: '广东省广州市百丽广州市白云区百顺北路1号云门新世界1楼 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '267197'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c1081eed-f66c-4ad3-b8e6-d6d5eaaff0ce',
        name: '耐克重庆新光天地体验店',
        region: 'GREATER_CHINA',
        storeNumber: '267798'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c12a3f92-80eb-4229-ba35-24d9a758f304',
        name: '浙江省杭州市滔搏杭州上城延安路湖滨银泰二期C区KL L1',
        region: 'GREATER_CHINA',
        storeNumber: '260739'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c12b96c2-2334-40e8-8540-f5d16e0aaa04',
        name: '나이키 서귀포',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '79826'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c132ba4b-0a21-4662-a0f7-57d407fab5a6',
        name: '浙江省义乌市义乌宾王商贸区耐克二店SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '4502'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c1388df0-acee-4dd1-a58b-796cf839b41f',
        name: '广东省深圳市福华三路COCO Park KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '269218'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c14282dd-2f1b-40da-a3c1-19f9df6c67f6',
        name: '河南郑州二七路丹尼斯大卫城RISE 750',
        region: 'GREATER_CHINA',
        storeNumber: '279091'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: 'c14f4319-7353-4b4a-9cc8-e81e03aba7a9',
        name: 'Nike Factory Store - Vaughan Mills',
        region: 'NORTH_AMERICA',
        storeNumber: '4281'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'c16646fa-b4fe-4154-8e0b-3708eba3c055',
        name: 'Nike Vegas Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3892'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c1687f83-c278-40e2-aabe-82fdf239ff9b',
        name: '辽宁沈阳沈北新区蒲昌路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272380'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c17294d3-8e0c-4f63-bc67-88cf47cd6d6c',
        name: '重庆高新天街NK-M',
        region: 'GREATER_CHINA',
        storeNumber: '274366'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: 'c1758244-9341-42f1-8473-5bcf640bb5bc',
        name: 'Nike Factory Store Edinburgh Craigleith',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '997'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c179fe06-bf9b-4da7-84eb-7b25d0c329d5',
        name: '福建省福州市宝胜福州市鼓楼区八一七北路  BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '260726'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c19114c3-bebd-4d8b-aa02-a81185203a3a',
        name: '吉林省吉林市丰满区欧亚综合体NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '194686'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c20d868e-c52d-4f36-a5f5-df33ffc5eab4',
        name: 'POUSHENG辽宁大连中山区百年城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '287288'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c2105e91-8986-4ddd-a917-19289b7c502f',
        name: '河北省张家口市桥东区百盛购物中心NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '273473'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c22e9865-97a9-4721-88a0-ab529b42f0f9',
        name: 'JORDAN 139 NANDONG',
        region: 'GREATER_CHINA',
        storeNumber: '189395'
      },
      {
        address: {
          country: 'LBN'
        },
        id: 'c23581c3-2a02-4601-ab74-8c0fda1da04d',
        name: 'Nike Souks',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LEB065'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c26f66a1-6012-430d-a668-f4e8f6d7d70b',
        name: '北京大兴区槐房南路万达购物中心BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '177702'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'c287f525-9898-4410-a903-30fa92bc4d85',
        name: 'Nike Well Collective - Karmiel (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2346'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c2886a3b-1603-47a5-99bb-91b5dabbbf32',
        name: '河南省濮阳市河南省濮阳经开区万达万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '242927'
      },
      {
        address: {
          country: 'CZE'
        },
        id: 'c2ac60d9-ffc0-46d2-8be2-00dac529d890',
        name: 'Nike Well Collective - Cerny Most (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2484'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'c2ccabd3-2061-413c-9cb0-3b2817c138a6',
        name: 'NIKE北京赛特换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3416'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c2d3783d-5028-4eb2-9036-2092739cbd4f',
        name: '云南省昆明市昆明和谐广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270724'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c33f5371-7b5a-451c-b5ab-02ed4abc83e8',
        name: '北京石景山万达KICKS LOUNGE L2',
        region: 'GREATER_CHINA',
        storeNumber: '260989'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: 'c3537037-9d1f-4910-8259-1ee086d5c192',
        name: 'NIKE上海华夏优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3795'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c35fb9e7-96be-4462-88d1-ce2f7ba8f5da',
        name: '广东省汕头市汕头万象城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272414'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c399a57c-fc12-4ddd-9ee3-f10fbcb2c99a',
        name: '乌鲁木齐沙依巴克友好北路太百购物NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '292343'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '8b2ae4b8-eb16-4d51-83bb-dba72ba34715',
        id: 'c3befa95-9269-4ad3-a036-e96daf7f39b3',
        name: 'Nike Factory Store Toulon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2011'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c3c17915-16f6-4f65-b53a-bf479830c194',
        name: '河北省廊坊市广阳区万达广场BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '270958'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: 'c3cc4528-6177-4e83-a9c0-62028b466c03',
        name: 'NIKE天津京津换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3620'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c3d56c04-3d9b-4ac2-b93f-3cf8262d98e8',
        name: '云南省昆明市昆明呈贡七彩第一城SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270472'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c3f57f78-78bd-4aae-a07b-6b82f09e2b7c',
        name: '云南保山隆阳永昌路吾悦广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '273475'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c400c04d-ab29-4a9d-85a3-cef2fb17d31b',
        name: '北京丰台区合生广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241955'
      },
      {
        address: {
          country: 'BEL'
        },
        districtId: '031af3aa-9b11-4b5d-b05f-80d63e997ea7',
        id: 'c4027e14-0cbb-4638-b884-1929e2add94f',
        name: 'Nike Employee Store Laakdal',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '503'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: 'c403059c-1b19-4f4f-9ec8-00ea9c35197f',
        name: 'Nike Well Collective - El Segundo',
        region: 'NORTH_AMERICA',
        storeNumber: '466'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'c425e8a7-be5c-4d83-ab3c-329f9ace5950',
        name: 'Nike Well Collective - Uskudar (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2343'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c43e8274-de12-4a9c-95a9-43052dbf6f59',
        name: '나이키 롯데 은평',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71730'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c4512454-bcfb-45d3-a380-27ea436330bb',
        name: '四川西昌月城NIKE SPORT L',
        region: 'GREATER_CHINA',
        storeNumber: '287505'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c46e7834-cc46-4633-a535-2d002690ff07',
        name: '나이키 현대 신도림',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '68925'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c4826579-6876-4650-ad31-a7234eb1851f',
        name: '나이키 서면2',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95994'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c48a2c00-86b0-4e82-a214-b8497946f0a1',
        name: '青岛市李沧区京口路胜道运动城BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '186637'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c493079c-b192-4d92-b344-7e456c18bd2d',
        name: '北京顺义祥云小镇NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '242317'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: 'c4a193e1-b2fc-455b-be96-33948835939e',
        name: 'Converse Factory Store - Tanger Outlet Charleston',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3714'
      },
      {
        address: {
          country: 'AUT'
        },
        id: 'c4a2832a-88d2-4423-9f9d-be3007c2d97c',
        name: 'Nike Well Collective - Alte Donau (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2447'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'c4ad6410-3d80-4402-8a15-6f390c60f8d5',
        name: 'Nike CDO Limketkai',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3427'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c4bdcdd5-99b2-4304-9476-b51d7480a63b',
        name: '浙江省温州市温州龙湾新城吾悦广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270504'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: 'c4cbd8d4-20a5-43f2-8e4d-559e038f1c6f',
        name: 'Nike Clearance Store Olivium',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '896'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
        id: 'c4fadd29-1d8f-460f-bcd4-6449d37f8411',
        name: '나이키 유나이트 대구',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8105'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c5003347-b317-4637-872f-d65cb8822ba2',
        name: '黑龙江省哈尔滨市黑龙江省哈尔滨阿城区解放大街达仁印象城Beacon L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '481503'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c512f920-59a5-4654-9a16-e7bab17305b6',
        name: '广东省东莞市盛世长运东莞民盈山国贸中心KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '274306'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c51c0477-0f17-470b-90bf-c5c1594f24c6',
        name: '云南省昭通市昭阳区二甲路洋人街中段Beacon L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '526844'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c5207b06-29f0-4d39-a5e0-ce6ae6c9b9d5',
        name: '나이키 현대 동대구',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85300'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c54aced6-8535-408a-8e84-4d60a260c831',
        name: '나이키 신세계 광주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '88980'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c5626489-099a-45e8-a35c-17fa0baf62c6',
        name: '滔搏浙江宁波鄞州银泰百货NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '293451'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c56e8229-92da-4e66-a851-2ced7b202e70',
        name: '福州浦上大道爱琴海东百NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '246577'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c5774448-273a-4095-8f7a-03bb4906d1f7',
        name: '나이키 마리오 가산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78498'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c57f7c94-1ff4-4c28-931c-d1eb00e55ea3',
        name: '天津市津南区永旺梦乐城Nike Sport-L',
        region: 'GREATER_CHINA',
        storeNumber: '270836'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c5878d81-752f-4357-8939-61de57ed0437',
        name: '重庆渝中区接圣街来福士广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '293455'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'c5a7e01f-633a-4687-b2d2-ddbbc04d87a5',
        name: 'Nike Brigade Road',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4732'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: 'c5b56565-965c-4473-9f0b-3e02909e2bd4',
        name: '나이키 유나이트 용인',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8106'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'c5bef737-7251-4a7a-8dc8-306a7f4dbc39',
        name: 'Nike Himayat Nagar',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9047'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: 'c5c239a4-307e-41a4-a534-1c3b51ed66b6',
        name: 'NIKE OFF SITE - WOODBURY SERVICE CENTER',
        region: 'NORTH_AMERICA',
        storeNumber: '298'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c5f39da9-8e29-4965-a11a-cd9c08c64189',
        name: 'TOPSPORTS浙江杭州余杭欧美金融城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293716'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c5f7e7f0-4a1b-4c1c-9d77-d13cae8e5ce3',
        name: '나이키 김천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '49679'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'c5f86be1-3119-4639-81b8-88d16eead6dc',
        name: 'Nike Highpoint',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1815'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c621468a-e941-427f-a273-f669ac3e6381',
        name: '河南省濮阳市胜利路联华名品百货NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '240387'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'c642decb-273d-44f3-ba9b-3e9426bab030',
        name: 'Nike JM Road',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5094901'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c648fffb-bac0-442c-894a-6315fc81139f',
        name: '나이키 서면 제일',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21807'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c649b0d6-7a32-4b2b-bc3b-3badf081dc74',
        name: 'SANSE广东佛山广东省佛山市南海区桂城桂澜中路18号中海NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '275332'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
        id: 'c64f0cdf-62a5-4e2b-a2b5-2e3b8363ddcb',
        name: 'Nike Unite Troyes',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '506'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: 'c659fada-0a26-4d48-a985-e5626cdec2be',
        name: 'NIKE济宁海川优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4065'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c6631d40-b4c4-4a11-9e42-b7814fb0fabb',
        name: '耐克南京艾尚天地KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '526894'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c66f4b9e-5c8b-4103-af81-724f81140a96',
        name: '江苏省盐城市滔搏盐城商业大厦750',
        region: 'GREATER_CHINA',
        storeNumber: '260277'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: 'c688c589-6b29-4385-adb7-5228a5b233a8',
        name: 'Nike Well Collective - Hingham',
        region: 'NORTH_AMERICA',
        storeNumber: '558'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c68c797c-a315-49be-8825-654b7a946efd',
        name: '나이키 롯데 부천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '41552'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c68f613e-a43b-4a59-8e88-475aba0e7f6a',
        name: '杭州大厦C座NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '190563'
      },
      {
        address: {
          country: 'KAZ'
        },
        id: 'c68fed23-aec7-4760-9bca-ecb717aa426b',
        name: 'Nike Store Shymkent Plaza (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KAZ025'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c69b2783-cbf6-49f8-9faa-8c4b5102a4f9',
        name: '나이키 시흥',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '46927'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c69c0c04-5271-4daa-b46f-b2440e3b69f6',
        name: '北京西城区西单大悦城 TOTAL HOOPS-L2',
        region: 'GREATER_CHINA',
        storeNumber: '178823'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c6ac4c7c-35c2-47ea-ac8e-e8914990197c',
        name: '北京东城来福士NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '259071'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c6c60b4b-616c-4f44-a668-75ed59bcbfeb',
        name: '나이키 모다 울산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '66380'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c6d03209-1d71-4e6c-93f9-72fa1ad6b215',
        name: '滔搏湖南长沙开福区湘江北路1500号北辰大悦城G+1F BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '272590'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'c6d49c88-8446-4008-b0a0-0c109234b13c',
        name: 'Nike Factory Store - Fortaleza OFF',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6336'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c6dfcc35-16a2-4824-842b-edff315491a2',
        name: 'TOPSPORTS山西阳泉新城大道万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282190'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: 'c6ed6873-e63b-423b-8b10-dfed18a7561c',
        name: 'Nike Unite Brunnthal',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '968'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: 'c6efb58a-6142-4be6-9881-38acea12d056',
        name: 'NIKE太原龙湖优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3881'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c7082c19-5a5c-489b-86fb-0fc6674ced7f',
        name: '나이키 AK 수원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85994'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c723fcff-4ef3-4b73-b081-22acdca42e10',
        name: '陕西省神木市恒生国际购物中心Nike Sport Small',
        region: 'GREATER_CHINA',
        storeNumber: '273133'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: 'c7260bc2-fa68-4fd4-babd-a1401753a17c',
        name: 'Nike Clearance Store - McAllen',
        region: 'NORTH_AMERICA',
        storeNumber: '279'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c72eb11b-2c7a-4548-a7c0-0913db3c06d7',
        name: '辽宁省营口市站前万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269328'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c7337ffa-46a2-4865-99ed-6902ab92c179',
        name: '耐克重庆八一广场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '191711'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'dcc4eb42-f310-4b95-ba54-d696a999c727',
        id: 'c73f083f-b055-45b8-82f4-4622e8b85d8f',
        name: 'Nike Well Collective Kingston',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2508'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c741be95-5719-4c7c-a642-2c75a933e257',
        name: '山东省济南市泉城路世茂广场NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '527984'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '05f259e2-5c3f-43b8-aae2-8a59f239af36',
        id: 'c74fb7e7-dbdf-4c4e-a325-10ce749a5513',
        name: 'Nike Factory Store London ICON',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2050'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: 'c753cb7f-18df-48b5-90f6-8bf698ebf2ea',
        name: 'NIKE上海成山路换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3820'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'c75d7dd0-b09c-4422-ac58-85db02aded0a',
        name: 'NFS Ambala',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9753'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: 'c75fcff7-58f0-403d-b07d-4afaea347038',
        name: 'Converse Factory Store - Desert Hills Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3733'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'c761f641-9566-49d2-96c0-c7f9f21c8c0a',
        name: 'Nike Up Town Center',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2896'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c76463bf-7d81-4698-9d57-c51d5ac19522',
        name: 'TOPSPORTS湖北鄂州鄂东大道新城吾悦NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274349'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: 'c764f509-6b35-4857-a35b-fe0046a43769',
        name: 'Nike Clearance Store Birmingham One Stop',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '800'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'c7763018-0206-4c5f-b4c8-19bc88357535',
        name: 'Nike Factory Store - NLEX',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9299'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '3ff1a7ff-de7d-4f2b-b7e7-e85a5c5a68c7',
        id: 'c786ae0a-edf1-4d1a-b53e-ba25cf253764',
        name: 'Nike Factory Store Barcelona',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '522'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c78bcfaa-d610-417f-9cc9-d125fd05a39e',
        name: '나이키 스타필드 위례',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85550'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c7919448-eb82-4e22-a836-f44cde330f9d',
        name: '泉州南安中骏世界城NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '240346'
      },
      {
        address: {
          country: 'BLR'
        },
        id: 'c7a24c1b-049c-40df-87dd-4dc81ef32407',
        name: 'Nike Store Minsk Zamok (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BYE006'
      },
      {
        address: {
          country: 'LTU'
        },
        id: 'c7c2aaed-c899-4501-91d7-07d871bc075b',
        name: 'Nike Outlet Klaipeda Akropolis (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LIT003'
      },
      {
        address: {
          country: 'ARE'
        },
        id: 'c7cef042-fa97-41d2-816d-2b7c8fac76c0',
        name: 'Nike Store SSS MIRDIFF CITY CENTRE',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UAE044'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'c7d3f235-e520-47ed-b3a9-feb63bab6b67',
        name: 'Nike Eastgardens',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1242'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c7fe3df4-5be2-422b-9cf6-fbd582bd1095',
        name: '河南省郑州市二七区航海路万千百货BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '244479'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: 'c820240b-c50a-4306-b25b-cc5eb86ec27d',
        name: 'Converse Factory Store - Woodbury Common Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3735'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c85e4cc4-f7b7-4441-a017-85702a2a4d9a',
        name: '重庆市大足大融城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274365'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'c88294c1-469a-49e9-9880-54817146a5f1',
        name: '나이키 점촌',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '96668'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c8931ba0-4fa5-491b-b2c8-caaea0885135',
        name: 'Chongqing AOTI FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6021'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c8936a4b-34ca-4c98-827c-ea419c6099ca',
        name: '陕西西安雁塔区小寨赛格购物中心Jordan-L1',
        region: 'GREATER_CHINA',
        storeNumber: '241136'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c896406b-6673-4fac-ab3f-bd8d91b0d851',
        name: 'HIGHWAVE SPORTS四川绵阳花园南街苏宁百货NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288449'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
        id: 'c8b4b6b0-e4aa-4f57-b0a8-cc5b58aba0b7',
        name: 'Nike Employee Store Hilversum',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '502'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'c8c03229-6255-4eff-ad5d-e2eb2c9dbe86',
        name: 'Converse Brand Outlet Store Lyon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8117'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c8c32504-714a-4192-8ea5-78906f40f516',
        name: '湖北省武汉市锐力武汉解放路NK BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526866'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c8c804ac-f63d-475a-ad1b-97b3b412eb62',
        name: '浙江省金华市永盛购物广场 KL-1',
        region: 'GREATER_CHINA',
        storeNumber: '272782'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd018da20-ba06-4a8b-82d2-eaa2a5670c5d',
        id: 'c8c9dfcc-0b2e-4ce6-9900-568df724a148',
        name: 'Nike Factory Store Jerez',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '994'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: 'c8cc9108-0ac1-4627-81e4-2679e1b15225',
        name: 'Nike Birmingham',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2506'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
        id: 'c8d3c83b-4972-40d6-b5da-b6811336cff9',
        name: '나이키 팩토리 스토어 파주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6990'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c8f07343-a5a6-4783-abcc-e50c386c9d62',
        name: '山东省济南市高新区中铁银座NIKESPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '268903'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c942c28c-b4eb-408f-93e4-2216689a4b3a',
        name: 'POUSHENG江苏无锡蠡溪路滨湖万象汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293472'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: 'c97b023a-2cf6-4663-b092-abbed9680db1',
        name: 'Nike Factory Store - Daytona Beach',
        region: 'NORTH_AMERICA',
        storeNumber: '249'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: 'c9982db4-b25e-4e9c-b562-b99716d389be',
        name: 'NIKE ONLY EASTGATE',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2303'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'c9b65100-f9aa-4d93-8186-712c7918b56f',
        name: 'Nike Store Milan Assago (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2497'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'c9bd331e-4705-4d41-b036-83633868fe2f',
        name: 'Nike Wave Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5056082'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'c9ec8dbc-5053-4038-8ca8-f563f55715a2',
        name: '531474',
        region: 'GREATER_CHINA',
        storeNumber: '531474'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ca00e70f-8608-4918-b7dc-2fd7d77b97cd',
        name: '山东省济南市愧荫区振兴街银座百货BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '269109'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ca02ee22-fe4f-4328-9d39-cfd534369b65',
        name: '나이키 정읍',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '31179'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ca1c524b-8286-4875-bf9f-f4d41dcfc418',
        name: 'TOPSPORTS河北保定裕华东路北国商城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '1488'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'ca2421c4-fe3a-4e31-9eb7-08dac5e2ff6f',
        name: 'Nike Parque Tezontle',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '300330'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'ca2fe4c1-d2b4-4f85-8ace-fc721d57ad55',
        name: 'Nike MGF Gurgaon',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3973'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: 'ca3319c3-6fd0-44f8-ad41-23110d090490',
        name: 'Nike Well Collective - Minnetonka',
        region: 'NORTH_AMERICA',
        storeNumber: '604'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ca37c494-7627-418c-accc-ec8a3fb5bc6b',
        name: '나이키 타임스퀘어',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '58745'
      },
      {
        address: {
          country: 'GEO'
        },
        id: 'ca4da9d8-1dae-429a-906e-fffa63cc5f81',
        name: 'Nike Store Tbilisi Vazha Pshavela (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'GEO002'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ca5223b6-a996-4bce-85e9-c6434abaa006',
        name: '山东省威海市环翠区威高广场 NIKE SPORT L',
        region: 'GREATER_CHINA',
        storeNumber: '270735'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ca63c84b-ffed-4183-bdb4-547adc56341d',
        name: '耐克北京金源时代购物中心体验店',
        region: 'GREATER_CHINA',
        storeNumber: '241490'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'ca6ca252-255a-4e46-b92e-39cc6e487dc3',
        name: 'Nike Store OzdilekPark (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2315'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ca78d1c0-3854-4235-8696-598174abf923',
        name: '나이키 롯데 광교',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '66125'
      },
      {
        address: {
          country: 'NOR'
        },
        districtId: '0f873e25-c3dc-420c-82e5-740c579c5669',
        id: 'ca8320c4-b405-4a4a-9086-2f5b1752c5e1',
        name: 'Nike Store Oslo (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2471'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: 'ca9fa041-73d1-412b-94b3-72215ac3acf0',
        name: 'Nike Unite - The Quarry',
        region: 'NORTH_AMERICA',
        storeNumber: '260'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cab3a201-d048-4e39-8589-f3eb08fc840e',
        name: 'TOPSPORTS天津西青区大悦汇NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '293973'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cac1c3b7-34a1-4bed-8b71-e41057f53fb9',
        name: '铁力正阳大街铁力商城NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '268802'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cacc7498-697b-4a9f-982d-5ce835c7bd4f',
        name: '山东省新泰市山东省泰安市新泰县新泰银座SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '189581'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: 'cafd5690-2f37-490a-bbc2-216185d5ec6d',
        name: 'Converse Factory Store - Ellenton Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3730'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'cb0ffec9-d4fa-404d-84e8-36978f55ff38',
        name: 'Nike Oasis Coyoacan',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '422160'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cb1e9c13-7419-4dae-ba25-9b938f86b363',
        name: '四川省成都市成都万达武侯广场SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '272585'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: 'cb1fcf38-3a3a-4d51-9e6a-9c3be13f63c4',
        name: 'Converse Factory Store - Wrentham Village Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3709'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cb25acc7-c314-4e0c-a865-ca244e20e77d',
        name: '四川省成都市四川省成都天府新区麓山大道爱琴海购物公园NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287266'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cb25cfe7-1afa-4204-bbb3-35ee60fd1f21',
        name: '江苏省南京市滔搏南京市鼓楼区中央路金茂汇SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271019'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cb40dc5e-1169-415f-a013-7cbfd5638900',
        name: 'JORDAN 120 BAYIQI',
        region: 'GREATER_CHINA',
        storeNumber: '267101'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cb58ec86-0ea8-4a5a-9e09-416266034091',
        name: '北京大兴大族广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '501126'
      },
      {
        address: {
          country: 'CZE'
        },
        id: 'cb59c8da-1543-4679-95c4-9aa264fd0680',
        name: 'Nike Well Collective - Anděl (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2437'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'cb80ca8f-3b60-4ac5-9baa-6d8de4b1817d',
        name: '나이키 광장',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '96666'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cbaad06c-ce7a-4aac-a9c2-d3d3085a2739',
        name: '青海西宁市西大街王府井百货B馆BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '531436'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cbc08b2b-de6b-4357-bd6e-26dd744e0fa2',
        name: '滔搏湖南省吉首市人民中路湘泉广场天虹购物中心1F NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272758'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cbc4eba4-2493-4f4a-9418-1bd2f2b81d1b',
        name: '滔搏广西壮族自治区南宁市江南区金凯路龙光蓝鲸世界广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273144'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
        id: 'cbc5a057-15d8-45af-ac81-ba95c6063ae4',
        name: 'NIKE大中华园区员工店',
        region: 'GREATER_CHINA',
        storeNumber: '3833'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cbe658f7-b9ca-44e0-985e-cf8bf0aa8026',
        name: 'JORDAN 688 JIEFANG',
        region: 'GREATER_CHINA',
        storeNumber: '243049'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'cc1c9a57-18c9-47b3-ab96-c245d45834c1',
        name: 'NIKE STORE PADOVA',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2384'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1cb2bafd-c74f-4e9b-afa0-deae26868954',
        id: 'cc1f8170-a572-42da-b421-d20477ca3287',
        name: 'Nike Factory Store Vicolungo',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '566'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cc2bc7e1-8fb9-4de4-a869-a9960c3c5d19',
        name: 'REALLY江苏扬州江都金鹰NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '452911'
      },
      {
        address: {
          country: 'LTU'
        },
        id: 'cc40221e-8722-4111-ae35-a1f9349a2154',
        name: 'Nike Outlet Vilnius Big (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LIT004'
      },
      {
        address: {
          country: 'NGA'
        },
        id: 'cc49f154-281c-4c9e-a0a8-88f9d996738e',
        name: 'Nike Store Ikeja City Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'NIG001'
      },
      {
        address: {
          country: 'ESP'
        },
        id: 'cc4e24f3-62e3-4e9c-9c69-8b63c0266ed6',
        name: 'Nike Store Madrid Serrano II (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2450'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cc507cd7-4cba-4cb3-b082-baf603e81206',
        name: '河南平顶山体育路运动城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '16959'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cc62ad43-b49b-4971-b48e-85779c4f6850',
        name: '山东省济宁市任城区济州上城广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273642'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: 'cc6d4a34-91ab-4c6e-a696-139246d10ef6',
        name: 'Nike Unite Adelaide',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6171'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cc6fde82-c6eb-4a09-92c3-542f7f06e8e3',
        name: 'REALLY江苏扬州汶河南路文昌金鹰NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '195486'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cc84477a-c69c-4da0-9a9c-8a32f3b8cadf',
        name: '广东省佛山市盛世长运佛山岭南天地KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269216'
      },
      {
        address: {
          country: 'THA'
        },
        districtId: '1e4c57d2-d7e5-4937-99ca-8c74e84c150c',
        id: 'cc8fe7a6-b08d-4477-ba01-554a25f40964',
        name: 'Nike Clearance Store Pattaya',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6813'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cc958688-039c-42d4-819f-2ebcaadce390',
        name: 'Suzhou Aeon II FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6011'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'cce23aef-06b2-41ca-8fee-ef52e8c3d151',
        name: '나이키 마산 합성',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '30797'
      },
      {
        address: {
          country: 'CHE'
        },
        districtId: '1c3af8dc-d58a-4495-909d-823d7d8f09e1',
        id: 'ccf66a32-3db7-4838-8cd4-aeb1f3f2c664',
        name: 'Nike Factory Store Mendrisio',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '507'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ccf84391-c60e-49e6-826d-8988d14e7902',
        name: 'POUSHENG辽宁鞍山二道街百盛KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288873'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: 'cd2e14dd-da3f-4a1b-847d-1dacfbc4fe7f',
        name: 'Converse Factory Store - Tanger Outlets Southaven',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3116'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cd46f958-c4b2-45a7-95d5-e1dfaa52f321',
        name: '黑龙江省哈尔滨市南岗区红旗大街会展中央公园 NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272971'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: 'cd605f60-6b04-4e8f-90ac-cc4202cee46a',
        name: 'NIKE武汉兴业换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3874'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cd65e1b6-22f2-45e6-b4fa-93dac70586e4',
        name: '浙江省嘉善市滔搏嘉兴嘉善嘉善大道太平洋百货NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '245803'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'cd8e7c2f-34c2-4980-993b-ae9566b639c9',
        name: 'Nike Store Optimum (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2773'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'cd9fdb43-f71b-43d2-8a66-32775c14f072',
        name: 'Nike Factory Store - Vale Sul',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6324'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cda6d6e2-5eed-4e30-a249-22c362c5b307',
        name: '重庆市江北区观音桥北城天街Kicks Lounge L1',
        region: 'GREATER_CHINA',
        storeNumber: '270997'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'cdad37d9-b458-40d2-b1b4-d68cc2905ad2',
        name: 'Nike Plaza Vespucio',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7234'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'cdc4dd33-80d4-4b4c-a2c9-6a9096efcb3c',
        name: 'Nike Indooroopilly',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7173'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
        id: 'cdce29d4-c3f1-4ae9-b8b1-ec6f660515d0',
        name: 'NIKE淮海品牌体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3441'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cdd66ddf-4eb3-413d-b688-55f8920cbb04',
        name: '山东省青岛市青岛黄岛香江路长江利群NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272773'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '027e5cb7-0256-4a70-882d-cc673a2cf581',
        id: 'cdeca7ba-6118-4f98-98d0-88455c644e37',
        name: 'Nike Well Collective - Bayshore',
        region: 'NORTH_AMERICA',
        storeNumber: '457'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cdf3292e-085a-45cc-b4f5-bf2af52fa187',
        name: '江苏省盐城市宝胜盐城金鹰天地BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241169'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cdf8cfe2-3ea6-4d42-8b1c-bdb03bd4511b',
        name: '江苏省徐州市滔搏徐州云龙和平大道万达广场BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '269314'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ce017972-fee2-4f27-808c-611497bf70f9',
        name: '北京市海淀区翠微百货KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '189467'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ce0a4f07-944a-49fe-aafc-21fc022762a4',
        name: '나이키 경주2',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '28637'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'ce34d76b-c8b2-49c2-b5af-86a9b8ff925a',
        name: 'NIKE北京机场换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3655'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
        id: 'ce44bd73-da03-4ea3-993a-84cf7c30bdce',
        name: 'Nike Factory Store - Northfield',
        region: 'NORTH_AMERICA',
        storeNumber: '450'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'ce48e952-ad80-46e5-b20b-a807a00a1a73',
        name: 'Nike SM Bacolod',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5097809'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ce8faafb-4bb1-4783-a52d-cd495db17d3e',
        name: '浙江省绍兴市上虞上百万和城',
        region: 'GREATER_CHINA',
        storeNumber: '275340'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: 'cea43d94-06fd-4b47-99fb-3765bb0bcec9',
        name: 'Nike Clearance Store - Pasadena',
        region: 'NORTH_AMERICA',
        storeNumber: '257'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ceb7f687-b79f-42a3-abf6-578f126c8b56',
        name: '江苏省徐州市徐州金鹰二期KL L1',
        region: 'GREATER_CHINA',
        storeNumber: '259053'
      },
      {
        address: {
          country: 'GRC'
        },
        districtId: 'f6f807a1-6c74-48f8-af79-d9dad8664dd7',
        id: 'cec45ee7-bb02-4c24-93c1-aaa3bd6524f8',
        name: 'Nike Factory Store Piraeus',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '661'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cef080fe-bd54-4c69-92c5-6c8f6ab9782a',
        name: '无锡锡山区荟聚中心NK RISE',
        region: 'GREATER_CHINA',
        storeNumber: '274246'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'cf00eb5c-1743-48ce-a219-18d0368f5d4b',
        name: 'Nike By Vaishali',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8221'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cf8b6910-a85f-4650-a796-ce69f14ac60f',
        name: '辽宁省鞍山市立山区胜利北街大悦城 NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '269325'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cf8ec048-a986-4c0d-bf97-03d73f73cd46',
        name: '云南省昆明市昆明百大新西南NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271285'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66772996-562e-4025-a37f-33b8bab87475',
        id: 'cf9ac2c6-91b8-4124-83ed-e6bcf7512c11',
        name: 'Converse SoHo',
        region: 'NORTH_AMERICA',
        storeNumber: 'C5303'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cfaaebdf-ca09-4269-8bba-3d85a694fd32',
        name: '福建省厦门市宝胜厦门磐基名品中心KICKS LOUNGE L2',
        region: 'GREATER_CHINA',
        storeNumber: '267211'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'cfc4c6e1-e1ed-412a-9817-2956c37f79ad',
        name: 'Nike Store Akbati (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2617'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cfcc0270-046a-4873-a1ba-a05782a0a44d',
        name: '吉林省长春市吉林省长春晨宇购物中心-亚泰新动BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '270487'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cfcd64ab-8601-4045-b2e8-533415df94d2',
        name: '山东省济南市泉城路泉城广场银座NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274137'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'cfcf498f-f8e8-46ce-b2a9-0d8caab9d4e6',
        name: 'Converse Brand Outlet Store Castel Romano',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8112'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: 'cfd8172c-e11c-4d80-a2de-7507670ac327',
        name: 'Nike Factory Store Tillicoultry',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '523'
      },
      {
        address: {
          country: 'HUN'
        },
        districtId: '3c364fbe-617b-46b1-b867-1579b1e478f2',
        id: 'cfdb073b-d425-467b-aa1b-a05d19b50b2a',
        name: 'Nike Factory Store Budapest',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '569'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'cfded6e7-da53-4522-a52c-9a21de985020',
        name: 'TOPSPORTS辽宁抚顺新城东路与盛城街万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293452'
      },
      {
        address: {
          country: 'ISR'
        },
        districtId: '80d72218-6d2b-46e2-bea0-2210cb625fbb',
        id: 'cfe7766d-131a-4cbd-b6f8-f80490558cf5',
        name: 'Jerusalem Nike Factory Store',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '950'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd034d991-550f-4fb0-a14a-6ca7dbfc3277',
        name: '山东省青岛市青岛市北合肥路佳世客BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '109275'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd0388cb3-66cf-4b32-bacc-7729bec98bc7',
        name: 'Hangzhou Sasseur FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6028'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd04701ec-0721-41f8-a9bf-712b6ef5ed45',
        name: '耐克深圳海岸城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '525676'
      },
      {
        address: {
          country: 'POL'
        },
        id: 'd0591633-4f79-4cab-a541-269c7539ff63',
        name: 'Nike Store Rzeszow Millenium Hall II (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2402'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd08996ae-9f5a-4392-a68b-1e6ed4a8e187',
        name: '江苏省南通市锐力桃园路中南城A馆NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272778'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'd09e8cb2-35b3-4b25-abfe-0d5ac0adc9af',
        name: 'Converse Brand Outlet Store Troyes',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8107'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd0a4760a-0493-455e-a20b-84f328d3609a',
        name: '黑龙江省牡丹江市牡丹江太平路百货大楼NK BEACON',
        region: 'GREATER_CHINA',
        storeNumber: '259073'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd0ad7fbd-c3a8-49d8-b74c-6b4cd32032a8',
        name: 'POUSHENG安徽合肥高新区鼓楼二店KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '293494'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd0d07dec-2f0e-491c-8923-9a4a122043a3',
        name: '山东省青岛市李村北方国贸NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271247'
      },
      {
        address: {
          country: 'HRV'
        },
        id: 'd0dada1f-978d-4b53-bff2-d9a3d1121b58',
        name: 'NSP Mall Of Split',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2748'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd0f1151f-a3c5-4db1-9620-c50ec0d3badd',
        name: '吉林长春南关区华远路远大购物中心 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293439'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd0f5842a-3014-46f9-a0ff-602678613161',
        name: 'POUSHENG安徽合肥世纪金源NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288432'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd1281b2e-d8da-4f5a-b3fd-26a01fe2573c',
        name: '나이키 송탄',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21621'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd1458a7b-e7f5-45a2-9dd4-2087a99ccbbb',
        name: '나이키 HOOP 이태원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '69679'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd153a176-6eca-48fb-900c-a89e44314fff',
        name: 'POUSHENG河南长葛长社路宇龙广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '530489'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd15d0d87-5fe9-4e91-9a8e-2057724e73a4',
        name: '吉林省长春市南关区人民大街百货大楼NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '70537'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'd17d00e6-2d2f-418a-be1e-ce7ad09f9d37',
        name: 'Converse Brand Outlet Store Corbeil Paris',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8119'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'd17ee209-b486-4196-be65-ccd1f4c6d5ba',
        name: 'Nike Store Lyon (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2604'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd199f57a-6249-4305-847e-112a7866d14b',
        name: '哈新疆大街红旗茂NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '259341'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd1b1bebf-79e6-4c9c-a5de-bae8ede20e81',
        name: '新疆维吾尔自治区乌鲁木齐市新疆乌鲁木齐市新市区德港万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '274319'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'd1c4f502-7172-4bb4-87c4-53c515180463',
        name: 'Nike Istanbul',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2332'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd1c9b39e-1b8d-4134-a6f1-280c14dfe3b8',
        name: '나이키 신제주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21610'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd1dc0131-6408-4791-a5ea-03187dd8c061',
        name: 'POUSHENG黑龙江哈尔滨道里百盛KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '282198'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd1e36cc2-ced4-459c-ab98-7655ec1d9294',
        name: 'REALLY江苏扬州邗江区文昌中路万象汇NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288370'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd1e61763-c38b-4f10-9bbb-c5db08feb8de',
        name: 'JORDAN 1 EASTCHANGAN',
        region: 'GREATER_CHINA',
        storeNumber: '259058'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd1e8d959-67d1-4b06-9fd3-23da99f43b9f',
        name: 'TOPSPORTS河北保定竞秀区万博广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288463'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: 'd1f20754-cfce-46ba-8717-d61aa10a7880',
        name: 'Converse Factory Store - Pleasant Prairie Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3711'
      },
      {
        address: {
          country: 'EGY'
        },
        id: 'd1f85677-73d7-4081-8174-be5e4d003c23',
        name: 'Nike Store City Stars (Phase 1) Flagship',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY033'
      },
      {
        address: {
          country: 'BEL'
        },
        id: 'd1f97c12-e82c-474f-ae3c-8a977a5dfc05',
        name: 'Nike Well Collective - Die Scone (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2416'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd2062008-42b2-4fbd-a726-b5a67abfcd87',
        name: '广东省东莞市东城区东纵大道东莞君尚百货NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '3202'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd20be136-cb99-4098-8dad-9899b6aa1705',
        name: '山西省长治市长兴北路万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '273521'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'd213d6bd-6196-435a-86e2-e78d16f136d6',
        name: 'Nike Store Forum Bornova (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2333'
      }
    ],
    pages: {
      next: 'store/store_locations/v1?search=%28id%21%3Dfoo%29&anchor=3000&count=1000&fields=id%2Cname%2CstoreNumber%2Caddress.country%2CdistrictId%2Cregion',
      prev: 'store/store_locations/v1?search=%28id%21%3Dfoo%29&anchor=1000&count=1000&fields=id%2Cname%2CstoreNumber%2Caddress.country%2CdistrictId%2Cregion',
      totalPages: 4,
      totalResources: 3599
    }
  };
