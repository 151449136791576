import {
  addressValidator,
  countryCodeValidator,
  currenciesValidator,
  dateISOValidator,
  IPChannelValidator,
  IPKeyCityValidator,
  IPShipToValidator,
  IPSubTerritoryValidator,
  IPTemporaryClosureValidator,
  IPTerritoryValidator,
  IPTimezoneValidator,
  latitudeValidator,
  longitudeValidator,
  managerNameValidator,
  phoneNumberValidator,
  requiredValidator,
  storeNumberValidator,
  timezoneValidator,
  warehouseChannelsValidator,
  warehouseCompanyValidator,
  warehouseIPTypeValidator,
  warehouseTypeValidator,
} from './sls-validation';

export const fieldsToValidate = [
  { prop: 'address.address1', validators: [requiredValidator, addressValidator] },
  { prop: 'address.address2', validators: [addressValidator] },
  { prop: 'address.address3', validators: [addressValidator] },
  { prop: 'address.city', validators: [requiredValidator] },
  { prop: 'address.country', validators: [requiredValidator, countryCodeValidator] },
  { prop: 'address.postalCode', validators: [requiredValidator] },
  { prop: 'address.state', validators: [requiredValidator] },
  { prop: 'channels', validators: [warehouseChannelsValidator] },
  { prop: 'company', validators: [requiredValidator, warehouseCompanyValidator] },
  { prop: 'coordinates.latitude', validators: [requiredValidator, latitudeValidator] },
  { prop: 'coordinates.longitude', validators: [requiredValidator, longitudeValidator] },
  { prop: 'currencies', validators: [currenciesValidator] },
  { prop: 'islandPacificAttributes.keyCity', validators: [IPKeyCityValidator] },
  { prop: 'islandPacificAttributes.ipChannel', validators: [requiredValidator, IPChannelValidator] },
  { prop: 'islandPacificAttributes.ipSubTerritory', validators: [IPSubTerritoryValidator] },
  { prop: 'islandPacificAttributes.ipTerritory', validators: [IPTerritoryValidator] },
  { prop: 'islandPacificAttributes.ipTimezone', validators: [IPTimezoneValidator] },
  { prop: 'islandPacificAttributes.ipWarehouseType', validators: [requiredValidator, warehouseIPTypeValidator] },
  { prop: 'islandPacificAttributes.sapShipTo', validators: [requiredValidator, IPShipToValidator] },
  { prop: 'islandPacificAttributes.temporaryClosure', validators: [IPTemporaryClosureValidator] },
  { prop: 'islandPacificAttributes.warehouseNumber', validators: [requiredValidator] },
  { prop: 'managerName', validators: [managerNameValidator] },
  { prop: 'name', validators: [requiredValidator] },
  { prop: 'operationalDetails.openingDate', validators: [dateISOValidator] },
  { prop: 'operationalDetails.closingDate', validators: [dateISOValidator] },
  { prop: 'phone', validators: [phoneNumberValidator] },
  { prop: 'timezone', validators: [requiredValidator, timezoneValidator] },
  { prop: 'type', validators: [requiredValidator, warehouseTypeValidator] },
  { prop: 'warehouseNumber', validators: [requiredValidator, storeNumberValidator] },
];

export default fieldsToValidate;
