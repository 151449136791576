import { Loading } from '@nike/frame-component-library';
import { ModalStyled } from '@nike/nike-design-system-components';
import { Default, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../../../reusable';

const ModalStatus = ({
  isLoading, isOpen, setIsOpen, message, setMessage, setTitle, title,
}) => {
  const closeModal = () => {
    setMessage({});
    setTitle('');
    setIsOpen(false);
  };

  const displaySomeMessage = ({ message: msg, status } = {}) => match(status)(
    ['initiating', <p className="text-color-primary-dark ta-sm-c body-2 mt2-sm">{msg}</p>],
    ['success', <p className="text-color-success ta-sm-c body-2 mt2-sm">{msg}</p>],
    ['failed', <p className="text-color-error ta-sm-c body-2 mt2-sm">{msg}</p>],
    [Default, null],
  );

  const CloseButton = () => (
    <article className="ncss-col-sm-12 ta-sm-c va-sm-t">
      <ButtonBlack
        isDisabled={isLoading}
        label="Close"
        onClick={closeModal}
      />
    </article>
  );

  return (
    <>
      <ModalStyled
        closeModal={closeModal}
        isOpen={isOpen}
        title={title}
      >
        <article className="ncss-col-sm-12 va-sm-t">
          {displaySomeMessage(message)}
          {isLoading ? <Loading /> : null}
          <CloseButton />
        </article>
      </ModalStyled>
      <div id="modal-root" />
    </>
  );
};

export default ModalStatus;

ModalStatus.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.shape().isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
