import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel } from '../../../reusable';
import { Address as AddressInfo, Coordinates } from '../../generic';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || (properties.formErrors.address
    ? (!!properties.formErrors.address.address1 || !!properties.formErrors.address.city
      || !!properties.formErrors.address.state || !!properties.formErrors.address.postalCode)
    : true) // true because this object is required
  || (properties.formErrors.coordinates
    ? (!!properties.formErrors.coordinates.latitude || !!properties.formErrors.coordinates.longitude)
    : true) // true because this object is required
  || !!properties.formErrors.timezone);

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Address"
      onClick={this.panelToggle}
    >
      <article className="ncss-col-sm-6 va-sm-t pt1-sm">
        <AddressInfo
          address={this.props.formData.address || {}}
          addressErrors={this.props.formErrors.address || {}}
          disableCmpFields={this.props.disableCmpFields}
          facilityType="Stores"
          updateAddress={this.props.updateAddress}
          userIsReadOnly={this.props.userIsReadOnly}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t pt1-sm">
        <Coordinates
          disableCmpFields={this.props.disableCmpFields}
          formData={this.props.formData}
          formErrors={this.props.formErrors}
          latitude={this.props.formData.coordinates?.latitude}
          longitude={this.props.formData.coordinates?.longitude}
          timezone={this.props.formData.timezone || ''}
          updateCoordinates={(childData) => this.props.updateFormData('coordinates', childData)}
          updateTimezone={(childData) => this.props.updateFormData('timezone', childData)}
          userIsReadOnly={this.props.userIsReadOnly}
        />
      </article>
    </CustomPanel>
  );
}

Address.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateAddress: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Address;
