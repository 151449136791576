/* eslint-disable no-alert */
import { Input } from '@nike/frame-component-library';
import ommit from 'lodash/omit';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { REGISTERS_TABLE_COLUMNS, WORKSTATION_DEVICE_TYPES } from '../../constants/registerleveltracking';
import {
  getDeviceTypeLabel, isHighestWorkstationNumber, groupWorkstationRecords, getPlaceholder, isInvalidSubmit, isRecordUpdated, getErrorMessage,
} from '../../utils/rlt-utils';
import { deleteRegisters, postRegisters, putRegisters } from '../../utils/service-calls/register-level-tracking';
import {
  ButtonBlack, Select,
} from '../reusable';
import EditableTable from '../reusable/EditableTable';

import AddOptions from './actions/AddOptions';
import DeleteOptions from './actions/DeleteOptions';
import ReplaceOptions from './actions/ReplaceOptions';

const emptyRegisterObj = {
  dirtyFlagAdd: true,
  serialNumber: '',
  type: '',
  vendorCode: '',
  workstationNumber: '',
};

class RegistersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageError: '',
      messageSuccess: '',
      registers: [],
      saveSuccess: false,
    };
  }

  componentDidMount() {
    this.setState({
      messageError: this.props.messageError,
      registers: this.props.registers,
    });
  }

  getColumns = (cols) => cols.map((colConfig) => ({
    accessor: colConfig.accessor,
    Cell: this.renderField,
    Header: colConfig.Header,
    width: colConfig.width,
  }));

  onClickAddConfirm = (record) => {
    if (isInvalidSubmit(record)) {
      this.setState({ messageError: 'Please input all the mandatory fields.' });
    } else {
      this.setState((previousState) => ({
        messageError: '',
        registers: (previousState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item, isProcessing: true } : item))),
      }), () => {
        const newRegisterRecord = { storeId: this.props.selectedStore.id, ...(ommit(record, ['rowNo', 'dirtyFlagAdd', 'workstationNumber'])) };

        this.registersPost(this.props.userInfo.accessToken, newRegisterRecord)
          .then((res) => (this.setState((prevState) => ({
            messageError: '',
            messageSuccess: 'New Workstation successfully added',
            registers: this.props.deviceType === 'ALL' || this.props.deviceType === newRegisterRecord.type
              ? groupWorkstationRecords((prevState.registers.map((item) => (item.rowNo === record.rowNo ? { ...res, dirtyFlagAdd: false, isProcessing: false } : item))))
              : groupWorkstationRecords((prevState.registers.filter((item) => (item.rowNo !== record.rowNo)))),
            saveSuccess: true,
          }))))
          .catch((err) => this.setState((previousState) => ({ messageError: getErrorMessage(err), registers: (previousState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item, isProcessing: false } : item))) })));
      });
    }
  };

  onClickAddNew = () => {
    if (this.props.isReadOnly) { return; }
    if (this.state.registers.find((item) => item.dirtyFlagAdd)) {
      // eslint-disable-next-line no-alert
      alert('Please save or discard the unsaved Workstation entry before adding another');
    } else {
      this.setState((prevState) => ({
        messageError: '',
        registers: [
          ...prevState.registers,
          { ...emptyRegisterObj, rowNo: prevState.registers.length + 1 },
        ],
        saveSuccess: false,
      }));
    }
  };

  onClickCancel = (record, flag) => {
    this.setState((prevState) => ({
      messageError: '',
      registers: flag === 'add'
        ? prevState.registers.filter(
          (item) => item.rowNo !== record.rowNo,
        )
        : prevState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item.original, dirtyFlagUpdate: false, original: item.original } : item)),
    }));
  };

  onClickDelete = (record) => {
    if (this.props.isReadOnly) { return; }
    this.setState((previousState) => ({
      messageError: '',
      messageSuccess: '',
      registers: (previousState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item, isProcessing: true } : item))),
    }), () => {
      this.registersDelete(this.props.userInfo.accessToken, record.id)
        .then(() => this.setState((prevState) => ({
          messageError: '',
          messageSuccess: 'Workstation successfully deleted',
          registers: groupWorkstationRecords(prevState.registers.filter(
            (item) => item.rowNo !== record.rowNo,
          )),
          saveSuccess: true,
        })))
        .catch((err) => this.setState((previousState) => ({ messageError: getErrorMessage(err), registers: (previousState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item, isProcessing: false } : item))) })));
    });
  };

  onClickReplace = (record) => {
    if (this.props.isReadOnly) { return; }
    if (!record.dirtyFlagUpdate) {
      // Clicked on the replace first time; make fields editable
      this.setState((prevState) => ({
        messageError: '',
        registers: prevState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item, dirtyFlagUpdate: true, original: item } : item)),
        saveSuccess: false,
      }));
    } else {
      // Clicked on the replace 2nd time; persist the changes
      const updatedRegisterData = pick(record, ['vendorCode', 'serialNumber']);
      if (isInvalidSubmit(updatedRegisterData)) {
        this.setState({ messageError: 'Please input all the mandatory fields.' });
      } else {
        this.setState((previousState) => ({
          messageError: '',
          registers: (previousState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item, isProcessing: true } : item))),
        }), () => {
          if (isRecordUpdated(updatedRegisterData, this.state.registers.find((item) => item.rowNo === record.rowNo)?.original)) {
            this.registersPut(this.props.userInfo.accessToken, record.id, updatedRegisterData)
              .then(() => (this.setState((prevState) => ({
                messageError: '',
                messageSuccess: 'Workstation successfully updated',
                registers: prevState.registers.map((item) => (item.rowNo === record.rowNo
                  ? {
                    ...item, dirtyFlagUpdate: false, isProcessing: false, original: null,
                  }
                  : item)),
                saveSuccess: true,
              }))))
              .catch((err) => this.setState((previousState) => ({ messageError: getErrorMessage(err), registers: (previousState.registers.map((item) => (item.rowNo === record.rowNo ? { ...item, isProcessing: false } : item))) })));
          } else {
            this.setState((prevState) => ({
              messageError: 'Replace opertation was ignored. There was nothing to update.',
              messageSuccess: '',
              registers: prevState.registers.map((item) => (item.rowNo === record.rowNo
                ? {
                  ...item, dirtyFlagUpdate: false, isProcessing: false, original: null,
                }
                : item)),
            }));
          }
        });
      }
    }
  };

  onRowInputChange = (e, column, record) => {
    const value = typeof e === 'object' ? e.target.value : e;
    const items = [...this.state.registers];
    const currentItem = items.find((item) => item.rowNo === record.rowNo);
    currentItem[column] = value;
    this.setState((prevState) => ({
      registers: prevState.registers.map((item) => (item.rowNo === record.rowNo ? currentItem : item)),
    }));
  };

  registersDelete = async (token, id) => {
    const deleteResposne = await deleteRegisters(token, id)
      .then((response) => response.body)
      .catch((err) => { throw err; });
    return deleteResposne;
  }

  registersPost = async (token, record) => {
    const postResposne = await postRegisters(token, record)
      .then((response) => response.body)
      .catch((err) => { throw err; });
    return postResposne;
  }

  registersPut = async (token, id, record) => {
    const putResposne = await putRegisters(token, id, record)
      .then((response) => response.body)
      .catch((err) => { throw err; });
    return putResposne;
  }

  renderField = (props) => {
    const record = props.original;
    const { column } = props;
    const colConfig = REGISTERS_TABLE_COLUMNS.find((item) => item.accessor === column.id);

    const isEditable
      = (record.dirtyFlagAdd && colConfig.isUserInput && !record.isProcessing)
      || (record.dirtyFlagUpdate && colConfig.isUpdatable)
      || colConfig.control === 'actions';

    if (!isEditable) return colConfig.accessor === 'type' ? getDeviceTypeLabel(record[colConfig.accessor]) : record[colConfig.accessor];

    switch (colConfig.control) {
      case 'dropdown':
        return (
          <Select
            key={`${colConfig.accessor}-${record.rowNo}`}
            className="ncss-col-sm-12 ta-sm-l"
            id={colConfig.accessor}
            options={WORKSTATION_DEVICE_TYPES.filter((item) => item.value !== 'ALL')}
            placeholder={getPlaceholder(colConfig)}
            style={{ position: 'absolute' }}
            value={record[colConfig.accessor]}
            zIndex={20}
            onChange={(value) => this.onRowInputChange(value, colConfig.accessor, record)}
          />
        );
      case 'textfield':
        return (
          <Input
            key={`${colConfig.accessor}-${record.rowNo}`}
            id={colConfig.accessor}
            placeholder={getPlaceholder(colConfig)}
            style={{ zIndex: 1 }}
            type="text"
            value={record[colConfig.accessor]}
            onChange={(e) => this.onRowInputChange(e, colConfig.accessor, record)}
          />
        );
      case 'actions':
        return (
          <article className="ncss-col-sm-12 va-sm-t ta-sm-l m2-sm">
            {!(record.dirtyFlagUpdate || record.dirtyFlagAdd || record.isProcessing) && (
            <ButtonBlack
              className="m1-sm"
              isDisabled={this.props.isReadOnly}
              label="Replace"
              onClick={() => this.onClickReplace(record)}
            />
            )}
            {!(record.dirtyFlagAdd || record.dirtyFlagUpdate)
              && (isHighestWorkstationNumber(this.state.registers, record)) && (
                <DeleteOptions isReadOnly={this.props.isReadOnly} record={record} onClickDelete={this.onClickDelete} />
            )}

            {record.dirtyFlagUpdate && (<ReplaceOptions record={record} onClickCancel={this.onClickCancel} onClickReplace={this.onClickReplace} />)}

            {record.dirtyFlagAdd && (
              <AddOptions record={record} onClickAddConfirm={this.onClickAddConfirm} onClickCancel={this.onClickCancel} />
            )}
          </article>
        );
      default:
        return <></>;
    }
  };

  render = () => (
    <section className="ncss-row mt8-sm">
      {(
        <EditableTable
          className="-striped -highlight"
          columns={this.getColumns(REGISTERS_TABLE_COLUMNS)}
          data={this.state.registers}
          getTdProps={() => ({
            style: {
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              overflow: 'visible',
              padding: '2px',
            },
          })}
          sortable={false}
          style={{ overflow: 'visible' }}
        />
      )}
      {this.state.saveSuccess ? (
        <aside className="m4-sm text-color-success body-2">
          {this.state.messageSuccess}
        </aside>
      ) : (
        <aside className="m4-sm text-color-error body-2">
          {this.state.messageError}
        </aside>
      )}
      <article className="ncss-col-sm-6 va-sm-t ta-sm-c m2-sm">
        <ButtonBlack
          className="m2-sm"
          label="Refresh List"
          onClick={this.props.onSearchClick}
        />
        <ButtonBlack
          className="m2-sm"
          isDisabled={this.props.isReadOnly}
          label="Add New"
          onClick={this.onClickAddNew}
        />
      </article>
    </section>
  );
}
RegistersList.defaultProps = {
  registers: [],
};

RegistersList.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  messageError: PropTypes.string.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  registers: PropTypes.arrayOf(PropTypes.shape),
  selectedStore: PropTypes.shape().isRequired,
  userInfo: PropTypes.shape({
    accessToken: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInfo: state.authorizationReducer.auth,
});

export default connect(mapStateToProps, null)(RegistersList);
