export default {
    objects: [
      {
        address: {
          country: 'CHN'
        },
        id: '463b7d3a-ac5d-47d5-9269-550f73e082dc',
        name: 'POUSHENG吉林财富广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '293440'
      },
      {
        address: {
          country: 'IND'
        },
        id: '464dce3b-e5e7-47fd-8f65-1e3b8576ef99',
        name: 'Nike Kamla Nagar',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4730'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '466193e9-d4a4-461c-971e-be545fda89e2',
        name: 'NIKE苏州友新换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3737'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '92c6ee55-0adc-4eab-b0cc-256a9e8b04dd',
        id: '467f7224-ddd3-4381-9496-7bf5ee988535',
        name: 'Nike Factory Store Sicilia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '638'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '468e1866-00ad-4e7e-92f9-86330f3eba42',
        name: '나이키 원주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85554'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '469c7342-5d42-4251-83b4-5ef2da41dc43',
        name: '浙江省温州市锐力温州大南路世贸广场JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '522707'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '46b487b7-1c2c-4022-a43d-7e330e4aea48',
        name: 'MARKVILLE SHOPPING CENTRE',
        region: 'NORTH_AMERICA',
        storeNumber: '469246'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '46c7699d-e37b-4d20-a380-e1d9e8fcf688',
        name: 'Nike Outlet Kyiv Spectrum (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR034'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9d9eff3f-247a-4ecb-92af-3ea47a3ef09f',
        id: '46daa740-be97-457a-ae35-717a384fd03c',
        name: 'Nike Factory Store - Winnipeg',
        region: 'NORTH_AMERICA',
        storeNumber: '451376'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '46e30d94-7032-4ade-a3da-89b7d7748fc6',
        name: '内蒙古自治区乌兰察布市察哈尔大街万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270473'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '46f9e05f-deaf-461b-9dd3-ea9333fe446b',
        name: 'TOPSPORTS山东枣庄君山路贵诚购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287202'
      },
      {
        address: {
          country: 'ZAF'
        },
        districtId: '43bbce93-a2ad-4606-a7df-205395646fdf',
        id: '470db61b-6398-4478-bbe0-446f76f01a67',
        name: 'Nike Factory Store Atterbury',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '955'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4717e25d-1ff4-4e6a-87aa-dac7470851da',
        name: '陕西省西安市雁塔区慈恩西路大悦城购物中心SPORTS-L',
        region: 'GREATER_CHINA',
        storeNumber: '274248'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '472a8cae-5fa4-4af0-b5f0-c8157bff8e60',
        name: '天津梅江环宇城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288048'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '47389e88-53f8-4ddb-b76b-d417106a5f23',
        name: 'NIKE长沙欧洲换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3853'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: '4739D4C3-C17C-42D3-8810-F5A1B9B78A27',
        name: 'Nike Clearance Store - Niagara Falls',
        region: 'NORTH_AMERICA',
        storeNumber: '165'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '473f879f-35ec-4057-aa66-42015cd027d0',
        name: '重庆江南大道协信星光时代广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '177737'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4744b228-8f65-4ec5-8ff6-cc3130f28ffc',
        name: 'Huzhou Capital FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6022'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '47701892-5c14-4b90-83e9-81d02df4530f',
        name: '浙江省义乌市绿地朝阳门NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287325'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4772a959-508d-4aa3-a6e8-ac4dc2284c88',
        name: 'POUSHENG安徽省合肥市保利广场SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274293'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '47992c9c-2dbb-4459-ae4c-e390f4d3798f',
        name: 'TOPSPORTS广东湛江霞山区人民大道南116号鼎盛广场3F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287336'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: '47cd53fa-3005-467d-b03f-d067a1309a1c',
        name: 'Nike Factory Store Tours',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2010'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '47e13404-30bb-472e-ae41-098745ca8aae',
        name: 'NIKE OFF SITE - WOODBURN OFF SITE',
        region: 'NORTH_AMERICA',
        storeNumber: '9129'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '47ef6684-f599-4956-8b3c-f43a01fc80bd',
        name: '江苏省镇江市宝胜镇江吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271314'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '47fb351b-5e7d-4ae7-8c61-8e56e60d8cca',
        name: '湖南省永州市滔搏湖南永州冷水滩区永州大道万达广场1F NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '272164'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: '4822a056-b7ed-4b43-9ed6-e1ff049ed1df',
        name: 'NIKE石家庄奥体优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4064'
      },
      {
        address: {
          country: 'LVA'
        },
        id: '483e99e8-1e0c-4d87-9db9-cc8fbf90af63',
        name: 'Nike Outlet Riga Origo (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LVL028'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4848dd7a-bfe5-405c-896c-60f93b393965',
        name: '浙江省湖州市滔搏湖州南浔人瑞路吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271331'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '524440b5-a003-410b-9e4b-25c2de21868b',
        id: '487b9689-8cd3-47c8-9458-dc35b2da4442',
        name: 'Nike Factory Store Valmontone',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '808'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '488565AC-730F-4EFA-9EB7-6BBFE904DF7B',
        name: 'Nike Clearance Store - Las Vegas South',
        region: 'NORTH_AMERICA',
        storeNumber: '55'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4897a12a-885f-4fcf-9c85-b76ceda5554b',
        name: '山东省临沂市临沂运动城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '5786'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4897c995-0d0e-419f-b3a2-ed5640649cde',
        name: '浙江省杭州市杭州萧山万象汇JORDAN ONLY-L2',
        region: 'GREATER_CHINA',
        storeNumber: '243792'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: '48F59B32-3172-4076-9DCB-68965C5433EA',
        name: 'Nike Factory Store - Celebration',
        region: 'NORTH_AMERICA',
        storeNumber: '97'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '48c301f1-7a56-4c37-8240-53cfed33309c',
        name: 'REALLY浙江宁波江北区大闸南路来福士KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '293702'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: '4901B530-4165-4D90-B2C2-E6188ABCD233',
        name: 'Nike Factory Store - Grove City',
        region: 'NORTH_AMERICA',
        storeNumber: '65'
      },
      {
        address: {
          country: 'POL'
        },
        id: '490972f1-306a-45c6-8888-f3241499f713',
        name: 'Nike Store Gdansk Forum Radunia (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2321'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '4926eca8-ecb7-469a-8833-a6dfb5343775',
        name: 'NIKE上海南汇换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3413'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '49411837-db5f-4963-913f-eea0abbf2d28',
        name: '耐克上海月星环球港体验店',
        region: 'GREATER_CHINA',
        storeNumber: '194823'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4946d146-73e8-4da8-8e06-1f3773c2bdf4',
        name: '山西省太原市太原市晋源区万象城Beacon L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '524963'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '494FA372-34F8-4D17-8277-0E8E6B96CBEC',
        name: 'Nike Factory Store - Seaside',
        region: 'NORTH_AMERICA',
        storeNumber: '26'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
        id: '495906EB-E445-4523-9AE9-87CD0C8A1180',
        name: 'Nike Factory Store - Aguascalientes',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6620'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '495e2d26-c970-43e9-933d-b9b93b1d1f57',
        name: 'Nike Well Collective - Malcha (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2717'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '499cc154-7dad-4432-a9d0-0a6dda1d0096',
        name: '나이키 포항로데오',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '29066'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '49d2aced-b76b-45c0-8076-573579ffdfe1',
        name: '耐克沈阳青年大街K11体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260991'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: '49ec8617-bfad-48fc-a817-761494468c64',
        name: 'NIKE大连疏港换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3712'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '4A1A8459-3489-4A1A-9812-E67484B07DAA',
        name: 'Nike Clearance Store - Florida City',
        region: 'NORTH_AMERICA',
        storeNumber: '66'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '4AE6D0A2-34A6-4CE0-BF68-B092FD000A65',
        name: 'Nike Clearance Store - Gurnee',
        region: 'NORTH_AMERICA',
        storeNumber: '136'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '4B361610-03EE-4A00-9148-59B0C09C6DC1',
        name: 'Nike Factory Store - Camarillo',
        region: 'NORTH_AMERICA',
        storeNumber: '64'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '4B786549-64D3-4379-8718-D59F8F9D8993',
        name: 'Nike Community Store - East LA',
        region: 'NORTH_AMERICA',
        storeNumber: '225'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '4B79677C-1B8A-46FB-82E7-26C43AB3E9AA',
        name: 'Nike Unite - Meridian',
        region: 'NORTH_AMERICA',
        storeNumber: '205'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '4B86F603-90BA-4639-BF47-FCD6950D952C',
        name: 'Nike Clearance Store - Concord',
        region: 'NORTH_AMERICA',
        storeNumber: '175'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '4C690686-F90A-4FF4-A8FB-1F4A3CD43C39',
        name: 'Nike Factory Store - Aurora',
        region: 'NORTH_AMERICA',
        storeNumber: '122'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '4CDAB22C-15B4-4CA3-B235-2F8D3B0D925D',
        name: 'Nike Clearance Store - Memphis',
        region: 'NORTH_AMERICA',
        storeNumber: '161'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: '4D7AF62D-FD64-4783-A23C-1290E09FED95',
        name: 'Nike Factory Store - Aurora',
        region: 'NORTH_AMERICA',
        storeNumber: '144'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: '4D9778CB-A972-4B62-976F-126F3AEACC3A',
        name: 'Nike Clearance Store - Flushing Queens',
        region: 'NORTH_AMERICA',
        storeNumber: '218'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '4F09E08B-DB42-4F55-A8D6-6BD71B5EBDDC',
        name: 'Nike Factory Store - Gaffney',
        region: 'NORTH_AMERICA',
        storeNumber: '69'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
        id: '4F53A20F-D961-4C56-81FD-38A1BB138F0B',
        name: 'Nike Clearance Store - Auburn',
        region: 'NORTH_AMERICA',
        storeNumber: '215'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4a014ea1-f00d-4bc4-a071-6cf5553b3e71',
        name: '나이키 신세계 스타일마켓 대전',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '68975'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '4a01c268-b1b6-4bdf-a02c-9fa22802227e',
        name: 'Converse Factory Store - Albertville Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3734'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4a022483-cb31-4983-a0c1-517672cba3d8',
        name: '나이키 메가마트 동래',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '59818'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4a0b1edc-0e62-420c-b646-0de633b04f81',
        name: 'TOPSPORTS江苏南京栖霞大道万象汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288836'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '4a808278-e0eb-4350-95e5-1aa9d5ed66e7',
        name: 'NIKE广州汉溪换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3652'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4ac488cb-11e9-4a2e-87eb-85f08eccaae9',
        name: '耐克杭州金沙印象城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '241177'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4ac56dff-690b-4a73-a42a-88adeeecc607',
        name: 'TOPSPORTS安徽合肥长江东路龙湖天街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287272'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '4ad02e91-cce9-4a75-85c0-203d2ce02671',
        name: 'NIKE杭州解放东换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3879'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4ad72dde-7446-4501-9b9d-b1cf733a93fe',
        name: '重庆大渡口新天泽NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260411'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4adc565d-f615-42f0-9856-7c319209670c',
        name: '陕西省安康市汉滨区南方运动城NIKE_SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '266816'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'dcc4eb42-f310-4b95-ba54-d696a999c727',
        id: '4ade95ff-2645-4c2a-8c38-37b8434373a2',
        name: "Nike Well Collective King's Road",
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '500'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4b343783-a98f-494a-a6c3-03de35b73fdc',
        name: '吉林省长春市长春朝阳区欧亚卖场JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '436913'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '4b3fabcc-fac7-4509-8146-e2fb972d969b',
        name: 'Nike Store Rimini (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '831'
      },
      {
        address: {
          country: 'SGP'
        },
        id: '4b560629-6354-44fe-a4fe-62f9b52a6287',
        name: 'Nike Suntec',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5537'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4b591d67-5065-445e-9a57-1897d7b0aa9a',
        name: '四川省绵阳市涪城区临园路凯德广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '5818'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4b5938a2-1a86-476a-bd25-a3b2c4690fcb',
        name: '四川省成都市成都市锦江区总府路王府井SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '268680'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4b760f0b-d959-422a-bc89-33cd175bf9b9',
        name: '北京市大兴区大悦春风里NIKE SPORTS L',
        region: 'GREATER_CHINA',
        storeNumber: '269294'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: '4b7f28d1-6b4b-41c2-b577-8caf6e4ee1be',
        name: 'Nike Well Collective - Dedham',
        region: 'NORTH_AMERICA',
        storeNumber: '561'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: '4b859508-c6e4-4748-9b80-2f3e8df644ab',
        name: 'Nike Unite Hiroshima',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3193'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: '4ba56a51-ddc4-45b5-ad76-9daaf444a2b5',
        name: 'Nike Unite - East New York',
        region: 'NORTH_AMERICA',
        storeNumber: '253'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4bcd552f-9570-46ea-b05a-5b2d70c02924',
        name: '福建省厦门市百丽福建省厦门市同安区环城西路乐海广场NIKE SPORTS-S',
        region: 'GREATER_CHINA',
        storeNumber: '266798'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4c484e2d-b184-4cda-9a15-7c6f6f0cf045',
        name: '新疆乌鲁木齐铁路局太百YOUNGPARK NIKE SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '195562'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '4c56572c-959f-4815-bf1d-a9cf3c0593d0',
        name: 'NIKE三亚红树林换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4001'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4c86adfc-8fe1-45ad-8f79-abadd49eb536',
        name: '四川省成都市成都锦江区IFS国际金融中心YOUNG ATHLETES-L2',
        region: 'GREATER_CHINA',
        storeNumber: '177731'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '4c96880f-ddbb-4478-9a5a-c8ea33a4955c',
        name: 'NIKE 換季優惠店 台灣文化',
        region: 'GREATER_CHINA',
        storeNumber: '3389'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '4c97da36-ed0a-4078-b593-a624f6f22e78',
        name: 'Nike Plaza Egaña',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7196'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: '4cbce817-a790-4c09-b743-f43d1117a6a4',
        name: 'Nike Unite Iruma',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3041'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '4cda0219-34b2-411f-bebc-0afe5e368405',
        name: 'Converse Factory Store - Cincinnati Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3749'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4ce3fda0-f41b-47af-9206-2f7e0934d8ec',
        name: '山西省太原市小店区亲贤北街茂业一期BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241502'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4cec89c0-cdbc-46c3-96dc-2f8876074347',
        name: '山东省青岛市城阳区青特万达广场NIKE SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '242936'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4cf080b8-1721-43d7-9bc5-48dd3ad86db1',
        name: '나이키 롯데 고양',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '91130'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4d147663-2b55-4562-aad8-ffc93d3c66e5',
        name: '四川省成都市成都龙湖西宸天街耐克BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '529700'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4d5269ba-be9b-4147-a55b-b667c58eda5e',
        name: '天津河东津滨大道万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270671'
      },
      {
        address: {
          country: 'DEU'
        },
        id: '4d71d385-b754-44f7-9d75-cba5fce7a570',
        name: 'Nike Store Berlin Mitte (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '737'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4d7f2c5e-0d9a-48c5-b0c2-6f58daa43ece',
        name: 'HIGHWAVE SPORTS重庆江南大道协信NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281969'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4d8719c9-c6ab-4d09-bf49-b8d8d1e014ad',
        name: 'REALLY上海五角场巴黎春天NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '267187'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4d8dc60e-cb04-4296-8083-ae765fe6ae6e',
        name: '吉林省长春市长春市朝阳区卓展购物中心BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '195717'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4da9c1e8-1bbf-4896-b82d-4db9e5a989d8',
        name: '上海置汇旭辉KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '526013'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1cb2bafd-c74f-4e9b-afa0-deae26868954',
        id: '4dc1a78c-7347-4526-bdcb-c3651a2f43e7',
        name: 'Nike Factory Store Serravalle',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '544'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4dc6651a-3894-4554-9e5a-415a644e6a73',
        name: 'Nike Well Collective - 스타필드 수원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '99215'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4dcf6fa0-bffb-4154-a9b3-84c3acb1850e',
        name: '北京西城西直门外大街凯德MALL NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271042'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '5a9a0356-121c-4c8e-a37a-c6666b616408',
        id: '4ded8f7c-a2ed-4bbd-be3d-a7778ec66e87',
        name: 'Nike Well Collective – Galerias Guadalajara',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '518575'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4df98810-deff-471d-aa8a-f9a3a2d5dacb',
        name: '安溪河滨万达广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '240541'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '4e04f384-49b8-4f71-bb30-3dd6dc8c17ed',
        name: 'NIKE南京中山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3845'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4e06ff00-bbe4-4571-b98e-335ae05f291e',
        name: 'TOPSPORTS浙江衢州白云中大道路新城吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288389'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '4e209bb6-f813-4e5a-81d8-1b083ba57ae2',
        name: 'Nike Store Verona (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2787'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4e22b7d4-4b6c-4a37-89b0-ba6e103fe149',
        name: '安徽省马鞍山市金鹰NK BEACON750',
        region: 'GREATER_CHINA',
        storeNumber: '271014'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '4e2ec119-30b2-4248-b6bf-71161bc4de5a',
        name: 'NIKE杭州古墩换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3817'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4e3b6f3f-ea8e-419f-b3e3-b1edc010e0e1',
        name: '江苏省南京市百丽南京新城市广场 Beacon L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '265548'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: '4e3d2762-e749-49d7-a960-89e373c58623',
        name: 'Nike Well Collective - Shrewsbury',
        region: 'NORTH_AMERICA',
        storeNumber: '475'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4e6d01dc-3216-4740-9014-204c8507a767',
        name: '浙江省杭州市锐力杭州大厦C馆 Beacon L2-750',
        region: 'GREATER_CHINA',
        storeNumber: '260209'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '4e7cbe17-66f8-429a-aa2c-66fba4c9ac4e',
        name: 'Nike Neutral Bay',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2151'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '4e9cc9c8-571c-4198-867b-e783262205b8',
        name: 'Converse Factory Store - Charlotte Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3794'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4ea8d3b8-29d6-452a-828c-1ab0490b47fc',
        name: '上海市上海锐力金山万达BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '266812'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4eb31b39-dfde-4b87-bc4b-220d3bc8c96f',
        name: '耐克石家庄万象城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '526892'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4eb7d009-088d-46bd-97b9-77f4221ef154',
        name: '나이키 현대 충청',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '49546'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4ee104ba-51e2-4989-b015-c501ded113a0',
        name: '北京市北京丰台大悦春风里NIKE SPORTS L',
        region: 'GREATER_CHINA',
        storeNumber: '272145'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '4ee8dd75-b47b-44ea-afe0-b2dbb84bf4dc',
        name: 'NIKE长沙雷锋换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3630'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '4ef2562e-f98c-4345-87b8-4d56bd460630',
        name: '나이키 간석',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '82275'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '4f130152-9e9f-47c0-8561-b954e4924c9e',
        name: 'Nike Store Ashdod Big (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2711'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4f256e47-9431-406b-b7c1-0ccb3e8449a0',
        name: '耐克上海大中里商场KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '189392'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4f2e41fc-1041-4217-95ac-7655ea003fd2',
        name: 'POUSHENG浙江杭州城北万象城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288452'
      },
      {
        address: {
          country: 'IND'
        },
        id: '4f3f9e53-e777-482e-9979-0e6b0ef553c9',
        name: 'Nike Rajkot',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1690'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4f659a55-e669-4a57-9e24-c016f576b201',
        name: '黑龙江省哈尔滨市哈尔滨南岗区国际会展中心KL L2',
        region: 'GREATER_CHINA',
        storeNumber: '184708'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4f7192e4-4066-40a7-a95c-e3c4369002ae',
        name: '辽宁省营口市站前区市府路财富广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '267635'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '4f9e6d75-c8af-4a2c-a50f-1cd721586f30',
        name: 'NIKE广州海珠换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3410'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4fabb6aa-04a0-4978-94ba-5bb28dc14e76',
        name: '河北省秦皇岛市秦皇岛乐都汇BEACON L2 EXTENED',
        region: 'GREATER_CHINA',
        storeNumber: '260300'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4fb99b47-153b-4812-962d-b6ee378b026e',
        name: '浙江省宁波市锐力鄞州区万达广场BEACON750',
        region: 'GREATER_CHINA',
        storeNumber: '269017'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '4fcc6979-62f5-44e7-bf26-423d5ab0b84a',
        name: 'Nike Sherway Gardens',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD6'
      },
      {
        address: {
          country: 'SVK'
        },
        id: '4fde3a00-b14e-4bdc-856d-49a56d941323',
        name: 'Nike Well Collective - Eurovea City (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2430'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '4fe3b0b0-8880-4e8c-bda9-79221bb46f9b',
        name: 'Nike Trinoma',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7398'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '4ffb5b5e-2b93-4c15-b894-164c245cb4e1',
        name: 'NIKE KIDS上海南京东路宝大祥店',
        region: 'GREATER_CHINA',
        storeNumber: '273391'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '50042fd9-f641-40c3-bcad-27558d052539',
        name: '海南省海口市百丽海口秀英区长滨四路远大购物中心NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260390'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '50047d8f-0492-4458-a52f-1fd4681594bc',
        name: 'Converse Factory Store - Las Vegas South Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3702'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '50161647-3d1c-4aa8-bfdf-fc072273011a',
        name: 'NIKE青岛新兴换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3454'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5038ecdc-a6a7-468b-8a96-8d8fe8a54d97',
        name: '河北省秦皇岛市海港区世纪港湾BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '269014'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '5059101c-3eb2-453e-a488-2cabb0f49430',
        name: 'Nike Fairview Mall',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD8'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '50662a34-d212-48a1-8bd1-7eb5efec979b',
        name: 'Nike SM Davao',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8435'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '5090350a-9076-4bb4-9784-a4fadd279086',
        name: 'Nike Azteca',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '314342'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: '50aa20c6-b7eb-4d2e-bac3-eb5bba7525fd',
        name: 'Nike Factory Store Montabaur',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '913'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '50b9a38c-0cca-49b5-bd7d-15e7ce50ac6a',
        name: '江苏省盐城市宝原盐城金鹰BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '244481'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '50bea7cb-4568-4f2c-84b4-201999018155',
        name: '湖南省娄底市宝胜湖南东之杰娄底天虹百货5楼 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '267238'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '50c92519-20f4-40f6-bd8d-94b33012560b',
        name: 'Nike Store Gigli Firenze (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2499'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '50f3db28-46b5-4de7-8421-a72c2051b12c',
        name: '北京市大兴区大悦春风里KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269293'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '51010aa4-50ed-41f8-b60d-0fbb19eda355',
        name: 'Nike George Street',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4098'
      },
      {
        address: {
          country: 'HUN'
        },
        id: '510f51be-4dc6-41e2-841b-5882a0e5fac4',
        name: 'Nike Budapest (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2444'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5111db60-783e-4791-9b70-0f246e38fcb7',
        name: '广东省深圳市百丽深圳福田卓悦汇购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '244483'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '512ab28d-ea0d-4536-812e-4b47ebe0c7f8',
        name: 'Nike Gateway WA',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6225'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '51355bc9-5799-42c1-a983-fad24e4e861e',
        name: '河南省信阳市平桥区新七大道羊山万达广场Nike Sport M',
        region: 'GREATER_CHINA',
        storeNumber: '274203'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5136982b-9a54-4d87-a719-308b61acc2cf',
        name: '福建省漳州市百丽福建漳州市龙文区梧桥中路宝龙广场 1F  NIKE SPORT SMALL',
        region: 'GREATER_CHINA',
        storeNumber: '271337'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '8b2ae4b8-eb16-4d51-83bb-dba72ba34715',
        id: '5148250b-4bc1-4271-8837-5fe7b9f64c79',
        name: 'Nike Unite Villeneuve-Loubet Riviera',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '696'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '51485068-090b-422a-918b-f258fe75b916',
        name: 'JORDAN 9 GUANGHUA',
        region: 'GREATER_CHINA',
        storeNumber: '190347'
      },
      {
        address: {
          country: 'LTU'
        },
        id: '5149921a-b7ce-4991-86d0-c9e75c9c79c0',
        name: 'Nike Outlet Vilnius (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LIT002'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '51500520-9330-4409-ba01-77e40723bde0',
        name: '北京石景山喜隆多购物中心SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '519034'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5151e804-81c7-4013-9010-e9cf57dcba92',
        name: '나이키 AK 서현',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '70926'
      },
      {
        address: {
          country: 'DNK'
        },
        id: '515cb6a6-3503-496b-ac18-15f8e4f6794f',
        name: 'Nike Well Collective - Kobmagergade (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2442'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5164591b-7ab6-462b-a15d-cda3604226c4',
        name: '吉林省长春市南关区金宇大路钜城国际NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '531921'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '517b7b9c-6013-472e-bbd0-da45a34fc2d3',
        name: 'Nike Store Mosadak Str',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY049'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5184f3ae-f303-4a70-92a5-9b74a7869473',
        name: '四川省德阳市旌阳区长江西路钻石广场SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '136307'
      },
      {
        address: {
          country: 'EGY'
        },
        id: '5193be16-7281-4cd0-8654-651f84a199cd',
        name: 'Nike Store Mall of Arabia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY055'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5196b305-a953-4ee7-8af2-38e925cdd588',
        name: '北京市北京朝阳区颐堤港购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '16330'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '51b0ad05-6b55-47b9-b024-cf8ac415c6f3',
        name: 'NIKE武汉江城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3819'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '51b63612-4225-47ae-bcb6-6e8fd636da04',
        name: 'Nike Store Terracity (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2677'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '51df7c9b-72af-4d4c-bb60-8ad339ad6a98',
        name: 'Nike Factory Store - Santa Úrsula',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6317'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: '51e0b4d7-8ecc-4b93-8fe5-d6fa01b9cdca',
        name: 'Nike Unite - Waterford Lakes',
        region: 'NORTH_AMERICA',
        storeNumber: '280'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '51e691e9-e8d9-495e-837a-8d505c05d119',
        name: '黑龙江省哈尔滨市黑龙江省哈尔滨群力新区第五大道王府井购物中心Kicks lounge-L2',
        region: 'GREATER_CHINA',
        storeNumber: '195512'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '522336f3-d1df-4492-9c83-120b7df5861e',
        name: '나이키 현대 동대문',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '69299'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '52270041-6363-41f4-b875-b9e355d2455c',
        name: 'REALLY江苏如东青园北路文峰大世界KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '275334'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '522ea3ba-9b90-49d4-a703-51984a0c375b',
        name: 'Tianjin Binhai FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6018'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '523ea2b7-5faf-40b9-bffc-fc1970dd3ecc',
        name: 'TOPSPORTS吉林辽源财富大路欧亚NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288827'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '527cb9fb-b48d-4901-9df9-4ef7dccc4fcb',
        name: '江苏省无锡市锐力无锡中山路八佰伴KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270919'
      },
      {
        address: {
          country: 'ARG'
        },
        id: '528cce1f-652e-4660-915a-364e3a9d43a7',
        name: 'Nike Factory Store - Arcos',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6528'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '659456f0-a9b5-4c12-8bd8-649097373619',
        id: '52ae4059-849e-4866-a058-85b55e5a2cea',
        name: 'Nike Factory Store Poznan',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '939'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: '52ce2612-75c5-4157-bd45-a5d5e1818726',
        name: 'Nike Well Collective - Crocker Park',
        region: 'NORTH_AMERICA',
        storeNumber: '465'
      },
      {
        address: {
          country: 'BEL'
        },
        id: '52d591da-9caf-4c8c-8780-af18c9ea1942',
        name: 'Nike Store Antwerp (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2443'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: '52ec80e6-9473-4c21-b62f-183e98eb5da1',
        name: 'NIKE北京京良优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3713'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '53248ef6-1ab5-43dd-a1c7-eab7b0de7359',
        name: '江苏省泰州市锐力迎春东路中骏世界城SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272157'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5334c806-e902-4ed4-be50-39bb74f3d88a',
        name: '北京市昌平区龙德广场翠微beacon l2 350',
        region: 'GREATER_CHINA',
        storeNumber: '246009'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5361ec0b-6279-4c4a-9d9c-c5678831982b',
        name: '宿州埇桥区万达广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '262392'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '536b1168-11d9-4cb7-8ccd-afd5475ae6d7',
        name: '江苏省盐城市盐城盐都吾悦Nike Sport - S',
        region: 'GREATER_CHINA',
        storeNumber: '266702'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '53708fa6-fc6d-4a5f-9dfa-5107c961d143',
        name: 'Converse Factory Store - Tanger Outlet Westgate',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3773'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: '538ead22-d68b-42a3-9e55-a02d367655ba',
        name: 'NIKE北京朝阳园换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3402'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '53a13c83-4499-407e-b28f-7ade52b4cb77',
        name: 'Nike Pumas',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '479204'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: '53aa8db5-1c1f-4b5f-baaf-2d79485ba8fe',
        name: 'Nike Factory Store Perpignan',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '695'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '53b1d022-0511-40fb-905b-3a9fd6e62173',
        name: 'NIKE苏州工业园换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3494'
      },
      {
        address: {
          country: 'MKD'
        },
        id: '53d03cee-2932-4658-8274-1e8c627ae12c',
        name: 'Nike Store Skopje East Gate Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MAC102'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '53dbad6c-971d-427a-9a89-70b7268f6a9b',
        name: '江苏省连云港市苏宁广场 NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '481495'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '53f0545d-741c-4e09-bc75-f4647afe99dc',
        name: '滔搏福建省福州市台江区工业路233号苏宁广场B馆1F BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '274209'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '54440112-3887-4d4c-8931-b41ba04cac93',
        name: '江西省南昌市宝胜南昌洪城路十字街王府井购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241495'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5470ea93-011b-41ee-8941-6cb042329449',
        name: '青海西宁城北区宁张公路与纬二路交叉口万达广场NIKE SPORTS-S',
        region: 'GREATER_CHINA',
        storeNumber: '273335'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '548c4006-ec16-4265-aac8-47996e84f559',
        name: '江苏省南京市南京华彩天地BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240437'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '54c38d7a-d9ac-4268-b2a5-224aef4b52eb',
        name: '北京昌平同城街华联购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273330'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '54cde1cd-e50b-43da-afc6-99609b8cd4ed',
        name: '北京大兴区龙湖天街KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '243900'
      },
      {
        address: {
          country: 'URY'
        },
        id: '54d51ba4-818e-4706-bff9-b64ca4ad2fb2',
        name: 'Nike Factory Store - Tres Cruces',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '30001'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '54e6b7f3-59ff-4e4e-a7f8-db047715ea20',
        name: '天津市东丽万达NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271217'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '54ea62fd-12cf-442d-85a3-45908fc3a912',
        name: 'Nike Well Collective - Lexington',
        region: 'NORTH_AMERICA',
        storeNumber: '521'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5511840d-ec9b-49cd-ba8f-8350100262d9',
        name: '福建省福州市锐力福建福州台江区工业路宝龙广场KL L2',
        region: 'GREATER_CHINA',
        storeNumber: '526876'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
        id: '552ffe8c-ffe9-4ce7-a079-2f9dad15f151',
        name: 'Nike Factory Store Amsterdam Sugar City',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2053'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '55486608-b4be-48b5-9627-bd775df715f2',
        name: 'Nike Unite - Lehi',
        region: 'NORTH_AMERICA',
        storeNumber: '317'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '55b0d71b-8a13-4c80-97fb-6772cec2c791',
        name: 'NIKE武汉盘龙换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3653'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '55bfc0f5-4bc9-46a2-94f3-ac0c8b426109',
        name: '浙江省衢州市宝原衢州万达广场KL店',
        region: 'GREATER_CHINA',
        storeNumber: '240423'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '55d0d96d-2c5c-41bf-b401-8a55059d02c3',
        name: 'NIKE厦门天水换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4015'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '55d3c8d6-4c89-4f81-8164-26e57ae5e1ed',
        name: '江苏省南京市滔搏南京秦淮三山街万象天地Nike Rise',
        region: 'GREATER_CHINA',
        storeNumber: '274329'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '55ec87f6-4ddb-49e0-9296-3cb2ff547e57',
        name: 'Nike Polanco',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '300327'
      },
      {
        address: {
          country: 'AUT'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: '5648da41-4a1a-473f-893f-9a0401fe1f7b',
        name: 'Nike Factory Store Graz Murpark',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '984'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '567a2538-d96f-4cec-bfb0-d37a8236513b',
        name: 'Nike Macarthur Square',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8697'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '567c793c-a59f-4452-82c3-169a12f18aae',
        name: '海南省海口市盛世长运海口日月广场 BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '266941'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '567da1ee-ad0d-4f03-ad5f-cafa40ffea7f',
        name: 'POUSHENG贵州贵阳南明区惠隆路海豚广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '293945'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '5687e059-87a9-417a-a979-93e208cc7f19',
        name: 'Nike Malang Olympic Garden',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5060910'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5689e41b-3bcc-46f7-8f41-4f4c1bad51f9',
        name: '나이키 롯데 안산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '41551'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '56925e78-8667-446c-9ba6-408a5540b6e5',
        name: '内蒙古呼和浩特市回民区振华购物广场KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '269507'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '56b99a97-61a4-4e4a-af78-d156ae98c353',
        name: '新疆乌鲁木齐沙区七一酱园SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '260225'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '56c143e3-7c21-4851-8399-d87aab80a96b',
        name: '浙江省长兴市锐力长兴八佰伴BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '266732'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1c3af8dc-d58a-4495-909d-823d7d8f09e1',
        id: '56c99a1c-4bb0-4d8b-962d-1e28aacfd4a6',
        name: 'Nike Factory Store Palmanova',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '615'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '56e49caf-64d4-4428-bec4-fc02ae01a59f',
        name: '江苏省淮安市滔搏淮安楚州翔宇大道万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260531'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '56efc143-f239-4432-87c4-d28ca658f30c',
        name: '江苏省苏州市锐力工业园区久光百货BCN 550',
        region: 'GREATER_CHINA',
        storeNumber: '270918'
      },
      {
        address: {
          country: 'SGP'
        },
        id: '56fe1608-36a6-4e62-91b7-8af755467d3f',
        name: 'Nike Vivo Kicks Lounge',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4104'
      },
      {
        address: {
          country: 'SWE'
        },
        id: '57096eb6-f48c-4e5d-829a-1cfca00b8fd9',
        name: 'Nike Mall Of Scandinavia (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2492'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '570ae3d8-5e1d-42c9-b3b3-19e4b9a6c0e4',
        name: 'NIKE換季優惠店 台灣學城',
        region: 'GREATER_CHINA',
        storeNumber: '3391'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '5726AA9E-2307-4DAE-BC33-27B59522D77A',
        name: 'Nike Factory Store - Canutillo',
        region: 'NORTH_AMERICA',
        storeNumber: '162'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '5737A470-444A-4745-B3BD-88FEE70DA8EC',
        name: 'Nike Factory Store - Nova America',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6307'
      },
      {
        address: {
          country: 'POL'
        },
        id: '574a2283-afe4-409b-987e-0b58f05ea9de',
        name: 'Nike Store Poznan Stary Browar Atrium (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2322'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5772f88d-94c3-4b66-bd11-8b7e7f00bff6',
        name: '福州长乐路世欧广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '260227'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5776e9ef-96da-4565-9f98-2acf5ca61b0a',
        name: '나이키 전주 평화동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '48941'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '579529a9-729a-4adc-b07d-baba19db286b',
        name: 'SHUANGJIAN浙江杭州丰潭路银泰商业NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287285'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '57a85724-6636-4d8f-b99f-f96b3ef219f2',
        name: '나이키 영통',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '33177'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '57b990be-fb3d-4281-88ef-f7afcc7b9b1e',
        name: '河南省郑州市二七区民主路万象城NIKESPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '268793'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '57cc23e2-cecf-46e0-8445-4972a58e78ab',
        name: '陕西省西安市西安小寨开米KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '267217'
      },
      {
        address: {
          country: 'MYS'
        },
        id: '57ccfc2c-0970-4fc3-8780-6c5a195f2022',
        name: 'Nike 1 Utama',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1266'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '57f645a7-e755-4418-a06d-2e69f1a14304',
        name: 'NIKE济南美里换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3844'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5801a76b-38b1-42d3-b488-c88ebc024621',
        name: '山西省大同市城区永泰南路百盛购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '245636'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: '5808f9bd-e3ac-45a0-ab1c-3d7adb9239fb',
        name: 'NIKE郑州雁鸣优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3849'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '580b4f4f-2945-433f-b929-42e45307cd02',
        name: 'Haikou  Mission Hill Golf FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6017'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5818e52f-92b1-40f3-940a-24d92ce172ba',
        name: 'POUSHENG辽宁沈阳中街NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '293453'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '58244ddc-2676-44bf-98a3-2491f2cc2c70',
        name: '江苏省苏州市锐力苏州姑苏广济南路石路天虹KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '279071'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '583412e7-17f2-45b7-aa08-7275d2b39522',
        name: '北京朝阳区龙湖长楹天街BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '268832'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '58478402-e16c-4bfd-ba74-aff531059691',
        name: '广东省东莞市百丽东莞南城宏伟路时代广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '527986'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '584b68f4-5070-466c-b7a0-9a5d3243107a',
        name: '贵州省贵阳市金阳南路金阳国贸NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269456'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '58654565-4c84-4c76-9145-e6f7f1c659c8',
        name: '河北省定州市河北保定定州中山中路时代广场NIKE BEACON-L2 EXTED',
        region: 'GREATER_CHINA',
        storeNumber: '8077'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '58838865-73DF-41C6-8B91-363F019A393F',
        name: 'Nike Factory Store - Park City',
        region: 'NORTH_AMERICA',
        storeNumber: '61'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: '5896789d-65d7-4f90-a758-795174adf243',
        name: 'Nike Factory Store Livingston',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '623'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '58bd68e3-4acd-4e6f-ab3b-2496bc405d73',
        name: '浙江省台州市宝胜台州青悦城耐克 KL2',
        region: 'GREATER_CHINA',
        storeNumber: '531784'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '58ced6f1-9b6f-42d4-9fd3-53a62c53e20c',
        name: 'TOPSPORTS浙江杭州东新路银泰商业NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288062'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '30f321ed-e7ae-43af-8afd-eba31bcf811e',
        id: '58d0909a-3cca-4e27-ad47-524ab8bf0e22',
        name: 'Nike House Of Innovation Paris',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2501'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '58db7ff6-0640-446d-9042-79483217611e',
        name: 'TOPSPORTS山东济宁金宇路龙贵广场（原贵和）NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '275331'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '58ea5b06-a119-43d7-8101-23b36d6c1df2',
        name: 'POUSHENG辽宁沈阳太原街百盛KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '281959'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: '58ff0303-89da-44d1-a133-7f8678282ce1',
        name: 'Converse Factory Store - Allen Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3712'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '590258ef-6314-4b89-8e17-20d706aa4fef',
        name: '耐克合肥之心城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260203'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '59058e33-c279-4b12-b0fe-5e143f5249d4',
        name: 'NIKE贵阳明珠换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3931'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: '590B52C4-0CD6-4D99-B9F4-C6166BC79F68',
        name: 'Nike Factory Store - Foley',
        region: 'NORTH_AMERICA',
        storeNumber: '90'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '592a2aa9-dccb-40d0-a955-ffcf22ba34b4',
        name: 'NIKE台州中心换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4021'
      },
      {
        address: {
          country: 'IND'
        },
        id: '5935197a-900f-4213-81f0-81dbd0fce627',
        name: 'Nike Fun Republic',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5043169'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '593d2c7f-ab3c-44c4-90d9-57676750cdaf',
        name: '北京朝阳太阳宫中路凯德NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271040'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5954994a-b3e2-4e57-a5b5-39f7efbcf34d',
        name: 'POUSHENG天津南京路乐宾百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '481424'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '59727da9-aa3a-43af-84cb-d3de11b1f097',
        name: 'Denver',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3115'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '59824016-e2e8-495e-9deb-cfd1ea845420',
        name: '나이키 롯데 대구역',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78512'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: '59880abf-3a95-4579-a6ca-f573db84dda5',
        name: 'NIKE沈阳浦河换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3671'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: '599b49ca-9aa2-46b7-9503-80d5d6ef29bd',
        name: 'NIKE北京北苑换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3882'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: '59B8900F-C941-4649-9A24-04B91012DD12',
        name: 'Nike Factory Store - Terrell',
        region: 'NORTH_AMERICA',
        storeNumber: '181'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '59ae3470-c2a8-4bc7-a380-65286cb5a674',
        name: 'TOPSPORTS湖北武汉白沙洲大道与白沙二路交汇处万科广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287199'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '59c84c18-9b9f-477a-b01b-bd522b0a6432',
        name: '浙江省杭州市滔搏杭州临安白湖畈醉花路宝龙广场Beacon 350',
        region: 'GREATER_CHINA',
        storeNumber: '259644'
      },
      {
        address: {
          country: 'HUN'
        },
        districtId: '3c364fbe-617b-46b1-b867-1579b1e478f2',
        id: '59d06ca9-c9a5-419b-b19b-514b7585ff6c',
        name: 'Nike Clearance Store Polgar',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '644'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '59d081c0-9d85-46e4-92ac-e0877023dadb',
        name: '吉林省长春市吉林省长春高新北区北湖新城吾悦广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '269008'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '59ffa507-a2bc-4bae-bc00-07ff3966a452',
        name: '나이키 롯데 인천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85939'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: '5BF5AFB7-0F8A-44EC-8129-D696CB7D21C9',
        name: 'Nike Factory Store - Queenstown',
        region: 'NORTH_AMERICA',
        storeNumber: '33'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: '5CA88FFB-11E5-4A24-A240-EBA42A5FE5AE',
        name: 'Nike Factory Store - Mercedes',
        region: 'NORTH_AMERICA',
        storeNumber: '115'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '5D74B999-71E0-414E-926E-0C50F086E6DA',
        name: 'Nike Factory Store - Rosemont',
        region: 'NORTH_AMERICA',
        storeNumber: '208'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '5EF49B62-2E18-44B3-9D9A-CDCF6BA64736',
        name: 'Nike Factory Store - Sevierville',
        region: 'NORTH_AMERICA',
        storeNumber: '107'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9d9eff3f-247a-4ecb-92af-3ea47a3ef09f',
        id: '5a06ff05-d1e2-4ef5-b4e0-f38a261fe65d',
        name: 'Nike Factory Store - Edmonton',
        region: 'NORTH_AMERICA',
        storeNumber: '212223'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1cb2bafd-c74f-4e9b-afa0-deae26868954',
        id: '5a129714-89f4-4550-a4bc-f77052f91e96',
        name: 'Nike Factory Store Torino',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2041'
      },
      {
        address: {
          country: 'PRT'
        },
        id: '5a67f7bc-b02d-4c15-9d0d-1bc522337d1f',
        name: 'Nike Well Collective - Colombo',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2417'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5a6c7b41-ae10-483d-bcbd-7f13d739650c',
        name: '나이키 올림픽파크',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '28867'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5a7954d1-b0eb-470e-888c-954779815840',
        name: '나이키 스타필드 안성',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '92180'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: '5a89aafd-614c-4bbf-b5f6-26b43aa4b533',
        name: 'Nike Factory Store Piding',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '676'
      },
      {
        address: {
          country: 'IND'
        },
        id: '5a910cf7-4dda-4c59-a96c-6425f841ae83',
        name: 'NFS HSR Layout',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9472'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5a977e35-5f22-4635-a6ae-f935c65b2dca',
        name: '西安未央区大明宫万达BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '328470'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: '5aa17133-a7d3-44ea-b361-d8ef73d796e7',
        name: 'Nike Well Collective - Birmingham',
        region: 'NORTH_AMERICA',
        storeNumber: '473'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'aedd4b07-bd95-46bc-bedc-c1dafa8b491e',
        id: '5aa38a39-68f1-40e9-8d44-a60c4a6fa2df',
        name: 'Nike Unite Giverny',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2079'
      },
      {
        address: {
          country: 'THA'
        },
        districtId: '1e4c57d2-d7e5-4937-99ca-8c74e84c150c',
        id: '5ab75fb2-9804-40cb-9a2c-b4c1721573c0',
        name: 'Nike Unite - SPO',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6815'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5ac08db1-f1bb-4ecf-a523-346e25282d04',
        name: '河南省郑州市二七区人民路丹尼斯1号馆NIKESPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272589'
      },
      {
        address: {
          country: 'MYS'
        },
        id: '5ac4a27a-9fde-4494-8f61-8bc36409620c',
        name: 'Nike KLCC',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1267'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '5ad41fdf-ae4f-4e22-83a6-4fbceaf4b4cd',
        name: 'Nike Well Collective Sant Andreu (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2449'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5ad657ee-a07e-4600-88c6-e919c2cf0c53',
        name: '山东省青岛市市北区台东三路恒泰BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '268768'
      },
      {
        address: {
          country: 'ALB'
        },
        id: '5adfb1cf-cee6-4b4d-99ee-4269f9cf8dc7',
        name: 'Nike Store Tirana (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ALB100'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ece0a5e8-4fc9-4866-b8b1-89e5a5e0bd1e',
        id: '5ae13e18-42da-473f-9d9f-83879dd723c2',
        name: 'Nike Well Collective - South Windsor',
        region: 'NORTH_AMERICA',
        storeNumber: '474'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5b13c159-c4af-476f-9463-26aa89768cbd',
        name: '四川省自贡市汇川路万达广场 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271047'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5b1962fd-443b-44a6-864d-a99dc71ef2a4',
        name: '나이키 LF 스퀘어 광양',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72670'
      },
      {
        address: {
          country: 'LBN'
        },
        id: '5b49121e-592c-40b5-9038-8148f3c7d8f2',
        name: 'Nike Abc Dbayeh',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LEB068'
      },
      {
        address: {
          country: 'FIN'
        },
        id: '5b50c811-11dc-4f6e-8caa-831e1e47c3ea',
        name: 'Nike Well Collective - Sello (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2409'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '5b544b62-cb1f-4509-9134-7bd24aa8c9de',
        name: 'Nike Store Kyiv Blockbuster Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR052'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '5b5c65ba-ebd3-4564-a651-ec084cbf86ad',
        name: 'Nike Store Eir Yamim',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2768'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5b631bee-843a-4dd6-9f4f-85e5b876faa4',
        name: '나이키 현대 부산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '24471'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5b67fd37-e83a-4753-8b0f-9186740c544f',
        name: '天津市塘沽区金元宝东方NIKE SPORT-L店',
        region: 'GREATER_CHINA',
        storeNumber: '274024'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5b85b634-c307-4fd6-82bc-f7e9177ba424',
        name: '广东省佛山市宝胜鹏瑞利季华广场 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272411'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5bbfa2bb-e133-478b-be06-c56d50894531',
        name: '나이키 군산 수송동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '53967'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'fe144077-30cf-4be6-9316-3a64d50a3124',
        id: '5bce9242-b77c-45fa-b31c-28d202892d3e',
        name: 'Nike Factory Store Lyon Villefontaine',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2048'
      },
      {
        address: {
          country: 'NZL'
        },
        id: '5bef8d0c-e3fd-418f-a08d-6656dbb67540',
        name: 'Nike Newmarket',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6582'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '5bf2cc9b-c3f7-43fd-b661-37492fabda07',
        name: 'Nike Well Collective - Naperville',
        region: 'NORTH_AMERICA',
        storeNumber: '437'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '5c077494-a2e0-4e81-a78c-a83139a8f128',
        name: 'Nike Ginza',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3005'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '5c16fbce-fccd-435a-8ff6-d862f8e820db',
        name: 'Converse Factory Store - San Francisco Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3772'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '5c428eb6-3ddd-4604-b7fd-373a80dd61a9',
        name: 'Nike Store Kyiv Ocean Plaza (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR047'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5c6a6e96-9a27-454a-8c98-52647f11e1c4',
        name: '江苏省溧阳市滔博溧阳吾悦广场NK',
        region: 'GREATER_CHINA',
        storeNumber: '260080'
      },
      {
        address: {
          country: 'KAZ'
        },
        id: '5c6c03e8-12fe-4d57-b314-9ec0935fa59f',
        name: 'Nike Outlet Almaty Rajymbeka (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KAZ023'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5c7b6d7d-490b-402c-af44-28007611b0f5',
        name: '重庆市涪陵区兴华路泽胜中央广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '243041'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5ca82e54-3a1f-489f-8f07-ab9ea54892d2',
        name: '北京市昌平区龙德广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270834'
      },
      {
        address: {
          country: 'DEU'
        },
        id: '5cb061e9-a9a7-48cb-8046-eba642c3a5d7',
        name: 'Nike Store Hamburg (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2780'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: '5ccb9c81-f907-4901-81d1-2e7872d46e50',
        name: 'Nike Manchester',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2510'
      },
      {
        address: {
          country: 'IND'
        },
        id: '5cdaca25-3104-4155-81b5-38b7f64bdbc9',
        name: 'Nike DB Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1463'
      },
      {
        address: {
          country: 'POL'
        },
        id: '5ce507a7-0c43-4d0d-85b1-418369176be1',
        name: 'Nike Store Wroclaw Magnolia Park (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2709'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '5cee21d3-210c-413e-9323-4e511447ded8',
        name: 'Nike Yorkdale Mall',
        region: 'NORTH_AMERICA',
        storeNumber: 'CA7'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '5cf4d584-8350-4d5a-a3b7-a86cd94451be',
        name: 'NIKE昆明砂之船换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3864'
      },
      {
        address: {
          country: 'IND'
        },
        id: '5d152b0c-6ca4-4c26-b96b-bf11c3d44ce3',
        name: 'NFS Sec 14 Gurgaon',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090550'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5d3c66da-fa20-4e86-9d37-c3c922a027bc',
        name: '나이키 보령',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '52366'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5d4cf9e4-1348-4f04-bfb0-7d5ae8edb58f',
        name: 'TOPSPORTS广东东莞东城区东纵路东城万达广场苏宁易购1F层NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '287630'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5d54a5f0-1db1-49d4-93ed-005b65401a57',
        name: 'TOPSPORTS浙江长兴忻湖路与画溪大道交叉口万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288441'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: '5d7c17e4-cac7-46b0-b14d-6ae074def7c6',
        name: 'NIKE西安临潼换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3822'
      },
      {
        address: {
          country: 'QAT'
        },
        id: '5d7ddfb7-836c-4a6c-8792-d1092a0dfec1',
        name: 'MALL OF QATAR',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'QAT008'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5d7f2b6e-bd3e-48fe-b256-91521aa001c3',
        name: '山西省太原市太原市小店区亲贤北街茂业天地二期Kicks Lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '262414'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '5d8c17d4-0922-4901-8657-b7a7ecb2ffea',
        name: 'Jordan World Of Flight Milan',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2440'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5d9eef23-b5c5-4947-b91a-e07707f3155f',
        name: '新疆维吾尔自治区乌鲁木齐市万科广场NIKE SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '271018'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: '5da55d22-58e0-4ac1-a7f9-de6906818a66',
        name: 'Converse Factory Store - Vacaville Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3750'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '5dac72d0-f5a3-4338-bfd0-2515e9cd877f',
        name: 'NIKE成都双流换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3602'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5e0898ad-62a2-4ab2-a9af-49e2baa28ff3',
        name: 'TOPSPORTS湖南常德朗州路友阿国际NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288382'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '5e220331-4c7f-464b-bd45-b26e3cea044f',
        name: 'Nike Store (Sq1) Temp Store',
        region: 'NORTH_AMERICA',
        storeNumber: '105'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5e31d32d-3213-4a9f-bfb1-3576cb856f3f',
        name: '黑龙江省哈尔滨市道里区中央大街中央商城 KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '266964'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5e342cd9-488a-4f59-90df-8290747dbc33',
        name: '西安曲江创意谷NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '241213'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5e3de0c1-c3b1-4441-aa51-7813633d4566',
        name: '湖南省怀化市怀化佳惠华盛堂NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270685'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
        id: '5e6c1888-162d-4d68-8965-39ccf3920176',
        name: 'Nike Factory Store - Thornton',
        region: 'NORTH_AMERICA',
        storeNumber: '290'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '5eb012b8-cd6f-4c9e-b49d-d35d76ae7b7a',
        name: 'NIKE昆明中汇换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3447'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5eb01b9c-6b01-46be-a60b-2eb9f7eb9529',
        name: '江苏省宜兴市宜兴华地百货KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '526835'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5eb02c36-8872-4879-9743-a427af2c43ed',
        name: 'REALLY浙江桐乡庆丰中路浙北大厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281957'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '5ebe3d44-5bc0-4595-a75e-ca5b082261b6',
        name: 'Nike Harajuku',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3044'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '5ec43986-9bdf-4a54-9db5-eeac804de27e',
        name: '나이키 롯데 진주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71732'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5f3d9259-844e-448d-9a3c-7858bd5fd1fa',
        name: '辽宁省营口市辽宁营口市东升路大福源 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '267176'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1cb2bafd-c74f-4e9b-afa0-deae26868954',
        id: '5f489cc0-8fc3-430b-93ab-4b5f2b120a66',
        name: 'Nike Factory Store Brugnato',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '964'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '84065b43-8aa0-4415-b314-5ff958ddbecd',
        id: '5f560fa8-2f01-4ea3-b7ec-0c4ad5573514',
        name: 'NIKE LA JONQUERA FACTORY STORE',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '923'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '40880d74-de09-4fd5-81ce-fa42cca1d744',
        id: '5f5bcb1c-2517-47fc-9c41-cac8d16cd890',
        name: 'Nike Factory Store Braintree',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '525'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '5f5e9f07-c96d-47ea-8485-e327931d0173',
        name: 'NIKE杭州香岸优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4040'
      },
      {
        address: {
          country: 'MAC'
        },
        districtId: '4da1e8ed-2893-4ac4-a578-e03e4ac4c5aa',
        id: '5f66cdd8-fc5b-47c1-97ad-a048419829aa',
        name: 'NIKE澳門關閘換季優惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4901'
      },
      {
        address: {
          country: 'IND'
        },
        id: '5f70bf64-8e5f-4b70-968d-1df61a8f8ac4',
        name: 'Nike Vega Mall Siliguri',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6640'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5f73cae4-b8ae-4d11-ac73-b416b67aa709',
        name: '山西省太原市山西万科印力广场NIKE BEACON-L2 EXTED',
        region: 'GREATER_CHINA',
        storeNumber: '266809'
      },
      {
        address: {
          country: 'DEU'
        },
        id: '5f7a0a23-be26-4b84-8b61-2aa9ed156883',
        name: 'Converse Brand Outlet Store Neumunster',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8133'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5fa04961-41e4-4505-99b7-fe05bfecb0a3',
        name: '江苏省溧阳市百丽溧阳华地百货BBEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241501'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: '5fa65f57-6cb9-4187-a67a-eefef008bbef',
        name: 'Converse Factory Store - Silver Sands Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3796'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5fadef49-3d9d-417b-b49b-23c65e4f7134',
        name: 'SHUANGJIAN浙江杭州浙江省杭州市杭州市景昙路银泰商业NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '194134'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5fdd24de-7b3f-45b4-a1bb-1bcf10be5937',
        name: '四川省成都市高新区招商大魔方NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287268'
      },
      {
        address: {
          country: 'HUN'
        },
        districtId: '3c364fbe-617b-46b1-b867-1579b1e478f2',
        id: '5ffa3970-4ed7-49cc-a685-5da8ffbef0e3',
        name: 'Nike Factory Store Budapest Buy Way',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2045'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '5ffe7b42-d7e4-4801-ab51-9d3188c0be59',
        name: '重庆市江北区观音桥大融城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '243421'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '6006fcba-9c17-4059-887d-f6e7fd5b8f7a',
        name: 'Nike Store Mamila',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2718'
      },
      {
        address: {
          country: 'DZA'
        },
        id: '60181984-fbad-482d-bb1b-18c65380ba8a',
        name: 'Nike Store Garden City Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ALG033'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '60239a77-88cd-429d-83f1-0b2452c295e1',
        name: '浙江省舟山市浙江舟山市定海区海宇道新城凯虹广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269021'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6027f947-deee-4f3f-a9dd-0a0a3bed6d9c',
        name: 'POUSHENG山东乳山商业街振华购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282293'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '602af582-e6e8-428d-bd6f-f19437a0698f',
        name: '山西省晋城市白水东街万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274249'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '6036c8cb-257d-4620-9c6f-13c746237585',
        name: 'NIKE海口观澜湖换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3767'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '60415e70-7c43-4d2b-bc17-bcf700ee4605',
        name: 'Nike Factory Store - Sta Rosa',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9297'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: '60458061-402e-4ffd-9445-c5fa4f7a8dfd',
        name: 'NIKE OFF SITE - FOLSOM OFF SITE',
        region: 'NORTH_AMERICA',
        storeNumber: '9035'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '60532812-be13-4299-819f-5999c42ee8e4',
        name: 'NIKE南京桑园换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3799'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '0edcb3f7-a768-4ade-83d0-d0e5365d6dc5',
        id: '6056d24a-4cbf-4fbd-9993-a82893841259',
        name: 'Nike Factory Store Junction One',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '585'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '6059816e-f816-4f46-b88e-9dc6743b3122',
        name: 'Nike Store Belgrade Ada Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER107'
      },
      {
        address: {
          country: 'IND'
        },
        id: '6065e8b2-2e22-4ec6-81c3-7f23d060d77c',
        name: 'NFS Hyderabad',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090603'
      },
      {
        address: {
          country: 'ARE'
        },
        id: '6077caf2-7ac6-4a43-81ef-586be2a1473e',
        name: 'Nike Yas Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UAE055'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '608668e7-a4fb-4f9e-8bfe-dd74b2bc8815',
        name: '나이키 롯데 광명',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '61017'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '60E6FF0D-6C9E-46FD-96D5-DF029DEB2561',
        name: 'Nike Factory Store - Woodburn',
        region: 'NORTH_AMERICA',
        storeNumber: '129'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '60a52080-a50b-4898-8c01-4326828967de',
        name: 'NIKE重庆天鹿换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3933'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '60b69c1a-fab9-475e-a473-20a87a6083d4',
        name: 'Nike Store Bucharest Baneasa Shopping City (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM116'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '60bbd8a8-9a86-4db1-b40a-85f6399affc0',
        name: '浙江省温州市锐力温州公园路DSP',
        region: 'GREATER_CHINA',
        storeNumber: '140842'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '60c9ae1c-333f-463f-90fc-5f7c440c5325',
        name: 'Shanghai Printemps FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6003'
      },
      {
        address: {
          country: 'IND'
        },
        id: '60d7a750-e941-4e7f-a81d-676eb43dd05e',
        name: 'Nike MG Road',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4902'
      },
      {
        address: {
          country: 'IND'
        },
        id: '60e52310-cbd1-4243-b480-362dfef00b23',
        name: 'Nike City Centre Salt Lake',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8860'
      },
      {
        address: {
          country: 'GRC'
        },
        id: '60ef0508-b111-432c-89e6-70c9cd2745c9',
        name: 'NIKE STORE ERMOU 1 SYNTAGMA II',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2392'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '60fd18c3-431b-4750-8a0d-6965389a6eae',
        name: 'NIKE宜昌常刘换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3928'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '61073d94-e0e9-4b03-88eb-e5fdcf05a54b',
        name: 'NIKE北京品牌体验店',
        region: 'GREATER_CHINA',
        storeNumber: '271317'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: '610ebebf-05b4-4d8c-95f6-0f145d0fc0a8',
        name: 'NIKE合肥珠江换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4009'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6114b9cc-d6b7-4dcd-b1b3-f7e73634c0e4',
        name: '吉林省长春市双阳区双阳大街 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '190953'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '611d2f52-36c2-4f1b-b489-4fa243a1a116',
        name: 'REALLY江苏南京中山路大洋百货KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '481628'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '611f6a5b-09c4-447c-9d7b-07baff6e5696',
        name: '나이키 수원 망포',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '27403'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: '61254470-45d6-44d2-8c13-ee09aae63c6a',
        name: 'NIKE武汉高新优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3867'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '612a0c07-0d7c-4843-91b6-25e26bb84481',
        name: '江西省南昌市南昌武商广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274478'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '524440b5-a003-410b-9e4b-25c2de21868b',
        id: '61418a7f-cc23-4641-aab8-a0c65467e5f0',
        name: 'Nike Factory Store Florence',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '900'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '614ca4b7-5d0c-424c-9a1b-38adccb345ee',
        name: '耐克杭州湖滨银泰BEACON 1200店',
        region: 'GREATER_CHINA',
        storeNumber: '270422'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '61593b7a-45c4-4d86-947f-4cf15bf94ea8',
        name: '山东省莱西市上海中路莱西利群NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '274011'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '615ed309-b90b-4021-b0fe-1e83d0adc3e9',
        name: '广东省深圳市百丽广东省深圳市龙岗区龙翔大道7188号万科广场2F Beacon Extend 350',
        region: 'GREATER_CHINA',
        storeNumber: '271074'
      },
      {
        address: {
          country: 'AUT'
        },
        id: '61839dad-2b19-4ef9-a407-5de2871a284d',
        name: 'NIKE STORE GRAZ (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2377'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '61926d38-a7ca-475c-bed2-2b3be22d0c9f',
        name: '上海静安西藏北路大悦城店NK',
        region: 'GREATER_CHINA',
        storeNumber: '273116'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '61968f75-923e-4116-be86-7f18f2aaa52e',
        name: 'REALLY北京大兴瀛海环宇坊NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288199'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '61c36f95-4606-4ebb-9358-26fdc1eeb078',
        name: '天津和平区天河城BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '177704'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '61d1e990-8dd7-44ec-bd4f-361a3d430935',
        name: '나이키 모다 진주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95991'
      },
      {
        address: {
          country: 'BIH'
        },
        id: '61d8b685-1fcb-4206-8a9f-d9a62488b348',
        name: 'Nike Outlet Sarajevo (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BOS102'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6214650d-e9e6-446e-a3fe-cc052e2babfe',
        name: '北京市朝阳区大悦城YA-L2',
        region: 'GREATER_CHINA',
        storeNumber: '482343'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '621acdad-ce64-481c-a98e-e8752239bf8d',
        name: 'Nike Store Rome Via Del Corso (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '829'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6222b213-6c7a-482d-9724-52e79e5d9165',
        name: '辽宁省鞍山市铁东区二道街国泰 BEACON L2-750',
        region: 'GREATER_CHINA',
        storeNumber: '270922'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '6235d831-645b-480b-93e1-3758531d5b3e',
        name: 'Nike Richmond Center',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD4'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '62414dce-efe6-42c7-a5f5-180e411febe2',
        name: '唐山遵化文化路金缘购物NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '275339'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: '6241f65a-7854-4016-adb9-c80c4d6a5f46',
        name: 'Converse Factory Store - The Outlet Shoppes at El Paso',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3727'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6252ce85-277d-46f5-a3f0-5a2c4c038467',
        name: '广东省深圳市百丽广东省深圳市龙华新区壹方天地BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240451'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: '6263e53a-0420-44b1-906f-f7bbc4f048e1',
        name: 'Nike Factory Store Metzingen',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '912'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '627130aa-0a95-4b31-b301-11d82ce16bdd',
        name: '安徽省马鞍山市宝胜马鞍山市万达广场Kicks Lounge',
        region: 'GREATER_CHINA',
        storeNumber: '240842'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '628e9881-4373-40a9-86ef-f0f70e6bbd96',
        name: '山东省威海市环翠区万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270674'
      },
      {
        address: {
          country: 'MKD'
        },
        id: '62a2105c-fbd6-470c-a76d-6a599ced09cb',
        name: 'Nike Store Skopje City Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MAC100'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '62c1d2d3-ba1e-4230-b3a1-ab1567ce1155',
        name: 'Nike Store Bucharest Afi Cotroceni Park Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM110'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '62d39e86-35df-4344-8606-5b7cdbea9104',
        name: 'NIKE东莞龙平换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4029'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '62e6626b-8c4e-41f4-8e16-6112c31f8102',
        name: 'Nike Store PrimeMall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2774'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '631530d3-69a4-40b0-a59d-8dc6367a5552',
        name: 'Nike Outlet Bucharest Militari (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM031'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '63172487-403f-4bbc-b6f9-104797df1dce',
        name: 'Nike Store Cepa (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2683'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '63458c5f-d664-4e33-84ee-1d021c472fd3',
        name: 'Shanghai Florence FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6033'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '635b0fa4-c051-4fba-a81a-addff7ae419e',
        name: 'TOPSPORTS北京市石景山区京西大悦城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287539'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '63732a1b-09ba-4a7b-8d19-385df8bf5c36',
        name: '山西省晋城市黄华街圣亚服饰广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270966'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '63907fca-fb2d-48db-9e0c-f0547c996633',
        name: 'TOPSPORTS河南焦作民主南路万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '275346'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '639ca6b2-92d7-4889-af62-bf2f8763acae',
        name: '耐克深圳新安酒店体验店',
        region: 'GREATER_CHINA',
        storeNumber: '179304'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '63d98909-2da9-417c-b9fc-4d226b0771cb',
        name: 'Nike Factory Store - Mactan',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9298'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '63da9edd-dfe3-47cd-b189-cc4f7c25b019',
        name: '广东省广州市新市百信Beacon-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '270840'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '63ded683-477f-4c7c-b953-4fb1705e7f5d',
        name: '나이키 대전 제일',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21176'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '63e44e69-4ade-4e20-9be0-c39f222d2bd4',
        name: '浙江省长兴市锐力解放西路八佰伴KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '17249'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '63e5b663-0e06-4457-b36a-7a5f0770b38b',
        name: 'POUSHENG安徽省马鞍山市大华国际广场NK SPORTS M',
        region: 'GREATER_CHINA',
        storeNumber: '274454'
      },
      {
        address: {
          country: 'ARG'
        },
        id: '6415e63d-e7e4-463f-a410-c1db766cdfbd',
        name: 'Nike Factory Store - Soleil',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6525'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '642309a7-d650-4d8b-8eea-c9f36d704947',
        name: '浙江省乐清市滔搏温州乐清宴海西路正大广场NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '273017'
      },
      {
        address: {
          country: 'THA'
        },
        id: '644d0b80-9b5d-4da8-aea0-957393254d01',
        name: 'Nike Central Praza Ladproa',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2752'
      },
      {
        address: {
          country: 'IND'
        },
        id: '646e2ce8-56ee-4124-a3d7-24a89898f226',
        name: 'Nike Cyberhub',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5096000'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '6482f66d-b0d0-4f2e-98d0-ee58a0812590',
        name: 'AL HAMRA MALL (KSA)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA109'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '64854eec-0963-42e4-9856-c4c1ec0af8f2',
        name: 'Nike Store Kayseri Park (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2689'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '64a8518c-e768-410e-8773-c9c3faa270c5',
        name: '内蒙古呼和浩特新华大街摩尔城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '190887'
      },
      {
        address: {
          country: 'BEL'
        },
        districtId: '031af3aa-9b11-4b5d-b05f-80d63e997ea7',
        id: '64bf65c5-1da6-419c-9e0a-5c787cd60fbe',
        name: 'Nike Factory Store Maasmechelen',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '602'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '64efcfd8-b6ef-4b17-ae15-a6eaed3b34db',
        name: '宁夏回族自治区银川市金凤区万达广场BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '270808'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '64fa631c-dbf4-4948-9ac7-1c8febfef5fe',
        name: '上海浦东育路东前滩太古里NIKE-SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272131'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: '650beef3-870a-411e-b7d5-fb20b9146e86',
        name: 'NIKE深圳民塘换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3763'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '650de0fe-8ec5-4948-aa39-7f73b1fc7be1',
        name: '江苏省南京市宝胜南京仙林金鹰湖滨天地KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '244482'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '651a8dbd-fb8b-4384-a534-c715a4899322',
        name: '河北省石家庄市桥西区裕华路益友百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272096'
      },
      {
        address: {
          country: 'MYS'
        },
        id: '65222b0c-3b11-42ee-a99c-96304f1b5df3',
        name: 'Nike Southkey',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7240'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '65238dcb-d746-4186-b3bd-7db9597f877b',
        name: '나이키 NC 순천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '56601'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '652fbf57-0791-4333-8618-dbbe6f47df87',
        name: '江苏省江阴市滔搏江阴人民中路华地百货BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '4241'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '655d321f-3a61-46ad-bf39-27cb1dbb9645',
        name: '徐州鼓楼中山北路金鹰国际购物中心BEC',
        region: 'GREATER_CHINA',
        storeNumber: '487449'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '65863461-b83e-4627-a84c-363fc64e33e0',
        name: 'REALLY江苏南京夫子庙水游城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '281929'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: '031af3aa-9b11-4b5d-b05f-80d63e997ea7',
        id: '659250bf-5ebd-41c5-b8d0-70b20f58f558',
        name: 'Nike Unite Roosendaal',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '605'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '65946d3d-fdde-4916-9aaa-b5abd4dad9d0',
        name: '上海奉贤百齐路南郊购物中心Beacon 350',
        region: 'GREATER_CHINA',
        storeNumber: '241154'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '659935a7-61de-41c4-abfd-d85a50bcb3b3',
        name: 'NIKE宁波新星换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3780'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '659bfeb5-4649-499c-bb5d-9fcf6317862d',
        name: '四川省成都市新都区苏宁易购广场 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269342'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: '65D7599A-085C-4362-8D8B-CC9A6181BA9D',
        name: 'Nike Factory Store - Atlanta',
        region: 'NORTH_AMERICA',
        storeNumber: '343'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: '65F27793-0411-43CC-9277-7A70ACF2993C',
        name: 'Nike Factory Store - Gulfport',
        region: 'NORTH_AMERICA',
        storeNumber: '74'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '65aae1fb-f7e1-4d15-8b07-0136440bd997',
        name: '新疆维吾尔自治区库尔勒市新疆巴音郭楞蒙古自治州库尔勒人民东路天百购物中心NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '271191'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '65adaeff-9040-45a2-8f8c-bbd515d0748f',
        name: 'POUSHENG吉林松原欧亚KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '282203'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '65c67084-15f3-493c-82d8-d99257ca902d',
        name: '广东省广州市百丽广东省广州市番禺区福德路281号奥园广场1楼BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '270675'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '65e83fee-8910-4bc1-b1b5-4471bee4284f',
        name: 'Nike Well Collective - Levazim (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2330'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '65fd5c0a-564b-4e45-b275-4ddc7b8d8432',
        name: 'TOPSPORTS湖南长沙环湖路梅西湖金茂NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288383'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '66369bb7-40f2-4478-bf28-040713bd3053',
        name: 'Nike Othaim Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA071'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '2f1b4c39-e8ac-4cea-abd2-95d8b9b7aefe',
        id: '664a4680-f644-4451-9237-ea02bfa1a9cc',
        name: 'Nike Factory Store Berlin Wustermark',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2054'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '665b6845-c38a-4d6e-b408-3ec3e6eb418f',
        name: 'TOPSPORTS山西运城人民路新城吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274305'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '66643bcc-6ad6-4986-8173-1a588b3f2af8',
        name: '四川省成都市成都市锦江区春熙路红马商厦Beacon L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '268911'
      },
      {
        address: {
          country: 'IND'
        },
        id: '6667f9c0-d4a8-4712-b453-8477b5296fe3',
        name: 'Nike Sripuram Road Vizag',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5810'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '66de360c-fe81-41db-ba9a-acdef9c7b179',
        name: 'REALLY江苏如东青园北路文峰大世界NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '275335'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '66fa430e-86ba-4fd5-8df7-c82e5df31dba',
        name: 'Nike Factory Store - Catarina',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6335'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: '66fb317a-ad2e-4ead-9a6a-143b15476c49',
        name: 'Nike Unite Yokohama',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3009'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '67097618-247c-45d1-8058-299591f7d6db',
        name: '重庆市渝北区礼贤路龙湖礼嘉天街NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270757'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '670db6e4-250c-4da9-851a-1734dcfb4d13',
        name: '吉林省长春市亚泰新动力KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270726'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '671a2e46-5a0a-4f17-8667-0f35788e70c0',
        name: '江苏省江阴市滔搏江阴虹桥南路八佰伴购物中心NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '268809'
      },
      {
        address: {
          country: 'IND'
        },
        id: '672342d6-cca0-425e-b0d1-87b5643c3de6',
        name: 'NFS Mulund',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090046'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
        id: '67258785-b9e2-408d-aa49-64fdb8079b45',
        name: 'Nike Clearance Store - Aurora',
        region: 'NORTH_AMERICA',
        storeNumber: '230'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: '673a6fa8-9ca9-411e-b2bd-0476b56cae83',
        name: 'Nike Well Collective - Kansas City',
        region: 'NORTH_AMERICA',
        storeNumber: '515'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '673f54db-d2b9-4f09-9a42-5a661dd22fee',
        name: 'Nike Store Agora (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2334'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '677e00ef-dd08-4a72-8cdc-a8b683b5e483',
        name: 'Nike Well Collective - Brickell',
        region: 'NORTH_AMERICA',
        storeNumber: '444'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '678edb5b-8e0d-4816-831f-08dbf6b781a2',
        name: '黑龙江省哈尔滨市黑龙江省哈尔滨道里区上海路卓展购物中心二期BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '529606'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: '679025F7-524A-4583-ADDF-7B1AC546BA7B',
        name: 'Nike Factory Store - San Clemente',
        region: 'NORTH_AMERICA',
        storeNumber: '200'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '6791c715-46b0-4a50-a7bb-47f23349addd',
        name: 'Nike Factory Store - Novo Hamburgo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6320'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6798dcd2-710c-4d3c-a2ef-d014588dd921',
        name: '나이키 군산 영동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '29634'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '67e2d3f4-9161-43f6-9e6e-7b03a9941b35',
        name: '山东省青岛市李村利客来NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '136978'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '67fc4450-485f-4768-8d52-85d75a6a2b58',
        name: 'Nike Well Collective - Keystone',
        region: 'NORTH_AMERICA',
        storeNumber: '459'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: '6806cab3-6097-4ef6-ac9f-ea9fa0c6a3ab',
        name: 'Nike Well Collective - Southlake',
        region: 'NORTH_AMERICA',
        storeNumber: '461'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '68104bb9-a290-4983-be91-26960da4c5f8',
        name: 'Nike Manado Town Square 3',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4972'
      },
      {
        address: {
          country: 'ESP'
        },
        id: '681c5820-5cd5-4505-a7c7-ce43f7f61131',
        name: 'Converse Brand Outlet Store Mallorca',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8109'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '68283614-1bfc-44f6-a53f-147f45c8e5c9',
        name: '福建省福州市宝胜福州鼓楼区817路东百 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '481498'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6833f898-911c-4b77-ba82-d23948571c99',
        name: '江西南昌红谷滩会展路苏宁易购广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288184'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '684558cf-21ff-404e-a5b0-8f97882f8686',
        name: '江苏省无锡市无锡万象城KL L2',
        region: 'GREATER_CHINA',
        storeNumber: '531736'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '684b68e2-4905-46bc-92af-6fc05ac19c93',
        name: '江苏省南通市锐力南大街文峰BEACON 750',
        region: 'GREATER_CHINA',
        storeNumber: '271021'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
        id: '685d2e87-3b9f-4b62-ad88-c08e62cc14b9',
        name: 'Nike Factory Store Metz',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '977'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '68619758-8c27-4fed-b559-18c0f4ca37a3',
        name: '北京大兴宜家KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '189462'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '68954192-936e-430f-a8c8-42466dabdd14',
        name: '福建省厦门市福建省厦门集美区杏林湾路IOI棕榈城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271336'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '68c07018-3065-414c-837b-f14305f693f9',
        name: '上海市开元地中海商业广场新松江路Beacon-ex',
        region: 'GREATER_CHINA',
        storeNumber: '259067'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '68c19bdf-a64b-4e0a-a511-cd6a57edf69d',
        name: '广东省广州市盛世长运广州乐峰广场 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271220'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '68cdbef3-1b1c-47e4-909b-cd23c154bc92',
        name: 'Nike Canberra',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7260'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '68d2eae4-83f2-4afa-a364-f5be946a3991',
        name: '나이키 롯데 구리',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '46110'
      },
      {
        address: {
          country: 'ARE'
        },
        id: '6908b4b9-12cf-4700-bdd5-6a708e0434d9',
        name: 'Nike Mercato',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UAE035'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
        id: '6922023f-4a98-4b06-9486-d704697bf8e4',
        name: 'Nike & WOF Mexico City',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6652'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '693a7104-c044-4aec-82fa-4a10ba949727',
        name: 'Nike Sun Plaza',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5031516'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '6979612f-7fc3-4773-a71b-810543081b7e',
        name: 'Nike Store Kyiv Lesi Ukrainki (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR003'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '697f7fb9-6a5b-4cc5-9533-253bc990162c',
        name: '内蒙古包头市东河区维多利新天地BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241157'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '699d39a3-2abf-4a06-a07a-692345be8347',
        name: '浙江省永嘉市温州永嘉置诚广场SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '189237'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '69c67466-0ac5-483d-8450-4299ce9b57c0',
        name: '上海市静安大融城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526251'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '69d9bcce-ddeb-48cb-a8ba-23b1919c5d84',
        name: '耐克重庆三峡广场LAMOO体验店',
        region: 'GREATER_CHINA',
        storeNumber: '187077'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: '69dd49e9-c3c1-4e44-9164-8a1e7c2726b3',
        name: 'Converse Clearance Store - Kissimmee Clearance Center',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3774'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: '69eefa69-27e4-4e5c-996e-f164df7b9450',
        name: 'Nike Well Collective - La Costa',
        region: 'NORTH_AMERICA',
        storeNumber: '477'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6cbfa39f-c9e9-4743-9aed-471fda453fb5',
        id: '69f8b505-559a-4943-8a12-e67f75bc63c8',
        name: 'Nike Well Collective - Virginia Beach',
        region: 'NORTH_AMERICA',
        storeNumber: '442'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '69fa4669-ccaa-4011-86ca-a7d4364b954e',
        name: '浙江省平阳市锐力平阳鳌江车站大道平阳万达广场SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260920'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: '6A603E02-B08C-42B8-B693-78FDC9245470',
        name: 'Nike Factory Store - Rehoboth',
        region: 'NORTH_AMERICA',
        storeNumber: '5'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: '6A741626-F7F5-4891-BC6E-A7906B6F256A',
        name: 'Nike Factory Store - National Harbor',
        region: 'NORTH_AMERICA',
        storeNumber: '212'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: '6A8343D8-0164-4AD5-8A9F-17F20CB8B459',
        name: 'Nike Factory Store - Hanover',
        region: 'NORTH_AMERICA',
        storeNumber: '27'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: '6C8B2C50-C470-4389-AD8C-A58DBE8D04A2',
        name: 'Nike Factory Store - Katy Mills',
        region: 'NORTH_AMERICA',
        storeNumber: '8'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '6a121cc0-a7f7-4074-b417-15968ae547ad',
        name: 'Nike Ayala Cebu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5033930'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6a2a0163-3333-4acd-bb2c-97015aa55804',
        name: '나이키 여수',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '56602'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6a35f3ba-0c38-49e1-9bb4-1f8cc0488f89',
        name: '云南大理鹤庆路爱琴海购物公园NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274448'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6a427253-2ffc-4087-83ef-e1e40b85e3e7',
        name: '上海百丽世纪大道百联Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '146231'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: '6a777ed3-3e72-4632-bd1e-7837db009bc4',
        name: 'Nike Factory Store Dresden',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2044'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '6a7bf9a6-dedc-4359-af06-51048fc4ee3d',
        name: 'NIKE无锡惠山优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4067'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6aa37e41-88d5-4af2-81db-1ce94208f765',
        name: '나이키 메세나 합정',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '50755'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6ac9af09-5dc1-4aa0-a849-ac5e73ac2623',
        name: '福建省福州市百丽福建福州仓山区则徐大道白湖亭万达广场-1楼Nike Sport Medium',
        region: 'GREATER_CHINA',
        storeNumber: '273021'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6ae48ba6-b6f1-428c-a4c2-3ed79b745941',
        name: 'TOPSPORTS湖南长沙茶子山东路凯德壹中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288818'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '6b1b6093-e286-40d0-861a-d26599e19278',
        name: 'Nike Store Symbol (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2612'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '6b2bb56d-6a3a-4817-8d8c-a0702417060f',
        name: 'Nike Store Dizengof',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2723'
      },
      {
        address: {
          country: 'IND'
        },
        id: '6b2dfbc6-e05e-4c2b-b5ec-35ef6efcba6f',
        name: 'NFS Mysore',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090520'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6b47d8a1-9e43-44a5-a254-45afac82b287',
        name: '나이키 조치원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21883'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '9d2a3b18-08e9-46c4-93be-dc8d1bfc1f1a',
        id: '6b57a9f5-b3c0-4fcf-befd-3cd66431bcaf',
        name: 'Nike Factory Store Brescia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '559'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '6b6fe311-e6dc-4dad-9965-18e173e5ca81',
        name: 'Nike Store Timisoara Iulius Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM113'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '6b7996b2-5715-4b73-95f2-676cb44df8c8',
        name: 'Nike Reforma',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '329'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6baaea41-80ca-490c-b181-87d2d3142c5d',
        name: '나이키 신세계 대전',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95045'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6bb3ea52-b95e-4ea9-820b-c5de9ac61bf7',
        name: '나이키 포항 중앙',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21537'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6bc06507-6705-4cdc-9629-dda2d2bbd3a2',
        name: '福建省厦门市百丽福建省厦门市思明区思明南路中华城 1F KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '270962'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '6bf81197-bf19-4dc0-a278-f012254cca4a',
        name: 'Nike Pitt Street',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2287'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6c032183-ba34-479d-a16a-eaf380ebda00',
        name: '海南省海口市百丽海口市秀英区海榆中线与椰海大道万达广场1层NIK SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '269115'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '6c1a3f60-8bc3-4b36-8d3d-22426332d29e',
        name: 'NIKE莆田荔华换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3704'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6c33a70a-e25a-4180-8cbe-83b6c8391981',
        name: 'FAYA山西太原杏花岭区解放北路万达广场KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '274303'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6c543389-cbdb-458e-b78a-11e61492bf6d',
        name: '宁夏回族自治区银川市银川兴庆吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273478'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6c61bd4d-8210-4cb0-89a5-25051d6db04b',
        name: '合肥滨湖世纪金源NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '245381'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6c732ac9-9491-45ff-805a-40464e751ef8',
        name: '江苏省南京市南京六合欢乐港BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '482440'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6c7d7570-9bd4-47fa-8086-43f2ad6702a1',
        name: '陕西省西安市利和陕西西安未央区大融城JD-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271064'
      },
      {
        address: {
          country: 'NLD'
        },
        id: '6ca719a8-cdd4-4597-b23e-aa0711556c1c',
        name: 'NIKE STORE ROTTERDAM ZUIDPLEIN (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2370'
      },
      {
        address: {
          country: 'IND'
        },
        id: '6ca8f72c-4776-4ff7-bd36-11225f816dcf',
        name: 'Nike Forum Mall Hyderabad',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4367'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6cb82136-893d-485a-a220-8547d9f8bf27',
        name: '广西壮族自治区崇左市百丽崇左江州区东盟大道华润万象汇NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '269351'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6cdebbbf-c508-4c08-95f9-b952d19af9ca',
        name: '陕西省西安市西安市碑林区长安路西部五环TOTAL HOOPS-L1',
        region: 'GREATER_CHINA',
        storeNumber: '245635'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6ce46ea3-919d-45b3-9321-acf6b3760739',
        name: '内蒙古自治区呼和浩特市内蒙古呼和浩特玉泉区凯德MALL BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '269379'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: '6cf563f5-d38a-48d2-9c1c-a7e8decddb9a',
        name: 'Nike Well Collective - Ardmore',
        region: 'NORTH_AMERICA',
        storeNumber: '429'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: '6d02b125-fd39-4753-8470-94d9d640ca8f',
        name: 'Nike Unite Glasgow Bishopbriggs',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '821'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6d1f3545-1e58-4e12-a166-7afd2aa1d6f7',
        name: '山东省青岛市黄岛区融创茂 BEACON 750',
        region: 'GREATER_CHINA',
        storeNumber: '270785'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6d26c31b-21e9-44cd-a874-bff7f1c087d6',
        name: '辽宁省沈阳市沈河区中街 BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '190308'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6d2a7c6a-3a0f-4377-bfe2-9c02ac9d621f',
        name: '나이키 은행',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71307'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6d353dd3-66e0-4057-875c-7b6e3897bd50',
        name: '安徽省宿州市苏宁广场NS-L',
        region: 'GREATER_CHINA',
        storeNumber: '269535'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6d5a1b86-f1b8-45b1-8b99-dbefe6e30b61',
        name: 'Nike Well Collective - 부평',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '50587'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6d63ac43-6139-4adf-b464-98d61275d264',
        name: '陕西省西安市陕西西安市莲湖区星火路老城根NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '531663'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6da3bc6a-7ba7-4019-8c45-05ef188eca88',
        name: '湖南省长沙市雨花区步步高星城天地Beacon-L2-M',
        region: 'GREATER_CHINA',
        storeNumber: '273019'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6da93b25-361c-4777-bde8-58e1c937bde9',
        name: '上海市闵行区沪闵路三井啦啦宝都KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '272712'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6dac5424-fc5b-452c-8e9c-57381182d628',
        name: '浙江省永康市宝龙广场NS-S',
        region: 'GREATER_CHINA',
        storeNumber: '269354'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6dc2d57b-a0d4-4235-81d4-dde48a26245a',
        name: '贵州省贵阳市智诚YY潮流汇KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '267239'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6dd62a24-f06d-435d-9a98-1046ae2c298b',
        name: '广东省深圳市百丽深圳宝安建安一路99号海雅缤纷城BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '241476'
      },
      {
        address: {
          country: 'KWT'
        },
        id: '6df395c7-3fff-44a0-a9f9-cad7fc859be5',
        name: 'Nike Souq Sharq',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KUW106'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6dfc5561-4245-49a9-9fab-a8e5af04acc1',
        name: '上海市滔搏七宝万科广场KL1',
        region: 'GREATER_CHINA',
        storeNumber: '266955'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '6dff3341-07f1-4194-8dd8-fe8c6f432d60',
        name: 'NIKE上海莲花换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3854'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: '6e04591d-302c-495f-b396-23c9389b19de',
        name: 'Nike Store Menlyn (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2311'
      },
      {
        address: {
          country: 'ISR'
        },
        districtId: '80d72218-6d2b-46e2-bea0-2210cb625fbb',
        id: '6e0ce752-8df3-4b7e-99e4-5ac220dea8a2',
        name: 'Nike Factory Store Zichron',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '787'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '6e100f91-58e7-4c02-9c87-78be99b42a77',
        name: 'Nike Store Kiryon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2716'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6e1b9a56-95ac-44a3-a916-0fbcf6a47fbe',
        name: '四川省成都市成都市双流区星空路双流万达广场SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '189735'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6111a3bb-d8b0-452c-a7fc-92dbd88843d1',
        id: '6e245a14-05f2-474c-a6fd-5ab814bd6304',
        name: 'Nike Factory Store - Staten Island',
        region: 'NORTH_AMERICA',
        storeNumber: '258'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6e2519ea-2266-4b83-a7a9-6e12a883fe09',
        name: 'REALLY浙江宁波中山东路东门银泰NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '81600'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6e3ae928-c4f8-4b34-9b88-245adbf9b23d',
        name: '四川省成都市高新区远大购物广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270490'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6e61ce9a-8a50-4fc9-8694-610e9209802a',
        name: '河北省保定市竞秀区保百购物广场Jordan L2',
        region: 'GREATER_CHINA',
        storeNumber: '329519'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '6e877218-b389-486c-a812-874b292537d6',
        name: 'Nike Warringah Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9786'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '05f259e2-5c3f-43b8-aae2-8a59f239af36',
        id: '6e98c241-1c63-4885-8c92-b6e95006bb37',
        name: "Nike Central King's Cross",
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2026'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '6ebde0ae-1434-4ffe-ac29-daf6ff15ad7a',
        name: 'Nike Chermside',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4612'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6ecf484d-3c90-4265-aac0-a43330264321',
        name: '安徽省合肥市百丽合肥万象城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '136390'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6ef3c631-5144-4640-a86c-97a40d52ac46',
        name: '江苏省苏州市Really 苏州印象城BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '193951'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9d9eff3f-247a-4ecb-92af-3ea47a3ef09f',
        id: '6f4e1b08-1caa-4334-a31e-bc6a1575ce66',
        name: 'Nike Factory Store - Edmonton South',
        region: 'NORTH_AMERICA',
        storeNumber: '473708'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6f52863d-df7b-4563-a0ff-84c78288afd1',
        name: '北京王府井银泰NBA篮球体验店',
        region: 'GREATER_CHINA',
        storeNumber: '530496'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6f5ceb2d-d8fe-469a-b883-cf3fb6c9b7e7',
        name: '나이키 동성로',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '56816'
      },
      {
        address: {
          country: 'MAR'
        },
        id: '6f5ee7dc-c94a-4244-b9e7-a9cd4773db7b',
        name: 'Nike Store Anafee Blvd (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MOR002'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '6f6172c6-f482-4ce9-b662-2d46c3618e25',
        name: 'Nike Hyatt Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA063'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6f6982c1-490d-4ffe-a1d7-2f88e9040d5c',
        name: '广东省广州市宝胜广州萝岗区万达广场BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '194368'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '6f731784-e4da-484c-9ba9-1456bdd9d091',
        name: '나이키 광복2',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95954'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6f9e46d2-758e-472f-9a6e-c87aa43437b4',
        name: '江苏省宜兴市宝龙广场SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272968'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6fafed3a-2178-4a82-9739-2a21b4f23395',
        name: '江苏镇江京口区中山东路苏宁广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288427'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6fd0f8f8-1832-4237-98f3-80cd6f7ab44d',
        name: '广东省中山市百丽中山石岐莲员东路大信新都汇二期Beacon L2 Eextended',
        region: 'GREATER_CHINA',
        storeNumber: '329359'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: '6fe2cfd6-ac3e-4d40-87f6-5ae4623e6bae',
        name: 'NIKE石家庄青龙山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3865'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '6ff9d440-81bd-4bd6-95ae-b99ff4821a1c',
        name: '浙江省湖州市滔搏湖州吴兴太湖路吾悦广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271332'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '700349d6-f9b2-4c67-a500-a62665a7f5d3',
        name: '山东省济南市万虹广场 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '262440'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '7013e3c0-1d4e-4cbb-85f8-28ee38c7fd10',
        name: 'NIKE OFF SITE - LEHI OFF SITE',
        region: 'NORTH_AMERICA',
        storeNumber: '9317'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '701c77a0-d365-4c85-8125-4917bffb90ea',
        name: 'Nike Pioneer Place',
        region: 'NORTH_AMERICA',
        storeNumber: '707'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7028ef21-bdc9-4114-be1e-8d79a8e8d86a',
        name: '内蒙古呼和浩特市回民区振华购物广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '260307'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '70290be7-ae88-4331-846d-52b983fad51a',
        name: 'POUSHENG江西宜春宜阳东大道万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288478'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '70328af2-04a5-4ebc-891d-62e1e11fc58c',
        name: '나이키 당진',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '76466'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '70357540-0f8b-4a61-8c6e-84b4e986e0b0',
        name: '内蒙古自治区包头市包头青山万达广场JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '523573'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '703818c3-87f7-4049-87f5-5c4693b0bc9d',
        name: '安徽省六安市安徽百丽六安裕安六舒路吾悦广场Nike Sport Small',
        region: 'GREATER_CHINA',
        storeNumber: '269388'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '703ac5a0-a7c3-447d-af24-c021ce284f68',
        name: '河北省保定市竞秀区朝阳南大街万博广场KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '267235'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '704ef486-6c7b-4be5-884f-f8824ce7a195',
        name: 'Nike Rundle Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2695'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '70523652-83d7-465f-98ec-62e7c02c8ae8',
        name: '甘肃省兰州市兰州市城关区庆阳路华润万象城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287343'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7057c84c-c6be-4b01-9bcc-0eefb68eb2c0',
        name: 'SHUANGJIAN浙江杭州杭州市萧山区金城路927号万象汇NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274346'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '705ca00b-abff-4347-abaa-66ead2259827',
        name: 'POUSHENG浙江省绍兴市上虞华鸿万悦城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '274494'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '7078d1a3-0d2e-4964-8a88-f902df790a9c',
        name: '나이키 수원 천천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '40344'
      },
      {
        address: {
          country: 'HKG'
        },
        districtId: '4da1e8ed-2893-4ac4-a578-e03e4ac4c5aa',
        id: '708cfdcc-f32c-4d47-ba50-682a5e1c4d68',
        name: 'NIKE香港土瓜灣換季優惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3270'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '709c0198-3ce9-4448-9c5d-4205cfd76052',
        name: '上海市锐力上海高科东路唐镇阳光天地SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '269004'
      },
      {
        address: {
          country: 'BHR'
        },
        id: '70b66d0f-02a0-49df-805e-c7c36af1d101',
        name: "Nike Women's Bahrain City Centre",
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BAH003'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '70b93a0d-a62b-40c7-a16e-d49ee7f339aa',
        name: '吉林省长春市吉林省长春经济开发区欧亚万豪SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '101417'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '70bab3a8-5705-4faf-b6d3-617f9f0ef26c',
        name: '나이키 롯데 창원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78513'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '70cdd6dc-17f2-4ba7-a571-7ec99b15233d',
        name: '山东省高密市山东省潍坊市高密市夷安大道中骏世界城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287472'
      },
      {
        address: {
          country: 'IND'
        },
        id: '70f144bc-cf09-4389-ac4e-ebbaab9e4ab0',
        name: 'Nike Venus Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8126'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '71056503-d781-4f25-8b9d-8485c568c731',
        name: 'Nike Puerto Montt',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2688'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
        id: '713f8e1d-9b25-4689-97dc-8fd729a4feaf',
        name: 'Nike Clearance Store Utrecht',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '927'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: '714eb810-5b63-4489-80a7-03bbf52b912a',
        name: 'Nike Factory Store Corbeil',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '624'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '7166a280-75dd-4cc1-9c2f-cae7fc7df27c',
        name: 'Converse Factory Store - Ontario Mills',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3720'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '71854fc4-2f69-43c3-8e42-f40e9cccde88',
        name: 'Nike Joondalup',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8346'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '7189f6ec-a203-4e0d-94b6-7810e5309eca',
        name: '나이키 신세계 천안아산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '93086'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '71a1a41a-1d34-4f10-9db4-b62e3b3912b8',
        name: 'TOPSPORTS海南海口迎宾大道与椰海大道新城吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287286'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '71db2d85-fa35-4b8c-9000-1ecc49822cfd',
        name: '山东省济宁市高新区爱琴海购物公园NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271318'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '71fdf296-d634-458d-b41f-787424730c97',
        name: 'NIKE上海广中换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3848'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9d9eff3f-247a-4ecb-92af-3ea47a3ef09f',
        id: '7200f441-587d-4b33-9157-5713a3d20c5f',
        name: 'Nike Factory Store - Crossiron Mills',
        region: 'NORTH_AMERICA',
        storeNumber: '423621'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '721aa873-e298-45f4-9955-f99b41bcbe02',
        name: '甘肃省兰州市兰州西津西路兰州中心Beacon-L2-M 750',
        region: 'GREATER_CHINA',
        storeNumber: '531574'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7237c9ae-d89d-43a3-b2ef-f21647429ba1',
        name: 'HIGHWAVE SPORTS四川成都锦江区中纱帽街太古里LIVE LIFESTYLE',
        region: 'GREATER_CHINA',
        storeNumber: '274377'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '726c3cd0-5d1f-4ff8-83fc-7c02efa1e5e4',
        name: 'NIKE九江庐山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3752'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '727f7d3a-ed33-44b0-a2b9-1bed64cd7024',
        name: '北京市密云华润购物中心BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '195713'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '728289c8-5ba8-4d74-838a-479923857dfe',
        name: 'Converse Brand Outlet Store Roppenheim',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8106'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '72c84023-dd44-413a-97bf-7a4582a5a243',
        name: '广东广州南村镇兴南大道万达广场KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '292844'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '72d54898-6b34-4cdf-8a60-694ac2917ece',
        name: '浙江省杭州市滔搏杭州市延安路西湖银泰城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '524596'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: '731722e1-00e4-4fb6-80a1-e0fe1790638b',
        name: 'Nike Factory Store - Niagara on the Lake',
        region: 'NORTH_AMERICA',
        storeNumber: '387830'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '731af3d4-bada-4e9f-b057-e683da4fc0f5',
        name: '泉州浦西万达',
        region: 'GREATER_CHINA',
        storeNumber: '288162'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7322df3d-e428-45c9-9761-9c430a5e7ff9',
        name: 'POUSHENG辽宁沈阳太原街百盛NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281937'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '732f4998-e445-4cba-8139-5be8474e9c21',
        name: '上海青浦万达茂广场BBEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '242319'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '733d98a4-2736-431f-a112-da76b1d3bfc7',
        name: '耐克成都太古里跑步体验店',
        region: 'GREATER_CHINA',
        storeNumber: '157831'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '73449f92-614d-458a-a8bb-bc41024694bb',
        name: '江苏省宝应市锐力吾悦广场 KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '273645'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '73496c8b-ec00-4119-bfe2-5ae68f415846',
        name: 'TOPSPORTS广东深圳宝安区沙井京基百纳广场4F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288871'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '735669d0-9404-4f19-b482-fb5afeee4fbb',
        name: 'TOPSPORTS山东德州德城区澳德乐NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '279082'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '738128a9-42d0-4f7b-941c-ecc044fb6882',
        name: '北京昌平区合生汇KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '293762'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1c3af8dc-d58a-4495-909d-823d7d8f09e1',
        id: '739ea557-c8cf-4363-bd73-5b0359f1137b',
        name: 'Nike Factory Store Fidenza',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2030'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: '73BEF534-B480-4889-A12E-A6F4A5A2526E',
        name: 'Nike Factory Store - Pearl',
        region: 'NORTH_AMERICA',
        storeNumber: '214'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '73aa0e2c-4593-4465-94b1-97711ac7b11d',
        name: '河北省唐山市唐山远洋城购物中心Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '193867'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '73c5bcd8-8fd1-4d9b-a961-ff47262fcac7',
        name: 'Nike Factory Store - Vila Velha',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6328'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '73d9eb8a-5c43-42f2-983b-b3febf301a8c',
        name: '나이키 춘천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '43124'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '73dd639a-7858-4d77-802f-76ffd5ca65d2',
        name: 'POUSHENG重庆沙坪坝重百NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '4471'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '73fc2ee1-c208-4c5a-aee9-6ce99d699cc8',
        name: 'TOPSPORTS佛山南国东路大融城1F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273143'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7431ebb5-4991-439d-bd39-62aff1648eae',
        name: '江苏省泰州市锐力金鹰购物中心 KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '273129'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '744030cf-a427-4aa6-9d34-1c7af4d7fd4b',
        name: '山东省淄博市张店区银座购物商城Beacon-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '241499'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '74530ce9-baa3-428e-a889-4aa168ca8dd5',
        name: 'REALLY湖北鄂州南浦路银泰百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '7903'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: '74532bc7-ea33-4436-a3d0-03a2dbd7a743',
        name: 'Nike Well Collective - La Cantera',
        region: 'NORTH_AMERICA',
        storeNumber: '571'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'c39640bc-c790-42de-8810-e227062b5fb5',
        id: '74552868-36b1-4d38-a8c0-e3eadea1433a',
        name: 'Nike Nagoya Sakae',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3053'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '74816CB7-4490-4178-BF8D-86D7458ABFBE',
        name: 'Nike Factory Store - Southaven',
        region: 'NORTH_AMERICA',
        storeNumber: '245'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: '750270EF-73CC-4C28-A0D7-2B1C9F6066CC',
        name: 'Nike Factory Store - Petaluma',
        region: 'NORTH_AMERICA',
        storeNumber: '147'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: '7504d07c-b8b9-44be-8fd7-ed1f74401716',
        name: 'Nike King of Prussia',
        region: 'NORTH_AMERICA',
        storeNumber: '635'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: '75071a4b-bde9-488a-97c0-6ada1b9383d7',
        name: 'Nike Factory Store - Halton Hills',
        region: 'NORTH_AMERICA',
        storeNumber: '375347'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '75291912-3c52-4a80-8dda-6fb1219c4bcd',
        name: '浙江省绍兴市上虞万达广场NIKE-S',
        region: 'GREATER_CHINA',
        storeNumber: '259316'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7530f227-6dfc-4cbb-b3a0-c95656cd1773',
        name: 'Jordan World Of Flight Beijing',
        region: 'GREATER_CHINA',
        storeNumber: '4070'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '754b106e-2311-4fad-b264-52e32442a2ba',
        name: '河南省洛阳市河南省洛阳西工区中州路丹尼斯NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274537'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '755cbbd3-cbbc-4e82-bc58-4eaa006808b5',
        name: '四川德阳万达NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '273119'
      },
      {
        address: {
          country: 'BEL'
        },
        districtId: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
        id: '7592aea3-9d9d-40ec-85b1-6fa161648057',
        name: 'Nike Factory Store Messancy',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2005'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: '7595bee9-64fc-433f-92c9-73fa7eed3177',
        name: 'Nike Factory Store - Napa',
        region: 'NORTH_AMERICA',
        storeNumber: '262'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '75af9211-32ac-4a5c-917b-0543720fb2ef',
        name: 'NIKE重庆东城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3872'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '75e1d638-10cc-43f4-ae0d-5d860695b71e',
        name: 'Nike Tunjungan',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5978'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '75f709d0-e326-4040-afe9-b10c5c584d8e',
        name: '辽宁省沈阳市沈阳市和平区青年大街K11 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '522791'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '7600d9d9-f35b-4458-8c47-ad75e4f9bb94',
        name: 'NIKE太仓上海路换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3701'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '76047465-49a2-4240-b566-9d9dae956f03',
        name: 'Ningbo Shanshan FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6035'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '5ef7b608-d72a-4ef4-8ae6-1ccf6b7e3ea3',
        id: '7610BB14-681B-4FDE-A148-E6B39001FECD',
        name: 'Nike Factory Store - Pottstown',
        region: 'NORTH_AMERICA',
        storeNumber: '168'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '761586a7-5fd7-4439-a215-fe574d2da84e',
        name: 'SCARBOROUGH TOWN CENTRE',
        region: 'NORTH_AMERICA',
        storeNumber: '484400'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '762ebce5-9c95-4160-81b6-087e33cb3698',
        name: '黑龙江省大庆市萨尔图区百货大楼BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240841'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '762ee80f-2f3e-409d-a04c-6cadfdf29dbc',
        name: 'SHAANXI LIHE陕西西安太华北路万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288196'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
        id: '7670b115-8268-426e-8b4c-bb1a8aed3c3d',
        name: 'Unite Guadalajara',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6641'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '769ad50c-706c-40d2-b272-83a7761f45ce',
        name: '四川省眉山市眉山雕像广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '266992'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '76ace006-5557-4959-aa17-ad067d3c6a2c',
        name: '浙江省金华市金华万达广场',
        region: 'GREATER_CHINA',
        storeNumber: '240421'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '76b41090-e5a6-4185-bf0a-26d31601f82d',
        name: 'TOPSPORTS湖南长沙湘府中路德思勤城市广场一期NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288372'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '76bdcc84-80bb-49b5-9b45-843995914b92',
        name: '广东省汕头市宝胜汕头潮阳中华路Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '14157'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '77170c53-b255-4764-8444-88dbcd908cea',
        name: '나이키 엔터식스 왕십리',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '79668'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: '771EB400-975B-4D39-AADF-4508E281CCB3',
        name: 'Nike Factory Store - Locust Grove',
        region: 'NORTH_AMERICA',
        storeNumber: '142'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '77254381-7ac7-412f-8c8e-3e9f9d832aaa',
        name: '安徽省蚌埠市宝胜蚌埠市时代广场NK专550',
        region: 'GREATER_CHINA',
        storeNumber: '244486'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '774c514f-cd80-4bfa-b3ab-e7ceedce6b90',
        name: 'Nike Parramatta',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5070588'
      },
      {
        address: {
          country: 'IND'
        },
        id: '7755b8f6-2e1c-4aa9-a502-cb76c62e486a',
        name: 'Nike Seawood',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6086'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7756a57c-0dc8-4232-be29-3be3da761331',
        name: 'TOPSPORTS河南郑州二七路与太康路大卫城丹尼斯百货NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '292350'
      },
      {
        address: {
          country: 'PRT'
        },
        districtId: 'd018da20-ba06-4a8b-82d2-eaa2a5670c5d',
        id: '775fc910-defc-41f3-869a-db8f66f966bb',
        name: 'Nike Factory Store Porto 2',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '924'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: '7768c4ce-f846-4157-a0d0-dce1edf7d0bc',
        name: 'Nike Factory Store Viaport Kartal',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '985'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '77712672-e464-419a-9489-1bee4e7ea325',
        name: '耐克北京王府井中环购物中心KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '482359'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: '7772a027-8f85-41d9-b378-b1c06b978fec',
        name: 'NIKE大庆世纪换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3664'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '778ba47d-98e2-4081-8885-215b8aecc9c5',
        name: 'POUSHENG江苏镇江宝龙广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282162'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '77981ae0-ef32-4970-87db-294af93976cd',
        name: 'SM Cebu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5097845'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: '7799401a-d883-47cc-89b4-2db3d4377f66',
        name: 'Nike Factory Store Manchester Fort',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '819'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
        id: '779f155e-de16-43d0-99c7-5a79a5236820',
        name: 'Nike Bellevue Square',
        region: 'NORTH_AMERICA',
        storeNumber: '536'
      },
      {
        address: {
          country: 'BIH'
        },
        id: '77a27924-71f0-468e-933e-b7b41522fbf2',
        name: 'Nike Store Mostar Mepas Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BOS103'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '77aa94fb-61cb-4602-a349-60f4ee23c5ef',
        name: 'Nike Store Historia (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2620'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '77b753b0-fd13-4d5e-a10f-d4f79f9b32c3',
        name: '北京市北京海淀金源燕莎购物中心YA-L2',
        region: 'GREATER_CHINA',
        storeNumber: '7923'
      },
      {
        address: {
          country: 'IRL'
        },
        districtId: '0edcb3f7-a768-4ade-83d0-d0e5365d6dc5',
        id: '77d8194c-366a-41cd-8adb-7ff932642a0a',
        name: 'Nike Unite Blanchardstown',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2080'
      },
      {
        address: {
          country: 'THA'
        },
        districtId: '1e4c57d2-d7e5-4937-99ca-8c74e84c150c',
        id: '77e2559c-2bd6-4540-8849-f83e787b945c',
        name: 'Nike Factory Store Chiang Mai Plaza',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6812'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7803090f-8d9a-4979-afd9-8ce9224f74b0',
        name: '黑龙江省牡丹江市牡丹江时尚运动城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '246011'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7803da9f-3611-4980-b95c-0c1ca044b83f',
        name: 'POUSHENG山西太原学府街华宇百花谷NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '293664'
      },
      {
        address: {
          country: 'POL'
        },
        id: '780990b7-6787-4c73-bd1c-3b0f921a7ec3',
        name: 'Nike Store Katowice Silesia City Center (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2707'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '780eba1e-25c7-4a6c-9fea-86edbe973a01',
        name: 'Nike Store OLAYA STREET',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA056'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7812518c-6e00-456d-8cb9-aba7d001877e',
        name: '广东省佛山市盛世长运佛山王府井购物中心 KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '271104'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7829729f-9d43-4af1-b987-6babec81537f',
        name: 'POUSHENG湖南长沙河西金星中路步步高NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274357'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '783ec6c3-0954-47b0-84a5-c0ae56ccacfe',
        name: 'Nike Store Belgrade Galerija (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER104'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: '7851c61a-6656-451e-8669-5ad67ac605db',
        name: 'Nike Well Collective - South Congress',
        region: 'NORTH_AMERICA',
        storeNumber: '427'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '787573e4-1a05-435c-95e1-be3d57129d67',
        name: 'Nike Factory Store - Oak Brook',
        region: 'NORTH_AMERICA',
        storeNumber: '99'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd4ecdc02-7ca6-40d5-ae0a-f7aa28736667',
        id: '78780862-0703-4c8a-9863-d2bf10ca3aae',
        name: 'Nike Community Store - Elmwood',
        region: 'NORTH_AMERICA',
        storeNumber: '293'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '78821402-4452-42a3-b851-e66435bc4cb3',
        name: '江苏省盐城市百丽盐城市宝龙广场NK店',
        region: 'GREATER_CHINA',
        storeNumber: '15902'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '78835db8-3f9e-41bc-91d6-2c30d5557686',
        name: '广东省汕头市百丽汕头市金平潮汕路61号万达广场1F层SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '272775'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
        id: '78FDB0CC-06C3-49C9-A6A0-12D5FE68839B',
        name: 'Nike Factory Store - Aragon',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6631'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '78b9e9de-e40d-4090-8d06-c03960889ab6',
        name: '耐克北京国贸体验店',
        region: 'GREATER_CHINA',
        storeNumber: '186289'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '78bad8cb-0cc2-4f65-bd1a-d0c9ac626296',
        name: '나이키 롯데 관악',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72488'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '78f0361a-22de-418b-84c0-d9b3fc323bee',
        name: '河北省衡水市桃城区怡然城购物中心 Nike Sport-L',
        region: 'GREATER_CHINA',
        storeNumber: '270847'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: '78fb5a2f-0ebc-4317-9ed4-392e66ecac96',
        name: 'Nike Unite Nagashima',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3030'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '78fea259-5eda-4f76-afa2-d3599b34f504',
        name: 'POUSHENG安徽巢湖人民路合百KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '292578'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7906f116-8d80-435a-b092-f7c5dd63f835',
        name: '湖北省孝感市孝南区乾坤大道吾悦广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271196'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '790cbd8b-8719-4550-a099-05d2323aa8c7',
        name: '山西省晋城市城区新市西街兰花城Nike Sport-M',
        region: 'GREATER_CHINA',
        storeNumber: '269134'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7919c8c9-9be3-485d-ad79-557fdbf84f7e',
        name: '辽宁省阜新市海州区解放街胜道运动城 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240485'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '791fdc04-c879-428d-a247-5954e29d49d9',
        name: 'EATON CENTER MALL',
        region: 'NORTH_AMERICA',
        storeNumber: '468981'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
        id: '7922a25f-596f-40b8-b412-4b9db97c6e61',
        name: 'Nike Factory Store Talange',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '555'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '793555a5-59df-4c69-b4ec-7a3fe572ba13',
        name: '山东省济南市槐荫区嘉华购物广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '269303'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '793e2ebe-08b4-4ea5-9667-4fc518de0362',
        name: '四川省成都市锦江区春熙路群光广场KICKS LOUNGEKICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271102'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7944d820-9ce1-43fa-b814-3ed86f49858c',
        name: '福建省福州市宝胜福州八一七路织缎巷BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '25660'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '795ef43e-6956-4a8a-8a91-db4965f73326',
        name: 'Nike Well Collective - Louisville',
        region: 'NORTH_AMERICA',
        storeNumber: '570'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '797422d2-b060-42fd-aff6-3141f4205b0c',
        name: '上海市上海宝胜瑞安虹桥新天地BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '147014'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '797af046-dac7-4440-bbd1-61763ea9f0e7',
        name: 'POUSHENG陕西西安雁塔区MOMO PARKKICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '273689'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '79a0f2da-86ee-461c-af6e-473c1775d344',
        name: 'Nike Summarecon Mal Bekasi',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2853'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '79b026d1-7b7f-45d2-bbbf-15e68d98cdc7',
        name: '山西省太原市山西太原柳巷茂业BEACON L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '267195'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '79c2ae2f-6884-4aec-90b6-ea35b1c1331e',
        name: 'Nike Store Pristina Albi Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'RKS100'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '7A93FA85-A4E1-41CE-B150-BAB1D0970305',
        name: 'Nike Factory Store - Birch Run',
        region: 'NORTH_AMERICA',
        storeNumber: '34'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2660ed67-8dd6-47cf-8336-ded5e73522f1',
        id: '7B7EFDD9-3966-4B3C-BE32-05CC0D3746EE',
        name: 'Nike Factory Store - Lakewood',
        region: 'NORTH_AMERICA',
        storeNumber: '7'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '7B988866-4C8C-4062-AF86-E26C14A4AE96',
        name: 'Nike Factory Store - Howell',
        region: 'NORTH_AMERICA',
        storeNumber: '32'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
        id: '7C0B96F8-6D7B-4039-A5E8-F561121A4299',
        name: 'Nike Factory Store - Tulalip',
        region: 'NORTH_AMERICA',
        storeNumber: '125'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: '7C9C50BA-0AF1-403F-B880-76AB6BE4A8C8',
        name: 'Nike Factory Store - Somerville',
        region: 'NORTH_AMERICA',
        storeNumber: '194'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b6d3e9e2-a3f1-4980-a31d-622ffbfc6a5a',
        id: '7CDBDE7B-5DCA-422A-AA99-413595111D03',
        name: 'Nike Factory Store - Round Rock',
        region: 'NORTH_AMERICA',
        storeNumber: '139'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9971b364-907d-44a5-9e91-2b790a5b3268',
        id: '7D058D82-C3EC-4BAA-9464-1922E94FB340',
        name: 'Nike Factory Store - Bluffton',
        region: 'NORTH_AMERICA',
        storeNumber: '92'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'edea497c-6d71-4706-a2aa-7dbe47d60b84',
        id: '7E6B09C3-9D0A-4452-A9B9-387FC8D00FF8',
        name: 'Nike Factory Store - Cypress',
        region: 'NORTH_AMERICA',
        storeNumber: '169'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '7a29d1c9-94dc-42d5-9537-5c351fa71e21',
        name: '나이키 평택 중앙',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87099'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '7a58e500-838a-407c-96a5-e38d400c4a0e',
        name: 'NIKE赣州和谐换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3930'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '7a67651b-22f8-4c31-af52-54115fd63681',
        name: '나이키 롯데월드몰',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '56817'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '7a96f7ad-8808-4434-baa8-8b39636af8c5',
        name: 'Converse Factory Store - Fashion Outlets of Chicago',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3780'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7ab9cb0c-6452-47d1-970f-bc157b6ca9f0',
        name: '青岛李沧京口路NK NSW',
        region: 'GREATER_CHINA',
        storeNumber: '189615'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7abad755-7aff-4c98-97f5-ec7f3aa46e35',
        name: 'REALLY浙江温州龙湾区上江路吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '270505'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7abe7078-384d-4bab-a9d9-d2b2f825fd29',
        name: 'TOPSPORTS山东枣庄薛城区万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269509'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: '7acb173a-3cfd-4f92-86c9-6df104ec5ae3',
        name: 'Nike Factory Store - Dorchester',
        region: 'NORTH_AMERICA',
        storeNumber: '282'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '7ad3407c-d26b-49b8-9153-2ebe09911757',
        name: 'NIKE重庆南坪换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4017'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7ad39dcf-02b5-46de-bc71-56d097b4e061',
        name: 'TOPSPORTS浙江湖州志成路星火NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '205200'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7ae68cbc-25ed-416e-b37a-860ab3f43f35',
        name: '重庆重庆大渡口区钢花路万象汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271199'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7ae84146-bbc4-4642-bc1d-4510cc22fd79',
        name: 'POUSHENG安徽省合肥市华润五彩城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282189'
      },
      {
        address: {
          country: 'ARG'
        },
        id: '7ae9e1e2-5740-4704-8b36-9921d6519c7c',
        name: 'Nike Factory Store - Rivadavia',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6512'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7b0a5904-e476-4456-8b3b-24ace9a77142',
        name: 'JORDAN 6 NANMENWAI',
        region: 'GREATER_CHINA',
        storeNumber: '195339'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3106f6ce-d592-4e61-b488-0b146fb45bf7',
        id: '7b1b9334-ba6e-4f88-a867-f33bdb5feff0',
        name: 'Chicago Marathon',
        region: 'NORTH_AMERICA',
        storeNumber: '327'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7b2d409a-0480-47ea-82eb-cd44b436b36f',
        name: '南通通州朝霞东路万达广场SP',
        region: 'GREATER_CHINA',
        storeNumber: '452551'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7b317371-96af-4b01-8103-b0f982d6573e',
        name: 'Tianjin Incity Fos',
        region: 'GREATER_CHINA',
        storeNumber: 'C6030'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7b3fe0d1-4234-4f5a-8e68-b3fadd8ac469',
        name: '黑龙江省哈尔滨市哈尔滨南岗松雷 BEACON L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '190845'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '7b5cb26d-318e-4715-9dcc-2366339ad77e',
        name: 'Nike Chatswood',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '574'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '659456f0-a9b5-4c12-8bd8-649097373619',
        id: '7b68a5ad-f43a-4daf-b5d5-4c75be1e38bb',
        name: 'Nike Factory Store Warsaw Annopol',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '999'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '7b801056-fa69-4659-8e55-3e95bfc5704e',
        name: '나이키 현대 압구정',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '27053'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7bb4fcd9-ffb7-4d6b-8729-115593ac7cc5',
        name: '江苏省苏州市美罗百货新区店SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271175'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '7c01f8c5-f5e9-4938-8854-5027ee1558a5',
        name: 'NIKE福州世纪金源换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3830'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7c127325-54d1-4978-a11f-776e5baffe7a',
        name: '安徽省芜湖市宝胜芜湖银泰城KL',
        region: 'GREATER_CHINA',
        storeNumber: '330980'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '7c30c890-77af-49ea-90dc-5fc1a1a1c10f',
        name: 'Nike Store Armada (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2682'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7c3ddee2-36b8-4e5e-9038-34640686fcf9',
        name: '四川省南充市顺庆区人民中路四季来 SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '8693'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7c6a6738-3b83-49a3-b5a5-033bc412d863',
        name: 'TOPSPORTS广西桂林红岭路万象城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287298'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7c6f6d2f-bda4-417c-af0e-45a77f67c28a',
        name: '安徽省合肥市融创茂SPORTL',
        region: 'GREATER_CHINA',
        storeNumber: '270714'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: '7c7a869d-f706-48a7-ae13-c4c11e66da73',
        name: 'Nike Unite Ashford',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '529'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7ca3317e-ff4d-40b4-9356-685cc820f6da',
        name: 'POUSHENG贵州安顺市西路国贸NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288486'
      },
      {
        address: {
          country: 'NLD'
        },
        id: '7ccbca4c-f118-48a2-9525-c36448c32d4b',
        name: 'Nike Den Haag (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2491'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '2f1b4c39-e8ac-4cea-abd2-95d8b9b7aefe',
        id: '7cd4b29c-2ad4-458a-a730-068153136a09',
        name: 'Nike Store Berlin',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '841'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7cdcbb70-d478-4ef7-bcb4-895dc0d108bd',
        name: 'POUSHENG安徽省宿州市埇桥区吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282199'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7cea7096-35eb-4752-97b2-b7433ab1908c',
        name: '江苏省苏州市苏州大悦春风里BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '272722'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7cede26d-86e0-4fc3-a1c4-7b3a628c1fe7',
        name: '上海市滔博上海闵行区浦江万达BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '260737'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7d125d19-7cb4-48bf-a83f-f94fbf94ced5',
        name: '广东省广州市盛世长运广州市天河区珠江东路K11 Kicks Lounge L2',
        region: 'GREATER_CHINA',
        storeNumber: '482402'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: '7d2b0499-150d-4c4f-9f12-9d66d766867d',
        name: 'Nike Unite Claye Souilly',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '666'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '7d3de1b5-6bd4-4b93-936a-3880f8c50a15',
        name: 'Nike Store Gordion (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2684'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '7d489a24-42c0-4768-85e4-5653648f47b6',
        name: 'Nike Fountain Gate',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9823'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7d698e20-78e3-4842-8b7b-d2c21f4620a1',
        name: '黑龙江省哈尔滨市南岗区长江路会展中心 NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '270516'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: '7d8a63bb-f717-424c-ba1b-13827b9176c9',
        name: 'Nike Factory Store - Redlands',
        region: 'NORTH_AMERICA',
        storeNumber: '247'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7da26fe8-3d90-4bfb-b977-54f795a4ae10',
        name: '山东省潍坊市坊子区泰华城NIKESPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271292'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '7da94c18-c17a-4b28-ba83-bc1435a1f30b',
        name: 'Nike Factory Store - Curauma',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6415'
      },
      {
        address: {
          country: 'UKR'
        },
        id: '7dde7afd-47c1-45a4-a2db-005a77644e69',
        name: 'Nike Store Kyiv River Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR050'
      },
      {
        address: {
          country: 'IND'
        },
        id: '7de8ed29-c003-4e75-a4a1-2847362f5a2b',
        name: 'Nike Brookfields Mall Coimbatore',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1766'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '2a0898a2-fe79-4fa0-8d7d-b8c63500ced5',
        id: '7e4bc0e1-58cc-4aec-a7ab-d73d203a4939',
        name: 'Nike Store La Defense',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '883'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7e4f27f5-b309-4da8-81f2-c7093781da40',
        name: '耐克天津M-PLAZA体验店',
        region: 'GREATER_CHINA',
        storeNumber: '522271'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7e5be307-bb8b-4a30-857f-4c2dac12a49d',
        name: 'TOPSPORTS福建闽侯国宾大道东百永嘉天地NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288844'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7e6ad872-b018-4fb1-a0db-7e4fdb934850',
        name: '四川省广安市吾悦广场NIKE SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '274286'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7e7868fc-a7db-4ea1-90bb-4ad809dd1093',
        name: '广东省东莞市百丽广东东莞市虎门镇连升路虎门天虹1F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272373'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7e78a338-d692-4e4f-a8aa-2eeb8f0791a5',
        name: '江苏省无锡市滔搏无锡梁溪人民中路恒隆广场Nike Sport M',
        region: 'GREATER_CHINA',
        storeNumber: '270844'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '7e8cc7c6-574c-4301-b31a-5083804bb2f3',
        name: 'Nike Antofagasta',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7235'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: '7e9286f7-3adc-4eca-8ed5-8a83b2672e01',
        name: 'Nike Unite St Helens',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2063'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: '7ed086b0-71e6-4481-8116-8b4620005e96',
        name: 'NIKE上海金科换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3800'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7ee639f4-7848-4e0a-98f5-a69c3c3204a5',
        name: '耐克深圳万象城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '242345'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7efbc2bb-419a-4197-8ad7-d5b3053c9dad',
        name: '河北省保定市莲池区裕华路财满耐克店BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '260211'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7f11fb1c-50b5-4d92-a575-34e6ddb181c1',
        name: '北京市朝阳区西大望路合生汇BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '270536'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: '7f25c394-4d19-497a-a18b-14b84ad4f7dc',
        name: 'Nike Factory Store Minamimachida',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3195'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7f2a0318-fca6-45ae-9840-cce3e44ad100',
        name: '浙江省宁波市锐力清河路万象城JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271193'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7f4fb357-93eb-44bd-aab5-2b633a54207b',
        name: 'CC YUJIE吉林延吉光明街延吉百货大楼NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '279057'
      },
      {
        address: {
          country: 'MEX'
        },
        id: '7f532027-adeb-4dc4-9d77-775016a01e24',
        name: 'Nike Artz Pedregal',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '86170'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7f642922-4ef1-48f8-823f-61ef34e5e221',
        name: '四川省资中市四川省内江市资中县摩尔玛NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '12949'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7f94e4b8-2685-46f5-b277-7bd8dc789f3c',
        name: '新疆维吾尔自治区乌鲁木齐市德汇万达KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '273644'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '3c0636a0-8d1a-41bf-a7e1-5e48a1fe52ee',
        id: '7fb395db-425b-49a2-af7d-b77703b8287c',
        name: 'Nike Factory Store Rugby',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2032'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: '7fb8956f-491e-4525-88b7-48a56901edab',
        name: 'Nike Factory Store Shisui',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3183'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7fda7eaf-11f7-444a-88d4-2b0736ba4783',
        name: 'POUSHENG安徽省蚌埠市万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '274291'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '7ff1abd9-838e-4893-b0f6-db47aa36c2ca',
        name: '江苏省如皋锐力如皋惠政路吾悦广场KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '274333'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '2a0898a2-fe79-4fa0-8d7d-b8c63500ced5',
        id: '7fff36d4-7e6e-4a36-81bc-691937426913',
        name: 'Nike Store Paris Les Halles',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '809'
      },
      {
        address: {
          country: 'LVA'
        },
        id: '8013dc21-0677-479b-a10e-1327a47aaa3c',
        name: 'Nike Store Riga Spice Center (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LVL003'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '80359fd5-e053-455e-b9de-52a2fb1ef066',
        name: 'Nike Parque Arauco',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7244'
      },
      {
        address: {
          country: 'THA'
        },
        districtId: '1e4c57d2-d7e5-4937-99ca-8c74e84c150c',
        id: '804f1d4f-5854-4c79-9617-de70e0d14c7c',
        name: 'Nike Unite - MTT',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6811'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: '80528a6b-9aaf-4275-b573-2337a2a876de',
        name: 'Nike Factory Store Zweibruecken',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '543'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8059a47b-1d1e-4aa3-9256-2a68cd812f12',
        name: '耐克北京西单大悦城跑步体验店',
        region: 'GREATER_CHINA',
        storeNumber: '178821'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '8061bb8e-95ca-4d76-aec0-db4e1516486c',
        name: 'Converse Clearance Store Flushing - The Shops at SkyView Center',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3130'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8074a539-9fe9-41ac-84cb-554637b6a2ad',
        name: 'TOPSPORTS河南郑州金水区花园路国贸新田NIKE RISE-1200',
        region: 'GREATER_CHINA',
        storeNumber: '293410'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b5f4164d-1990-4484-a90e-185f85c325fc',
        id: '807922c3-ae97-4822-98d9-2a72b710755f',
        name: 'Nike Unite - Knoxville',
        region: 'NORTH_AMERICA',
        storeNumber: '296'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: '808706b2-7799-4e22-8fc6-d1754055bd89',
        name: 'Converse Factory Store - Waikele Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3752'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '80a53527-bc1d-4d02-ad3e-1dd482f3527c',
        name: '黑龙江省齐齐哈尔市龙沙区龙华路运动城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '242460'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '80b54067-8e63-417e-9723-daeb8b5d2ecc',
        name: '甘肃省兰州市中海广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273672'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '80c4a022-40bb-4847-9931-119c3a323059',
        name: '나이키 김해',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '96667'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '40880d74-de09-4fd5-81ce-fa42cca1d744',
        id: '80dc7f61-45f3-4ad7-8812-90e7db4fb5cd',
        name: 'Nike Unite Portsmouth',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '542'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '81005432-a8d6-4c3e-8069-d9c1fe719830',
        name: '나이키 상인 타워',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '68450'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '810EE711-8C3C-4AF3-9BC9-273E65A513BC',
        name: 'Nike Factory Store - Marana',
        region: 'NORTH_AMERICA',
        storeNumber: '238'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '813ab1fa-b7f8-4bed-8cf6-fd05b460e00a',
        name: '吉林省白山市吉林省白山欧亚合兴KL-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '13971'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8162fe8d-a09b-4d1d-9afb-5fa17d10f40e',
        name: '安徽省合肥市宝胜合肥万科广场NKkickslounge',
        region: 'GREATER_CHINA',
        storeNumber: '241095'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8168f657-6109-4e82-bac0-f7936cd59a2a',
        name: '义乌银泰百货NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '260290'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '816fc199-4351-48de-b45b-18f3e9f0b78e',
        name: 'Nike Pacific Place',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7471'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: '817215b1-e378-4d79-86e4-3c820082399e',
        name: 'Converse Factory Store - Sawgrass Mills',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3769'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: '8183b725-09d8-4aec-a5df-0eafe344a63e',
        name: 'Nike Factory Store Izmir',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '672'
      },
      {
        address: {
          country: 'KWT'
        },
        id: '8186ed9e-08b2-433e-a985-ed0b9cd50695',
        name: 'Nike Kuwait Airport',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KUW105'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '1c3af8dc-d58a-4495-909d-823d7d8f09e1',
        id: '818df6fd-8541-443e-a0e8-26b054755338',
        name: 'Nike Factory Store Mantova',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '670'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '0d35d03a-1472-47ae-a5e1-f5b15b230f10',
        id: '81A37269-5C2F-4F57-AF7F-E534BB9D406F',
        name: 'Nike Clearance Store - Centralia',
        region: 'NORTH_AMERICA',
        storeNumber: '148'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '81a6da3a-e752-4a64-aaf1-ef5037edf9a1',
        name: '나이키 홍대',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95630'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '81ab8506-43f2-4a8e-b83b-ba64c9c0f353',
        name: '广东省江门市百丽江门市蓬江区白石大道166号大融城3楼 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '266999'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '81d61386-d1f7-45e3-b0ae-b60dd6e4993f',
        name: '山东省淄博市张店区万象汇KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '266708'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '81dacf8f-d75f-4290-9676-d0021d1ff96c',
        name: '나이키 롯데 잠실',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72491'
      },
      {
        address: {
          country: 'IND'
        },
        id: '81ec33d5-eb0e-4c48-a6a2-513be8af5119',
        name: 'Nike Union Point',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1516'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8250c9b7-f5b1-4a6d-946f-61eaecf7047f',
        name: '北京通州万达广场万达百货BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '113678'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '82796f48-2d69-4225-b61a-1dd9422fc75a',
        name: '耐克徐州兴隆大厦体验店',
        region: 'GREATER_CHINA',
        storeNumber: '526394'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '827a8e9e-427f-4796-b6d9-aeb84332095c',
        name: '山东省济南市万虹广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '527473'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '829d5a72-2d3f-4629-9ffc-6051799ad3da',
        name: '山东省荣成市威海荣成文化路振华商厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272248'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '82a38bdc-e2e9-4281-b465-19e83cb20911',
        name: '广东省佛山市盛世长运佛山保利水城 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274308'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '82c154b8-cf06-4a83-8b24-826ee2f822d4',
        name: '浙江省东阳市东阳第一百货NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '271034'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '82f5e109-8198-4048-b893-64e386d9abab',
        name: '山东省淄博市张店区万象汇BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240487'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '82fdf9e8-a613-45ba-84d8-d883bf2c3236',
        name: '陕西省宝鸡市宝鸡市金台大道银泰百货BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '272138'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '524440b5-a003-410b-9e4b-25c2de21868b',
        id: '8321899a-b12c-43e4-a6e5-d2488e67811a',
        name: 'Nike Factory Store Barberino',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '689'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '83420e97-1e57-438f-8cbb-4797f5eaad99',
        name: 'POUSHENG河北廊坊新华路明珠大厦KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '293713'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8345baea-2f4b-4e48-b4fd-96f38953e4de',
        name: '나이키 안성',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95738'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8346ddd2-a310-423d-af84-c461e3ee2252',
        name: '나이키 스타필드 부천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '89730'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: '835503a5-aab4-42da-a4f7-af0805d623eb',
        name: 'Nike Unite - Fresno',
        region: 'NORTH_AMERICA',
        storeNumber: '493'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: '83585a05-6e8f-4cdb-938c-52640fa568b8',
        name: 'Nike Clearance Store Kerpen',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2009'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '835d114e-bd9a-420a-8fbe-4dde00c3bffb',
        name: '나이키 스타필드 하남',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '71722'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '836699df-0be7-42f7-b44e-e2114a2fdfac',
        name: '广西壮族自治区南宁市百丽广西南宁市兴宁区朝阳路南宁百货大楼6F NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '140903'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8392b28e-586f-4a7b-80be-3bbedb99b264',
        name: 'Wuhan Bailian FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6040'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '83a29a78-fd91-4585-bbc4-98ff60087bf9',
        name: 'Nike Store Venezia (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2488'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd99e4ddd-3a04-4da8-af15-7a4b1648727f',
        id: '83a93d95-1507-4659-8b2b-ee5a67210fbb',
        name: 'Nike Unite Madrid Torrejon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2060'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '83c6444c-145f-4540-939c-36dd70e4d205',
        name: '浙江省杭州市双键拱墅区城西银泰KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '269039'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '83cfd649-7f11-4f8d-a482-31e10ad694ae',
        name: '南昌中山天虹NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '5046251'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '83d38983-cc30-4bd5-93e4-f186e2e2352f',
        name: '耐克石家庄先天下体验店',
        region: 'GREATER_CHINA',
        storeNumber: '531110'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '83f3f8e4-d0e1-4698-9269-5ee036d11ec7',
        name: 'TOPSPORTS湖北武汉文昌路南湖佰港城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288469'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '840402b8-3cab-413c-98af-4ce031f79061',
        name: '安徽省合肥市包河苏宁易购NKBEACON550',
        region: 'GREATER_CHINA',
        storeNumber: '268874'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '8414AEB1-54EF-46DB-9C1D-F5034799660E',
        name: 'Nike Community Store - Detroit',
        region: 'NORTH_AMERICA',
        storeNumber: '251'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8416a3b8-14ca-4097-b33b-2beb6f7d82e8',
        name: '江苏省南京市锐力江宁金鹰天地KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '186414'
      },
      {
        address: {
          country: 'GEO'
        },
        id: '842b83a2-1a14-40e0-bf4f-8be890f2a943',
        name: 'Nike Store Tbilisi Rustaveli (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'GEO001'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '84402c16-1ba6-489d-88eb-0f12cf7f8fa1',
        name: '辽宁省沈阳市沈阳沈河区运动汇BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '13685'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: '84720f05-c76f-49d0-b308-000461531f15',
        name: 'Nike Unite Ryuo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3047'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
        id: '847be6a7-a5f1-4117-af6d-2ef8cb182fe2',
        name: 'NIKE上海X158店',
        region: 'GREATER_CHINA',
        storeNumber: '3468'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8492ca17-cc63-4a44-b4d9-a34ea38e43a0',
        name: '绵阳联腾NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '262397'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '849C4983-6637-429A-B7B9-5D32AAA1D0A4',
        name: 'Nike Factory Store - Pismo Beach',
        region: 'NORTH_AMERICA',
        storeNumber: '345'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '849dd85e-e8de-4626-9a83-5b205e0d06db',
        name: 'FOX NSP ICE MALL',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2348'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '84ae687a-d067-4dfd-a853-83b19a75c12f',
        name: '耐克广州北京路KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '180187'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '84d32ed1-366c-476c-aa6c-5b14a01d6a83',
        name: 'TOPSPORTS河南郑州惠济区开元路万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274344'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: '84dbce30-aab9-4dce-bcad-106332419f79',
        name: 'NIKE北京马连道换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3404'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2a8a5272-2ead-401e-a8ea-15edea456d51',
        id: '84dfd5d7-87ee-40e1-8ecf-782b18c4a606',
        name: 'Nike Well Collective - Chestnut Hill',
        region: 'NORTH_AMERICA',
        storeNumber: '433'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '84fbff3a-5a94-4fc8-9cfc-8a50685e15d7',
        name: '江苏省常州市金坛吾悦SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271311'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd99e4ddd-3a04-4da8-af15-7a4b1648727f',
        id: '851ab162-5bee-4b2f-bc8a-0afd7342d2ef',
        name: 'Nike Unite Madrid Las Rozas',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '508'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '85517777-50f4-4346-b30e-ae840d2b9054',
        name: '安徽省合肥市高新区银泰NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '271050'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '855d9db6-dc87-4b5b-a7e2-caa0af07ee90',
        name: '나이키 동대구',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21203'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '85b2c870-07df-4bc7-8e15-b2c529e2ca18',
        name: '广西壮族自治区桂林市百丽桂林穿山东路万达百货SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '189523'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '85cb2449-69e3-4327-bf87-2215216c5ae3',
        name: '安徽省合肥市百丽合肥庐阳淮河路梦之城BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '487520'
      },
      {
        address: {
          country: 'SAU'
        },
        id: '85dbf8f8-b968-4eaa-a5fd-5c05c528f37b',
        name: 'Nike Al Ajlan',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA003'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '85e1904e-271f-4d6a-984d-8e34c1a3a703',
        name: '江苏省苏州市苏州中心Beacon 1200',
        region: 'GREATER_CHINA',
        storeNumber: '270790'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '85fefb48-c847-4128-a3db-07942aab0200',
        name: 'Nike Fukuoka',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3043'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8601896a-ba03-4bef-8026-ff2c1f2dc3ee',
        name: 'TOPSPORTS肇庆市端州区端州四路10号星湖国际1F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281936'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: '861233BF-8943-4B2E-B0DD-BA223FD40379',
        name: 'Nike Unite - Commerce',
        region: 'NORTH_AMERICA',
        storeNumber: '2'
      },
      {
        address: {
          country: 'CZE'
        },
        id: '8613054c-5f18-4ebf-9b6f-c2d6dc2f7078',
        name: 'Nike Store Prague (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2448'
      },
      {
        address: {
          country: 'EST'
        },
        id: '8613e6e7-2663-4c80-92e1-901efe7660eb',
        name: 'Nike Store Tallinn Ülemiste Center (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EST020'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '861a0d39-04bc-4a75-819e-93625ab4cb0b',
        name: 'Nike Well Collective - IFC',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '49545'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: '8621bcac-525e-4376-876e-8469bf8e0fdb',
        name: 'NIKE常州通江换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3856'
      },
      {
        address: {
          country: 'PRT'
        },
        districtId: 'd018da20-ba06-4a8b-82d2-eaa2a5670c5d',
        id: '8652006c-0d0a-47d9-8e4b-6ddea20feef9',
        name: 'Nike Factory Store Lisbon Strada',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '965'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '865b07a6-d065-43dd-918e-6a857efa9d5f',
        name: '广西壮族自治区桂林市桂林临桂万达广场 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270420'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '866182a0-c1dc-46c1-a481-17f1c8159a49',
        name: 'TOPSPORTS河北秦皇岛海港长江西道广缘孟营NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288185'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8677f9ea-c4f8-4775-a507-33d72ce34bcd',
        name: 'TOPSPORTS湖南益阳益阳大道东万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '281932'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '86892f1e-44d5-470b-848f-3ba928cb25a9',
        name: 'Nike Factory Store - Salvador',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6329'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '868c6ef5-c699-4b9e-ac78-a6157fc5a224',
        name: '나이키 롯데 평촌',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85991'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '8b2ae4b8-eb16-4d51-83bb-dba72ba34715',
        id: '868d700b-f3e7-4c98-b5b1-fa145e88c8f3',
        name: 'Nike Factory Store La Valentine',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '826'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '86C44650-4823-49C0-91EB-720CEB3CB95C',
        name: 'Nike Factory Store - Phoenix',
        region: 'NORTH_AMERICA',
        storeNumber: '62'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
        id: '86bec70a-7c37-49af-ba5a-4c306bb41c58',
        name: '나이키 팩토리 스토어 양주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6105'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: '86c305a8-14cb-439d-81e7-ddd4d50f50a5',
        name: 'Nike Factory Store - Altoona',
        region: 'NORTH_AMERICA',
        storeNumber: '263'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '86ce7728-7a1a-4677-aa07-5afb3944b579',
        name: '上海市锐力广富林路松江印象城BEACON750',
        region: 'GREATER_CHINA',
        storeNumber: '273114'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '86d55455-48ac-4ce6-ab7c-bb97a3f97d38',
        name: '나이키 업스퀘어 울산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '84023'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: '86db1013-6bfe-4700-bb7e-5ccfe127c289',
        name: '나이키 유나이트 가산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6108'
      },
      {
        address: {
          country: 'POL'
        },
        id: '86ecc589-9c15-4924-ab3b-81aa10c09bf4',
        name: 'Nike Store Zlote Tarasy (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2494'
      },
      {
        address: {
          country: 'NZL'
        },
        id: '86f110ef-2d80-4194-a176-330f262b5ef8',
        name: 'Nike Botany',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6583'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '86fba23d-32df-4909-aebb-344f8be586b5',
        name: '나이키 충장로',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '62996'
      },
      {
        address: {
          country: 'GRC'
        },
        id: '870ea198-14a0-4515-a7b4-f80fc3cfcd07',
        name: 'NSP TSIMISKI II',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2393'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '87253485-231f-4570-933b-dee6c6cf3778',
        name: '泉州浦西万达广场NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '246575'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '8728C7B3-B2CD-4DDF-867D-592DDEC99FF7',
        name: 'Nike Factory Store - Barceloneta',
        region: 'NORTH_AMERICA',
        storeNumber: '108'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '872EEC9F-73E4-49D6-B3A9-C2D108A95B6A',
        name: 'Nike Clearance Store - Tempe',
        region: 'NORTH_AMERICA',
        storeNumber: '134'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '873253e4-65f6-4428-b97b-dbfb4dee4c9b',
        name: '上海市上海杨浦淞沪路又一城Beacon 350',
        region: 'GREATER_CHINA',
        storeNumber: '267188'
      },
      {
        address: {
          country: 'NLD'
        },
        districtId: 'fc32718b-821b-4be5-bc65-a9b03f86cbaa',
        id: '873c6904-a49f-4867-8a6d-ce449f1ae5fe',
        name: 'Nike Unite Amsterdam Osdorp',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '640'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8740e2b8-3469-409e-8182-76b93c3dc6c1',
        name: '日照保利购物广场NK',
        region: 'GREATER_CHINA',
        storeNumber: '293800'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '8753ac2f-1b76-4da5-b504-7b607331dfdb',
        name: 'NIKE广州沙凤换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3919'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: '87569797-C91A-4879-9314-1A6D8CCD1DCC',
        name: 'Nike Factory Store - San Juan',
        region: 'NORTH_AMERICA',
        storeNumber: '241'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8767452f-5f40-4806-99c8-a24de877f775',
        name: 'TOPSPORTS湖北武汉解放大道武商广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '2292'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: '876ab95b-a16c-4fa1-82a6-c95cfa98e2cf',
        name: 'Nike Unite - Hershey',
        region: 'NORTH_AMERICA',
        storeNumber: '481'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '8783ebfa-d0b4-4316-aec4-a14dfc5b7be0',
        name: 'Nike Store Constanta City Park (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM118'
      },
      {
        address: {
          country: 'ARM'
        },
        id: '87aac71a-78fe-4391-b5c2-863abb4c588f',
        name: 'Nike Store Yerevan Dalma Garden Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ARM005'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '51996a81-74ad-4aaf-9e52-4997bf860de0',
        id: '87ce16a8-2ab8-4f77-865a-42b690c99ec5',
        name: 'Nike Well Collective - Bridgeport Village',
        region: 'NORTH_AMERICA',
        storeNumber: '533'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '87f27df3-6e78-45ef-9408-5f6b6a26e921',
        name: '上海市宝胜上海宝杨路宝龙城市广场550',
        region: 'GREATER_CHINA',
        storeNumber: '260082'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '881176f6-70cb-473f-b974-b380015df813',
        name: 'POUSHENG浙江杭州丽水路远洋乐堤港NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '194370'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '882becf2-6fc5-41a0-b941-3d27d59165e4',
        name: '吉林省长春市万象城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288212'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '886cf027-1283-4d1a-a590-5f0fa62da18f',
        name: '山东省济南市历下区恒隆广场Beacon-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '260992'
      },
      {
        address: {
          country: 'AUS'
        },
        id: '886eb8eb-6039-4bba-8802-5126b551df5b',
        name: 'Nike Claremont',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4865'
      },
      {
        address: {
          country: 'GRC'
        },
        id: '887c93c6-23d0-4adc-811a-2843a7c8e370',
        name: 'Nike Store Patra (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2637'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '88849a17-ac0e-4733-b7d2-b428687f53ab',
        name: '나이키 구미 인동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '54089'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: '8893060c-1460-40a2-9e21-1b35954b6060',
        name: 'Converse Factory Store Hanover - Arundel Mills',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3132'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8896a6f7-9230-4457-b40c-4ac2f758d59e',
        name: '나이키 전주 효자동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '57077'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8898e7fd-820e-4f39-a77b-54910ed66bc3',
        name: 'Foshan Florence FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C9376'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '889a683c-52ed-4f52-b341-fba07b36fa24',
        name: '宝胜深圳中央大街one avenue  BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '242325'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '889db1b8-adbd-4565-a489-90ab6516bc19',
        name: '贵州贵阳金阳南路1号世纪金源NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274362'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: '889ea6f0-3676-42db-a0eb-e6b67e6ca215',
        name: 'Nike Factory Store Ankara',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '671'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f6bf44a0-e278-49be-aba9-05645c2ea3df',
        id: '88BB9C48-2C3E-4316-A4CE-F31FFE33222E',
        name: 'Nike Factory Store - Pittsburgh',
        region: 'NORTH_AMERICA',
        storeNumber: '166'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: '88f8929a-382d-4f1c-8f9d-6ed6a442996c',
        name: 'Nike Clearance Store Nasu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3192'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '88fe0249-d0aa-4fcd-ae92-c18758ee0630',
        name: '山东省济宁市济宁市任城区新城吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274522'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8919ebae-8ef1-438d-b4d4-540d3314dc45',
        name: '上海市锐力上海宝山万达BEACON',
        region: 'GREATER_CHINA',
        storeNumber: '195202'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '89282812-a63c-4749-8495-25939ed9e633',
        name: 'NIKE YORKDALE SHOPPING CENTRE (NEW)',
        region: 'NORTH_AMERICA',
        storeNumber: '504577'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8947468c-c4a1-4669-ba7f-2dfd2b31903b',
        name: 'POUSHENG黑龙江富锦中央大街新玛特NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287216'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '89589d1d-d4c2-4608-8a47-63d94bb7ecdf',
        name: '江苏省苏州市苏州苏体中BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '520338'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '896b818d-0a42-4764-8d0b-d1f7907cfac9',
        name: '나이키 모다 인천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '68977'
      },
      {
        address: {
          country: 'PHL'
        },
        id: '898e1548-e38c-4a98-92c0-4987a3f04564',
        name: 'Nike The Fort',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7348'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'dcc4eb42-f310-4b95-ba54-d696a999c727',
        id: '898f2593-0949-4e92-b80f-357128366445',
        name: 'Nike West London',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '806'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '89c15580-3d41-4671-8c58-865a865bdc1b',
        name: '辽宁省锦州市凌河区千盛BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '262395'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '89c4af2b-d060-4cda-92c8-ecc532f54fc3',
        name: '江苏省张家港市张家港吾悦广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '531613'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '89ce49d3-7239-4cb9-aa37-94f9c5c0e791',
        name: '山东省青岛市青岛市崂山区崂山万象汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274458'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: '89d2565b-5647-4bff-9d0d-beb28269795c',
        name: 'NIKE北京顺平换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3868'
      },
      {
        address: {
          country: 'BRA'
        },
        id: '89ffba06-7b3e-47ab-9eba-84ac9db5fd31',
        name: 'Nike Factory Store - Araguaia',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6326'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: '8AB986C9-AB54-4815-8A87-4B7267744DE6',
        name: 'Nike Unite - Henderson',
        region: 'NORTH_AMERICA',
        storeNumber: '385'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
        id: '8B7D73E9-0200-4498-B224-965B7AB66F17',
        name: 'Nike Factory Store - Tijuana',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6629'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f8c1f6da-21e3-4a63-b2c8-ae4d6935bf71',
        id: '8E082AA4-DC1A-45A1-B004-C7AEA143BAFC',
        name: 'Nike Factory Store - Cincinnati',
        region: 'NORTH_AMERICA',
        storeNumber: '378'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8a0eb3d3-8527-4b8a-a4c2-ce66f82ddca5',
        name: 'NIKE KIDS上海港汇广场店',
        region: 'GREATER_CHINA',
        storeNumber: '522641'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8a2c5ad1-f6ba-4560-ac00-885bc298a8fa',
        name: '陕西省渭南市陕西省渭南吾悦广场NIKE SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '241288'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8a5d10a2-e888-42f6-820e-39d076ff3e2e',
        name: 'POUSHENG新疆昌吉中山南路汇嘉NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '279069'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8a5f9039-8ec5-4301-b16e-371eb6bc861f',
        name: 'REALLY浙江省温州市鹿城区瓯江路与杨府山路万象城JORDAN-L2',
        region: 'GREATER_CHINA',
        storeNumber: '293670'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8a70b0ea-40fa-4578-913b-09de3ff25c92',
        name: '나이키 경성 부경',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '99686'
      },
      {
        address: {
          country: 'IDN'
        },
        id: '8a7a7541-5283-4687-a498-1622d048b4f5',
        name: 'Nike Bandung',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '96089'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '8a7c773b-fbb0-43ff-90a8-de20806e48be',
        name: 'Company Store Tokyo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3032'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20571e42-cd9f-476c-9571-962ebd17937c',
        id: '8ab97d7a-8144-4192-b69d-88e100f4b6d9',
        name: 'Nike Clearance Store - Tucson',
        region: 'NORTH_AMERICA',
        storeNumber: '470'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8ab995b0-5ebc-41de-8092-5e0a88fc2250',
        name: '江苏省南京市南京新街口中央商场750',
        region: 'GREATER_CHINA',
        storeNumber: '245800'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8abb05ff-6294-4eeb-908a-485cbc3f3be3',
        name: '辽宁省丹东市丹东元宝区新玛特BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '22111'
      },
      {
        address: {
          country: 'ARE'
        },
        id: '8ae94ffc-1243-4e6c-a798-60bb2ab4616f',
        name: 'Nike Store FUJAIRAH CITY CENTRE',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UAE054'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8aedebdd-6ba7-4f5a-bbc5-270c89ab6373',
        name: '内蒙古呼和浩特回民区维多利商厦TOTAL HOOPS-L2',
        region: 'GREATER_CHINA',
        storeNumber: '102248'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '8aeebc65-79cf-419d-b193-d599fd4450bc',
        name: 'Nike Store Bari (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2785'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8afaf2c2-a449-4628-ae68-78063f2c16d7',
        name: '浙江省杭州市下沙龙湖KL1',
        region: 'GREATER_CHINA',
        storeNumber: '271023'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: '8b0512b9-9295-4b4b-84cc-3232cf781aa2',
        name: 'Converse Factory Store - Palm Beach Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3788'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '0edcb3f7-a768-4ade-83d0-d0e5365d6dc5',
        id: '8b071bec-092c-44aa-bb2e-0bff0010df7c',
        name: 'Nike Factory Store Bridgewater',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '606'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '8b0965f6-71ae-4a26-a28f-214ba1535e2d',
        name: 'Converse Factory Store - Gloucester Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3102'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '8b17c131-c3a8-45f5-b2eb-8fdb547c00fc',
        name: 'Nike Store Grand Haifa',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2714'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8b28f99b-0c66-4c4d-9e22-3fc1ee46b2bf',
        name: '河南省洛阳市涧西区长申国际KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269315'
      },
      {
        address: {
          country: 'CAN'
        },
        id: '8b2e9b54-2fb0-4f1f-9a46-62c4ba003f58',
        name: 'Nike Toronto',
        region: 'NORTH_AMERICA',
        storeNumber: '327722'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '8b57d8d7-cb05-459c-b739-950d29c583eb',
        name: 'Nike Store Verona Adigeo',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2359'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8b5d36ae-ba52-4d7f-ba8e-dda8e791d383',
        name: '宁夏银川王府井百货BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '195560'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '20698335-5f01-4d39-be57-78cf7b399aa7',
        id: '8b63251a-5f3c-4441-b8be-ea7d5c2cd2e5',
        name: 'Nike Unite - North Bronx',
        region: 'NORTH_AMERICA',
        storeNumber: '451'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8b828e31-9cf5-4702-87bc-3b97f6c0cde7',
        name: '重庆市璧山区俊豪中央大街 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '266908'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: '8b99593d-567e-46bd-841d-f1b9ddd794fd',
        name: 'NIKE浙江杭州换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3611'
      },
      {
        address: {
          country: 'CHL'
        },
        id: '8bc6ad5f-379f-4a60-a402-02793a495854',
        name: 'Nike Marina Oriente',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7233'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: '8bcb560d-4e83-4dc5-8829-961d0ec9cb0f',
        name: 'Nike Leeds City Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2509'
      },
      {
        address: {
          country: 'ISR'
        },
        id: '8be84247-af14-4d47-ad47-e4bf84ac20d5',
        name: 'Nike Store Fox Nos Haifa',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2715'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8bef39fb-be1b-4249-87a0-700d8f6c9abd',
        name: 'TOPSPORTS北京市顺义区华联NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '281948'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '424a9a4d-32cb-431b-936d-31fe4b0de399',
        id: '8bf8b573-c651-4a71-9035-8c71e1251ff2',
        name: 'Nike Unite Fukaya',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3199'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: '8bfd8586-cf0a-4836-be7b-a63fb95285a2',
        name: 'Nike Well Collective - Glendale',
        region: 'NORTH_AMERICA',
        storeNumber: '384'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: '8c0f09ad-f54f-4495-8cbd-35ffd25f6323',
        name: 'NIKE贵阳广大优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4037'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8c2ac9ca-1a97-4cfa-8a9c-68cc91424b67',
        name: '新疆维吾尔自治区乌鲁木齐市乌鲁木齐天山区中山路汇嘉商场 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '185416'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: '8c3d9419-0fcc-4060-8bdb-3f466876a60c',
        name: 'Nike Factory Store Bordeaux',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '540'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8c3eee3a-77bc-4583-a97a-f1a89ccc25e3',
        name: 'JORDAN 1 HONGXING',
        region: 'GREATER_CHINA',
        storeNumber: '180638'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8c51936d-bf19-44a1-b7bc-028d381a565e',
        name: 'Suzhou Aeon FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6007'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8c59e2d1-3573-4aaa-b5cf-2f3b907f3e31',
        name: '福建省福州市百丽福建福州仓山区胪雷路50号阳光天地购物中心1楼NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271334'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8c5ad0a9-67eb-444d-a7c0-97c67f97148d',
        name: '云南省丽江市古城区国际购物中心NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288377'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8ca86960-658d-4b89-8516-ea12b9059c2c',
        name: '耐克常州吾悦广场 体验店',
        region: 'GREATER_CHINA',
        storeNumber: '245801'
      },
      {
        address: {
          country: 'ITA'
        },
        id: '8cc111c6-160a-4a20-9795-72dc20180049',
        name: 'Nike Store Milano Loreto (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2478'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8cfa2d64-36eb-4766-a44d-7d16b78e5bbe',
        name: 'TOPSPORTS辽宁阜新解放大街万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287583'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8d07aad7-70e3-4c6a-8677-775c8a508431',
        name: 'TOPSPORTS黑龙江哈尔滨中兴大道万达广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '293456'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8d2397bc-0455-4c61-8c06-44e7a0e642a6',
        name: '广东省广州市盛世长运天河太古汇 BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '270476'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: '8d2d9042-ed3d-434a-b9cd-05c2336d9bb8',
        name: 'Converse Factory Store - Philadelphia Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3728'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: '8d48d131-20e8-4591-bf7c-a0fceb911e7d',
        name: 'Nike Factory Store Toulouse Fenouillet',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2031'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8d493730-3642-4ea3-b40b-91d1ac4700c3',
        name: '绍兴国金大悦城NK KL',
        region: 'GREATER_CHINA',
        storeNumber: '274495'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '625223c0-7d4b-4b16-87b1-d2e4d658554b',
        id: '8d51da78-010e-4268-9204-de26d02a1759',
        name: 'Nike Well Collective - Legacy West',
        region: 'NORTH_AMERICA',
        storeNumber: '398'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8d52a2e9-2c6b-42f7-a28f-fe1a62343f16',
        name: '河南省平顶山市湛河区凌云路中骏世界城NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '273482'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: '8d556c69-a39e-4f47-bfe7-dd2c09ea8922',
        name: 'NIKE天津友谊换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3858'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: '8d5d3baf-0333-4f49-9001-eb252d8fe2cb',
        name: 'NIKE換季優惠店 高雄家樂福',
        region: 'GREATER_CHINA',
        storeNumber: '3382'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8d686b9e-23cd-4449-82be-fa73f32fd47b',
        name: '济南弘阳购物广场NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '273682'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8d84b586-71cf-47ac-ab45-4a8c8af73e54',
        name: '甘肃省兰州市城关区亚欧KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270866'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8d8e3e00-ebfe-4a14-9d7e-6db313abd7a4',
        name: '河南省洛阳市洛龙区泉舜大商新玛特BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '262409'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: '8da38c8b-35da-4e4d-8ed5-672d5b344642',
        name: 'NIKE自贡丹桂换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3826'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8da67638-7c6c-44c5-ba76-79743f523358',
        name: '나이키 김포 장기',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '42967'
      },
      {
        address: {
          country: 'FRA'
        },
        id: '8db7c2d3-e9a7-4293-a186-3c64dd08310d',
        name: 'Converse Brand Outlet Store Provence',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8118'
      },
      {
        address: {
          country: 'THA'
        },
        id: '8dc72c8a-7f7b-46f1-a00a-0b1291ab528e',
        name: 'Nike Megabangna',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '97959'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '8e054e53-7fc3-4ed8-b778-5ce8a4b7e187',
        name: 'NIKE佛山胜利换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3654'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8e20d6c8-c67d-4a31-9e56-28cc5f4a2e24',
        name: '耐克长沙IFS体验店',
        region: 'GREATER_CHINA',
        storeNumber: '454549'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8e2b10cb-6019-4bb8-8d73-f5e1736daf38',
        name: 'POUSHENG江苏苏州狮山龙湖天街KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '293654'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8e2b2a82-cd96-47c7-9e12-a0c0bbdab934',
        name: '나이키 스타필드 코엑스',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '60307'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8e2f3888-e063-4b24-ae40-17f2520b0ffd',
        name: '나이키 롯데 영등포',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '72443'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'e9f1131e-6c76-4ad8-b794-c4980b41ad4f',
        id: '8e4cf246-b76d-4823-9db2-cf26a52f1a5f',
        name: 'World of Flight Tokyo Shibuya',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3197'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8e57cc1c-f7f9-4cd9-8ec8-6b5dbb69701f',
        name: 'POUSHENG浙江省金华市世贸中心KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '274491'
      },
      {
        address: {
          country: 'TWN'
        },
        id: '8e6467a5-46ac-475b-bd60-e90954e999c7',
        name: 'NIKE經銷商門市- Jordan 16 Songgao',
        region: 'GREATER_CHINA',
        storeNumber: '5073131'
      },
      {
        address: {
          country: 'DNK'
        },
        districtId: '0f873e25-c3dc-420c-82e5-740c579c5669',
        id: '8e7606ea-059e-48b6-b8ff-e6fcb21c0b80',
        name: 'Nike Factory Store Copenhagen Disa',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '933'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: '8e839dee-d677-4772-a696-7a1317a720d9',
        name: 'Converse Factory Store - Orlando International Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3724'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8e8a1af5-ac72-48de-8413-8be023e7c057',
        name: '上海市上海宝山区沪太路宝山日月光中心NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '273638'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '8e9fb914-6c2e-4327-9488-1f0621cf518c',
        name: 'Nike Well Collective - Balcova (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2341'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8ea60d42-6ff9-4500-964a-f8e5538d4a7d',
        name: '나이키 문산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21499'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8eaac454-d084-447c-8900-c7f8a4211be2',
        name: 'POUSHENG辽宁锦州茂业购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288195'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8eac230e-c9c3-43d2-8486-5d4946cfbcc8',
        name: '江苏省无锡市宝胜江苏无锡Pousheng商业大厦KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '3355'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8eb20f56-c6e2-47e4-8105-a62e77f702ab',
        name: 'POUSHENG安徽省合肥市悦方国际NK SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '281951'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '659456f0-a9b5-4c12-8bd8-649097373619',
        id: '8eb4c86a-c0ce-4ceb-95c9-dbb598c08923',
        name: 'Nike Factory Store Szczecin',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '685'
      },
      {
        address: {
          country: 'SRB'
        },
        id: '8eb93e64-dbc6-44f1-8dbb-0d75f54a6fc7',
        name: 'Nike Well Collective - Usce (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER100'
      },
      {
        address: {
          country: 'POL'
        },
        id: '8ebe59e9-cc26-4542-b0bf-ac8fdc227db4',
        name: 'Nike Store Szczecin Kaskada II (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2406'
      },
      {
        address: {
          country: 'KOR'
        },
        id: '8f00bbfa-7ea1-487f-bc94-418d0489e28f',
        name: '나이키 모다 김천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '66240'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: '8f0a0241-4cbe-490b-bd0e-4204e5523ea4',
        name: 'NIKE玉林民主换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3742'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: '8f0ae04f-a82f-4f85-972b-aea3fd0272e0',
        name: 'NIKE万宁莲兴换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3766'
      },
      {
        address: {
          country: 'CHN'
        },
        id: '8f0b7090-0335-4f71-9624-a05cd9b4c6d0',
        name: '山西省大同市新市区万达广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '268876'
      },
      {
        address: {
          country: 'DNK'
        },
        id: '8f16ae09-fcdf-486a-a4c0-3fd12a9e50f7',
        name: 'Nike Well Collective - Aarhus (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2432'
      },
      {
        address: {
          country: 'ROU'
        },
        id: '8f234156-66db-44b4-80f6-d1b710bab311',
        name: 'Nike Outlet Bucharest Pallady (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM114'
      },
      {
        address: {
          country: 'TUR'
        },
        id: '8f2e1c31-25c8-4716-b5a5-ad96496250ae',
        name: 'Nike Store M1 (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2777'
      }
    ],
    pages: {
      next: 'store/store_locations/v1?search=%28id%21%3Dfoo%29&anchor=2000&count=1000&fields=id%2Cname%2CstoreNumber%2Caddress.country%2CdistrictId%2Cregion',
      prev: 'store/store_locations/v1?search=%28id%21%3Dfoo%29&anchor=0&count=1000&fields=id%2Cname%2CstoreNumber%2Caddress.country%2CdistrictId%2Cregion',
      totalPages: 4,
      totalResources: 3599
    }
  };
