{
    "lastA11yAudit": "",
    "name": "Side Panel",
    "description": "The Side Panel can be used to display supplementary information or actions that don't otherwise fit into the workflow of the page.",
    "docs": "#/components/SidePanel",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=6410%3A298948",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/SidePanel",
    "tags": ["Side Panel", "Drawer", "popout", "filter"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
