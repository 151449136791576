import { isNone, isSome } from '@nike/rcf-fp';

export const trimToEmpty = (str) => str?.trim() ?? '';

export const isWhitespace = (str) => isSome(str) && str.trim() === '';

export const isNotWhitespace = (str) => !isWhitespace(str);

export const isWhitespaceOrNil = (str) => isNone(str) || isWhitespace(str);

export const isNotWhitespaceOrNil = (str) => !isWhitespaceOrNil(str);
