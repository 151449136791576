import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  formatStoreOfferingsAttributes,
  restructureWarehouses,
  sortOfferings,
  sortStoresByNumber,
} from '../../utils/formatting';
import {
  getDigitalStores, getDigitalStoreById,
  getStoreOfferings, getStores, getStoreById,
  getWarehouses, getWarehouseById,
} from '../../utils/service-calls/sls';
import { isUserAllowed, slsPermissions } from '../../utils/tab-permissions';

import DigitalStoresMain from './digital-stores';
import { Links } from './generic';
import StoresMain from './stores';
import WarehousesMain from './warehouses';

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDigitalStores: [],
      allWarehouses: [],
      digitalStores: [],
      formData: {},
      getError: '',
      getting: false,
      loadedStores: [],
      path: '',
      regionToLoadStoresFor: '',
      storeOfferings: [],
      stores: [],
      viewingDigitalStores: false,
      viewingStores: true,
      viewingWarehouses: false,
      warehouses: [],
    };
  }

  async componentDidMount() {
    this.changeStoreType();
  }

  async componentDidUpdate(_, prevState) {
    // we reload stores for the stores page every time the Stores components tells us that the user has selected or re-selected a region
    const reloadStores = this.state.regionToLoadStoresFor !== prevState.regionToLoadStoresFor;
    if (reloadStores) {
      this.viewStores();
    }
    // don't update what you're viewing if the pathname is the same
    if (this.state.path !== window.location.pathname) {
      this.changeStoreType();
    }
  }

  changeStoreType = async () => this.setState({
    path: window.location.pathname,
    viewingDigitalStores: window.location.pathname.startsWith('/storelocationservices/digital-stores'),
    viewingStores: window.location.pathname.startsWith('/storelocationservices/stores'),
    viewingWarehouses: window.location.pathname.startsWith('/storelocationservices/warehouses'),
  }, async () => this.viewFacilityType());

  handleError = (method, message) => this.setState({ [method]: message });

  retrieveFacilities = async (userInfo, type) => {
    if (
      !userInfo
        || !isUserAllowed(userInfo.groups, slsPermissions)
        || window.location.pathname.startsWith('/storelocationservices/stores/detail')
        || window.location.pathname.startsWith('/storelocationservices/digital-stores/detail')
        || window.location.pathname.startsWith('/storelocationservices/warehouses/detail')
    ) {
      return null;
    }
    const fields = [
      'id',
      'storeNumber',
      'name',
      'company',
      'facilityType',
      'businessConcept',
      'storeConcept',
      'address.country',
      'address.city',
      'address.state',
      'region',
      'offerings.name',
      'storeServices.serviceGroup',
      'storeServices.serviceSubGroup',
      'storeStatus',
    ].join(',');

    switch (type) {
      case 'Store': {
        let region;
        if (this.state.regionToLoadStoresFor.value) {
          region = this.state.regionToLoadStoresFor.value;
        } else if (localStorage.getItem('ros-stores-region')) {
          // with an empty regions value, the getStores function will return all regions
          region = localStorage.getItem('ros-stores-region') === 'All Regions' ? '' : localStorage.getItem('ros-stores-region');
        }
        return getStores(userInfo.accessToken, fields, region)
          .then((res) => sortStoresByNumber(res))
          .catch((err) => this.setState({ getError: err.message }));
      }
      case 'Digital Store':
        return getDigitalStores(userInfo.accessToken)
          .then((res) => sortStoresByNumber(res.body.objects))
          .catch((err) => this.setState({ getError: err.message }));
      case 'Warehouse':
        return getWarehouses(userInfo.accessToken)
          .then((res) => restructureWarehouses(res.body.objects))
          .catch((err) => this.setState({ getError: err.message }));
      default:
        return null;
    }
  };

  updateProps = (key, value) => {
    if (key === 'formData') {
      return this.setState({ getError: '', [key]: value });
    }
    return this.setState({ [key]: value });
  };

  viewDigitalStores = async () => this.setState({ getting: true },
    async () => {
      if (!this.props.detailOnly) {
        const allDigitalStores = await this.retrieveFacilities(this.props.userInfo, 'Digital Store');
        return this.setState({
          allDigitalStores, digitalStores: allDigitalStores, getting: false,
        });
      }
      return getDigitalStoreById(this.props.userInfo.accessToken, this.props.match.params.digitalId)
        .then((res) => this.setState({ formData: res.body, getting: false }))
        .catch((err) => this.setState({ getError: err.message, getting: false }));
    });

  viewFacilityType = async () => {
    // get the facilities that we are viewing
    if (this.state.viewingWarehouses) {
      return this.viewWarehouses();
    }
    if (this.state.viewingDigitalStores) {
      return this.viewDigitalStores();
    }
    if (this.state.viewingStores && (this.state.regionToLoadStoresFor || this.props.detailOnly || localStorage.getItem('ros-stores-region'))) {
      return this.viewStores();
    }
    return null;
  };

  viewStores = async () => this.setState({ getting: true },
    async () => {
      if (this.props.detailOnly) {
        await getStoreById(this.props.userInfo.accessToken, this.props.match.params.storeId)
          .then((res) => this.setState({ formData: res.body }))
          .catch((err) => this.setState({ getError: err.message }));
      }
      const eventuallyloadedStores = this.retrieveFacilities(this.props.userInfo, 'Store');
      const eventuallyStoreOfferings = this.state.offeringFormOpen || window.location.pathname.startsWith('/storelocationservices/stores/detail')
        ? getStoreOfferings(this.props.userInfo.accessToken)
          .then((res) => formatStoreOfferingsAttributes(sortOfferings(res.body.objects)))
          .catch((err) => this.setState({ getError: err.message }))
        : [];
      const [loadedStores, storeOfferings] = await Promise.all([eventuallyloadedStores, eventuallyStoreOfferings]);
      return this.setState({
        getting: false, loadedStores, storeOfferings, stores: loadedStores,
      });
    });

  viewWarehouses = async () => this.setState({ getting: true },
    async () => {
      if (!this.props.detailOnly) {
        const allWarehouses = await this.retrieveFacilities(this.props.userInfo, 'Warehouse');
        return this.setState({ allWarehouses, getting: false, warehouses: allWarehouses });
      }
      return getWarehouseById(this.props.userInfo.accessToken, this.props.match.params.warehouseId)
        .then((res) => this.setState({ formData: res.body, getting: false }))
        .catch((err) => this.setState({ getError: err.message, getting: false }));
    });

  render = () => (
    <main className="ncss-col-sm-10 ta-sm-c">
      {window.location.pathname.startsWith('/storelocationservices/warehouses') && (
        <WarehousesMain
          allWarehouses={this.state.allWarehouses || []}
          detailOnly={this.props.detailOnly}
          formData={this.state.formData}
          getError={this.state.getError}
          getting={this.state.getting}
          updateFormData={(formData) => this.updateProps('formData', formData)}
          updateWarehouses={(warehouses) => this.updateProps('warehouses', warehouses)}
          userInfo={this.props.userInfo}
          warehouses={this.state.warehouses || []}
        />
      )}
      {window.location.pathname.startsWith('/storelocationservices/digital-stores') && (
        <DigitalStoresMain
          allDigitalStores={this.state.allDigitalStores || []}
          detailOnly={this.props.detailOnly}
          digitalStores={this.state.digitalStores || []}
          formData={this.state.formData}
          getError={this.state.getError}
          getting={this.state.getting}
          updateDigitalStores={(digitalStores) => this.updateProps('digitalStores', digitalStores)}
          updateFormData={(formData) => this.updateProps('formData', formData)}
          userInfo={this.props.userInfo}
        />
      )}
      {window.location.pathname.startsWith('/storelocationservices/stores') && (
        <StoresMain
          detailOnly={this.props.detailOnly}
          formData={this.state.formData}
          getError={this.state.getError}
          getting={this.state.getting}
          initialRegion={localStorage.getItem('ros-stores-region') ? localStorage.getItem('ros-stores-region') : ''}
          loadStoresForRegion={(region) => this.setState({ regionToLoadStoresFor: region })}
          loadedStores={this.state.loadedStores || []}
          storeOfferings={this.state.storeOfferings || []}
          stores={this.state.stores || []}
          updateFormData={(formData) => this.updateProps('formData', formData)}
          updateStores={(stores) => this.updateProps('stores', stores)}
          userInfo={this.props.userInfo}
        />
      )}
      {/* If someone goes to /storelocationservices manually, give them links to navigate to a valid page */}
      {!window.location.pathname.startsWith('/storelocationservices/warehouses')
      && !window.location.pathname.startsWith('/storelocationservices/digital-stores')
      && !window.location.pathname.startsWith('/storelocationservices/stores')
      && window.location.pathname.startsWith('/storelocationservices')
        && <Links facilityType="" />}
    </main>
  )
}

Container.defaultProps = {
  match: {},
};

Container.propTypes = {
  detailOnly: PropTypes.bool.isRequired,
  match: PropTypes.shape(),
  userInfo: PropTypes.shape({
    accessToken: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInfo: state.authorizationReducer.auth,
});

export default connect(mapStateToProps, null)(Container);
