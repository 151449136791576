import { Checkbox, Loading } from '@nike/frame-component-library';
import { Check } from '@nike/nike-design-system-icons';
import { match, Default } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { deleteStoreOffering, getStoreOfferings } from '../../../../utils/service-calls/sls';
import {
  Table, ButtonBlack, ButtonRed,
} from '../../../reusable';

import OfferingForm from './OfferingForm';

const SUCCESS_STATUS_CODE = 200;

const ViewOffering = ({
  userToken, username,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [offeringData, setOfferingData] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editingOffering, setEditingOffering] = useState();
  const [editingOfferingId, setEditingOfferingId] = useState();
  const [showPublicOfferings, setShowPublicOfferings] = useState(true);
  const [showPrivateOfferings, setShowPrivateOfferings] = useState(true);

  const fetchStoreOfferings = useCallback(() => {
    setFetchError('');
    setIsLoading(true);
    return getStoreOfferings(userToken)
      .then((data) => setOfferingData(data.body.objects))
      .catch((err) => setFetchError(err.message))
      .finally(() => setIsLoading(false));
  }, [userToken]);

  const fetchUpdatedOffering = useCallback(() => {
    getStoreOfferings(userToken)
      .then((data) => setEditingOffering(data.body.objects.find(({ id }) => id === editingOfferingId)))
      .catch((err) => setFetchError(err.message));
  }, [userToken, editingOfferingId]);

  const deleteOffering = ({ id, name }) => {
    setErrorMessage('');
    setSuccessMessage('');
    return deleteStoreOffering(userToken, encodeURIComponent(id))
      .catch((err) => {
        setErrorMessage(err.message);
      })
      .then((data) => {
        setErrorMessage(data?.statusCode === SUCCESS_STATUS_CODE ? '' : (data?.body || `Failed to delete ${name}`));
        setSuccessMessage(data?.statusCode === SUCCESS_STATUS_CODE ? `Successfully Deleted ${name}` : '');
        return fetchStoreOfferings();
      });
  };

  useEffect(() => {
    fetchStoreOfferings();
  }, [fetchStoreOfferings]);

  const getColumns = () => {
    const columns = [
      {
        accessor: 'name',
        Header: 'Offering Name',
      },
      {
        accessor: 'description',
        Header: 'Description',
      },
      {
        Cell: (d) => (d.original.publicOffering ? <section className="flx-jc-sm-c"><Check /></section> : <div />),
        Header: 'Publicly Available',
      },
      {
        Cell: (d) => (<section><img alt="" height="75" src={d.original.serviceImage ? d.original.serviceImage : 'https://static.nike.com/a/images/f_auto/7cb8e7f1-ec92-4827-a7ac-a0d91fffae08/image.png'} style={{ margin: 'auto' }} width="70" /></section>),
        Header: 'Image',
        width: 80,
      },
      {
        Cell: (d) => (d.original.iconUrl ? <section><img alt="" height="75" src={d.original.iconUrl} style={{ margin: 'auto' }} width="70" /></section> : <div />),
        Header: 'Icon',
        width: 80,
      },
      {
        accessor: 'id',
        Header: 'Id',
      },
      {
        accessor: 'externalUrl',
        Header: 'External URL',
      },
      {
        accessor: 'externalUrlText',
        Header: 'Link Text',
      },
      {
        Cell: (d) => (
          <ButtonBlack
            className=""
            label="Edit"
            onClick={() => {
              setEditingOffering(d.original);
              setEditingOfferingId(d.original.id);
            }}
          />
        ),
        Header: 'Edit',
        width: 80,
      },
      {
        Cell: (d) => <ButtonRed className="" confirmationMessage="Deleting this offering will remove the offering from ALL the stores in Store Views. Do you still want to proceed?" label={<i className="g72-x-thick" />} onClick={() => deleteOffering(d.original)} />,
        Header: 'Delete',
        width: 80,
      },
    ];
    return columns;
  };
  return match()(
    [() => isLoading, <Loading />],
    [() => editingOffering, (
      <OfferingForm
        isEditing
        closeOffering={() => {
          setEditingOffering(undefined);
          fetchStoreOfferings();
        }}
        fetchUpdatedOffering={fetchUpdatedOffering}
        offering={editingOffering}
        userToken={userToken}
        username={username}
      />
    )],
    [Default, (
      <article className="ncss-col-sm-10 ta-sm-l"> {
          fetchError
            ? <section>{fetchError}</section>
            : (
              <section>
                {successMessage && (
                  <p className="text-color-success ta-sm-c body-2 mt2-sm">
                    {successMessage}
                  </p>
                )}
                {errorMessage && (
                  <p className="text-color-error ta-sm-c body-2 mt2-sm">
                    {errorMessage}
                  </p>
                )}
                <section className="ncss-row">
                  <section className="ncss-col-sm-3 ncss-col-sm-offset-3 ta-sm-r">
                    <Checkbox
                      id="showPublicOfferings"
                      isChecked={showPublicOfferings}
                      label="Show Public Offerings"
                      onChange={({ target: { checked } }) => setShowPublicOfferings(checked)}
                    />
                  </section>
                  <section className="ncss-col-sm-3">
                    <Checkbox
                      id="showPrivateOfferings"
                      isChecked={showPrivateOfferings}
                      label="Show Private Offerings"
                      onChange={({ target: { checked } }) => setShowPrivateOfferings(checked)}
                    />
                  </section>
                </section>
                <Table
                  fetched
                  columns={getColumns(offeringData)}
                  data={[...offeringData.filter((offering) => (showPublicOfferings ? offering.publicOffering : false)),
                    ...offeringData.filter((offering) => (showPrivateOfferings ? !offering.publicOffering : false))]}
                  defaultPageSize={20}
                />
              </section>
            )
        }
      </article>
    )],
  );
};

ViewOffering.propTypes = {
  username: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default ViewOffering;
