const doris = {
  inputValue: 'DORIS_FAST_REGULAR',
  serviceGroup: 'DORIS',
};
const standardDoris = {
  enabled: false,
  serviceGroup: 'DORIS',
  serviceSubGroup: 'STANDARD_REFUND',
  storeServiceTypeId: (process.env.NODE_ENV === 'production')
    ? 'c3560700-97a7-49bc-9970-8d1bd9635503'
    : '0342c6e2-4dd7-4fa0-807d-a36b9efcc3ce',

};
const fastDoris = {
  enabled: false,
  serviceGroup: 'DORIS',
  serviceSubGroup: 'FAST_REFUND',
  storeServiceTypeId: (process.env.NODE_ENV === 'production')
    ? '0342c6e2-4dd7-4fa0-807d-a36b9efcc3ce'
    : '7b9ef7d5-4f9d-4d9a-9462-2168facd2bad',

};
module.exports = {
  doris, fastDoris, standardDoris,
};
