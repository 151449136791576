import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addAuth } from '../../actions/authorization';
import { authIsCurrent, loadState } from '../../utils/local-storage';
import { oktaAuth } from '../../utils/service-calls/reusable';
import {
  doNotShowTheseGroups,
  getReadOnlyAlternativeGroups,
  getSubGroups,
  isUserAllowed,
  permissionsToGroups,
  rosPermissions,
  subGroupUsages,
} from '../../utils/tab-permissions';

import ButtonWhite from './ButtonWhite';

class PageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      help: false,
      receivedUserInfo: true,
      showUserGroups: false,
      userAllowed: false,
      userGroups: [],
    };
  }

  componentDidMount() {
    this.determineAuthorization(loadState('auth'));
  }

  componentDidUpdate(prevProps) {
    // if you are changing security groups and the currentAuth is no longer valid
    if (prevProps.auth !== this.props.auth && !authIsCurrent(prevProps.userInfo.expiresAt)) {
      // then we need to redetermine that their authentication
      this.determineAuthorization(loadState('auth'));
    }
  }

  determineAuthorization = (localAuth) => {
    // if the local auth is empty, it is expired, and you have to call okta and start again
    if (!localAuth?.expiresAt) {
      this.setState({ receivedUserInfo: false, userAllowed: false });
      oktaAuth(this.props.path);
    // if the local auth exists, it is current, and you have to save it for next time
    } else {
      this.props.addAuth(localAuth);
      /*
      PROD - Allowing all the Nike Employees to access the PROD Env without any ADGroup.
      TEST - User has to be part of the 'App.RetailOperations.Users' ADGroup, because in Dev portal(https://developer.niketech.com/apps/nike.rcffus.retailoperations) we can't allow all the Nike Employees
      */
      this.setState({ receivedUserInfo: true, userAllowed: (this.props.auth === rosPermissions && process.env.NODE_ENV === 'production') ? true : isUserAllowed(localAuth.groups, this.props.auth), userGroups: localAuth.groups });
    }
  };

  getAlternativeGroups = () => {
    const alternativeGroups = getReadOnlyAlternativeGroups(this.props.auth);
    return alternativeGroups ? ` or ${alternativeGroups}` : '';
  };

  render = () => this.state.receivedUserInfo && (
    <main className="ta-sm-c">
      <aside className="ta-sm-r">
        <ButtonWhite
          className="text-color-secondary"
          label={this.state.help ? 'Done' : 'Need Help?'}
          onClick={() => this.setState((prevState) => ({ help: !prevState.help }))}
        />
      </aside>
      <h1 className="display-3 text-color-accent mb3-sm">
        {this.props.title}
      </h1>
      <aside className="ncss-col-sm-6">
        <h3 className="headline-4 mb10-sm">
          {this.props.description}
        </h3>
      </aside>
      <section className="ncss-col-sm-12 ta-sm-c">
        {// eslint-disable-next-line no-nested-ternary
        !this.state.help
          ? (this.state.userAllowed
            ? this.props.page
            : (
              <>
                <p className="body-2">You are in the right place, but you are not authorized to view this page.</p>
                <p className="body-2">In order to view this page, you must be a part of <span className="headline-5">{process.env.NODE_ENV === 'production' ? '' : 'App.RetailOperations.Users'}</span>{permissionsToGroups[this.props.auth] !== 'App.RetailOperations.Users' && (<> {process.env.NODE_ENV === 'production' ? '' : 'and'} <span className="headline-5">{permissionsToGroups[this.props.auth]} {this.getAlternativeGroups()}</span></>)}.</p>
                <p className="body-2 pt4-sm">If you are not a member of the group(s), please contact your manager and have them follow the process linked below:</p>
                <a
                  className="ncss-cta-primary-dark underline text-color-accent pb4-sm"
                  href="https://confluence.nike.com/display/RE/Adding+Users+to+UIs"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Adding Users to UIs
                </a>
                {getSubGroups(this.props.auth).length !== 0 && (
                  <ul className="body-2 pt4-sm"> In order to view and edit data, you also must be a part of one or more of the following groups:
                    {getSubGroups(this.props.auth).filter((subGroup) => !doNotShowTheseGroups.includes(subGroup)).map((subGroup) => (
                      <li key={subGroup} className="ncss-col-sm-12">
                        <article className="ncss-col-sm-6 ta-sm-r">{subGroup}</article>
                        <article className="ncss-col-sm-6 ta-sm-l">{subGroupUsages[subGroup]}</article>
                      </li>
                    ))}
                    <p className="body-2 pt4-sm">Please ask your manager to confirm which groups and then follow the same process in&nbsp;
                      <a
                        className="ncss-cta-primary-dark underline text-color-accent"
                        href="https://confluence.nike.com/display/RE/Adding+Users+to+UIs"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Adding Users to UIs
                      </a>&nbsp;to gain access.
                    </p>
                  </ul>
                )}
                <br />
                <ButtonWhite
                  label={`${this.state.showUserGroups ? 'hide' : 'show'} current user groups`}
                  onClick={() => this.setState((prevState) => ({ showUserGroups: !prevState.showUserGroups }))}
                />
                {this.state.showUserGroups && (
                  <ul className="body-2 pt4-sm pb8-sm">
                    <p className="headline-5">You are currently a member of these groups:</p>
                    {Object.values(this.state.userGroups).sort().map((group) => <li key={group}>{group}</li>)}
                  </ul>
                )}
              </>
            )
          )
          : this.props.help
        }
      </section>
    </main>
  )
}

PageTemplate.defaultProps = {
  userInfo: {},
};

PageTemplate.propTypes = {
  addAuth: PropTypes.func.isRequired,
  auth: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  help: PropTypes.element.isRequired,
  page: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userInfo: PropTypes.shape({
    expiresAt: PropTypes.number,
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};

const mapStateToProps = (state) => ({
  userInfo: state.authorizationReducer.auth,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addAuth,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate);
