import { ModalStyled, NikeDesignSystemProvider } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack } from '../../reusable';

const OrderInfoModal = ({ closeModal, isOpen, orderJSON }) => {
  if (!isOpen) return null;
  const closeOrderModal = () => {
    closeModal();
  };
  const CloseButton = () => (
    <article className="ncss-col-sm-12 ta-sm-c va-sm-t">
      <ButtonBlack
        label="Close"
        onClick={closeOrderModal}
      />
    </article>
  );
  return (
    <>
      <NikeDesignSystemProvider>
        <ModalStyled
          isOpen={isOpen}
          title="Order Information"
        >
          <textarea className="order-json">{JSON.stringify(orderJSON, null, 2)}</textarea>
          <CloseButton />
        </ModalStyled>
      </NikeDesignSystemProvider>
      <div id="modal-root" />
    </>
  );
};

export default OrderInfoModal;

OrderInfoModal.defaultProps = {
  closeModal: () => {},
  isOpen: false,
  orderJSON: {},
};

OrderInfoModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  orderJSON: PropTypes.shape(),
};
