import { requestWrapper } from './reusable';

const config = require('../../config')();

const productAnalyzerGet = (token, type, code, countryCode) => requestWrapper(
  token,
  config.productAnalyzerResource,
  'get',
  { code, countryCode, type },
);

export default productAnalyzerGet;
