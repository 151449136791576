import { Cmd, loop } from 'redux-loop';

import { deviceWaitlineUpdateSuccess } from '../actions/devices';
import {
  TICKETS_FETCH, TICKETS_FETCH_SUCCESS, TICKETS_FETCH_FAILURE,
  ticketsFetchSuccess, ticketsFetchFailure,
  TICKET_DELETE, TICKET_DELETE_SUCCESS, TICKET_DELETE_FAILURE,
  ticketDeleteSuccess, ticketDeleteFailure,
  WAITLINES_FETCH, WAITLINES_FETCH_SUCCESS, WAITLINES_FETCH_FAILURE,
  waitlinesFetchSuccess, waitlinesFetchFailure,
  WAITLINES_SHOW_CREATE_FORM, WAITLINES_HIDE_CREATE_FORM,
  WAITLINE_CREATE, WAITLINE_CREATE_SUCCESS, WAITLINE_CREATE_FAILURE,
  waitlineCreateSuccess, waitlineCreateFailure,
  WAITLINE_SAVE, WAITLINE_SAVE_SUCCESS, WAITLINE_SAVE_FAILURE,
  waitlineSaveSuccess, waitlineSaveFailure,
  WAITLINE_DELETE, WAITLINE_DELETE_SUCCESS, WAITLINE_DELETE_FAILURE,
  waitlineDeleteSuccess, waitlineDeleteFailure,
  WAITLINE_DEVICE_UPDATE_SUCCESS, WAITLINE_DEVICE_UPDATE_FAILURE,
  waitlineDeviceUpdateFailure,
} from '../actions/waitlines';
import { replaceWhere } from '../utils/array';
import { assoc, dissoc, dissocAll } from '../utils/object';
import {
  ticketDelete, ticketsGet,
  waitlineDelete, waitlinesGet, waitlinePost, waitlinePut, waitlinePutToDevices,
} from '../utils/service-calls/devices-and-waitlines';
import { validateWaitlines } from '../utils/validation/devices-and-waitlines';

const initialState = {
  createWaitlineError: null, // error
  createWaitlineRequest: null, // { type: 'CREATE' }
  currentStore: {},
  fetchWaitlinesError: null, // error
  fetchWaitlinesRequest: null, // { type: 'FETCH' }
  showCreateForm: false,
  storeId: null,
  ticketErrors: {}, // { [ticketId]:   error }
  ticketRequests: {}, // { [ticketId]: { type: 'DELETE' } }
  ticketsByWaitline: {}, // { [waitlineId]: ticket[] }
  waitlineErrors: {}, // { [waitlineId]: error }
  waitlineRequests: {}, // { [waitlineId]: { type: 'SAVE' | 'DELETE' } }
  waitlines: [],
  waitlineSaveSuccesses: {}, // { [waitlineId]: success }
  waitlineTicketsErrors: {}, // { [waitlineId]: error }
  waitlineTicketsRequests: {}, // { [waitlineId]: { type: 'FETCH' } }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WAITLINES_FETCH: {
      if (action.storeId !== state.storeId || state.fetchWaitlinesRequest === null) {
        return loop(
          {
            ...state,
            fetchWaitlinesError: null,
            fetchWaitlinesRequest: { type: 'FETCH' },
            storeId: action.storeId,
            ticketErrors: {},
            ticketRequests: {},
            waitlineErrors: {},
            waitlineRequests: {},
            waitlineTicketsErrors: {},
            waitlineTicketsRequests: {},
          },
          Cmd.run(waitlinesGet, {
            args: [action.token, action.storeId],
            failActionCreator: (error) => waitlinesFetchFailure(action.storeId, error),
            successActionCreator: (data) => waitlinesFetchSuccess(action.storeId, data.body),
          }),
        );
      } else {
        return state;
      }
    }
    case WAITLINES_FETCH_SUCCESS: {
      let waitlines = [];
      try {
        waitlines = validateWaitlines(action.waitlines);
      } catch (err) {
        return { ...state, fetchWaitlinesError: err, fetchWaitlinesRequest: null };
      }
      return { ...state, fetchWaitlinesRequest: null, waitlines };
    }
    case WAITLINES_FETCH_FAILURE: {
      return { ...state, fetchWaitlinesError: action.error, fetchWaitlinesRequest: null };
    }
    case WAITLINES_SHOW_CREATE_FORM: {
      return {
        ...state,
        createWaitlineError: null,
        createWaitlineRequest: null,
        showCreateForm: true,
      };
    }
    case WAITLINES_HIDE_CREATE_FORM: {
      return { ...state, showCreateForm: false };
    }
    case WAITLINE_CREATE: {
      if (state.createWaitlineRequest === null) {
        return loop({ ...state, createWaitlineError: null, createWaitlineRequest: { type: 'CREATE' } },
          Cmd.run(waitlinePost, {
            args: [action.token, state.storeId, action.waitlineData],
            failActionCreator: (error) => waitlineCreateFailure(error),
            successActionCreator: (created) => waitlineCreateSuccess(created),
          }));
      } else {
        return state;
      }
    }
    case WAITLINE_CREATE_SUCCESS: {
      return {
        ...state,
        createWaitlineRequest: null,
        showCreateForm: false,
        waitlines: state.waitlines.concat([action.waitline]),
      };
    }
    case WAITLINE_CREATE_FAILURE: {
      return { ...state, createWaitlineError: action.error, createWaitlineRequest: null };
    }
    case WAITLINE_SAVE: {
      if (!(action.waitline.id in state.waitlineRequests)) {
        return loop({
          ...state,
          waitlineErrors: dissoc(state.waitlineErrors, action.waitline.id),
          waitlineRequests: assoc(state.waitlineRequests, action.waitline.id, { type: 'SAVE' }),
          waitlineSaveSuccesses: {},
        },
        Cmd.list([
          Cmd.run(waitlinePut, {
            args: [action.token, action.waitline],
            failActionCreator: (error) => waitlineSaveFailure(error),
            successActionCreator: () => waitlineSaveSuccess(action.waitline),
          }),
          action.device
            ? Cmd.run(waitlinePutToDevices, {
              args: [action.token, action.waitline, action.device],
              failActionCreator: (error) => waitlineDeviceUpdateFailure(action.waitline, action.device, error),
              successActionCreator: () => deviceWaitlineUpdateSuccess(action.device),
            })
            : Cmd.none,
        ]));
      } else {
        return state;
      }
    }
    case WAITLINE_SAVE_SUCCESS: {
      return {
        ...state,
        waitlineRequests: dissoc(state.waitlineRequests, action.waitline.id),
        waitlines: replaceWhere(state.waitlines, (item) => item.id === action.waitline.id, action.waitline),
        waitlineSaveSuccesses: assoc(state.waitlineSaveSuccesses, action.waitline.id, 'Waitline successfully updated.'),
      };
    }
    case WAITLINE_SAVE_FAILURE: {
      return {
        ...state,
        waitlineErrors: assoc(state.waitlineErrors, action.waitline.id, action.error),
        waitlineRequests: dissoc(state.waitlineRequests, action.waitline.id),
        waitlineSaveSuccesses: dissoc(state.waitlineRequests, action.waitline.id),
      };
    }
    case WAITLINE_DEVICE_UPDATE_SUCCESS: {
      return { ...state, waitlines: replaceWhere(state.waitlines, (w) => w.id === action.waitline.id, action.waitline) };
    }
    case WAITLINE_DEVICE_UPDATE_FAILURE: {
      // TODO: tell the user
      return state;
    }
    case WAITLINE_DELETE: {
      return loop({ ...state, waitlineErrors: dissoc(state.waitlineErrors, action.waitlineId), waitlineRequests: assoc(state.waitlineRequests, action.waitlineId, { type: 'DELETE' }) },
        Cmd.run(waitlineDelete, {
          args: [action.token, action.waitlineId],
          // Ignore 404 errors, since we just care that the resource is gone.
          failActionCreator: (error) => (error.statusCode !== 404
            ? waitlineDeleteFailure(action.waitlineId, error)
            : { ...state, waitlineRequests: dissoc(state.waitlineRequests, action.waitlineId) }),

          successActionCreator: () => waitlineDeleteSuccess(action.waitlineId),
        }));
    }
    case WAITLINE_DELETE_SUCCESS: {
      return { ...state, waitlineRequests: dissoc(state.waitlineRequests, action.waitlineId), waitlines: state.waitlines.filter((item) => item.id !== action.waitlineId) };
    }
    case WAITLINE_DELETE_FAILURE: {
      return { ...state, waitlineErrors: assoc(state.waitlineErrors, action.waitlineId, action.error), waitlineRequests: dissoc(state.waitlineRequests, action.waitlineId) };
    }
    case TICKETS_FETCH: {
      if (!(action.waitlineId in state.waitlineTicketsRequests)) {
        const ticketIdsToBeReset = (state.ticketsByWaitline[action.waitlineId] || []).map((ticket) => ticket.id);
        return loop(
          {
            ...state,
            ticketErrors: dissocAll(state.ticketErrors, ticketIdsToBeReset),
            ticketRequests: dissocAll(state.ticketRequests, ticketIdsToBeReset),
            waitlineTicketsErrors: dissoc(state.waitlineTicketsErrors, action.waitlineId),
            waitlineTicketsRequests: assoc(state.waitlineTicketsRequests, action.waitlineId, { type: 'FETCH' }),
          },
          Cmd.run(ticketsGet, {
            args: [action.token, action.waitlineId],
            failActionCreator: (error) => ticketsFetchFailure(action.waitlineId, error),
            successActionCreator: (data) => ticketsFetchSuccess(action.waitlineId, data.body.list),
          }),
        );
      } else {
        return state;
      }
    }
    case TICKETS_FETCH_SUCCESS: {
      return { ...state, ticketsByWaitline: assoc(state.ticketsByWaitline, action.waitlineId, action.tickets), waitlineTicketsRequests: dissoc(state.waitlineTicketsRequests, action.waitlineId) };
    }
    case TICKETS_FETCH_FAILURE: {
      return { ...state, waitlineTicketsErrors: assoc(state.waitlineTicketsErrors, action.waitlineId, action.error), waitlineTicketsRequests: dissoc(state.waitlineTicketsRequests, action.waitlineId) };
    }
    case TICKET_DELETE: {
      if (!(action.ticketId in state.ticketRequests)) {
        return loop({ ...state, ticketErrors: dissoc(state.ticketErrors, action.ticketId), ticketRequests: assoc(state.ticketRequests, action.ticketId, { type: 'DELETE' }) },
          Cmd.run(ticketDelete, {
            args: [action.token, action.ticketId],
            // Ignore 404 errors, since we just care that the resource is gone.
            failActionCreator: (error) => (error.statusCode !== 404
              ? ticketDeleteFailure(action.ticketId, error)
              : { ...state, ticketRequests: dissoc(state.ticketRequests, action.ticketId) }),

            successActionCreator: () => ticketDeleteSuccess(action.ticketId, action.waitlineId),
          }));
      } else {
        return state;
      }
    }
    case TICKET_DELETE_SUCCESS: {
      const findTicketIndex = (waitline, ticketId) => {
        const index = waitline.findIndex((t) => t.id === ticketId);
        return (index >= 0) ? index : undefined;
      };
      const index = findTicketIndex(state.ticketsByWaitline[action.waitlineId], action.ticketId);
      const newTicketsList = [...state.ticketsByWaitline[action.waitlineId]].splice(index, 1);
      return { ...state, ticketRequests: dissoc(state.ticketRequests, action.ticketId), ticketsByWaitline: assoc(state.ticketsByWaitline, action.waitlineId, newTicketsList) };
    }
    case TICKET_DELETE_FAILURE: {
      return { ...state, ticketErrors: assoc(state.ticketErrors, action.ticketId, action.error) };
    }
    default:
      return state;
  }
};

export default reducer;
