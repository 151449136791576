{
    "lastA11yAudit": "",
    "name": "Datatip",
    "docs": "#/components/Datatip",
    "description": "The Datatip displays additional information about the data point, such as its value, label, category, or description",
    "figma": "",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Datatip",
    "tags": ["datatip"],
    "designStatuses": ["Beta"],
    "devStatuses": ["Beta"]
}
