import React from 'react';

import { loadState } from '../../utils/local-storage';
import { Select, StoreSelect } from '../reusable';

import BOPIS from './bopis';
import bopis from './bopis/constants';
import DORIS from './doris';
import { doris } from './doris/constants';
import ShipFromStore from './sfs';
import { sfs } from './sfs/constants';

export const fulfillmentOptions = [
  { label: bopis.serviceGroup, value: bopis.storeServiceTypeId },
  { label: doris.serviceGroup, value: doris.inputValue },
  { label: sfs.serviceGroup, value: sfs.storeServiceTypeId },
];

class SelectFulfillment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fulfillmentOption: '',
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
    };
  }

  getFulfillmentOptions = () => fulfillmentOptions?.filter((option) => {
    if (
      ['NIKE_STORE_EXTENSION', 'POPUP_STORE'].includes(this.state.selectedStore?.facilityType)
    ) {
      return option.label !== doris.serviceGroup;
    } else return option;
  });

  selectCountry = (selectedCountry) => this.setState({
    fulfillmentOption: '',
    selectedCountry,
    selectedStore: null,
  });

  selectStore = async (selectedStore) => this.setState({
    fulfillmentOption: '',
    selectedStore,
  });

  render = () => (
    <main className="ncss-col-sm-10">
      <article className="ncss-col-sm-10">
        <StoreSelect
          selectCountry={this.selectCountry}
          selectStore={this.selectStore}
          selectedCountry={this.state.selectedCountry}
          selectedStore={this.state.selectedStore}
          storeviewsFields={['id', 'address.country', 'storeNumber']}
        />
      </article>
      {this.state.selectedStore && (
        <Select
          className="ncss-col-sm-4 ta-s-c mt8-sm"
          id="roles"
          label="Fulfillment Options"
          options={this.getFulfillmentOptions()}
          value={this.state.fulfillmentOption}
          onChange={(fulfillmentOption) => {
            this.setState({ fulfillmentOption });
          }}
        />
      )}
      <article className="ncss-col-sm-10 ta-s-c mt8-sm mb10-sm">
        {this.state.fulfillmentOption === sfs.storeServiceTypeId && (
          <ShipFromStore
            selectedStore={this.state.selectedStore}
          />
        )}
        {this.state.fulfillmentOption === bopis.storeServiceTypeId && (
          <BOPIS
            selectedStore={this.state.selectedStore}
          />
        )}
        {this.state.fulfillmentOption === doris.inputValue && (
          <DORIS
            selectedStore={this.state.selectedStore}
          />
        )}
      </article>
    </main>

  );
}

export default SelectFulfillment;
