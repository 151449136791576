import { requestWrapper } from './reusable';

const config = require('../../config')();

// eslint-disable-next-line import/prefer-default-export
export const csvPost = (token, csv) => requestWrapper(
  token,
  config.taxIdentifiersResource,
  'post',
  undefined,
  csv,
);
