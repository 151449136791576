import { requestWrapper } from './reusable';

const config = require('../../config')();

/* STORE SERVICES */

export const createStoreService = (token, body) => requestWrapper(
  token,
  config.storeServicesResource,
  'post',
  undefined,
  body,
);

export const fetchStoreServices = (token, storeId) => requestWrapper(
  token,
  config.storeServicesResource,
  'get',
  { storeId },
);

export const updateStoreService = (token, storeServiceId, body) => requestWrapper(
  token,
  config.storeServicesResource,
  'put',
  { storeServiceId },
  body,
);

/* STORE SERVICE TYPES */

export const fetchStoreServiceTypes = (token) => requestWrapper(
  token,
  config.storeServicesTypesResource,
  'get',
);

/* TOPICS */

export const createTopic = (token, body) => requestWrapper(
  token,
  config.storeServicesTopicsResource,
  'post',
  undefined,
  body,
);

export const fetchTopics = (token, storeId) => requestWrapper(
  token,
  config.storeServicesTopicsResource,
  'get',
  { storeId },
);

export const updateTopic = (token, body) => requestWrapper(
  token,
  config.storeServicesTopicsResource,
  'patch',
  undefined,
  body,
);
