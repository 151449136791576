import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack, ButtonRed, DateInput } from '../../../reusable';

import OpenDuration from './OpenDuration';

const SpecialHours = (props) => (
  <section className="ncss-col-sm-12">
    <header className="headline-5 pt2-sm">Special Hours</header>
    {props.specialHours.map((specialHour, specialHourIndex) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <article key={specialHourIndex} className="ncss-row">
        <DateInput
          className="ncss-col-sm-2 va-sm-t"
          id={JSON.stringify(specialHour)}
          isDisabled={props.userIsReadOnly}
          label="Date"
          value={specialHour.date || ''}
          onChange={(specialDate) => {
            props.updateDate(specialHourIndex, specialDate);
          }}
        />
        {specialHour.hours.length > 0
          ? specialHour.hours.map((openDuration, openDurationIndex) => (
            <OpenDuration
              key={JSON.stringify(openDuration)}
              close={(checked) => props.updateHours(specialHourIndex, checked ? [] : [{ localCloseTime: '22:00', localOpenTime: '08:00' }])}
              index={specialHourIndex}
              localCloseTime={openDuration.localCloseTime || ''}
              localOpenTime={openDuration.localOpenTime || ''}
              updateHours={(prop, value) => {
                let dayHours = props.specialHours[specialHourIndex].hours;
                if (Array.isArray(dayHours) && openDurationIndex === undefined) {
                  dayHours = [{ ...props.specialHours[specialHourIndex].hours[0], [prop]: value }];
                } else if (openDurationIndex === undefined) {
                  dayHours = [({ [prop]: value })];
                } else {
                  dayHours[openDurationIndex] = {
                    ...props.specialHours[specialHourIndex].hours[openDurationIndex],
                    [prop]: value,
                  };
                }
                props.updateHours(specialHourIndex, dayHours);
              }}
              userIsReadOnly={props.userIsReadOnly}
            />
          ))
          : (
            <OpenDuration
              close={(checked) => props.updateHours(specialHourIndex, checked ? [] : [{ localCloseTime: '22:00', localOpenTime: '08:00' }])}
              closed={specialHour.hours.length === 0}
              localCloseTime=""
              localOpenTime=""
              updateHours={(prop, value) => {
                const dayHours = [({ [prop]: value })];
                props.updateHours(specialHourIndex, dayHours);
              }}
              userIsReadOnly={props.userIsReadOnly}
            />
          )}
        <ButtonRed
          className="mt6-sm"
          isDisabled={props.userIsReadOnly}
          label={<i className="g72-x-thick" />}
          onClick={() => props.popSpecialHour(specialHourIndex)}
        />
      </article>
    ))}
    <p className="text-color-error body-4 mt2-sm">{props.errors}</p>
    <ButtonBlack
      className="mt2-sm"
      isDisabled={props.userIsReadOnly}
      label="Add Special Hour"
      onClick={() => {
        const indexOfLast = props.specialHours.length - 1;
        if (indexOfLast === -1
          || (props.specialHours[indexOfLast].date !== ''
            && props.specialHours[indexOfLast].date !== undefined
            && props.specialHours[indexOfLast].date !== null)
        ) {
          props.pushSpecialHour();
        }
      }}
    />
  </section>
);

SpecialHours.propTypes = {
  errors: PropTypes.string.isRequired,
  popSpecialHour: PropTypes.func.isRequired,
  pushSpecialHour: PropTypes.func.isRequired,
  specialHours: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateDate: PropTypes.func.isRequired,
  updateHours: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default SpecialHours;
