/* eslint-disable react/forbid-prop-types */
import { Default, isSome, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

import { Select } from '../reusable';

import FourPartKeyIds from './FourPartKeyIds';
import SinglePartIds from './SinglePartIds';

const getQueryTypeLabel = (queryType) => match(queryType)(
  ['FOUR_PART_ID', () => 'Four-Part ID'],
  ['ORDER_ID', () => 'Order ID'],
  ['TRANSACTION_ID', () => 'Transaction ID'],
  [Default, () => ''],
);

const QUERY_TYPE_OPTIONS = [
  'FOUR_PART_ID',
  'ORDER_ID',
  'TRANSACTION_ID',
].map((value) => ({ label: getQueryTypeLabel(value), value }));

const getRegionLabel = (region) => match(region)(
  ['CHINA', () => 'China'],
  ['GLOBAL', () => 'Global'],
  [Default, () => ''],
);

const REGION_OPTIONS = [
  'CHINA',
  'GLOBAL',
].map((value) => ({ label: getRegionLabel(value), value }));

const CheckIngestStatus = ({
  handleQueryTypeChange,
  queryType,
  setTransactionIds,
  transactionIds,
  createBlankQueryId,
  setCheckingIngestStatus,
  maxRows,
  region,
  handleRegionChange,
  checkIngestStatusError,
}) => (
  <div className="ncss-container p4-sm">
    { checkIngestStatusError !== '' && (
      <div className="ncss-row">
        <div className="ncss-col-sm-12 text-color-error">{checkIngestStatusError}</div>
      </div>
    )}
    <h3 className="headline-3">Check Ingest Status</h3>
    <div className="ncss-row">
      <Select
        className="ncss-col-sm-2"
        errorMessage=""
        id="region"
        label="Region"
        options={REGION_OPTIONS}
        value={region}
        zIndex={10}
        onChange={handleRegionChange}
      />
    </div>
    { isSome(region) && (
      <div className="ncss-row">
        <Select
          className="ncss-col-sm-2"
          errorMessage=""
          id="queryType"
          label="Query Type"
          options={QUERY_TYPE_OPTIONS}
          value={queryType}
          zIndex={5}
          onChange={handleQueryTypeChange}
        />
      </div>
    )}

    {queryType === 'FOUR_PART_ID' && (
      <FourPartKeyIds
        createBlankQueryId={createBlankQueryId}
        maxRows={maxRows}
        setCheckingIngestStatus={setCheckingIngestStatus}
        setTransactionIds={setTransactionIds}
        transactionIds={transactionIds}
      />
    )}
    { ['ORDER_ID', 'TRANSACTION_ID'].includes(queryType) && (
      <SinglePartIds
        createBlankQueryId={createBlankQueryId}
        maxRows={maxRows}
        queryTypeLabel={getQueryTypeLabel(queryType)}
        setCheckingIngestStatus={setCheckingIngestStatus}
        setTransactionIds={setTransactionIds}
        transactionIds={transactionIds}
      />
    )}
  </div>
);

CheckIngestStatus.defaultProps = {
  queryType: null,
  region: null,
};

CheckIngestStatus.propTypes = {
  checkIngestStatusError: PropTypes.string.isRequired,
  createBlankQueryId: PropTypes.func.isRequired,
  handleQueryTypeChange: PropTypes.func.isRequired,
  handleRegionChange: PropTypes.func.isRequired,
  maxRows: PropTypes.number.isRequired,
  queryType: PropTypes.string,
  region: PropTypes.string,
  setCheckingIngestStatus: PropTypes.func.isRequired,
  setTransactionIds: PropTypes.func.isRequired,
  transactionIds: PropTypes.array.isRequired,
};

export default CheckIngestStatus;
