import PropTypes from 'prop-types';
import React from 'react';
import {
  Tabs, Tab, TabList, TabPanel,
} from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

import FormCsvTab from './FormCsvTab';

const PatchTabs = (props) => (
  <Tabs onSelect={(index) => props.handleEntryChange(index === 0)}>
    <TabList>
      <Tab>Style Color</Tab>
      <Tab>GTIN</Tab>
    </TabList>
    <TabPanel>
      <FormCsvTab
        codes={props.codes}
        couponDiscountEnabled={props.couponDiscountEnabled}
        employeeDiscountCode={props.employeeDiscountCode}
        formErrors={props.formErrors}
        handleCodeChange={props.handleCodeChange}
        handleFileUpload={props.handleFileUpload}
        handleFormChange={props.handleFormChange}
        handleUploadTypeChange={props.handleUploadTypeChange}
        id="Style Color"
        itemDiscountCode={props.itemDiscountCode}
        priceOverrideEnabled={props.priceOverrideEnabled}
        promotionEnabled={props.promotionEnabled}
        thresholdDiscountCode={props.thresholdDiscountCode}
        transactionDiscountCode={props.transactionDiscountCode}
      />
    </TabPanel>
    <TabPanel>
      <FormCsvTab
        codes={props.codes}
        couponDiscountEnabled={props.couponDiscountEnabled}
        employeeDiscountCode={props.employeeDiscountCode}
        formErrors={props.formErrors}
        handleCodeChange={props.handleCodeChange}
        handleFileUpload={props.handleFileUpload}
        handleFormChange={props.handleFormChange}
        handleUploadTypeChange={props.handleUploadTypeChange}
        id="GTIN"
        itemDiscountCode={props.itemDiscountCode}
        priceOverrideEnabled={props.priceOverrideEnabled}
        promotionEnabled={props.promotionEnabled}
        thresholdDiscountCode={props.thresholdDiscountCode}
        transactionDiscountCode={props.transactionDiscountCode}
      />
    </TabPanel>
  </Tabs>
);

PatchTabs.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  couponDiscountEnabled: PropTypes.string.isRequired,
  employeeDiscountCode: PropTypes.string.isRequired,
  formErrors: PropTypes.shape().isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  handleEntryChange: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleUploadTypeChange: PropTypes.func.isRequired,
  itemDiscountCode: PropTypes.string.isRequired,
  priceOverrideEnabled: PropTypes.string.isRequired,
  promotionEnabled: PropTypes.string.isRequired,
  thresholdDiscountCode: PropTypes.string.isRequired,
  transactionDiscountCode: PropTypes.string.isRequired,
};

export default PatchTabs;
