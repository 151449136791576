import PropTypes from 'prop-types';
import React from 'react';
import './FinalShortlinks.css';

const FinalShortlinks = (props) => {
  if (!props.data || !props.data.length) {
    return <div> No data</div>;
  } return (
    <>
      <article className="ncss-row">
        <section className="ncss-col-sm-12 mt4-sm va-sm-b ta-sm-l pb6-sm">
          <p className="mt4-sm mb3-sm headline-5"> Final Shortlink URLs </p>
          <div className="rectangle">
            <ul data-testid="url">
              {props.data.map(({ url }) => (
                <li key={url} className="body-3 pt3-sm pb3-sm prl5-sm" data-testid="urls">{url}</li>
              ))}
            </ul>
          </div>
        </section>
      </article>
    </>
  );
};

FinalShortlinks.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ).isRequired,
};
export default FinalShortlinks;
