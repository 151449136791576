/* eslint-disable no-await-in-loop */
export const trampoline = <T>(f: Function) => (...args: any[]): T => {
  let result: any = f(...args);
  while (typeof result === 'function') {
    result = result();
  }
  return result;
};

export const trampolineAsync = (f: Function) => async<T>(...args: any[]): Promise<T> => {
  let result: any = await f(...args);
  while (typeof result === 'function') {
    result = await result();
  }
  return result;
};
