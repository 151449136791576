import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Authorize from './components/Authorize';
import Authorized from './components/Authorized';
import CCM from './components/ccm';
import DCNAudit from './components/dcn-audit';
// import DirectiveAnalyzer from './components/directive-analyzer';
import GeneratePriceDCN from './components/generate-price-dcn';
import GenerateProductDCN from './components/generate-product-dcn';
import Home from './components/Home';
import O2OCapabilities from './components/o2o-capabilities';
import O2OOrderLookup from './components/o2o-order-lookup';
import Patch from './components/patch';
import POSReasons from './components/posreasons';
import PrinterPortal from './components/printer-portal';
import ProductAnalyzer from './components/product-analyzer';
import QRCodeGenerator from './components/qr-code-generator';
import RegisterLevelTracking from './components/registerleveltracking';
import RouteInvalid from './components/RouteInvalid';
import SCS from './components/scs';
import SCSConfigbyKey from './components/scs/config-by-key';
import SideBar from './components/SideBar';
import SLS from './components/sls';
import SNITransactionSearch from './components/sni-transaction-search';
import StoreHierarchy from './components/store-hierarchy';
import StoreServices from './components/store-services';
import TaxIdentifiers from './components/tax-identifiers';
import Traffic from './components/traffic';
import TransactionAnalyzer from './components/transaction-analyzer';
import Unauthorized from './components/Unauthorized';
import Waitlines from './components/waitlines';
import Zones from './components/zones';
import store from './store';

const App = () => (
  <Router>
    <Provider store={store}>
      <Route component={(props) => <SideBar history={props.history} location={props.location} />} />
      <main style={{ marginLeft: '55px' }}>
        <header className="bg-black ta-sm-c va-sm-m pt3-sm pb3-sm" id="main">
          <a aria-label="retail operations header" className="display-2 text-color-accent" href="/">Retail Operations</a>
        </header>
        <Switch>
          <Route exact component={Authorize} path="/" />
          <Route exact component={Authorized} path="/auth" />
          <Route exact component={Home} path="/home" />
          <Route exact component={DCNAudit} path="/dcnaudit" />
          <Route exact component={Waitlines} path="/waitlines" />
          <Route exact component={GeneratePriceDCN} path="/generatepricedcn" />
          <Route exact component={GenerateProductDCN} path="/generateproductdcn" />
          <Route exact component={O2OCapabilities} path="/onlinetooffline" />
          <Route exact component={O2OOrderLookup} path="/onlinetooffline/orderlookup" />
          <Route exact component={O2OOrderLookup} path="/onlinetooffline/orderlookup/:orderIdentifier" />
          <Route exact component={Patch} path="/patch" />
          <Route exact component={POSReasons} path="/posreasons" />
          <Route exact component={PrinterPortal} path="/printerportal" />
          <Route exact component={ProductAnalyzer} path="/productanalyzer" />
          <Route exact component={QRCodeGenerator} path="/qrcodegenerator*" />
          <Route exact component={RegisterLevelTracking} path="/registerleveltracking" />
          <Route exact component={SCS} path="/storeconfigservice/managestoreconfig" />
          <Route exact component={SCSConfigbyKey} path="/storeconfigservice/searchconfigbykey" />
          <Route exact component={(props) => <SLS detailOnly history={props.history} location={props.location} match={props.match} />} path="/storelocationservices/digital-stores/detail/:digitalId" />
          <Route exact component={(props) => <SLS detailOnly history={props.history} location={props.location} match={props.match} />} path="/storelocationservices/stores/detail/:storeId" />
          <Route exact component={(props) => <SLS detailOnly history={props.history} location={props.location} match={props.match} />} path="/storelocationservices/warehouses/detail/:warehouseId" />
          <Route exact component={SLS} path="/storelocationservices*" />
          <Route exact component={CCM} path="/commonconfigmanager/config" />
          <Route exact component={SNITransactionSearch} path="/snitransactionsearch" />
          <Route exact component={StoreHierarchy} path="/storehierarchy" />
          <Route exact component={StoreServices} path="/storeservices" />
          <Route exact component={TaxIdentifiers} path="/taxidentifiers" />
          <Route exact component={Traffic} path="/traffic" />
          <Route exact component={TransactionAnalyzer} path="/transactionanalyzer" />
          <Route exact component={Zones} path="/zones" />
          <Route exact component={Unauthorized} path="/unauthorized" />
          <Route component={RouteInvalid} />
        </Switch>
      </main>
    </Provider>
  </Router>
);

App.defaultProps = {
  history: {},
  location: {},
  match: {},
};

App.propTypes = {
  history: PropTypes.shape(),
  location: PropTypes.shape(),
  match: PropTypes.shape(),
};

export default App;
