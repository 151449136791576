import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import zoneTypes from '../../../utils/static/zone-types';
import { Select } from '../../reusable';

const options = zoneTypes.map((zoneType) => ({
  label: zoneType,
  value: zoneType,
}));

const Fields = (props) => (
  <section className="ncss-row mb2-sm">
    <article className="ncss-col-sm-6 va-sm-t">
      <Input
        errorMessage={props.formErrors.zoneName}
        label="Name"
        value={props.zoneName}
        onChange={({ target: { value } }) => props.updateState('zoneName', value)}
      />
    </article>
    <article className="ncss-col-sm-6 va-sm-t">
      <Input
        errorMessage={props.formErrors.zoneDescription}
        label="Description"
        value={props.zoneDescription}
        onChange={({ target: { value } }) => props.updateState('zoneDescription', value)}
      />
    </article>
    <article className="ncss-col-sm-6 va-sm-t">
      <Input
        className="ncss-input prl4-sm"
        errorMessage={props.formErrors.floorNumber}
        id="floorNumber"
        label="Floor Number"
        min="0"
        step="1"
        type="number"
        value={props.floorNumber}
        onChange={({ target: { value } }) => props.updateState('floorNumber', value)}
      />
    </article>
    <article className="ncss-col-sm-6 va-sm-t">
      <Select
        errorMessage={props.formErrors.zoneType}
        id="zoneType"
        label="Zone Type"
        options={options}
        value={props.zoneType}
        onChange={(value) => props.updateState('zoneType', value)}
      />
    </article>
  </section>
);

Fields.defaultProps = {
  zoneDescription: '',
  zoneName: '',
  zoneType: '',
};

Fields.propTypes = {
  floorNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateState: PropTypes.func.isRequired,
  zoneDescription: PropTypes.string,
  zoneName: PropTypes.string,
  zoneType: PropTypes.string,
};

export default Fields;
