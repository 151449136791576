import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { loadState } from '../../utils/local-storage';
import productAnalyzerGet from '../../utils/service-calls/product-analyzer';
import { rosPermissions } from '../../utils/tab-permissions';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonSubmit, Creatable, PageTemplate, Select, StoreSelect,
} from '../reusable';

const options = [
  { label: 'GTIN', value: 'gtin' },
  { label: 'Style Color', value: 'productCode' },
];

/* EXAMPLE COMBINATION:
 * country = Russian Federation
 * code type = GTIN
 * Product Code = 00887791365668
 */

class ProductAnalyzer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      errorMessage: '',
      fetched: false,
      fetching: false,
      formErrors: {
        code: REQUIRED_FIELD,
        selectedCountry: '',
      },
      result: {},
      selectedCodeType: options[0].value,
      selectedCountry: loadState('auth')?.country,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  formSubmit = (selectedCodeType, selectedCountry, code) => {
    this.setState({ fetching: true, result: {} });
    productAnalyzerGet(this.props.accessToken, selectedCodeType, code, selectedCountry)
      .then((result) => this.setState({
        code: null, fetched: true, fetching: false, result: result.body,
      }))
      .catch((error) => this.setState({ errorMessage: error.message, fetched: true, fetching: false }));
  };

  handleCodeChange = (value) => this.setState({ code: value });

  selectCodeType = (selectedCodeType) => this.setState({ code: null, selectedCodeType });

  selectCountry = (selectedCountry) => this.setState({ selectedCountry });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <PageTemplate
      auth={rosPermissions}
      description="Identify any product issues (Not On File, Zero MSRP) and find the corresponding ServiceNow assignment group."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Product+Analyzer+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-row">
          <StoreSelect
            isRequired
            noStores
            formErrors={this.state.formErrors}
            selectCountry={this.selectCountry}
            selectStore={() => { }}
            selectedCountry={this.state.selectedCountry}
          />
          <Select
            className="ncss-col-sm-6 ta-sm-l"
            id="codeSelect"
            label="Code Type"
            options={options}
            value={this.state.selectedCodeType}
            zIndex={11}
            onChange={this.selectCodeType}
          />
          <br />
          <Creatable
            errorMessage={this.state.formErrors.code}
            label={this.state.selectedCodeType === 'gtin' ? 'GTIN' : 'Style Color'}
            value={this.state.code}
            width={6}
            onChange={(code) => this.handleCodeChange(code)}
          />
          <ButtonSubmit
            isDisabled={!this.state.selectedCountry || (!this.state.code || this.state.code.length === 0)}
            isLoading={this.state.fetching}
            submitError={this.state.errorMessage}
            onClick={() => this.formSubmit(this.state.selectedCodeType, this.state.selectedCountry.value, this.state.code.value)}
          />
          <footer className="ncss-col-sm-8 mt2-sm">
            <p className="body-1 text-color-accent">{this.state.result?.gpcrProduct && `Product Name: ${this.state.result.gpcrProduct.name}`}</p>
            {(this.state.result && this.state.result.reports) && this.state.result.reports.map((report) => (
              <article key={report.message} className="body-1 ta-sm-l">
                {report.service && <>Service: <p className="body-3 ml4-sm">{report.service}</p></>}
                <>Code: <p className="body-3 ml4-sm">{report.code}</p></>
                {report.actionSuggested && <>Action Suggested: <p className="body-3 ml4-sm">{report.actionSuggested}</p></>}
                <>Message: <p className="body-3 ml4-sm">{report.message}</p></>
              </article>
            ))}
          </footer>
        </main>
      )}
      path={this.props.location.pathname}
      title="Product Analyzer"
    />
  );
}

ProductAnalyzer.defaultProps = {
  accessToken: undefined,
};

ProductAnalyzer.propTypes = {
  accessToken: PropTypes.string,
  location: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(ProductAnalyzer);
