import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonBlack, ButtonRed } from '../../reusable';

const ReplaceOptions = ({ record, onClickReplace, onClickCancel }) => (
  record.isProcessing
    ? <section className="ncss-row full-width"><article className="ncss-col-sm-6 ta-sm-r va-sm-t"><Loading isNotCentered /></article><article className="ncss-col-sm-6 ta-sm-l">Updating...</article></section>
    : (
      <>
        <ButtonRed
          className="m1-sm"
          confirmationMessage="Replacing will de-assign the Workstation Number from the existing device. Do you want to proceed ?"
          label="Replace"
          onClick={() => onClickReplace(record)}
        />
        <ButtonBlack
          className="m1-sm"
          label="Cancel"
          onClick={() => onClickCancel(record, 'update')}
        />
      </>
    )
);

ReplaceOptions.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickReplace: PropTypes.func.isRequired,
  record: PropTypes.shape().isRequired,
};

export default ReplaceOptions;
