"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadErrorType = exports.Environment = void 0;
var Environment;
(function (Environment) {
    Environment["test"] = "test";
    Environment["prod"] = "prod";
})(Environment = exports.Environment || (exports.Environment = {}));
var UploadErrorType;
(function (UploadErrorType) {
    UploadErrorType["validation"] = "validation";
    UploadErrorType["default"] = "default";
})(UploadErrorType = exports.UploadErrorType || (exports.UploadErrorType = {}));
