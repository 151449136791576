import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { dateAddDay } from '../../utils/formatting';
import { createStoreService, updateStoreService } from '../../utils/service-calls/store-services';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import { ButtonSubmit, DateInput, Select } from '../reusable';

class StoreServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableFrom: props.currentService.availableFrom || (new Date(Date.now())).toISOString(),
      availableUntil: props.currentService.availableUntil || (new Date(Date.now())).toISOString(),
      errorMessage: '',
      formErrors: {
        availableFrom: '', // this is required but we default it above
        name: props.currentService.name ? '' : REQUIRED_FIELD,
        storeServiceTypeId: props.currentService.storeServiceTypeId ? '' : REQUIRED_FIELD,
      },
      isAdding: props.adding,
      loading: false,
      name: props.currentService.name || '',
      storeServiceTypeId: props.currentService.storeServiceTypeId || '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  isDisabled = () => !(this.state.name && this.state.availableFrom && this.state.storeServiceTypeId);

  onSubmit = () => this.setState({ errorMessage: '', loading: true, successMessage: '' },
    () => {
      const toSend = {
        availableFrom: this.state.availableFrom,
        availableUntil: this.state.availableUntil,
        name: this.state.name,
        storeId: this.props.storeId,
        storeServiceTypeId: this.state.storeServiceTypeId,
      };
      if (this.state.availableUntil === null) {
        delete toSend.availableUntil;
      }
      if (this.state.isAdding) {
        return createStoreService(this.props.accessToken, toSend)
          .then(() => this.setState({ errorMessage: '', successMessage: `Store Service "${toSend.name}" was successfully created.` }))
          .catch((error) => this.setState({ errorMessage: `Store Services Error: ${error.message}`, successMessage: '' }))
          .finally(() => this.setState({ loading: false }));
      } else {
        return updateStoreService(this.props.accessToken, this.props.currentService.id, toSend)
          .then(() => this.setState({ errorMessage: '', successMessage: `Store Service "${toSend.name}" was successfully updated.` }))
          .catch((error) => this.setState({ errorMessage: `Store Services Error: ${error.message}`, successMessage: '' }))
          .finally(() => this.setState({ loading: false }));
      }
    });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState({ formErrors });
  };

  render = () => (
    <article className="ncss-col-sm-8 ta-sm-c mb4-sm">
      <section className="ncss-col-sm-12 border pb2-sm">
        <Input
          errorMessage={this.state.formErrors.name}
          label="Name"
          value={this.state.name}
          onChange={({ target: { value } }) => this.setState({ name: value })}
        />
        <section className="ncss-row">
          <DateInput
            className="ncss-col-sm-6 va-sm-t"
            errorMessage={this.state.formErrors.availableFrom}
            id="availableFrom"
            label="Available From"
            value={this.state.availableFrom}
            onChange={(value) => this.setState({ availableFrom: value === '' ? null : dateAddDay(value) })}
          />
          <DateInput
            className="ncss-col-sm-6 va-sm-t"
            id="availableUntil"
            label="Available Until"
            value={this.state.availableUntil}
            onChange={(value) => this.setState({ availableUntil: value === '' ? null : dateAddDay(value) })}
          />
        </section>
        <Select
          errorMessage={this.state.formErrors.storeServiceTypeId}
          id="storeServiceType"
          label="Store Service Type"
          // Remove option to select DORIS, SHIP_FROM_STORE, BOPIS for any store
          options={this.props.storeServiceTypeOptions.filter((option) => !['DORIS', 'SHIP_FROM_STORE', 'BOPIS'].includes(option.classification.serviceGroup)).map((option) => ({ label: option.name, option, value: option.id }))}
          value={this.state.storeServiceTypeId}
          zIndex={90}
          onChange={(value) => this.setState({ storeServiceTypeId: value })}
        />
      </section>
      <ButtonSubmit
        isDisabled={this.isDisabled()}
        isLoading={this.state.loading}
        submitError={this.state.errorMessage}
        onClick={this.onSubmit}
      />
      <aside className="ncss-col-sm-12 body-2 text-color-success">{this.state.successMessage}</aside>
    </article>
  );
}

StoreServiceForm.propTypes = {
  accessToken: PropTypes.string.isRequired,
  adding: PropTypes.bool.isRequired,
  currentService: PropTypes.shape({
    availableFrom: PropTypes.string,
    availableUntil: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    storeServiceTypeId: PropTypes.string,
    topic: PropTypes.string,
  }).isRequired,
  storeId: PropTypes.string.isRequired,
  storeServiceTypeOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default StoreServiceForm;
