import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';

const EditableTable = (props) => (
  <article className="ncss-col-sm-12 ta-sm-c">
    {/* eslint-disable-next-line no-nested-ternary */}
    {props.isLoading
      ? <Loading />
      : props.data.length > 0
        ? (
          <>
            <h1 className="body-1 pt4-sm text-color-accent">{props.title}</h1>
            <ReactTable
              className="-striped -highlight"
              columns={props.columns.map((col) => ({ ...col, headerStyle: { background: 'black', color: 'white', fontSize: '20px' } }))}
              data={props.data}
              getTdProps={props.getTdProps || (() => ({
                style: {
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowX: 'auto',
                },
              }))}
              pageSize={props.data.length}
              showPagination={props.showPagination}
              sortable={props.sortable}
            />
          </>
        )
        : props.fetched && <p className="body-1 pt4-sm text-color-accent">No Matches Found.</p>}
  </article>
);

EditableTable.defaultProps = {
  fetched: true,
  getTdProps: {},
  isLoading: false,
  showPagination: false,
  sortable: true,
  title: '',
};

EditableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  fetched: PropTypes.bool,
  getTdProps: PropTypes.func,
  isLoading: PropTypes.bool,
  showPagination: PropTypes.bool,
  sortable: PropTypes.bool,
  title: PropTypes.string,
};

export default EditableTable;
