/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Select as EdsSelect } from '@nike/eds';
import React from 'react';

const Select = ({
    children,
    menuPosition = 'fixed', // Fixes issue of options being hidden when uses within a Modal
    ...props
}) => (
  <EdsSelect {...props} menuPosition={menuPosition}>
    {children}
  </EdsSelect>
);

export default Select;
