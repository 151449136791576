import PropTypes from 'prop-types';
import React from 'react';

const HierarchyColumnTitle = ({ title, actionComponent }) => (
  <div className="eds-flex eds-flex--direction-row eds-gap--8 eds-flex--justify-content-space-between">
    <div className="eds-type--title-2">{title}</div>
    {actionComponent}
  </div>
  );

HierarchyColumnTitle.propTypes = {
  actionComponent: PropTypes.node,
  title: PropTypes.string.isRequired,
};

HierarchyColumnTitle.defaultProps = {
  actionComponent: <div><></></div>,
};

export default HierarchyColumnTitle;
