"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const uuid_1 = require("uuid");
const useQueue = ({ sendMessage, isConnectionReady, }) => {
    const [queue, setQueue] = react_1.useState([]);
    const addMessage = react_1.useCallback((msg) => {
        setQueue((prev) => [...prev, { msg, id: uuid_1.v4() }]);
    }, [setQueue]);
    const processMessage = react_1.useCallback((msg, id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield sendMessage(msg);
        }
        catch (_a) {
            setQueue((prev) => [...prev, { msg, id }]);
        }
    }), [sendMessage, setQueue]);
    react_1.useEffect(() => {
        if (isConnectionReady && queue.length) {
            const { msg, id } = queue[0];
            processMessage(msg, id);
            setQueue((prev) => prev.filter((p) => p.id !== id));
        }
    }, [isConnectionReady, processMessage, queue, setQueue]);
    return [addMessage];
};
exports.default = useQueue;
