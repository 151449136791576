{
    "lastA11yAudit": "07/08/22",
    "name": "Text Field",
    "docs": "#/components/forms/TextField",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=411%3A13087",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/TextField",
    "tags": [
        "textfield",
        "combobox",
        "calendar",
        "input",
        "forms",
        "form elements",
        "date",
        "datepicker",
        "date picker",
        "time",
        "file upload",
        "month",
        "week",
        "password",
        "url",
        "email"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
