import { requestWrapper } from './reusable';

const config = require('../../config')();

export const postGtins = (token, gtins, request, countryCode) => requestWrapper(
  token,
  config.patchResource,
  'post',
  { countryCode, gtins },
  { request },
);

export const postStyleColors = (token, styleColors, request, countryCode) => requestWrapper(
  token,
  config.patchResource,
  'post',
  { countryCode, styleColors },
  { request },
);
