import React, { useEffect } from 'react';

import edsCss from '../styles/eds.css.js';
import hierarchyCss from '../styles/store-hierarchy.css.js';

import StoreHierarchyBody from './StoreHierarchyBody';
import StoreHierarchyHeader from './StoreHierarchyHeader';

const StoreHierarchyPage = () => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `${edsCss}\n${hierarchyCss}`;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

   return (
     <div className="store-hierarchy-page">
       <StoreHierarchyHeader />
       <StoreHierarchyBody />
     </div>
   );
};

export default StoreHierarchyPage;
