{
    "lastA11yAudit": "07/08/22",
    "name": "Table",
    "description": "Tables display information so it can be easily scanned, so that users can look for patterns and insights.",
    "docs": "#/components/tables/Table",
    "figma": "https://www.figma.com/file/XPxwJmGKI6AVROvU6qMp3s/EDS-Tables?node-id=50%3A0",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Table",
    "tags": ["table", "grid", "data"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
