import { requestWrapper } from './reusable';

const config = require('../../config')();

export const getPrinterModels = (token) => requestWrapper(
  token,
  config.printerModelResource,
  'get',
);

export const getPrinters = (token, storeId) => requestWrapper(
  token,
  config.printerResource,
  'get',
  { storeId },
);

export const deletePrinter = (token, printerId) => requestWrapper(
  token,
  config.printerResource,
  'delete',
  { printerId },
);

export const postPrinter = (token, data) => requestWrapper(
  token,
  config.printerResource,
  'post',
  undefined,
  { data },
);

export const putPrinter = (token, data, printerId) => requestWrapper(
  token,
  config.printerResource,
  'put',
  { printerId },
  { data },
);
