export default {
    objects: [
      {
        address: {
          country: 'CHN'
        },
        id: 'd21fe912-5661-4402-97ab-904c9193a5c2',
        name: '黑龙江省哈尔滨市道里区中央大街胜道BEACON-L2-L(1200)',
        region: 'GREATER_CHINA',
        storeNumber: '271030'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd2431675-743c-4c36-9342-be49c4792193',
        name: '北京朝阳双桥路万达NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271039'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd246aac9-d939-4ce2-a8d4-abec5d2c7c09',
        name: '绵阳涪城安昌路百盛NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287542'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: 'd2577ab3-efae-4998-8d96-a11bd0e9adab',
        name: 'NIKE深圳梅观换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3803'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'd28068b7-c054-46dd-8575-5147e01141b6',
        name: "Nike Well Collective - Modi'in (Partnered)",
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2345'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'd28d5107-b5fd-4877-ab48-05a73decbcac',
        name: 'Nike Pondok Indah Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7094'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: 'd28d75b5-4091-4e93-9d3c-19ad537dbb62',
        name: 'NIKE呼和浩特车站前优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4039'
      },
      {
        address: {
          country: 'TWN'
        },
        districtId: '91703f40-d783-4445-9f26-57eb6a73d2db',
        id: 'd2bb5abc-dc90-404a-aab7-f0adfe4dad66',
        name: 'NIKE換季優惠店 重新家樂福',
        region: 'GREATER_CHINA',
        storeNumber: '3388'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd2c89a8a-7c51-4d4b-a55c-8634ddc53447',
        name: 'HIGHWAVE SPORTS四川成都锦江区中纱帽街太古里NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '287265'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd2d49351-3df4-4cf8-8689-a268938fc1a5',
        name: '나이키 롯데 율하',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78529'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd2d79bac-42cd-4d76-b458-86e54fcd2e70',
        name: '陕西省咸阳市陕西省西安市咸阳秦都区统一路与白马路交叉口沣西吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '268847'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '218fbf1b-2e26-47fd-aafc-a22804da60e7',
        id: 'd2e0bde2-2207-4185-b19c-d1cf4e57efce',
        name: 'NSO GDL Company Store',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6651'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd2f1b2b0-8e62-471c-86db-9ad0217f51b1',
        name: '江苏省南京市新尧金地广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269223'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd2f56f6a-4241-42b1-bbea-89e04fac8f82',
        name: 'POUSHENG江苏无锡万象城NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288191'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'd2feba06-76a2-4cf7-98a0-721b95b65e9d',
        name: 'NIKE北京顺西换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3694'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: 'd309df07-a803-4099-b1b5-f9fed817a3ad',
        name: 'NIKE佛山佛平换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3779'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd3108c59-15d8-4bdb-a1b5-61146776b7d0',
        name: '上海市锐力上海闵行区颛桥龙湖NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '260406'
      },
      {
        address: {
          country: 'MAC'
        },
        districtId: '4da1e8ed-2893-4ac4-a578-e03e4ac4c5aa',
        id: 'd324ee0a-e8e6-4a02-997c-a5f4716359cf',
        name: 'NIKE澳門官也換季優惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4904'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'd3273ba8-9842-4a36-b2c7-5a651c92db81',
        name: 'Nike Rouse Hill',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4933'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd33426f2-e01e-43bd-b09c-717937c7664d',
        name: '辽宁省大连市沙河口区西安路麦凯乐NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272785'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd37a2601-cad3-47aa-b540-cc783861c595',
        name: '北京市昌平区合生汇NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274514'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'd3e6acc3-e63f-41ce-a642-270e1eac025f',
        id: 'd3983df8-2848-4550-af5e-de30e8d93de6',
        name: 'Nike Well Collective - Riverton',
        region: 'NORTH_AMERICA',
        storeNumber: '464'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd3b21d60-20d3-4ae2-bff3-a995438b4282',
        name: '贵州省兴义市中兴路欢乐橙BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240416'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: 'd3b21fdf-8bc6-4af0-937d-7d8bda41564b',
        name: 'NIKE ONLY GATEWAY',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2307'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd3d9d6b6-0172-448e-b0f5-ab6dc7a03589',
        name: '나이키 영등동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '27803'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd3e2b05f-8a7a-4f34-bdc6-1cf3ef9a3978',
        name: '新疆昌吉市乌伊路汇嘉时代购物中心BEACON-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '526537'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
        id: 'd40b5e87-adef-469f-92c2-6aafeadda3a3',
        name: '나이키 팩토리 스토어 김해',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6125'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd41eadda-43d6-46d8-abe8-a0b8ce72e8c4',
        name: '江苏省无锡市百乐广场NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '270478'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd442c6ea-a4e3-4da5-ae63-9e47c02140fc',
        name: '重庆永川区昌州大道华茂国际重百二店NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '288848'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'd4432fdf-bc04-4b9f-ac76-2dc7c844bd60',
        name: 'Nike Kasablanka',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9943'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd44b4af9-4356-4c4f-a094-a329de6e8d15',
        name: 'Nanchang Shanshan FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6036'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd46a9c69-221f-4043-8aa0-6ae7e29cf6a3',
        name: '耐克临沂华润万象汇体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260090'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd47dac5d-87e0-4fda-8308-10761c73700a',
        name: '辽宁省鞍山市建国大道万象汇BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241967'
      },
      {
        address: {
          country: 'CAN'
        },
        id: 'd481eb83-e721-460b-985b-022813714261',
        name: 'Nike Park Royal',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD2'
      },
      {
        address: {
          country: 'ESP'
        },
        id: 'd496e75f-a639-4c79-8197-ffbc5efd4bb0',
        name: 'Nike Store Sierra Nevada (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2396'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'c39640bc-c790-42de-8810-e227062b5fb5',
        id: 'd4a24d2d-f10e-4ee0-a80b-c2d571c8a9af',
        name: 'Nike Kyoto',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3052'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '125d973d-d862-4d06-b2b2-5d14f960d4af',
        id: 'd4a70f29-557d-491f-aef8-cfb4d2d34de2',
        name: 'Nike Factory Store La Coruna',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '646'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd4b7646d-05ce-4ecb-9149-f50510b0b926',
        name: 'POUSHENG四川泸州中天重百NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287504'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'd4d61584-94ba-4596-b83f-12eea702d722',
        name: 'Nike Mall of Dhahran',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA034'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: 'd4d91911-0cae-45ae-8a86-4bef585be78f',
        name: 'Nike Factory Store Croix Blanche',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '698'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'd4f6e7e9-2043-4c29-bb34-3e3381012a05',
        name: 'Nike SM Fairview',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3916'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd4fa1fd4-a186-4622-b9c3-ed5dd40dfb4b',
        name: '나이키 서산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85913'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd510148c-eb6d-40b3-ae31-caa3310eddad',
        name: 'TOPSPORTS 合肥马鞍山路百大 SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '109755'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd51119b1-8049-436e-826f-ec97a1d484cc',
        name: '辽宁省沈阳市辽宁省沈阳和平区长白岛长白万象汇NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '269326'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: 'd52a1dc2-2d6d-45cf-a7a6-4fb91541cd68',
        name: 'Nike Well Collective - Ponce City Market',
        region: 'NORTH_AMERICA',
        storeNumber: '428'
      },
      {
        address: {
          country: 'DZA'
        },
        id: 'd54d7074-b90a-45b2-851c-979e485cc051',
        name: 'Nike Store Bab Ezzouar Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ALG032'
      },
      {
        address: {
          country: 'AUT'
        },
        id: 'd597daa5-bb92-459b-9b7f-42c06cef326a',
        name: 'Nike Well Collective - Pasching (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2426'
      },
      {
        address: {
          country: 'CAN'
        },
        id: 'd5aeec21-9c40-4bfd-9499-568443e86138',
        name: 'Nike Masonville Place',
        region: 'NORTH_AMERICA',
        storeNumber: 'PD10'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd5d849c5-2fa3-48aa-9f29-f135eada76a6',
        name: '浙江省温州市锐力鹿城区世纪广场中央绿轴印象城MEGA NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272784'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd5de7b87-e3fa-4dc4-a7c7-964546306e11',
        name: 'REALLY浙江杭州富阳春秋北路银泰KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '279075'
      },
      {
        address: {
          country: 'DNK'
        },
        id: 'd5e6a2f1-4123-48e0-b7e1-9f4384d7a943',
        name: 'Nike Well Collective - Fisketorvet (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2470'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'd5fb40e3-a61a-4422-a882-e934eef07c91',
        name: 'Nike VR Mall Chennai',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4307'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd5ff305b-bde3-498d-9f5d-14c3801b4d4a',
        name: '山东省淄博市淄川区银座Beacon-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '258608'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'd6055387-ae11-4eb9-9fdc-22851ce5b34c',
        name: 'Nike Store SamsunPiazza (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2318'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd615e7d5-9284-488e-b4f0-a69cd66159a3',
        name: '나이키 갤러리아 타임월드',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '90544'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'd6442cd1-87c2-4b82-a1b3-469218177e4d',
        name: 'NFS Chattarpur',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9464'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd64bef2a-5998-4005-9d7a-3b4d09b23067',
        name: 'TOPSPORTS江苏泰州海陵北路茂业NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287530'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'd650e024-01cf-4af3-97ae-dd11ce10b0e9',
        name: 'Nike Ciputra Semarang',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7364'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: 'd65a58dd-a933-46c3-9e5a-b2c0f693044b',
        name: 'NIKE惠州鑫月优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4036'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd66c1345-821b-44d1-b67f-7ccbe61e3177',
        name: '宁夏回族自治区银川市新华东街新华百货总店NIKE SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '185578'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: 'd67bee37-41a2-4243-81d2-26a2fb2b4506',
        name: 'NIKE武汉江夏换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3632'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'd6812533-36aa-4350-8955-6bbd7abf69fc',
        name: 'NFS Motinagar',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5127'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd682f252-a1da-4742-9fd8-bc92a54d0b42',
        name: '浙江省嘉兴市滔搏嘉兴南湖纺工路南湖天地NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271149'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd6e6e1bb-c366-4d19-9a6f-c72b58050a12',
        name: '安徽省合肥市安徽百丽合肥瑶海临泉东路万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269298'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'd6f0e7ed-9789-4063-b06f-59df1d999f24',
        name: 'Nike Well Collective - Roccella (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2425'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: 'd717abc5-fa3f-4899-8477-d27a0429b0b5',
        name: 'NIKE太原和平优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4073'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd72995e1-a840-40ff-8778-2eb82697098d',
        name: '福建厦门集美世茂广场 Kick Lounge-2',
        region: 'GREATER_CHINA',
        storeNumber: '271028'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd7314cf8-2a16-4784-8cba-1782d5f554e2',
        name: '耐克温州中百商场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '77100'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: 'd74b651b-ab9d-435d-895b-1676f7981ab9',
        name: 'NIKE福州福银换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3857'
      },
      {
        address: {
          country: 'NZL'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: 'd74e8b03-994a-4101-9b63-47a3878bcfa1',
        name: 'Nike Unite Manawa Bay',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6215'
      },
      {
        address: {
          country: 'DZA'
        },
        id: 'd7602546-9c91-40ed-a102-b44b038fd3a4',
        name: 'Nike Store Senia Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ALG031'
      },
      {
        address: {
          country: 'BEL'
        },
        id: 'd787e1f4-52db-436f-8dfd-777abda2268d',
        name: 'Nike Store Ghent',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2419'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd79918dc-aeae-42bb-8eba-498a8edafb10',
        name: 'POUSHENG陕西西安大明宫万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288445'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: 'd7a86ca5-ce57-46b9-9339-0d7afd08768c',
        name: 'Converse Factory Store - Las Vegas North Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3729'
      },
      {
        address: {
          country: 'BEL'
        },
        id: 'd7ab1269-490f-4cca-96d1-9c0bf8ccc22e',
        name: 'NIKE STORE BRUSSELS RUE NEUVE',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2415'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'd7b0e6cc-ec3b-4917-bcb9-ac99696a453c',
        name: 'Nike Store Istanbul Airport (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2792'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd7b1bcfb-5183-4552-a9f7-cfa050fcb889',
        name: '辽宁省鞍山市铁东区新亚运动城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '240447'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: 'd7c03190-33b2-40cb-a76f-a2aa0e401522',
        name: 'NIKE南通星湖换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd7c23fbc-5cd4-4865-830d-f3af841d1e53',
        name: '湖南省浏阳市滔搏湖南长沙浏阳庆泰南路1号天虹百货一楼NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271035'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: 'd7da85fa-e5fa-4780-a951-aa6438d7bde7',
        name: 'Converse Factory Store - Norfolk Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3111'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'd7e8f6a0-5be0-45f4-b16f-3b1d664098b8',
        name: 'Converse Brand Outlet Store Serravalle',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8129'
      },
      {
        address: {
          country: 'ESP'
        },
        id: 'd7ea2b59-2f35-4043-ae9a-70036d60d3f9',
        name: 'Nike Well Collective Madrid Sur (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2461'
      },
      {
        address: {
          country: 'SRB'
        },
        id: 'd7eda5da-abfe-4480-83d0-0a5a0df8be89',
        name: 'Nike Store Kragujevac Big Fashion Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'SER105'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd7f439b9-94f5-4239-af29-8394ea1036ed',
        name: '吉林省长春市吉林省长春宽城区北人民大街万达广场NIKE SPORTS-S',
        region: 'GREATER_CHINA',
        storeNumber: '260426'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'd7fa31ca-f3b7-41a0-97ef-024c46b158e8',
        name: 'NSP Espark',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2351'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd81cbf4d-7869-4d3b-a1c3-426d47e004b0',
        name: '福建省厦门市百丽厦门集美万达 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '269319'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'd824857b-46f5-4e41-b2b2-bd527ce67889',
        name: 'Nike Store Korupark (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2320'
      },
      {
        address: {
          country: 'LVA'
        },
        id: 'd827e895-81e8-42ce-ad66-550e0f2219e9',
        name: 'Nike Store Riga Alfa (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LVL027'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'd69076bd-e5bf-4d7f-91d1-4ea5fe42e7c3',
        id: 'd83e4b36-95d4-49fc-bd3f-748529d83aee',
        name: 'NIKE三里屯邻里体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3417'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd8400033-5741-4ff7-8a62-5bd123676cba',
        name: '上海市百丽上海沪闵路友谊商城Beacon350',
        region: 'GREATER_CHINA',
        storeNumber: '7830'
      },
      {
        address: {
          country: 'NLD'
        },
        id: 'd8583f1b-dec3-4abe-bc8d-661577077508',
        name: 'Nike Store Utrecht (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2607'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd8628cdb-b64b-45e8-8c5b-66374183eb19',
        name: 'POUSHENG天津滨江道友谊新天地NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '4613'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd8777d0f-c684-48db-8047-099b7342de64',
        name: '黑龙江省哈尔滨市道里区远大群力KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271204'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd877b7d2-6242-4a24-bf4c-64f178dd83a0',
        name: '陕西省汉中市汉中新城吾悦广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '531659'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd88e32ea-fa5c-43bc-aa73-c339e5ad3a09',
        name: '나이키 롯데 동래',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '39860'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: 'd8a04117-e40d-4d9d-978b-32a0c31c96c2',
        name: 'NIKE衡阳衡州换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4026'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: 'd8a944bb-f426-4744-9c98-0f01d267638a',
        name: 'NIKE天津瑞康优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4074'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd8aecfb7-868f-4102-9010-9f61459f61ce',
        name: '江西南昌红谷滩万象天地KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '293712'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'd8b32471-b57b-4a6e-ae94-8543005a1002',
        name: 'Nike GVK Mall Hyderabad',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5613'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'd8c786df-ace7-48b8-9801-588ad86483ec',
        name: 'Nike Miranda',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '731'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd8e76600-b2a5-4a08-9303-00231ec02aed',
        name: '山东省济南市滔搏中海华山环宇城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274456'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd8fc878f-f6f3-41c8-8abf-8e224870f406',
        name: 'TOPSPORTS江苏苏州相城大道天虹NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287507'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd901e783-4a41-4a42-b40e-67eacab67c6b',
        name: '江苏省苏州市锐力工业园区久光百货KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '270541'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd920da2e-1668-48a9-bc21-d1ce1dcada8b',
        name: '吉林省长春市朝阳区红旗街欧亚商都 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '273118'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: 'd94b2cb3-030e-4b56-abc6-0f475e7913c3',
        name: 'Converse Factory Store - Tanger Outlet Sevierville',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3775'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd94d3dae-f9e5-4410-9656-797b737eb22b',
        name: '耐克成都成华区万象城 Beacon店',
        region: 'GREATER_CHINA',
        storeNumber: '269209'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd9671b8c-866e-482c-9f99-05be12b37a5e',
        name: '나이키 롯데 마산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '66305'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd9731e27-5517-4878-9f5a-f9eaec034870',
        name: 'TOPSPORTS江苏无锡中山路八佰伴NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274336'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'b40757b7-922b-4136-8933-625aa86711bf',
        id: 'd983a2af-20d7-458f-9716-466d7a65efbb',
        name: 'Nike Del Amo',
        region: 'NORTH_AMERICA',
        storeNumber: '488'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'd9889ad2-8397-4c05-afa0-3b5d5d8cac5d',
        name: '나이키 신세계 센텀시티',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '68976'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd9b4cb18-ab7b-4ac0-bab9-fa7e8e0b99b4',
        name: '黑龙江大庆市萨尔图区东风新村纬七路新东风购物广场 NIKE SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '4550'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'd9c22aa6-6d7f-4551-93b8-fed1a2756c5a',
        name: '北京西城区西单君太百货BEACON-L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '1481'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'd9d37af8-74a7-4bdc-900e-52fb253edc10',
        name: 'Nike Store Milano (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2608'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'd9e98328-22f7-42d4-8bf4-55d14fd95420',
        name: 'Nike Factory Store - Buenaventura',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6410'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'da373ca5-47d7-4292-995f-32fc5e486d99',
        name: '나이키 속초',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21620'
      },
      {
        address: {
          country: 'POL'
        },
        id: 'da532b55-3d17-457c-baca-90671aab66b2',
        name: 'Nike Well Collective Krakowska (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2495'
      },
      {
        address: {
          country: 'SGP'
        },
        id: 'da69648f-4d54-4095-8753-f901a077807e',
        name: 'Nike Well Collective – Bugis',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1666'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'da6b7554-8ae2-4d51-8a82-b7969887d25b',
        name: '耐克沈阳中街街铺体验店',
        region: 'GREATER_CHINA',
        storeNumber: '258603'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'da81809b-e489-4365-a741-ef458fe7c763',
        name: '山东省烟台市芝罘区大悦城KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '270539'
      },
      {
        address: {
          country: 'ISR'
        },
        districtId: '80d72218-6d2b-46e2-bea0-2210cb625fbb',
        id: 'da9d1b5c-69fa-4d94-9df9-e46305faba3e',
        name: 'Nike Clearance Store Krayot',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '945'
      },
      {
        address: {
          country: 'EGY'
        },
        id: 'daae3379-f85e-4f1e-89a1-c93dab6414d6',
        name: 'Nike Store Dandy Mall',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'EGY034'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'dab7bce7-50e6-4b8c-9574-31b29222441c',
        name: '나이키 복현',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '27100'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'daca4c77-06ed-4d4b-b1ec-3a8970a4c5aa',
        name: '江苏省南通市锐力通州文峰大世界耐克店 SP-SIS (DSP/SFD)',
        region: 'GREATER_CHINA',
        storeNumber: '481545'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'dace8a14-a15b-4186-a2a4-68b0fcebb691',
        name: 'NIKE沈阳双园优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3661'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dad722c3-bbc2-48b6-8bd0-3bf7a9ee1a5e',
        name: '云南昆明柏联百盛NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '526884'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dadb3952-39d7-4401-abc5-991ae3f71962',
        name: '黑龙江省大庆市让胡路区万达广场 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '531940'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db01fd5b-d370-40cd-b779-5450ec22b390',
        name: 'POUSHENG辽宁大连华南万象汇NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288197'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db02b438-ccbe-445c-916b-50df84eb6b10',
        name: '吉林省长春市欧亚大卖场KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '269454'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db185c49-f73f-4d14-92e2-daa7b8b27738',
        name: '浙江省宁波市锐力鄞州区天童南路环球银泰城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272097'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db1d73dd-4fb8-4e54-a0ee-71a922266477',
        name: 'TOPSPORTS山东枣庄薛城区万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287332'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db1ecc66-5b47-4b47-820e-c31dddc86776',
        name: '重庆九龙坡杨家坪正街瑞成商都NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '3630'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db20a3b0-0b79-41b6-bc55-522dc343c82a',
        name: 'TOPSPORTS广东省深圳市罗湖区万象街万象城三期1F层JORDAN-L1',
        region: 'GREATER_CHINA',
        storeNumber: '274470'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'dcc4eb42-f310-4b95-ba54-d696a999c727',
        id: 'db2c003a-b43d-4f36-b330-21480e4e8ba5',
        name: 'Nike Well Collective Battersea',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2505'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db34574e-8dad-49fb-b1e2-b12ff0f143ac',
        name: 'POUSHENG浙江省绍兴市上虞华鸿万悦城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274493'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: 'db37a976-8ecd-4dba-8b91-64e2d26a0d0b',
        name: 'NIKE绍兴二环换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3863'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '365c9a73-b7cf-42b6-8da8-e264840ef31c',
        id: 'db65aa0d-7068-4923-bd8e-918f7e0dbf5d',
        name: 'Converse Factory Store - The Crossings Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3731'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '3b6b7052-a6fe-42eb-8886-cd072dc1382e',
        id: 'db709dda-62ab-4df5-b296-ea4c0b9e8ed0',
        name: 'Nike Factory Store Mulhouse',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '664'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'db7932be-530d-40a9-ab25-cb2434e23d16',
        name: '江苏省宜兴市新苏南KICKS LOUNGE L2',
        region: 'GREATER_CHINA',
        storeNumber: '272111'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '05f259e2-5c3f-43b8-aae2-8a59f239af36',
        id: 'db7ec4b1-a277-4654-8c8d-fb015653c6bb',
        name: 'Nike Unite Wembley',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '818'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '125d973d-d862-4d06-b2b2-5d14f960d4af',
        id: 'db7ff2f5-d014-4ecc-88b7-d8fb729e3e69',
        name: 'Nike Unite Zaragoza',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '694'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'dba14c6d-4aa9-4e24-85e0-a3a3f835b85c',
        name: 'Nike Sarath City Capital Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5304'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'dba1ff28-896a-4154-a962-a94d9c060983',
        name: 'Nike Centrio',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95113'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'dba549c1-e429-4f9a-9399-77e199f64c1d',
        name: 'Nike Burwood',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8750'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'dbc3ed0b-468f-473f-b3c3-fa3a7a2df657',
        name: 'Nike Store Eilat',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2713'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dbc740c3-4215-4482-98c1-1fbdd7f6e5fc',
        name: '四川省宜宾市翠屏区龙头山路浙商新天地NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '243811'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dbdc3338-62bd-4931-9414-423dc96e5012',
        name: 'POUSHENG北京西单大悦城JORDAN-L1',
        region: 'GREATER_CHINA',
        storeNumber: '287317'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dbf51f0c-6148-407b-85d5-326f2f34061c',
        name: '青海省西宁市海湖新区大象城王府井三店BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '269061'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'dc0cb5cc-8af7-4b9c-a8c7-83555d0d4d60',
        name: 'Nike Factory Store - Santo André',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6321'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'dc35189b-974a-43ae-a665-10ef0a28286a',
        name: '나이키 현대 중동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '28451'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'dc370894-f522-4da9-a066-c7324dc2baa9',
        name: '나이키 시지',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21173'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: 'dc45c45b-a670-40ba-9b97-9a302b79a49b',
        name: 'NIKE ONLY FOURWAYS',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2304'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dc5b4f13-296c-4f48-94b1-b20aab150b80',
        name: '湖南省吉首市百丽湖南吉首步步高NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '242449'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'dc85ec6d-ad07-467e-8d2a-f767badefb65',
        name: 'Nike Factory Store Acienda',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5098165'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '3ff1a7ff-de7d-4f2b-b7e7-e85a5c5a68c7',
        id: 'dc87d6be-9164-46fa-87dd-64a0f37e9338',
        name: 'Nike Factory Store Viladecans',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2029'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: 'dceefd5d-4c10-42f4-8614-5a3e0144b226',
        name: 'NIKE深圳建设换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3797'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dcf0f7aa-955e-4113-82de-492ddbc7b27e',
        name: '辽宁省沈阳市沈阳市皇姑区佳禾运动城KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '481305'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: 'dcf79802-9fea-4627-86b6-55513a9dca57',
        name: 'Nike Clearance Store Rostock',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2042'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dd0a75c4-dd30-4885-a4b1-bd2382c30101',
        name: '长春朝阳区欧亚新生活BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '522027'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'dd1f8af6-7f2d-417f-85f3-e6586d1eaada',
        name: 'Nike Valentina',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9560'
      },
      {
        address: {
          country: 'PRT'
        },
        id: 'dd3815a5-a502-41aa-bf4a-08e52d0b23e7',
        name: 'Nike Store Chiado II (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2474'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dd5fba18-429d-47ec-b3fa-e7e1c4d45b37',
        name: '湖北省武汉市百丽武汉市江汉区世贸广场 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '825'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ddb1fdae-0998-4cf0-a98d-f1cc6d601468',
        name: '浙江省杭州市富阳新桥新路富阳万达广场SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271058'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'ddd096fc-e11b-4aa2-9995-37b35d3d4b00',
        name: 'Nike Grand Hotel',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1894'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
        id: 'ddda858c-92da-40ed-abb7-2f9f9a54d797',
        name: 'NIKE淮海TX  - Opening Soon',
        region: 'GREATER_CHINA',
        storeNumber: '3458'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'dddc0776-aedc-48d6-9841-399b644ba4ae',
        name: '山东省泰安市泰安中百大厦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272100'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'de228e3d-6653-443b-b867-a6ee3d36840c',
        name: '安徽省合肥市高新区银泰KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271048'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'de345a13-9a78-4e5b-8bd1-afb4a728a991',
        name: 'TOPSPORTS广州市天河区天河路200号中怡广百4F层 Nike Sport M',
        region: 'GREATER_CHINA',
        storeNumber: '5314'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '3adff7f0-f5bb-4c83-929e-5ce770d58df9',
        id: 'de469b47-6b3a-44fe-a168-11a9984405a7',
        name: 'NIKE OFF SITE - ORLANDO SERVICE CENTER',
        region: 'NORTH_AMERICA',
        storeNumber: '299'
      },
      {
        address: {
          country: 'CAN'
        },
        id: 'de5c97a5-5c9c-4c19-833a-0ccf9895bb89',
        name: 'Nike Quebec City',
        region: 'NORTH_AMERICA',
        storeNumber: 'S231'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5d3afeaf-c13b-459b-aeda-54a5fc6800fc',
        id: 'de6226fe-91ef-46f6-8045-7f9f9dc13d8b',
        name: 'NIKE广州西环换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3754'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'de6d7444-de88-402f-986d-025f66bf4cfc',
        name: '上海市百丽上海中信泰富万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '526233'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'de70c114-64a7-4f6a-9f16-9199ce7cf175',
        name: 'SANSE广东广州吉祥路广州动漫星城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '109163'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'de7f7545-fbc6-4cb8-a100-f30935354da4',
        name: '나이키 이천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '92066'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'de82ae21-4fe2-4fb2-aa64-4a56c9f3a4f2',
        name: '四川省成都市成都市金牛凯德广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '531457'
      },
      {
        address: {
          country: 'NZL'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: 'dea09a5e-bb8e-499c-8729-7c74a2a3430f',
        name: 'Nike Unite Westgate',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6154'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'deaa3445-5a6d-430c-9c27-6872e0695e7a',
        name: 'NIKE KIDS长沙九龙仓购物中心店',
        region: 'GREATER_CHINA',
        storeNumber: '189370'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'deae20ff-afae-4e7b-87d5-7949ce6aef5f',
        name: '广东省深圳市百丽深圳龙华区福城天虹购物中心NIKE SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '259296'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'decad036-fc76-43bf-9d7b-b30131e21af6',
        name: '遂宁船山新世纪NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '7615'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'df2bd933-658f-493a-8fc8-4f58966171da',
        name: 'Nike Patna Frazer Road',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9185'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'df3005c3-340b-47e1-ab62-5072f3e6a9ad',
        name: 'NIKE沈阳浑河换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3697'
      },
      {
        address: {
          country: 'LTU'
        },
        id: 'df34764c-0bd4-4cbf-89e4-938a2efcdaf8',
        name: 'Nike Outlet Vilnius Ogmios (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LIT005'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'deaab327-38f9-46b2-a620-287e15721e6d',
        id: 'df3c1ebf-f91c-4182-905b-dbb5bde7bbd0',
        name: 'NIKE上海天山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3827'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'df4d622a-2cb5-453f-bfa8-e601bfc3ff90',
        name: 'Nike SM Iloilo',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1249'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'df570885-d3f0-4cdf-a73a-5f62993c87f5',
        name: 'TOPSPORTS江西九江京九路联盛快乐城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281955'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '5f674e69-ec01-4eb8-9f86-eebb434fdcfd',
        id: 'df611eb8-ae47-410b-a60e-ba8209289869',
        name: 'NIKE天津宁海换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3823'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'df612a85-284b-4254-859d-d5c2e02bec62',
        name: '南充高坪江东大道中路王府井购物中心NS-M',
        region: 'GREATER_CHINA',
        storeNumber: '287201'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'df874eae-ce51-497e-810e-0ee0612ce7e8',
        name: 'TOPSPORTS广西桂林中山中路微笑堂NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '275362'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'df950b74-e5a0-48fa-b4eb-44c0db284d67',
        name: '나이키 세이브존 노원',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '62705'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '3354e3fb-9285-47ab-a3ce-13fcff9bf71c',
        id: 'dfac9e6e-6788-4824-892a-b73801c526d5',
        name: 'Nike Factory Store Sosnowiec',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '565'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'dfc187f2-63cb-4056-b65d-ffe1be8d0976',
        name: 'Nike Store Creteil (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2782'
      },
      {
        address: {
          country: 'POL'
        },
        id: 'dfc3acc2-5ff0-48f8-8e60-514cf58f7155',
        name: 'Nike Well Collective Mlociny (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2397'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'dfdadd18-ede8-4122-83ed-0d7090bb20cb',
        name: 'NFS Pune Kothrud',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090501'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '8b2ae4b8-eb16-4d51-83bb-dba72ba34715',
        id: 'dfe6f666-474a-46c2-bb5f-681f7fa24e0f',
        name: 'Nike Factory Store Marseille',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '620'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: 'e0111e30-a30a-4215-9f96-e6ff9a9971f4',
        name: 'NIKE昆山东城换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3816'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: 'e032d953-14bf-40e6-bef8-00fb5fbb16c1',
        name: 'NIKE南京横江优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4063'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e0406f5b-5a7b-4eac-96fc-9cfddae2e352',
        name: 'CC YUJIE吉林长春红旗街万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '189192'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e0445e14-4c3b-4852-9e20-6113ed94ba80',
        name: '广东省深圳市宝胜广东深圳龙华区红山华侨城6979 NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '267160'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'e044f373-4572-4cd6-a555-c25984df002b',
        name: 'Nike Brighton',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '589'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e05c5bb9-64d0-4a2e-9bcb-9abf3a23e2b9',
        name: '云南省昆明市昆明市五华区正义坊购物中心KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '180439'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e06f9140-61e9-4d09-a83b-7197aa1a34d3',
        name: '나이키 아트몰링 하단',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '74029'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: 'e0775e37-2e3b-47d0-872d-24a628ef508d',
        name: 'Nike Unite Bremen',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '547'
      },
      {
        address: {
          country: 'LBN'
        },
        id: 'e07e11ba-53ba-42af-901c-fa10d9ece385',
        name: 'Nike Verdun',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LEB059'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e080048a-68df-4050-aea7-0b82489daf80',
        name: '나이키 롯데 상인',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78527'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e083f3da-4505-4343-b0c7-845f62f46fb1',
        name: '安徽省合肥市合肥百大购物中心（心悦城）BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '330969'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'e0909dba-97a1-4093-8cdf-fac8eb22b146',
        name: 'Nike Store Forum Trabzon (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2317'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e0bb7190-d459-4be5-97b6-d0a9e098d4ac',
        name: '나이키 신세계 대구',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '93087'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e0ffe036-53a8-4bb8-83e5-416c12e83464',
        name: 'SANSE广东中山孙文东路万象汇KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '281926'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e118197a-d2e0-4850-94e7-733fc3190ac1',
        name: 'TOPSPORTS广东省珠海市百丽广东珠海市香洲区银桦路优特汇 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '268839'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e12019b2-28d1-4cbe-874f-dc35e7c3480c',
        name: '六盘水麒麟路万达胜道NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '241523'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'e1478343-b120-44c4-99fd-d5572d37fcd7',
        name: 'Nike Bin Homarain Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1507584-624d-42ef-95cb-70dbdcfc17e3',
        name: '重庆江北区观音桥北城天街BEACON L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '193836'
      },
      {
        address: {
          country: 'ZAF'
        },
        districtId: '43bbce93-a2ad-4606-a7df-205395646fdf',
        id: 'e153429b-5c71-4dcb-81d8-00997e16a6d3',
        name: 'Nike Factory Store East Point',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '958'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e174f7da-58f1-4817-8a3c-7eaf99844303',
        name: '北京房山良乡东路大学城龙湖NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271287'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'e1a861ba-92da-4e29-a8f3-e908d2bb69f3',
        name: 'Nike Broadway',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '280'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1c89feb-31ee-4994-aeca-37cbd121c6db',
        name: '锐力温州平阳水头二中北路68-72号NIKE店',
        region: 'GREATER_CHINA',
        storeNumber: '101957'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1d4452c-4b02-470c-8359-879a4396b67b',
        name: '江苏省无锡市滨湖区苏宁易购NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '273647'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1d7c25e-e3d3-452a-8e79-82019f33590f',
        name: '山东省烟台市开发区万达广场NIKESPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272577'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1da5a01-8c83-4af9-8d12-490466e56386',
        name: '河南省驻马店市驿城区爱克玖隆茂KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269322'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1db7e6e-4e13-4842-a992-1e9501e945a9',
        name: 'TOPSPORTS河南郑州金水区金水东路新田NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287211'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: 'e1e02f70-3a1b-4ac4-9511-b84cb8299452',
        name: 'NIKE宁波中山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3897'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1f38ed7-6c91-4cfd-b4d7-7bced6ed1e33',
        name: '广东省广州市盛世长运广州北京路新黄金盛世BEACON-L2-L(1200)',
        region: 'GREATER_CHINA',
        storeNumber: '194076'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e1ff9c9d-4788-4aac-a508-dd9d6ae09025',
        name: '上海市虹口区瑞虹路太阳宫NIKE SPORTS-M',
        region: 'GREATER_CHINA',
        storeNumber: '272107'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e2190a45-02ed-4919-9244-cdb26ee8bc92',
        name: '四川省成都市锦江区银石广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '272121'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e219830a-85de-4316-8714-4b6778bb84c6',
        name: '江苏省无锡市人民中路东岭锡上商业广场 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '271248'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'e22a3192-fe82-4311-98a4-6dcd2849f170',
        name: 'Nike Factory Store - Curitiba',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6323'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e2363dfc-adc7-4c53-bf01-5a2f3e29edd5',
        name: '海南省三亚市百丽海南三亚市吉阳区榆亚路一号港湾城3F NIKE SPORT –S',
        region: 'GREATER_CHINA',
        storeNumber: '269099'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e2777043-85e0-4df2-ba4f-0866b9e8bed3',
        name: '上海市百丽上海港汇广场KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '190228'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e27b2542-421d-4d44-b3a5-4cefab2b80fb',
        name: '安徽六安金安万达广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '328283'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e27c9427-4c39-4d8a-a934-cbaee81ce876',
        name: '나이키 부산대',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95985'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'e2816c61-4a23-4949-abfb-1756e6fbf545',
        name: 'NIKE大连华南换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3456'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e296a339-c6ac-418a-b153-11e52bdc56d2',
        name: '나이키 광복',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '95992'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e2a6dc55-4389-4445-a801-f1df0f7ef714',
        name: 'POUSHENG江苏宜兴华地NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288177'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e2c35f07-27d4-4a13-9e9f-c1ef11b84db5',
        name: '云南省昆明市恒隆广场JORDAN L1',
        region: 'GREATER_CHINA',
        storeNumber: '270781'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e2c3f587-06bf-44bb-93bd-393f927e489c',
        name: '重庆市大渡口区步行街劲浪运动城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '267000'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e2c79234-3f43-451f-b40b-a64516d4c3ad',
        name: '青海西宁唐道.637 BEACON-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '520315'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: 'e33b73c8-8395-4fb1-b8dc-4f76a9b2b46d',
        name: 'Nike Well Collective - Alpharetta',
        region: 'NORTH_AMERICA',
        storeNumber: '436'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: 'e358fcfb-9693-40b6-bab3-5bcfcacc980a',
        name: 'Nike Factory Store Viaport Venezia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '916'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e3956d32-57e9-4963-b972-4ce80ef3919f',
        name: '山东省烟台市芝罘区振华商厦Beacon-L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '454482'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e39ebb58-fbd0-4d39-b094-13513cd64cde',
        name: 'TOPSPORTS河北唐山玉田北方购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287477'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'e3b1a313-c10b-4060-9f3a-5e74de820b8f',
        name: 'Nike Maipú',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '1387'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e3d3b7fb-705d-4be9-9f90-2c6ab7290b34',
        name: '四川省西昌市达达春天百货 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '267095'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e3d56289-13e9-4acb-9053-c610c0ac790e',
        name: '辽宁省朝阳市双塔区朝阳大街兴隆大家庭 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '106390'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e3d75dee-651d-4466-877c-935db6d6d60f',
        name: '江苏省南京市南京六合欢乐港KL2',
        region: 'GREATER_CHINA',
        storeNumber: '258639'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e3dfaf0a-96c3-4d44-a35e-c5c9d8202a0d',
        name: '辽宁省沈阳市和平区太原街 BEACON-L2',
        region: 'GREATER_CHINA',
        storeNumber: '190073'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '524440b5-a003-410b-9e4b-25c2de21868b',
        id: 'e3ec2c1b-5263-40f0-a8ce-3a5aaf66f9c3',
        name: 'Nike Factory Store Val Di Chiana',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '583'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e3f843ea-8e5a-48af-b563-b083788e9bc2',
        name: '나이키 경산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21303'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e4032134-d5a4-40cc-a7fb-5625ab5270b2',
        name: '黑龙江省哈尔滨市松北区华润万象汇KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '260364'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e4075c3e-96bb-4bbb-90fa-edeacea9766c',
        name: '湖南省怀化市万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '243325'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'e40e6991-a794-45d9-af1e-0093ae4df339',
        name: 'Nike Store Strasbourg (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2493'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'e43bcf2f-ecb7-42ef-b2f8-4e4b2cdd793e',
        name: 'Nike Infinity Malad',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6889'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e43fe459-b2e7-46b8-b990-2d9fb43e8c98',
        name: '耐克广州置地广场跑步体验店',
        region: 'GREATER_CHINA',
        storeNumber: '179305'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'e44a3621-49c4-4500-a591-4127e5f73796',
        name: 'NIKE重庆龙湖换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3437'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e458a1d3-eb99-496a-ba76-399fd4ef5130',
        name: 'TOPSPORTS福建龙岩龙岩大道龙岩万宝广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '288484'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'e4598f91-0944-4f39-aba2-b9e1d3d61658',
        name: 'Nike Store Marka (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2622'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e46df18b-d7d2-45b7-a091-53517754d3dd',
        name: 'POUSHENG河北邯郸中华北大街新世纪KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '288192'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e48956bf-10f5-471f-a48a-d99e4c6c005c',
        name: '乌鲁木齐沙依巴克区友好南路友好红山Mall NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '529268'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e4b28fe2-5d43-42a5-b6ea-06f27c01c81d',
        name: 'TOPSPORTS辽宁瓦房店世纪广场新玛特NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '281938'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: 'e4b56a54-69df-4183-a818-4e4af63dd912',
        name: 'Nike Clearance Store - White Marsh',
        region: 'NORTH_AMERICA',
        storeNumber: '405'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e4def4d5-2095-42eb-bca8-c1f170d6c78b',
        name: '나이키 신세계 경기',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '93085'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '81c0c504-ca48-4db3-aa69-d53e26ea82fa',
        id: 'e4f2e177-b92b-4c0f-b525-5deccf05b032',
        name: 'Nike Well Collective - Rancho Cucamonga',
        region: 'NORTH_AMERICA',
        storeNumber: '595'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'e50486a8-6e13-4e76-abaa-96321e33989c',
        name: 'Nike Mall of Arabia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA032'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '74589f48-d268-4548-90ac-e18b348c08cd',
        id: 'e5334552-3049-408d-a114-482a9e382fa5',
        name: 'NIKE武汉品牌体验店',
        region: 'GREATER_CHINA',
        storeNumber: '3436'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'e542a5a8-3aa5-4f98-90b7-caabdec79a33',
        name: 'Nike Store AnkaMall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2680'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'e54cb00a-a7cc-4cde-8cb7-6187c27f452b',
        name: 'Nike Store Bologna (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '720'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'e586ccdd-3cc2-49dd-a027-539c31c914e5',
        name: 'NIKE哈尔滨智谷换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3815'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e592f659-4629-4a5d-b80f-a5d1052cff56',
        name: 'Nike Well Collective - 롯데 서울역',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '85992'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'e59f5859-08a7-4d8f-89f3-690575a66d41',
        name: 'Nike Store Carugate (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '753'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e5bcb122-12b7-48ff-8386-c4b5da3e57a1',
        name: 'POUSHENG辽宁营口鲅鱼圈万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282170'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: 'e5c4aff3-540c-42bd-818e-c46ca40b16b7',
        name: 'Nike Factory Store Bolu',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '657'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'e5c76bf9-4dad-4a59-9016-00517b4dc883',
        name: 'Nike City Centre 2',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2821'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e5de7558-86d5-4ead-a2be-1266d13a0243',
        name: '江苏省南京市宝原南京商厦NIKE SPORTS-L',
        region: 'GREATER_CHINA',
        storeNumber: '269113'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '3e4ffa44-6598-4076-b453-9ff095fc2f0b',
        id: 'e5f962b2-63cf-4a4b-9ba4-a1ed459c2aaf',
        name: 'Nike Well Collective – Angelopolis',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '522130'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e60d13e9-67b4-4138-ba27-fd54942a8bcd',
        name: 'REALLY江苏南京新街口瑞安IFCX NIKE RISE-1200',
        region: 'GREATER_CHINA',
        storeNumber: '287544'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e63815f7-058d-4109-b274-925815a972aa',
        name: '南京栖霞区仙林金鹰天地NK SPORT',
        region: 'GREATER_CHINA',
        storeNumber: '271192'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e63f213d-0907-43bc-8b7b-5f55a89e2f55',
        name: 'POUSHENG江苏南京河西金鹰NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '292581'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: 'e642bd30-e6d8-46cc-9ae3-a98214b71fa9',
        name: 'NIKE徐州潘安 - Opening Soon',
        region: 'GREATER_CHINA',
        storeNumber: '4047'
      },
      {
        address: {
          country: 'ROU'
        },
        id: 'e65920a1-b45c-45b7-b3c6-544f6fd7b6ab',
        name: 'Nike Store Craiova Promenada Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ROM019'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e667b17f-e63a-4add-82ed-43af0ae1d3bd',
        name: '四川省绵阳市涪城区临园路凯德广场 KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '270537'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '54530702-9c61-4bb5-9403-066b5e02d9fe',
        id: 'e69e131a-34f5-423b-98c4-1d427c995aad',
        name: 'Nike Palm Beach Gardens',
        region: 'NORTH_AMERICA',
        storeNumber: '703'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'e6cbd152-68da-4239-9b84-cf26f1b7e555',
        name: 'Nike Maxilom',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2895'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e6efdd1e-f6f6-47d0-af35-9ee6249acbda',
        name: '耐克厦门夏商体验店',
        region: 'GREATER_CHINA',
        storeNumber: '178768'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'e6f71e47-f437-425b-8e0c-1579efc5fca4',
        name: 'NSP Naharia',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2770'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e6fe838c-b18e-4ee2-9e32-b005f446a7ce',
        name: '江苏省南通市江苏省海门市锐力江海路新中南城NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '269321'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e703852a-71d4-4ece-afef-dbab5210157a',
        name: '耐克北京西单大悦城KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '178822'
      },
      {
        address: {
          country: 'BHR'
        },
        id: 'e7062947-da25-4b04-a285-c86fc339da3d',
        name: 'Nike Store SSS SEEF MALL',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BAH001'
      },
      {
        address: {
          country: 'ISR'
        },
        districtId: '80d72218-6d2b-46e2-bea0-2210cb625fbb',
        id: 'e70d1868-065b-4bb3-a170-c94ed54d3cf3',
        name: 'Nike Unite Bilu',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '966'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: '3ff1a7ff-de7d-4f2b-b7e7-e85a5c5a68c7',
        id: 'e70d9927-d1c4-4157-ad07-fb919e4a1c57',
        name: 'Nike Unite Parc Valles',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2012'
      },
      {
        address: {
          country: 'BGR'
        },
        id: 'e722c91d-84c8-430d-bc3d-9af94a770cb1',
        name: 'Nike Store Varna Grand Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BUL130'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '30b04d60-e533-4032-bc19-6cc227b6395d',
        id: 'e72c925b-1818-4004-91bd-e9c6d99c9d01',
        name: 'NIKE南京秣周换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3999'
      },
      {
        address: {
          country: 'UKR'
        },
        id: 'e735c212-f27b-469f-9da8-ee7d674d68a0',
        name: 'Nike Store Kharkiv Nikolsky (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UKR055'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e73709f1-fe58-4588-9766-89303c028d9f',
        name: '나이키 여천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '29355'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'e74646ef-2bc3-4043-b85a-cd7bd9072842',
        name: 'Nike Well Collective – Parque Lindavista',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '514375'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '0b43f8db-521a-4a00-8a99-d23f84e09f67',
        id: 'e7546302-7eac-4fe5-a0cd-b37b63f06e3c',
        name: 'NIKE烟台衡山换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3740'
      },
      {
        address: {
          country: 'AUS'
        },
        districtId: '874db406-7943-4c85-8f22-e671d4c2855e',
        id: 'e7550013-de59-4348-b592-4b642718858a',
        name: 'Nike Factory Store - Brisbane',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6175'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e76c5e67-f48d-4991-873e-e696063c409c',
        name: '辽宁省沈阳市和平区中兴BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '78000'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e7770a95-3193-4b63-bf25-fdb7d51f156f',
        name: 'SANSE海南海口海秀东路望海国际广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '240845'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: 'e7b56fc7-11bd-46be-89f5-f1b225ddcf3e',
        name: 'NIKE长沙金星换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3927'
      },
      {
        address: {
          country: 'URY'
        },
        id: 'e7dd462f-e556-49da-bb9f-242005784a7d',
        name: 'Nike Factory Store - 8 de Octubre',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '30101'
      },
      {
        address: {
          country: 'SGP'
        },
        districtId: 'b92f8324-e237-4b72-9463-0020d46f2be6',
        id: 'e7e73905-95ee-40b8-8278-8385db43b627',
        name: 'Nike Company Store - RSP',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e800163f-ccd7-4681-9366-257441d84e59',
        name: '辽宁省沈阳市和平区中兴BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '780'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e813cb98-bc4b-4516-b771-8f42e5ec71af',
        name: '山东省淄博市张店区淄博吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '273003'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66772996-562e-4025-a37f-33b8bab87475',
        id: 'e8290c20-1e1f-424d-af92-354b4d35efea',
        name: 'Converse at Lovejoy Wharf',
        region: 'NORTH_AMERICA',
        storeNumber: 'C5307'
      },
      {
        address: {
          country: 'POL'
        },
        id: 'e895a38a-1353-43a8-b243-f6cc7c1db1fc',
        name: 'Nike Store Olsztyn CH Warminska (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2670'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e8a50847-7408-4ca0-a9d3-4b369a472542',
        name: '河北省石家庄市长安区中山路勒泰购物中心KICK LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '281931'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e8f1e13a-75b3-45ed-a8d2-768d26b3c2d5',
        name: '哈尔滨南岗中兴大道万达广场KL',
        region: 'GREATER_CHINA',
        storeNumber: '287510'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e902ce22-74fa-483f-92b9-28488992aabb',
        name: '吉林省长春市朝阳区红旗街万达 Kicks Lounge L1',
        region: 'GREATER_CHINA',
        storeNumber: '274008'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e90a5a9d-5640-4e4a-874e-ea2f18050118',
        name: '辽宁省大连市西岗区五四路恒隆广场JORDAN-L1',
        region: 'GREATER_CHINA',
        storeNumber: '274147'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'e980479c-10a8-4e14-9178-14edf9ef7bc6',
        name: '나이키 돈암',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87386'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'ceb6f041-51ac-43b8-bc67-4c245ff68a82',
        id: 'e988a41f-c9c1-4ef1-9e1b-65fd12188f2c',
        name: 'Nike Well Collective - San Ramon',
        region: 'NORTH_AMERICA',
        storeNumber: '456'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: 'e989cdc5-1935-409c-ab30-e9dc20f18901',
        name: 'Nike Factory Store - Montreal',
        region: 'NORTH_AMERICA',
        storeNumber: '372420'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e9978a00-0741-4ab9-aa10-fbde05e51e43',
        name: '江苏省苏州市苏州石路天虹BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '245802'
      },
      {
        address: {
          country: 'AUT'
        },
        districtId: '641ef75c-1a82-4785-bc3e-1b682c3828ee',
        id: 'e9d496fa-5e5b-408c-b139-19c1b43aa2e9',
        name: 'Nike Unite Salzburg',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '823'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e9d84930-b154-46bb-8599-9f107c8e85f7',
        name: '耐克乌鲁木齐友好商场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '258615'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'c000c587-a82c-4e6d-85cd-534d2ea8bacf',
        id: 'e9e48f31-925c-4a6b-b70a-ad5e292e13e6',
        name: 'Nike Well Collective - 4th Street',
        region: 'NORTH_AMERICA',
        storeNumber: '552'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'e9e8edcb-1b15-48fd-b904-a31db4c24eaa',
        name: 'NIKE葫芦岛龙湾换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3711'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'e9f59fdd-beaf-4c61-90ab-298501444224',
        name: '北京海淀区双安商场 Beacon L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '200'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ea069166-d73e-48ec-a98a-5674871f3fb6',
        name: 'POUSHENG黑龙江牡丹江太平路新玛特NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287292'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'ea27f2be-5ac8-442b-87ae-fb1eb738ff6b',
        name: 'Nike Bondi',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '137'
      },
      {
        address: {
          country: 'MEX'
        },
        id: 'ea3b3204-196a-43a5-bf21-46e3d1a7c6f4',
        name: 'Nike Andares',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '518826'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'ea43cde1-cb5f-4612-9c11-ae468e84001a',
        name: 'Nike Southland',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9360'
      },
      {
        address: {
          country: 'ESP'
        },
        id: 'ea61008a-3a4d-4b2e-9d3d-93ea36934c84',
        name: 'Nike Well Collective Barajas (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2427'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ea66c66b-2af0-4a93-a7ab-12df9c4df8b7',
        name: '江苏省苏州市滔搏苏州吴江开平路吾悦广场Nike Sport-S',
        region: 'GREATER_CHINA',
        storeNumber: '274335'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ea7eaf91-e0f4-4b5a-bd7e-fa326790c08e',
        name: '广东省深圳市盛世长运深圳市坪山新区益田假日世界 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '267119'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'eaa4dc98-1f0d-48f1-81a6-807068a981dc',
        name: '나이키 천안 쌍용',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '51351'
      },
      {
        address: {
          country: 'MNE'
        },
        id: 'eabd4019-539a-423c-8421-23d49085d67a',
        name: 'Nike Outlet Podgorica Big Fashion Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MON002'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'eac4848b-3ac6-4c48-8a09-b914570f5156',
        name: 'Xian Lintong FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6019'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'eae903d5-c50b-4f7d-8548-d51f75f0ef6e',
        name: '重庆市渝北区斑竹路金科美邻汇NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271063'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'eaebe610-6b0d-446f-b156-b9dc941368ca',
        name: '四川省成都市成都武侯区人民南路四段来福士NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '180029'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'eb525003-03fc-48fe-9821-2503be419aff',
        name: 'NIKE北京西翠换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3735'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'eb563e1b-9175-4a24-9914-07767c3fb7ad',
        name: '山东省寿光市圣城街全福元商厦BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '266993'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'eb8cc9fd-3359-43b0-bfbe-fe250f1cfc1c',
        name: 'NIKE重庆洋河换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3610'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: 'eb948ef4-a201-4931-b8fa-fcb21484cae9',
        name: 'Converse Factory Store - Seattle Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3779'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'eb99e47b-5d7b-4348-870a-fd204f79ec3c',
        name: '安徽省合肥市宝胜合肥瑶海区家天下BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '267192'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'eb9b888f-672a-426b-bc47-9a1ec2896c9c',
        name: '河北省保定市雄安新区容城惠友购物广场Beacon L2-Extended',
        region: 'GREATER_CHINA',
        storeNumber: '270845'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'ebaab9bc-2a44-48c1-8140-c20da763b9a6',
        name: 'Nike Factory Store - Light',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6314'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ebad3135-4670-4c6c-85f1-e1d453bf3116',
        name: 'Kunming Wangfujing FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6037'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ebd6b7ff-58b9-43d5-92c0-77981376ee60',
        name: '北京农大南路华联上地购物中心NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '260634'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ebd9bb37-09da-417c-8c83-8b9aacd9f858',
        name: '广东省中山市盛世长运中山利和广场 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '258745'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ebde2845-6e61-43fc-aba9-6097addcf1aa',
        name: 'TOPSPORTS河南许昌魏都区建安大道与八龙路胖东来天使城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288059'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ebe9aedd-38bd-4f8c-8af6-d08d9489ca1e',
        name: '安徽省长丰市安徽省合肥市滔博合肥北城万达广场NK',
        region: 'GREATER_CHINA',
        storeNumber: '260914'
      },
      {
        address: {
          country: 'ARG'
        },
        id: 'ec045490-5ca0-4527-bdb9-1cef9f6c634f',
        name: 'NSO Abasto',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6529'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ec1904ae-5a76-4260-b9d6-a3ad288f4193',
        name: '广东省佛山市顺德区南霞新路美的万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272719'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ec1fa822-137a-4a0a-94ec-ec33cc1b3fd3',
        name: '云南省昆明市昆明五华区沿河路/顺城购物中心BEACON L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '481627'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ec5ca631-33c2-4504-82da-32c0b00ecbe5',
        name: '四川乐山土桥BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '519021'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd99e4ddd-3a04-4da8-af15-7a4b1648727f',
        id: 'ec69f924-457c-4e00-9b24-ec86cf14bfdf',
        name: 'Nike Unite Madrid SSRR',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '815'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ec6f25e7-b1b0-4b48-8361-e13a55b3add2',
        name: 'POUSHENG安徽合肥包河万象汇NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '294007'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: 'ec7c48d7-4081-4ff8-9e21-fd3b210f1fd1',
        name: 'Converse Factory Store - The Pike Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3104'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'eca20164-9fe0-4294-a851-9a05bf9ccf07',
        name: '나이키 울산 달동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '65760'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'eca23140-d85a-48a3-b9e4-451a4a84bc20',
        name: '나이키 패션아일랜드 대전',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '64295'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ecac1a8d-a799-4fcb-92d7-d25f2a505ab6',
        name: '나이키 통영',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '69360'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ece7396d-b647-40b6-a131-3775f088adb1',
        name: '安徽芜湖镜湖苏宁广场BEACON  L2EXTENDED店',
        region: 'GREATER_CHINA',
        storeNumber: '481494'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: 'ed27416d-b3a6-4f5e-846b-af46199cc85b',
        name: 'NIKE贵阳机场换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3907'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ed432264-d87d-4382-ae37-922f0dda6901',
        name: 'POUSHENG浙江省金华市世贸中心SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274490'
      },
      {
        address: {
          country: 'ALB'
        },
        id: 'ed5fee39-a370-4166-9478-a473adb0bc29',
        name: 'Nike Outlet Tirana (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'ALB101'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ed63187a-c5a0-4195-8634-7b51c467ae69',
        name: '나이키 전주 송천동',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '48939'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ed688d23-db1b-48c6-97f2-3a21823891d9',
        name: '浙江省温州市锐力温州五马街中百商场BEACON-L2-L(1200)',
        region: 'GREATER_CHINA',
        storeNumber: '771'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ed778d35-c1d2-44b6-bee3-b0271ffa90b6',
        name: '云南省昆明市昆明盘龙区北京路恒隆广场BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '243425'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ed7c4d21-15c7-4f23-afa2-11c321f044f1',
        name: '海南省琼海市百丽琼海东风路宝真商业广场店NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '258624'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'edd69a42-7abe-48df-ba4f-fdf645818df2',
        name: '北京丰台西铁营万达NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '259070'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'edd6eb72-2275-42cf-b50a-7e5c14520648',
        name: '江苏省无锡市无锡惠山万达胜道运动城Beacon',
        region: 'GREATER_CHINA',
        storeNumber: '266704'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ede72c9e-0880-41ac-841e-9d038baf3996',
        name: '广东省佛山市南海区金沙洲金铂天地 BEACON-L2 EXD',
        region: 'GREATER_CHINA',
        storeNumber: '260727'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ede983fb-4a6b-4093-b708-e9ddb2d13f64',
        name: '广西壮族自治区贺州市南宁百货 NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '271323'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'edf3101f-576e-497c-a3ef-390d6a82ca02',
        name: '江苏省常州市常州金坛吾悦广场KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '241183'
      },
      {
        address: {
          country: 'ESP'
        },
        districtId: 'd018da20-ba06-4a8b-82d2-eaa2a5670c5d',
        id: 'edf70724-0f90-4e48-b692-5eaef779a5ed',
        name: 'Nike Unite Sevilla',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '673'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ee073951-c5e8-496c-a812-d89f98ff4de4',
        name: 'TOPSPORTS广东梅州梅江区金燕大道122号万达广场3F层NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287335'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ee0c54c9-cb6d-419c-ad69-33b248a24bb1',
        name: 'POUSHENG湖南娄底氐星路新城吾悦NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '293504'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ee0d431a-1a74-4d20-ae08-b430618c067f',
        name: '北京石景山喜隆多购物中心KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '262428'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ee125b81-2357-4692-a9b6-351113038260',
        name: '山东省泰安市泰山区新城吾悦NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271189'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ee186eca-b941-410e-9494-d0ee93471337',
        name: '江西省萍乡市百丽江西萍乡市安源区楚萍东路38号润达国际3F NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269226'
      },
      {
        address: {
          country: 'CHL'
        },
        id: 'ee342987-b0f8-4ef9-ac99-0500e130dd83',
        name: 'Nike Florida',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6160'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ee416554-26c5-4a84-84d6-c32bb2a6cdf2',
        name: '나이키 구미 중앙',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '87385'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'eea3d15c-5bb3-4611-95a7-0c0ddd1ac89f',
        name: 'Nike Senayan City',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6089'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'eecaeb26-fef8-4811-b98e-c9a8d9cbd980',
        name: '나이키 덕이',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '60434'
      },
      {
        address: {
          country: 'CAN'
        },
        districtId: '9ff5ff5b-cfc0-4222-8581-6f18329cf434',
        id: 'eee8ba63-9a07-4f7b-a22d-baae06540275',
        name: 'Nike Factory Store - Kitchener',
        region: 'NORTH_AMERICA',
        storeNumber: '283730'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'a1004c17-3c88-45e2-81a9-15d73108b589',
        id: 'ef01fafd-cb08-48d1-b87c-28584f54a10d',
        name: 'NIKE昆明昌源换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3706'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef2896ab-d7eb-4c21-a33e-19b457e88b66',
        name: 'Haikou  Wanning Capital FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6032'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef2a38e8-7d2b-42e9-bf6a-b918edbf1e7c',
        name: '浙江省余姚市滔搏宁波余姚四明西路万达广场Beacon L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '194035'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'ef2c6a24-eaec-4026-9c2a-362d1bd15650',
        name: 'Nike Ahmedabad One',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5044'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'df6aa038-b7e1-4fd8-b759-7e60d9f8dc43',
        id: 'ef2dadb2-61a2-47d9-bb73-69b1c217c668',
        name: 'NIKE兰州北环换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4023'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef374b07-86a2-4efc-87f0-b24ffb2c83ef',
        name: '广东省深圳市百丽广东省深圳市龙岗区翔鸽路2号万象汇 4F Nike Sport-M',
        region: 'GREATER_CHINA',
        storeNumber: '270843'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef41803e-971d-4c12-a43a-d43fc7e89998',
        name: '辽宁省大连市甘井子区山东路亿合城 Beacon-L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '258589'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef4237ef-d734-4643-a1be-e63b5c9cd247',
        name: '浙江省乐清市锐力温州乐清伯乐东路银泰百货BEACON EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '258586'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef4314b9-005f-4a80-a8d3-7ba72dc9c55f',
        name: '广东省广州市百丽番禺永旺梦乐城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '530971'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef489f16-7299-46b7-b1e0-ffc3070ad370',
        name: 'REALLY福建漳州南昌中路6号NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '279077'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'de5b82a0-e649-4103-9387-cf868dd85899',
        id: 'ef4d5095-609e-4366-998f-53ed4d2ff9a0',
        name: 'Nike Well Collective - Hoboken',
        region: 'NORTH_AMERICA',
        storeNumber: '454'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ef535040-4497-4965-a590-9b73031dc911',
        name: '宁夏回族自治区银川市建发现代城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272123'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '66590f61-0956-411b-b290-faa23fdcb884',
        id: 'ef61da46-0d6d-452b-bb9a-22ffae0ea63e',
        name: 'Nike Unite - Atlantic Station',
        region: 'NORTH_AMERICA',
        storeNumber: '272'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: '40880d74-de09-4fd5-81ce-fa42cca1d744',
        id: 'efa568a3-e2aa-4977-bffb-1718b406990b',
        name: 'Nike Unite Bournemouth',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2078'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'efa8fa24-78de-4573-96c0-de99c67c67fb',
        name: '耐克济南印象城体验店',
        region: 'GREATER_CHINA',
        storeNumber: '246711'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'efaa0eb6-8104-46d5-bc0d-1aa36194d365',
        name: '天津宁河吾悦广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272716'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '2ff34fbb-a74f-4e02-b64e-d8a918560628',
        id: 'efda66a7-a7ac-4cb8-ae44-af5805f7729a',
        name: 'NIKE北京草桥换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3824'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'efe3466d-8e04-40a7-a350-bc354121d8ff',
        name: '나이키 목포 하당',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '56320'
      },
      {
        address: {
          country: 'MEX'
        },
        districtId: '011c974d-ad7b-4a21-ae1b-ff52a2a066cd',
        id: 'efe69299-62ca-4c60-91a3-b85faf6b78bb',
        name: 'Nike Factory Store - Cuernavaca',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6636'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'efeb8c69-06ef-402a-a0f2-1222d549200e',
        name: '广东省东莞市盛世长运东莞民盈山国贸中心Beacon L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '482414'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'efee64e6-62e0-4ac3-a0fc-5e05f3cbe2e2',
        name: '浙江省苍南市锐力温州苍南银泰城KL L3',
        region: 'GREATER_CHINA',
        storeNumber: '266937'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '4d436642-e2c9-4253-bca2-0b5df3c4bbe0',
        id: 'f00f5db3-cdeb-4e9c-a3fe-518e5ab9de95',
        name: 'Nike Factory Store Wolfsburg',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '613'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '45f3854f-83d2-4f5d-9ccd-2dc96b72fc84',
        id: 'f0452008-7aa7-4093-bc4f-f66cf3cf6c2d',
        name: 'Nike House of Innovation Shanghai',
        region: 'GREATER_CHINA',
        storeNumber: '3408'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f04f720a-f8ff-4ef1-ad85-f5841e0f491c',
        name: '山东省淄博市张店区淄博万达广场Beacon-L2 EXT',
        region: 'GREATER_CHINA',
        storeNumber: '267118'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f04fd5f2-2dac-4cbe-b7fe-69f845612353',
        name: '云南省昆明市官渡区世纪金源购物中心A栋NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '282186'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f04ffe7e-dc36-4640-953a-01bbd6e550ed',
        name: '锐力上海宝山一二八纪念路宝山万达RISE',
        region: 'GREATER_CHINA',
        storeNumber: '293955'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f0650c75-94d9-4200-9650-81b62f59dac4',
        name: '나이키 롯데 서면',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '39834'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '91733885-7e73-49ee-9e69-ab42e3ceeb71',
        id: 'f07c95b9-66ea-45d4-9fa5-cbc8cee932c7',
        name: 'NIKE苏州阳澄换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3821'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f0876933-c372-4cd1-ae54-fd6c69c1575e',
        name: '江苏省南京市滔搏双龙大道景枫Beacon 750',
        region: 'GREATER_CHINA',
        storeNumber: '267201'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f08af4ad-d825-4813-90cd-fdb666f72efb',
        name: '云南昆明西山爱琴海购物中心NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '274275'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f0a5eebb-f32a-46bc-a2d5-95dc7d619421',
        name: '武汉洪山珞喻路群光广场SP',
        region: 'GREATER_CHINA',
        storeNumber: '258617'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f0b2fdbb-f673-43b5-8489-218a750b23f3',
        name: 'TOPSPORTS山东济南莱城区文化南路茂业NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '282158'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f0c62014-83aa-4e12-bc9c-c332aeab64d4',
        name: 'REALLY 江苏扬州江都万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287209'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '51a1785a-96a0-4fb2-a5d8-c3d531ada5b3',
        id: 'f0dc0c76-f99a-4153-8b84-59784bc39d26',
        name: '나이키 해운대 CS',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6113'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f1082168-bdae-4123-b761-12805b45bd50',
        name: '陕西省西安市长安区阳光天地购物中心NIKE_SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '268887'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '1e9e58b7-a2be-470e-81e2-fd02aca3b48a',
        id: 'f146f367-f3ef-4982-baed-d33b09bf69ad',
        name: 'NIKE武汉高龙换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3998'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f1528e6d-2a0e-4060-908d-7fe310ba8b48',
        name: 'TOPSPORTS黑龙江省齐齐哈尔市龙华路新玛特NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '281946'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f19debda-3021-4a75-b02e-1f913bb5ee55',
        name: 'Nike Well Collective - 갤러리아 광교',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '90635'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f1b98018-f719-40de-be33-8ff9b0018569',
        name: '湖北省宜昌市湖北宜昌兴发广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '270426'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f1c5579e-b4b1-4d08-8a42-cc9ff10cc965',
        name: '조던 서울',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '91190'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'f1e6a906-235b-4012-819e-ef7e8c5edc3a',
        name: 'Nike Ayala Fairview Terraces',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2394'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f1ed33a2-5abf-45c6-86e9-8e9cd33ed72a',
        name: '重庆江北北城天街JORDAN-L1',
        region: 'GREATER_CHINA',
        storeNumber: '272591'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f1f0418f-164a-49a5-af99-f9f30e9826da',
        name: '四川省成都市盛和一路凯丹广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '187487'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: 'f20d5e42-bc3a-479f-98ee-158095ea4c83',
        name: 'NIKE东莞莞长换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3736'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '8dffbe7e-cf8f-4017-b2f3-7282828d4735',
        id: 'f22f3d7d-b7de-46bf-9dfb-d2f4cf177d86',
        name: 'Converse Factory Store - San Marcos Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3704'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'f2437d00-21dd-486d-a1f9-4bd8757734bd',
        name: 'Nike Store YANBU',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA066'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: 'f24ab20c-8037-11e6-ae22-56b6b6499611',
        name: 'Nike Miami',
        region: 'NORTH_AMERICA',
        storeNumber: '381'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f24b7a51-6315-49ef-8bea-02d1a62931e5',
        name: '黑龙江省哈尔滨市黑龙江省哈尔滨群力新区第五大道王府井购物中心BEACON-L2 Ext',
        region: 'GREATER_CHINA',
        storeNumber: '194813'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f25e328b-1a38-4929-9dd9-2c6f5ad8de90',
        name: '浙江省义乌市宝胜义乌之心Beacon 750',
        region: 'GREATER_CHINA',
        storeNumber: '267099'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'f262a143-60ad-43fb-aa38-24c1802df171',
        name: 'Nike Store TAIF',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA070'
      },
      {
        address: {
          country: 'TUR'
        },
        districtId: 'b19c0c8e-eb13-492f-b2a9-4486e9d5bb40',
        id: 'f275f3f2-18c0-42eb-87e4-599f106c39e4',
        name: 'Nike Factory Store 212 Power Outlet',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2023'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f28819a2-e34d-4ae0-ad0a-0a956861dec3',
        name: '重庆市沙坪坝区土主镇融创文旅城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '267216'
      },
      {
        address: {
          country: 'PRT'
        },
        id: 'f2a1afaa-550f-4f92-b971-582bca048c7e',
        name: 'Converse Brand Outlet Store Lisbon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'C8132'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f2a7b4c1-bb86-4ec0-a3c0-ff769146d761',
        name: '나이키 현대 울산 동구',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '28423'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f2b6a19f-ace6-4c1b-9033-b5e2e16cf183',
        name: '广东省广州市宝胜广州天环广场KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '243759'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f2baf15e-7a64-4485-8588-ca93c4aabf1b',
        name: '江苏省张家港市张家港曼巴特BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '111488'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f2dbac38-d453-45ab-9149-b37c86191425',
        name: '浙江省宁波市锐力鄞州区钱湖北路万象汇BEACON750',
        region: 'GREATER_CHINA',
        storeNumber: '267163'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f2e14674-6bce-41c5-9880-a56fbcd8dc2d',
        name: 'POUSHENG江苏无锡三阳百盛KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '288489'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f2e429b2-f095-46b7-9332-362d86e23975',
        name: '广东省珠海市宝胜茂业百货  beacon-ext',
        region: 'GREATER_CHINA',
        storeNumber: '268880'
      },
      {
        address: {
          country: 'BRA'
        },
        id: 'f2f5cb7b-0986-462f-a4ab-0be960ed7310',
        name: 'Nike Factory Store - Alexania',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6322'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3119a07-23ee-4f17-8b27-40244b61e9a2',
        name: 'HIGHWAVE SPORTS重庆民权路大融城NIKE RISE',
        region: 'GREATER_CHINA',
        storeNumber: '287289'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: '1026dbdd-5a1b-4809-82cd-d925ef9a6d49',
        id: 'f339f8d5-9fde-42e0-891a-57cca695a624',
        name: 'Nike Unite Kitakyushu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3196'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'f8a4616a-8d6e-11ee-b9d1-0242ac120002',
        id: 'f34b5d72-8c06-427d-88b3-b1cb46698176',
        name: 'Nike Unite Merry Hill',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2071'
      },
      {
        address: {
          country: 'FIN'
        },
        id: 'f34c74d0-cf16-4918-b783-406e01aa8bfe',
        name: 'Nike Well Collective - Itis (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2410'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f36136df-2535-4344-b6de-25ce28063659',
        name: '河北省张家口市张家口宣化区光大新天地BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '244476'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f36fd158-b0a4-4fcd-bdc4-98d223cd2df9',
        name: '北京通州爱琴海NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '271043'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f3749315-5082-42fc-a025-a1dd065e4145',
        name: '나이키 거제',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '78532'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f378fd94-d349-42d4-832d-7367e6048aeb',
        name: '耐克无锡商业大厦体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260204'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f37ae140-6b9d-4e17-b363-ba675cadf15c',
        name: '四川巴中市王府井广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '258777'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: 'f383d8c1-03b1-4228-a1de-9a8cbc4388fe',
        name: 'NIKE深圳艺展优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4072'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'af5d9302-afc2-430f-a888-c74fa23805bc',
        id: 'f386b1ca-288a-4d1d-a603-da95552f342f',
        name: 'Converse Factory Store - Outlets at Sparks',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3745'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'f395735b-b78f-4d5e-868f-68e2adea1dc3',
        name: 'Nike Connaught Place',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '6765'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f39ce4b0-20f0-4eeb-99cc-12e85e1e0892',
        name: '나이키 더현대 서울',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '92181'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3a05345-ff55-406f-8af5-69fbc75fe99d',
        name: '陕西省西安市西安会展中心万象天地BEACON-L2-M(750)',
        region: 'GREATER_CHINA',
        storeNumber: '272727'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'f3b31d1a-ccb4-4a2f-a163-96f235d265b5',
        name: 'Nike SM MOA',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7347'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3bf72b2-4135-4a37-8871-3992959ffb5e',
        name: '广东省深圳市盛世长运深圳来福士广场KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269938'
      },
      {
        address: {
          country: 'CAN'
        },
        id: 'f3d066b6-6c80-4013-aec3-0dec399d956d',
        name: 'Nike Eaton de Montreal',
        region: 'NORTH_AMERICA',
        storeNumber: 'S233'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3d2a9f4-2993-41f8-8783-04b7df224e8d',
        name: '江苏省扬州市锐力京华城路金鹰国际购物中心 Beacon 750',
        region: 'GREATER_CHINA',
        storeNumber: '269225'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3d3ae02-65dd-4c77-9847-188e28fca34f',
        name: '河北省高碑店市悦百汇Beacon L2-Extended',
        region: 'GREATER_CHINA',
        storeNumber: '270846'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3d47b98-b748-417a-b71a-934722b109d1',
        name: 'TOPSPORTS山东潍坊福寿东街谷德广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287579'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '92c6ee55-0adc-4eab-b0cc-256a9e8b04dd',
        id: 'f3dc87e1-8f9f-4e62-94c4-e2a157fc93a0',
        name: 'Nike Clearance Store Salerno',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '690'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3eb487e-3cb9-4daa-ad83-c723dc9d3561',
        name: 'HIGHWAVE SPORTS四川崇州永康东路万达广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '287269'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f3f240fe-247b-4fb6-a735-2d0ca5e9f71b',
        name: '江苏省常州市锐力常州延陵西路泰富百货NSW BEST',
        region: 'GREATER_CHINA',
        storeNumber: '6149'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'aedd4b07-bd95-46bc-bedc-c1dafa8b491e',
        id: 'f408456b-9d8e-4c5f-90b0-923c2612e1f5',
        name: 'Nike Factory Store Lille',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '917'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'c64cdc24-2b56-4f6c-bbd7-58f2d0a3fda0',
        id: 'f4102262-cdb8-4e05-b2fc-44b8d9f0e03e',
        name: 'NIKE舟山海印换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3911'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f41e6e0e-e7b0-4588-904b-4283445db463',
        name: 'TOPSPORTS内蒙古赤峰玉龙大街众联广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '12282'
      },
      {
        address: {
          country: 'IRL'
        },
        districtId: '0edcb3f7-a768-4ade-83d0-d0e5365d6dc5',
        id: 'f4281a9c-3570-45e2-b6f0-7c85164799a4',
        name: 'Nike Well Collective - Dundrum',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2515'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f432fb02-bb37-4593-b51d-ed2e3a13e76e',
        name: '陕西省西安市碑林区开元商城KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '260315'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '9d821243-a553-4e4c-a3fd-9f7430d01bc5',
        id: 'f440f346-f422-4131-ae87-8bcb8a790822',
        name: 'NIKE厦门环岛换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4031'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f4460c63-e497-49ff-bb30-23dc63016fb9',
        name: '北京海淀五棵松华熙TOTAL HOOPS-L1',
        region: 'GREATER_CHINA',
        storeNumber: '190902'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'f4487681-1239-487d-a1d1-f1e47f970e81',
        name: 'Nike Galleria - Quezon City',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2389'
      },
      {
        address: {
          country: 'BGR'
        },
        id: 'f45ee2f0-cb29-4320-875d-6c469a6bc9ae',
        name: 'Nike Outlet Mladost (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'BUL128'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'f461f756-e659-4c10-bfbd-1998605ae091',
        name: 'Nike Store Adriatico (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '783'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: 'bf94e3d6-a7b9-4ff4-a2a3-099641f92e42',
        id: 'f462eb4f-adc8-4b35-aaa2-d5d8087a6635',
        name: '나이키 유나이트 송도',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8102'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f476c257-c178-4ae9-976f-f46ad568fca3',
        name: '나이키 양산 뉴타운',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '99688'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'f4861631-7c2d-471f-90c9-da0bb9dbf9c7',
        name: 'Nike Rohini',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4731'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'f48af8a6-0ca8-468e-819d-bc598603cdff',
        name: 'NIKE长春永顺换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4008'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f4a2ba37-79e2-4745-a544-a226aab9148e',
        name: '南京秦淮中山南路新街口百货SP',
        region: 'GREATER_CHINA',
        storeNumber: '188592'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'f9f191cd-6cf3-4497-a8a9-47565b127244',
        id: 'f4c03068-6df8-4547-8ad9-03cf6371ace8',
        name: 'Nike Unite - Tulsa',
        region: 'NORTH_AMERICA',
        storeNumber: '494'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f4ca066f-8a4d-4217-b42c-23dcefbb8996',
        name: '河北省廊坊市廊坊市广阳区新华路万达百货BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '241163'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f4d1a60a-272d-48c2-99b9-0d8e053f6b6e',
        name: '山东省青岛市台东区利群商厦BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '262398'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f4d69f59-8515-4b78-9b1d-8bf33e9c63d5',
        name: '陕西省西安新城长缨西路万和城购物中心NK SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288065'
      },
      {
        address: {
          country: 'ZAF'
        },
        id: 'f50a58cf-ee74-4585-bc76-882c353251fe',
        name: 'Nike Sandton (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2300'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f536ee5b-d141-4ef5-bdd2-209509c319ec',
        name: '重庆涪陵兴华中路涪陵商都NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '288847'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f53e72a2-490e-4c36-8d34-b57fb73a387d',
        name: '黑龙江省七台河市桃山区新玛特NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272251'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f541a042-8656-40fc-a62e-17142886dce4',
        name: '广西壮族自治区桂林市百丽桂林叠彩万达广场店SP-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '330141'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f565c3dd-ecc5-4321-a792-7a3498080245',
        name: '河北省定州市河北定州市万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274144'
      },
      {
        address: {
          country: 'KAZ'
        },
        id: 'f568f5dd-7ecf-4fb1-b5c3-bec3edc96dd3',
        name: 'Nike Store Almaty Mega (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KAZ022'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f589ac20-38bd-4204-a019-cd5107caa85f',
        name: '内蒙古自治区呼和浩特市维多利时代城BEACON L2 750',
        region: 'GREATER_CHINA',
        storeNumber: '267094'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f5b73ca2-e169-4b1d-b98a-26008d5781fb',
        name: '河北省涿州市范阳中路惠友钻石广场NIKE SPORT M',
        region: 'GREATER_CHINA',
        storeNumber: '269353'
      },
      {
        address: {
          country: 'KOR'
        },
        districtId: '46b3c735-a7bc-4f24-97b2-e8ae23086077',
        id: 'f5b7aa55-5932-4730-880c-021cd133eb08',
        name: '나이키 클리어런스 스토어 이천',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8101'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f5bb917b-0da4-4894-8b7b-d30997e56625',
        name: '安徽省滁州市滁州苏宁广场BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '189400'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: 'f5c6464b-f1e9-4cc6-902e-da3d5e47199c',
        name: 'Converse Factory Store - Phoenix Premium Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3778'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f626b241-0eb7-4c42-80b9-e9efacde6d97',
        name: '辽宁省沈阳市和平区太原里HOOP DREAMS-L1',
        region: 'GREATER_CHINA',
        storeNumber: '243027'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'f62765c2-9104-463c-9830-99cc62900d49',
        name: 'Nike King Fahad',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA108'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f65211e3-50f0-48f2-9993-d282482f805c',
        name: '四川省成都市高新区银泰城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '269210'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'f65e19a6-edbc-4489-b661-dd9ed5bf3a59',
        name: 'Nike Store Kemeraltı (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2611'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '3354e3fb-9285-47ab-a3ce-13fcff9bf71c',
        id: 'f67285bf-935a-493a-9b18-74609f98ea57',
        name: 'Nike Factory Store Krakow',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '662'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f6755c11-1e7e-482c-9e69-6cf7c472bd43',
        name: '河北省石家庄市长安区中山路勒泰购物中心BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '529872'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'f69a09d0-7ebf-42b8-8fc1-a04de6b53517',
        name: 'Nike Inorbit Mall Hyderabad',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5098467'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'f6a2eb5a-3f04-4f37-ae76-8d6006babc28',
        name: 'Nike Store Forum Kayseri (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2678'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f6cd7c54-513a-49e1-9b6f-9dc5bab8f001',
        name: '上海市上海崇明万达广场BEACON EXT',
        region: 'GREATER_CHINA',
        storeNumber: '270720'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f6f3e8c4-69cc-49d1-bbae-641fb9d1ecf6',
        name: 'TOPSPORTS河南洛阳中州中路王府井KICKS LOUNGE-L1',
        region: 'GREATER_CHINA',
        storeNumber: '288180'
      },
      {
        address: {
          country: 'ISR'
        },
        districtId: '80d72218-6d2b-46e2-bea0-2210cb625fbb',
        id: 'f7300ba1-3bac-459e-9531-ce855429cb9c',
        name: 'Big Eilat Nike Factory Store',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '967'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: 'f781edba-9cf5-46d2-ad1f-1d445a8df361',
        name: 'Nike Unite - Glenarden',
        region: 'NORTH_AMERICA',
        storeNumber: '420'
      },
      {
        address: {
          country: 'BEL'
        },
        districtId: 'aedd4b07-bd95-46bc-bedc-c1dafa8b491e',
        id: 'f7bbcda9-aa7e-49b6-992c-ec79d6ee56be',
        name: 'Nike Clearance Store Mons',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2006'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'f7bd2838-521b-4ec9-bb2d-6171ea65a816',
        name: 'Nike Store KentPlaza (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2686'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f7f120f2-1a84-439d-a73e-6ab17357ab87',
        name: '江苏省南京市南京德盈虹悦城BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '453853'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'f83bfec1-b47e-4490-b226-07c7f3012393',
        name: 'Nike Guwahati',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7009'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'f8548ec3-0ad3-4f37-9f34-621dc22198e6',
        name: '나이키 상주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '21583'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f891c9cd-fdc6-45c6-aa84-72418414f7d3',
        name: 'Wuhan Florence FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6023'
      },
      {
        address: {
          country: 'MAR'
        },
        id: 'f8971a95-db42-4a8a-af9d-63aaf0460429',
        name: 'Nike Store Rabat (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MOR005'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'f8a44b34-3eae-4b6f-a6b7-7c668ce9565d',
        name: 'NIKE成都岷江换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '4020'
      },
      {
        address: {
          country: 'IDN'
        },
        id: 'f8bb75f1-ac23-4908-8a6d-d77c98d6296a',
        name: 'Nike Ska Mall Pekanbaru',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '4971'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '6f6e8036-8809-4f7d-9afa-a80558caf025',
        id: 'f8cf418a-38b0-4451-af3a-3566936f6280',
        name: 'Nike Aventura',
        region: 'NORTH_AMERICA',
        storeNumber: '478'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f8d427ad-b852-4d83-996c-577a6fa552b1',
        name: '浙江省杭州市双键杭州萧山印象城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '272103'
      },
      {
        address: {
          country: 'HKG'
        },
        districtId: '4da1e8ed-2893-4ac4-a578-e03e4ac4c5aa',
        id: 'f931f680-923e-48a1-bb63-7b49db00f88b',
        name: 'NIKE香港盛泰道換季優惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3269'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'f9515165-8b2a-4823-870f-9d5b8b2e8b57',
        name: 'NIKE沈阳长青换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3453'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'f95282f8-8395-42d9-8027-01eb507ef354',
        name: 'Nike Salama Centre',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA053'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'f9532c35-0681-41c9-946c-37811828d752',
        name: 'NIKE重庆向阳优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4034'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'e3e74059-d6af-4b01-97ab-7fabe9559476',
        id: 'f9556173-27af-43fb-9993-0a3e316f9492',
        name: 'Nike Unite East Kilbride',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '959'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: 'b548f7f0-840f-4f19-86c4-1d4ed8f754b5',
        id: 'f97c956e-c46f-4726-a2b0-553fd1b73368',
        name: 'NIKE沈阳大堤优选体验店',
        region: 'GREATER_CHINA',
        storeNumber: '4042'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: 'f97d256d-1905-44ee-9ed7-93e24843d4af',
        name: 'Nike Unite St. Denis',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '520'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '3b20bf35-6bba-4024-ba55-9f1af89e4081',
        id: 'f9816f6e-4579-44f4-8e59-22072d76068d',
        name: 'Nike Factory Store Radolfzell',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '978'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f98b3be4-a9a4-496a-a190-32fe47628182',
        name: '山东省滕州市滕州银座 NSW-SIS',
        region: 'GREATER_CHINA',
        storeNumber: '13179'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f9af56ec-93b1-476d-94a6-33a70cbd3433',
        name: '江苏省南通市百丽南通八佰伴NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '102130'
      },
      {
        address: {
          country: 'IRL'
        },
        districtId: '0edcb3f7-a768-4ade-83d0-d0e5365d6dc5',
        id: 'f9b2e520-7686-457a-a4d5-473e5994a307',
        name: 'Kildare Nike Factory Store',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '589'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f9b38bbf-94ff-4c4a-b80a-31624b1c1f72',
        name: '河南省洛阳市长申国际 BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '260990'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'f9b5bc82-e76d-408a-a3bf-d6c9b3c4ba3d',
        name: 'BARCIN – NIKE BAYRAMPASA',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2353'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f9b8d7c8-c4b8-4b8b-93ff-21284b40aeec',
        name: '江苏省苏州市滔搏苏州长江路绿宝百货 BEACON 350',
        region: 'GREATER_CHINA',
        storeNumber: '528275'
      },
      {
        address: {
          country: 'UZB'
        },
        id: 'f9c454b9-0adc-4b01-b569-9292acfaf02c',
        name: 'Nike Store Tashkent City Mall (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'UZB004'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f9c65bb6-7597-45d0-8fce-1e6c59456986',
        name: 'JORDAN 99 HUBIN DONG',
        region: 'GREATER_CHINA',
        storeNumber: '526890'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'f9ed2682-d888-4fdf-aeba-db742562d9c3',
        name: '耐克宁波天一广场体验店',
        region: 'GREATER_CHINA',
        storeNumber: '259069'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '92c6ee55-0adc-4eab-b0cc-256a9e8b04dd',
        id: 'f9ee406e-d059-4b5c-a6a5-8cfd922cad7f',
        name: 'Nike Factory Store Molfetta',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '601'
      },
      {
        address: {
          country: 'CZE'
        },
        districtId: '3c364fbe-617b-46b1-b867-1579b1e478f2',
        id: 'fa12c07c-76de-44bf-897e-a4c2008129a1',
        name: 'Nike Factory Store Prague Fashion Arena',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '611'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fa369cb3-774f-4fc3-a1d9-9aacacebb1f3',
        name: '山东烟台莱山区观海路华润万象汇NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '271293'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fa3f6380-8177-494c-9cbb-037b618fea44',
        name: '广东省广州市盛世长运广州美林商业广场 BEACON-EXT',
        region: 'GREATER_CHINA',
        storeNumber: '242760'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '9f153b80-e0fd-4480-8a9b-3fc027b984a3',
        id: 'fa685744-80ee-4625-a24f-cae471e28c82',
        name: 'Nike Unite - Silver Spring',
        region: 'NORTH_AMERICA',
        storeNumber: '468'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fa6c4d23-a41d-4577-96f8-276054c0fbb3',
        name: '江苏省南京市新尧金地广场KL-L2',
        region: 'GREATER_CHINA',
        storeNumber: '269222'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'fa6edb51-e7f9-45f9-b78d-bd53f7e5060f',
        name: '나이키 모다 경주',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '56815'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fa75a4e3-da78-4074-9160-da5688872d6b',
        name: 'POUSHENG新疆喀什万达广场NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '12293'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fa937a6f-e827-40e5-a3da-43a16e1b209c',
        name: 'POUSHENG江苏无锡蠡溪路滨湖万象汇KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '293471'
      },
      {
        address: {
          country: 'MYS'
        },
        id: 'fa95c45b-ded6-422e-ad62-73990c83b45e',
        name: 'Nike Ipoh',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9466'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'faa177c1-11cd-4d5f-b722-0c7410aa7db9',
        name: '陕西榆林市榆林万达广场Beacon-L2 ext',
        region: 'GREATER_CHINA',
        storeNumber: '246725'
      },
      {
        address: {
          country: 'POL'
        },
        id: 'fab0a4bf-f38d-4b3c-8433-0cdd56852330',
        name: 'Nike Store Arkadia Warsaw (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2489'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'faea3c29-6d8c-499f-a928-efc7369559a1',
        name: '江苏省南京市浦口区江北虹悦城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '272967'
      },
      {
        address: {
          country: 'THA'
        },
        id: 'fb11a0e7-e3e0-4c86-84be-d2628feb609c',
        name: 'Nike Store Central Phuket Floresta',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '2750'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fb1217a9-c09a-473c-af5d-3d187166b3e3',
        name: '贵州省贵阳市贵阳南明区花果园购物中心Beacon L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '57199'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fb543154-027b-4185-8568-f394c9b6e354',
        name: '上海市锐力上海平凉路百联滨江购物中心SPORT S',
        region: 'GREATER_CHINA',
        storeNumber: '246582'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fb60aff1-be05-45e8-9154-ebb4ff747f61',
        name: '上海市百丽上海上海吴中路万象城店BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '523175'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fb782144-ea5e-46ea-ba74-4cf7584a2712',
        name: 'TOPSPORTS江苏宜兴解放东路八佰伴NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '189272'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: 'bf7b87f4-0c97-477a-9e89-29308ba4f737',
        id: 'fb7a1157-2637-426c-a301-cc22f4cfb26e',
        name: 'Converse Factory Store - Citadel Outlets',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3755'
      },
      {
        address: {
          country: 'SAU'
        },
        id: 'fb7a3756-6e3f-4d28-8ba0-748d88a5d453',
        name: 'RASHID MALL',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KSA055'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '89f63b5e-a00c-4043-b80e-a062e72e6c38',
        id: 'fb8af310-66c2-41af-910c-382caa6f3722',
        name: 'Converse Factory Store - Opry Mills',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3763'
      },
      {
        address: {
          country: 'MAR'
        },
        id: 'fb981372-2a42-4d41-b89f-73d5492eed97',
        name: 'Nike Factory Store Ain Sebaa (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'MOR004'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'fbaf8524-8b5c-44ea-ae81-8178d3fae1c1',
        name: 'Nike Orion Mall',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '8061'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fbb0fcdf-23f3-4ce2-96af-b73ff1a94a58',
        name: '河南郑州中原路万达广场NIKE RISE-750',
        region: 'GREATER_CHINA',
        storeNumber: '288303'
      },
      {
        address: {
          country: 'KAZ'
        },
        id: 'fbc17fae-b4ea-4d56-8393-2210d6b55fb6',
        name: 'Nike Store Almaty Dostyk Plaza (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'KAZ020'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fbd0698a-8dd9-48fb-bb5a-70bf80db39aa',
        name: '陕西省西安市碑林区钟楼开元购物中心NIKE SPORT L',
        region: 'GREATER_CHINA',
        storeNumber: '267242'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '929fe395-4f1e-43ff-9225-7711308d15be',
        id: 'fc178485-4522-437a-aae0-54e257420c27',
        name: 'NIKE OFF SITE - CITADEL OFF SITE',
        region: 'NORTH_AMERICA',
        storeNumber: '9339'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fc1d2be8-2dff-4cc9-b1b9-87e08a5d8ab9',
        name: 'Shenzhen North Railway Station FOS',
        region: 'GREATER_CHINA',
        storeNumber: 'C6008'
      },
      {
        address: {
          country: 'ITA'
        },
        id: 'fc3c28b6-da8d-4f9d-a22e-bd53927870d8',
        name: 'Nike Roma Termini (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2487'
      },
      {
        address: {
          country: 'FRA'
        },
        id: 'fc3cdabd-32e3-44a1-92bb-f1c71ab65547',
        name: 'Nike Store Marseille (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2457'
      },
      {
        address: {
          country: 'JPN'
        },
        districtId: 'b3792ac9-5f24-4ee5-84cb-d95bc1366799',
        id: 'fc42058c-f183-48ed-b204-40d3ac10a2c2',
        name: 'Nike Unite Toki',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '3036'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fc5be9c8-3db7-455b-9bb4-9d0b3e1adab1',
        name: '浙江省杭州市双键萧山市心广场Beacon L2 Extended',
        region: 'GREATER_CHINA',
        storeNumber: '135891'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fc5e5fdc-b8f6-4009-8623-2917d63f7de6',
        name: '江苏省南京市锐力新街口百货KL-L1',
        region: 'GREATER_CHINA',
        storeNumber: '266726'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fc7746d0-f810-413f-86cb-f577a8b7a5c7',
        name: 'TOPSPORTS浙江余姚城东路五彩城NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '274348'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fc7cab28-22e2-46cd-b315-39a2191b348a',
        name: '广东省深圳市百丽深圳市南山区茂业百货BEACON-L2 EXTENDED',
        region: 'GREATER_CHINA',
        storeNumber: '240334'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fc7e7094-a46d-4120-abf8-49fd02eb4764',
        name: '耐克武汉江汉路步行街体验店',
        region: 'GREATER_CHINA',
        storeNumber: '179514'
      },
      {
        address: {
          country: 'AUS'
        },
        id: 'fc8ea773-5a54-4ed6-9a2e-45d5e79dafca',
        name: 'Nike Emporium',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '9350'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fc939078-313e-412d-a52c-ad4705ed2748',
        name: '广东省惠州市百丽广东惠州仲恺天益城购物中心二期NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '269349'
      },
      {
        address: {
          country: 'USA'
        },
        districtId: '2ed44a24-9b27-48aa-a0f1-ad2a51d431b4',
        id: 'fcb48874-47d6-4480-b103-2952ca5d5383',
        name: 'Converse Factory Store - Dolphin Mall',
        region: 'NORTH_AMERICA',
        storeNumber: 'C3744'
      },
      {
        address: {
          country: 'ISR'
        },
        id: 'fcc0efaf-5325-4e14-a9f8-6d832e293c4c',
        name: 'Nike Store Nazeret',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2720'
      },
      {
        address: {
          country: 'CYP'
        },
        id: 'fcdd7335-db19-4e8f-8680-b25f21e40158',
        name: 'Nike Store Girne (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2328'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'fce2b9f2-8194-4c75-8b04-2c7e80e94084',
        name: '나이키 아산',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '41274'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fce97b2b-8fa3-4c22-83c7-d7d4f7860838',
        name: '四川成都双楠伊藤NIEK SPORT M T2',
        region: 'GREATER_CHINA',
        storeNumber: '287502'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fd123c4b-9909-4ab0-824b-ada2616d281c',
        name: '江苏省无锡市百丽无锡苏宁广场NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '266730'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fd166d1a-830f-4cf3-9b96-4b2fd7ee3a5e',
        name: 'TOPSPORTS山东诸城人民西路佳乐家NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '287628'
      },
      {
        address: {
          country: 'IND'
        },
        id: 'fd2b33a9-2002-4af8-9b97-069952c33e1b',
        name: 'Nike Jammu',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '7446'
      },
      {
        address: {
          country: 'POL'
        },
        districtId: '3354e3fb-9285-47ab-a3ce-13fcff9bf71c',
        id: 'fd37556d-0307-4b51-919e-c0f0c36df946',
        name: 'Nike Factory Store Wroclaw',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '663'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fd48ceb2-d022-4e77-a754-dffbf9b34193',
        name: 'TOPSPORTS北京市石景山区京西大悦城NIKE SPORT-L',
        region: 'GREATER_CHINA',
        storeNumber: '526872'
      },
      {
        address: {
          country: 'TUR'
        },
        id: 'fd5d73fb-0514-47a7-b3f2-9d2366cfae86',
        name: 'Nike Store Kulesite (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2690'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fd6eb9a5-ce9d-4fde-8819-b68d1a793543',
        name: '上海市宝胜上海长宁来福士Beacon-L2',
        region: 'GREATER_CHINA',
        storeNumber: '246877'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fda187d1-495c-48d4-ac14-6fb20eebcbc4',
        name: '浙江省宁波市宁波万象城NK',
        region: 'GREATER_CHINA',
        storeNumber: '271059'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fdae3f1c-30b4-4128-a519-faff8cae4eff',
        name: '湖南省长沙市宝胜长沙宜家荟聚购物中心 Nike sport-L',
        region: 'GREATER_CHINA',
        storeNumber: '270990'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fdc63e87-90cb-4aaa-95a9-827d65c08fc1',
        name: '江苏省南京市南京浦珠北路桥北万象汇750',
        region: 'GREATER_CHINA',
        storeNumber: '270821'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '7e0ce3f3-34a5-4462-8400-aec5d8cd8e3f',
        id: 'fdf38409-1744-40b7-8c62-12170d73210f',
        name: 'NIKE成都锦华换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3709'
      },
      {
        address: {
          country: 'DEU'
        },
        districtId: '2f1b4c39-e8ac-4cea-abd2-95d8b9b7aefe',
        id: 'fe1b863a-ce79-4d2a-9626-bbf091bd41b4',
        name: 'Nike Well Collective - Steglitz',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2504'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fe1d3d2a-0152-416f-af36-140864fcab9c',
        name: '内蒙古自治区呼和浩特市呼市赛罕区万达广场NIKE SPORT-S',
        region: 'GREATER_CHINA',
        storeNumber: '13051'
      },
      {
        address: {
          country: 'PRT'
        },
        districtId: 'd018da20-ba06-4a8b-82d2-eaa2a5670c5d',
        id: 'fe1dcc71-77a0-4f67-80de-444830ecde4b',
        name: 'Albufeira Nike Factory Store',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '513'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fe2d7458-07ab-4fc5-b8c0-103ff08baba4',
        name: '上海市锐力上海澄浏中路百联购物中心DSP',
        region: 'GREATER_CHINA',
        storeNumber: '153545'
      },
      {
        address: {
          country: 'JOR'
        },
        id: 'fe5b5275-532f-4787-a39e-d85b92c4f79c',
        name: 'ABDALI MALL',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'JOR011'
      },
      {
        address: {
          country: 'GBR'
        },
        districtId: 'ac92a794-1790-4505-9279-186e2dfa2087',
        id: 'fe6fcb21-154c-4224-a371-1fb69d93501d',
        name: 'Nike Unite Cannock',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2052'
      },
      {
        address: {
          country: 'LTU'
        },
        id: 'fef059fa-ed95-4e18-b260-d0b43033d0c7',
        name: 'Nike Store Vilnius Akropolis (Partnered)',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: 'LIT001'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ff207ad8-7694-4d12-8ffa-4b5ee9fd0a20',
        name: '河南省南阳市新华城市广场BEACON-L2 Ext.',
        region: 'GREATER_CHINA',
        storeNumber: '530936'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ff4f63d9-ca88-4613-99b1-eeb4fba0fcf8',
        name: 'TOPSPORTS浙江湖州志成路星火NIKE SPORT-M',
        region: 'GREATER_CHINA',
        storeNumber: '2052'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'ff5b4aac-26cb-493e-82c6-5c0529c0f4b7',
        name: '耐克福州仓山万达KICKS LOUNGE体验店',
        region: 'GREATER_CHINA',
        storeNumber: '260322'
      },
      {
        address: {
          country: 'CHN'
        },
        districtId: '28aca82e-0f84-4d73-85d9-3fec40d6a8ac',
        id: 'ff697e72-137f-4fc4-960f-730178f274f1',
        name: 'NIKE深圳塘朗换季优惠店',
        region: 'GREATER_CHINA',
        storeNumber: '3932'
      },
      {
        address: {
          country: 'ITA'
        },
        districtId: '92c6ee55-0adc-4eab-b0cc-256a9e8b04dd',
        id: 'ff8ad7d8-fb47-46b7-a516-22dd1960d44e',
        name: 'Nike Clearance Store Catania',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '2008'
      },
      {
        address: {
          country: 'KOR'
        },
        id: 'ff9a2554-ed4c-4ede-ad25-b5ac6bb13c76',
        name: '나이키 롯데 원흥',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '79667'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '56770516-f6da-4a8c-bb78-6f68ab1d0959',
        id: 'ffa84e7f-74f9-41be-a323-fdc643b22a3e',
        name: 'Nike Factory Store Gennevilliers',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '975'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: 'fe144077-30cf-4be6-9316-3a64d50a3124',
        id: 'ffae1842-d02d-4cc5-96e7-98b7e49df84e',
        name: 'Nike Clearance Store Romans',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '588'
      },
      {
        address: {
          country: 'PHL'
        },
        id: 'ffbf4a49-7987-4196-9356-275645fcd5e3',
        name: 'Jordan Manila',
        region: 'ASIA_PACIFIC_LATIN_AMERICA',
        storeNumber: '5090056'
      },
      {
        address: {
          country: 'FRA'
        },
        districtId: '805dc7a1-eeca-4eac-8685-a6b3380ca6c2',
        id: 'ffc4da6c-d1a2-44bd-a58d-0393dc646064',
        name: 'Nike Factory Store Avignon',
        region: 'EUROPE_MIDDLE_EAST_AFRICA',
        storeNumber: '699'
      },
      {
        address: {
          country: 'CHN'
        },
        id: 'fffbe02d-1ce0-4b6f-a378-c84f67f89d7f',
        name: '山西省太原市万柏林区公园时代城KICKS LOUNGE-L2',
        region: 'GREATER_CHINA',
        storeNumber: '243046'
      }
    ],
    pages: {
      prev: 'store/store_locations/v1?search=%28id%21%3Dfoo%29&anchor=2000&count=1000&fields=id%2Cname%2CstoreNumber%2Caddress.country%2CdistrictId%2Cregion',
      totalPages: 4,
      totalResources: 3599
    }
  };
