{
    "lastA11yAudit": "07/08/22",
    "name": "Skeleton",
    "description": "Skeleton is a wireframe view representing the content while the page loads.",
    "docs": "#/components/loaders/Skeleton",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=33002%3A495600",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Skeleton",
    "tags": ["skeleton", "spinner", "loading", "loader"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
