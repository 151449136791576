import { Checkbox } from '@nike/frame-component-library';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  dateAddDay,
  dateSubtractDay,
  fromISODateToInput,
  getReloadStoreNumber,
} from '../../utils/formatting';
import { loadState } from '../../utils/local-storage';
import {
  viewTraffic,
  reloadTraffic,
  generateTraffic,
  reloadThenViewTraffic,
  viewThenGenerateTraffic,
  reloadThenGenerateTraffic,
  reloadThenViewThenGenerateTraffic,
} from '../../utils/service-calls/traffic';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';

import AllForm from './AllForm';
import GenerateOnlyForm from './GenerateOnlyForm';
import ReloadAndViewForm from './ReloadAndViewForm';

const DAY_INTERVAL = 1440;
const storeviewsFields = ['address.country', 'company', 'storeNumber', 'timezone'];
const yesterday = dateSubtractDay(new Date(Date.now()));

class GeneralTraffic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // by default do not search for today
      endDate: fromISODateToInput(yesterday),
      fetching: false,
      formErrors: {
        company: REQUIRED_FIELD,
        selectedCountry: '',
        selectedStore: REQUIRED_FIELD,
      },
      generateEnabled: false,
      generateResponse: '',
      interval: DAY_INTERVAL,
      reloadEnabled: false,
      reloadResponse: '',
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      startDate: fromISODateToInput(dateSubtractDay(yesterday)),
      trafficData: [],
      viewEnabled: false,
      viewResponse: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  getFormattedTrafficData = (data) => {
    if (!data || !Array.isArray(data) || !('trafficSensorReadings' in data[0]) || !('name' in data[0])) {
      return [];
    }
    const returnArray = data.map((sensor) => sensor.trafficSensorReadings.map(({ trafficIn, trafficOut, startDate: date }) => ({
      date,
      name: sensor.name,
      trafficIn,
      trafficOut,
      type: sensor.sensorType,
    })));
    return [].concat(...returnArray)
      .sort((a, b) => {
        if (a.type < b.type) {
          return -1;
        } else if (a.type > b.type) {
          return 1;
        } else {
          return 0;
        }
      });
  };

  handleServiceResponses = (responses) => responses.forEach((response) => {
    if (Array.isArray(response)) {
      // if there are three service calls, then we need to break up the second array response
      this.handleServiceResponses(response);
    } else if (response.error || response.body?.error || response.message) {
      if (!response.error?.serviceName && !response.body?.serviceName && !response.serviceName) {
        this.setState({ genericErrorResponse: response.message });
      }
      if (response.error?.serviceName === 'traffic-service') {
        this.setState({ viewResponse: `View Traffic error: ${this.parseErrors(response)}` });
      }
      if (response.error?.serviceName === 'retail-next-historical') {
        if (response.error?.error.includes('STORE_NOT_FOUND')) {
          this.setState({ reloadResponse: 'Traffic Reload error: this store does not exist in RetailNext.' });
        } else {
          this.setState({ reloadResponse: `Traffic Reload error: ${this.parseErrors(response)}` });
        }
      }
      if (response.error?.serviceName === 'retail-traffic-reports' || response.body?.serviceName === 'retail-traffic-reports') {
        this.setState({ generateResponse: `Generate WFM Reports error: ${response.error?.error || response.body?.errorMsg}` });
      }
    } else {
      if (response.body?.serviceName === 'traffic-service' && response.body?.stores) {
        const trafficData = this.getFormattedTrafficData(response.body.stores[0]?.trafficSensors);
        if (trafficData && trafficData.length !== 0) {
          this.setState({ trafficData, viewResponse: 'View Traffic: Successful' });
        } else {
          this.setState({ viewResponse: 'No Traffic Data' });
        }
      }
      if (response.body?.serviceName === 'retail-next-historical') {
        this.setState({ reloadResponse: 'Traffic Reload: Successful' });
      }
      if (response.body?.serviceName === 'retail-traffic-reports') {
        this.setState({ generateResponse: 'Generate WFM Reports: Successful' });
      }
    }
  });

  makeServiceCall = async (serviceCall, parameters) => serviceCall(this.props.accessToken, parameters)
    .then((res) => this.handleServiceResponses(Array.isArray(res) ? res : [res]))
    .catch((err) => this.handleServiceResponses(Array.isArray(err) ? err : [err]))
    .finally(() => this.setState({ fetching: false }));

  onInputChange = (key, value, selectedStore = null) => {
    if (key.includes('Enabled')) {
      // reset selectedCountry and selectedStore when switching between forms
      // because GenerateOnlyForm only uses the country and company, not the full selectedStore
      return this.setState({
        [key]: value,
        selectedStore: null,
      }, () => this.resetResponses());
    }
    if (key === 'selectedCountry' || key === 'company') {
      // reset/overwrite selectedStore when selecting a country (generically) or company (GenerateOnlyForm)
      return this.setState({
        [key]: value,
        selectedStore,
      }, () => this.resetResponses());
    }
    // normally, just set the value
    return this.setState({
      [key]: value,
    }, () => this.resetResponses());
  };

  onSubmit = async () => {
    this.resetResponses();
    const parameters = {
      company: this.state.selectedStore.company,
      country: this.state.selectedStore.address.country,
      endDate: this.state.endDate,
      interval: this.state.interval,
      // if search by day, add a day to endDate to retrieve data only up until the end date (stores in UTC time will show including)
      isoEndDate: moment.tz(this.state.interval === DAY_INTERVAL ? dateAddDay(this.state.endDate) : this.state.endDate, this.state.selectedStore.timezone).set({
        hours: 0, milliseconds: 0, minutes: 0, seconds: 0,
      }).format(),
      // using the store's timezone offset for the trafficservice call
      isoStartDate: moment.tz(this.state.startDate, this.state.selectedStore.timezone).set({
        hours: 0, milliseconds: 0, minutes: 0, seconds: 0,
      }).format(),
      startDate: this.state.startDate,
      storeNumber: getReloadStoreNumber(this.state.selectedStore.storeNumber, this.state.selectedStore.company),
    };
    this.setState({ fetching: true },
      () => {
        let serviceCall;
        if (this.state.viewEnabled && !this.state.reloadEnabled && !this.state.generateEnabled) {
          // view only
          serviceCall = viewTraffic;
        } else if (!this.state.viewEnabled && this.state.reloadEnabled && !this.state.generateEnabled) {
          // reload only
          serviceCall = reloadTraffic;
        } else if (!this.state.viewEnabled && !this.state.reloadEnabled && this.state.generateEnabled) {
          // generate only
          serviceCall = generateTraffic;
        } else if (this.state.viewEnabled && this.state.reloadEnabled && !this.state.generateEnabled) {
          // view and reload
          serviceCall = reloadThenViewTraffic;
        } else if (this.state.viewEnabled && !this.state.reloadEnabled && this.state.generateEnabled) {
          // view and generate
          serviceCall = viewThenGenerateTraffic;
        } else if (!this.state.viewEnabled && this.state.reloadEnabled && this.state.generateEnabled) {
          // reload and generate
          serviceCall = reloadThenGenerateTraffic;
        } else if (this.state.viewEnabled && this.state.reloadEnabled && this.state.generateEnabled) {
          // view, reload, and generate
          serviceCall = reloadThenViewThenGenerateTraffic;
        } else {
          // do nothing if ! all options, because no one should be submitting with no options selected
          return this.setState({ fetching: false });
        }
        return this.makeServiceCall(serviceCall, parameters);
      });
  };

  parseErrors = (err) => err.error?.error?.split('"message":')[1] || err.error?.error || err.message;

  resetResponses = () => this.setState({
    generateResponse: '',
    genericErrorResponse: '',
    reloadResponse: '',
    trafficData: [],
    viewResponse: '',
  });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState({ formErrors });
  };

  render = () => (
    <section className="ncss-col-sm-8 ta-sm-c ta-sm-l">
      <Checkbox
        key="viewEnabledCheckbox"
        containerClassName="ncss-col-sm-12"
        isChecked={this.state.viewEnabled}
        label="View Traffic"
        onChange={({ target: { checked } }) => this.onInputChange('viewEnabled', checked)}
      />
      <Checkbox
        key="reloadTrafficCheckBox"
        containerClassName="ncss-col-sm-12"
        isChecked={this.state.reloadEnabled}
        label="Reload Traffic"
        onChange={({ target: { checked } }) => this.onInputChange('reloadEnabled', checked)}
      />
      <Checkbox
        key="generateWFMReportsCheckbox"
        containerClassName="ncss-col-sm-12"
        isChecked={this.state.generateEnabled}
        label="Generate WFM Reports"
        onChange={({ target: { checked } }) => this.onInputChange('generateEnabled', checked)}
      />
      {(this.state.viewEnabled || this.state.reloadEnabled) && this.state.generateEnabled && (
        <AllForm
          disableSubmit={!(this.state.selectedStore?.storeNumber && this.state.selectedStore?.company && this.state.selectedStore?.address.country)}
          endDate={this.state.endDate.toString()}
          fetching={this.state.fetching}
          formErrors={this.state.formErrors}
          reloadEnabled={this.state.reloadEnabled}
          selectedCountry={this.state.selectedCountry}
          startDate={this.state.startDate.toString()}
          storeDetails={this.state.selectedStore}
          storeviewsFields={storeviewsFields}
          trafficData={this.state.trafficData}
          viewEnabled={this.state.viewEnabled}
          onCountryChange={(selectedCountry) => this.onInputChange('selectedCountry', selectedCountry)}
          onEndDateChange={(endDate) => this.onInputChange('endDate', endDate)}
          onStartDateChange={(startDate) => this.onInputChange('startDate', startDate)}
          onStoreChange={(selectedStore) => this.onInputChange('selectedStore', selectedStore)}
          onSubmit={this.onSubmit}
        />
      )}
      {(this.state.viewEnabled || this.state.reloadEnabled) && !this.state.generateEnabled && (
        <ReloadAndViewForm
          disableSubmit={!(this.state.selectedStore?.storeNumber && this.state.selectedStore?.company && this.state.selectedStore?.address.country)}
          endDate={this.state.endDate.toString()}
          fetching={this.state.fetching}
          formErrors={this.state.formErrors}
          interval={this.state.interval}
          reloadEnabled={this.state.reloadEnabled}
          selectedCountry={this.state.selectedCountry}
          startDate={this.state.startDate.toString()}
          storeDetails={this.state.selectedStore}
          storeviewsFields={storeviewsFields}
          trafficData={this.state.trafficData}
          onCountryChange={(selectedCountry) => this.onInputChange('selectedCountry', selectedCountry)}
          onEndDateChange={(endDate) => this.onInputChange('endDate', endDate)}
          onStartDateChange={(startDate) => this.onInputChange('startDate', startDate)}
          onStoreChange={(selectedStore) => this.onInputChange('selectedStore', selectedStore)}
          onSubmit={this.onSubmit}
          onTrafficIntervalChange={(interval) => this.setState({ interval })}
        />
      )}
      {!this.state.viewEnabled && !this.state.reloadEnabled && this.state.generateEnabled && (
        <GenerateOnlyForm
          endDate={this.state.endDate.toString()}
          fetching={this.state.fetching}
          formErrors={this.state.formErrors}
          selectedCountry={this.state.selectedCountry}
          startDate={this.state.startDate.toString()}
          storeDetails={this.state.selectedStore}
          onCompanyChange={(company) => this.onInputChange('company', company, {
            address: { country: this.state.selectedStore?.address.country },
            company,
            storeNumber: '',
          })}
          onCountryChange={(selectedCountry) => this.onInputChange('selectedCountry', selectedCountry, {
            address: { country: selectedCountry.value },
            company: this.state.selectedStore?.company,
            storeNumber: '',
          })}
          onEndDateChange={(endDate) => this.onInputChange('endDate', endDate)}
          onStartDateChange={(startDate) => this.onInputChange('startDate', startDate)}
          onStoreChange={(selectedStore) => this.onInputChange('selectedStore', selectedStore)}
          onSubmit={this.onSubmit}
        />
      )}
      <article className="ncss-row m2-sm">
        <p className="body-2 mb4-sm text-color-error">{this.state.genericErrorResponse}</p>
        <p className="body-2 mb4-sm text-color-accent">{this.state.viewResponse}</p>
        <p className="body-2 mb4-sm text-color-accent">{this.state.reloadResponse}</p>
        <p className="body-2 mb4-sm text-color-accent">{this.state.generateResponse}</p>
      </article>
    </section>
  );
}

GeneralTraffic.defaultProps = {
  accessToken: undefined,
};

GeneralTraffic.propTypes = {
  accessToken: PropTypes.string,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(GeneralTraffic);
