const getHeaderInfo = (location) => {
  const { pathname } = location;
  const data = {};
  switch (pathname) {
    case '/qrcodegenerator':
      data.description = 'Generate trackable, Nike-specific QR codes that connect physical spaces to digital experiences in the Nike App or on Nike.com';
      data.title = 'QR Code Generator';
      break;
    case '/qrcodegenerator/managecampaign':
      data.description = 'Manage Campaigns';
      data.title = 'QR Code Generator';
      break;
    case '/qrcodegenerator/createcampaign':
      data.description = `${location?.state?.editCampaign ? 'Edit Campaign' : 'Create New Campaign'}`;
      data.title = '';
      break;
    case '/qrcodegenerator/history':
      data.description = 'Campaigns History';
      data.title = '';
      break;

    default:
      return data;
  }
  return data;
};

export { getHeaderInfo as default };
