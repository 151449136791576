{
    "lastA11yAudit": "07/08/22",
    "name": "Radio Group",
    "description": "Radio Group is a grouping of radio inputs.",
    "docs": "#/components/forms/RadioGroup",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Podium-Enterprise-DS-Library?node-id=48843%3A356696",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/RadioGroup",
    "tags": ["radiogroup", "inputs", "form elements", "forms"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
