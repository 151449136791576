import { Info } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';

import './ToolTip.css';

const ToolTip = ({ children, left }) => (
  <section className="tool-tip" style={{ left, position: 'absolute' }}>
    <Info />
    <article className="ncss-col-sm-8 ta-sm-l tool-tip-content" style={{ zIndex: 2 }}>
      <span>{children}</span>
    </article>
  </section>
);

ToolTip.defaultProps = {
  left: '',
};

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  left: PropTypes.string,
};

export default ToolTip;
