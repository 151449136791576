import { Input } from '@nike/frame-component-library';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

import { facilityTypeValues, regionValues, serviceStatusValues } from '../../../utils/static/sls-property-values';
import { ButtonWhite } from '../../reusable';

import '../../reusable/Select.css';

const requiredRed = '#d43f21';

const getStaticFilterOptions = (accessor) => {
  switch (accessor) {
    case 'facilityType':
      return facilityTypeValues.map((val) => ({ label: val, value: val }));
    case 'region':
      return regionValues.map((val) => ({ label: val, value: val }));
    case 'serviceStatus':
      return serviceStatusValues.map((val) => ({ label: val, value: val }));
    default:
      return [];
  }
};

const getFilterOptions = (facilities, filter) => [{ label: filter.label, value: '' }]
  .concat(getStaticFilterOptions(filter.accessor).length
    ? getStaticFilterOptions(filter.accessor)
    : [...new Set([].concat(...facilities.map((facility) => (typeof filter.accessor === 'string' ? get(facility, filter.accessor) : filter.accessor(facility)))))]
      .sort().map((code) => ({ label: filter.getLabel(code), value: code })));

const Filters = (props) => (
  <article className="ncss-row mb2-sm">
    <section className="ncss-col-sm-12">
      <header className="headline-1 ta-sm-c">Search For {props.facilityType}</header>
      {props.filters.map((filter) => (
        <article key={filter.id} className="ncss-col-sm-4 mt2-sm" style={{ display: filter.disabled ? 'none' : '' }}>
          <ReactSelect
            classNamePrefix="react-select"
            id={filter.id}
            isDisabled={filter.isDisabled}
            options={getFilterOptions(props.facilities, filter)}
            styles={{
              menu: (styles) => ({ ...styles, zIndex: 100 }),
              singleValue: (styles) => ({ ...styles, color: filter.required ? requiredRed : styles.color }),
            }}
            value={filter.option}
            onChange={(value) => filter.changeValue(value)}
          />
        </article>
      ))}
      <article className="ncss-col-sm-8 mt2-sm va-sm-t">
        <Input
          placeholder="Search..."
          style={{ zIndex: 1 }}
          type="text"
          value={props.search}
          onChange={({ target: { value } }) => props.changeSearch(value)}
        />
      </article>
      <article className="ncss-col-sm-4 mt2-sm va-sm-t">
        <ButtonWhite
          className="u-full-width border"
          label="Clear Filters"
          onClick={props.clearFilters}
        />
      </article>
      <footer className="ncss-col-sm-12 mt2-sm">
        Results: {props.facilities.length}
      </footer>
    </section>
  </article>
);

Filters.propTypes = {
  changeSearch: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  facilityType: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    changeValue: PropTypes.func,
    getLabel: PropTypes.func,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    option: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    required: PropTypes.bool,
  })).isRequired,
  search: PropTypes.string.isRequired,
};

export default Filters;
