import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { forwarderAuditsGet, sniPost } from '../../utils/service-calls/sni-transaction-search';
import { rosPermissions } from '../../utils/tab-permissions';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonBlack, ButtonSubmit, DateInput, PageTemplate, StoreSelect, Table,
} from '../reusable';

const storeviewsFields = ['id'];

// EXAMPLE: from date = about 1 month ago (02/20/2020 works on 03/27/2020)

class SNITransactionSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: 'SNI',
      country: { label: 'Turkey', value: 'TUR' },
      data: [],
      errorMessage: '',
      fetching: false,
      formErrors: {
        selectedStore: REQUIRED_FIELD,
        transactionDateAfterEq: REQUIRED_FIELD,
        transactionId: REQUIRED_FIELD,
      },
      searched: false,
      selectedStore: null,
      successMessage: '',
      transactionDateAfterEq: '',
      transactionDateBeforeEq: '',
      transactionId: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  // storeID is required, and you need either a transactionID or a transactionDateAfterEq
  isDisabled = () => (!this.state.selectedStore || !(this.state.transactionId || this.state.transactionDateAfterEq));

  onReprocess = (row) => sniPost(this.props.accessToken, this.state.data[row.index])
    .then((res) => {
      if (!res.body) {
        throw new Error(res.statusText);
      }
      return this.setState((prevState) => ({
        errorMessage: '',
        successMessage: `Successfully reprocessed transaction: ${prevState.data[row.index].transactionId}. \n Please refresh the page to see the results.`,
      }));
    })
    .catch((err) => this.setState((prevState) => ({
      errorMessage: `Failed to reprocess transaction ${prevState.data[row.index].transactionId}: ${err.message}`,
      successMessage: '',
    })));

  onSubmit = () => {
    this.setState({ fetching: true });
    return forwarderAuditsGet(
      this.props.accessToken,
      this.state.selectedStore.id,
      this.state.transactionId,
      this.state.clientName,
      this.state.transactionDateAfterEq,
      this.state.transactionDateBeforeEq,
    )
      .then((res) => {
        if (!res.body) {
          throw new Error(res.statusText);
        }
        return this.setState({
          data: res.body.objects, errorMessage: '', fetching: false, searched: true, successMessage: '',
        });
      })
      .catch((err) => this.setState({
        data: [], errorMessage: err.message, fetching: false, searched: true, successMessage: '',
      }));
  }

  selectStore = (selectedStore) => this.setState({ selectedStore });

  showIDWarning = () => this.state.transactionId && (this.state.transactionDateAfterEq || this.state.transactionDateBeforeEq);

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    if (this.state.transactionId) {
      formErrors.transactionDateAfterEq = '';
      formErrors.transactionDateBeforeEq = '';
    } else if (this.state.transactionDateAfterEq) {
      formErrors.transactionId = '';
    }
    this.setState({ formErrors });
  };

  render = () => (
    <PageTemplate
      auth={rosPermissions}
      description="Search for SNI transactions and reprocess the transactions."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+SNI+Transaction+Search+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-10">
          <article className="ncss-row">
            <aside className="ncss-col-sm-6 body-2 pb4-sm ta-sm-l">Country: {this.state.country.label}</aside>
          </article>
          <StoreSelect
            isRequired
            countryRestriction={this.state.country}
            formErrors={this.state.formErrors}
            selectCountry={() => { }}
            selectStore={this.selectStore}
            selectedStore={this.state.selectedStore}
            storeviewsFields={storeviewsFields}
          />
          <section className="ncss-row pt4-sm">
            <article className="ncss-col-sm-6">
              <Input
                errorMessage={this.state.formErrors.transactionId}
                id="uuid"
                label="Transaction UUID"
                type="text"
                onChange={({ target: { value } }) => this.setState({ transactionId: value })}
              />
            </article>
          </section>
          <section className="ncss-row pt4-sm">
            <DateInput
              className="ncss-col-sm-3 va-sm-t"
              errorMessage={this.state.formErrors.transactionDateAfterEq}
              id="afterDate"
              label="From Date"
              value={this.state.transactionDateAfterEq}
              onChange={(value) => this.setState({ transactionDateAfterEq: value })}
            />
            <DateInput
              className="ncss-col-sm-3 va-sm-t"
              id="beforeDate"
              label="To Date"
              value={this.state.transactionDateBeforeEq}
              onChange={(value) => this.setState({ transactionDateBeforeEq: value })}
            />
          </section>
          <ButtonSubmit
            isDisabled={this.isDisabled()}
            isLoading={this.state.fetching}
            label="Search"
            submitError={this.state.errorMessage}
            onClick={this.onSubmit}
          />
          {this.isDisabled() && <p className="text-color-accent body-4">A Store and either Transaction UUID or a From Date is required to search.</p>}
          {this.showIDWarning() && <p className="text-color-accent body-4">Searches that include a Transaction UUID will ignore Dates when fetching transactions.</p>}
          <Table
            showPagination
            columns={[
              { accessor: 'transactionId', Header: 'Transaction UUID' },
              { accessor: 'transactionNumber', Header: 'Transaction Number' },
              { accessor: 'workStationNumber', Header: 'Workstation Number' },
              { accessor: 'transactionDate', Header: 'Date' },
              { accessor: 'invoiceNumber', Header: 'Invoice Number' },
              { accessor: 'clientResponseCode', Header: 'Response Code' },
              {
                accessor: 'reprocess',
                Cell: (d) => <ButtonBlack className="" label="&gt;" onClick={() => this.onReprocess(d)} />,
                Header: 'Reprocess',
                width: 100,
              },
            ]}
            data={this.state.data}
            defaultPageSize={10}
            fetched={this.state.searched}
            title="Transactions"
          />
          <p className="text-color-success body-2">{this.state.successMessage}</p>
        </main>
      )}
      path={this.props.location.pathname}
      title="SNI Transaction Search"
    />
  );
}

SNITransactionSearch.defaultProps = {
  accessToken: undefined,
};

SNITransactionSearch.propTypes = {
  accessToken: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(SNITransactionSearch);
