import qs from 'qs';
import urlJoin from 'url-join';

import { MSG_AUTH_EXPIRE } from '../../constants/constants';
import { isCurrent } from '../local-storage';

const config = require('../../config')();

export const oktaAuth = (currentRoute = '/home') => {
  const queryString = qs.stringify({
    client_id: config.client_id,
    nonce: 'NONCE_STRING',
    redirect_uri: config.redirectUrl,
    response_type: config.response_type,
    scope: config.scope,
    state: currentRoute,
  });
  window.location.href = urlJoin(config.authUrl, `?${queryString}`);
};

export const storeViewsByCountry = async (country, published, fields) => {
  const url = new URL(`${config.storeviewsHost}${config.storeviewsResource}`);
  url.search = qs.stringify({
    count: 3000,
    fields: fields ? fields.join(',') : undefined,
    search: `(address.country==${country} and (company!=Hurley) and published==${published})`,
  });

  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'nike-api-caller-id': 'com.nike:test-caller-id',
    },
    method: 'GET',
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return { body: await response.json() };
};

export const requestWrapper = async (token, resource, method, queryStringParameters, body) => {
  if (isCurrent()) {
  const url = new URL(config.rosHost + resource);
  if (queryStringParameters) {
    Object.entries(queryStringParameters).forEach(([key, value]) => url.searchParams.append(key, value));
  }
  const options = {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method,
  };
  const response = await fetch(url, options);
  if (!response.ok) {
    throw await response.json();
  }
  return { body: await response.json() };
}
  throw new Error(MSG_AUTH_EXPIRE);
};

export const emailFeedback = (token, body) => requestWrapper(
    token,
    config.emailResource,
    'post',
    undefined,
    body,
);
