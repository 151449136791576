{
    "lastA11yAudit": "",
    "name": "Tag",
    "docs": "#/components/Tag",
    "description": "Tag is a component.",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?type=design&node-id=78497-8434&mode=design&t=2llVIpMOXIEvxKmX-0",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Tag",
    "tags": ["tag, chip", "badge", "pill", "flag"],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
