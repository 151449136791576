import { CaseTuple, Predicate } from '../index.js';

const isFunction = (o: any) => o instanceof Function;

const isEqual = (l: any) => (r: any) => l === r;

const predicate = (literalOrPredicate: any) => (isFunction(literalOrPredicate)
  ? literalOrPredicate
  : isEqual(literalOrPredicate));

const testPredicate = (arg: any, valueOrPredicate: any) => predicate(valueOrPredicate)(arg);

const expression = (literalOrExpression: any) => (isFunction(literalOrExpression)
  ? literalOrExpression
  : () => literalOrExpression);

const applyExpression = (arg: any, literalOrExpression: any): any => expression(literalOrExpression)(arg);

const defaultAccumulator = { isDefault: true };

const reducer = <T>(arg?: T) => (acc: any, caseTuple: CaseTuple<T>) => (acc === defaultAccumulator && testPredicate(arg, caseTuple[0])
  ? { isDefault: false, transformerValue: applyExpression(arg, caseTuple[1]) }
  : acc);

const of = <T>(arg?: T) => (...caseTuples: CaseTuple<T>[]) => caseTuples.reduce(reducer(arg), defaultAccumulator).transformerValue;

export const match: <T>(arg?: T) => (...caseTuples: CaseTuple<T>[]) => any = <T>(arg?: T) => of(arg);

export const Default: Predicate<any> = () => true;
