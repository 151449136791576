{
    "lastA11yAudit": "07/08/22",
    "name": "Snack",
    "description": "Snack is a notification to communicate the status of user actions.",
    "docs": "#/components/snacks/Snack",
    "figma": "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=2805%3A152178",
    "github": "https://github.com/nike-enterprise-design-system/eds/blob/main/src/components/Snack",
    "tags": [
        "Snackbar",
        "Toast",
        "Notification",
        "Snack",
        "Pill",
        "dialog",
        "accessibility",
        "a11y",
        "alert",
        "prompt"
    ],
    "designStatuses": ["Shipped"],
    "devStatuses": ["Shipped"]
}
