import { MapLikeEntry, MapLike } from '../index.js';

const objectFromEntry = ([key, value]: MapLikeEntry<any>) => ({ [key]: value });

export const reduceEntry = (
  acc: MapLike<any> | MapLikeEntry<any>,
  [key, value]: MapLikeEntry<any>,
) => (acc.constructor.name === 'Object'
  ? { ...acc, [key]: value }
  : { ...objectFromEntry(acc as unknown as MapLikeEntry<any>), [key]: value });
