import { requestWrapper } from './reusable';

const config = require('../../config')();

export const getDCNAudit = (token, id) => requestWrapper(
  token,
  config.dcnAuditResource,
  'get',
  { id },
);

export const postDCNAudit = (token, body) => requestWrapper(
  token,
  config.dcnAuditResource,
  'post',
  undefined,
  body,
);
