import { Checkbox } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { MSG_AUTH_EXPIRE } from '../../constants/constants';
import { dateSubtractDay, dateSubtractTwoYears } from '../../utils/formatting';
import { loadState } from '../../utils/local-storage';
import { postPriceFeed, getPriceFeedStatus } from '../../utils/service-calls/generate-price-dcn';
import { storeViewsByCountry } from '../../utils/service-calls/reusable';
import { countryToServerRegion } from '../../utils/static/country-mappings';
import { rosPermissions } from '../../utils/tab-permissions';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonSubmit, ButtonBlack, DateInput, PageTemplate, StoreSelect,
} from '../reusable';

const storeviewsFields = ['id', 'storeNumber'];

class GeneratePriceDCN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      awsRegion: 'us-east-1',
      endDate: new Date().toISOString(),
      errorMessage: '',
      failureCount: 0,
      fetched: false,
      fetching: false,
      formErrors: {
        selectedCountry: '',
      },
      fullLoad: false,
      priceFeedResults: [],
      priceJobStatusFetching: false,
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      selectedStores: [],
      startDate: dateSubtractDay(new Date().toISOString()),
      storesInCountry: [],
    };
  }

  componentDidMount() {
    // pretend we selected the country because we need the list of all stores in case no stores are selected
    this.selectCountry(this.state.selectedCountry);
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors), prevState);
    }
  }

  checkPriceJobStatus = async () => {
    this.setState({ priceJobStatusFetching: true });
    const { priceFeedResults } = this.state;
    const updatedPriceFeedResults = await Promise.all(priceFeedResults.map((priceFeedResult) => getPriceFeedStatus(this.props.accessToken, priceFeedResult.id)
      .then((result) => ({ id: result.body.id, status: result.body.status }))
      .catch(() => ({ id: priceFeedResult.id, status: 'Failed to get job status. Please retry.' }))));
    this.setState({ priceFeedResults: updatedPriceFeedResults });
    this.setState({ priceJobStatusFetching: false });
  };

  getStoresByCountry = (selectedCountry) => storeViewsByCountry(selectedCountry.value, false, storeviewsFields)
    .then((res) => {
      if (!res.body || !res.body.objects || !Array.isArray(res.body.objects)) {
        throw new Error(res.statusText);
      }
      return this.setState({ storesInCountry: res.body.objects });
    })
    .catch((err) => this.setState({ errorMessage: err.message, storesInCountry: [] }));

  onClear = () => {
    this.setState({ selectedStore: null, selectedStores: [] });
    this.onUpdate();
  };

  onSubmit = () => {
    this.setState({ errorMessage: '', failureCount: 0, fetching: true });
    // if a user has not selected any stores, then they have implicitly selected every store in their selected country
    const priceFeedResults = [];
    const sendStores = this.state.selectedStores.length === 0 ? this.state.storesInCountry : this.state.selectedStores;
    sendStores.forEach((store) => {
      const toSend = {
        request: {
          configurationName: 'pricingdcn',
          priority: 'NORMAL',
          request: {
            awsRegion: this.state.awsRegion,
            fromDate: `${this.state.startDate.split('T')[0]}T00:00:00.000Z`,
            storeId: store.id,
            toDate: this.state.endDate,
          },
          targetList: [store.id],
        },
      };
      postPriceFeed(this.props.accessToken, toSend)
        .then((result) => {
          priceFeedResults.push(result.body);
          return this.setState({ priceFeedResults });
        })
        .catch((err) => {
          if (err.message === MSG_AUTH_EXPIRE) {
            return this.setState((prevState) => ({ errorMessage: `${err.message}`, failureCount: prevState.failureCount + 1 }));
          }
          return this.setState((prevState) => ({ errorMessage: `${prevState.errorMessage} ${store.storeNumber} - `, failureCount: prevState.failureCount + 1 }));
        })
        .finally(() => this.setState({ fetched: true, fetching: false }))
        .catch(() => this.setState({ fetched: true, fetching: false }));
    });
  };

  onUpdate = () => this.setState({ errorMessage: '', fetched: false });

  selectCountry = (selectedCountry) => {
    // this is necessary in order to default a selection of all stores
    this.getStoresByCountry(selectedCountry);
    this.setState({ awsRegion: countryToServerRegion(selectedCountry.value), selectedCountry });
    this.onClear();
  };

  selectEndDate = (endDate) => {
    this.setState({ endDate });
    this.onUpdate();
  };

  selectFullLoad = (checked) => {
    // endDate has already been set as today; fullLoad implies startDate is two years ago today
    this.setState((prevState) => ({ fullLoad: checked, startDate: dateSubtractTwoYears(prevState.endDate) }));
    this.onUpdate();
  };

  selectStartDate = (startDate) => {
    this.setState({ startDate });
    this.onUpdate();
  };

  selectStore = (selectedStore) => {
    this.setState((prevState) => {
      const { selectedStores } = prevState;
      if (selectedStores.includes(selectedStore)) {
        return ({});
      }
      selectedStores.push(selectedStore);
      return ({ selectedStore, selectedStores });
    });
    this.onUpdate();
  };

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <PageTemplate
      auth={rosPermissions}
      description="Generate a Delta Load (within a date range) or Full Load (last 24 months) store price DCN."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Generate+Price+DCN+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-8">
          <StoreSelect
            isRequired
            formErrors={this.state.formErrors}
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            selectedStores={this.state.selectedStores}
            storeviewsFields={storeviewsFields}
            onClear={this.onClear}
          />
          {this.state.selectedCountry && this.state.selectedStores.length === 0
            && <p className="text-color-accent">If no stores are selected, then a Price Feed will be generated for every store in the selected country</p>}
          {!this.state.fullLoad && (
            <section className="ncss-row mt4-sm">
              <DateInput
                className="ncss-col-sm-6"
                id="startDate"
                label="Start Date"
                value={this.state.startDate}
                onChange={(date) => this.selectStartDate(date)}
              />
              <DateInput
                isDisabled
                className="ncss-col-sm-6"
                id="endDate"
                label="End Date"
                value={this.state.endDate}
                onChange={(date) => this.selectEndDate(date)}
              />
            </section>
          )}
          <section className="ncss-col-sm-12 mt2-sm">
            <Checkbox
              id="fullLoad"
              isChecked={this.state.fullLoad}
              label="Full Load"
              onChange={({ target: { checked } }) => this.selectFullLoad(checked)}
            />
          </section>
          <ButtonSubmit
            isDisabled={!Array.isArray(this.state.storesInCountry) || this.state.storesInCountry.length === 0}
            isLoading={this.state.fetching || this.state.priceJobStatusFetching}
            label="Initiate Request"
            submitError={this.state.errorMessage}
            onClick={this.onSubmit}
          />
          {this.state.fetched && this.state.priceFeedResults.map((result) => (
            <section key={result.id} className="p4-sm">
              <h2 className="text-color-accent">Status: {result.status}</h2>
              <h2 className="text-color-primary-light">ID: {result.id}</h2>
            </section>
          ))}
          {this.state.fetched && this.state.priceFeedResults.length > 0
            && (
              <ButtonBlack
                isDisabled={this.state.priceJobStatusFetching}
                label="Check Status"
                onClick={this.checkPriceJobStatus}
              />
            )}
          {this.state.fetched && this.state.failureCount !== 0
            && (
              <p className="text-color-error">
                {this.state.errorMessage !== MSG_AUTH_EXPIRE
                  && 'Request Initiations Failed (all unlisted stores initiated successfully). Please contact "Retail-RISE Pricing Promo & Store to cloud" on ServiceNow.'}
              </p>
            )}
        </main>
      )}
      path={this.props.location.pathname}
      title="Generate Price DCN"
    />
  );
}

GeneratePriceDCN.defaultProps = {
  accessToken: undefined,
};

GeneratePriceDCN.propTypes = {
  accessToken: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(GeneratePriceDCN);
