import PropTypes from 'prop-types';
import React from 'react';

import {
  ButtonSubmit, DateInput, StoreSelect, Table,
} from '../reusable';

const viewReloadGenerate = 'View Traffic, Reload Traffic, and Generate WFM Reports';
const viewGenerate = 'View Traffic and Generate WFM Reports';
const reloadGenerate = 'Reload Traffic and Generate WFM Reports';

const Form = (props) => (
  <section className="ncss-col-sm-12 ta-sm-c mt2-sm mb2-sm">
    <aside className="text-color-accent ta-sm-l">
      {/* eslint-disable-next-line no-nested-ternary */}
      Please select a country and store to {props.viewEnabled && props.reloadEnabled
        ? viewReloadGenerate
        : props.reloadEnabled
          ? reloadGenerate
          : viewGenerate}.
    </aside>
    <section className="ncss-row">
      <article className="ncss-col-sm-12">
        <StoreSelect
          isPublished
          isRequired
          formErrors={props.formErrors}
          selectCountry={props.onCountryChange}
          selectStore={props.onStoreChange}
          selectedCountry={props.selectedCountry}
          selectedStore={props.storeDetails}
          storeviewsFields={props.storeviewsFields}
        />
      </article>
      <DateInput
        className="ncss-col-sm-6"
        id="fromDate"
        label="From"
        value={props.startDate || ''}
        onChange={(value) => props.onStartDateChange(value)}
      />
      <DateInput
        className="ncss-col-sm-6"
        id="untilDate"
        label="Until"
        value={props.endDate || ''}
        onChange={(value) => props.onEndDateChange(value)}
      />
      {props.startDate > props.endDate && <aside className="ncss-col-sm-12 text-color-error">From date must be before Until date</aside>}
      <section className="ncss-col-sm-12 va-sm-t">
        <ButtonSubmit
          isDisabled={props.disableSubmit || props.startDate > props.endDate}
          isLoading={props.fetching}
          // eslint-disable-next-line no-nested-ternary
          label={props.viewEnabled && props.reloadEnabled
            ? viewReloadGenerate
            : props.reloadEnabled
              ? reloadGenerate
              : viewGenerate}
          onClick={props.onSubmit}
        />
        <a
          className="ncss-btn-primary-dark bg-white text-color-primary-dark border mt4-sm"
          href="https://nike.cloud.retailnext.net/analytics"
          rel="noopener noreferrer"
          target="_blank"
        >
          RetailNext
        </a>
      </section>
      {props.trafficData.length !== 0 && (
        <Table
          columns={[
            { accessor: 'type', Header: 'Type', width: 100 },
            { accessor: 'name', Header: 'Name' },
            { accessor: 'date', Header: 'Date' },
            { accessor: 'trafficIn', Header: 'Traffic In', width: 100 },
            { accessor: 'trafficOut', Header: 'Traffic Out', width: 110 },
          ]}
          data={props.trafficData}
          title="Traffic Service Data"
        />
      )}
    </section>
  </section>
);

Form.defaultProps = {
  storeDetails: null,
};

Form.propTypes = {
  disableSubmit: PropTypes.bool.isRequired,
  endDate: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  formErrors: PropTypes.shape().isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onStoreChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reloadEnabled: PropTypes.bool.isRequired,
  selectedCountry: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
  storeDetails: PropTypes.shape(),
  storeviewsFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  trafficData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  viewEnabled: PropTypes.bool.isRequired,
};

export default Form;
