import PropTypes from 'prop-types';
import React from 'react';

import { slsPermissions } from '../../utils/tab-permissions';
import { PageTemplate } from '../reusable';

import Container from './Container';

const SLS = (props) => (
  <PageTemplate
    auth={slsPermissions}
    description="View and maintain physical, digital, and warehouse store location information."
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+SLS+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
    )}
    page={<Container detailOnly={props.detailOnly} history={props.history} location={props.location} match={props.match} />}
    path={props.location.pathname}
    title="Store Location Services"
  />
);

SLS.defaultProps = {
  detailOnly: false,
};

SLS.propTypes = {
  detailOnly: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

export default SLS;
