import { IconButton } from '@nike/eds';
import PropTypes from 'prop-types';
import React from 'react';

const AddNodeButton = ({ onClick, label }) => (
  <IconButton
    icon="Plus"
    label={label}
    size="small"
    variant="ghost"
    onClick={onClick}
  />
);

AddNodeButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default AddNodeButton;
