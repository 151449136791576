// src/Modal.js

import PropTypes from 'prop-types'; // Import PropTypes from prop-types package
import React from 'react';

import './Modal.css'; // Create a separate CSS file for modal styles
import {
  ButtonBlack, ButtonWhite
 } from '../reusable';

const Modal = ({
 isOpen, onClose, title, buttonName, loading, children
}) => {
  // Add 'children' to props validation
  Modal.propTypes = {
    buttonName: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  };
  Modal.defaultProps = {
    loading: false,
  };

  if (!isOpen) return null;

  return (
    <>
      {loading ? (
        <div className="modal-overlay">
          <div className="modal-content">
            <ButtonWhite label="Loading" />
          </div>
        </div>
    ) : (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>{title}</h2>
          {children}
          <ButtonBlack label={buttonName} onClick={onClose} />
        </div>
      </div>
    )}
    </>
  );
};

export default Modal;
