import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CreateCampaign from '../create-campaign/CreateCampaign';
import History from '../history/History';
import ManageCampaign from '../manage-campaign/ManageCampaign';

const QRCodeSubRoutes = () => (
  <Switch>
    <Route
      component={ManageCampaign}
      path="/qrcodegenerator/managecampaign"
    />
    <Route
      component={CreateCampaign}
      path="/qrcodegenerator/createcampaign"
    />
    <Route
      component={History}
      path="/qrcodegenerator/history"
    />
  </Switch>
);

export default QRCodeSubRoutes;
