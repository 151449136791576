import { Predicate } from '../index.js';

/**
 * Checks if all predicates in an array return true for a given value.
 */
export const and: <T>(p1: Predicate<T>, p2: Predicate<T>) => Predicate<T> = (p1, p2) => (o) => p1(o) && p2(o);
/**
 * Checks if any predicate in an array returns true for a given value.
 */
export const or: <T>(p1: Predicate<T>, p2: Predicate<T>) => Predicate<T> = (p1, p2) => (o) => p1(o) || p2(o);
/**
 * Negates a predicate function.
 */
export const not: <T>(p1: Predicate<T>) => Predicate<T> = (p) => (o) => !p(o);
/**
 * Predicate that is always true for any given value.
 */
export const isAlwaysTrue: Predicate<any> = (_arg) => true;
/**
 * Predicate that is always false for any given value.
 */
export const isAlwaysFalse: Predicate<any> = (_arg) => false;
/**
 * Checks if any predicate in an array returns true for a given value.
 */
export const anyOf = <T>(...predicates: Predicate<T>[]) => predicates.reduce((acc, predicate) => or(acc, predicate), isAlwaysFalse);
/**
 * Checks if all predicates in an array return true for a given value.
 */
export const allOf = <T>(...predicates: Predicate<T>[]) => predicates.reduce((acc, predicate) => and(acc, predicate), isAlwaysTrue);
/**
 * Checks if a value is not null or undefined.
 */
export const isSome: Predicate<any> = (o) => o !== null && o !== undefined;
/**
 * Checks if a value is null or undefined.
 */
export const isNone: Predicate<any> = not(isSome);
