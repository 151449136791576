// eslint-disable-next-line import/no-extraneous-dependencies
import { Map } from 'immutable';

import { MapLikeKey, MapLike, Mutation } from '../index.js';

const reducer = (
  acc: Map<MapLikeKey, any>,
  [path, value]: [string[], any?],
  _currentIndex: number,
  _arr: Mutation[],
) => (value === undefined || value === null ? acc.deleteIn(path) : acc.setIn(path, value));

/**
 * Creates a copy of an object.
 * If a mutation has a value, the value at path will be set.
 * If a mutation does not have a value, the value at path will be deleted.
 */
export const copy = <T extends MapLike<any>>(o: T, ...mutations: Mutation[]): T => mutations.reduce(reducer, Map(o)).toJS();
