import PropTypes from 'prop-types';
import React from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  ButtonSubmit, DateInput, Select, StoreSelect, Table,
} from '../reusable';

const options = [
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '1 Hour', value: 60 },
  { label: '1 Day', value: 1440 },
];

const Form = (props) => (
  <section className="ncss-col-sm-12 ta-sm-c mt2-sm mb2-sm">
    <aside className="text-color-accent ta-sm-l">
      Please select a country and store to {props.reloadEnabled ? 'Reload Traffic and/or ' : ''}View Traffic.
    </aside>
    <section className="ncss-row">
      <article className="ncss-col-sm-12">
        <StoreSelect
          isPublished
          isRequired
          formErrors={props.formErrors}
          selectCountry={props.onCountryChange}
          selectStore={props.onStoreChange}
          selectedCountry={props.selectedCountry}
          selectedStore={props.storeDetails}
          storeviewsFields={props.storeviewsFields}
        />
      </article>
      <DateInput
        className="ncss-col-sm-6"
        id="fromDate"
        label="From"
        value={props.startDate || ''}
        onChange={(value) => props.onStartDateChange(value)}
      />
      <DateInput
        className="ncss-col-sm-6"
        id="untilDate"
        label="Until"
        value={props.endDate || ''}
        onChange={(value) => props.onEndDateChange(value)}
      />
      {props.startDate > props.endDate && <aside className="ncss-col-sm-12 text-color-error">From date must be before Until date</aside>}
      {!props.reloadEnabled && (
        <Select
          className="ncss-col-sm-6"
          id="trafficIntervalSelect"
          label="Traffic Interval"
          options={options}
          value={options.find((interval) => interval.value === props.interval)?.value}
          zIndex={3}
          onChange={(interval) => props.onTrafficIntervalChange(interval)}
        />
      )}
      <section className="ncss-col-sm-12 va-sm-t">
        <ButtonSubmit
          isDisabled={props.disableSubmit || props.startDate > props.endDate}
          isLoading={props.fetching}
          label={props.reloadEnabled ? 'Reload and View Traffic' : 'View Traffic'}
          onClick={props.onSubmit}
        />
        <a
          className="ncss-btn-primary-dark bg-white text-color-primary-dark border mt4-sm"
          href="https://nike.cloud.retailnext.net/analytics"
          rel="noopener noreferrer"
          target="_blank"
        >
          RetailNext
        </a>
      </section>
      {props.trafficData.length !== 0 && (
        <Table
          columns={[
            { accessor: 'type', Header: 'Type', width: 100 },
            { accessor: 'name', Header: 'Name' },
            { accessor: 'date', Header: 'Date' },
            { accessor: 'trafficIn', Header: 'Traffic In', width: 100 },
            { accessor: 'trafficOut', Header: 'Traffic Out', width: 110 },
          ]}
          data={props.trafficData}
          title="Traffic Service Data"
        />
      )}
    </section>
  </section>
);

Form.defaultProps = {
  storeDetails: null,
};

Form.propTypes = {
  disableSubmit: PropTypes.bool.isRequired,
  endDate: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  formErrors: PropTypes.shape().isRequired,
  interval: PropTypes.number.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onStoreChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTrafficIntervalChange: PropTypes.func.isRequired,
  reloadEnabled: PropTypes.bool.isRequired,
  selectedCountry: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
  storeDetails: PropTypes.shape(),
  storeviewsFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  trafficData: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default Form;
