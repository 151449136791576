import { dateAddDay } from '../../../utils/formatting';

export const today = new Date(Date.now()).toISOString().split('T')[0];

export const tomorrow = dateAddDay(today).split('T')[0];

export const daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const carrierCodeOptions = [
  { label: 'FedEx', value: 'FEDEX' },
  { label: 'UPS', value: 'UPS' },
];

export const carrierPickupTimes = {
  friday: [],
  monday: [],
  saturday: [],
  sunday: [],
  thursday: [],
  tuesday: [],
  wednesday: [],
};

export const defaultCapacity = {
  friday: { maxUnits: 0 },
  monday: { maxUnits: 0 },
  saturday: { maxUnits: 0 },
  sunday: { maxUnits: 0 },
  thursday: { maxUnits: 0 },
  tuesday: { maxUnits: 0 },
  wednesday: { maxUnits: 0 },
};

export const defaultCapacityException = {
  from: today,
  maxUnits: 0,
  to: tomorrow,
};

export const defaultCarrierPickupTime = {
  carrierCode: '',
  pickupTime: '',
};

export const sfs = {
  serviceGroup: 'SHIP_FROM_STORE',
  storeServiceTypeId: (process.env.NODE_ENV === 'production')
    ? '349d7825-6974-475b-b0a1-a8d2bd87a4f6'
    : 'e0131bb6-a2e4-4437-89a4-bf6ce44149d0',
};
