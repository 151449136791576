export const DEVICES_FETCH = 'DEVICES_FETCH';
export const DEVICES_FETCH_SUCCESS = 'DEVICES_FETCH_SUCCESS';
export const DEVICES_FETCH_FAILURE = 'DEVICES_FETCH_FAILURE';

export const DEVICE_SAVE = 'DEVICE_SAVE';
export const DEVICE_SAVE_SUCCESS = 'DEVICE_SAVE_SUCCESS';
export const DEVICE_SAVE_FAILURE = 'DEVICE_SAVE_FAILURE';

export const DEVICE_WAITLINE_UPDATE_SUCCESS = 'DEVICE_WAITLINE_UPDATE_SUCCESS';
export const DEVICE_WAITLINE_UPDATE_FAILURE = 'DEVICE_WAITLINE_UPDATE_FAILURE';

export const devicesFetch = (token, storeId) => ({ storeId, token, type: DEVICES_FETCH });
export const devicesFetchSuccess = (storeId, devices) => ({ devices, storeId, type: DEVICES_FETCH_SUCCESS });
export const devicesFetchFailure = (storeId, error) => ({ error, storeId, type: DEVICES_FETCH_FAILURE });

export const deviceSave = (token, device, waitline) => ({
  device, token, type: DEVICE_SAVE, waitline,
});
export const deviceSaveSuccess = (device) => ({ device, type: DEVICE_SAVE_SUCCESS });
export const deviceSaveFailure = (device, error) => ({ device, error, type: DEVICE_SAVE_FAILURE });

export const deviceWaitlineUpdateSuccess = (device) => ({ device, type: DEVICE_WAITLINE_UPDATE_SUCCESS });
export const deviceWaitlineUpdateFailure = (device, waitline, error) => ({
  device, error, type: DEVICE_WAITLINE_UPDATE_FAILURE, waitline,
});
