import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { dateSubtractDay } from '../../utils/formatting';
import { loadState } from '../../utils/local-storage';
import { getDCNAudit, postDCNAudit } from '../../utils/service-calls/dcnaudit';
import { rosPermissions } from '../../utils/tab-permissions';
import { didRequiredFieldsChange, REQUIRED_FIELD } from '../../utils/validation/input-validation';
import {
  ButtonBlack, ButtonSubmit, Creatable, DateInput, JSONFormatter, PageTemplate, Select, StoreSelect, Table,
} from '../reusable';

/* EXAMPLE REQUEST
 * country = italy
 * store = 566
 * start date = 2020-03-05
 * end date = 2020-03-08
 * dcn type = PRICE
 * GTIN = 00192502424092
 */

const options = [
  { label: 'PRODUCT', value: 'PRODUCT' },
  { label: 'PRICE', value: 'PRICE' },
  { label: 'STORE', value: 'STORE' },
  { label: 'PROMOTION', value: 'PROMOTION' },
  { label: 'TAX', value: 'TAX' },
];

class DCNAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      dcnFinalResult: [],
      dcnResult: [],
      dcnResultFetched: false,
      dcnStatusFetching: false,
      endDate: new Date().toISOString(),
      errorMessage: '',
      fetched: false,
      fetching: false,
      formErrors: {
        code: REQUIRED_FIELD,
        selectedCountry: '',
        selectedStore: REQUIRED_FIELD,
      },
      fullLoad: false,
      selectedCodeType: options[0].value,
      selectedCountry: loadState('auth')?.country,
      selectedStore: null,
      selectedStores: [],
      startDate: dateSubtractDay(new Date().toISOString()),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (didRequiredFieldsChange(Object.keys(this.state.formErrors), prevState, this.state)) {
      this.validateRequiredFields(Object.keys(this.state.formErrors));
    }
  }

  checkStatus = async () => {
    this.setState({ dcnStatusFetching: true });
    const { dcnResult } = this.state;
    const updatedDCNResults = await Promise.all(dcnResult.map((result) => getDCNAudit(this.props.accessToken, result.id)
      .then((res) => res)
      .catch(() => ({ id: result.id, status: 'Failed to get job status. Please retry.' }))));
    const resultObject = updatedDCNResults[0].body.status ? { id: updatedDCNResults[0].body.id, status: updatedDCNResults[0].body.status } : { id: updatedDCNResults[0].body.response.id, status: updatedDCNResults[0].body.response.status };
    if (updatedDCNResults[0].body?.response?.status === 'COMPLETED') {
      this.displayResults(updatedDCNResults[0].body.s3Result);
    }
    this.setState({ dcnResult: [resultObject] });
    this.setState({ dcnStatusFetching: false });
  };

  displayResults = (result) => this.setState({
    dcnFinalResult: (result.notifications.map((notification) => notification.dcnEntries.map((entry) => ({
      auditKey: result.auditKey,
      creationDate: notification.creationDate,
      dcnEntry: entry,
      dcnName: notification.dcnName,
      status: notification.status,
    })))).flat(),
    dcnResultFetched: true,
  });

  handleChange = (key, value) => this.setState({ errorMessage: '', [key]: value });

  isDisabled = () => (this.state.selectedStores.length === 0 || !this.state.selectedCodeType || (this.state.selectedCodeType !== 'STORE' && !this.state.code));

  onClear = () => this.setState({ errorMessage: '', selectedStore: null, selectedStores: [] });

  onSubmit = () => {
    const dcnResult = [];
    this.setState({ dcnResultFetched: false, fetching: true });
    this.state.selectedStores.forEach((store) => {
      const toSend = {
        request: {
          auditKey: this.state.selectedCodeType === 'STORE' ? this.state.storeNumber : this.state.code.value,
          auditType: this.state.selectedCodeType,
          creationDateAfterEq: new Date(this.state.startDate).toISOString(),
          creationDateBeforeEq: new Date(this.state.endDate).toISOString(),
          targetId: store.id,
        },
      };
      postDCNAudit(this.props.accessToken, toSend)
        .then((records) => {
          if (!records.body) {
            return this.setState({ errorMessage: 'Request initiation failed. Please contact Retail-RISE-Pricing Promo & Store to Cloud' });
          } else {
            getDCNAudit(this.props.accessToken, records.body.id)
              .then((response) => {
                if (response.body.status) {
                  dcnResult.push(response.body);
                  return this.setState({ dcnResult, errorMessage: '' });
                }
                return this.displayResults(response.body.s3Result);
              })
              .catch((error) => this.setState({ dcnResult: '', errorMessage: error.message }));
          }
          return undefined;
        })
        .catch((error) => this.setState({ errorMessage: error.message }))
        .finally(() => this.setState({ fetched: true, fetching: false }))
        .catch(() => this.setState({ fetched: true, fetching: false }));
    });
  };

  selectCountry = (selectedCountry) => this.setState({
    errorMessage: '', selectedCountry, selectedStore: null, selectedStores: [],
  });

  selectStore = (selectedStore) => this.setState((prevState) => {
    const { selectedStores } = prevState;
    if (selectedStores.includes(selectedStore)) {
      return ({});
    }
    selectedStores.push(selectedStore);
    return ({
      errorMessage: '', selectedStore, selectedStores, storeNumber: selectedStore.storeNumber,
    });
  });

  validateRequiredFields = (requiredFields) => {
    const formErrors = Object.fromEntries(requiredFields.map((field) => ([[field], (this.state[field]) ? '' : REQUIRED_FIELD])));
    this.setState(() => ({ formErrors }));
  };

  render = () => (
    <PageTemplate
      auth={rosPermissions}
      description="Search for the APTOS store audit information (product item, store, price, promo) that was sent via a DCN file."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+DCN+Audit+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-8">
          <StoreSelect
            isRequired
            formErrors={this.state.formErrors}
            selectCountry={this.selectCountry}
            selectStore={this.selectStore}
            selectedCountry={this.state.selectedCountry}
            selectedStore={this.state.selectedStore}
            selectedStores={this.state.selectedStores}
            onClear={this.onClear}
          />
          {!this.state.fullLoad && (
            <section className="ncss-row mt4-sm">
              <DateInput
                className="ncss-col-sm-6"
                id="startDate"
                label="Start Date"
                value={this.state.startDate}
                onChange={(date) => this.handleChange('startDate', date)}
              />
              <DateInput
                className="ncss-col-sm-6"
                id="endDate"
                label="End Date"
                value={this.state.endDate}
                onChange={(date) => this.handleChange('endDate', date)}
              />
            </section>
          )}
          <section className="ncss-row mt4-sm va-sm-t">
            <Select
              className="ncss-col-sm-6 ta-sm-l"
              id="codeSelect"
              label="DCN Type"
              options={options}
              value={this.state.selectedCodeType}
              onChange={(codeType) => this.handleChange('selectedCodeType', codeType)}
            />
            {this.state.selectedCodeType !== 'STORE' && (
              <Creatable
                errorMessage={this.state.formErrors.code}
                label="GTIN"
                value={this.state.code}
                width={6}
                onChange={(code) => this.handleChange('code', code)}
              />
            )}
          </section>
          <ButtonSubmit
            isDisabled={this.isDisabled()}
            isLoading={this.state.fetching || this.state.dcnStatusFetching}
            submitError={this.state.errorMessage}
            onClick={this.onSubmit}
          />
          {this.state.fetched && this.state.dcnResult.map((result) => (
            result.status !== 'COMPLETED'
              ? (
                <section key={result.id} className="p4-sm">
                  <h2 className="text-color-accent">Status: {result.status}</h2>
                  <h2 className="text-color-primary-light">ID: {result.id}</h2>
                  <ButtonBlack
                    isDisabled={this.state.dcnStatusFetching}
                    label="Check Status"
                    onClick={this.checkStatus}
                  />
                </section>
              )
              : ''
          ))}
          <section className="mt6-sm mb6-sm">
            <Table
              showPagination
              columns={[
                { accessor: 'auditKey', Header: 'Audit Key' },
                { accessor: (d) => d.creationDate.split('T')[0], Header: 'Creation Date', id: 'creationDate' },
                { accessor: 'dcnName', Header: 'DCN Name' },
                { accessor: 'dcnEntry', Cell: (d) => <JSONFormatter data={d.original.dcnEntry} />, Header: 'DCN Entry' },
                { accessor: 'status', Header: 'Status' },
              ]}
              data={this.state.dcnFinalResult}
              defaultPageSize={5}
              fetched={this.state.fetched && this.state.dcnResultFetched}
            />
          </section>
        </main>
      )}
      path={this.props.location.pathname}
      title="DCN Audit"
    />
  );
}

DCNAudit.defaultProps = {
  accessToken: undefined,
};

DCNAudit.propTypes = {
  accessToken: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.authorizationReducer.auth.accessToken,
});

export default connect(mapStateToProps, null)(DCNAudit);
