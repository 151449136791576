import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

const QRCODELIMIT = 99;

const BulkDownload = ({
  state: { sequenceEnd, sequenceStart, formErrors },
  generateQRCodehandler,
}) => (
  <>
    <p className="mt4-sm mb3-sm headline-5">Bulk Download </p>
    <article className="ncss-row">
      <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
        Sequence Start
        <Input
          errorMessage={!sequenceStart && formErrors.sequenceStart}
          id="sequenceStart"
          maxLength="3"
          minLength="3"
          name="sequenceStart"
          placeholder="Example: 000"
          onChange={generateQRCodehandler}
        />
        <div className="support-text-container">
          {sequenceStart.length !== 0 && sequenceStart.match('^[0-9][0-9][0-9]') === null && (
            <aside className="text-color-error body-4 mt2-sm">
              <span aria-live="polite" className="support-text">
                Please enter a 3 digit numeric value (example 000 – 999)
              </span>
            </aside>
          )}
        </div>
      </article>
      <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
        Sequence End
        <Input
          errorMessage={!sequenceEnd && formErrors.sequenceEnd}
          id="sequenceEnd"
          maxLength="3"
          minLength="3"
          name="sequenceEnd"
          placeholder="Example: 003"
          value={sequenceEnd}
          onChange={generateQRCodehandler}
        />
        <div className="support-text-container">
          {sequenceEnd.length !== 0 && sequenceEnd.match('^[0-9][0-9][0-9]') === null && (
            <aside className="text-color-error body-4 mt2-sm">
              <span aria-live="polite" className="support-text">
                Please enter a 3 digit numeric value (example 000 – 999)
              </span>
            </aside>
          )}
          {String(sequenceStart.length !== 0 && sequenceEnd - sequenceStart) > QRCODELIMIT && (
            <aside className="text-color-error body-4 mt2-sm">
              <span aria-live="polite" className="support-text">
                We can generate only upto 100 QR codes from the seq start, please verify the total count and reenter the value (example: 101-200 or 250 to 349 or 022 to 044)
              </span>
            </aside>
          )}
          {String(sequenceStart.length !== 0 && sequenceEnd - sequenceStart) < 1 && sequenceEnd.length > 0 && (
            <aside className="text-color-error body-4 mt2-sm">
              <span aria-live="polite" className="support-text">
                Sequence end shulld be greater than sequence start
              </span>
            </aside>
          )}
        </div>
      </article>
    </article>
  </>
);

BulkDownload.defaultProps = {
  generateQRCodehandler: PropTypes.func,
};

BulkDownload.propTypes = {
  generateQRCodehandler: PropTypes.func,
  state: PropTypes.shape({
    formErrors: PropTypes.shape({
      sequenceEnd: PropTypes.string,
      sequenceStart: PropTypes.string,
    }),
    sequenceEnd: PropTypes.string,
    sequenceStart: PropTypes.string,
  }).isRequired,
};
export default BulkDownload;
