{
    "lastA11yAudit": "",
    "name": "Banner",
    "description": "A full width messaging component for page and app level communication. Can be used to display important information, alerts, or calls to action.",
    "docs": "#/components/Banner",
    "github": "",
    "figma": "",
    "tags": ["alert", "card"],
    "designStatuses": ["In Development"],
    "devStatuses": ["In Development"]
}
