const topicApplicationValues = [
  {
    applicationName: 'Squib',
    id: '2ffe98f2-b292-410c-b6fb-dfd1168b2980',
    links: {
      self: '/workforce/athlete_applications/v1/2ffe98f2-b292-410c-b6fb-dfd1168b2980',
    },
    resourceType: 'workforce/athlete_applications',
  },
  {
    applicationName: 'Link',
    id: 'bb473729-2834-4e93-860b-8375ed6d5e36',
    links: {
      self: '/workforce/athlete_applications/v1/bb473729-2834-4e93-860b-8375ed6d5e36',
    },
    resourceType: 'workforce/athlete_applications',
  },
  {
    applicationName: 'Score',
    id: '36ef2eae-3b24-40ed-91d2-90a5b3b991e3',
    links: {
      self: '/workforce/athlete_applications/v1/36ef2eae-3b24-40ed-91d2-90a5b3b991e3',
    },
    resourceType: 'workforce/athlete_applications',
  },
  {
    applicationName: 'Assist',
    id: 'f9e9e692-4caf-4a06-88f3-61044716bac3',
    links: {
      self: '/workforce/athlete_applications/v1/f9e9e692-4caf-4a06-88f3-61044716bac3',
    },
    resourceType: 'workforce/athlete_applications',
  },
];

export default topicApplicationValues;
