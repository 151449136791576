export default {
  enabled: true,
  fulfillmentGuarantees: {
    leadTime: 'PT2H',
  },
  serviceGroup: 'BOPIS',
  storeServiceTypeId: (process.env.NODE_ENV === 'production')
    ? '29e99806-b350-47ea-a3aa-3682a9121f58'
    : 'a3f06c89-4f79-416d-9e78-0dc5437b2307',
};
