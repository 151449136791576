// This was written by Aidan, Sierra only understands some of it
const createValidObjectOrArray = (schema, objectToFix) => {
  let finalObjectOrArray;
  // if the schema is an object run this object logic
  if (typeof schema === 'object' && !Array.isArray(schema)) {
    // type didn't match schema so return the template value
    if (!(typeof objectToFix === 'object' && !Array.isArray(objectToFix))) {
      return schema;
    }
    finalObjectOrArray = { ...objectToFix };
    // every property in the schema should be in the objectToFix
    Object.keys(schema).forEach((key) => {
      // if the objectToFix doesn't have this yet, give it the schema's value
      if (finalObjectOrArray[key] === undefined) {
        finalObjectOrArray[key] = schema[key];
      // otherwise, run the validation on the inner value
      } else {
        finalObjectOrArray[key] = createValidObjectOrArray(schema[key], objectToFix[key]);
      }
    });
  // if the schema is an array run this array logic
  } else if (Array.isArray(schema)) {
    // type didn't match schema so return the template value
    if (!Array.isArray(objectToFix)) {
      return schema;
    } else {
      // just return the array because we validate phone number and hours separately
      return objectToFix;
    }
  // if the schema and objectToFix are primitives just return the objectToFix value
  } else if ((typeof schema === 'string' || typeof schema === 'number' || typeof schema === 'boolean')
    && (typeof objectToFix === 'string' || typeof objectToFix === 'number' || typeof objectToFix === 'boolean')
  ) {
    return objectToFix;
  // if there was a mismatch somewhere along the way return the schema value
  } else {
    return schema;
  }
  return finalObjectOrArray;
};

export default createValidObjectOrArray;
