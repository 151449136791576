export const ZONES_FETCH = 'ZONES_FETCH';
export const ZONES_FETCH_SUCCESS = 'ZONES_FETCH_SUCCESS';
export const ZONES_FETCH_FAILURE = 'ZONES_FETCH_FAILURE';

export const CREATE_ZONE = 'CREATE_ZONE';
export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_FAILURE = 'CREATE_ZONE_FAILURE';

export const DELETE_ZONE = 'DELETE_ZONE';
export const DELETE_ZONE_SUCCESS = 'DELETE_ZONE_SUCCESS';
export const DELETE_ZONE_FAILURE = 'DELETE_ZONE_FAILURE';

export const UPDATE_ZONE = 'UPDATE_ZONE';
export const UPDATE_ZONE_SUCCESS = 'UPDATE_ZONE_SUCCESS';
export const UPDATE_ZONE_FAILURE = 'UPDATE_ZONE_FAILURE';

export const SET_CURRENT_ZONE = 'SET_CURRENT_ZONE';

export const zonesFetch = (storeId, accessToken) => ({ accessToken, storeId, type: ZONES_FETCH });
export const zonesFetchSuccess = (requestNum, storeId, zones) => ({
  requestNum, storeId, type: ZONES_FETCH_SUCCESS, zones,
});
export const zonesFetchFailure = (requestNum, storeId, error) => ({
  error, requestNum, storeId, type: ZONES_FETCH_FAILURE,
});

export const zoneCreate = (zone, accessToken) => ({ accessToken, type: CREATE_ZONE, zone });
export const zoneCreateSuccess = (requestNum, zone) => ({ requestNum, type: CREATE_ZONE_SUCCESS, zone });
export const zoneCreateFailure = (requestNum, error) => ({ error, requestNum, type: CREATE_ZONE_FAILURE });

export const zoneDelete = (zone, accessToken) => ({ accessToken, type: DELETE_ZONE, zone });
export const zoneDeleteSuccess = (data) => ({ data, type: DELETE_ZONE_SUCCESS });
export const zoneDeleteFailure = (error) => ({ error, type: DELETE_ZONE_FAILURE });

export const zoneUpdate = (id, zone, accessToken) => ({
  accessToken, id, type: UPDATE_ZONE, zone,
});
export const zoneUpdateSuccess = (requestNum, zone) => ({ requestNum, type: UPDATE_ZONE_SUCCESS, zone });
export const zoneUpdateFailure = (requestNum, error) => ({ error, requestNum, type: UPDATE_ZONE_FAILURE });

export const setCurrentZone = (currentZone) => ({ currentZone, type: SET_CURRENT_ZONE });

export const LOCALIZATIONS_FETCH = 'LOCALIZATIONS_FETCH';
export const LOCALIZATIONS_FETCH_SUCCESS = 'LOCALIZATIONS_FETCH_SUCCESS';
export const LOCALIZATIONS_FETCH_FAILURE = 'LOCALIZATIONS_FETCH_FAILURE';

export const LOCALIZATION_CREATE = 'LOCALIZATION_CREATE';
export const LOCALIZATION_CREATE_SUCCESS = 'LOCALIZATION_CREATE_SUCCESS';
export const LOCALIZATION_CREATE_FAILURE = 'LOCALIZATION_CREATE_FAILURE';

export const LOCALIZATION_DELETE = 'LOCALIZATION_DELETE';
export const LOCALIZATION_DELETE_SUCCESS = 'LOCALIZATION_DELETE_SUCCESS';
export const LOCALIZATION_DELETE_FAILURE = 'LOCALIZATION_DELETE_FAILURE';

export const LOCALIZATION_UPDATE = 'LOCALIZATION_UPDATE';
export const LOCALIZATION_UPDATE_SUCCESS = 'LOCALIZATION_UPDATE_SUCCESS';
export const LOCALIZATION_UPDATE_FAILURE = 'LOCALIZATION_UPDATE_FAILURE';

export const localizationsFetch = (zoneId, accessToken) => ({ accessToken, type: LOCALIZATIONS_FETCH, zoneId });
export const localizationsFetchSuccess = (localizations) => ({ localizations, type: LOCALIZATIONS_FETCH_SUCCESS });
export const localizationsFetchFailure = (error) => ({ error, type: LOCALIZATIONS_FETCH_FAILURE });

export const localizationCreate = (localization, accessToken) => ({
  accessToken, localization, type: LOCALIZATION_CREATE,
});
export const localizationCreateSuccess = (data) => ({ data, type: LOCALIZATION_CREATE_SUCCESS });
export const localizationCreateFailure = (error) => ({ error, type: LOCALIZATION_CREATE_FAILURE });

export const localizationDelete = (localization, accessToken) => ({
  accessToken, localization, type: LOCALIZATION_DELETE,
});
export const localizationDeleteSuccess = (data) => ({ data, type: LOCALIZATION_DELETE_SUCCESS });
export const localizationDeleteFailure = (error) => ({ error, type: LOCALIZATION_DELETE_FAILURE });

export const localizationUpdate = (localization, accessToken) => ({
  accessToken, localization, type: LOCALIZATION_UPDATE,
});
export const localizationUpdateSuccess = (data) => ({ data, type: LOCALIZATION_UPDATE_SUCCESS });
export const localizationUpdateFailure = (error) => ({ error, type: LOCALIZATION_UPDATE_FAILURE });

export const CLEAR_ZONES_RESULTS = 'CLEAR_ZONES_RESULTS';
export const clearZonesResults = () => ({ type: CLEAR_ZONES_RESULTS });
