import { requestWrapper } from './reusable';

const config = require('../../config')();

export const dcnLoadPostApi = (token, country, stores, jobType, gtin) => requestWrapper(
  token,
  config.generateProductDCNResource,
  'post',
  undefined,
  {
    country,
    gtin,
    jobType,
    stores,
  },
);

export const dcnGtinStatusApi = (token, countryCode, id) => requestWrapper(
  token,
  config.generateProductDCNStatusResource,
  'get',
  { countryCode, id },
);
