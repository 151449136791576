import { Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

import { unPadStoreNumber, sortStoresByNumber } from '../../utils/formatting';
import { storeViewsByCountry } from '../../utils/service-calls/reusable';
import countries from '../../utils/static/countriesWithNikeStores';

import ButtonWhite from './ButtonWhite';

import './Select.css';

// Everything you need to run a storeSelect
const defaultStoreviewsFields = ['storeNumber', 'name', 'facilityType', 'company'];

class StoreSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      storesInCountry: [],
    };
  }

  componentDidMount = () => {
    if (this.props.countryRestriction) {
      this.getStoresByCountry(this.props.countryRestriction);
    } else if (!this.props.noStores) {
      this.getStoresByCountry(this.props.selectedCountry);
    }
  };

  getStoresByCountry = (country) => {
    if (!country.value) {
      return;
    }
    this.setState({ errorMessage: '', fetching: true });
    let published = false;
    if (this.props.isPublished) {
      published = this.props.isPublished;
    }
    storeViewsByCountry(country.value, published, this.getStoreviewsFields())
      .then((res) => {
        if (!res.body || !res.body.objects || !Array.isArray(res.body.objects)) {
          throw new Error(res.statusText);
        }
        const storesInCountry = res.body.objects.map((storeInCountry) => ({
          ...storeInCountry,
          label: `${unPadStoreNumber(storeInCountry.storeNumber)} - ${storeInCountry.name} - ${storeInCountry.facilityType} - ${storeInCountry.company}`,
        }));
        sortStoresByNumber(storesInCountry);
        return this.setState({
          fetching: false,
          storesInCountry: this.props.onlyNikeOwned
            ? storesInCountry.filter((store) => (store.facilityType === 'NIKE_OWNED_STORE'))
            : storesInCountry,
        });
      })
      .catch((err) => this.setState({ errorMessage: err.message, fetching: false, storesInCountry: [] }));
  };

  // If a storeSelect is trying to use their own set of fields, make sure they have at least defaultStoreviewsFields the storeSelect itself works.
  // If they don't pass their own storeviewsFields, then don't pass any so we still get all attributes from storeviews. They may not be ready for the results
  // to be pared down, yet.
  getStoreviewsFields = () => (this.props.storeviewsFields ? [...new Set([...defaultStoreviewsFields, ...this.props.storeviewsFields])] : null);

  render = () => (
    <section className="ncss-row full-width">
      {!this.props.countryRestriction && (
        <article className={`ncss-col-sm-6 va-sm-t ta-sm-l ${this.props.singleColumn ? 'm1-sm' : ''}`}>
          Country
          <ReactSelect
            classNamePrefix="react-select"
            clearable={false}
            id="countrySelect"
            isDisabled={this.props.isDisabled}
            options={countries}
            styles={{
              container: (styles) => ({ ...styles, zIndex: 110 }),
              control: (styles) => ({
                ...styles,
                borderColor: (this.props.isRequired && this.props.formErrors.selectedCountry) ? 'red' : 'lightgrey',
                padding: '0px 0px 0px 3px',
              }),
            }}
            value={this.props.selectedCountry}
            onChange={(selectedOption) => {
              if (!this.props.noStores) { this.getStoresByCountry(selectedOption); }
              this.props.selectCountry(selectedOption);
            }}
          />
          {this.props.isRequired && this.props.formErrors.selectedCountry
            && <p className="text-color-error body-4 mt2-sm">{this.props.formErrors.selectedCountry}</p>}
        </article>
      )}
      {!this.state.fetching && !this.props.noStores && this.props.selectedCountry && this.state.storesInCountry.length !== 0
        ? (
          <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
            Store: Number - Name - Type - Company
            <ReactSelect
              classNamePrefix="react-select"
              clearable={false}
              id="storeSelect"
              isDisabled={this.props.isDisabled}
              options={this.state.storesInCountry.map((storeInCountry) => ({
                full: storeInCountry,
                label: storeInCountry.label,
                value: storeInCountry.label,
              }))}
              styles={{
                container: (styles) => ({ ...styles, zIndex: 100 }),
                control: (styles) => ({
                  ...styles,
                  borderColor: (this.props.isRequired && this.props.formErrors.selectedStore) ? 'red' : 'lightgrey',
                  padding: '0px 0px 0px 3px',
                }),
              }}
              value={this.props.selectedStore ? { label: this.props.selectedStore.label, value: this.props.selectedStore.label } : null}
              onChange={(selectedOption) => this.props.selectStore(selectedOption.full)}
            />
            {this.props.isRequired && this.props.formErrors.selectedStore
              && <p className="text-color-error body-4 mt2-sm">{this.props.formErrors.selectedStore}</p>}
          </article>
        )
        : this.state.fetching && !this.props.noStores && <article className="ncss-col-sm-6 va-sm-t mt-6-sm"><Loading /></article>}
      {this.state.errorMessage && <aside className="text-color-error body-4 mt2-sm">{this.state.errorMessage}</aside>}
      {this.props.selectedStores.length !== 0 && (
        <section>
          <article className="ncss-col-sm-8 mt2-sm ta-sm-l">
            <p className="text-color-accent">Selected Stores:</p>
            <ul>
              {this.props.selectedStores.map((store) => <li key={`${store.storeNumber}`}>{`*  ${store.label}`}</li>)}
            </ul>
          </article>
          <article className="ncss-col-sm-4 mt4-sm ta-sm-r va-sm-t">
            <ButtonWhite className="border" label="Clear Stores" onClick={this.props.onClear} />
          </article>
        </section>
      )}
    </section>
  );
}

StoreSelect.defaultProps = {
  countryRestriction: null,
  formErrors: {},
  isDisabled: false,
  isPublished: false,
  isRequired: false,
  noStores: false,
  onClear: () => null,
  onlyNikeOwned: false,
  selectedCountry: {},
  selectedStore: {},
  selectedStores: [],
  singleColumn: false,
  storeviewsFields: null,
};

StoreSelect.propTypes = {
  countryRestriction: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  formErrors: PropTypes.shape(),
  isDisabled: PropTypes.bool,
  isPublished: PropTypes.bool,
  isRequired: PropTypes.bool,
  noStores: PropTypes.bool,
  onClear: PropTypes.func,
  onlyNikeOwned: PropTypes.bool,
  selectCountry: PropTypes.func.isRequired,
  selectedCountry: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  selectedStore: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  selectedStores: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  selectStore: PropTypes.func.isRequired,
  singleColumn: PropTypes.bool,
  storeviewsFields: PropTypes.arrayOf(PropTypes.string),
};

export default StoreSelect;
