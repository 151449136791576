import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

import './Select.css';

const Select = (props) => (
  <article className={props.className}>
    {props.label}
    <ReactSelect
      classNamePrefix="react-select"
      id={props.id}
      isDisabled={props.isDisabled}
      options={props.options}
      placeholder={props.placeholder}
      styles={{
        container: (styles) => ({ ...styles, zIndex: props.zIndex }),
        control: (styles) => ({
          ...styles,
          borderColor: props.errorMessage !== '' ? 'red' : 'lightgrey',
          padding: '0px 0px 0px 3px',
          zIndex: props.zIndex,
        }),
      }}
      value={props.value
        ? {
          label: (props.options.find((option) => option.value === props.value) ? props.options.find((option) => option.value === props.value).label : null),
          value: props.value,
        }
        : null}
      onChange={({ value }) => (value !== undefined ? props.onChange(value) : null)}
      onMenuOpen={props.onMenuOpen}
    />
    {props.errorMessage && <p className="text-color-error body-4 mt2-sm">{props.errorMessage}</p>}
  </article>
);

Select.defaultProps = {
  className: 'ta-sm-l va-sm-t',
  errorMessage: '',
  isDisabled: false,
  onMenuOpen: () => {},
  placeholder: 'Select...',
  value: null,
  zIndex: 10,
};

Select.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  zIndex: PropTypes.number,
};

export default Select;
