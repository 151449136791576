export const WAITLINES_FETCH = 'WAITLINES_FETCH';
export const WAITLINES_FETCH_SUCCESS = 'WAITLINES_FETCH_SUCCESS';
export const WAITLINES_FETCH_FAILURE = 'WAITLINES_FETCH_FAILURE';

export const WAITLINES_SHOW_CREATE_FORM = 'WAITLINES_SHOW_CREATE_FORM';
export const WAITLINES_HIDE_CREATE_FORM = 'WAITLINES_HIDE_CREATE_FORM';

export const WAITLINE_CREATE = 'WAITLINE_CREATE';
export const WAITLINE_CREATE_SUCCESS = 'WAITLINE_CREATE_SUCCESS';
export const WAITLINE_CREATE_FAILURE = 'WAITLINE_CREATE_FAILURE';

export const WAITLINE_SAVE = 'WAITLINE_SAVE';
export const WAITLINE_SAVE_SUCCESS = 'WAITLINE_SAVE_SUCCESS';
export const WAITLINE_SAVE_FAILURE = 'WAITLINE_SAVE_FAILURE';

export const WAITLINE_DELETE = 'WAITLINE_DELETE';
export const WAITLINE_DELETE_SUCCESS = 'WAITLINE_DELETE_SUCCESS';
export const WAITLINE_DELETE_FAILURE = 'WAITLINE_DELETE_FAILURE';

export const WAITLINE_DEVICE_UPDATE_SUCCESS = 'WAITLINE_DEVICE_UPDATE_SUCCESS';
export const WAITLINE_DEVICE_UPDATE_FAILURE = 'WAITLINE_DEVICE_UPDATE_FAILURE';

export const TICKETS_FETCH = 'TICKETS_FETCH';
export const TICKETS_FETCH_SUCCESS = 'TICKETS_FETCH_SUCCESS';
export const TICKETS_FETCH_FAILURE = 'TICKETS_FETCH_FAILURE';

export const TICKET_DELETE = 'TICKET_DELETE';
export const TICKET_DELETE_SUCCESS = 'TICKET_DELETE_SUCCESS';
export const TICKET_DELETE_FAILURE = 'TICKET_DELETE_FAILURE';

export const waitlinesFetch = (token, storeId) => ({ storeId, token, type: WAITLINES_FETCH });
export const waitlinesFetchSuccess = (storeId, waitlines) => ({ storeId, type: WAITLINES_FETCH_SUCCESS, waitlines });
export const waitlinesFetchFailure = (storeId, error) => ({ error, storeId, type: WAITLINES_FETCH_FAILURE });

export const waitlinesShowCreateForm = () => ({ type: WAITLINES_SHOW_CREATE_FORM });
export const waitlinesHideCreateForm = () => ({ type: WAITLINES_HIDE_CREATE_FORM });

export const waitlineCreate = (token, waitlineData) => ({ token, type: WAITLINE_CREATE, waitlineData });
export const waitlineCreateSuccess = (waitline) => ({ type: WAITLINE_CREATE_SUCCESS, waitline });
export const waitlineCreateFailure = (error) => ({ error, type: WAITLINE_CREATE_FAILURE });

export const waitlineSave = (token, waitline, device) => ({
  device, token, type: WAITLINE_SAVE, waitline,
});
export const waitlineSaveSuccess = (waitline) => ({ type: WAITLINE_SAVE_SUCCESS, waitline });
export const waitlineSaveFailure = (waitline, error) => ({ error, type: WAITLINE_SAVE_FAILURE, waitline });

export const waitlineDelete = (token, waitlineId) => ({ token, type: WAITLINE_DELETE, waitlineId });
export const waitlineDeleteSuccess = (waitlineId) => ({ type: WAITLINE_DELETE_SUCCESS, waitlineId });
export const waitlineDeleteFailure = (waitlineId, error) => ({ error, type: WAITLINE_DELETE_FAILURE, waitlineId });

export const waitlineDeviceUpdateSuccess = (waitline) => ({ type: WAITLINE_DEVICE_UPDATE_SUCCESS, waitline });
export const waitlineDeviceUpdateFailure = (waitline, device, error) => ({
  device, error, type: WAITLINE_DEVICE_UPDATE_FAILURE, waitline,
});

export const ticketsFetch = (token, waitlineId) => ({ token, type: TICKETS_FETCH, waitlineId });
export const ticketsFetchSuccess = (waitlineId, tickets) => ({ tickets, type: TICKETS_FETCH_SUCCESS, waitlineId });
export const ticketsFetchFailure = (waitlineId, error) => ({ error, type: TICKETS_FETCH_FAILURE, waitlineId });

export const ticketDelete = (token, ticketId, waitlineId) => ({
  ticketId, token, type: TICKET_DELETE, waitlineId,
});
export const ticketDeleteSuccess = (ticketId, waitlineId) => ({ ticketId, type: TICKET_DELETE_SUCCESS, waitlineId });
export const ticketDeleteFailure = (ticketId, error) => ({ error, ticketId, type: TICKET_DELETE_FAILURE });
